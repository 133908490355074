import { IAppNotification } from "../types/AppNotification";

export const APP_NOTIFICATION_SEND_INTERNAL = "APP_NOTIFICATION_SEND_INTERNAL";
export const APP_NOTIFICATION_ADD = "APP_NOTIFICATION_ADD";
export const APP_NOTIFICATION_ADD_IF_NOT_EXISTS =
  "APP_NOTIFICATION_ADD_IF_NOT_EXISTS";
export const APP_NOTIFICATION_DELETE = "APP_NOTIFICATION_DELETE";
export const APP_NOTIFICATION_DELETE_BY_ID = "APP_NOTIFICATION_DELETE_BY_ID";
export const APP_NOTIFICATION_SNOOZE = "APP_NOTIFICATION_SNOOZE";
export const APP_NOTIFICATION_UPDATE = "APP_NOTIFICATION_UPDATE";
export const APP_NOTIFICATION_USER_ACKNOWLEDGED =
  "APP_NOTIFICATION_USER_ACKNOWLEDGED";

export interface IAppNotificationSendInternal {
  type: typeof APP_NOTIFICATION_SEND_INTERNAL;
  notification: IAppNotification;
  overwriteExisting?: boolean;
}

export function sendInternalAppNotification(
  notification: IAppNotification,
  overwriteExisting?: boolean
): IAppNotificationSendInternal {
  return {
    type: APP_NOTIFICATION_SEND_INTERNAL,
    notification,
    overwriteExisting,
  };
}

export interface IAppNotificationAdd {
  type: typeof APP_NOTIFICATION_ADD;
  notification: IAppNotification;
}

export function addAppNotification(
  notification: IAppNotification
): IAppNotificationAdd {
  return {
    type: APP_NOTIFICATION_ADD,
    notification,
  };
}

export interface IAppNotificationAddIfNotExists {
  type: typeof APP_NOTIFICATION_ADD_IF_NOT_EXISTS;
  notification: IAppNotification;
}

export function addAppNotificationIfNotExists(
  notification: IAppNotification
): IAppNotificationAddIfNotExists {
  return {
    type: APP_NOTIFICATION_ADD_IF_NOT_EXISTS,
    notification,
  };
}

export interface IAppNotificationDelete {
  type: typeof APP_NOTIFICATION_DELETE;
  notification: IAppNotification;
}

export function deleteAppNotification(
  notification: IAppNotification
): IAppNotificationDelete {
  return {
    type: APP_NOTIFICATION_DELETE,
    notification,
  };
}

export interface IAppNotificationDeleteById {
  type: typeof APP_NOTIFICATION_DELETE_BY_ID;
  identifier: string;
}

export function deleteAppNotificationById(
  identifier: string
): IAppNotificationDeleteById {
  return {
    type: APP_NOTIFICATION_DELETE_BY_ID,
    identifier,
  };
}

export interface IAppNotificationSnooze {
  type: typeof APP_NOTIFICATION_SNOOZE;
  notification: IAppNotification;
  durationMilliseconds: number;
}

export function snoozeAppNotification(
  notification: IAppNotification,
  durationMilliseconds: number
): IAppNotificationSnooze {
  return {
    type: APP_NOTIFICATION_SNOOZE,
    notification,
    durationMilliseconds,
  };
}

export interface IAppNotificationUserAcknowledged {
  type: typeof APP_NOTIFICATION_USER_ACKNOWLEDGED;
}

export function userAcknowledgedAppNotification(): IAppNotificationUserAcknowledged {
  return {
    type: APP_NOTIFICATION_USER_ACKNOWLEDGED,
  };
}

export type AppNotificationActions =
  | IAppNotificationSendInternal
  | IAppNotificationAdd
  | IAppNotificationAddIfNotExists
  | IAppNotificationDelete
  | IAppNotificationDeleteById
  | IAppNotificationSnooze
  | IAppNotificationUserAcknowledged;
