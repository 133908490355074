import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { AuthServiceInstance } from "../authentication/authService";

declare global {
  interface Window {
    HubLogging: ApplicationInsights;
  }
}

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

const remoteDependencyType = "RemoteDependencyData";
const resizeObserverError = "ResizeObserver loop limit exceeded";
const durationThreshold = Number.parseInt(
  process.env.REACT_APP_APPLICATION_INSIGHTS_SLOW_DEPENDENCY_THRESHOLD ?? "1000"
);

class HubLogging {
  public initialise = async (): Promise<void> => {
    const instance = this.getAppInsightsInstance();
    const userId = await AuthServiceInstance.getUserId();
    const clientId = await AuthServiceInstance.getClientId();
    const isAdmin = await AuthServiceInstance.isAdmin();

    instance.setAuthenticatedUserContext(
      `${isAdmin ? "hgem_" : "user_"}${userId}`,
      `client_${clientId}`,
      true
    );

    instance.trackPageView();
    this.setAppInsightsInstance(instance);
  };

  public trySerializeErrorObject = (error: unknown): string => {
    let message = "";

    if (typeof error === "string") {
      message = error;
    } else if (typeof error === "object") {
      message = JSON.stringify(error);
    }

    return message;
  };

  public captureEvent = (eventName: string, message?: string | undefined) => {
    const event = {
      name: eventName,
    } as IEventTelemetry;

    const eventProperties = {
      message: message,
    };

    const instance = this.getAppInsightsInstance();
    instance.trackEvent(event, eventProperties);
  };

  public capturePerformanceEvent = (
    eventName: string,
    eventStart: number,
    eventEnd: number,
    message?: string
  ) => {
    const event = {
      name: eventName,
    } as IEventTelemetry;

    const eventProperties = {
      message: message,
      duration: eventEnd - eventStart,
    };

    const instance = this.getAppInsightsInstance();
    instance.trackEvent(event, eventProperties);
  };

  public captureError = (
    errorMessage: string,
    error: Error | string | unknown,
    severityLevel: SeverityLevel,
    requestedResource?: string | undefined
  ) => {
    const ex = {
      error: undefined,
      severityLevel: severityLevel,
      properties: {
        message: errorMessage,
        resource: requestedResource,
      },
    } as IExceptionTelemetry;

    if (typeof error === "string") {
      ex.exception = new Error(error);
    } else if (error instanceof Error) {
      ex.exception = error;
    }

    const instance = this.getAppInsightsInstance();
    instance.trackException(ex);

    console.error(ex);
  };

  private telemetryInitialiser = (telemetryItem: ITelemetryItem): boolean => {
    let logEvent = true;

    if (telemetryItem.data?.message === resizeObserverError) {
      logEvent = false;
    }

    if (telemetryItem.baseType === remoteDependencyType) {
      const success = (telemetryItem.baseData?.success as boolean) ?? false;
      const duration = Number.parseFloat(
        telemetryItem.baseData?.duration ?? "0"
      );

      if (success === true && duration < durationThreshold) {
        logEvent = false;
      }
    }

    return logEvent;
  };

  private getAppInsightsInstance(): ApplicationInsights {
    let instance: ApplicationInsights = window.HubLogging;
    if (!instance) {
      instance = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
          autoTrackPageVisitTime: true,
          enableAutoRouteTracking: true,
        },
      });

      instance.loadAppInsights();
      instance.addTelemetryInitializer(this.telemetryInitialiser);
    }

    return instance;
  }

  private setAppInsightsInstance(instance: ApplicationInsights): void {
    window.HubLogging = instance;
  }
}

export const Logging = new HubLogging();
