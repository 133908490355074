import { useEffect, useState } from "react";
import { Logging } from "../utils/logging";

interface IProps {
  children: JSX.Element;
}

const AppInsightsWrapper = (props: IProps) => {
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    const setLogging = async () => {
      await Logging.initialise().then(() => {
        setInitialised(true);
      });
    };

    if (!initialised) {
      setLogging();
    }
  }, [initialised]);

  return <>{initialised && props.children}</>;
};

export default AppInsightsWrapper;
