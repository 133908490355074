import { IClientPeriod, IVisitDateFilterOptions } from "../types/FilterOptions";

export interface IClientPeriodByClientIdVariables {
  clientId: number;
}

export interface IClientPeriodByClientId {
  clientPeriodByClientId: IGraphClientPeriod[];
}

export interface IGraphClientPeriod {
  name: string;
  startDate: Date;
  endDate: Date;
  clientPeriodYear?: IGraphClientPeriodYear;
}

export interface IGraphClientPeriodYear {
  name: string;
  periodYearStart: Date;
  periodYearEnd: Date;
}

class Mapper {
  public map = (
    data: IClientPeriodByClientId | undefined
  ): IVisitDateFilterOptions => {
    return {
      clientPeriods: data?.clientPeriodByClientId.map((x) => {
        return {
          periodName: x.name,
          startDate: x.startDate,
          endDate: x.endDate,
          periodYearName: x.clientPeriodYear
            ? x.clientPeriodYear.name
            : "",
        } as IClientPeriod;
      }),
    } as IVisitDateFilterOptions;
  };
}

export const ClientPeriodByClientIdMapper = new Mapper();
