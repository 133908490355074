import React, { useEffect, useState } from "react";
import FormField from "../formField/formField";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { CommonDropdownFieldStyles } from "./styles/commonDropdownFieldStyles";

interface IDropdownValue {
  id: number;
  name: string;
}

interface IProps {
  availableValues: IDropdownValue[];
  labelId?: string;
  onChange: (value: IDropdownValue) => void;
  value?: IDropdownValue | undefined;
  labelClassName?: string | undefined;
  labelText?: string | undefined;
  fieldClassName?: string | undefined;
  fieldDefaultText?: string | undefined;
  notificationIdentifier?: string | undefined;
  before?: React.ReactElement | undefined;
  displayEmpty?: boolean;
  placeholder?: string;
}

const CommonDropdownField = (props: IProps) => {
  const [selectedValueId, setSelectedValueId] = useState<number>(0);

  const defaultClasses = CommonDropdownFieldStyles();

  useEffect(() => {
    setSelectedValueId(props.value ? props.value.id : 0);
  }, [setSelectedValueId, props.value]);

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const valueId = Number.parseInt(event.target.value as string);

    setSelectedValueId(valueId);

    const noChange = valueId === props.value?.id;

    if (noChange || valueId === 0) {
      return;
    }

    const value = props.availableValues.find((x) => x.id === valueId);

    if (value) {
      props.onChange(value);
    }
  };

  return (
    <FormField
      before={props.before}
      notificationIdentifier={props.notificationIdentifier}
    >
      <>
        {props.labelId && (
          <InputLabel
            id={`${props.labelId}-label`}
            className={props.labelClassName ?? defaultClasses.categoryLabel}
          >
            {props.labelText ?? i18n.translate("TASK_CENTRE_FIELD_Category")}
          </InputLabel>
        )}
        <Select
          labelId={`${props.labelId}-label`}
          fullWidth
          value={selectedValueId}
          onChange={onChange}
          className={props.fieldClassName ?? defaultClasses.categoryField}
          disabled={props.availableValues.length === 0}
          displayEmpty={props.displayEmpty ? true : false}
          renderValue={() =>
            props.value ? (
              <span>{props.value.name}</span>
            ) : (
              <span style={{ color: "#b6b8bb" }}>{props.placeholder}</span>
            )
          }
        >
          {props.value === null && (
            <MenuItem key={0} value={0} disabled>
              {props.fieldDefaultText ??
                i18n.translate("TASK_CENTRE_CATEGORY_Empty")}
            </MenuItem>
          )}
          {props.availableValues.map((c) => {
            return (
              <MenuItem key={c.id ? c.id : 0} value={c.id}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
      </>
    </FormField>
  );
};

export default CommonDropdownField;
