import { clientBorderColorThresholds } from "../state/api";

const getBorderColourThresholds = async (clientId: number) => {
  const borderColorThresholdsResponse = await clientBorderColorThresholds(
    clientId
  );
  let borderColorThresholds = {
    lowThreshold: 70,
    highThreshold: 90,
  };
  if (
    borderColorThresholdsResponse.data.data?.queryForClient.nodes &&
    borderColorThresholdsResponse.data.data?.queryForClient.nodes.length > 0
  ) {
    borderColorThresholds =
      borderColorThresholdsResponse.data.data?.queryForClient.nodes[0];
  }

  return borderColorThresholds;
};

export default getBorderColourThresholds;
