import React, { useState } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { IKeyValuePair } from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";

interface IProps {
  availableLocations: IKeyValuePair[];
  selectedLocation: IKeyValuePair | undefined;
  applySelectionCallback: (selectedLocation: IKeyValuePair) => void;
}

const LocationRankFilter = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    return props.selectedLocation ? props.selectedLocation.value : "";
  };

  const applySelection = (selectedOptions: string[]) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const matchedOptions = props.availableLocations.filter(
        (x) => selectedOptions.indexOf(x.value) > -1
      );
      if (matchedOptions && matchedOptions.length > 0) {
        props.applySelectionCallback(matchedOptions[0]);
      }
    }
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  return (
    <FilterWrapper
      label={i18n.translate("LOCATION_RANK_PICKER_Location_Rank")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={getAppliedSelectionText()}
      highlight={true}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText=""
        multiSelect={false}
        options={props.availableLocations.map((x) => x.value)}
        selectedOptions={props.selectedLocation? [props.selectedLocation.value] : [""]}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
      />
    </FilterWrapper>
  );
};

export default LocationRankFilter;
