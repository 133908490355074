import { useDispatch, useSelector } from "react-redux";
import { IStateObserver, IStateObserverProps } from "./IStateObserver";
import {
  FilterOptionsErrors,
  FilterOptionsLastUpdated,
  FilterOptionsLoaded,
  UserProfileAvailableBrands,
  UserProfileAvailableClients,
  UserProfileCurrentClientId,
} from "../state/selectors";
import {
  filterOptionsError,
  filterOptionsSuccess,
} from "../../../state/actions/FilterOptions-Actions";
import dayjs from "dayjs";
import {
  IAppNotification,
  NotificationIdentifiers,
  NotificationSource,
  NotificationType,
} from "../../../state/types/AppNotification";
import {
  deleteAppNotificationById,
  sendInternalAppNotification,
} from "../../../state/actions/AppNotification-Actions";
import {
  locationsApi,
  periodHierarchyByClientAndBrandAPI,
  periodHierarchyGlobalDefaultAPI,
  questionnaireAPI,
  questionnaireTagsAPI,
  visitDatesApi,
} from "../../../state/api/FilterOptions-API";
import { IGraphQueryResponse } from "../../../state/graphs/types";
import {
  IQuestionnaireBrandLink,
  IQuestionnairesByClientIdAndBrand,
  QuestionnairesByClientIdAndBrandMapper,
} from "../../../state/graphs/questionnairesByClientId";
import {
  IGraphPeriodHierarchy,
  IPeriodHierarchBrandLink,
  mapPeriodHierarchies,
} from "../../../state/graphs/periodHierarchyByClientId";
import { IFilterOptions } from "../../../state/types/FilterOptions";
import { ClientPeriodByClientIdMapper } from "../../../state/graphs/clientPeriodByClientId";
import { BranchesByClientIdAndManagerIdMapper } from "../../../state/graphs/branchesByClientIdAndManagerId";

const useFilterOptionsObserver = () => {
  const dispatch = useDispatch();

  const notificationId = NotificationIdentifiers.UseFilterOptionsObserver;
  const maxCacheValue =
    process.env.REACT_APP_PRE_LOAD_FILTER_OPTIONS_CACHE_MAX_MINUTES;
  const maxCacheMinutes = Number.parseInt(
    maxCacheValue ? maxCacheValue : "180"
  );

  const notificationValue =
    process.env.REACT_APP_PRE_LOAD_FILTER_OPTIONS_CACHE_NOTIFICATION_MINUTES;
  const notificationMinutes = Number.parseInt(
    notificationValue ? notificationValue : "300"
  );

  const filterOptionsLoaded = useSelector(FilterOptionsLoaded);
  const filterOptionsErrors = useSelector(FilterOptionsErrors);
  const filterOptionsLastUpdated = useSelector(FilterOptionsLastUpdated);
  const userProfileAvailableBrands = useSelector(UserProfileAvailableBrands);
  const userProfileCurrentClientId = useSelector(UserProfileCurrentClientId);
  const userProfileAvailableClients = useSelector(UserProfileAvailableClients);

  const initialiseState = async (props: IStateObserverProps) => {
    await fetchFilterOptions(props);

    dispatch(deleteAppNotificationById(notificationId));
  };

  const stateRequiresRefresh = async (): Promise<boolean> => {
    const stateIsFresh = stateHasLoadedAndNotStale(notificationMinutes);
    return !stateIsFresh;
  };

  const refreshState = () => {
    const refreshNotification: IAppNotification = {
      Identifier: notificationId,
      Source: NotificationSource.FilterOptions_StateRefresh,
      Type: NotificationType.UserActionRequired,
      ShowInNotificationCentre: true,
      NotificationTimeout: 0,
    };

    dispatch(sendInternalAppNotification(refreshNotification));

    return Promise.resolve();
  };

  const stateHasLoadedAndNotStale = (duration: number): boolean => {
    const now = dayjs(new Date()).subtract(duration, "minute");
    const lastRefreshed = dayjs(filterOptionsLastUpdated);

    if (
      !filterOptionsLoaded ||
      !filterOptionsLastUpdated ||
      lastRefreshed.isBefore(now)
    ) {
      return false;
    }

    return true;
  };

  const fetchFilterOptions = async (props: IStateObserverProps) => {
    try {
      const { userId, clientId } = props;

      if (!clientId || !userId) {
        return false;
      }

      const clientName =
        userProfileCurrentClientId && userProfileAvailableClients
          ? userProfileAvailableClients.find(
              (x) => x.id == userProfileCurrentClientId
            )?.name
          : undefined;

      const visitDateOptionsResponse = await visitDatesApi(clientId);

      throwIfResponseContainsErrors(visitDateOptionsResponse.data);

      const locationOptionsResponse = await locationsApi(clientId, userId);

      throwIfResponseContainsErrors(locationOptionsResponse.data);

      const questionnaireTagsResponse = await questionnaireTagsAPI(clientId);

      throwIfResponseContainsErrors(questionnaireTagsResponse.data);

      const questionnaireTags = questionnaireTagsResponse.data.data
        ? questionnaireTagsResponse.data.data.questionnaireTagsByClientId
        : [];

      const questionnaireBrandLinks: IQuestionnaireBrandLink[] = [];
      const questionnaireOptions: IQuestionnairesByClientIdAndBrand = {
        questionnairesByClientId: [],
      };

      if (userProfileAvailableBrands) {
        for (let i = 0; i < userProfileAvailableBrands.length; i++) {
          const response = await questionnaireAPI(
            clientId,
            userProfileAvailableBrands[i].id
          );

          throwIfResponseContainsErrors(response.data);

          if (response.data.data) {
            questionnaireBrandLinks.push(
              ...response.data.data.questionnairesByClientId.map((x) => {
                return {
                  brand: userProfileAvailableBrands[i].name,
                  questionnaire: x.description,
                };
              })
            );

            questionnaireOptions.questionnairesByClientId.push(
              ...response.data.data.questionnairesByClientId.filter((x) => {
                return (
                  questionnaireOptions.questionnairesByClientId.find(
                    (q) => q.id === x.id
                  ) === undefined
                );
              })
            );
          }
        }
      }

      const periodHierarchyBrandLinks: IPeriodHierarchBrandLink[] = [];
      const periodHierarchyOptions: IGraphPeriodHierarchy[] = [];

      const response = await periodHierarchyByClientAndBrandAPI(
        clientId,
        undefined
      );

      throwIfResponseContainsErrors(response.data);

      if (
        response.data.data &&
        response.data.data.periodHierarchyByClientId.length > 0
      ) {
        periodHierarchyBrandLinks.push({
          brandId: undefined,
          brandName: undefined,
          hierarchyIds: response.data.data.periodHierarchyByClientId.map(
            (x) => x.id
          ),
        });

        periodHierarchyOptions.push(
          ...response.data.data.periodHierarchyByClientId
        );
      }

      if (periodHierarchyOptions.length === 0) {
        const response = await periodHierarchyGlobalDefaultAPI();
        throwIfResponseContainsErrors(response.data);

        if (
          response.data.data &&
          response.data.data.periodHierarchyGlobalDefault.length > 0
        ) {
          periodHierarchyBrandLinks.push({
            brandId: undefined,
            brandName: "system-default",
            hierarchyIds: response.data.data.periodHierarchyGlobalDefault.map(
              (x) => x.id
            ),
          });

          periodHierarchyOptions.push(
            ...response.data.data.periodHierarchyGlobalDefault
          );
        }
      }

      const filterOptions: IFilterOptions = {
        visitDateOptions: ClientPeriodByClientIdMapper.map(
          visitDateOptionsResponse.data.data
        ),
        locationOptions: BranchesByClientIdAndManagerIdMapper.map(
          locationOptionsResponse.data.data,
          clientName
        ),
        questionnaireOptions: QuestionnairesByClientIdAndBrandMapper.map(
          questionnaireOptions,
          questionnaireBrandLinks,
          questionnaireTags
        ),
        periodHierarchyOptions: mapPeriodHierarchies(
          periodHierarchyOptions,
          periodHierarchyBrandLinks
        ),
        lastUpdated: new Date(),
      };

      dispatch(filterOptionsSuccess(filterOptions));
    } catch (e) {
      dispatch(filterOptionsError("Failed to fetch filter options"));
      throw e;
    }
  };

  const throwIfResponseContainsErrors = (
    response: IGraphQueryResponse<unknown>
  ): void => {
    if (response.errors && response.errors.length > 0) {
      throw Error(JSON.stringify(response.errors));
    }
  };

  const observer: IStateObserver = {
    name: "useFilterOptionsObserver",
    isInitialised: stateHasLoadedAndNotStale(maxCacheMinutes),
    hasErrors: filterOptionsErrors.length > 0,
    lastRefresh: filterOptionsLastUpdated,
    initialiseState,
    stateRequiresRefresh,
    refreshState,
  };

  return observer;
};

export default useFilterOptionsObserver;
