import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import {
  FormControlLabel,
  makeStyles,
  Switch,
  Theme,
  useTheme,
} from "@material-ui/core";
import { StorageInstance } from "../../../utils/localStorage";
import { i18n } from "../../../localizations";

enum PickerType {
  Distinct = "distinct",
  Grouped = "grouped",
}

interface IProps {
  availableSections: string[];
  selectedSections: string[];
  sectionGroupings?: Array<{ key: string; values: Array<string> }>;
  applySelectionCallback: (selectedSections: string[]) => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  switchContainer: {
    margin: "10px",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  switchLabel: {
    marginBottom: "5px",

    "& .MuiFormControlLabel-label": {
      color: theme.palette.grey[500],
    },
  },
}));

const QuestionSectionPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSections, setSelectedSections] = useState([] as string[]);

  const [sectionGroupings, setSectionGroupings] = useState<
    Array<{ key: string; values: Array<string> }> | undefined
  >(undefined);

  const initialPickerType = StorageInstance.getValueOrDefault(
    "SectionFilterPreference",
    PickerType.Distinct
  );
  if (initialPickerType === PickerType.Grouped && props.sectionGroupings) {
    setSectionGroupings(props.sectionGroupings);
  }

  const [selectedPickerType, setSelectedPickerType] = useState(
    initialPickerType
  );

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedSections.length === 0 ||
      selectedSections.length === props.availableSections.length
    ) {
      selectionText = i18n.translate("QUESTION_SECTIONS_PICKER_All_Selected");
    } else if (selectedSections.length === 1) {
      selectionText = selectedSections[0];
    } else {
      selectionText = `${selectedSections.length} ${i18n.translate(
        "QUESTION_SECTIONS_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    return selectedSections.join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    props.applySelectionCallback(selectedOptions);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  const swicthPickerType = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelectedPickerType(checked ? PickerType.Grouped : PickerType.Distinct);

    if (checked && props.sectionGroupings) {
      setSectionGroupings(props.sectionGroupings);
    } else {
      setSectionGroupings(undefined);
    }
  };

  useEffect(() => {
    setSelectedSections(props.selectedSections);
  }, [setSelectedSections, props.selectedSections]);

  return (
    <FilterWrapper
      label={i18n.translate("QUESTION_SECTIONS_PICKER_Sections")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      <div>
        {props.sectionGroupings && props.sectionGroupings.length > 1 && (
          <div className={classes.switchContainer}>
            <FormControlLabel
              className={classes.switchLabel}
              control={
                <Switch
                  checked={selectedPickerType === PickerType.Grouped}
                  onChange={swicthPickerType}
                  name="question-picker-type-swicth"
                  color="primary"
                />
              }
              label={i18n.translate("QUESTION_SECTIONS_PICKER_Show_Groupings")}
            />
          </div>
        )}

        <GenericMultiSelect
          useSelectAllOption={false}
          selectAllOptionText={i18n.translate(
            "QUESTION_SECTIONS_PICKER_Select_All_Option"
          )}
          multiSelect={true}
          options={props.availableSections}
          selectedOptions={selectedSections}
          applySelectionCallback={applySelection}
          cancelSelectionCallback={cancelSelection}
          applyLocalisationToOptions={false}
          optionGroupings={sectionGroupings}
        />
      </div>
    </FilterWrapper>
  );
};

export default QuestionSectionPicker;
