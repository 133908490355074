export const REPORTVIEWER_SET_PAGE_SETTINGS = "REPORTVIEWER_SET_PAGE_SETTINGS";
export const REPORTVIEWER_SET_CURRENT_PAGE = "REPORTVIEWER_SET_CURRENT_PAGE";
export const REPORTVIEWER_SET_BUSY_STATE = "REPORTVIEWER_SET_BUSY_STATE";
export const REPORTVIEWER_SET_DATA_EXPORT_STATE =
  "REPORTVIEWER_SET_DATA_EXPORT_STATE";

export interface IReportViewerSetPageSettings {
  type: typeof REPORTVIEWER_SET_PAGE_SETTINGS;
  pages: string[];
  defaultPage: string;
}

export function setReportViewerPageSettings(
  pages: string[],
  defaultPage: string
): IReportViewerSetPageSettings {
  return {
    type: REPORTVIEWER_SET_PAGE_SETTINGS,
    pages,
    defaultPage,
  };
}

export interface IReportViewerSetCurrentPage {
  type: typeof REPORTVIEWER_SET_CURRENT_PAGE;
  page: string;
}

export function setReportViewerCurrentPage(
  page: string
): IReportViewerSetCurrentPage {
  return {
    type: REPORTVIEWER_SET_CURRENT_PAGE,
    page,
  };
}

export interface IReportViewerSetBusyState {
  type: typeof REPORTVIEWER_SET_BUSY_STATE;
  isBusy: boolean;
}

export function setReportViewerBusyState(
  isBusy: boolean
): IReportViewerSetBusyState {
  return {
    type: REPORTVIEWER_SET_BUSY_STATE,
    isBusy,
  };
}

export interface IReportViewerSetExportState {
  type: typeof REPORTVIEWER_SET_DATA_EXPORT_STATE;
  isOpen: boolean;
}

export function setReportViewerDataExportState(
  isOpen: boolean
): IReportViewerSetExportState {
  return {
    type: REPORTVIEWER_SET_DATA_EXPORT_STATE,
    isOpen,
  };
}

export type ReportViewerActions =
  | IReportViewerSetPageSettings
  | IReportViewerSetCurrentPage
  | IReportViewerSetBusyState
  | IReportViewerSetExportState;
