import i18n from "../../../../localizations/i18n";
import {
  IQuestionnaireFilterOption,
  IVisitSourceOption,
} from "../../../../state/types/FilterOptions";
import {
  IHubFilter,
  HubFilterType,
  IVisitTypeFilter,
  IQuestionSectionFilter,
  IQuestionnaireTypeFilter,
  IKeyValuePair,
} from "../../../../state/types/FilterSets";
import { CaseInsensitiveSort } from "../../../../utils/sortnig";

export interface IQuestionOption {
  Questions: string[];
  QuestionSubjects: string[];
}

export const filterUnavailableSelections = (
  availableValues: string[],
  selectedValues: string[]
): string[] => {
  return selectedValues.filter((x) => availableValues.indexOf(x) > -1);
};

export const filterUnavailableKeyValueSelections = (
  availableValues: IKeyValuePair[],
  selectedValues: IKeyValuePair[]
): IKeyValuePair[] => {
  return selectedValues.filter((x) =>
    availableValues.find((f) => f.key === x.key)
  );
};

export const getQuestionnairesTypesForSelectedVisitTypes = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[]
): string[] => {
  const sourceQuestionnaireTypes: string[] = [];

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const visitTypes = visitTypeFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (q.category && q.category !== "") {
          sourceQuestionnaireTypes.push(q.category);
        }
      });
    }
  });

  return distinctList(sourceQuestionnaireTypes);
};

export const getGroupedQuestionSectionsForSelectedQuestionnaireTypes = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[]
): Array<{ key: string; values: Array<string> }> => {
  const groupedSections: { header: string; section: string }[] = [];

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const questionnaireFilter = currentFilters.find(
    (x) => x.type === HubFilterType.QuestionnaireType
  ) as IQuestionnaireTypeFilter;

  const visitTypes = visitTypeFilter?.value;
  const questionnaireTypes = questionnaireFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (isValidSelection(q.category, questionnaireTypes)) {
          q.sections.forEach((s) => {
            if (!s.isMenuSection) {
              groupedSections.push({
                header:
                  q.category && q.category.length > 0
                    ? q.category
                    : i18n.translate(`VISIT_TYPE_OPTION_${x.visitType}`),
                section: s.name,
              });
            }
          });
        }
      });
    }
  });

  const result: Array<{ key: string; values: Array<string> }> = [];
  const distinctHeaders = [
    ...new Set(groupedSections.map((x) => x.header)),
  ].sort();

  distinctHeaders.forEach((x) => {
    result.push({
      key: x,
      values: distinctList(groupedSections.map((s) => s.section)).sort(
        CaseInsensitiveSort
      ),
    });
  });

  return result;
};

export const getQuestionsForSelectedSections = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[]
): IQuestionOption => {
  const sourceQuestions: IQuestionOption = {
    Questions: [],
    QuestionSubjects: [],
  };

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const questionnaireFilter = currentFilters.find(
    (x) => x.type === HubFilterType.QuestionnaireType
  ) as IQuestionnaireTypeFilter;

  const sectionFilter = currentFilters.find(
    (x) => x.type === HubFilterType.Sections
  ) as IQuestionSectionFilter;

  const visitTypes = visitTypeFilter?.value;
  const questionnaireTypes = questionnaireFilter?.value;
  const sections = sectionFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (isValidSelection(q.category, questionnaireTypes)) {
          q.sections.forEach((s) => {
            if (isValidSelection(s.name, sections)) {
              s.questions.forEach((qu) => {
                sourceQuestions.Questions.push(qu.question);

                if (qu.subject && qu.subject.length > 0) {
                  sourceQuestions.QuestionSubjects.push(qu.subject);
                }
              });
            }
          });
        }
      });
    }
  });

  return {
    Questions: distinctList(sourceQuestions.Questions),
    QuestionSubjects: distinctList(sourceQuestions.QuestionSubjects),
  } as IQuestionOption;
};

export const getQuestionCategoriesForSelectedQuestionnaireTypes = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[]
): string[] => {
  const sourceCategories: string[] = [];

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const questionnaireFilter = currentFilters.find(
    (x) => x.type === HubFilterType.QuestionnaireType
  ) as IQuestionnaireTypeFilter;

  const visitTypes = visitTypeFilter?.value;
  const questionnaireTypes = questionnaireFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (isValidSelection(q.category, questionnaireTypes)) {
          q.sections.forEach((s) => {
            s.questionCategories.forEach((c) => {
              if (sourceCategories.indexOf(c) === -1) {
                sourceCategories.push(c);
              }
            });
          });
        }
      });
    }
  });

  return distinctList(sourceCategories);
};

export const getQuestionBenchmarksForSelectedQuestionnaireTypes = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[]
): string[] => {
  const sourceBenchmarks: string[] = [];

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const questionnaireFilter = currentFilters.find(
    (x) => x.type === HubFilterType.QuestionnaireType
  ) as IQuestionnaireTypeFilter;

  const visitTypes = visitTypeFilter?.value;
  const questionnaireTypes = questionnaireFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (isValidSelection(q.category, questionnaireTypes)) {
          q.sections.forEach((s) => {
            s.benchmarks.forEach((b) => {
              if (sourceBenchmarks.indexOf(b) === -1) {
                sourceBenchmarks.push(b);
              }
            });
          });
        }
      });
    }
  });

  return distinctList(sourceBenchmarks);
};

export const getQuestionTagsForSelectedQuestionnaireTypes = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[]
): string[] => {
  const sourceAnalysisTags: string[] = [];

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const questionnaireFilter = currentFilters.find(
    (x) => x.type === HubFilterType.QuestionnaireType
  ) as IQuestionnaireTypeFilter;

  const visitTypes = visitTypeFilter?.value;
  const questionnaireTypes = questionnaireFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (isValidSelection(q.category, questionnaireTypes)) {
          q.sections.forEach((s) => {
            s.analysisTags.forEach((t) => {
              if (sourceAnalysisTags.indexOf(t) === -1) {
                sourceAnalysisTags.push(t);
              }
            });
          });
        }
      });
    }
  });

  return distinctList(sourceAnalysisTags);
};

export const getTagValuesForSelectedQuestionnaireTypes = (
  questionnaireOptions: IQuestionnaireFilterOption[],
  currentFilters: IHubFilter[],
  tagName: string
): string[] => {
  const sourceTagValues: string[] = [];

  const visitTypeFilter = currentFilters.find(
    (x) => x.type === HubFilterType.VisitType
  ) as IVisitTypeFilter;

  const questionnaireFilter = currentFilters.find(
    (x) => x.type === HubFilterType.QuestionnaireType
  ) as IQuestionnaireTypeFilter;

  const visitTypes = visitTypeFilter?.value;
  const questionnaireTypes = questionnaireFilter?.value;

  questionnaireOptions.forEach((x) => {
    if (isValidSelection(x.visitType, visitTypes)) {
      x.questionnaires.forEach((q) => {
        if (isValidSelection(q.category, questionnaireTypes)) {
          const tag = q.tags.find(
            (x) => x.tag.toLowerCase() === tagName.toLowerCase()
          );
          if (tag && tag.values) {
            sourceTagValues.push(...tag.values);
          }
        }
      });
    }
  });

  return sourceTagValues;
};

export const getVisitSources = (
  visitSourceOptions: IVisitSourceOption[]
): Array<{ key: number; value: string }> =>
  visitSourceOptions.map(({ id, description }) => ({
    key: id,
    value: description,
  }));

const isValidSelection = (
  selection: string,
  availableValues: string[] | undefined
): boolean => {
  return (
    !availableValues ||
    availableValues.length === 0 ||
    availableValues.indexOf(selection) > -1
  );
};

const distinctList = (values: string[]): string[] => {
  const distinct = new Map(
    values
      .filter((v) => v !== undefined && v !== null)
      .map((v) => [v.toLowerCase(), v])
  );
  return [...distinct.values()];
};
