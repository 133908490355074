import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  newTabIcon: {
    color: hgemColours.ExtraMediumGrey,
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  headerText: {
    color: hgemColours.DarkGrey,
    fontSize: "1em",
  },
  subHeader: {
    color: hgemColours.ExtraMediumGrey,
    fontSize: "0.85em",
  },
  bodyText: {
    fontSize: "75%",
    color: hgemColours.ExtraMediumGrey,
  },
  iconSpan: {
    marginBottom: "10%",
    alignSelf: "flex-end",
    [theme.breakpoints.down("md")]: {
      alignSelf: "flex-start",
    },
  },
  miniCard: {
    border: `1px solid ${hgemColours.LightGrey}`,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    margin: "3% 0 3% 0",
    padding: "5%",
  },
}));

export const RelatedConversationsStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
