import React, { ReactElement } from "react";
import i18n from "../../../localizations/i18n";
import { ICaseEvent } from "../../../state/types/TaskCentreCases";
import { ContactConfirmedStyles } from "../styles/contactConfirmedStyles";

interface IProps {
  caseEvent: ICaseEvent;
  eventHeader: React.ReactElement;
}

const ContactConfirmed = (props: IProps) => {
  const classes = ContactConfirmedStyles();

  const getComment = (): ReactElement => {
    if (props.caseEvent.comment) {
      return (
        <div className={classes.confirmationText}>
          {props.caseEvent.comment}
        </div>
      );
    } else {
      return (
        <div className={`${classes.confirmationText} ${classes.emptyNotes}`}>
          {i18n.translate("TASK_CENTRE_Contact_Confirmed_No_Comment")}
        </div>
      );
    }
  };

  return (
    <div className={classes.contactConfirmationContainer}>
      {props.eventHeader}
      <div>
        <span className={classes.contactConfirmation}>
          <span
            className={`icon far fa-address-book ${classes.confirmationIcon}`}
          ></span>
          {getComment()}
        </span>
      </div>
    </div>
  );
};

export default ContactConfirmed;
