import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import {
  CaseEventType,
  ICaseEvent,
} from "../../../state/types/TaskCentreCases";
import { IUserProfileState } from "../../../state/reducers/UserProfile-Reducer";

export const AllManagers = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.managers;
  }
);

export const CurrentUserId = createSelector(
  (state: RootState) => state.UserProfileReducer,
  (state: IUserProfileState) => {
    return state.userProfile?.Id;
  }
);

export const EventsByEventType = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (_: RootState, eventTypes: CaseEventType[]) => eventTypes,
  (
    state: ITaskCentreCaseListState,
    eventTypes: CaseEventType[]
  ): ICaseEvent[] => {
    if (eventTypes.length > 0) {
      const caseId = state.taskCentreCases.selectedCaseId;
      const currentCase = state.taskCentreCases.cases.find(
        (x) => x.id === caseId
      );

      if (currentCase) {
        return currentCase.events.filter(
          (x) => eventTypes.indexOf(x.type) > -1
        );
      }
    }

    return [];
  }
);
