import { Icon } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

type Props = {
  isDownloading: boolean;
  download: () => void;
};

const MediaMessageSideContent = (props: Props) => {
  return props.isDownloading ? (
    <Icon
      style={{ overflow: "visible" }}
      className="fas fa-spinner fa-spin fa-3x fa-fw"
    />
  ) : (
    <Icon
      style={{
        overflow: "visible",
        cursor: "pointer",
        color: hgemColours.LimeGreen,
      }}
      onClick={props.download}
      className="fas fa-download"
    />
  );
};

export default MediaMessageSideContent;
