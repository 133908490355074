import { i18n } from "../../../../localizations";
import { IGuestDetails } from "../../../../state/types/GuestDetails";
import { ICaseDetails } from "../../../../state/types/TaskCentreCases";
import { IVisitDetail } from "../../../../state/types/VisitDetails";
import { IReviewResponseTemplate } from "../../../../state/types/VisitReport";
import {
  textSubstitution,
  textSubstituionKey,
  ApplyTextSubstitutions,
} from "../../../../utils/textManipulations";

type props = {
  guestContactDetails?: IGuestDetails;
  currentVisit?: IVisitDetail;
  brandName?: string;
  currentUser: string;
  caseDetails?: ICaseDetails;
  templatesList: IReviewResponseTemplate[];
};
export const templateTextFormat = (props: props) => {
  const guestName =
    props.guestContactDetails?.name.fullName ||
    i18n.translate("TASK_CENTRE_GUEST_CONTACT_NAME_FALLBACK");
  const substitutions: textSubstitution[] = [
    {
      key: textSubstituionKey.ReviewAuthor,
      value: guestName,
    },
    {
      key: textSubstituionKey.BranchName,
      value:
        props.currentVisit?.branch ||
        i18n.translate("TASK_CENTRE_GUEST_CONTACT_BRANCH_NAME_FALLBACK"),
    },
    {
      key: textSubstituionKey.BrandOrClientName,
      value: props.brandName,
    },
    {
      key: textSubstituionKey.ManagerName,
      value: props.currentUser,
    },
    {
      key: textSubstituionKey.TaskId,
      value: `${props.caseDetails?.id}`,
    },
  ];
  return props.templatesList.map((templateObject) => {
    const formattedText = ApplyTextSubstitutions(
      templateObject.template,
      substitutions
    );
    return { ...templateObject, template: formattedText };
  });
};
