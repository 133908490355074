import { Button } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../localizations/i18n";
import {
  downloadVisitReportPdf,
  fetchVisitReportHtml,
} from "../../../state/actions/VisitReport-Actions";
import { VisitReportContent } from "../state/selectors";
import { PandoraReportStyles } from "../styles/pandoraReportStyles";

interface IProps {
  visitId: number;
  minContainerWidth: number;
}

const PandoraReport = (props: IProps) => {
  const classes = PandoraReportStyles();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const reportContent = useSelector(VisitReportContent);
  const [containerWidth, setContainerWidth] = useState(0);

  const downloadVisitReport = () => {
    dispatch(downloadVisitReportPdf(props.visitId));
  };

  useEffect(() => {
    if (
      containerWidth > 0 &&
      props.visitId &&
      props.visitId !== reportContent?.visitId
    ) {
      dispatch(fetchVisitReportHtml(props.visitId));
    }
  }, [containerWidth, dispatch, props.visitId, reportContent]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const width =
        containerRef.current.clientWidth > props.minContainerWidth
          ? containerRef.current.clientWidth
          : props.minContainerWidth;

      setContainerWidth(width - 10);
    }
  }, [props.minContainerWidth]);

  return (
    <div ref={containerRef} className={classes.container}>
      {reportContent?.htmlContent && reportContent.htmlContent.length > 0 && (
        <>
          <div className={classes.downloadContainer}>
            <Button
              className={classes.downloadButton}
              size="small"
              variant="outlined"
              onClick={downloadVisitReport}
            >
              {i18n.translate("VISIT_DETAILS_REPORT_PDF_Download")}
            </Button>
          </div>
          <div
            className={classes.frame}
            dangerouslySetInnerHTML={{ __html: reportContent.htmlContent }}
          ></div>
        </>
      )}
    </div>
  );
};

export default PandoraReport;
