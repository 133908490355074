import React from "react";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import i18n from "../../../localizations/i18n";
import { VisitListErrorStyles } from "../styles/visitListErrorStyles";

const VisitListError = () => {
  const classes = VisitListErrorStyles();

  return (
    <div className={classes.errorContainer}>
      <Alert
        elevation={4}
        variant="filled"
        severity="warning"
        action={
          <Button
            className={classes.errorTask}
            onClick={() => {
              window.location.reload();
            }}
          >
            {i18n.translate("VISIT_DETAILS_LIST_Refresh_Page")}
          </Button>
        }
      >
        {i18n.translate("VISIT_DETAILS_LIST_Error_Message")}
      </Alert>
    </div>
  );
};

export default VisitListError;
