import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConversationListStyles } from "./styles/conversationListStyles";
import { Skeleton } from "@material-ui/lab";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import dayjs from "dayjs";
import {
  clearConversationsList,
  fetchConversationsList,
  selectConversation,
} from "../../state/actions/Conversations-Actions";
import {
  ConversationStates,
  SelectedConversationId,
  InitialConversationsListLoaded,
  MoreConversationsAvailable,
  ConversationsListPending,
} from "../../state/selectors/Conversations-Selectors";
import InfiniteScrollWindow from "../_common/scrollWindow/InfiniteScrollWindow";
import { i18n } from "../../localizations";
import {
  ConversationCard,
  ConversationListEmpty,
  ConversationListSkeleton,
} from "./components";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import { formatSender } from "./utils";
interface IProps {
  pageRef: string;
  listHeightOffset?: number;
  showAsPopupList?: boolean;
}

const ConversationList = (props: IProps) => {
  const classes = ConversationListStyles();

  const queryConversationId = getUrlQueryValueNumber("conversationid");

  const dispatch = useDispatch();

  const [popupListOpen, setPopupListOpen] = useState(false);

  const conversationStates = useSelector(ConversationStates);
  const selectedConversationId = useSelector(SelectedConversationId);
  const initialConversationsListLoaded = useSelector(
    InitialConversationsListLoaded
  );
  const moreConversationsAvailable: boolean = useSelector(
    MoreConversationsAvailable
  );
  const conversationsPending = useSelector(ConversationsListPending);

  const loadConversations = (): void => {
    dispatch(
      fetchConversationsList({
        conversationId: queryConversationId,
      })
    );
  };

  useEffect(() => {
    if (!initialConversationsListLoaded) {
      loadConversations();
    }
  }, [initialConversationsListLoaded, loadConversations]);

  useEffect(() => {
    if (initialConversationsListLoaded) {
      dispatch(clearConversationsList());

      loadConversations();
    }
  }, [queryConversationId]);

  const refs = props.showAsPopupList
    ? conversationStates.reduce(
        (acc: { [_: number]: React.RefObject<HTMLDivElement> }, value) => {
          acc[value.conversation.conversationId] = React.createRef();
          return acc;
        },
        {} as { [_: number]: React.RefObject<HTMLDivElement> }
      )
    : ({} as { [_: number]: React.RefObject<HTMLDivElement> });

  const scrollToSelected = (): void => {
    if (selectedConversationId && refs) {
      refs[selectedConversationId].current?.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const conversationList = (): JSX.Element => {
    return (
      <InfiniteScrollWindow
        scrollCallback={loadConversations}
        shouldFetchMore={moreConversationsAvailable}
        isLoading={conversationsPending}
      >
        <div>
          {conversationsPending && conversationStates.length === 0 && (
            <ConversationListSkeleton />
          )}
          {!conversationsPending && conversationStates.length === 0 && (
            <ConversationListEmpty />
          )}
          {conversationStates.map((conversationState) => {
            return (
              <div
                ref={refs[conversationState.conversation.conversationId]}
                key={conversationState.conversation.conversationId}
              >
                <ConversationCard
                  conversation={conversationState.conversation}
                  onItemSelected={(conversationId) =>
                    dispatch(selectConversation(conversationId))
                  }
                  isSelected={
                    conversationState.conversation.conversationId ===
                    selectedConversationId
                  }
                />
              </div>
            );
          })}
        </div>
      </InfiniteScrollWindow>
    );
  };

  const popupSelectorLabel = () => {
    let labelText = "n/a";

    const selectedConversationState = conversationStates.find(
      (x) => x.conversation.conversationId === selectedConversationId
    );

    if (!conversationsPending && selectedConversationState) {
      labelText = `${formatSender(
        selectedConversationState.conversation.sender,
        selectedConversationState.conversation.source
      )} - ${dayjs(
        selectedConversationState.conversation.mostRecentMessageCreatedDate
      ).format(i18n.translate("CONVERSATION_MESSAGE_DATE_TIME_FORMAT_LONG"))}`;
    }

    return labelText;
  };

  const conversationListInitialised =
    !conversationsPending || conversationStates.length !== 0;

  return (
    <div className={classes.listContainer}>
      {props.showAsPopupList && conversationListInitialised && (
        <Button
          className={classes.popupSelection}
          onClick={() => setPopupListOpen(true)}
        >
          <>
            <span>
              {i18n.translate("CONVERSATION_SELECTED")}
              <div className={classes.popupSelectionHint}>
                {popupSelectorLabel()}
              </div>
            </span>
          </>
        </Button>
      )}

      {props.showAsPopupList && !conversationListInitialised && (
        <>
          <Skeleton
            className={classes.loader}
            variant="rect"
            height={40}
            animation="wave"
          />
        </>
      )}

      {props.showAsPopupList && (
        <Dialog
          className={classes.popupSelectionDialog}
          fullWidth
          onClose={() => setPopupListOpen(false)}
          onEntered={() => scrollToSelected()}
          open={popupListOpen}
        >
          <DialogContent>{conversationList()}</DialogContent>
        </Dialog>
      )}

      {!props.showAsPopupList && conversationList()}
    </div>
  );
};

export default ConversationList;
