import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import { ICaseCategory } from "../../../state/types/TaskCentreCases";

export const CaseListPending = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.pending;
  }
);

export const AllManagers = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.managers;
  }
);

export const AllCategories = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.categories.map((x) => {
      return {
        id: x.id,
        name: x.name,
      } as ICaseCategory;
    });
  }
);
