import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { i18n } from "../../../../localizations";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFile: File | undefined;
  onFileSelected?: (
    file: File
  ) => void | Promise<void> | (() => void | Promise<void>);
};

const AttachmentFileSelectConfirmDialog = (props: Props) => {
  const { isOpen, setIsOpen, selectedFile, onFileSelected } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {i18n.translate("ATTACHMENT_UPLOAD_ATTACHMENT")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "inherit" }}
        >
          {i18n.translate("ATTACHMENT_CONFIRM_UPLOAD")} "{selectedFile?.name}"?
        </DialogContentText>
      </DialogContent>
      {selectedFile && (
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false);
              if (onFileSelected) {
                onFileSelected(selectedFile);
              }
            }}
            autoFocus
          >
            {i18n.translate("ATTACHMENT_UPLOAD")}
          </Button>
          <Button onClick={() => setIsOpen(false)}>
            {i18n.translate("ATTACHMENT_CANCEL")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AttachmentFileSelectConfirmDialog;
