import React, { useState } from "react";
import { Icon } from "@material-ui/core";
import { VisitAudioStyles } from "../styles/visitAudioStyles";
import VisitAudioModal from "../components/visitAudioModal";
import i18n from "../../../localizations/i18n";

interface IProps {
  visitId: number;
  fileName: string;
}

const VisitAudio = (props: IProps) => {
  const classes = VisitAudioStyles();

  const [modalOpen, setModalOpen] = useState(false);

  const openAudio = () => {
    setModalOpen(true);
  };

  const closeAudio = () => {
    setTimeout(() => {
      setModalOpen(false);
    }, 100);
  };

  return (
    <div className={classes.container} onClick={openAudio}>
      <Icon className={`fas fa-play-circle ${classes.audioPending}`} />
      <span className={classes.audioPendingLabel}>
        {i18n.translate("VISIT_DETAILS_AUDIO_PlayAudio")}
      </span>

      {modalOpen && (
        <VisitAudioModal
          fileName={props.fileName}
          visitId={props.visitId}
          onClose={closeAudio}
        />
      )}
    </div>
  );
};

export default VisitAudio;
