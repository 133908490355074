import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";

const imageExtensions = [".jpg", ".jpeg", ".bmp", ".png"];
const audioExtensions = [".mp3", ".wav", ".aac", ".m4a", ".wma"];

export const AllPhotoPaths = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (_: RootState, visitId: number | undefined) => visitId,
  (state, visitId) => {
    if (visitId) {
      const visitFiles = state.visitMediaEntries.find(
        (x) => x.visitId === visitId
      );

      return visitFiles
        ? visitFiles.fileNames.filter((x) => {
            return (
              imageExtensions.find((ext) => x.toLowerCase().endsWith(ext)) !==
              undefined
            );
          })
        : [];
    } else {
      return undefined;
    }
  }
);

export const AllAudioPaths = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (_: RootState, visitId: number | undefined) => visitId,
  (state, visitId) => {
    if (visitId) {
      const visitFiles = state.visitMediaEntries.find(
        (x) => x.visitId === visitId
      );

      return visitFiles
        ? visitFiles.fileNames.filter((x) => {
            return (
              audioExtensions.find((ext) => x.toLowerCase().endsWith(ext)) !==
              undefined
            );
          })
        : [];
    } else {
      return undefined;
    }
  }
);

export const AllReceiptPaths = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (_: RootState, visitId: number | undefined) => visitId,
  (state, visitId) => {
    if (visitId) {
      return state.visitReceiptEntries.find((x) => x.visitId === visitId);
    } else {
      return undefined;
    }
  }
);
