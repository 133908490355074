import { MessageCore, MessageProps } from ".";

const DefaultMessage = (props: MessageProps) => {
    return <MessageCore
        conversationId={props.conversationId}
        message={props.message}
        messageHeader={props.messageHeader}
        content={<>{props.message.messageText}</>} />;
}

export default DefaultMessage;