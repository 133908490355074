import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
    videoContainer: {
        height: "300px"
    },
    video: {
        maxHeight: "300px"
    },
    imageContainer: {
        height: "300px"
    },
    image: {
        maxHeight: "300px"
    }
  }));
  
export const ConversationMessageMediaStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
  