import {
  CaseEventType,
  ICaseDetails,
  IQuestion,
  WorkflowStatus,
} from "../../../state/types/TaskCentreCases";

export const appealCanEdit = (
  appeal: ICaseDetails,
  userId: number | undefined,
  isAdmin: boolean | undefined
) => {
  return (
    (appeal.wfStatus === WorkflowStatus.AppealRaised &&
      (userId === getAppealRaisedEvent(appeal).authorId || isAdmin)) ||
    (appeal.wfStatus === WorkflowStatus.AppealApprovedForReview &&
      ((getAppealApproverEvent(appeal) === undefined &&
        userId === getAppealRaisedEvent(appeal).authorId) ||
        isAdmin)) ||
    (appeal.wfStatus === WorkflowStatus.AppealUnderReview && isAdmin)
  );
};

export const getAppealApproverEvent = (appeal: ICaseDetails) => {
  const events = appeal.events.filter(
    (x) =>
      x.type === CaseEventType.AppealReviewerAssignedEvent &&
      x.assigneeName !== "HGEM"
  );

  return events.length > 0 ? events[events.length - 1] : undefined;
};

export const getAppealBonusAppealOnly = (appeal: ICaseDetails) => {
  const events = appeal.events.filter((x) =>
    [
      CaseEventType.AppealRaisedEvent,
      CaseEventType.AppealBonusAppealOnlySetEvent,
    ].includes(x.type)
  );

  return events[events.length - 1].bonusAppealOnly;
};

export const getAppealRaisedEvent = (appeal: ICaseDetails) => {
  const events = appeal.events.filter(
    (x) => x.type === CaseEventType.AppealRaisedEvent
  );

  return events[0];
};

export const getAppealQuestions = (appeal: ICaseDetails) => {
  return appeal.events
    .filter((x) => x.type === CaseEventType.AppealQuestionAddedEvent)
    .map((x) => x.question)
    .filter((x): x is IQuestion => x !== undefined);
};
