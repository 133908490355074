import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    marginTop: "2%",
    paddingLeft: "1%",
    marginBottom: "1%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "clip",
  },

  caseInfoContainer: {
    marginTop: "2%",
    paddingLeft: "1%",
    marginBottom: "1%",
    display: "flex",
    flexDirection: "column",
  },
  noScroll: {
    overflow: "auto",
  },
  statusField: {
    marginBottom: "15px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "98%",
      justifyContent: "space-between",
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  titlePrefix: {
    width: "5%",
    marginRight: "5%",
    color: hgemColours.ExtraMediumGrey,
    fontWeight: "bold",
  },
  titleField: {
    width: "55%",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "70%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  accordionSummaryAlignment: {
    alignItems: "baseline",
  },
  editCaseItemInput: {
    backgroundColor: hgemColours.White,
    borderRadius: "4px",
    paddingLeft: "1.5%",
  },
}));

export const EditCaseItemStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
