import { useTheme, makeStyles, Theme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  categoryField: {
    width: "100%",
  },
  categoryLabel: {
    fontSize: "75%",
    color: hgemColours.DarkGrey,
  },
}));

export const CategoryFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
