import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/reducers/RootReducer";
import { CaseEventType } from "../../state/types/TaskCentreCases";
import CaseEventList from "./components/caseEventList";
import {
  AllManagers,
  CurrentUserId,
  EventsByEventType,
} from "./state/selectors";

interface IProps {
  visitId: number;
  caseId: number;
  eventTypes: CaseEventType[];
}

const TaskCentreEvents = (props: IProps) => {
  const currentUserId = useSelector(CurrentUserId);
  const managers = useSelector(AllManagers);

  const events = useSelector((state: RootState) =>
    EventsByEventType(state, props.eventTypes)
  );

  return (
    <>
      <CaseEventList
        visitId={props.visitId}
        caseId={props.caseId}
        caseEvents={events}
        currentUserId={currentUserId ? currentUserId : 0}
        managers={managers}
      />
    </>
  );
};

export default TaskCentreEvents;
