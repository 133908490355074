import React from "react";
import {
  CaseEventType,
  ICaseEvent,
} from "../../../state/types/TaskCentreCases";
import {
  Accordion,
  AccordionSummary,
  Icon,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import dayjs from "dayjs";
import { i18n } from "../../../localizations";
import { GuestContactStyles } from "../styles/guestContactStyles";
import { MessageReceivedStyles } from "../../taskCentreEvents/styles/messageReceivedStyles";
import { MessageSentStyles } from "../../taskCentreEvents/styles/messageSentStyles";

type props = {
  messageEvent: ICaseEvent;
  isMobileView?: boolean;
  openByDefault: boolean;
};
const GuestContactRow = (props: props) => {
  const { messageEvent, isMobileView, openByDefault } = props;
  const classes = GuestContactStyles();
  const sentMessageClasses = MessageSentStyles();
  const receivedMessageClasses = MessageReceivedStyles();
  const messageClass =
    messageEvent.type === CaseEventType.MessageSentEvent
      ? "messageSent"
      : "messageReceived";
  const messageStyleClasses =
    messageEvent.type === CaseEventType.MessageSentEvent
      ? sentMessageClasses
      : receivedMessageClasses;
  const iconType =
    messageEvent.type === CaseEventType.MessageSentEvent
      ? "fas fa-reply"
      : "fas fa-inbox";
  const openOnLoad = openByDefault || !isMobileView;
  return (
    <>
      <Accordion
        key={messageEvent.id}
        className={`${classes.accordionRow}`}
        defaultExpanded={openOnLoad}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className={`${messageStyleClasses.accordionSummary}`}>
            <span>
              <Icon className={`${iconType} ${classes.inboxIcon}`} />
            </span>
            <div className={classes.titleContainer}>
              <div className={classes.messageSubject}>
                <span style={{ fontWeight: 900 }}>
                  {messageEvent.authorName} |{" "}
                </span>
                <span className={classes.dateLine}>
                  {" "}
                  {dayjs(messageEvent.timestamp).format(
                    i18n.translate("TASK_CENTRE_Timestamp_Format")
                  )}{" "}
                </span>
                | {messageEvent.subject}
              </div>
            </div>
          </span>
        </AccordionSummary>
        <AccordionDetails
          style={{ padding: "8px 16px 0 16px" }}
          className={classes[messageClass]}
        >
          <div className={messageStyleClasses.messageSentContainer}>
            <span className={messageStyleClasses.message}>
              <div className={messageStyleClasses.content}>
                <div className={messageStyleClasses.messageContent}>
                  <div className={messageStyleClasses.messageText}>
                    {messageEvent.message?.replace(/\u00a0/g, " ")}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GuestContactRow;
