import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up("lg")]: {
      flexShrink: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    color: theme.palette.text.primary,
  },
  drawerPaper: {
    overflowY: "auto",
    overflowX: "clip",
    [theme.breakpoints.up("lg")]: {
      width: "13.5%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
    backgroundColor: theme.hubSettings.navigation.backgroundColor,
    background: theme.hubSettings.navigation.background,
    animation: "fadeIn-Generic 0.5s",
  },
  hubToolbar: {
    [theme.breakpoints.up("lg")]: {
      boxShadow: "none",
      minHeight: "70px;",
      backgroundColor: "transparent",
      backgroundPosition: "40% center",
      backgroundSize: theme.hubSettings.logo.size,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "50px;",
      backgroundColor: theme.hubSettings.navigation.backgroundColor,
      backgroundPosition: "center center",
      backgroundSize: theme.hubSettings.logo.mobileSize,
    },
    backgroundImage: theme.hubSettings.logo.path,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5%",
      backgroundPosition: "25%",
      backgroundSize: "90px",
    },
  },
  navContainer: {
    height: "calc(100vh - 101px)",

    "&& ul": {
      padding: 0,
    },
  },
  navOptionListNested: {
    marginLeft: "20px",
    overflowX: "scroll",
    overflowY: "clip",
  },
}));

export const NavigationPanelStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
