import i18n from "../../../localizations/i18n";
import { Typography } from "@material-ui/core";
import { AppealsStyles } from "../styles";
import { AppealWorkflowStatuses } from "../../../state/types/TaskCentreCases";
interface Props {
  status?: number;
}

const AppealStatus = (props: Props) => {
  const classes = AppealsStyles();
  const status =
    AppealWorkflowStatuses.find((status) => status.value === props.status)
      ?.text || i18n.translate("APPEALS_STATUS_NEW");
  return (
    <Typography className={`${classes.staticTextBlock}`}>
      <span className={classes.boldText}>
        {i18n.translate("APPEALS_STATUS_HEADER")}
      </span>
      {status}
    </Typography>
  );
};

export default AppealStatus;
