import React, { useState } from "react";
import {
  MenuItem,
  Button,
  Menu,
  useTheme,
  Theme,
  makeStyles,
} from "@material-ui/core";
import dayjs from "dayjs";
import GenericTreeView from "../genericTreeView/genericTreeView";
import { IClientPeriod } from "../../../state/types/FilterOptions";
import { VisitDateSelectionType } from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";

interface IProps {
  availablePeriods: IClientPeriod[];
  selectedPeriods: string[];
  onCancel: () => void;
  onApply: (selection: string[], selectionType: VisitDateSelectionType) => void;
}

interface IPresetOption {
  option: VisitDateSelectionType;
  matchedPeriods: string[];
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  quickOptionsContainer: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  quickOptionsButton: {
    margin: "5px",
  },
}));

const PeriodPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [selectedPreset, setSelectedPreset] = useState(
    VisitDateSelectionType.ClientPeriods
  );

  const [selectedPeriods, setSelectedPeriods] = useState(props.selectedPeriods);

  const handlePresetSelection = (selection: VisitDateSelectionType): void => {
    setSelectedPreset(selection);

    const options = populatePresetOptions();
    const matchedSelectionEntry = options.find((o) => o.option === selection);

    if (matchedSelectionEntry) {
      const s = matchedSelectionEntry.matchedPeriods.map((p) => {
        return `${p}`;
      });

      setSelectedPeriods(s);
    }

    handleQuickOptionsMenuClose();
  };

  const populatePresetOptions = () => {
    const now = dayjs();
    const orderedPeriods = props.availablePeriods.sort((a, b) => {
      return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
    });

    const completedPeriods = orderedPeriods.filter((p) => {
      return dayjs(p.endDate).isBefore(now);
    });

    const presetOptions: IPresetOption[] = [];
    const mostRecentIsActive = dayjs(orderedPeriods[0].endDate).isAfter(now);

    if (mostRecentIsActive) {
      presetOptions.push({
        option: VisitDateSelectionType.CurrentPeriod,
        matchedPeriods: [orderedPeriods[0].periodName],
      } as IPresetOption);
    }

    if (completedPeriods.length > 0) {
      presetOptions.push({
        option: VisitDateSelectionType.LastCompletePeriod,
        matchedPeriods: [completedPeriods[0].periodName],
      } as IPresetOption);
    }

    if (completedPeriods.length > 1) {
      presetOptions.push({
        option: VisitDateSelectionType.LastTwoCompletePeriods,
        matchedPeriods: [
          completedPeriods[0].periodName,
          completedPeriods[1].periodName,
        ],
      } as IPresetOption);
    }

    if (completedPeriods.length > 2) {
      presetOptions.push({
        option: VisitDateSelectionType.LastThreeCompletePeriods,
        matchedPeriods: [
          completedPeriods[0].periodName,
          completedPeriods[1].periodName,
          completedPeriods[2].periodName,
        ],
      } as IPresetOption);
    }

    const currentYearPeriods = orderedPeriods.filter((o) => {
      return o.periodYearName === orderedPeriods[0].periodYearName;
    });

    if (currentYearPeriods.length > 0) {
      presetOptions.push({
        option: VisitDateSelectionType.CurrentYearToDate,
        matchedPeriods: currentYearPeriods.map((p) => p.periodName),
      } as IPresetOption);
    }

    const years = orderedPeriods
      .filter((p) => p.periodYearName !== orderedPeriods[0].periodYearName)
      .map((x) => x.periodYearName);

    const previousYearPeriods =
      years.length > 0
        ? orderedPeriods.filter((x) => x.periodYearName === years[0])
        : [];

    if (previousYearPeriods.length > 0) {
      presetOptions.push({
        option: VisitDateSelectionType.PreviousYear,
        matchedPeriods: previousYearPeriods.map((p) => p.periodName),
      } as IPresetOption);
    }

    return presetOptions;
  };

  const [quickOptionsMenuAnchor, setQuickOptionsMenuAnchor] = React.useState<
    HTMLButtonElement | undefined
  >(undefined);

  const handleQuickOptionsMenuOpen = (target: HTMLButtonElement) => {
    setQuickOptionsMenuAnchor(target);
  };

  const handleQuickOptionsMenuClose = () => {
    setQuickOptionsMenuAnchor(undefined);
  };

  const presetOptions = populatePresetOptions();

  const nodeSelectionChanged = () => {
    setSelectedPreset(VisitDateSelectionType.ClientPeriods);
  };

  const applyPeriodSelection = (selection: string[]) => {
    props.onApply(selection, selectedPreset);
  };

  return (
    <>
      <div className={classes.quickOptionsContainer}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.quickOptionsButton}
          onClick={(e) => handleQuickOptionsMenuOpen(e.currentTarget)}
        >
          {i18n.translate("PERIOD_PICKER_Preset_Selections")}
        </Button>
        <Menu
          anchorEl={quickOptionsMenuAnchor}
          keepMounted
          open={Boolean(quickOptionsMenuAnchor)}
          onClose={handleQuickOptionsMenuClose}
        >
          {presetOptions.map((o) => {
            return (
              <MenuItem
                key={o.option}
                value={o.option}
                onClick={() => handlePresetSelection(o.option)}
              >
                {i18n.translate(
                  `PERIOD_PICKER_OPTION_${VisitDateSelectionType[
                    o.option
                  ].toString()}`
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </div>

      <GenericTreeView
        filterPlaceholderText={i18n.translate(
          "PERIOD_PICKER_OPTION_Filter_Periods"
        )}
        selectedNodes={selectedPeriods}
        nodeOptions={props.availablePeriods}
        nodeSequence={["periodYearName", "periodName"]}
        expandAllByDefault={true}
        applySelectionCallback={applyPeriodSelection}
        cancelSelectionCallback={props.onCancel}
        nodeSelectedCallback={nodeSelectionChanged}
        leafNodeTooltipProperty="displayDate"
        sortNodes={false}
      />
    </>
  );
};

export default PeriodPicker;
