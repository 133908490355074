import {
  IGetCasesQuery,
  SortOrder,
  SortBy,
  IDateRange,
} from "../../../state/types/TaskCentreCaseQuery";
import { IHubFilter, HubFilterType } from "../../../state/types/FilterSets";
import {
  convertNpsClassificationToScores,
  getFilterSelection,
  IFilterDates,
  IFilterScoreRange,
} from "../../../utils/hubFilterReader";

const BuildCaseListQuery = (
  pageNumber: number,
  hubFilters: IHubFilter[],
  selectedBrands: string[],
  defaultVisitTypes: string[],
  requestedVisitId: number | undefined
): IGetCasesQuery => {
  const visitSources = getFilterSelection(
    HubFilterType.VisitSource,
    hubFilters
  ) as number[];
  const questionnaireTypes = getFilterSelection(
    HubFilterType.QuestionnaireType,
    hubFilters
  ) as string[];
  const filteredLocationIds = getFilterSelection(
    HubFilterType.Locations,
    hubFilters
  ) as number[];
  const filteredDates = getFilterSelection(
    HubFilterType.VisitDate,
    hubFilters
  ) as IFilterDates;
  const filteredTaskStatuses = getFilterSelection(
    HubFilterType.TaskCentreStatus,
    hubFilters
  ) as number[];
  const filteredTaskPriorities = getFilterSelection(
    HubFilterType.TaskCentrePriority,
    hubFilters
  ) as number[];
  const filteredTaskCategories = getFilterSelection(
    HubFilterType.TaskCentreCategory,
    hubFilters
  ) as number[];
  const filteredFbCategories = getFilterSelection(
    HubFilterType.FeedbackCategory,
    hubFilters
  ) as string[];
  const filteredFbSubCategories = getFilterSelection(
    HubFilterType.FeedbackSubCategory,
    hubFilters
  ) as string[];
  const filteredDays = getFilterSelection(
    HubFilterType.DayOfWeek,
    hubFilters
  ) as string[];
  const filteredAssignees = getFilterSelection(
    HubFilterType.TaskAssignee,
    hubFilters
  ) as number[];
  const filteredContactStatus = getFilterSelection(
    HubFilterType.VisitContactStatus,
    hubFilters
  ) as string;

  const filteredVisitScores = getFilterSelection(
    HubFilterType.VisitScoreRange,
    hubFilters,
    null
  ) as IFilterScoreRange;
  const filteredNpsClassification = getFilterSelection(
    HubFilterType.NPS,
    hubFilters
  ) as string[];
  // const filteredDishNames = getFilterSelection(
  //   HubFilterType.DishFilters,
  //   hubFilters
  // ) as number[];
  let visitTypes = getFilterSelection(
    HubFilterType.VisitType,
    hubFilters
  ) as string[];

  if (!visitTypes || visitTypes.length === 0) {
    visitTypes = defaultVisitTypes;
  }

  let dates: IDateRange | null = null;
  let periods: string[] = [];
  let periodHierarchies: number[] = [];

  if (
    filteredDates.periodHierarchies &&
    filteredDates.periodHierarchies.length > 0
  ) {
    periodHierarchies = filteredDates.periodHierarchies;
  } else if (filteredDates.periods && filteredDates.periods.length > 0) {
    periods = filteredDates.periods;
  } else {
    dates = {
      start: filteredDates.startDate,
      end: filteredDates.endDate ?? new Date(),
    };
  }

  const batchValue = process.env.REACT_APP_TASK_CENTRE_LIST_BATCH_SIZE;
  const batchSize = Number.parseInt(batchValue ? batchValue : "100");

  const query: IGetCasesQuery = {
    visitId: requestedVisitId,
    categories: filteredTaskCategories,
    priorities: filteredTaskPriorities,
    createdBetween: dates,
    visitPeriods: periods,
    periodHierarchies: periodHierarchies,
    pagination: { itemsPerPage: batchSize, pageIndex: pageNumber },
    sort: { order: SortOrder.Descending, property: SortBy.CreatedDate },
    workflowStatuses: filteredTaskStatuses,
    locationIds: filteredLocationIds,
    brands: selectedBrands,
    visitTypes: visitTypes,
    visitSources: visitSources,
    feedbackCategories: filteredFbCategories,
    feedbackSubCategories: filteredFbSubCategories,
    daysOfWeek: filteredDays,
    questionnaireTypes: questionnaireTypes,
    assignees: filteredAssignees,
    contactStatus: filteredContactStatus,
    visitScoreRange: filteredVisitScores,
    npsScores: convertNpsClassificationToScores(filteredNpsClassification),
    // dishes: filteredDishNames,
  };

  return query;
};

export default BuildCaseListQuery;
