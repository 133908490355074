import { hgemColours } from "../themes/defaultTheme";
import { Theme, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  lightGreyText: {
    color: `${hgemColours.MediumGrey}`,
  },
  lighterGreyText: {
    color: `${hgemColours.LighterGrey}`,
  },
  darkGreyText: {
    color: `${hgemColours.DarkGrey}`,
  },
  validationErrorSpan: {
    color: `${hgemColours.Red}`,
    fontSize: "1em",
  },
}));

export const CommonStyleClasses = () => {
  const theme = useTheme();
  return useStyles(theme);
};
