import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { i18n } from "../../../../localizations";
import { IMinimalCaseEventAttachment } from "../../../../state/types/TaskCentreCases";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attachment: IMinimalCaseEventAttachment;
  deleteAttachment: () => Promise<void>;
};

const AttachmentDeleteDialog = (props: Props) => {
  const { isOpen, setIsOpen, attachment, deleteAttachment } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {i18n.translate("ATTACHMENT_DELETE_ATTACHMENT")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "inherit" }}
        >
          {i18n.translate("ATTACHMENT_CONFIRM_DELETE")} "{attachment.filepath}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsOpen(false);
            deleteAttachment();
          }}
          autoFocus
        >
          {i18n.translate("ATTACHMENT_DELETE")}
        </Button>
        <Button onClick={() => setIsOpen(false)}>
          {i18n.translate("ATTACHMENT_CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentDeleteDialog;
