import {
  GoogleIcon,
  FacebookIcon,
  InstagramIcon,
  TripadvisorIcon,
  BookingComIcon,
  DeliverooIcon,
  ExpediaIcon,
  OpenTableIcon,
  TrustPilotIcon,
  YelpIcon,
  DesgingMyNightIcon,
  uberEatsIcon,
  hotelsComIcon,
  travelocityIcon,
  justEatIcon,
  orbitzIcon,
  commentArrowUpRight,
  commentSlashRegular,
} from "../../../assets";

export const customIconMap = {
  google: GoogleIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  tripadvisor: TripadvisorIcon,
  booking: BookingComIcon,
  "booking.com": BookingComIcon,
  deliveroo: DeliverooIcon,
  "deliveroo reviews": DeliverooIcon,
  expedia: ExpediaIcon,
  opentable: OpenTableIcon,
  trustpilot: TrustPilotIcon,
  yelp: YelpIcon,
  "design my night": DesgingMyNightIcon,
  "uber eats": uberEatsIcon,
  hotels: hotelsComIcon,
  travelocity: travelocityIcon,
  "just eat": justEatIcon,
  orbitz: orbitzIcon,
  sentemail: commentArrowUpRight,
  stop: commentSlashRegular,
};
