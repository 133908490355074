import React, { useState } from "react";
import { getTaskEventAttachmentApi } from "../../../state/api/TaskCentre-API";
import { useClientId } from "../../../state/hooks/clients/useClientId";
import {
  ICaseEvent,
  ICaseEventAttachment,
} from "../../../state/types/TaskCentreCases";
import {
  MediaMessageContent,
  MediaMessageSideContent,
  useMedia,
} from "../../inboxViews/components/mediaMessages";
import { MediaLinks } from "../../inboxViews/components/mediaMessages/types/mediaLinks";
import { CommentStyles } from "../styles/commentStyles";

interface IProps {
  visitId: number;
  caseId: number;
  caseEvent?: ICaseEvent;
  caseEventAttachment: ICaseEventAttachment | undefined;
  currentUser: number;
  eventHeader: React.ReactElement;
  comment?: string;
}

const Comment = (props: IProps) => {
  const classes = CommentStyles();
  const commentSourceClass =
    props.caseEvent?.authorId === props.currentUser
      ? "my-comment"
      : "other-comment";

  return (
    <div className={`${classes.container} ${commentSourceClass}`}>
      {props.eventHeader}
      {props.caseEventAttachment ? (
        <CommentAttachment
          visitId={props.visitId}
          caseId={props.caseId}
          caseEventEventAttachmentId={props.caseEventAttachment.id}
          filepath={props.caseEventAttachment.filepath}
          authorId={props.caseEvent?.authorId}
          currentUser={props.currentUser}
        />
      ) : (
        <CommentMessage comment={props.caseEvent?.comment || props.comment} />
      )}
    </div>
  );
};

type CommentMessageProps = {
  comment: string | undefined;
};

const CommentMessage = (props: CommentMessageProps) => {
  const classes = CommentStyles();

  return (
    <div>
      <span className={`${classes.commentText} comment`}>{props.comment}</span>
    </div>
  );
};

type CommentAttachmentProps = {
  visitId: number;
  caseId: number;
  caseEventEventAttachmentId: number;
  filepath: string;
  authorId?: number;
  currentUser: number;
};

const CommentAttachment = (props: CommentAttachmentProps) => {
  const classes = CommentStyles();

  const clientId = useClientId();

  const [mediaLinks, setMediaLinks] = useState<MediaLinks | undefined>();
  const [isDownloading, setIsDownloading] = useState(false);

  const media = useMedia({
    filename: props.filepath,
    getMedia: clientId
      ? () =>
          getTaskEventAttachmentApi(
            clientId,
            props.visitId,
            props.caseId,
            props.caseEventEventAttachmentId,
            props.filepath
          )
      : undefined,
    mediaLinks: [mediaLinks, setMediaLinks],
    isDownloading: [isDownloading, setIsDownloading],
  });

  return (
    <div className={classes.conversationsFlexRow}>
      <span className={`${classes.commentText} media comment`}>
        <MediaMessageContent
          shouldRenderMedia={media.shouldRenderMedia}
          filename={props.filepath}
          contentType={media.contentType}
          download={() => setIsDownloading(true)}
          mediaLinks={media.links}
        />
      </span>
      <span
        className={
          props.authorId === props.currentUser
            ? classes.outBound
            : classes.inBound
        }
      >
        <MediaMessageSideContent
          isDownloading={isDownloading}
          download={() => setIsDownloading(true)}
        />
      </span>
    </div>
  );
};

export default Comment;
