import { useEffect, useState } from "react";
import { AuthServiceInstance } from "../../../authentication/authService";

export const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (!isAdmin) {
      AuthServiceInstance.isAdmin().then((isAdmin) => {
        setIsAdmin(isAdmin);
      });
    }
  }, [isAdmin, setIsAdmin]);

  return isAdmin;
};
