import React, { useState, useEffect } from "react";
import { Logging, SeverityLevel } from "../../../utils/logging";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { IVisualExportTarget } from "../utils/pbiReportRendering";
import { ReportDataExportStyles } from "../styles/reportDataExportStyles";

export interface IProps {
  sourceVisuals: IVisualExportTarget[] | undefined;
  onClose: () => void;
  runExport: (visualName: string) => Promise<string> | undefined;
}

enum ExportDataVisualState {
  noTargetVisuals,
  singleTargetVisual,
  multipleTargetVisuals,
}

const ReportDataExport = (props: IProps) => {
  const classes = ReportDataExportStyles();

  const exportTimestamp = dayjs().format(
    i18n.translate("REPORT_DATA_EXPORT_FileDateFormat")
  );
  const [visuals, setVisuals] = useState([] as IVisualExportTarget[]);
  const [selectedVisual, setSelectedVisual] = useState("");
  const [fileName, setFileName] = useState(
    `Hub_Data_Export__${exportTimestamp}`
  );

  const closeDialog = () => {
    setVisuals([]);
    props.onClose();
  };

  const handleFilenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const fileNameContainsErrors = (): boolean => {
    const invalidCharRegex = /[^a-zA-Z0-9_-]/;
    return fileName.length === 0 || invalidCharRegex.test(fileName);
  };

  const handleSelectedVisualChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    const matchedSource = props.sourceVisuals?.find(
      (x) => x.visualName === value
    );

    if (matchedSource) {
      setSelectedVisual(matchedSource.visualName);
    }
  };

  const handleExport = () => {
    props
      .runExport(selectedVisual)
      ?.then((data: string) => {
        saveFile(data);
      })
      .catch(() => {
        console.log("Failed to export data");
      });
  };

  const saveFile = (data: string) => {
    try {
      if (data.length > 0) {
        const fullFilename = `${fileName}.csv`;

        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        saveAs(blob, fullFilename);

        Logging.captureEvent("ReportExportSuccess", fullFilename);
      }
    } catch (e) {
      Logging.captureError(
        "ReportExportErrorSavingFile",
        e,
        SeverityLevel.Error
      );
    }
  };

  useEffect(() => {
    if (props.sourceVisuals) {
      setVisuals(props.sourceVisuals);

      if (props.sourceVisuals.length > 0) {
        setSelectedVisual(props.sourceVisuals[0].visualName);
      }
    }
  }, [props.sourceVisuals]);

  const exportVisualState: ExportDataVisualState =
    visuals.length === 0
      ? ExportDataVisualState.noTargetVisuals
      : visuals.length > 1
      ? ExportDataVisualState.multipleTargetVisuals
      : ExportDataVisualState.singleTargetVisual;

  return (
    <>
      <Dialog onClose={closeDialog} open={true}>
        <DialogContent>
          <DialogTitle className={classes.dialogTitle}>
            {i18n.translate("REPORT_DATA_EXPORT_Export_Data")}
          </DialogTitle>

          {exportVisualState !== ExportDataVisualState.noTargetVisuals && (
            <DialogContentText className={classes.dialogText}>
              {i18n.translate("REPORT_DATA_EXPORT_Exports_Help_Message")}
            </DialogContentText>
          )}

          {exportVisualState ===
            ExportDataVisualState.multipleTargetVisuals && (
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="export-data-source-label">
                {i18n.translate("REPORT_DATA_EXPORT_Source")}
              </InputLabel>
              <Select
                labelId="export-data-source-label"
                label={i18n.translate("REPORT_DATA_EXPORT_Source")}
                id="export-data-source"
                value={selectedVisual}
                onChange={handleSelectedVisualChange}
              >
                {visuals.map((x) => {
                  return (
                    <MenuItem key={x.visualName} value={x.visualName}>
                      {x.visualTitle}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {exportVisualState !== ExportDataVisualState.noTargetVisuals && (
            <FormControl className={classes.formControl}>
              <TextField
                id="export-data-filename"
                label={i18n.translate("REPORT_DATA_EXPORT_File_Name")}
                variant="outlined"
                value={fileName}
                onChange={handleFilenameChange}
                inputProps={{
                  maxLength: "100",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={classes.inputFileExtension}
                      position="end"
                    >
                      {i18n.translate(
                        "REPORT_DATA_EXPORT_File_Extension_Adornment"
                      )}
                    </InputAdornment>
                  ),
                }}
                error={fileNameContainsErrors()}
                helperText={i18n.translate(
                  "REPORT_DATA_EXPORT_FileName_HelpText"
                )}
              />
            </FormControl>
          )}

          {exportVisualState === ExportDataVisualState.noTargetVisuals && (
            <DialogContentText className={classes.dialogTextWarning}>
              {i18n.translate("REPORT_DATA_EXPORT_No_visuals_available")}
            </DialogContentText>
          )}

          <div className={classes.exportTasks}>
            <Button
              variant="contained"
              onClick={handleExport}
              className={classes.button}
              disabled={fileNameContainsErrors() || visuals.length === 0}
            >
              {i18n.translate("REPORT_DATA_EXPORT_Export")}
            </Button>
            <Button onClick={closeDialog} className={classes.button}>
              {i18n.translate("REPORT_DATA_EXPORT_Cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportDataExport;
