import { grey } from "@material-ui/core/colors";
import { createTheme, lighten } from "@material-ui/core/styles";
import { hgemColours, hgemFont } from "./defaultTheme";

export const wagamamaTheme = createTheme({
  breakpoints: {
    values: {
      xs: 960,
      sm: 1080,
      md: 1160,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: hgemColours.LimeGreen,
    },
    secondary: {
      main: hgemColours.Turquoise,
    },
    background: {
      paper: hgemColours.White,
      default: hgemColours.LightGrey,
    },
    text: {
      primary: hgemColours.DarkGrey,
      secondary: hgemColours.White,
    },
  },

  hubSettings: {
    body: {
      forceLowerCaseText: true,
    },
    logo: {
      path: "URL('/themes/wagamama/satoru.png')",
      position: "0px 12px",
      size: "225px",
      mobileSize: "220px",
      iconColor: hgemColours.DarkGrey,
    },
    navigation: {
      textColor: hgemColours.DarkGrey,
      backgroundColor: hgemColours.White,
      background:
        "linear-gradient(90deg, rgba(233,233,233,1) 0%, rgba(238,238,238,1) 20%, rgba(255,255,255,1) 100%)",
      optionBackgroundColor: "rgba(59,65,69, 0.05)",
      optionBackgroundColorHover: "rgba(59,65,69, 0.08)",
    },
  },

  // Component Global Overrides
  overrides: {
    MuiPaper: {
      root: {
        textTransform: "lowercase",
      },
    },

    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },

    // Button
    MuiButton: {
      root: {
        textTransform: "none",
      },

      contained: {
        boxShadow: "none",
        backgroundColor: hgemColours.LimeGreen,
        color: hgemColours.White,

        "&:hover": {
          backgroundColor: hgemColours.DarkGrey,
          boxShadow: "none",
        },
      },

      containedPrimary: {
        backgroundColor: hgemColours.LimeGreen,
        color: hgemColours.White,

        "&:hover": {
          backgroundColor: hgemColours.DarkGrey,
        },
      },

      containedSecondary: {
        color: hgemColours.DarkGrey,
        border: `1px solid ${lighten(hgemColours.Turquoise, 0.5)}`,
        backgroundColor: lighten(hgemColours.Turquoise, 0.8),

        "&:hover": {
          border: `1px solid ${lighten(hgemColours.Turquoise, 0.5)}`,
          backgroundColor: lighten(hgemColours.Turquoise, 0.6),
        },
      },
    },

    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
    },

    // Typography
    MuiTypography: {
      h1: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h2: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h3: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h4: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h5: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h6: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
    },

    // Checkbox
    MuiCheckbox: {
      colorSecondary: {
        color: hgemColours.LimeGreen,
        "&$checked": {
          color: hgemColours.LimeGreen,
        },
      },
    },

    // Dialog
    MuiDialogContent: {
      root: {
        paddingBottom: "20px",
      },
    },
    MuiDialogTitle: {
      root: {
        marginBottom: "20px",
      },
    },

    // TextField
    MuiInput: {
      underline: {
        "&::before": {
          borderColor: "#eaeaea",
        },
      },
    },

    // Tabs
    MuiTab: {
      root: {
        textTransform: "lowercase",
        backgroundColor: grey[50],
        border: `1px solid ${lighten(hgemColours.LightGrey, 0.1)}`,
        borderRadius: "5px",
        marginRight: "5px",
        padding: "10px 10px 5px 10px",
        minHeight: "40px",

        "&&.Mui-disabled": {
          backgroundColor: "transparent",
          opacity: "0.4",
        },
      },
    },

    MuiTabs: {
      root: {
        minHeight: "40px",
      },
      scrollable: {
        overflowX: "hidden",
      },
      scrollButtons: {
        width: "30px",
        transition: "width 300ms",

        "&&.Mui-disabled": {
          width: "0px",
        },
      },
    },

    MuiList: {
      root: {
        "&:focus": {
          outlineColor: hgemColours.LightGrey,
        },
      },
    },
  },
});
