import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { CaseListSkeletonStyles } from "../styles/caseListSkeletonStyles";

const CaseListSkeleton = () => {
  const classes = CaseListSkeletonStyles();

  return (
    <div>
      <Skeleton variant="text" animation="wave" />
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
    </div>
  );
};

export default CaseListSkeleton;
