import axios from "axios";
import { Logging, SeverityLevel } from "../../../utils/logging";

export interface IReviewSourceDetails {
  reviewUrl: string;
  responseUrl: string;
}

export const fetchVisitReportReviewSourceDetailsApi = async (
  clientId: number,
  visitId: number
): Promise<IReviewSourceDetails | null> => {
  let result: IReviewSourceDetails | null = null;

  const response = await axios
    .get<IReviewSourceDetails>(
      `${process.env.REACT_APP_HUB_API_BASEURL}VisitReport/Client/${clientId}/GetReviewSourceDetails/${visitId}`
    )
    .catch((error) => {
      const e = new Error();
      if (error) {
        e.message = error.toString();
      }

      Logging.captureError(
        "fetchVisitReportReviewSourceDetailsApi",
        e,
        SeverityLevel.Error
      );
    });

  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
