import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
    availableNPSValues: string[];
    selectedNPSValues: string[];
    applySelectionCallback: (selectedNPSValues: string[]) => void;
}

const NetPromoterScorePicker = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedNPSValues, setSelectedNPSValues] = useState(
      [] as string[]
    );
  
    const handleToggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const getAppliedSelectionText = (): string => {
      let selectionText = "";
      if (
        selectedNPSValues.length === 0 ||
        selectedNPSValues.length ===
          props.availableNPSValues.length
      ) {
        selectionText = i18n.translate("QUESTION_NPS_PICKER_All_Selected");
      } else if (selectedNPSValues.length === 1) {
        selectionText = selectedNPSValues[0];
      } else {
        selectionText = `${selectedNPSValues.length} ${i18n.translate(
          "QUESTION_NPS_PICKER_Selected"
        )}`;
      }
  
      return selectionText;
    };
  
    const buildTooltipContent = (): string => {
      return selectedNPSValues.join(", ");
    };
  
    const applySelection = (selectedOptions: string[]) => {
      props.applySelectionCallback(selectedOptions);
      setIsOpen(false);
    };
  
    const cancelSelection = () => {
      setIsOpen(false);
    };
  
    useEffect(() => {
      setSelectedNPSValues(props.selectedNPSValues);
    }, [setSelectedNPSValues, props.selectedNPSValues]);
  
    return (
      <FilterWrapper
        label={i18n.translate("QUESTION_NPS_PICKER_NetPromoter")}
        selectionHint={getAppliedSelectionText()}
        isOpen={isOpen}
        toggleOpenState={handleToggleOpen}
        tooltip={buildTooltipContent()}
      >
        <GenericMultiSelect
          useSelectAllOption={false}
          selectAllOptionText={i18n.translate(
            "QUESTION_ANALYSIS_NPS_Select_All_Option"
          )}
          multiSelect={true}
          options={props.availableNPSValues}
          selectedOptions={selectedNPSValues}
          applySelectionCallback={applySelection}
          cancelSelectionCallback={cancelSelection}
          applyLocalisationToOptions={false}
        />
      </FilterWrapper>
    );
}

export default NetPromoterScorePicker;