import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialogText: {
    color: theme.palette.text.primary,
  },
  labelText: {
    marginLeft: "3%",
    marginBottom: "1%",
    color: hgemColours.LighterGrey,
  },
  voucherControls: {
    minWidth: "45%",
    backgroundColor: hgemColours.White,
  },
  voucherInputs: {
    marginTop: "2.5%",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    gap: "10%",
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: "column",
    },
  },
  voucherSelect: { background: hgemColours.White },
  reasonInput: {
    background: hgemColours.White,
    "& label": {
      color: hgemColours.LighterGrey,
    },

    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
  },
  fullWidth: {
    width: "100%",
  },
  voucherDetailsDialog: {
    width: "60%",
  },
  definitionListItem: {
    marginTop: "2.5%",
    width: "33%",
  },
  definitionListDefinition: {
    marginLeft: "auto",
    marginTop: "2.5%",
    width: "66%",
    whiteSpace: "pre-line",
  },
  alignedLabel: {
    marginLeft: "1.3%",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  reasonSpan: {
    fontSize: "0.8em",
    color: `${hgemColours.LighterGrey}`,
  },
}));

export const CardDetailsStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
