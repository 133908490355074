import { Tooltip, Icon, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConversationVisitState } from "../../../state/reducers/Conversations-Reducer";
import { ConversationVisit } from "../../../state/types/Conversations";
import { RelatedConversationsStyles } from "../styles/relatedConversationsStyles";
import { InboxItemStyles } from "../../inboxList/styles/inboxItemStyles";

type Props = {
  conversationVisit: ConversationVisitState;
  relatedConversationVisits: ConversationVisit[];
};

const RelatedConversations = (props: Props) => {
  const conversationClasses = RelatedConversationsStyles();
  const toolTipClasses = InboxItemStyles();
  const { conversationVisit, relatedConversationVisits } = props;
  const [showRelatedVisits, setShowRelatedVisits] = useState<boolean>(false);
  const [relatedVisitList, setRelatedVisitList] = useState<ConversationVisit[]>(
    []
  );

  useEffect(() => {
    const duplicatedVisit = relatedConversationVisits.find(
      (relatedVisit) => relatedVisit.visitId === conversationVisit.visit.visitId
    );
    if (duplicatedVisit) {
      setRelatedVisitList(
        relatedConversationVisits.filter(
          (visit) => visit.visitId !== duplicatedVisit.visitId
        )
      );
      setShowRelatedVisits(true);
    }
    setShowRelatedVisits(false);
  }, [conversationVisit, relatedConversationVisits]);

  const formatDate = (dateString: Date) => {
    return dayjs(dateString, { utc: true }).local().format("ddd DD MMM YY");
  };

  const trimText = (text: string | undefined): string => {
    return `${text?.substring(0, 15)}...`;
  };
  return (
    <div className={conversationClasses.flexColumn}>
      <span className={conversationClasses.iconSpan}>
        <Tooltip
          classes={{ tooltip: toolTipClasses.toolTip }}
          placement="left"
          PopperProps={{ disablePortal: true }}
          arrow={true}
          title="View in the conversations tab"
        >
          <Link
            to={`/inbox/conversations?conversationId=${conversationVisit.conversation.conversationId}`}
          >
            <Icon
              className={`fas fa-external-link-alt ${conversationClasses.newTabIcon}`}
            />
          </Link>
        </Tooltip>
      </span>
      {!showRelatedVisits && (
        <Typography className={conversationClasses.subHeader}>
          Also from this person:
        </Typography>
      )}
      {relatedVisitList
        .sort((x, y) => (x.visitId < y.visitId ? 1 : -1))
        .map((conversationObject) => (
          <Link
            key={conversationObject.visitId}
            style={{ textDecoration: "none" }}
            to={`/inbox?visitId=${conversationObject.visitId}`}
          >
            <div className={conversationClasses.miniCard}>
              <Typography className={conversationClasses.headerText}>
                {conversationObject.firstMessageText
                  ? trimText(conversationObject.firstMessageText)
                  : ""}
              </Typography>
              <Typography className={conversationClasses.bodyText}>
                {formatDate(conversationObject.startDate)}-
                {conversationObject.endDate
                  ? formatDate(conversationObject.endDate)
                  : "present"}
              </Typography>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default RelatedConversations;
