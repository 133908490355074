import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  messageSentMediaContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "40px",
    marginRight: "125px",
    textAlign: "left",
    minWidth: "200px",
  },
  accordionSummary: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "3%",
    justifyContent: "flex-start",
    height: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
      marginRight: "0",
    },
  },
  messageSentContainer: {
    marginBottom: "40px",
    width: "calc(100% - 100px)",
    marginRight: "100px",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      marginRight: "25px",
      marginLeft: "0",
      width: "100%",
    },
  },
  message: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    padding: "15px 10px",
    borderRadius: "20px",
    backgroundColor: hgemColours.LighterMediumGrey,
    marginTop: "5px",
    whiteSpace: "pre-line",
    fontSize: "0.9em",
  },
  messageIcon: {
    fontSize: "1.4em",
    color: theme.palette.grey[500],
    margin: "0 10px 0 5px",
  },
  messageContent: {
    display: "grid",
  },
  messageText: {
    marginTop: "15px",
    textWrap: "pretty",
  },
  messageMedia: {
    marginTop: "15px",
  },
  messageSubject: {
    fontWeight: 600,
  },
  inBound: {
    order: 3,
    marginLeft: "2%",
  },
}));

export const MessageReceivedStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
