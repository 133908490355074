import { ICaseDetails } from "../../../../state/types/TaskCentreCases";
import {
  AttachmentFileSelector,
  AttachmentsContainer,
  AttachmentsList,
} from "../../../inboxViews/components/attachments";

type Props = {
  caseDetails?: ICaseDetails | undefined;
  userId: number;
  isAdmin: boolean;
  onFileSelected: (file: File) => void;
  removeFile: (file: File) => void;
  files: File[];
};

const CommonAttachmentsInput = (props: Props) => {
  const { onFileSelected, removeFile, files } = props;

  const attachments = files.map((x, i) => {
    return {
      id: i,
      filepath: x.name,
    };
  });

  const deleteAttachment = (attachmentId: number) => {
    removeFile(files[attachmentId]);
    return Promise.resolve();
  };

  return (
    <>
      <AttachmentsContainer showTitle={false}>
        <>
          <AttachmentFileSelector onFileSelected={onFileSelected} />
          <AttachmentsList
            attachments={attachments}
            downloadAttachment={undefined}
            authorDeleteAttachment={() => deleteAttachment}
          />
        </>
      </AttachmentsContainer>
    </>
  );
};

export default CommonAttachmentsInput;
