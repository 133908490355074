import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  notificationIconMobile: {
    color: theme.palette.text.primary,
    padding: "12px 8px",
  },
  notificationIconDesktop: {
    color: theme.palette.text.primary,
    borderRadius: "0",
  },
  closeButton: {
    float: "right",
  },
  announcementIcon: {
    color: theme.palette.text.primary,
    fontSize: "1.5em",
    display: "flex",
  },
  announceKitButton: {
    padding: "0",
    lineHeight: "0",
  },
}));

export const NotificationStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
