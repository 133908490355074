import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialogTitle: {
    padding: "0",
  },
  dialogText: {
    color: theme.palette.text.primary,
  },
  dialogTextNote: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.caption.fontSize,
  },
  dialogDivider: {
    margin: "10px 0 20px 0",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  filterGroup: {
    marginTop: "20px",

    "& p": {
      fontWeight: "bold",
    },
  },
  checkbox: {
    color: theme.palette.grey[400],
    padding: "5px 9px",
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "0",
  },
  filterButton: {
    width: "max-content",
    backgroundColor: hgemColours.LimeGreen,
    border: "none",
    color: hgemColours.White,
    "&:hover": {
      backgroundColor: hgemColours.DarkGrey,
      border: "none",
    },
  },
}));

export const FilterConfigurationOptionsStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
