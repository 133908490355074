import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, DialogActions, DialogContent } from "@material-ui/core";

interface IProps {
  showConfirmation: boolean;
  title: string;
  message: string;
  confirmButtonText: string;
  onConfirmCallback: () => void;
}

const ConfirmationDialog = (props: IProps) => {
  return (
    <>
      <Dialog open={props.showConfirmation}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.message}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onConfirmCallback}>
            {props.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
