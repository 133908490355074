import { getConversationMessageMediaAPI } from "../../../../../state/api/Conversations-API";
import { useClientId } from "../../../../../state/hooks/clients/useClientId";
import { ConversationMessageMedia } from "../../../../../state/types/Conversations";
import { MessageCore, MessageProps } from "..";
import { MediaMessageContent, MediaMessageSideContent, useMedia } from "../../../../inboxViews/components/mediaMessages";
import { useState } from "react";
import { MediaLinks } from "../../../../inboxViews/components/mediaMessages/types/mediaLinks";

export type MediaMessageProps = MessageProps & { media: ConversationMessageMedia };

const MediaMessage = (props: MediaMessageProps) => {
    const clientId = useClientId();

    const [mediaLinks, setMediaLinks] = useState<MediaLinks | undefined>();
    const [isDownloading, setIsDownloading] = useState(false);

    const media = useMedia({
        filename: props.media.filename,
        contentType: props.media.contentType,
        getMedia: props.message.conversationMessageId && clientId 
            ? () => getConversationMessageMediaAPI(clientId, props.conversationId, props.message.conversationMessageId as number, props.media.conversationMessageMediaId)
            : undefined,
        mediaLinks: [mediaLinks, setMediaLinks],
        isDownloading: [isDownloading, setIsDownloading]
      });

    return <MessageCore
        conversationId={props.conversationId}
        message={props.message}
        messageHeader={props.messageHeader}
        content={<MediaMessageContent 
            shouldRenderMedia={media.shouldRenderMedia} 
            filename={props.media.filename} 
            contentType={props.media.contentType} 
            download={() => setIsDownloading(true)} 
            mediaLinks={media.links} />}
        sideContent={<MediaMessageSideContent 
            isDownloading={isDownloading} 
            download={() => setIsDownloading(true)} />} />
}

export default MediaMessage;