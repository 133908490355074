const renderInlineContentTypesPattern = new RegExp(`^(image|audio|video)`);

export const shouldRender = (contentType: string) => {
    return renderInlineContentTypesPattern.test(contentType);
}

export const isImage = (contentType: string) => {
    return contentType.startsWith("image");
}

export const isVideo = (contentType: string) => {
    return contentType.startsWith("video");
}

export const isAudio = (contentType: string) => {
    return contentType.startsWith("audio");
}

