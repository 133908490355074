export const routes = {
  _empty: "/",
  login: "/login",
  logout: "/logout",
  resetSession: "/reset-session",
  notFound: "/not-found",
  error: "/error",
  reports: "/reports/:reportName",
  myDashboard: "/reports/mydashboard",
  visitDetails: "/visit-details",
  eLearning: "/team-learning",
  visitReportDownload: "/reporting/downloadvisitreport",
  inbox: "/inbox",
};

export interface routeParameters {
  reportName: string;
}

const redirections = {
  preLoad: {
    path: "/preload",
    redirection: "/inbox",
  },
  actionCentre: {
    path: "/actioncentre",
    redirection: "/inbox/tasks",
  },

  actionCentreV2: {
    path: "/action-centre",
    redirection: "/inbox/tasks",
  },

  actionCentreStatistics: {
    path: "/actioncentrestatistics",
    redirection: "/inbox/tasks",
  },
  assessments: {
    path: "/assessments",
    redirection: "/reports/audit-overview",
  },
  benchmarks: {
    path: "/benchmarks",
    redirection: "/reports/benchmarking",
  },
  eLearning: {
    path: "/elearning",
    redirection: "/e-learning",
  },
  "e-learning": {
    path: "/e-learning",
    redirection: "/team-learning",
  },
  employeeSurvey: {
    path: "/employeesurvey",
    redirection: "/reports/team-engagement",
  },
  guestJourney: {
    path: "/guestjourney",
    redirection: "/reports/feedback-overview",
  },
  internalAudits: {
    path: "/internalaudits",
    redirection: "/reports/self-audits",
  },
  leaderboard: {
    path: "/leaderboard",
    redirection: "/reports/leaderboards",
  },
  managementStatistics: {
    path: "/management/statistics",
    redirection: "/reports/statistics",
  },
  managementFulfilment: {
    path: "/management/fulfilment",
    redirection: "/reports/fulfilment",
  },
  menu: {
    path: "/menu",
    redirection: "/reports/dish-ratings",
  },
  nps: {
    path: "/nps",
    redirection: "/reports/net-promoter",
  },
  projectAssessments: {
    path: "/projectassessments",
    redirection: "/reports/project-audits",
  },
  reviews: {
    path: "/reviews",
    redirection: "/reports/review-ratings",
  },
  thirdPartyDeliveries: {
    path: "/thirdpartydeliveries",
    redirection: "/reports/audit-overview",
  },
  visitDetail: {
    path: "/visitdetail",
    redirection: "/inbox",
  },
};

export const getRedirectionPaths = () => {
  return Object.values(redirections).map((x) => x.path);
};

export const getRedirectionForUrl = (url: string): string | undefined => {
  const redirection = Object.values(redirections).find(
    (x) => url.toLowerCase().indexOf(x.path.toLowerCase()) > -1
  );

  return redirection ? redirection.redirection : undefined;
};
