import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { DeleteTaskDialogStyles } from "../styles/deleteTaskDialogStyles";
import { DeleteCaseCommand } from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import {
  executeCaseCommand,
  removeTaskCaseItem,
} from "../../../state/actions/TaskCentre-CaseList-Actions";

interface IProps {
  caseId: number;
  title: string;
  dialogOpen: boolean;
  onDialogClose: () => void;
}

const DeleteTaskDialog = (props: IProps) => {
  const classes = DeleteTaskDialogStyles();
  const dispatch = useDispatch();

  const handleTaskDelete = () => {
    const deleteCommand = new DeleteCaseCommand(props.caseId);
    dispatch(
      executeCaseCommand(props.caseId, [deleteCommand], () => {
        dispatch(removeTaskCaseItem(props.caseId));
      })
    );

    props.onDialogClose();
  };

  return (
    <>
      <Dialog open={props.dialogOpen} onClose={props.onDialogClose}>
        <DialogTitle>
          {i18n.translate("TASK_CENTRE_EDIT_CASE_DELETE_Title")}
        </DialogTitle>
        <DialogContent>
          {i18n.translate("TASK_CENTRE_EDIT_CASE_DELETE_Message")}
          <span className={classes.deletionCaseTitle}>'{props.title}'</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onDialogClose}>
            {i18n.translate("TASK_CENTRE_EDIT_CASE_DELETE_Cancel")}
          </Button>
          <Button variant="contained" onClick={handleTaskDelete}>
            {i18n.translate("TASK_CENTRE_EDIT_CASE_DELETE_Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteTaskDialog;
