import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HubFilterType,
  IHubFilter,
  IHubTextFilter,
  IKeyValuePair,
} from "../../state/types/FilterSets";
import { RootState } from "../../state/reducers/RootReducer";
import { InboxListStyles } from "../inboxList/styles/inboxListStyles";
import {
  AllVisits,
  GetCurrentFilterSet,
  GetVisibleFilters,
  MoreVisitsAvailable,
  AllTaskCounts,
  SelectedVisitId,
  TotalVisitCount,
  VisitDetailsListError,
  VisitDetailsListPending,
  VisitsLastRefreshed,
  GetUserCurrentBrands,
} from "../inboxList/state/selectors";
import { setCurrentFilterSetToDefault } from "../../state/actions/FilterSets-Actions";
import {
  clearVisitDetailsList,
  fetchVisitTasksCounts,
  fetchVisitDetailsList,
  setSelectedVisitDetailId,
} from "../../state/actions/VisitDetails-Actions";
import IsAuthorised from "../../authorisation/permissions";
import { PermissionType } from "../../authorisation/permissionsUtils";
import { clearVisitReportError } from "../../state/actions/VisitReport-Actions";
import BuildVisitDetailsListQuery from "../inboxList/utils/visitDetailsListQueryBuilder";
import VisitListError from "../inboxList/components/visitListError";
import VisitList from "../inboxList/components/visitList";
import VisitListSkeleton from "../inboxList/components/visitListSkeleton";
import VisitListEmpty from "../inboxList/components/visitlistEmpty";
import InboxItem from "../inboxList/components/inboxItem";
import { Skeleton } from "@material-ui/lab";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import i18n from "../../localizations/i18n";
import dayjs from "dayjs";
import { AppealWorkflowStatuses } from "../../state/types/TaskCentreCases";
import { ClientBorderThreshold } from "../../state/api";

interface IProps {
  pageRef: string;
  visitTypes: string[];
  borderColorThresholds: ClientBorderThreshold | undefined;
  requestedVisitId?: number | undefined;
  listHeightOffset?: number;
  showAsPopupList?: boolean;
}

const InboxList = (props: IProps) => {
  const classes = InboxListStyles();

  const dispatch = useDispatch();

  const [popupListOpen, setPopupListOpen] = useState(false);
  const [visitTypes, setVisitTypes] = useState(props.visitTypes);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [filterState, setFilterState] = useState<IHubFilter[]>([]);
  const [userCanReadGuestDetails, setUserCanReadGuestDetails] = useState(false);

  const currentFilterSet = useSelector(GetCurrentFilterSet);
  const usersCurrentBrands = useSelector(GetUserCurrentBrands);
  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, props.pageRef)
  );

  const visits = useSelector(AllVisits);
  const selectedVisitId = useSelector(SelectedVisitId);
  const visitsTotalCount = useSelector(TotalVisitCount);
  const visitsLastRefreshed = useSelector(VisitsLastRefreshed);
  const moreVisitsAvailable = useSelector(MoreVisitsAvailable);
  const visitsPending = useSelector(VisitDetailsListPending);
  const visitsError = useSelector(VisitDetailsListError);
  const actionCounts = useSelector(AllTaskCounts);

  const availableStatuses = AppealWorkflowStatuses;

  const selectedStatuses: IKeyValuePair[] = availableStatuses.map((x) => {
    return {
      key: x.value,
      value: x.text,
    } as IKeyValuePair;
  });

  const queryFilters = filters.map((filter) => {
    if (filter.type === HubFilterType.AppealStatus) {
      return { ...filter, value: selectedStatuses };
    }
    return filter;
  });
  const appealFilterStatus = filters.find(
    (filterSet) => filterSet.type === HubFilterType.AppealStatus
  ) as IHubTextFilter;

  const filterSetToSend =
    appealFilterStatus?.value.length > 0 ? filters : queryFilters;
  const applyVisitsSelection = (visitId: number): void => {
    if (selectedVisitId) {
      dispatch(fetchVisitTasksCounts([selectedVisitId]));
    }

    if (visitId && visitId > 0) {
      setPopupListOpen(false);
      dispatch(setSelectedVisitDetailId(visitId));
      dispatch(clearVisitReportError());
    }
  };

  const loadAdditionalVisits = (): void => {
    const query = BuildVisitDetailsListQuery(
      visitsTotalCount,
      queryFilters,
      props.visitTypes,
      selectedBrands,
      undefined,
      undefined
    );

    dispatch(fetchVisitDetailsList(query));
  };

  useEffect(() => {
    const checkUserCanReadGuestDetails = async () => {
      const userCanReadDetails = await IsAuthorised(
        PermissionType.ReadGuestDetails
      );
      setUserCanReadGuestDetails(userCanReadDetails);
    };

    checkUserCanReadGuestDetails();
  });

  useEffect(() => {
    if (!currentFilterSet) {
      dispatch(setCurrentFilterSetToDefault(props.pageRef));
    }
  }, [currentFilterSet, dispatch, props.pageRef]);

  useEffect(() => {
    if (
      currentFilterSet &&
      (visitsLastRefreshed === undefined ||
        filterState !== filters ||
        visitTypes !== props.visitTypes ||
        selectedBrands !== usersCurrentBrands)
    ) {
      dispatch(clearVisitDetailsList());

      const query = BuildVisitDetailsListQuery(
        0,
        filterSetToSend,
        props.visitTypes,
        usersCurrentBrands,
        props.requestedVisitId,
        undefined
      );

      setFilterState(filters);
      setVisitTypes(props.visitTypes);
      setSelectedBrands(usersCurrentBrands);
      dispatch(fetchVisitDetailsList(query));
    }

    if (visits && visits.length > 0 && selectedVisitId === undefined) {
      dispatch(setSelectedVisitDetailId(visits[0].id));
    }
  }, [
    currentFilterSet,
    dispatch,
    filterState,
    filters,
    props.requestedVisitId,
    props.visitTypes,
    selectedVisitId,
    visitTypes,
    visits,
    visitsLastRefreshed,
    selectedBrands,
    usersCurrentBrands,
    queryFilters,
  ]);
  // @typescript-eslint/no-explicit-any disabled for const refs
  const refs = props.showAsPopupList
    ? visits.reduce((acc: any, value) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {} as any)
    : ({} as any);

  const scrollToSelected = (): void => {
    if (selectedVisitId && refs) {
      refs[selectedVisitId].current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const visitList = (): JSX.Element => {
    return (
      <>
        {visitsError && <VisitListError />}
        {!visitsError && props.borderColorThresholds && (
          <VisitList
            visits={visits}
            isLoading={visitsPending}
            fetchMoreVisits={loadAdditionalVisits}
            moreVisitsAvailable={moreVisitsAvailable || false}
            skeleton={<VisitListSkeleton />}
            visitView={(visitDetails) => {
              return (
                <div ref={refs[visitDetails.id]} key={visitDetails.id}>
                  <InboxItem
                    visitDetails={visitDetails}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    borderColorThresholds={props.borderColorThresholds!}
                    onItemSelected={applyVisitsSelection}
                    isSelected={visitDetails.id === selectedVisitId}
                    tasksCount={
                      actionCounts.find((x) => x.visitId === visitDetails.id)
                        ?.count
                    }
                    showGuestContact={userCanReadGuestDetails}
                  />
                </div>
              );
            }}
            emptyVisits={<VisitListEmpty />}
          />
        )}
      </>
    );
  };

  const popupSelectorLabel = () => {
    let labelText = i18n.translate("VISIT_DETAILS_LIST_NoVisitSelected");

    if (!visitsPending && selectedVisitId) {
      const selectedVisit = visits.find((x) => x.id === selectedVisitId);
      if (selectedVisit) {
        labelText = `${selectedVisit.branch} - ${dayjs(
          selectedVisit.date
        ).format(i18n.translate("VISIT_DETAILS_LIST_Date_Format"))} - ${
          selectedVisit.score
        }%`;
      }
    }

    return labelText;
  };

  const visitListInitialised = !visitsPending || visits.length !== 0;

  return (
    <div className={classes.listContainer}>
      {props.showAsPopupList && visitListInitialised && (
        <Button
          className={classes.popupSelection}
          onClick={() => setPopupListOpen(true)}
        >
          <>
            <span>
              {i18n.translate("VISIT_DETAILS_LIST_Select_Visit")}
              <div className={classes.popupSelectionHint}>
                {popupSelectorLabel()}
              </div>
            </span>
          </>
        </Button>
      )}

      {props.showAsPopupList && !visitListInitialised && (
        <>
          <Skeleton
            className={classes.loader}
            variant="rect"
            height={40}
            animation="wave"
          />
        </>
      )}

      {props.showAsPopupList && (
        <Dialog
          className={classes.popupSelectionDialog}
          fullWidth
          onClose={() => setPopupListOpen(false)}
          onEntered={() => scrollToSelected()}
          open={popupListOpen}
        >
          <DialogContent>{visitList()}</DialogContent>
        </Dialog>
      )}

      {!props.showAsPopupList && visitList()}
    </div>
  );
};

export default InboxList;
