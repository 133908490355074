import * as pbi from "powerbi-client";
import * as models from "powerbi-models";
import {
  IHubFilter,
  HubFilterType,
  IVisitDateFilter,
  ILocationRankFilter,
  IVisitTypeFilter,
  IQuestionsFilter,
  IHubTextFilter,
  IHubNumericRangeFilter,
  IHubKeyValueFilter,
  ILocationFilter,
} from "../../../state/types/FilterSets";
import { IEmbedConfigurationBase } from "embed";
import * as filterObjects from "./pbiFIlterObjects";
import { Logging, SeverityLevel } from "../../../utils/logging";
import {
  extractNonHubFilters,
  buildBasicFilterObject,
  buildNumericRangeFilter,
  buildHubDateFilter,
} from "./pbiFIlterObjects";
import {
  LocationRankSlicerTitle,
  DateSlicerTitle,
  PeriodSlicerTitle,
  PeriodHierarchySlicerTitle,
} from "./pbiSlicerTypes";
import UndefinedSlicer from "./undefinedSlicer";

export interface IVisualExportTarget {
  visualName: string;
  visualTitle: string;
}

class PbiReportRendering {
  private hasPerformanceApi = false;
  private renderInProgress = false;
  private pbiServiceInstance: pbi.service.Service | null = null;
  private pbiContainerRef: React.RefObject<HTMLDivElement>;
  private reportLoadedCallback: () => void;
  private pagesLoadedCallback: (
    pages: string[],
    defaultPage: string
  ) => void | undefined;
  private reportErrorCallback: (error: models.IError) => void;

  constructor(
    container: React.RefObject<HTMLDivElement>,
    reportLoadedCallback: () => void,
    pagesLoadedCallback: (
      pages: string[],
      defaultPage: string
    ) => void | undefined,
    reportErrorCallback: (error: models.IError) => void
  ) {
    this.pbiServiceInstance = this.getPbiServiceInstance();
    this.pbiContainerRef = container;

    this.reportLoadedCallback = reportLoadedCallback;
    this.pagesLoadedCallback = pagesLoadedCallback;
    this.reportErrorCallback = reportErrorCallback;

    if (window.performance) {
      this.hasPerformanceApi = true;
    }
  }

  public renderReport = (
    reportId: string,
    reportUrl: string,
    accessToken: string,
    clientId: number,
    selectedBrands: string[] | undefined,
    availableLocationIds: number[],
    hubFilters: IHubFilter[] | undefined,
    isMobileViewport = false,
    applyDatesAsPageFilters = false
  ): void => {
    let renderStart = 0;
    if (this.hasPerformanceApi) {
      renderStart = window.performance.now();
    }

    this.renderInProgress = true;

    const defaultSlicers = this.buildSlicersState(
      hubFilters,
      applyDatesAsPageFilters
    );

    const defaultFilters = this.buildReportLevelFilters(
      clientId,
      selectedBrands,
      availableLocationIds,
      hubFilters,
      applyDatesAsPageFilters
    );

    const layoutSettings = {
      displayOption: models.DisplayOption.ActualSize,
      pageSize: {
        type: models.PageSizeType.Custom,
        width: "100%",
        height: "100%",
      },
    } as models.ICustomLayout;

    const renderSettings = {
      layoutType: isMobileViewport
        ? models.LayoutType.MobilePortrait
        : models.LayoutType.Custom,
      customLayout: layoutSettings,
      hideErrors: true,
      panes: {
        bookmarks: { visible: false },
        fields: { visible: false },
        filters: { visible: false, expanded: false },
        pageNavigation: { visible: false },
        selection: { visible: false },
        syncSlicers: { visible: false },
        visualizations: { visible: false },
      },
    } as models.ISettings;

    const embedConfiguration = {
      id: reportId,
      embedUrl: reportUrl,
      accessToken: accessToken,
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      permissions: pbi.models.Permissions.Read,
      settings: renderSettings,
      filters: defaultFilters,
      slicers: defaultSlicers,
    } as IEmbedConfigurationBase;

    const htmlContainer = this.pbiContainerRef.current;

    if (htmlContainer != null && this.pbiServiceInstance) {
      try {
        this.prepareInstance(this.pbiServiceInstance, htmlContainer);

        const report = this.pbiServiceInstance.embed(
          htmlContainer,
          embedConfiguration
        ) as pbi.Report;

        report.off("error");
        report.on("error", (event) => {
          const error = event.detail as models.IError;
          const e = new Error(error.detailedMessage);

          if (error.level === models.TraceType.Fatal) {
            this.reportErrorCallback(error);
            Logging.captureError(
              error.message,
              e,
              SeverityLevel.Error,
              window.location.href
            );
          } else {
            Logging.captureError(
              error.message,
              e,
              SeverityLevel.Warning,
              window.location.href
            );
          }
        });

        report.off("loaded");
        report.on("loaded", () => {
          this.reportLoadedCallback();
          this.getReportPages(report);

          if (this.hasPerformanceApi && this.renderInProgress) {
            const loaded = window.performance.now();
            Logging.capturePerformanceEvent(
              "ReportLoad",
              renderStart,
              loaded,
              reportId
            );
          }
        });

        report.off("rendered");
        report.on("rendered", () => {
          if (this.hasPerformanceApi && this.renderInProgress) {
            const rendered = window.performance.now();
            Logging.capturePerformanceEvent(
              "ReportRendered",
              renderStart,
              rendered,
              reportId
            );
          }
          this.renderInProgress = false;
        });
      } catch (e) {
        Logging.captureError("Error in 'renderReport'", e, SeverityLevel.Error);
      }
    }
  };

  public prepareInstance = (
    serviceInstance: pbi.service.Service,
    htmlContainer: HTMLDivElement
  ): void => {
    const cleanIframeBetweenRenderers =
      process.env.REACT_APP_POWERBI_RESET_IFRAME_BETWEEN_RENDERS;
    if (
      cleanIframeBetweenRenderers &&
      cleanIframeBetweenRenderers.toLowerCase() === "true"
    ) {
      serviceInstance.reset(htmlContainer);
    }
  };

  public updateAccessToken = (token: string) => {
    const container = this.pbiContainerRef.current;
    if (container && token) {
      const pbi = this.pbiServiceInstance?.get(container);
      pbi?.setAccessToken(token).catch((e: Error) => {
        Logging.captureEvent("GenerateRefreshTokenFailure", e.message);
      });
    }
  };

  public setCurrentPage = (pageName: string): void => {
    const report = this.getCurrentReport();
    if (report && !this.renderInProgress) {
      report.getPages().then((p) => {
        const matchedPage = p.find((x) => x.displayName === pageName);
        if (matchedPage && !matchedPage.isActive) {
          report.setPage(matchedPage.name);
          Logging.captureEvent(
            "ChangeReportPage",
            `Page:[${matchedPage.displayName}] Report:[${matchedPage.report.config.id}]`
          );
        }
      });
    }
  };

  public reapplyFilters = (
    clientId: number,
    brands: string[] | undefined,
    availableLocationIds: number[],
    filters: IHubFilter[] | undefined,
    applyDatesAsPageFilters: boolean
  ): void => {
    let reportFilters = this.buildReportLevelFilters(
      clientId,
      brands,
      availableLocationIds,
      filters,
      applyDatesAsPageFilters
    );

    const container = this.pbiContainerRef.current;
    if (container) {
      const report = this.pbiServiceInstance?.get(container) as pbi.Report;

      report.getFilters().then((existingFilters) => {
        const nonHubFilters = extractNonHubFilters(existingFilters);
        if (nonHubFilters.length > 0) {
          reportFilters = reportFilters.concat(nonHubFilters);
        }

        report
          .setFilters(reportFilters)
          .catch((e) =>
            Logging.captureError(
              "Filed to reapply filters",
              e,
              SeverityLevel.Error
            )
          );

        this.syncDateAndPeriodSlicers(filters);
        this.syncLocationRankSlicer(filters);

        Logging.captureEvent("ReportSlicersChanged");
      });
    }
  };

  public getDataExportTargets = async (
    visualTypes: string[],
    visualNames?: string[]
  ): Promise<IVisualExportTarget[]> => {
    const currentPage = await this.getCurrentReportPage();
    let exportTargets: IVisualExportTarget[] = [];

    if (currentPage) {
      await currentPage.getVisuals().then((visuals) => {
        visuals.forEach((v) => {
          const displayStateMode = v?.layout?.displayState?.mode;

          if (
            v.title &&
            visualTypes &&
            visualTypes.indexOf(v.type) > -1 &&
            displayStateMode === pbi.models.VisualContainerDisplayMode.Visible
          ) {
            exportTargets.push({
              visualName: v.name,
              visualTitle: v.title,
            });
          }
        });
      });
    }

    if (visualNames && visualNames.length > 0) {
      exportTargets = exportTargets.filter(
        (x) => visualNames.indexOf(x.visualTitle) > -1
      );
    }

    exportTargets = exportTargets.sort((x, y) => {
      return x.visualTitle.localeCompare(y.visualTitle);
    });

    return exportTargets;
  };

  public getDataExport = async (visualName: string): Promise<string> => {
    const currentPage = await this.getCurrentReportPage();
    let exportContents = "";

    if (currentPage) {
      await currentPage.getVisuals().then(async (visuals) => {
        const exportVisual = visuals.find((x) => x.name === visualName);
        if (exportVisual) {
          await exportVisual
            .exportData(pbi.models.ExportDataType.Summarized)
            .then((result: pbi.models.IExportDataResult) => {
              exportContents = result.data;
            })
            .catch((e) => {
              Logging.captureError(
                "ReportExportError",
                e,
                SeverityLevel.Error,
                `Report:${currentPage.report.config.id} => Page:${currentPage.displayName} => Visual:${visualName}`
              );
            });
        }
      });
    }

    return exportContents;
  };

  public cleanup = (): void => {
    try {
      const container = this.pbiContainerRef.current;

      if (container) {
        const pbi = this.pbiServiceInstance?.get(container);

        pbi?.off("error");
        pbi?.off("loaded");
        pbi?.off("rendered");
        this.pbiServiceInstance?.reset(container);
      }
    } catch (e) {
      Logging.captureError(
        "Failed to cleanup pbi resources",
        e,
        SeverityLevel.Warning
      );
    }
  };

  private getCurrentReport = (): pbi.Report | undefined => {
    const container = this.pbiContainerRef.current;
    if (container) {
      return this.pbiServiceInstance?.get(container) as pbi.Report;
    }
  };

  private getCurrentReportPage = async (): Promise<
    Promise<pbi.Page> | undefined
  > => {
    const report = this.getCurrentReport();
    let page = undefined;

    if (report) {
      await report.getPages().then((p) => {
        page = p.find((x) => x.isActive);
      });
    }

    return page;
  };

  private getReportPages = async (report: pbi.Report): Promise<void> => {
    report
      .getPages()
      .then((pages: Array<pbi.Page>) => {
        const pageNames = pages
          .filter((p) => p.visibility === 0)
          .map((p) => p.displayName);

        const defaultPage = pages.find((x) => x.isActive);

        if (this.pagesLoadedCallback !== undefined && pageNames.length > 0) {
          this.pagesLoadedCallback(
            pageNames,
            defaultPage ? defaultPage.displayName : pageNames[0]
          );
        }
      })
      .catch((e) =>
        Logging.captureError(
          "Error fetching pages for report",
          e,
          SeverityLevel.Error
        )
      );
  };

  private addFilterToCollection = (
    filterCollection: pbi.models.IFilter[],
    filter: pbi.models.IFilter | undefined
  ): void => {
    if (filter) {
      filterCollection.push(filter);
    }
  };

  private buildReportLevelFilters = (
    clientId: number,
    brands: string[] | undefined,
    availableLocationIds: number[],
    hubFilters: IHubFilter[] | undefined,
    applyDatesAsPageFilters: boolean
  ): pbi.models.IFilter[] => {
    const reportFilters: pbi.models.IFilter[] = [];

    this.addFilterToCollection(
      reportFilters,
      buildBasicFilterObject([clientId], "Client_Id")
    );

    this.addFilterToCollection(
      reportFilters,
      buildBasicFilterObject(brands, "Brand_Name")
    );

    hubFilters?.forEach((filter) => {
      switch (filter.type) {
        case HubFilterType.Locations:
          this.addFilterToCollection(
            reportFilters,
            buildBasicFilterObject(
              this.getFilterLocations(filter, availableLocationIds),
              HubFilterType[filter.type]
            )
          );
          break;

        case HubFilterType.VisitDate:
          if (applyDatesAsPageFilters) {
            this.addFilterToCollection(
              reportFilters,
              buildHubDateFilter(filter as IVisitDateFilter)
            );
          }
          break;

        case HubFilterType.AreasAndRegions:
        case HubFilterType.Segments:
        case HubFilterType.SegmentFilter:
        case HubFilterType.QuestionnaireType:
        case HubFilterType.Sections:
        case HubFilterType.QuestionCategory:
        case HubFilterType.Benchmarks:
        case HubFilterType.NPS:
        case HubFilterType.DayOfWeek:
        case HubFilterType.DayPart:
        case HubFilterType.VisitAnalysisRoom:
        case HubFilterType.VisitAnalysisDepartment:
        case HubFilterType.FeedbackCategory:
        case HubFilterType.FeedbackSubCategory:
        case HubFilterType.LengthOfService:
          this.addFilterToCollection(
            reportFilters,
            buildBasicFilterObject(
              (filter as IHubTextFilter).value,
              HubFilterType[filter.type]
            )
          );
          break;
        case HubFilterType.VisitType:
          this.addFilterToCollection(
            reportFilters,
            buildBasicFilterObject(
              (filter as IVisitTypeFilter).value,
              HubFilterType[filter.type]
            )
          );
          break;
        case HubFilterType.Questions:
          this.addFilterToCollection(
            reportFilters,
            buildBasicFilterObject(
              (filter as IQuestionsFilter).selectedQuestions.length > 0
                ? (filter as IQuestionsFilter).selectedQuestions
                : (filter as IQuestionsFilter).selectedQuestionSubjects,
              (filter as IQuestionsFilter).selectedQuestions.length > 0
                ? "Questions"
                : "Question_Subject"
            )
          );
          break;
        case HubFilterType.VisitScoreRange:
        case HubFilterType.QuestionScoreRange:
          this.addFilterToCollection(
            reportFilters,
            buildNumericRangeFilter(
              (filter as IHubNumericRangeFilter).minValue,
              (filter as IHubNumericRangeFilter).maxValue,
              HubFilterType[filter.type]
            )
          );
          break;

        case HubFilterType.TaskCentreCategory:
        case HubFilterType.TaskCentrePriority:
        case HubFilterType.TaskCentreStatus:
        case HubFilterType.VisitSource:
        case HubFilterType.DishFilters:
          this.addFilterToCollection(
            reportFilters,
            buildBasicFilterObject(
              (filter as IHubKeyValueFilter).value.map((x) => x.value),
              HubFilterType[filter.type]
            )
          );
          break;

        default:
          break;
      }
    });

    return reportFilters;
  };

  private buildSlicersState = (
    hubFilters: IHubFilter[] | undefined,
    applyDatesAsPageFilters: boolean
  ) => {
    const slicers: pbi.models.ISlicer[] = [];

    if (hubFilters) {
      const dateFilter = this.getVisitDateFilter(hubFilters);
      if (dateFilter && !applyDatesAsPageFilters) {
        const dateSlicerConfig =
          filterObjects.getVisitDateSlicerConfig(dateFilter);
        slicers.push(dateSlicerConfig);

        const periodSlicerConfig =
          filterObjects.getVisitPeriodSlicerConfig(dateFilter);
        slicers.push(periodSlicerConfig);

        const periodHierarchySlicerConfig =
          filterObjects.getVisitPeriodHierarchySlicerConfig(dateFilter);
        slicers.push(periodHierarchySlicerConfig);
      }

      const locationRankFilter = this.getLocationRankFilter(hubFilters);
      if (
        locationRankFilter &&
        locationRankFilter.selectedLocation &&
        locationRankFilter.selectedLocation.key > 0
      ) {
        const locationRankSlicerConfig =
          filterObjects.getLocationRankSlicerConfig(
            locationRankFilter.selectedLocation.key
          );
        slicers.push(locationRankSlicerConfig);
      }
    }

    return slicers;
  };

  private getVisitDateFilter = (
    filters: IHubFilter[] | undefined
  ): IVisitDateFilter | undefined => {
    if (filters) {
      return filters.find(
        (x) => x.type === HubFilterType.VisitDate
      ) as IVisitDateFilter;
    }

    return;
  };

  private getLocationRankFilter = (
    filters: IHubFilter[] | undefined
  ): ILocationRankFilter | undefined => {
    if (filters) {
      return filters.find(
        (x) => x.type === HubFilterType.LocationRank
      ) as ILocationRankFilter;
    }

    return;
  };

  private syncDateAndPeriodSlicers = (
    filters: IHubFilter[] | undefined
  ): void => {
    const container = this.pbiContainerRef.current;
    const dateFilter = this.getVisitDateFilter(filters);

    if (filters && container && dateFilter) {
      const report = this.pbiServiceInstance?.get(container) as pbi.Report;

      const dateSlicerConfig =
        filterObjects.getVisitDateSlicerConfig(dateFilter);

      const periodSlicerConfig =
        filterObjects.getVisitPeriodSlicerConfig(dateFilter);

      const periodHierarchySlicerConfig =
        filterObjects.getVisitPeriodHierarchySlicerConfig(dateFilter);

      report
        .getPages()
        .then((pages) => {
          const currentPage = pages.find((p) => p.isActive);
          if (currentPage) {
            currentPage
              .getVisuals()
              .then((visuals) => {
                const dateSlicer = this.getSlicer(visuals, DateSlicerTitle);
                const periodSlicer = this.getSlicer(visuals, PeriodSlicerTitle);
                const periodHierarchySlicer = this.getSlicer(
                  visuals,
                  PeriodHierarchySlicerTitle
                );

                if (dateFilter.customDateRange !== null) {
                  dateSlicer
                    .setSlicerState(dateSlicerConfig.state)
                    .then(() =>
                      periodSlicer.setSlicerState({
                        filters: [],
                      } as models.ISlicerState)
                    )
                    .then(() =>
                      periodHierarchySlicer.setSlicerState({
                        filters: [],
                      } as models.ISlicerState)
                    );
                } else if (dateFilter.selectedPeriods.length > 0) {
                  periodSlicer
                    .setSlicerState(periodSlicerConfig.state)
                    .then(() => {
                      dateSlicer.setSlicerState({
                        filters: [],
                      } as models.ISlicerState);
                    })
                    .then(() =>
                      periodHierarchySlicer.setSlicerState({
                        filters: [],
                      } as models.ISlicerState)
                    );
                } else if (dateFilter.selectedPeriodHierarchies.length > 0) {
                  periodHierarchySlicer
                    .setSlicerState(periodHierarchySlicerConfig.state)
                    .then(() => {
                      dateSlicer.setSlicerState({
                        filters: [],
                      } as models.ISlicerState);
                    })
                    .then(() =>
                      periodSlicer.setSlicerState({
                        filters: [],
                      } as models.ISlicerState)
                    );
                }
              })
              .catch((e) =>
                Logging.captureError(
                  "Unable to fetch visuals",
                  e,
                  SeverityLevel.Error
                )
              );
          }
        })
        .catch((e) =>
          Logging.captureError("Unable to fetch pages", e, SeverityLevel.Error)
        );
    }
  };

  private getSlicer = (
    visuals: pbi.VisualDescriptor[],
    name: string
  ): pbi.VisualDescriptor | UndefinedSlicer => {
    const slicer = visuals.find(
      (x) => x.type === "slicer" && x.title?.toLowerCase() === name
    );

    return slicer ? slicer : new UndefinedSlicer();
  };

  private syncLocationRankSlicer = (
    filters: IHubFilter[] | undefined
  ): void => {
    const container = this.pbiContainerRef.current;
    const rankFilter = this.getLocationRankFilter(filters);

    if (filters && container && rankFilter) {
      const report = this.pbiServiceInstance?.get(container) as pbi.Report;

      const rankSlicerConfig = filterObjects.getLocationRankSlicerConfig(
        rankFilter.selectedLocation ? rankFilter.selectedLocation.key : 0
      );

      report
        .getPages()
        .then((pages) => {
          const currentPage = pages.find((p) => p.isActive);
          if (currentPage) {
            currentPage
              .getVisuals()
              .then((visuals) => {
                const rankSlicer = visuals.find(
                  (x) =>
                    x.type === "slicer" &&
                    x.title?.toLowerCase() === LocationRankSlicerTitle
                );

                if (rankSlicer) {
                  rankSlicer.setSlicerState(rankSlicerConfig.state);
                } else {
                  Logging.captureError(
                    "Rank Slicer not found in report",
                    new Error("Missing Report Slicers"),
                    SeverityLevel.Warning
                  );
                }
              })
              .catch((e) =>
                Logging.captureError(
                  "Unable to fetch visuals",
                  e,
                  SeverityLevel.Error
                )
              );
          }
        })
        .catch((e) =>
          Logging.captureError("Unable to fetch pages", e, SeverityLevel.Error)
        );
    }
  };

  private getPbiServiceInstance = (): pbi.service.Service => {
    return new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
  };

  private getFilterLocations(
    filter: IHubFilter,
    availableLocationIds: number[]
  ) {
    const locationFilter = filter as ILocationFilter;

    const locations: number[] =
      locationFilter.value.length > 0
        ? [...locationFilter.value.map((x) => x.key)]
        : [...availableLocationIds];

    if (locations.length === 0) {
      locations.push(-1);
      Logging.captureEvent("NULL BRANCH LIST");
    }
    return locations;
  }
}

export default PbiReportRendering;
