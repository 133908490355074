import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
  availableVisitTypes: string[];
  selectedVisitTypes: string[];
  applySelectionCallback: (selections: string[]) => void;
}

const VisitTypePicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVisitTypes, setSelectedVisitTypes] = useState([] as string[]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedVisitTypes.length === 0 ||
      selectedVisitTypes.length === props.availableVisitTypes.length
    ) {
      selectionText = i18n.translate("VISIT_TYPE_PICKER_All_Selected");
    } else if (selectedVisitTypes.length === 1) {
      selectionText = i18n.translate(
        `VISIT_TYPE_OPTION_${selectedVisitTypes[0]}`
      );
    } else {
      selectionText = `${selectedVisitTypes.length} ${i18n.translate(
        "VISIT_TYPE_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    return selectedVisitTypes
      .map((x) => i18n.translate(`VISIT_TYPE_OPTION_${x}`))
      .join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    props.applySelectionCallback(selectedOptions);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedVisitTypes(props.selectedVisitTypes);
  }, [setSelectedVisitTypes, props.selectedVisitTypes]);

  return (
    <FilterWrapper
      label={i18n.translate("VISIT_TYPE_PICKER_Visit_Type")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      highlight={true}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "VISIT_TYPE_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={props.availableVisitTypes}
        selectedOptions={selectedVisitTypes}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
        applyLocalisationToOptions={true}
        localisationPrefix="VISIT_TYPE_OPTION_"
      />
    </FilterWrapper>
  );
};

export default VisitTypePicker;
