import {
  darken,
  lighten,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";

const gemWheelColours = {
  baseFontColor: "#4f575c",
  white: "#fff",
  black: "#000",
  lightgrey: "#f1f0f1",
  midgrey: "#4f575c",
  grey: "#697074",
  darkgrey: "#333",
  brandPrimary: "#b6ce35",
  brandSecondary: "#4f575c",
  brandTertiary: "#aabebf",
  brandQuaternary: "#56B6BD",
  brandQuintary: "#879194",
  brandTheHubIcon: "#4f575c",
  brandTheHubBg: "#929b9f",
  brandTheHubLight: "#f0f0f0",
  brandOnlineReviewsBg: "#91a38b",
  brandMysteryGuestIcon: "#607776",
  brandMysteryGuestBg: "#7a9393",
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "inline-block",
    width: "100%",
    verticalAlign: "middle",
    padding: "0 0 10px 0",
    textAlign: "center",

    "& .circle": {
      overflow: "visible !important",
      display: "block",
      position: "relative",
      maxHeight: "90vh",
      padding: "0 20px 0 0",

      [theme.breakpoints.down("xs")]: {
        maxHeight: "70vh",
        padding: "0",
      },

      "& .circle-active": {
        cursor: "pointer",
      },

      "& .selected-segment": {
        fill: `${gemWheelColours.brandPrimary} !important`,
      },

      "& .circle-light": {
        fill: gemWheelColours.white,
        "& > path": {
          "&:first-child": {
            fill: lighten(gemWheelColours.brandMysteryGuestBg, 0.25),
          },
        },
      },

      "& .circle-dark": {
        fill: gemWheelColours.white,
        "& > path": {
          "&:first-child": {
            fill: gemWheelColours.brandMysteryGuestBg,
          },
        },
      },

      "& .circle-heart": {
        fill: gemWheelColours.white,
        "& path": {
          "&:first-child": {
            fill: gemWheelColours.brandPrimary,
          },
        },
      },

      "& .circle-icon": {
        fill: gemWheelColours.brandMysteryGuestIcon,
      },

      "&.circle-hover": {
        "& .circle-light.circle-active": {
          "&:hover": {
            "& path": {
              "&:first-child": {
                fill: "#7a9393",
              },
            },
          },
        },

        "& .circle-dark.circle-active": {
          "&:hover": {
            "& path": {
              "&:first-child": {
                fill: darken(gemWheelColours.brandMysteryGuestBg, 0.25),
              },
            },
          },
        },

        "& .circle-heart": {
          "&:hover": {
            "& path": {
              "&:first-child": {
                fill: darken(gemWheelColours.brandTheHubIcon, 0.25),
              },
            },
          },
        },
      },
    },
  },
}));

export const GemWheelStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
