import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import {
  WorkflowStatus,
  AppealWorkflowStatuses,
} from "../../../state/types/TaskCentreCases";
import { IKeyValuePair } from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";

interface IProps {
  selectedTaskStatuses: IKeyValuePair[];
  applySelectionCallback: (selectedTaskStatuses: IKeyValuePair[]) => void;
}

const AppealStatusPicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(
    [] as IKeyValuePair[]
  );

  const availableStatuses = AppealWorkflowStatuses;

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedStatuses.length === 0 ||
      selectedStatuses.length === availableStatuses.length
    ) {
      selectionText = i18n.translate("APPEAL_STATUS_PICKER_All_Selected");
    } else if (selectedStatuses.length === 1) {
      selectionText = i18n.translate(
        `APPEALS_WORKFLOW_STATUS_${WorkflowStatus[
          selectedStatuses[0].key
        ].toString()}`
      );
    } else {
      selectionText = `${selectedStatuses.length} ${i18n.translate(
        "APPEAL_STATUS_PICKER_Selected"
      )}`;
    }
    return selectionText;
  };

  const buildTooltipContent = (): string => {
    const statusNames = selectedStatuses.map((x) => x.value);
    const uniqueStatuses = [...new Set(statusNames)];

    return uniqueStatuses.join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    const selectedStatuses: IKeyValuePair[] = availableStatuses
      .map((status) => {
        return {
          key: status.value,
          value: status.text,
        } as IKeyValuePair;
      })
      .filter((status) => selectedOptions.indexOf(status.value) > -1);
    props.applySelectionCallback(selectedStatuses);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedStatuses(props.selectedTaskStatuses);
  }, [props.selectedTaskStatuses]);

  return (
    <FilterWrapper
      label={i18n.translate("APPEAL_STATUS_PICKER_Status")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      isDisabled={availableStatuses.length === 0}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "APPEAL_STATUS_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={availableStatuses.map((x) => x.text)}
        selectedOptions={selectedStatuses.map((x) => x.value)}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
        applyLocalisationToOptions={false}
        applySort={false}
      />
    </FilterWrapper>
  );
};

export default AppealStatusPicker;
