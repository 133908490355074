import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { ContactStatusType } from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";

interface IProps {
  selectedValue: string;
  applySelectionCallback: (selectedValue: string) => void;
}

const ContactValues = [
  ContactStatusType.ShowAll,
  ContactStatusType.AwaitingContact,
  ContactStatusType.ContactMade,
  ContactStatusType.GuestReplied,
  ContactStatusType.WithContact,
  ContactStatusType.WithNoContact,
  ContactStatusType.ReviewUnresponded,
  ContactStatusType.ReviewResponded
];

const VisitContactStatusPicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedContactValue, setSelectedContactValue] = useState<string>(
    ContactStatusType.ShowAll
  );

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    return i18n.translate(
      `VISIT_CONTACT_STATUS_PICKER_PREFIX_${selectedContactValue}`
    );
  };

  const applySelection = (selectedOptions: string[]) => {
    if (selectedOptions && selectedOptions.length === 1) {
      props.applySelectionCallback(selectedOptions[0]);
    }

    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const selected = ContactValues.find((x) => x === props.selectedValue);
    if (selected) {
      setSelectedContactValue(selected);
    }
  }, [props.selectedValue]);

  return (
    <FilterWrapper
      label={i18n.translate("VISIT_CONTACT_STATUS_PICKER_Contact_Status")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={getAppliedSelectionText()}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        multiSelect={false}
        options={ContactValues}
        selectedOptions={[selectedContactValue]}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
        applyLocalisationToOptions={true}
        localisationPrefix="VISIT_CONTACT_STATUS_PICKER_PREFIX_"
        applySort={false}
      />
    </FilterWrapper>
  );
};

export default VisitContactStatusPicker;
