import {
  HubVersionUpdateActions,
  NEW_VERSION_ACCEPTED,
  SET_NEW_VERSION_AVAILABLE,
  SET_NEW_VERSION_INCOMING,
} from "../actions/HubVersionUpdate-Actions";

export type HubVersionUpdateControl = {
  newVersionIncoming: boolean;
  newVersionAvailable: boolean;
};

const INITIAL_STATE: HubVersionUpdateControl = {
  newVersionIncoming: false,
  newVersionAvailable: false,
};

export default function HubVersionUpdate(
  state: HubVersionUpdateControl = INITIAL_STATE,
  action: HubVersionUpdateActions
) {
  switch (action.type) {
    case SET_NEW_VERSION_INCOMING:
      return { ...state, newVersionIncoming: true };
    case SET_NEW_VERSION_AVAILABLE:
      return {
        ...state,
        newVersionAvailable: true,
      };
    case NEW_VERSION_ACCEPTED:
      return INITIAL_STATE;
    default:
      return state;
  }
}
