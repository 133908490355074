import {
  clientsApi,
  brandsApi,
  navigationApi,
  appVersionApi,
} from "../api/UserProfile-API";
import { BrandAccess, BrandIdAndName, IAppSourceVersion, IUserProfile } from "../types/UserProfile";
import { AuthServiceInstance } from "../../authentication/authService";

export type UserProfileAppVersion = {
  userProfile: IUserProfile, 
  appVersion: IAppSourceVersion
}

const getUserProfileAsync = async (clientId: number, userId: number, userName: string) => {
    const clients = await clientsApi(clientId);
    const brands = await brandsApi(clientId);
    const nav = await navigationApi(clientId);
    const appVersion = await appVersionApi();

    const availableBrands = brands.data.brandAccessList
      .filter((b: BrandAccess) => {
        return b.userHasAccess;
      })
      .map((b: BrandAccess) => {
        return { id: b.id, name: b.name } as BrandIdAndName;
      });

    const selectedBrands = AuthServiceInstance.getUserBrands(
      availableBrands.map((x) => x.name)
    );

    const userProfile: IUserProfile = {
      Id: userId,
      UserName: userName,
      currentClient: clientId,
      availableClients: clients.data,
      currentBrands: selectedBrands,
      availableBrands: availableBrands,
      navigationOptions: nav.data,
      userCanSwitchBrands: availableBrands.length > 1,
      userCanSwitchClients: clients.data.length > 1,
    };

    const result: UserProfileAppVersion = {
      userProfile,
      appVersion: appVersion.data
    }

    return result;
  }

export {
    getUserProfileAsync
}