import React from "react";
import { ICaseDetails } from "../../../state/types/TaskCentreCases";
import CaseListFilter from "./caseListFilter";
import { InboxTasksListStyles } from "../styles/inboxTasksListStyles";
import { useSelector } from "react-redux";
import { TaskCentreCasesCount } from "../../../state/selectors/TaskCentre-Selectors";
import { i18n } from "../../../localizations";
import InfiniteScrollWindow from "../../_common/scrollWindow/InfiniteScrollWindow";

interface IProps {
  skeleton: React.ReactElement;
  isLoading: boolean;
  cases: ICaseDetails[];
  caseView: (caseDetails: ICaseDetails, index?: number) => React.ReactElement;
  emptyCase?: React.ReactElement;
  after?: React.ReactElement;
  scrollWindowStyle?: React.CSSProperties;
  showFilter: boolean;
  totalCasesLoaded?: number;
  appliedSearchText?: string;
  fetchMoreCases: () => void;
  moreCasesAvailable: boolean;
}

const CaseList = (props: IProps) => {
  const classes = InboxTasksListStyles();

  const caseCount = useSelector(TaskCentreCasesCount);

  return (
    <div>
      {caseCount !== undefined && (
        <div className={classes.caseCountText}>
          {caseCount}{" "}
          {i18n.translate("TASK_CENTRE_CASE_LIST_RESULTS_MATCHING_FILTERS")}
        </div>
      )}
      {props.showFilter && props.totalCasesLoaded !== undefined && (
        <CaseListFilter
          caseCount={props.cases.length}
          appliedSearchText={
            props.appliedSearchText ? props.appliedSearchText : ""
          }
          totalCasesLoaded={props.totalCasesLoaded}
        />
      )}
      <InfiniteScrollWindow
        shouldFetchMore={props.moreCasesAvailable}
        scrollCallback={props.fetchMoreCases}
        isLoading={props.isLoading}
      >
        <div>
          {props.isLoading && props.cases.length === 0 && props.skeleton}
          {!props.isLoading && props.cases.length === 0 && props.emptyCase}
          {props.cases.map(props.caseView)}
        </div>
      </InfiniteScrollWindow>
    </div>
  );
};

export default CaseList;
