import dayjs from "dayjs";
import { WorkflowStatus } from "../../../../state/types/TaskCentreCases";

export enum DueDateStatus {
  None,
  Overdue,
  DueSoon,
  OnTrack,
  Ok,
}

export class DueDateStatuses {
  static resolve(status: WorkflowStatus, dueDate?: dayjs.Dayjs) {
    if (dueDate && status === WorkflowStatus.ActionTaken) {
      return DueDateStatus.Ok;
    }
    if (!dueDate || !DueDateStatuses.openStatuses.includes(status)) {
      return DueDateStatus.None;
    }

    const now = dayjs(new Date());

    const diff = dueDate.diff(now);

    if (dueDate.isBefore(now)) {
      return DueDateStatus.Overdue;
    }
    if (diff <= Milliseconds.fromDays(1)) {
      return DueDateStatus.DueSoon;
    }
    return DueDateStatus.OnTrack;
  }

  private static openStatuses = [
    WorkflowStatus.InProgress,
    WorkflowStatus.ActionRequired,
  ];
}

export class Milliseconds {
  static fromDays(n: number) {
    return 1000 * 60 * 60 * 24 * n;
  }
  static fromHours(n: number) {
    return 1000 * 60 * 60 * n;
  }
  static fromMinutes(n: number) {
    return 1000 * 60 * n;
  }
  static fromSeconds(n: number) {
    return 1000 * n;
  }
}

export class TimeUntil {
  constructor(date: dayjs.Dayjs) {
    const now = dayjs(new Date());

    const diff = Math.abs(now.diff(date));

    this.days = Math.floor(diff / Milliseconds.fromDays(1));
    this.hours = Math.floor(
      (diff % Milliseconds.fromDays(1)) / Milliseconds.fromHours(1)
    );
    this.minutes = Math.floor(
      (diff % Milliseconds.fromHours(1)) / Milliseconds.fromMinutes(1)
    );
    this.seconds = Math.floor(
      (diff % Milliseconds.fromMinutes(1)) / Milliseconds.fromSeconds(1)
    );

    this.date = date;

    this.isFuture = date.isAfter(now);
    this.isPast = date.isBefore(now);
  }

  readonly isFuture: boolean;
  readonly isPast: boolean;

  readonly date: dayjs.Dayjs;

  readonly days: number;
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;
}
