import { useEffect, useState } from "react";
import { StorageInstance } from "../../utils/localStorage";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import Maintenance from "./Maintenance";

const bypassMaintenanceString = "bypassmaintenance";
const trueString = "true";

type Props = {
    children: React.ReactElement;
}

const MaintenanceBoundary = (props: Props) => {

    const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState<boolean>(
        process.env.REACT_APP_MAINTENANCE_MODE_ENABLED !== undefined && 
        process.env.REACT_APP_MAINTENANCE_MODE_ENABLED.toLowerCase() === trueString);

    const localStorageBypassMaintenance = StorageInstance.getValue(bypassMaintenanceString);
    const queryBypassMaintenance = getUrlQueryValueNumber(bypassMaintenanceString);

    useEffect(() => {
        if (!maintenanceModeEnabled) {
            return;
        }

        if (localStorageBypassMaintenance !== undefined && localStorageBypassMaintenance === trueString) {
            setMaintenanceModeEnabled(false);
        } 

        if (queryBypassMaintenance === 1) {
            StorageInstance.setValue(bypassMaintenanceString, trueString);

            setMaintenanceModeEnabled(false);
        }
    }, [maintenanceModeEnabled, localStorageBypassMaintenance, queryBypassMaintenance]);

    return (maintenanceModeEnabled 
        ? <Maintenance />
        : <>{props.children}</>
    );
};

export default MaintenanceBoundary;