import { SagaMiddleware } from "redux-saga";
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import {
  conversationMessageSending,
  conversationMessagesListPending,
  conversationMessagesListSuccess,
  conversationsListPending,
  conversationsListSuccess,
  conversationVisitMessagesListPending,
  conversationVisitMessagesListSuccess,
  FetchConversationMessagesList,
  FetchConversationVisitMessagesList,
  FETCH_CONVERSATIONS_LIST,
  FETCH_CONVERSATION_MESSAGES_LIST,
  INITIALIZE_CONVERSATIONS,
  SendConversationMessage,
  SEND_CONVERSATION_MESSAGE,
  setConversationsFeatureActive,
  setConversationsSourceConfigs,
  FETCH_CONVERSATION_VISIT_MESSAGES_LIST,
  FETCH_VISIT_CONVERSATION_CONTEXT,
  FetchVisitConversationContext,
  visitConversationContextSuccess,
  FetchConversationsList,
  setUnreadConversations,
  conversationMessageAdded,
  POST_MARK_CONVERSATION_MESSAGE_AS_READ,
  PostMarkConversationMessageAsRead,
  updateConversationReadCursorMessageId,
} from "../actions/Conversations-Actions";
import {
  getConversationMessagesAPI,
  getConversationsAPI,
  getConversationsFeatureActiveAPI,
  getConversationsSourceConfigsAPI,
  getConversationVisitMessagesAPI,
  getUnreadConversationsAPI,
  getvisitConversationContextAPI,
  postMarkConverstionMessageReadAPI,
  sendConversationMessageAPI as sendConversationMessageApi,
} from "../api/Conversations-API";
import {
  Conversation,
  ConversationMessage,
  ConversationsFeatureEnabled,
  ConversationSourceConfig,
  ConversationsTokenPaged,
  UnreadConversations,
  VisitConversationContext,
} from "../types/Conversations";
import { v4 as uuidv4 } from "uuid";
import {
  ConversationsNextPageToken,
  ConversationStates,
  ConversationVisitStates,
  SelectedConversationId,
  UnreadConversationsCount,
} from "../selectors/Conversations-Selectors";
import {
  ConversationVisitState,
  IConversationState,
} from "../reducers/Conversations-Reducer";
import {
  SignalRNotificationAction,
  SIGNALR_NOTIFICATION,
} from "../actions/SignalRNotifications-Actions";
import { SignalRNotificationTypes } from "../types/SignalRNotifications";

export function registerConversationsSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLatest(INITIALIZE_CONVERSATIONS, initializeConversations);
  });

  sagaMiddleware.run(function* () {
    yield takeLatest(FETCH_CONVERSATIONS_LIST, fetchConversationsList);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(
      FETCH_CONVERSATION_MESSAGES_LIST,
      fetchConversationMessagesList
    );
  });

  sagaMiddleware.run(function* () {
    yield takeLatest(
      FETCH_VISIT_CONVERSATION_CONTEXT,
      fetchVisitConversationContext
    );
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(
      FETCH_CONVERSATION_VISIT_MESSAGES_LIST,
      fetchConversationVisitMessagesList
    );
  });

  sagaMiddleware.run(function* () {
    yield takeEvery(SIGNALR_NOTIFICATION, signalRNotification);
  });

  sagaMiddleware.run(function* () {
    yield takeEvery(SEND_CONVERSATION_MESSAGE, sendConversationMessage);
  });

  sagaMiddleware.run(function* () {
    yield takeEvery(
      POST_MARK_CONVERSATION_MESSAGE_AS_READ,
      postMarkConversationMessageAsRead
    );
  });
}

function* initializeConversations(): unknown {
  const clientId = yield call(AuthServiceInstance.getClientId);

  const conversationsFeatureEnabled: ConversationsFeatureEnabled = (yield call(
    getConversationsFeatureActiveAPI,
    clientId
  )).data;

  yield put(
    setConversationsFeatureActive(conversationsFeatureEnabled.isEnabled)
  );

  if (conversationsFeatureEnabled.isEnabled) {
    const conversationSourceConfigs: ConversationSourceConfig[] = (yield call(
      getConversationsSourceConfigsAPI,
      clientId
    )).data;

    yield put(setConversationsSourceConfigs(conversationSourceConfigs));

    const unreadConversations: UnreadConversations = (yield call(
      getUnreadConversationsAPI,
      clientId
    )).data;

    yield put(setUnreadConversations(unreadConversations));
  }
}

function* fetchConversationsList(action: FetchConversationsList): unknown {
  yield put(conversationsListPending(action.filters));

  const clientId = yield call(AuthServiceInstance.getClientId);

  const conversationsNextPageToken = yield select(ConversationsNextPageToken);

  const conversationsTokenPaged: ConversationsTokenPaged<Conversation> =
    (yield call(
      getConversationsAPI,
      clientId,
      action.filters.conversationId,
      conversationsNextPageToken
    )).data;

  yield put(conversationsListSuccess(conversationsTokenPaged));
}

function* fetchConversationMessagesList(
  action: FetchConversationMessagesList
): unknown {
  const selectedConversationId: number = yield select(SelectedConversationId);

  const conversationStates: IConversationState[] = yield select(
    ConversationStates
  );

  const selectedConversationState: IConversationState = yield call(() =>
    conversationStates.find(
      (x: IConversationState) =>
        x.conversation.conversationId === selectedConversationId
    )
  );

  if (!selectedConversationState.messagesState.hasMorePages) {
    return;
  }

  yield put(conversationMessagesListPending(selectedConversationId));

  const clientId: number = yield call(AuthServiceInstance.getClientId);

  const conversationMessagesTokenPaged: ConversationsTokenPaged<ConversationMessage> =
    (yield call(
      getConversationMessagesAPI,
      clientId,
      selectedConversationState.conversation.conversationId,
      selectedConversationState.messagesState.nextPageToken
    )).data;

  yield put(
    conversationMessagesListSuccess(
      selectedConversationId,
      conversationMessagesTokenPaged,
      action.reason,
      action.scrollDistanceFromBottom
    )
  );
}

function* fetchVisitConversationContext(
  action: FetchVisitConversationContext
): unknown {
  const clientId: number = yield call(AuthServiceInstance.getClientId);

  const visitConversationContext: VisitConversationContext = (yield call(
    getvisitConversationContextAPI,
    clientId,
    action.visitId
  )).data;

  yield put(visitConversationContextSuccess(visitConversationContext));
}

function* fetchConversationVisitMessagesList(
  action: FetchConversationVisitMessagesList
): unknown {
  const conversationVisitStates: ConversationVisitState[] = yield select(
    ConversationVisitStates
  );

  const selectedConversationVisitState: ConversationVisitState = yield call(
    () =>
      conversationVisitStates.find(
        (x: ConversationVisitState) =>
          x.conversation.conversationId === action.conversationId &&
          x.visit.visitId === action.visitId
      )
  );

  if (!selectedConversationVisitState.messagesState.hasMorePages) {
    return;
  }

  yield put(
    conversationVisitMessagesListPending(action.conversationId, action.visitId)
  );

  const clientId: number = yield call(AuthServiceInstance.getClientId);

  const conversationMessagesTokenPaged: ConversationsTokenPaged<ConversationMessage> =
    (yield call(
      getConversationVisitMessagesAPI,
      clientId,
      action.conversationId,
      action.visitId,
      selectedConversationVisitState.messagesState.nextPageToken
    )).data;

  yield put(
    conversationVisitMessagesListSuccess(
      action.conversationId,
      action.visitId,
      conversationMessagesTokenPaged,
      action.reason,
      action.scrollDistanceFromBottom
    )
  );
}

function* sendConversationMessage(action: SendConversationMessage): unknown {
  let conversationId: number;
  let visitId: number;

  if (action.conversationId) {
    conversationId = action.conversationId;
  } else {
    conversationId = yield select(SelectedConversationId);
  }

  if (action.visitId) {
    visitId = action.visitId;
  } else {
    const conversationStates: IConversationState[] = yield select(
      ConversationStates
    );

    const conversationState: IConversationState = yield call(() =>
      conversationStates.find(
        (x: IConversationState) =>
          x.conversation.conversationId === conversationId
      )
    );

    visitId = yield call(() =>
      Math.max(...conversationState.conversation.visits.map((x) => x.visitId))
    );
  }

  const userId = yield call(AuthServiceInstance.getUserId);
  const senderCorrelationGuid = yield call(uuidv4);

  yield put(
    conversationMessageSending(conversationId, visitId, {
      isInbound: false,
      messageText: action.messageText,
      createdByManagerId: userId,
      senderCorrelationGuid: senderCorrelationGuid,
    })
  );

  const clientId = yield call(AuthServiceInstance.getClientId);

  yield call(
    sendConversationMessageApi,
    clientId,
    conversationId,
    action.messageText,
    action.file,
    senderCorrelationGuid
  );
}

function* signalRNotification(action: SignalRNotificationAction): unknown {
  const notification = action.notification;

  if (
    notification.notificationType !==
    SignalRNotificationTypes.ConversationMessageAdded
  ) {
    return;
  }

  const hasUnreadMessages = (conversation: Conversation) => {
    const [
      mostRecentMessageIsInbound,
      mostRecentInboundMessageId,
      readCursorMessageId,
    ] = [
      conversation.mostRecentMessageIsInbound,
      conversation.mostRecentInboundMessageId,
      conversation.readCursorMessageId,
    ];

    if (!mostRecentMessageIsInbound) {
      return false;
    }

    if (!mostRecentInboundMessageId) {
      return false;
    }

    if (!readCursorMessageId) {
      return true;
    }

    return readCursorMessageId < mostRecentInboundMessageId;
  };

  const conversationStates: IConversationState[] = yield select(
    ConversationStates
  );
  const conversationVisitStates: ConversationVisitState[] = yield select(
    ConversationVisitStates
  );

  const matchedConversaionState: IConversationState | undefined = yield call(
    () =>
      conversationStates.find(
        (x) => x.conversation.conversationId === notification.conversationId
      )
  );

  const matchedConversationVisitState: ConversationVisitState | undefined =
    yield call(() =>
      conversationVisitStates.find(
        (x) =>
          x.conversation.conversationId === notification.conversationId &&
          x.visit.visitId === notification.visit.visitId
      )
    );

  let unreadConversationsCount: number = yield select(UnreadConversationsCount);

  if (notification.isInbound) {
    if (matchedConversaionState) {
      if (!hasUnreadMessages(matchedConversaionState.conversation)) {
        unreadConversationsCount++;
      }
    } else if (matchedConversationVisitState) {
      if (!hasUnreadMessages(matchedConversationVisitState.conversation)) {
        unreadConversationsCount++;
      }
    } else {
      const clientId = yield call(AuthServiceInstance.getClientId);

      unreadConversationsCount = (yield call(
        getUnreadConversationsAPI,
        clientId
      )).data.unreadCount;
    }
  } else {
    if (matchedConversaionState) {
      if (hasUnreadMessages(matchedConversaionState.conversation)) {
        unreadConversationsCount--;
      }
    } else if (matchedConversationVisitState) {
      if (hasUnreadMessages(matchedConversationVisitState.conversation)) {
        unreadConversationsCount--;
      }
    } else {
      const clientId = yield call(AuthServiceInstance.getClientId);

      unreadConversationsCount = (yield call(
        getUnreadConversationsAPI,
        clientId
      )).data.unreadCount;
    }
  }

  yield put(setUnreadConversations({ unreadCount: unreadConversationsCount }));

  yield put(conversationMessageAdded(notification));
}

function* postMarkConversationMessageAsRead(
  action: PostMarkConversationMessageAsRead
): unknown {
  const clientId: number = yield call(AuthServiceInstance.getClientId);

  const unreadConversations: UnreadConversations = (yield call(
    postMarkConverstionMessageReadAPI,
    clientId,
    action.conversationId,
    action.conversationMessageId
  )).data;

  yield put(setUnreadConversations(unreadConversations));
  yield put(
    updateConversationReadCursorMessageId(
      action.conversationId,
      action.conversationMessageId
    )
  );
}
