import React, { useEffect, useState } from "react";
import { AuthServiceInstance } from "../../../authentication/authService";
import { ModuleViewerStyles } from "../styles/moduleViewerStyles";

interface IProps {
  accessToken: string;
  modulePath: string;
}

interface IAppInsightsDetails {
  key: string;
  uId: string;
  cId: string;
}

const ModuleViewer = (props: IProps) => {
  const classes = ModuleViewerStyles();
  const [insightsDetails, setInsightsDetails] = useState<string>();

  const eLearningContentPath = `${process.env.REACT_APP_ELEARNING_CONTENT_BASEURL}${props.accessToken}/${props.modulePath}/index.html`;

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userId = await AuthServiceInstance.getUserId();
      const clientId = await AuthServiceInstance.getClientId();
      const isAdmin = await AuthServiceInstance.isAdmin();
      const appInsightsKey = process.env.REACT_APP_APPLICATION_INSIGHTS_KEY;

      const insightsDetails: IAppInsightsDetails = {
        key: appInsightsKey ? appInsightsKey : "",
        uId: isAdmin ? `hgem_${userId}` : `user_${userId}`,
        cId: `client_${clientId}`,
      };

      const insightsDetailsEncoded = encodeURIComponent(JSON.stringify(insightsDetails));
      setInsightsDetails(insightsDetailsEncoded);
    };

    fetchUserDetails();
  }, []);

  return (
    <>
      {insightsDetails && (
        <iframe
          className={classes.frame}
          title="HGEM ELearning"
          src={`${eLearningContentPath}?c=${insightsDetails}`}
        />
      )}
    </>
  );
};

export default ModuleViewer;
