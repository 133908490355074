export const withPendingHandling = async (
  setIsPending: React.Dispatch<React.SetStateAction<boolean>>,
  promise: () => Promise<void>
) => {
  setIsPending(true);

  promise()
    .then(() => setIsPending(false))
    .catch(() => setIsPending(false));
};
