import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import FormField from "../../_common/formField/formField";
import { Icon, IconButton } from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { UpdateDueDateCommand } from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import DueDateIcon from "../../_common/Icons/dueDateIcon";
import { DueDateStatuses } from "../../_common/actionCentre/utils/dueDateStatus";
import { WorkflowStatus } from "../../../state/types/TaskCentreCases";
import { executeCaseCommand } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { DueDateFieldStyles } from "../styles/inputs/dueDateFieldStyles";
import { hgemColours } from "../../../themes/defaultTheme";
import { EditCaseItemStyles } from "../styles/editCaseItemStyles";

interface IProps {
  caseId: number;
  dueDate?: string;
  workflowStatus: WorkflowStatus;
}

const DueDateField = (props: IProps) => {
  const classes = DueDateFieldStyles();
  const inputClasses = EditCaseItemStyles();

  const dispatch = useDispatch();

  const [caseId, setCaseId] = useState(props.caseId);
  const [dateValue, setDateValue] = useState(
    props.dueDate ? dayjs(props.dueDate) : null
  );

  const onChange = (dueDate: Dayjs | null) => {
    dueDate = sanitise(dueDate);

    if (noChange(dueDate)) {
      return;
    }

    setDateValue(dueDate);
    const dateCommand = new UpdateDueDateCommand(
      props.caseId,
      dueDate ? dueDate.toDate() : null
    );
    dispatch(executeCaseCommand(props.caseId, [dateCommand]));
  };

  const sanitise = (dueDate: Dayjs | null) => {
    return dueDate
      ? dueDate
          .startOf("day")
          .add(23, "hour")
          .add(59, "minute")
          .add(59, "second")
      : null;
  };

  const noChange = (dueDate?: Dayjs | null) => {
    const original = props.dueDate ? dayjs(props.dueDate) : null;

    const bothNull = !original && !dueDate;
    const bothSame = original && dueDate && dueDate.isSame(original);

    return bothNull || bothSame;
  };

  const clearButton = () => {
    return dateValue !== null ? (
      <IconButton
        aria-label="clear due date"
        size="small"
        onClick={clearDueDate}
      >
        <Icon
          className="icon fa fa-times"
          style={{ color: hgemColours.DarkGrey }}
        />
      </IconButton>
    ) : undefined;
  };

  const clearDueDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onChange(null);
  };

  const dueDateStatus = DueDateStatuses.resolve(
    props.workflowStatus,
    dateValue ? dateValue : undefined
  );

  const getMinimumDate = (): dayjs.Dayjs => {
    const today = dayjs();
    if (dateValue && dateValue !== null) {
      if (dateValue.isBefore(today)) {
        return dateValue;
      }
    }

    return today;
  };

  useEffect(() => {
    if (caseId !== props.caseId) {
      setCaseId(props.caseId);
    }

    setDateValue(props.dueDate ? dayjs(props.dueDate) : null);
  }, [caseId, props.caseId, props.dueDate]);

  return (
    <FormField
      before={<DueDateIcon status={dueDateStatus} />}
      notificationIdentifier={`${props.caseId}|${UpdateDueDateCommand.type}`}
    >
      <>
        <DatePicker
          fullWidth
          disableToolbar
          autoOk
          className={`${classes.dateField} ${inputClasses.editCaseItemInput}`}
          minDateMessage=""
          variant="inline"
          format={i18n.translate("DATE_RANGE_PICKER_Display_Format")}
          id="action-due-date"
          minDate={getMinimumDate()}
          placeholder={i18n.translate("TASK_CENTRE_DUEDATE_No_Due_Date")}
          value={dateValue}
          onChange={onChange}
          InputProps={{
            endAdornment: clearButton(),
          }}
        />
      </>
    </FormField>
  );
};

export default DueDateField;
