import { useState } from "react";
import dayjs from "dayjs";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Button,
  Theme,
  useTheme,
  Typography,
} from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import FilterWrapper from "./filterWrapper";
import {
  IClientPeriod,
  IPeriodHierarchyOption,
} from "../../../state/types/FilterOptions";
import PeriodPicker from "./periodPicker";
import RelativeDatePicker from "./relativeDatePicker";
import CustomDateRangePicker from "./customDateRangePicker";
import PeriodHierarchyPicker from "./periodHierarchyPicker";
import {
  VisitDateSelectionType,
  IVIsitDateRange,
} from "../../../state/types/FilterSets";
import { generateRelativeDateFromSelectionType } from "../../../utils/visitDateFilterUtils";
import { hgemColours } from "../../../themes/defaultTheme";
import { i18n } from "../../../localizations";

interface IProps {
  dateSelectionType: VisitDateSelectionType;
  availableLegacyPeriods: IClientPeriod[];
  selectedLegacyPeriods: string[];
  selectedPeriodHierarchies: number[];
  selectedCustomRange: IVIsitDateRange | null;
  periodHierarchyOptions: IPeriodHierarchyOption[];
  expandedPeriodHierarchyNodes: string[] | null;
  applyLegacyPeriodSelectionCallback: (
    periods: string[],
    rangeType: VisitDateSelectionType
  ) => void;
  applyPeriodHeiorarchySelectionCallback: (
    periods: number[],
    rangeType: VisitDateSelectionType,
    expandedNodes: string[]
  ) => void;
  applySelectionRangeCallback: (
    dateRange: IVIsitDateRange,
    rangeType: VisitDateSelectionType
  ) => void;
}

const InitialView = "initial";
const RelativeDateView = "relative_date";
const LegacyPeriodView = "legacy_period";
const CustomDateView = "custom_date";
const PeriodHierarchyView = "period_hierarchy";
const HelpView = "help";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  subMenuArrow: {
    marginLeft: "30px",
  },
  helpContainer: {
    maxWidth: "400px",
    padding: theme.spacing(2),
  },
  helpItem: {
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    borderTop: `1px solid ${hgemColours.LightGrey}`,
    padding: theme.spacing(1),
  },
}));

const DateAndPeriodPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);
  const [currentView, setCurrentView] = useState(InitialView);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    setCurrentView(InitialView);
  };

  const switchView = (view: string) => {
    setCurrentView(view);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";

    switch (props.dateSelectionType) {
      case VisitDateSelectionType.Custom:
        selectionText = i18n.translate("DATE_PERIOD_PICKER_Custom_Date_Range");
        break;
      case VisitDateSelectionType.ClientPeriods:
        selectionText = `${props.selectedLegacyPeriods.length} ${i18n.translate(
          "DATE_PERIOD_PICKER_Num_Periods_Selected"
        )}`;
        break;
      case VisitDateSelectionType.reportingPeriods:
        selectionText = `${
          props.selectedPeriodHierarchies.length
        } ${i18n.translate(
          "DATE_PERIOD_PICKER_Num_Reporting_Periods_Selected"
        )}`;
        break;
      default:
        selectionText = i18n.translate(
          `DATE_PERIOD_PICKER_${VisitDateSelectionType[
            props.dateSelectionType
          ].toString()}`
        );
        break;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    let tooltipText = "";
    const dateRange = generateRelativeDateFromSelectionType(
      props.dateSelectionType
    );

    switch (props.dateSelectionType) {
      case VisitDateSelectionType.Custom:
        tooltipText = getTooltipFormatedDateRangeValue(
          props.selectedCustomRange?.dateStart,
          props.selectedCustomRange?.dateEnd
        );
        break;
      case VisitDateSelectionType.LastWeek:
      case VisitDateSelectionType.LastTwoWeeks:
      case VisitDateSelectionType.LastFourWeeks:
      case VisitDateSelectionType.LastTwelveWeeks:
        tooltipText = getTooltipFormatedDateRangeValue(
          dateRange.dateStart,
          dateRange.dateEnd
        );
        break;
      default:
        tooltipText = getTooltipFormattedLegacyPeriodRangeValue(
          props.selectedLegacyPeriods
        );
        break;
    }

    return tooltipText;
  };

  const getTooltipFormatedDateRangeValue = (
    dateStart: Date | undefined,
    dateEnd: Date | undefined
  ): string => {
    if (dateStart && dateEnd) {
      return `${dayjs(dateStart).format(
        i18n.translate("DATE_PERIOD_PICKER_Tooltip_Date_Format")
      )} -> ${dayjs(dateEnd).format(
        i18n.translate("DATE_PERIOD_PICKER_Tooltip_Date_Format")
      )}`;
    } else {
      return "";
    }
  };

  const getTooltipFormattedLegacyPeriodRangeValue = (
    periods: string[]
  ): string => {
    if (periods && periods.length > 0) {
      const tooltipText = `${periods.length} ${i18n.translate(
        "DATE_PERIOD_PICKER_Periods_Selected"
      )} `;
      let periodsList = periods.join(", ");

      if (periodsList.length > 150) {
        periodsList = periodsList.substring(0, 150) + "...";
      }

      return tooltipText + periodsList;
    } else {
      return "";
    }
  };

  const applyRelativeRangeSelection = (
    rangeType: VisitDateSelectionType
  ): void => {
    const dateRange = generateRelativeDateFromSelectionType(rangeType);
    applyDateRangeSelection(dateRange.dateStart, dateRange.dateEnd, rangeType);
  };

  const applyDateRangeSelection = (
    dateStart: Date,
    dateEnd: Date,
    rangeType: VisitDateSelectionType = VisitDateSelectionType.Custom
  ): void => {
    props.applySelectionRangeCallback({ dateStart, dateEnd }, rangeType);
    handleToggleOpen();
  };

  const applyLegacyPeriodSelection = (
    periods: string[],
    selectionType: VisitDateSelectionType
  ): void => {
    let selectedPeriods = periods;
    if (periods.length === 0) {
      selectedPeriods = props.availableLegacyPeriods.map((p) => p.periodName);
    }

    props.applyLegacyPeriodSelectionCallback(selectedPeriods, selectionType);
    handleToggleOpen();
  };

  const applyPeriodHierarchySelection = (
    periods: number[],
    selectionType: VisitDateSelectionType,
    expandedNodes: string[]
  ): void => {
    props.applyPeriodHeiorarchySelectionCallback(
      periods,
      selectionType,
      expandedNodes
    );
    handleToggleOpen();
  };

  return (
    <FilterWrapper
      label={i18n.translate("DATE_PERIOD_PICKER_Dates")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      {currentView === InitialView && (
        <>
          <List component="nav" dense>
            {props.periodHierarchyOptions.length > 0 && (
              <ListItem button onClick={() => switchView(PeriodHierarchyView)}>
                <ListItemText
                  primary={i18n.translate(
                    "DATE_PERIOD_PICKER_Reporting_Period"
                  )}
                />
                <ListItemIcon>
                  <ArrowRight className={classes.subMenuArrow} />
                </ListItemIcon>
              </ListItem>
            )}

            <ListItem button onClick={() => switchView(LegacyPeriodView)}>
              <ListItemText
                primary={i18n.translate("DATE_PERIOD_PICKER_Visit_Schedules")}
              />
              <ListItemIcon>
                <ArrowRight className={classes.subMenuArrow} />
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => switchView(RelativeDateView)}>
              <ListItemText
                primary={i18n.translate("DATE_PERIOD_PICKER_RelativeDate")}
              />
              <ListItemIcon>
                <ArrowRight className={classes.subMenuArrow} />
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => switchView(CustomDateView)}>
              <ListItemText
                primary={i18n.translate("DATE_PERIOD_PICKER_Custom_Date_Range")}
              />
              <ListItemIcon>
                <ArrowRight className={classes.subMenuArrow} />
              </ListItemIcon>
            </ListItem>
          </List>
          <div className={classes.actionContainer}>
            <Button variant="text" onClick={() => switchView(HelpView)}>
              {i18n.translate("DATE_PERIOD_PICKER_Which_do_I_Choose")}
            </Button>
          </div>
        </>
      )}

      {currentView === RelativeDateView && (
        <RelativeDatePicker
          onCancel={() => switchView(InitialView)}
          onApply={applyRelativeRangeSelection}
        />
      )}

      {currentView === LegacyPeriodView && (
        <PeriodPicker
          availablePeriods={props.availableLegacyPeriods}
          selectedPeriods={props.selectedLegacyPeriods ?? []}
          onCancel={() => switchView(InitialView)}
          onApply={applyLegacyPeriodSelection}
        />
      )}

      {currentView === PeriodHierarchyView && (
        <PeriodHierarchyPicker
          availablePeriods={props.periodHierarchyOptions}
          selectedPeriods={props.selectedPeriodHierarchies ?? []}
          expandedPeriods={props.expandedPeriodHierarchyNodes}
          onCancel={() => switchView(InitialView)}
          onApply={applyPeriodHierarchySelection}
        />
      )}

      {currentView === CustomDateView && (
        <CustomDateRangePicker
          defaultStartDateOffset={-14}
          endDate={props.selectedCustomRange?.dateEnd}
          startDate={props.selectedCustomRange?.dateStart}
          minDateDayOffset={-730}
          onCancel={() => switchView(InitialView)}
          onApply={applyDateRangeSelection}
        />
      )}

      {currentView === HelpView && (
        <div className={classes.helpContainer}>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_ReportingPeriod_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate(
                "DATE_PERIOD_PICKER_HELP_ReportingPeriod_Description"
              )}
            </Typography>
          </div>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_VisitScheule_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate(
                "DATE_PERIOD_PICKER_HELP_VisitScheule_Description"
              )}
            </Typography>
          </div>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Relative_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Relative_Description")}
            </Typography>
          </div>
          <div className={classes.helpItem}>
            <Typography variant="subtitle2">
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Custom_Title")}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {i18n.translate("DATE_PERIOD_PICKER_HELP_Custom_Description")}
            </Typography>
          </div>
          <Button variant="contained" onClick={() => switchView(InitialView)}>
            {i18n.translate("DATE_PERIOD_PICKER_Back")}
          </Button>
        </div>
      )}
    </FilterWrapper>
  );
};

export default DateAndPeriodPicker;
