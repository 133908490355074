import { IUserImpersonation } from "../types/UserImpersonation";

export const USERIMPERSONATION_PENDING = "USERIMPERSONATION_PENDING";
export const USERIMPERSONATION_SUCCESS = "USERIMPERSONATION_SUCCESS";
export const USERIMPERSONATION_ERROR = "USERIMPERSONATION_ERROR";
export const GET_IMPERSONATION_OPTIONS = "GET_IMPERSONATION_OPTIONS";
export const RESET_IMPERSONATION_OPTIONS = "RESET_IMPERSONATION_OPTIONS";

export interface IUserImpersonationSuccess {
  type: typeof USERIMPERSONATION_SUCCESS;
  userImpersonation: IUserImpersonation;
}

export function userImpersonationSuccess(
  userImpersonation: IUserImpersonation
): IUserImpersonationSuccess {
  return {
    type: USERIMPERSONATION_SUCCESS,
    userImpersonation: userImpersonation,
  };
}

export interface IUserImpersonationPending {
  type: typeof USERIMPERSONATION_PENDING;
}

export function userImpersonationPending(): IUserImpersonationPending {
  return {
    type: USERIMPERSONATION_PENDING,
  };
}

export interface IUserImpersonationError {
  type: typeof USERIMPERSONATION_ERROR;
  error: string;
}

export function userImpersonationError(error: string): IUserImpersonationError {
  return {
    type: USERIMPERSONATION_ERROR,
    error: error,
  };
}

export interface IGetUserImpersonationOptions {
  type: typeof GET_IMPERSONATION_OPTIONS;
  clientId: number | undefined;
}

export function getUserImpersonationOptions(
  clientId: number | undefined
): IGetUserImpersonationOptions {
  return {
    type: GET_IMPERSONATION_OPTIONS,
    clientId,
  };
}

export interface IResetUserImpersonationOptions {
  type: typeof RESET_IMPERSONATION_OPTIONS;
}

export function resetUserImpersonationOptions(): IResetUserImpersonationOptions {
  return {
    type: RESET_IMPERSONATION_OPTIONS,
  };
}

export type UserImpersonationActions =
  | IUserImpersonationSuccess
  | IUserImpersonationPending
  | IUserImpersonationError
  | IGetUserImpersonationOptions
  | IResetUserImpersonationOptions;
