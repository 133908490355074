import React, { useEffect, useState } from "react";
import { Icon } from "@material-ui/core";
import { getPhotoData, VisitPhotoData, UnavailableStatus } from "../state/api";
import VisitPhotoModal from "../components/visitPhotoModal";
import i18n from "../../../localizations/i18n";
import { VisitMediaStyles } from "../styles/visitMediaStyles";

interface IProps {
  visitId: number;
  fileName: string;
}

const VisitPhoto = (props: IProps) => {
  const classes = VisitMediaStyles();
  const [photoData, setPhotoData] = useState<VisitPhotoData | undefined>(
    undefined
  );
  const [modalOpen, setModalOpen] = useState(false);

  const showImage = () => {
    setModalOpen(true);
  };

  const closeImage = () => {
    setTimeout(() => {
      setModalOpen(false);
    }, 100);
  };

  useEffect(() => {
    const fetchPhoto = async () => {
      const photoData = await getPhotoData(props.visitId, props.fileName);
      setPhotoData(photoData);
    };

    if (!photoData) {
      fetchPhoto();
    }
  }, [photoData, props.fileName, props.visitId]);

  return (
    <div className={classes.container}>
      {photoData &&
        photoData.data !== UnavailableStatus &&
        photoData.data.length > 0 && (
          <img
            onClick={showImage}
            className={`${classes.thumbnailImage} ${classes.smallMargin}`}
            src={photoData.data.toString()}
            alt={i18n.translate("VISIT_DETAILS_MEDIA_Photo_Alt_Text")}
            title={i18n.translate("VISIT_DETAILS_MEDIA_Photo_Enlarge")}
          />
        )}
      {(!photoData ||
        photoData.data === UnavailableStatus ||
        photoData.data.length === 0) && (
        <Icon className={`fas fa-camera ${classes.imagePending}`} />
      )}

      {modalOpen && (
        <VisitPhotoModal
          fileName={props.fileName}
          visitId={props.visitId}
          onClose={closeImage}
        />
      )}
    </div>
  );
};

export default VisitPhoto;
