import { MenuItem, FormControl, Select, InputLabel } from "@material-ui/core";
import { AppealsStyles } from "../styles";
import { i18n } from "../../../localizations";
import { EditCaseItemStyles } from "../../taskCentreEditor/styles/editCaseItemStyles";
interface SelectOption {
  id: number | string;
  name: string;
}
interface Props {
  selectLabel: string;
  categoryOptions?: SelectOption[];
  reviewDecisionOptions?: SelectOption[];
  onChange: (id: number) => void;
  selectedId: number | undefined;
  isInvalid: boolean;
  validate: () => void;
  errorType: string;
}

const AppealsSelect = (props: Props) => {
  const appealsClasses = AppealsStyles();
  const inputClasses = EditCaseItemStyles();

  const {
    selectLabel,
    categoryOptions,
    reviewDecisionOptions,
    onChange,
    selectedId,
    validate,
    errorType,
  } = props;
  const options = categoryOptions || reviewDecisionOptions || [];
  const handleChange = (value: number) => {
    onChange(value);
    validate();
  };
  return (
    <FormControl className={`${appealsClasses.fullWidth}`}>
      <InputLabel
        className={`${appealsClasses.greyText}`}
        style={{ paddingTop: "1%" }}
        id="appeals-select"
        variant="outlined"
      >
        {selectLabel}
      </InputLabel>
      <Select
        className={inputClasses.editCaseItemInput}
        labelId="appeals-select"
        onChange={(event) => handleChange(event.target.value as number)}
        value={selectedId}
        defaultValue=""
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {props.isInvalid && (
        <span className={`${appealsClasses.errorSpan}`}>
          {i18n.translate("APPEALS_VALIDATION_SELECT")}
          {errorType}
        </span>
      )}{" "}
    </FormControl>
  );
};
export default AppealsSelect;
