import React, { useState } from "react";
import {
  Grid,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { GetActiveNotificationById } from "./state/selectors";
import { RootState } from "../../../state/reducers/RootReducer";
import { NotificationType } from "../../../state/types/AppNotification";
import { deleteAppNotification } from "../../../state/actions/AppNotification-Actions";
import i18n from "../../../localizations/i18n";
import { FormFieldStyles } from "./styles/formFieldStyles";

interface IProps {
  children: React.ReactElement;
  notificationIdentifier?: string;
  before?: React.ReactElement;
  errorDialogTitle?: string;
  errorDialogMessage?: string;
}

const FormField = (props: IProps) => {
  const classes = FormFieldStyles();

  const dispatch = useDispatch();

  const fieldNotification = useSelector((state: RootState) =>
    GetActiveNotificationById(state, props.notificationIdentifier)
  );

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const closeErrorDialog = () => {
    setErrorDialogOpen(false);

    if (fieldNotification) {
      dispatch(deleteAppNotification(fieldNotification));
    }
  };

  const getStatusIcon = () => {
    if (fieldNotification === undefined) {
      return <Icon className="icon" />;
    }

    switch (fieldNotification.Type) {
      case NotificationType.Busy:
        return (
          <Icon
            className={`icon fas fa-spinner fa-spin fa-3x fa-fw ${classes.iconStatus}`}
          />
        );
      case NotificationType.Success:
        return (
          <Icon
            className={`icon fas fa-check text_success ${classes.iconStatusOk}`}
          />
        );
      case NotificationType.Error:
        return (
          <Icon
            className={`icon fas fa-exclamation text_error ${classes.iconStatusError}`}
            onClick={() => setErrorDialogOpen(true)}
          />
        );
      default:
        return <Icon className="icon" />;
    }
  };

  let errorTitle = i18n.translate("FORM_FIELD_ERROR_Default_Title");
  if (props.errorDialogTitle) {
    errorTitle = props.errorDialogTitle;
  }

  let errorMessage = fieldNotification?.Message;
  if (props.errorDialogMessage) {
    errorMessage = props.errorDialogMessage;
  }

  return (
    <>
      <Grid
        className={classes.formFieldContainer}
        container
        spacing={1}
        alignItems="flex-end"
      >
        {props.before && (
          <Grid item className={`${classes.shrink} ${classes.icon}`}>
            {props.before}
          </Grid>
        )}

        <Grid item className={classes.grow}>
          {props.children}
        </Grid>
        <Grid item className={classes.indicator}>
          {getStatusIcon()}
        </Grid>
      </Grid>
      <Dialog open={errorDialogOpen} onClose={closeErrorDialog}>
        <DialogTitle>{errorTitle}</DialogTitle>
        <DialogContent>{errorMessage}</DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog}>
            {i18n.translate("FORM_FIELD_ERROR_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormField;
