import { SagaMiddleware } from "redux-saga";
import { call, put, takeLeading } from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import { Logging, SeverityLevel } from "../../utils/logging";
import {
  FETCH_GUEST_DETAILS,
  guestDetailsError,
  guestDetailsPending,
  guestDetailsSuccess,
  IFetchGuestDetails,
} from "../actions/GuestDetails-Actions";
import { getGuestDetailsApi } from "../api/GuestDetails-API";
import { IGuestDetails } from "../types/GuestDetails";

export function registerGuestDetailsSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_GUEST_DETAILS, fetchGuestDetails);
  });
}

function* fetchGuestDetails(action: IFetchGuestDetails): unknown {
  yield put(guestDetailsPending(action.visitId));

  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const detailsResponse: IGuestDetails = (yield call(
      getGuestDetailsApi,
      clientId,
      action.visitId
    )).data;
    yield put(guestDetailsSuccess(detailsResponse, action.visitId));
  } catch (e) {
    Logging.captureError("Saga:fetchGuestDetails", e, SeverityLevel.Error);
    yield put(
      guestDetailsError(
        action.visitId,
        "Failed to fetch guest details for visit"
      )
    );
  }
}
