import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { CommonStyleClasses } from "../../../../../assets";
import i18n from "../../../../../localizations/i18n";
import { CardDetailsStyles } from "../styles/cardDetailsStyles";
import { VoucherResponse } from "../../../../../state/types";
import {
  toggleProductOption,
  toggleProduct,
  InactiveToggleProductNames,
} from "../../../../../state/types/Voucher";
interface IProps {
  labelId: string;
  value?: IDropdownValue | undefined;
  labelText?: string | undefined;
  fieldDefaultText?: string | undefined;
  onTextInput: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSelectToggleProduct: (option: toggleProductOption) => void;
  setToggleOption: (option: toggleProduct) => void;
  setVoucherValue: (value: number) => void;
  voucherReason: string | undefined;
  voucherValue: number | undefined;
  voucherUpperLimit: number;
  voucherOptions: VoucherResponse | undefined;
  currentlySelectedToggleProduct: toggleProductOption | undefined;
  currentlySelectedToggleOption: toggleProduct | undefined;
}
interface IDropdownValue {
  id: number;
  name: string;
}
const CardDetails = (props: IProps) => {
  const classes = CardDetailsStyles();
  const commonClasses = CommonStyleClasses();
  const toggleReasonCharacterLimit = 185;
  const [useCustomValue, setUseCustomValue] = useState<boolean>(false);
  const [customValueIsValid, setCustomValueIsValid] = useState<boolean>(true);
  const [valueIsBelowLimit, setVoucherIsBelowLimit] = useState<boolean>(true);
  const [renderSubOption, setRenderSubOptions] = useState<boolean>(false);

  useEffect(() => {
    if (props.currentlySelectedToggleProduct) {
      if (useCustomValue === true) {
        setUseCustomValue(false);
      }
      if (
        props.currentlySelectedToggleProduct?.toggleProductOptions.length <=
          1 &&
        renderSubOption === true
      ) {
        setRenderSubOptions(false);
      }
      if (
        props.currentlySelectedToggleProduct?.toggleProductOptions.length > 1 &&
        renderSubOption === false
      ) {
        setRenderSubOptions(true);
      }
    }
  }, [props.currentlySelectedToggleProduct]);

  useEffect(() => {
    if (props.currentlySelectedToggleOption) {
      if (props.currentlySelectedToggleOption.toggleProductOptionPrice === 0) {
        setUseCustomValue(true);
      }
      if (props.currentlySelectedToggleOption.toggleProductOptionPrice !== 0) {
        setUseCustomValue(false);
      }
    }
  }, [props.currentlySelectedToggleOption]);

  const handleCustomValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputAsNumber = parseFloat(event?.target.value);
    setCustomValueIsValid(true);
    if (
      inputAsNumber % 1 === 0 &&
      inputAsNumber <= props.voucherUpperLimit &&
      inputAsNumber > 0
    ) {
      setCustomValueIsValid(true);
      const inputInPennies = inputAsNumber * 100;
      props.setVoucherValue(inputInPennies);
      return;
    }
    if (inputAsNumber > props.voucherUpperLimit) {
      setVoucherIsBelowLimit(false);
    }
    setCustomValueIsValid(false);
  };

  const selectToggleProduct = (id: number) => {
    const selectedOption = props.voucherOptions?.toggleVoucherProducts.find(
      (option) => option.toggleProductId === id
    );
    if (selectedOption) {
      props.onSelectToggleProduct(selectedOption);
    }
    if (selectedOption?.toggleProductOptions.length === 1) {
      props.setToggleOption(selectedOption.toggleProductOptions[0]);
      props.setVoucherValue(
        selectedOption.toggleProductOptions[0].toggleProductOptionPrice
      );
    }
    if (selectedOption && useCustomValue === true) {
      setUseCustomValue(false);
    }
  };

  const selectToggleProductOption = (id: number) => {
    const selectedOption =
      props.currentlySelectedToggleProduct?.toggleProductOptions.find(
        (option) => option.toggleProductOptionId === id
      );
    if (selectedOption) {
      props.setToggleOption(selectedOption);
      props.setVoucherValue(selectedOption.toggleProductOptionPrice);
    }
  };

  const blockInvalidChar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();
  };
  const errorText = () => {
    if (!valueIsBelowLimit) {
      return `${i18n.translate("GIFT_CARD_VALUE_UPPER_LIMIT_ERROR")}£${
        props.voucherUpperLimit
      }`;
    }
    return i18n.translate("GIFT_CARD_CUSTOM_VALUE_VALIDATION_ERROR_TEXT");
  };
  const subOptionValue = () => {
    if (props.currentlySelectedToggleOption) {
      if (props.currentlySelectedToggleOption.toggleProductOptionDescription) {
        return props.currentlySelectedToggleOption
          .toggleProductOptionDescription;
      }
      if (props.currentlySelectedToggleOption.toggleProductOptionPrice) {
        const price =
          props.currentlySelectedToggleOption.toggleProductOptionPrice / 100;
        return `£${price}.00`;
      }
    }
  };

  const renderToggleOptionsListItem = (toggleOption: toggleProductOption) => {
    if (
      toggleOption.toggleProductDescription !==
      InactiveToggleProductNames.InStoreGift
    ) {
      return (
        <MenuItem
          key={toggleOption.toggleProductId}
          value={toggleOption.toggleProductId}
        >
          {toggleOption.toggleProductDescription}
        </MenuItem>
      );
    }
  };
  return (
    <>
      <div className={classes.smallTopMargin}>
        <TextField
          inputProps={{ maxLength: toggleReasonCharacterLimit }}
          className={`${classes.reasonInput}`}
          placeholder={i18n.translate("GIFT_CARD_REASON_FOR_CARD")}
          label={i18n.translate("GIFT_CARD_REASON_FOR_CARD")}
          id={i18n.translate("GIFT_CARD_REASON_FOR_CARD")}
          value={props.voucherReason}
          onChange={(event) => props.onTextInput(event?.target.value)}
          variant="outlined"
          multiline={true}
          fullWidth
          required={true}
        />
        {props.voucherReason && (
          <span className={classes.reasonSpan}>
            {toggleReasonCharacterLimit - props.voucherReason.length}

            {i18n.translate("COMMON_CHARACTERS_REMAINING")}
          </span>
        )}
      </div>
      <div className={classes.voucherInputs}>
        <FormControl className={classes.voucherControls}>
          <InputLabel id="voucher-product-select" className={classes.labelText}>
            {i18n.translate("TOGGLE_SELECT_PRODUCT")}
          </InputLabel>
          <Select
            labelId="voucher-product-select"
            id={props.labelId}
            value={
              props.currentlySelectedToggleProduct?.toggleProductDescription
            }
            onChange={(event) =>
              selectToggleProduct(event?.target.value as number)
            }
            variant="outlined"
            required={true}
          >
            {props.voucherOptions?.toggleVoucherProducts.map((toggleOption) => {
              return renderToggleOptionsListItem(toggleOption);
            })}
          </Select>
        </FormControl>
        {renderSubOption && (
          <FormControl className={classes.voucherControls}>
            <InputLabel
              id="voucher-option-select"
              className={classes.labelText}
            >
              {i18n.translate("TOGGLE_SELECT_OPTION")}
            </InputLabel>
            <Select
              labelId={`voucher-option-select`}
              id={props.labelId}
              value={subOptionValue()}
              onChange={(event) =>
                selectToggleProductOption(event?.target.value as number)
              }
              variant="outlined"
              required={true}
            >
              {props.currentlySelectedToggleProduct?.toggleProductOptions.map(
                (option) => {
                  return (
                    <MenuItem
                      key={option.toggleProductOptionId}
                      value={option.toggleProductOptionId}
                    >
                      {option.toggleProductOptionDescription ||
                        `£${option.toggleProductOptionPrice / 100}.00`}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        )}
        {useCustomValue && (
          <>
            <TextField
              error={useCustomValue && !customValueIsValid}
              helperText={!customValueIsValid ? errorText() : ""}
              variant="outlined"
              className={`${classes.voucherSelect} ${classes.voucherControls} ${classes.reasonInput}`}
              label={i18n.translate("GIFT_CARD_CUSTOM_VALUE_PLACEHOLDER")}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              onKeyDown={(event) => blockInvalidChar(event)}
              onChange={(event) => handleCustomValue(event)}
            />
            {!customValueIsValid && <>{errorText()}</>}
          </>
        )}
      </div>
    </>
  );
};
export default CardDetails;
