export const getUrlQueryValueString = (key: string): string | undefined => {
  const queryParts = getQueryParts();
  if (queryParts && queryParts[key]) {
    return queryParts[key];
  }
};

export const getUrlQueryValueNumber = (key: string): number | undefined => {
  const queryParts = getQueryParts();
  if (queryParts && queryParts[key]) {
    return Number.parseInt(queryParts[key]);
  }
};

const getQueryParts = () => {
  const query = window.location.search.substring(1);

  const urlParts: any = {};
  let match: RegExpExecArray | null = null;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s: string) {
    return decodeURIComponent(s.replace(/\+/g, " ").toLowerCase());
  };

  while ((match = search.exec(query)) !== null) {
    urlParts[decode(match[1])] = decode(match[2]);
  }

  return urlParts;
};
