import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  outer: {
    margin: "0 20px 10px 0",

    [theme.breakpoints.down("md")]: {
      margin: "0 0 10px 0",
    },
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  textFilter: {
    flexGrow: 10,
    backgroundColor: hgemColours.White,
    "& input": {
      fontSize: "0.8em",
    },

    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  contactFilter: {
    flexShrink: 1,
  },
  filterState: {
    color: theme.palette.grey[500],
    fontSize: "0.8em",
    margin: "5px 0",
  },
}));

export const CaseListFilterStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
