import { Button, Icon } from "@material-ui/core";
import { i18n } from "../../../localizations";
import {
  WorkflowStatus,
  WorkflowStatusType,
} from "../../../state/types/TaskCentreCases";
import WorkflowStatusIcon from "../../_common/Icons/workflowStatusIcon";
import { WorkflowStatusFieldStyles } from "../styles/inputs/workflowStatusFieldStyles";

type props = {
  statusValue: WorkflowStatus;
  changeStatus: (WorkflowStatus: WorkflowStatus) => void;

  workflowStatuses: WorkflowStatusType[];
};

const WorkflowStatusButtons = (props: props) => {
  const { statusValue, changeStatus, workflowStatuses } = props;
  const classes = WorkflowStatusFieldStyles();

  const workFlowIconRequired = (workflowStatus: WorkflowStatus) => {
    return (
      workflowStatus === WorkflowStatus.ActionRequired ||
      workflowStatus === WorkflowStatus.InProgress
    );
  };
  return (
    <div className={classes.selectionContainer}>
      <span className={classes.labelText}>
        {i18n.translate("TASK_CENTRE_STATUS_PICKER_Status")}
      </span>
      {workflowStatuses.map((status) => {
        const isSelected = status.value === statusValue;
        const buttonClass = isSelected
          ? classes.selectedButton
          : classes.deselectedButton;

        const iconOpacity = isSelected ? "selected" : "deselected";
        return (
          <>
            <Button
              key={status.value}
              size="small"
              className={buttonClass}
              onClick={() => changeStatus(status.value)}
            >
              <WorkflowStatusIcon
                status={status.value}
                className={iconOpacity}
              />
              <span className={classes.buttonText}>{status.text}</span>
            </Button>
            {workFlowIconRequired(status.value) && (
              <Icon className={`fas fa-caret-right ${classes.flowIcon}`} />
            )}
          </>
        );
      })}
    </div>
  );
};

export default WorkflowStatusButtons;
