import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  popupContainer: {
    position: "fixed",
    minHeight: "100px",
    bottom: "0px",
    width: "100%",
    zIndex: 99999,
    color: theme.palette.primary.contrastText,
    "&.green": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.amber": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  popupContent: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: "32px",
    marginBottom: "10px",
  },
  icon: {
    verticalAlign: "middle",
    fontSize: "2.5em",
    marginRight: "20px",
  },
  message: {
    marginRight: "20px",
  },
  refreshButton: {
    verticalAlign: "baseline",
    color: theme.palette.primary.contrastText,
    marginTop: "5px",
  },
}));

export const SessionManagerStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
