import { ButtonBase } from "@material-ui/core";
import { ConverationCardStyles } from "../styles/conversationCardStyles";
import dayjs from "dayjs";
import { Conversation } from "../../../state/types/Conversations";
import truncate from "lodash.truncate";
import { i18n } from "../../../localizations";
import ConversationIcon from "./conversationIcon";
import { formatSender } from "../utils";

interface IProps {
  conversation: Conversation;
  isSelected: boolean;
  onItemSelected: (id: number) => void;
}

const ConversationCard = (props: IProps) => {
  const classes = ConverationCardStyles();
  const selectedStyle = props.isSelected ? "selected" : "";

  const hasUnreadMessages = (conversation: Conversation) => {
    const [mostRecentMessageIsInbound, mostRecentInboundMessageId, readCursorMessageId] = 
      [conversation.mostRecentMessageIsInbound, conversation.mostRecentInboundMessageId, conversation.readCursorMessageId];

    if (!mostRecentMessageIsInbound) {
      return false;
    }

    if (!mostRecentInboundMessageId) {
      return false;
    }

    if (!readCursorMessageId) {
      return true;
    }

    return readCursorMessageId < mostRecentInboundMessageId;
  }

  const source = (conversation: Conversation) => {
    return <ConversationIcon source={conversation.source} />;
  };

  const sender = (conversation: Conversation) => {
    return (
      <span className={`${classes.visitTitle} ${hasUnreadMessages(conversation) ? classes.highlightUnread : ""}`}>
        {formatSender(conversation.sender, conversation.source)}
      </span>
    );
  };

  const lastMessageDate = (conversation: Conversation) => {
    const dayJsDate = dayjs(conversation.mostRecentMessageCreatedDate, {
      utc: true,
    });

    const daysAgo = dayJsDate.diff(dayjs(), "day");

    let dateText: string | undefined;
    let dateFormat: string | undefined;

    switch (daysAgo) {
      case 0:
        dateFormat = i18n.translate("CONVERSATION_MESSAGE_TIME_FORMAT");
        break;
      case 1:
        dateText = i18n.translate("CONVERSATION_MESSAGE_YESTERDAY");
        break;
      default:
        dateFormat = i18n.translate("CONVERSATION_MESSAGE_DATE_FORMAT");
        break;
    }

    return (
      <span
        className={`${classes.infoBullet} ${classes.wideColumnVisibleOverflow}`}
      >
        <span className={hasUnreadMessages(conversation) ? classes.highlightUnread : ""}>
          {dateText ?? dayJsDate.local().format(dateFormat)}
        </span>
      </span>
    );
  };

  const lastMessageText = (conversation: Conversation) => {
    return (
      <span className={`${classes.visitTitle} ${hasUnreadMessages(conversation) ? classes.highlightUnread : ""}`}>
        {conversation.mostRecentMessageText &&
          truncate(conversation.mostRecentMessageText, {
            length: 25,
          })}
      </span>
    );
  };

  return (
    <ButtonBase
      onClick={() => props.onItemSelected(props.conversation.conversationId)}
      className={`${classes.visitDetailsItem} ${selectedStyle}`}
    >
      <div className={classes.simpleFlexRow}>
        <div className={`${classes.iconFlexColumm} ${classes.iconMargin}`}>
          {source(props.conversation)}
        </div>
        <div className={`${classes.flexColumn} ${classes.fullWidthFlex}`}>
          <div className={`${classes.flexRow}`}>
            {sender(props.conversation)}
          </div>
          <div className={classes.flexRow}>
            {lastMessageDate(props.conversation)}
            {lastMessageText(props.conversation)}
          </div>
        </div>
      </div>
    </ButtonBase>
  );
};

export default ConversationCard;
