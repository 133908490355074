import { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { DesktopViewStyles } from "./styles/desktopViewStyles";
import i18n from "../../localizations/i18n";
import { useDispatch, useSelector } from "react-redux";
import { SelectedCase, SelectedCaseMessageCount } from "./state/selectors";
import InboxTaskDetails from "../inboxTaskDetails/inboxTaskDetails";
import InboxMedia from "../inboxMedia/inboxMedia";
import InboxReport from "../inboxReport/inboxReport";
import TabPanel from "./components/tabPanel";
import TabLabel from "./components/tabLabel";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import { updateCurrentFiltersEntry } from "../../state/actions/FilterSets-Actions";
import { HubFilterType, IVisitIdFilter } from "../../state/types/FilterSets";
import IsAuthorised from "../../authorisation/permissions";
import { PermissionType } from "../../authorisation/permissionsUtils";
import InboxGuestContact from "../InboxGuestContact/inboxGuestContact";
import InboxTasksList from "../inboxTasksList/inboxTasksList";
import TaskCategoriesLoader from "./components/taskCategoriesLoader";
import TaskManagersLoader from "./components/taskManagersLoader";
import { MobileViewStyles } from "./styles/mobileViewStyles";
import { voucherDetails } from "../../state/hooks";
import { voucherConfiguration } from "../../state/actions/Vouchers-Actions";

import { useClientId } from "../../state/hooks/clients/useClientId";
import { GetVisibleFilters } from "../reportFilterPanel/state/selectors";
import { RootState } from "../../state/reducers/RootReducer";
import BuildVisitDetailsListQuery from "../inboxList/utils/visitDetailsListQueryBuilder";
import { fetchVisitDetailsList } from "../../state/actions/VisitDetails-Actions";
import { PageTabsStyles } from "../_common/pageTabs/styles/pageTabsStyles";
import { getVisitsApi } from "../../state/api/VisitDetails-API";
import { IVisitDetail } from "../../state/types/VisitDetails";
import { useUserId } from "../../state/hooks/clients/useUserId";

interface IProps {
  visitTypes: string[];
  isMobileView: boolean;
}

enum TabIndex {
  Tasks = 0,
  GuestContact = 1,
  VisitReport = 2,
  Media = 3,
}

const TasksView = (props: IProps) => {
  const classes = props.isMobileView ? MobileViewStyles() : DesktopViewStyles();
  const tabClassses = PageTabsStyles();

  const dispatch = useDispatch();
  const clientId = useClientId();
  const userId = useUserId();
  const taskCentrePageRef = "Tasks";
  const [tabIndex, setTabIndex] = useState(0);
  const [userCanReadGuestDetails, setUserCanReadGuestDetails] = useState(false);
  const [
    userCanAccessGuestCommunications,
    setUserCanAccessGuestCommunications,
  ] = useState(false);

  const queryVisitId = getUrlQueryValueNumber("visitid");

  const selectedCase = useSelector(SelectedCase);
  const [associatedVisit, setAssociatedVisit] = useState<IVisitDetail>();
  const messageCount = useSelector(SelectedCaseMessageCount);
  const voucherConfig = voucherDetails({ clientId: clientId });
  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, taskCentrePageRef)
  );

  useEffect(() => {
    if (voucherConfig) {
      dispatch(voucherConfiguration(voucherConfig));
    }
  }, [dispatch, voucherConfig]);

  useEffect(() => {
    const fetchRelatedVisit = async () => {
      let relatedVisitQuery = BuildVisitDetailsListQuery(
        1,
        filters,
        [],
        [],
        selectedCase?.vId,
        undefined
      );
      relatedVisitQuery = { ...relatedVisitQuery, userId: userId };
      const visit = await getVisitsApi(clientId, relatedVisitQuery);
      setAssociatedVisit(visit.data.visits[0]);
      await dispatch(fetchVisitDetailsList(relatedVisitQuery));
    };
    fetchRelatedVisit();
  }, [dispatch, selectedCase]);

  const currentlySelectedTab = () => {
    return tabIndex;
  };

  useEffect(() => {
    dispatch(
      updateCurrentFiltersEntry(HubFilterType.VisitId, (x: IVisitIdFilter) => {
        x.value = queryVisitId;
      })
    );
  }, [dispatch, queryVisitId]);

  useEffect(() => {
    const checkUserCanAccessGuestCommunications = async () => {
      const userPermission = await IsAuthorised(
        PermissionType.GuestCommunicationDisabled
      );
      setUserCanAccessGuestCommunications(userPermission);
    };
    const checkUserCanReadGuestDetails = async () => {
      const userCanReadDetails = await IsAuthorised(
        PermissionType.ReadGuestDetails
      );
      setUserCanReadGuestDetails(userCanReadDetails);
    };
    checkUserCanAccessGuestCommunications();
    checkUserCanReadGuestDetails();
  });

  const mediaTabActive =
    associatedVisit?.hasMedia || associatedVisit?.hasReceipt;

  const guestContactTabActive =
    associatedVisit?.contactAvailable &&
    associatedVisit?.hasEmail &&
    userCanReadGuestDetails &&
    userCanAccessGuestCommunications;

  useEffect(() => {
    if (
      (!mediaTabActive && tabIndex === TabIndex.Media) ||
      (!guestContactTabActive && tabIndex === TabIndex.GuestContact)
    ) {
      setTabIndex(TabIndex.Tasks);
    }
  }, [selectedCase]);

  return (
    <div className={classes.container}>
      <TaskCategoriesLoader />
      <TaskManagersLoader />
      <div className={classes.actionListOuterContainer}>
        <InboxTasksList
          applicableVisitTypes={props.visitTypes}
          showAsPopupList={props.isMobileView}
          requestedVisitId={queryVisitId}
        />
      </div>
      <div className={classes.actionsTabsContainer}>
        <Tabs
          className={classes.tabs}
          variant="scrollable"
          scrollButtons={props.isMobileView ? "on" : "off"}
          indicatorColor="primary"
          classes={{ indicator: tabClassses.blankIndicator }}
          onChange={(_, value) => setTabIndex(value)}
          value={currentlySelectedTab()}
        >
          <Tab
            value={TabIndex.Tasks}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
            label={<TabLabel labelText={i18n.translate("INBOX_TABS_Tasks")} />}
          />
          {guestContactTabActive && (
            <Tab
              value={TabIndex.GuestContact}
              classes={{
                selected: tabClassses.selectedTab,
                root: tabClassses.inactiveTab,
              }}
              label={
                <TabLabel
                  labelText={i18n.translate("INBOX_TABS_GuestCommunications")}
                  showBadge={messageCount > 0}
                />
              }
            />
          )}
          <Tab
            value={TabIndex.VisitReport}
            label={i18n.translate("INBOX_TABS_VisitReport")}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
          />
          {mediaTabActive && (
            <Tab
              value={TabIndex.Media}
              classes={{
                selected: tabClassses.selectedTab,
                root: tabClassses.inactiveTab,
              }}
              label={
                <TabLabel labelText={i18n.translate("INBOX_TABS_Media")} />
              }
            />
          )}
        </Tabs>
        <div className={classes.tabPanel}>
          <TabPanel value={tabIndex} index={TabIndex.Tasks}>
            <>
              <InboxTaskDetails
                visitId={selectedCase?.vId}
                case={selectedCase}
                showTaskSelector={false}
                isMobileView={props.isMobileView}
              />
            </>
          </TabPanel>

          <TabPanel value={tabIndex} index={TabIndex.GuestContact}>
            <>
              {userCanReadGuestDetails && (
                <InboxGuestContact
                  visitId={selectedCase?.vId}
                  case={selectedCase}
                />
              )}
            </>
          </TabPanel>
          <>
            <TabPanel value={tabIndex} index={TabIndex.VisitReport}>
              <InboxReport
                visitId={selectedCase?.vId}
                visitType={selectedCase?.vType ? selectedCase.vType : ""}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={TabIndex.Media}>
              <InboxMedia visitId={selectedCase?.vId} />
            </TabPanel>
          </>
        </div>
      </div>
    </div>
  );
};

export default TasksView;
