import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  pageTabs: {
    margin: "0 0 0 0.5%",
    width: "calc(100% - 120px)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1% 0 0 -4%",
      padding: "0 1.5% 0 1.5%",
    },
  },
  selectedTab: {
    backgroundColor: `${hgemColours.LimeGreen} !important`,
    color: hgemColours.White,
  },
  disabledTab: {
    backgroundColor: hgemColours.White,
    color: hgemColours.ExtraMediumGrey,
    "&.Mui-disabled": {
      color: hgemColours.ExtraMediumGrey,
      backgroundColor: hgemColours.White,
    },
  },
  inactiveTab: {
    backgroundColor: hgemColours.ExtraMediumGrey,
    color: hgemColours.White,
    "&.Mui-disabled": {
      color: hgemColours.ExtraMediumGrey,
      backgroundColor: hgemColours.White,
    },
  },
  blankIndicator: {
    display: "none",
  },
  pageSelector: {
    margin: "auto",
    width: "50%",
    padding: "5px",
  },
  tabScrollButton: {
    [theme.breakpoints.down("xs")]: {
      margin: "0% 2.5% 0 2.5%",
    },
  },
}));

export const PageTabsStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
