import { WorkflowStatus, Priority } from "./TaskCentreCases";

export interface ICaseCommand {
  readonly caseId: number;
  readonly commandType: string;
  authorId: number;
}

export interface IAttachableCaseCommand extends ICaseCommand {
  readonly attachments: File[] | undefined;
}

export class CreateCaseCommand {
  static readonly type: string = "CreateCase";
  readonly commandType = CreateCaseCommand.type;

  readonly visitId: number;
  readonly title: string;
  readonly workflowStatus: WorkflowStatus;
  creatorId = 0;

  constructor(visitId: number, title: string, workflowStatus: WorkflowStatus) {
    this.visitId = visitId;
    this.title = title;
    this.workflowStatus = workflowStatus;
  }
}

export class UpdateDueDateCommand implements ICaseCommand {
  static readonly type: string = "UpdateDueDate";
  readonly commandType = UpdateDueDateCommand.type;

  readonly caseId: number;
  readonly dueDate: string | null;
  authorId = 0;

  constructor(caseId: number, dueDate: Date | null) {
    this.caseId = caseId;
    this.dueDate = dueDate ? dueDate.toUTCString() : null;
  }
}

export class UpdateWorkflowStatusCommand implements ICaseCommand {
  static readonly type: string = "UpdateWorkflowStatus";
  readonly commandType = UpdateWorkflowStatusCommand.type;

  readonly caseId: number;
  readonly workflowStatus: number;
  authorId = 0;

  constructor(caseId: number, workflowStatusId: WorkflowStatus) {
    this.caseId = caseId;
    this.workflowStatus = workflowStatusId;
  }
}

export class ReprioritiseCaseCommand implements ICaseCommand {
  static readonly type: string = "ReprioritiseCase";
  readonly commandType = ReprioritiseCaseCommand.type;

  readonly caseId: number;
  readonly priority: Priority;
  authorId = 0;

  constructor(caseId: number, priority: Priority) {
    this.caseId = caseId;
    this.priority = priority;
  }
}

export class RecategoriseCaseCommand implements ICaseCommand {
  static readonly type: string = "RecategoriseCase";
  readonly commandType = RecategoriseCaseCommand.type;

  readonly caseId: number;
  readonly categoryId: number | null;
  authorId = 0;

  constructor(caseId: number, categoryId: number | null) {
    this.caseId = caseId;
    this.categoryId = categoryId;
  }
}

export class AddCommentCommand implements IAttachableCaseCommand {
  static readonly type: string = "AddComment";
  readonly commandType = AddCommentCommand.type;

  readonly caseId: number;
  readonly comment: string;
  readonly attachments: File[] | undefined;
  authorId = 0;

  constructor(
    caseId: number,
    comment: string,
    attachments: File[] | undefined
  ) {
    this.caseId = caseId;
    this.comment = comment;
    this.attachments = attachments;
  }
}

export class ChangeCaseTitleCommand implements ICaseCommand {
  static readonly type = "ChangeCaseTitle";
  readonly commandType: string = ChangeCaseTitleCommand.type;

  readonly caseId: number;
  readonly title: string;
  authorId = 0;

  constructor(caseId: number, title: string) {
    this.caseId = caseId;
    this.title = title.trim();
  }
}

export class AssignCaseCommand implements ICaseCommand {
  static readonly type = "AssignCase";
  readonly commandType = AssignCaseCommand.type;

  readonly caseId: number;
  readonly assigneeId: number;
  authorId = 0;

  constructor(caseId: number, assigneeId: number) {
    this.caseId = caseId;
    this.assigneeId = assigneeId;
  }
}

export class UnassignCaseCommand implements ICaseCommand {
  static readonly type = "UnassignCase";
  readonly commandType = UnassignCaseCommand.type;

  readonly caseId: number;
  authorId = 0;

  constructor(caseId: number) {
    this.caseId = caseId;
  }
}

export class DeleteCaseCommand implements ICaseCommand {
  static readonly type = "DeleteCase";
  readonly commandType = DeleteCaseCommand.type;

  readonly caseId: number;
  authorId = 0;

  constructor(caseId: number) {
    this.caseId = caseId;
  }
}

export class ContactGuestCommand implements IAttachableCaseCommand {
  static readonly type = "SendMessage";
  readonly commandType: string = ContactGuestCommand.type;

  readonly caseId: number;
  readonly subject: string;
  readonly message: string;
  readonly recipient: string;
  readonly attachments: File[] | undefined;
  authorId = 0;

  constructor(
    caseId: number,
    recipient: string,
    subject: string,
    message: string,
    attachments: File[] | undefined
  ) {
    this.caseId = caseId;
    this.subject = subject;
    this.message = message;
    this.recipient = recipient;
    this.attachments = attachments;
  }
}

export class GuestContactNotes implements ICaseCommand {
  static readonly type = "ConfirmGuestContact";
  readonly commandType: string = GuestContactNotes.type;

  readonly caseId: number;
  readonly comment: string;
  authorId = 0;

  constructor(caseId: number, comment: string) {
    this.caseId = caseId;
    this.comment = comment;
  }
}

export class VoucherSentCommand implements ICaseCommand {
  static readonly type = "VoucherSent";
  readonly commandType: string = VoucherSentCommand.type;

  readonly caseId: number;
  readonly voucherSent: boolean;
  authorId = 0;

  constructor(caseId: number) {
    this.caseId = caseId;
    this.voucherSent = true;
  }
}

export class AttachmentsAddedCommand implements IAttachableCaseCommand {
  static readonly type = "AddAttachments";
  readonly commandType: string = AttachmentsAddedCommand.type;

  readonly caseId: number;
  authorId = 0;
  readonly attachments: File[] | undefined;

  constructor(caseId: number, attachments: File[]) {
    this.caseId = caseId;
    this.attachments = attachments;
  }
}
