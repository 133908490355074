import { Divider, Hidden, Typography } from "@material-ui/core";
import { SelectedQuestionsList, AppealStatus } from "./";
import { AppealsStyles } from "../styles";
import {
  ICaseDetails,
  WorkflowStatus,
} from "../../../state/types/TaskCentreCases";
import { i18n } from "../../../localizations";
import {
  getAppealApproverEvent,
  getAppealBonusAppealOnly,
  getAppealQuestions,
} from "../utils";

type Props = {
  currentAppeal: ICaseDetails;
  buttonComponent?: JSX.Element;
};
const AppealInformation = (props: Props) => {
  const { currentAppeal } = props;
  const appealsClasses = AppealsStyles();

  const approverEvent = getAppealApproverEvent(currentAppeal);

  const bonusAppealOnly = getAppealBonusAppealOnly(currentAppeal);

  const questionSubjects = getAppealQuestions(currentAppeal).map(
    (x) => x.questionSubject
  );

  return (
    <div>
      <span className={`${appealsClasses.flexRow} ${appealsClasses.topRow}`}>
        <AppealStatus status={currentAppeal.wfStatus} />
        <Typography className={`${appealsClasses.appealsApproverHeader}`}>
          <span className={appealsClasses.boldText}>
            {i18n.translate("APPEALS_APPROVER_HEADER")}
          </span>
          <span className={appealsClasses.bodyText}>
            {approverEvent?.assigneeName}
          </span>
        </Typography>
        <Hidden implementation="js" xsDown>
          <span className={appealsClasses.appealStatus}>
            {props.buttonComponent && props.buttonComponent}
          </span>
        </Hidden>
      </span>
      <span className={`${appealsClasses.appealsQuestionRow}`}>
        {questionSubjects.length > 0 && (
          <SelectedQuestionsList questions={questionSubjects} />
        )}
        {questionSubjects.length === 0 && (
          <Typography className={`${appealsClasses.staticTextBlock}`}>
            {i18n.translate(
              bonusAppealOnly
                ? "APPEALS_QUESTIONS_BONUS_APPEAL_ONLY"
                : "APPEALS_QUESTIONS_WHOLE_REPORT_SELECTED"
            )}
          </Typography>
        )}
      </span>
      <Hidden implementation="css" xsUp>
        <span className={appealsClasses.appealStatus}>
          {props.buttonComponent && props.buttonComponent}
        </span>
      </Hidden>
      {currentAppeal.category &&
        currentAppeal.appealReviewDecision &&
        (currentAppeal.wfStatus === WorkflowStatus.AppealGranted ||
          currentAppeal.wfStatus === WorkflowStatus.AppealRejected) && (
          <>
            <Divider
              light={true}
              style={{ marginTop: "1.5%", marginBottom: "2.5%" }}
            />
            <div
              className={`${appealsClasses.flexRow} ${appealsClasses.appealClosedInfoRow}`}
            >
              <span className={`${appealsClasses.appealStatus}`}>
                <Typography className={`${appealsClasses.categoryTextBlock}`}>
                  <span className={appealsClasses.boldText}>
                    {i18n.translate("APPEALS_CATEGORY_HEADER")}
                  </span>
                  {currentAppeal.category?.name}
                </Typography>
              </span>
              <Typography className={`${appealsClasses.appealsApproverHeader}`}>
                <span className={appealsClasses.boldText}>
                  {i18n.translate("APPEALS_REVIEW_DECISION_HEADER")}
                </span>
                {currentAppeal.appealReviewDecision?.name}
              </Typography>
            </div>
          </>
        )}
    </div>
  );
};
export default AppealInformation;
