import React, { useEffect, useState } from "react";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { filterTaskCaseItems } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { CaseListFilterStyles } from "../styles/caseListFilterStyles";
import i18n from "../../../localizations/i18n";
import { InboxTasksListStyles } from "../styles/inboxTasksListStyles";

interface IProps {
  caseCount: number;
  totalCasesLoaded: number;
  appliedSearchText: string;
}

const CaseListFilter = (props: IProps) => {
  const classes = CaseListFilterStyles();
  const dispatch = useDispatch();
  const textClass = InboxTasksListStyles();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const searchTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        executeSearch(value);
      }, 500)
    );
  };

  const clearSearch = () => {
    if (searchText && searchText.length > 0) {
      setSearchText("");
      executeSearch("");
    }
  };

  const executeSearch = (searchText: string) => {
    dispatch(filterTaskCaseItems(searchText.trim()));
  };

  useEffect(() => {
    if (searchText === null) {
      props.appliedSearchText.length > 0
        ? setSearchText(props.appliedSearchText)
        : setSearchText("");
    }
  }, [props.appliedSearchText, searchText]);

  return (
    <div className={classes.outer}>
      <div className={classes.filterContainer}>
        {searchText !== null && (
          <TextField
            className={classes.textFilter}
            variant="outlined"
            size="small"
            title={i18n.translate(
              "TASK_CENTRE_CASE_LIST_FILTER_PlaceholderText"
            )}
            placeholder={i18n.translate(
              "TASK_CENTRE_CASE_LIST_FILTER_PlaceholderText"
            )}
            value={searchText}
            onChange={searchTextChanged}
            inputProps={{ maxLength: 100 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={searchText.length === 0}
                  size="small"
                  title={i18n.translate(
                    "TASK_CENTRE_CASE_LIST_FILTER_ClearSearch"
                  )}
                  onClick={clearSearch}
                >
                  <CloseIcon />
                </IconButton>
              ),
            }}
          />
        )}
      </div>
      <span className={textClass.caseCountText}>
        {i18n.translate("TASK_CENTRE_CASE_LIST_FILTER_OrderText")}
      </span>
      {props.totalCasesLoaded > 0 &&
        props.caseCount !== props.totalCasesLoaded && (
          <div className={classes.filterState}>
            {i18n.translate("TASK_CENTRE_CASE_LIST_FILTER_Showing")}
            {props.caseCount}
            {i18n.translate("TASK_CENTRE_CASE_LIST_FILTER_Cases_out_of")}
            {props.totalCasesLoaded}
          </div>
        )}
    </div>
  );
};

export default CaseListFilter;
