import {
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { VisitDateSelectionType } from "../../../state/types/FilterSets";
import { hgemColours } from "../../../themes/defaultTheme";

interface IProps {
  onCancel: () => void;
  onApply: (type: VisitDateSelectionType) => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  actionContainer: {
    borderTop: `1px solid ${hgemColours.LightGrey}`,
    padding: theme.spacing(1),
  },
}));

const RelativeDatePicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <List component="nav" dense>
        <ListItem
          button
          onClick={() => props.onApply(VisitDateSelectionType.LastWeek)}
        >
          <ListItemText
            primary={i18n.translate("DATE_PERIOD_PICKER_LastWeek")}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => props.onApply(VisitDateSelectionType.LastTwoWeeks)}
        >
          <ListItemText
            primary={i18n.translate("DATE_PERIOD_PICKER_LastTwoWeeks")}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => props.onApply(VisitDateSelectionType.LastFourWeeks)}
        >
          <ListItemText
            primary={i18n.translate("DATE_PERIOD_PICKER_LastFourWeeks")}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => props.onApply(VisitDateSelectionType.LastTwelveWeeks)}
        >
          <ListItemText
            primary={i18n.translate("DATE_PERIOD_PICKER_LastTwelveWeeks")}
          />
        </ListItem>
      </List>
      <div className={classes.actionContainer}>
        <Button onClick={props.onCancel}>
          {i18n.translate("DATE_PERIOD_PICKER_Back")}
        </Button>
      </div>
    </>
  );
};

export default RelativeDatePicker;
