import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  scrollContainer: {
    height: "75vh",
  },
  guestDetailsContainer: {
    padding: "5px",
    display: "flex",
    flexDirection: "row",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "2%",
    marginLeft: "2%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "3.5%",
      flexDirection: "column",
      marginBottom: "1%",
    },
  },
  wideGap: {
    gap: "10%",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  guestIcon: {
    fontSize: "1.6em",
    height: "40px",
    width: "40px",
    color: theme.palette.grey[400],
    border: `2px solid ${theme.palette.grey[300]}`,
    borderRadius: "40px",
    marginTop: "5px",

    "& span": {
      textAlign: "center",
      display: "block",
      height: "100%",
      marginTop: "7px",
    },
  },
  guestDetailItems: {
    display: "inline-grid",
    fontSize: "1em",
    marginLeft: "10px",
    color: theme.palette.grey[700],
  },
  guestName: {
    color: hgemColours.LimeGreen,
    fontSize: "1.4em",
  },

  messageInput: {
    marginTop: "2%",
    backgroundColor: hgemColours.White,
    "& label": {
      color: theme.palette.grey[400],
    },

    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
  },

  postButton: {
    position: "relative",
    float: "right",
    marginLeft: "10px",
  },
  postButtonError: {
    position: "absolute",
    right: 0,
    color: hgemColours.Red,
  },

  pendingText: {
    color: theme.palette.grey[500],
  },

  smallLightGreyText: {
    color: theme.palette.grey[500],
    fontSize: "0.85em",
  },
  checkbox: {
    color: theme.palette.grey[400],
  },
  retryButton: {
    display: "block",
    marginTop: "10px",
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  templateButton: {
    marginTop: "2%",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    overflow: "visible",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "3%",
      marginLeft: "-1%",
    },
  },
  headerIcon: {
    [theme.breakpoints.up("xs")]: {
      width: "15%",
      marginLeft: "3%",
    },
  },
}));

export const GuestContactFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
