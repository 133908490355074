export const APPLICATION_RESET = "APPLICATION_RESET";
export const RESET_PERSISTED_STATE_PENDING_FLAG =
  "RESET_PERSISTED_STATE_PENDING_FLAG";

export interface IApplicationReset {
  type: typeof APPLICATION_RESET;
  callback: () => void;
}

export function resetApplicationState(callback: () => void): IApplicationReset {
  return {
    type: APPLICATION_RESET,
    callback,
  };
}

export interface IResetPersistedStatePendingFlag {
  type: typeof RESET_PERSISTED_STATE_PENDING_FLAG;
}

export function resetPersistedStatePendingFlag(): IResetPersistedStatePendingFlag {
  return {
    type: RESET_PERSISTED_STATE_PENDING_FLAG,
  };
}

export type ApplicationActions =
  | IApplicationReset
  | IResetPersistedStatePendingFlag;
