import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialog: {
    "& div[role=dialog]": {
      minHeight: "200px",
      minWidth: "500px",

      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
    },

  },
  audioPlayer: {
    width: "100%",
    marginTop: "10px",
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.75)",
    borderRadius: "30px"
  }
}));

export const VisitAudioModalStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
