import { Icon, SvgIcon } from "@material-ui/core";
import { TypeMap, customIconMap } from "../utils";
import { InboxItemStyles } from "../styles/inboxItemStyles";

export const iconMap = {
  list: "fas fa-tasks",
  media: "fas fa-images",
  star: "fas fa-star-half-alt",
  person: "fas fa-user-check",
  comment: "fas fa-comment",
  box: "fas fa-box-open",
  foursquare: "fab fa-foursquare",
  yahoo: "fab fa-yahoo",
  yelp: "fab fa-yelp",
  Email: "far fa-envelope",
  hourglass: "fas fa-hourglass-start",
  comments: "far fa-comments",
  Phone: "fas fa-phone",
  Twitter: "fab fa-twitter",
  question: "fas fa-question",
  appeal: "fas fa-file-exclamation",
  complaint: "fas fa-ticket-alt",
  conversations: "fas fa-comment",
  gift: "far fa-gift",
};
const typeMap: TypeMap = {
  MV: "person",
  RC: "person",
  PA: "person",
  RV: "star",
  OF: "comment",
  CC: "star",
  DA: "box",
  DR: "box",
  TA: "Phone",
  EF: "complaint",
  CV: "conversations",
};

interface IconProp {
  icon?: keyof typeof iconMap | keyof typeof customIconMap;
  className?: string;
}

const findIcon = (iconName: string): boolean => {
  return iconName in iconMap;
};

const isVisitTypeAbbreviation = (iconName: string): boolean => {
  return iconName in typeMap;
};
const isBrandIcon = (iconName: string): boolean => {
  return iconName.toLowerCase() in customIconMap;
};
const InboxListIcon = (props: IconProp) => {
  const classes = InboxItemStyles();
  let iconKey: string | undefined;
  let brandIconKey: string | undefined;
  if (props.icon) {
    if (findIcon(props.icon)) {
      iconKey = props.icon;
    }
    if (isVisitTypeAbbreviation(props.icon)) {
      iconKey = typeMap[props.icon as keyof TypeMap];
    }
    if (isBrandIcon(props.icon)) {
      brandIconKey = props.icon.toLowerCase();
    }
  }
  return (
    <>
      {brandIconKey && (
        <SvgIcon
          className={classes.brandIcon}
          component={customIconMap[brandIconKey as keyof typeof customIconMap]}
          viewBox="0 0 48 48"
        />
      )}
      {iconKey && !brandIconKey && (
        <Icon
          style={{ overflow: "visible" }}
          className={`${iconMap[iconKey as keyof typeof iconMap]} ${
            props.className
          }`}
        />
      )}
    </>
  );
};

export default InboxListIcon;
