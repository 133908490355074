import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { AuthServiceInstance } from "../../../authentication/authService";
import i18n from "../../../localizations/i18n";
import { Logging, SeverityLevel } from "../../../utils/logging";
import {
  fetchVisitReportReviewSourceDetailsApi,
  IReviewSourceDetails,
} from "../state/api";
import { ReviewResponseLinkStyles } from "../styles/reviewResponseLinkStyles";

interface IProps {
  visitId: number;
  sourceName: string | undefined;
}

enum responseAction {
  openDirectly,
  showSelection,
}

enum VisitSources {
  google = "Google",
}

const ReviewResponseLink = (props: IProps) => {
  const classes = ReviewResponseLinkStyles();

  const [busy, setBusy] = useState(false);
  const [responseViewOpen, setResponseViewOpen] = useState(false);
  const [sourceDetails, setSourceDetails] =
    useState<IReviewSourceDetails | null>(null);
  const isGoogle = props.sourceName === VisitSources.google;
  const handleOnRespond = async () => {
    setBusy(true);
    let action = responseAction.showSelection;
    let details: IReviewSourceDetails | null = null;

    try {
      const clientId = await AuthServiceInstance.getClientId();
      const apiResponse = await fetchVisitReportReviewSourceDetailsApi(
        clientId,
        props.visitId
      );

      if (apiResponse) {
        if (apiResponse.responseUrl || apiResponse.reviewUrl) {
          details = apiResponse;
          if (details.responseUrl === details.reviewUrl) {
            action = responseAction.openDirectly;
          } else {
            action = responseAction.showSelection;
          }
        }
      }
    } catch (e) {
      Logging.captureError(
        "Error fetching review source details",
        e,
        SeverityLevel.Error
      );
    }

    actionResponse(action, details);
    setBusy(false);
  };

  const actionResponse = (
    action: responseAction,
    details: IReviewSourceDetails | null
  ) => {
    if (action === responseAction.openDirectly && details?.responseUrl) {
      window.open(details.responseUrl, "_blank");
    } else if (isGoogle && details?.reviewUrl) {
      window.open(details.reviewUrl, "_blank");
    } else {
      setSourceDetails(details);
      setResponseViewOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setResponseViewOpen(false);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleOnRespond}
        disabled={busy}
        variant="contained"
        color="primary"
        size="small"
      >
        {props.sourceName === VisitSources.google
          ? i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_View_Only")
          : i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_View_Respond")}
        {props.sourceName}
      </Button>

      <Dialog open={responseViewOpen}>
        <DialogTitle>
          {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Title")}
        </DialogTitle>
        <DialogContent>
          {!sourceDetails?.responseUrl && !sourceDetails?.reviewUrl && (
            <div className={classes.dialogOption}>
              {i18n.translate(
                "VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage"
              )}
              <a
                href={i18n.translate(
                  "VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage_ContactLink"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.translate(
                  "VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage_ContactUs"
                )}
              </a>
            </div>
          )}
          {sourceDetails?.reviewUrl && (
            <div className={`${classes.dialogOption} ${classes.optionBold}`}>
              {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_ViewReview")}
              <a
                href={sourceDetails.reviewUrl || sourceDetails.responseUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sourceDetails.reviewUrl || sourceDetails.responseUrl}
              </a>
            </div>
          )}
          {sourceDetails?.responseUrl && (
            <div className={`${classes.dialogOption} ${classes.optionBold}`}>
              {props.sourceName === VisitSources.google
                ? i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_ViewReview")
                : i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_RespondReview")}
              <a
                href={sourceDetails.responseUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sourceDetails.responseUrl}
              </a>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDialog}>
            {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewResponseLink;
