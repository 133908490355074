import { GuestContactFieldStyles } from "../styles/guestContactFieldStyles";

type Props = {
  guestName?: string;
  emailAddress?: string;
  mobileNumber?: string;
};

const GuestContactHeader = (props: Props) => {
  const classes = GuestContactFieldStyles();
  const { guestName, emailAddress, mobileNumber } = props;
  return (
    <div className={classes.guestDetailsContainer}>
      <span className={classes.guestIcon}>
        <span className="icon fas fa-user" />
      </span>
      <span className={classes.guestDetailItems}>
        <span className={classes.guestName}>{guestName}</span>
        {emailAddress && <span>{emailAddress}</span>}
        {mobileNumber && <span>{mobileNumber}</span>}
      </span>
    </div>
  );
};

export default GuestContactHeader;
