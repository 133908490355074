import { RootState } from "../../../state/reducers/RootReducer";

export const UserNavigationOptionsSelector = (state: RootState) => {
  const options = state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.navigationOptions
    : [];

  return options;
};

export const UserNameSelector = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.UserName
    : "";
};

export const UserClientsSelector = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.availableClients
    : [];
};

export const UserBrandsSelector = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.availableBrands.map((x) => x.name)
    : [];
};

export const UserCurrentBrandsSelector = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.currentBrands
    : [];
};

export const UserCanSwitchClientsSelector = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.userCanSwitchClients
    : false;
};

export const UserCanSwitchBrandsSelector = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.userCanSwitchBrands
    : false;
};

export const UserImpersonationSelector = (state: RootState) =>
  state.UserImpersonationReducer.userImpersonation;

export const UserImpersonationPendingSelector = (state: RootState) =>
  state.UserImpersonationReducer.pending;

export const UserImpersonationErrorSelector = (state: RootState) =>
  state.UserImpersonationReducer.error;

export const UserActiveNotifications = (state: RootState) => {
  return state.AppNotificationReducer.notifications.filter(
    (x) => x.ShowInNotificationCentre && !x.IsSnoozed
  );
};

export const UserHasUnreadNotifications = (state: RootState) => {
  return state.AppNotificationReducer.hasUnreadNotifications;
};
