import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import i18n from "../../../localizations/i18n";
import { switchUserProfile } from "../../../state/actions/UserProfile-Actions";
import { getUserImpersonationOptions } from "../../../state/actions/UserImpersonation-Actions";
import AccountSelectionForm from "../forms/AccountSelectionForm";
import { AccountSelectionStyles } from "../styles/accountSelectionStyles";
import { IAccountSelectionFormValues } from "../forms/AccountSelectionFormValues";
import { useHistory } from "react-router-dom";
import { routes } from "../../../navigation/routes";
import {
  IdAndName,
  IUserImpersonation,
} from "../../../state/types/UserImpersonation";
export interface AccountSelectionProps {
  availableClients: IdAndName[];
  userCanSwitchClients: boolean;
  userImpersonation: IUserImpersonation;
  impersonationPending: boolean;
}
interface IUserToolbarProps {
  accountSelectionProps: AccountSelectionProps;
  toggleDialogState: () => void;
  accountSelectionOpen: boolean;
}

const AccountSelectionDialog = (props: IUserToolbarProps) => {
  const {
    availableClients,
    userCanSwitchClients,
    userImpersonation,
    impersonationPending,
  } = props.accountSelectionProps;
  const { toggleDialogState, accountSelectionOpen } = props;
  const classes = AccountSelectionStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const onSelectedClient = (clientId: number) => {
    dispatch(getUserImpersonationOptions(clientId));
  };

  const onAccountSwitch = (
    clientId: number,
    managerId: number | undefined
  ): void => {
    closeDialog();
    dispatch(
      switchUserProfile(clientId, managerId, () => {
        history.push(routes.resetSession);
      })
    );
  };

  useEffect(() => {
    if (userImpersonation.selectedClient === undefined) {
      if (userImpersonation) {
        dispatch(getUserImpersonationOptions(undefined));
      }
    }
  }, [dispatch, userImpersonation]);

  const closeDialog = () => {
    dispatch(getUserImpersonationOptions(undefined));
    return toggleDialogState();
  };

  const accountFormValues = {
    availableClients: availableClients,
    clientId: userImpersonation.selectedClient,
    managerId: userImpersonation.defaultImpersonationOption,
    availableManagers: userImpersonation.impersonationOptions,
  } as IAccountSelectionFormValues;

  const AccountSelectionFormComponent = () => {
    return impersonationPending === false ? (
      <AccountSelectionForm
        formValues={accountFormValues}
        showManagerSelection={userImpersonation.userCanSwitchManagers}
        onClientChange={onSelectedClient}
        onSubmit={onAccountSwitch}
      />
    ) : (
      <>
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="rect" height={40} width={200} />
      </>
    );
  };

  return (
    <>
      <Dialog onClose={closeDialog} open={accountSelectionOpen}>
        <DialogContent>
          {userCanSwitchClients && (
            <>
              <DialogTitle className={classes.dialogTitle}>
                {i18n.translate("ACCOUNT_SELECTION_DialogTitle_Accounts")}
              </DialogTitle>
              <DialogContentText className={classes.dialogText}>
                {i18n.translate("ACCOUNT_SELECTION_DialogHint_Accounts")}
              </DialogContentText>
              {AccountSelectionFormComponent()}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AccountSelectionDialog;
