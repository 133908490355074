import { Icon } from "@material-ui/core";
import { ConversationSourceEnum } from "../../../state/types/Conversations";
import { InboxItemStyles } from "../../inboxList/styles/inboxItemStyles";
import { mapConversationSourceIcon } from "../utils";

type Props = {
  source: ConversationSourceEnum;
};

const ConversationIcon = (props: Props) => {
  const classes = InboxItemStyles();

  const mappedIcon = mapConversationSourceIcon(props.source);

  return (
    <Icon
      style={{ overflow: "visible" }}
      className={`${mappedIcon} ${classes.inboxIcon}`}
    />
  );
};

export default ConversationIcon;
