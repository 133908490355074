import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: "60px 20px 10px 10px",
    display: "block",
    height: "100%",
  },

  gridContainer: {
    [theme.breakpoints.up("md")]: {
      height: "fit-content",
      overflowY: "scroll",
      overflowX: "clip",
    },
  },

  sectionDescription: {
    color: hgemColours.DarkGrey,

    "& i": {
      marginRight: "15px",
    },

    "& h2": {
      fontSize: "2rem",
      fontWeight: 500,
      marginBottom: "5px",
      marginTop: "0",
    },
  },

  moduleDescription: {
    color: hgemColours.DarkGrey,
    height: "100%",

    "& h4": {
      paddingTop: "0px",
      fontSize: "1.5rem",
      marginBottom: "5px",
    },

    "& span.highlight": {
      fontWeight: 700,
      clear: "both",
      display: "inline-block",
    },

    "& ul": {
      margin: "0",
      padding: "0 0 0 5px",
      listStyle: "none",

      "& li": {
        paddingLeft: "1em",
        textIndent: "-1.3em",

        "&::before": {
          content: '"\u2605"',
          paddingRight: "0.5rem",
        },
      },
    },
  },

  launchButton: {
    marginTop: "5%",
  },
}));

export const ELearningViewStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
