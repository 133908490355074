import {
  CaseEventType,
  ICaseEvent,
} from "../../../state/types/TaskCentreCases";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GuestContactRow from "./guestContactRow";
import { GuestContactStyles } from "../styles/guestContactStyles";
import dayjs from "dayjs";
type props = {
  title: string;
  messages: ICaseEvent[];
  isMobileView?: boolean;
};
const GuestContactContainer = (props: props) => {
  const classes = GuestContactStyles();

  const sortedMessageList = props.messages.sort((eventA, eventB): number => {
    return dayjs(eventA.timestamp).isAfter(eventB.timestamp) ? -1 : 1;
  });
  const messageList = sortedMessageList.map((messageEvent, index) => {
    if (
      messageEvent.type === CaseEventType.MessageReceivedEvent ||
      messageEvent.type === CaseEventType.MessageSentEvent
    ) {
      const mostRecentMessage = index === 0 ? true : false;

      return (
        <GuestContactRow
          openByDefault={mostRecentMessage}
          messageEvent={messageEvent}
          key={messageEvent.id}
          isMobileView={props.isMobileView}
        />
      );
    }
  });
  return (
    <Accordion
      className={classes.accordionRow}
      defaultExpanded={props.isMobileView ? false : true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {props.title}
      </AccordionSummary>
      <div>{messageList}</div>
    </Accordion>
  );
};

export default GuestContactContainer;
