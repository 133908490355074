import { useEffect, useState } from "react";
import { AuthServiceInstance } from "../../../authentication/authService";

export const useClientId = () => {
  const [clientId, setClientId] = useState<number>(0);

  useEffect(() => {
    if (!clientId) {
      AuthServiceInstance.getClientId().then((clientId) => {
        setClientId(clientId);
      });
    }
  }, [clientId, setClientId]);

  return clientId;
};
