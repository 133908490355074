import { Button, Divider } from "@material-ui/core";
import { i18n } from "../../../localizations";
import { ICaseDetails } from "../../../state/types/TaskCentreCases";
import AppealInformation from "./appealInformation";
import { TasksStyles } from "../../taskCentreEditor/styles/tasksStyles";

type Props = {
  isAdmin: boolean | undefined;
  currentAppeal: ICaseDetails;
  postApprovedForReview: () => Promise<void>;
  isPendingAction: boolean;
  setIsPendingAction: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppealClosed = (props: Props) => {
  const taskStyles = TasksStyles();

  const { isAdmin, currentAppeal, postApprovedForReview, setIsPendingAction } =
    props;

  const editClosedAppeal = () => {
    setIsPendingAction(true);
    postApprovedForReview();
  };
  const editButton = () => {
    if (isAdmin) {
      return (
        <Button
          className={taskStyles.tasksButton}
          onClick={() => editClosedAppeal()}
        >
          {i18n.translate("APPEALS_EDIT")}
        </Button>
      );
    }
  };
  return (
    <>
      <AppealInformation
        currentAppeal={currentAppeal}
        buttonComponent={editButton()}
      />
      <Divider light={true} style={{ marginTop: "1%", marginBottom: "1%" }} />
    </>
  );
};

export default AppealClosed;
