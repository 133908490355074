import {
  ReportViewerActions,
  REPORTVIEWER_SET_BUSY_STATE,
  REPORTVIEWER_SET_CURRENT_PAGE,
  REPORTVIEWER_SET_DATA_EXPORT_STATE,
  REPORTVIEWER_SET_PAGE_SETTINGS,
} from "../actions/ReportViewer-Actions";

export interface IReportViewerState {
  isBusy: boolean;
  pages: string[];
  defaultPage: string;
  currentPage: string;
  dataExportOpen: boolean;
}

const INITIAL_STATE: IReportViewerState = {
  currentPage: "",
  defaultPage: "",
  pages: [],
  isBusy: false,
  dataExportOpen: false,
};

export default function ReportViewerReducer(
  state: IReportViewerState = INITIAL_STATE,
  action: ReportViewerActions
): IReportViewerState {
  switch (action.type) {
    case REPORTVIEWER_SET_PAGE_SETTINGS:
      return {
        ...state,
        currentPage: action.defaultPage,
        defaultPage: action.defaultPage,
        pages: action.pages,
      };
    case REPORTVIEWER_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case REPORTVIEWER_SET_BUSY_STATE:
      return {
        ...state,
        isBusy: action.isBusy,
      };
    case REPORTVIEWER_SET_DATA_EXPORT_STATE:
      return {
        ...state,
        dataExportOpen: action.isOpen,
      };
    default:
      return state;
  }
}
