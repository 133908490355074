import { IUserProfile, IAppSourceVersion } from "../types/UserProfile";

export const USERPROFILE_PENDING = "FETCH_USERPROFILE_PENDING";
export const USERPROFILE_SUCCESS = "FETCH_USERPROFILE_SUCCESS";
export const USERPROFILE_ERROR = "FETCH_USERPROFILE_ERROR";
export const USERPROFILE_SESSION_EXPIRED = "USERPROFILE_SESSIONEXPIRED";

export const RESET_USERPROFILE = "RESET_USERPROFILE";
export const SWITCH_USERPROFILE = "SWITCH_USERPROFILE";
export const SWITCH_USERBRANDS = "SWITCH_USERBRANDS";
export const CHECK_APPVERSION = "CHECK_APPVERSION";

export interface IUserProfileSuccess {
  type: typeof USERPROFILE_SUCCESS;
  userProfile: IUserProfile;
  appVersion: IAppSourceVersion;
}

export function fetchUserProfileSuccess(
  userProfile: IUserProfile,
  appVersion: IAppSourceVersion
): IUserProfileSuccess {
  return {
    type: USERPROFILE_SUCCESS,
    userProfile: userProfile,
    appVersion: appVersion,
  };
}

export interface IUserProfilePending {
  type: typeof USERPROFILE_PENDING;
}

export function fetchUserProfilePending(): IUserProfilePending {
  return {
    type: USERPROFILE_PENDING,
  };
}

export interface IUserProfileError {
  type: typeof USERPROFILE_ERROR;
  error: string;
}

export function fetchUserProfileError(error: string): IUserProfileError {
  return {
    type: USERPROFILE_ERROR,
    error: error,
  };
}

export interface IUserProfileSessionExpired {
  type: typeof USERPROFILE_SESSION_EXPIRED;
}

export function userProfileSessionExpired(): IUserProfileSessionExpired {
  return {
    type: USERPROFILE_SESSION_EXPIRED,
  };
}

export interface IResetUserProfile {
  type: typeof RESET_USERPROFILE;
}

export function resetUserProfile(): IResetUserProfile {
  return {
    type: RESET_USERPROFILE,
  };
}

export interface ISwitchUserProfile {
  type: typeof SWITCH_USERPROFILE;
  clientId: number;
  managerId: number | undefined;
  callback: (() => void) | undefined;
}

export function switchUserProfile(
  clientId: number,
  managerId: number | undefined = undefined,
  callback: (() => void) | undefined = undefined
): ISwitchUserProfile {
  return {
    type: SWITCH_USERPROFILE,
    clientId,
    managerId,
    callback,
  };
}

export interface ISwitchUserBrands {
  type: typeof SWITCH_USERBRANDS;
  brands: Array<string>;
}

export function switchUserBrands(brands: Array<string>): ISwitchUserBrands {
  return {
    type: SWITCH_USERBRANDS,
    brands,
  };
}

export interface ICheckAppVersion {
  type: typeof CHECK_APPVERSION;
  callback: (appSourceVersion: IAppSourceVersion) => void;
}

export function checkAppVersion(
  callback: (appSourceVersion: IAppSourceVersion) => void
): ICheckAppVersion {
  return {
    type: CHECK_APPVERSION,
    callback,
  };
}

export type UserProfileActions =
  | IUserProfileSuccess
  | IUserProfilePending
  | IUserProfileError
  | IUserProfileSessionExpired
  | IResetUserProfile
  | ISwitchUserProfile
  | ISwitchUserBrands
  | ICheckAppVersion;
