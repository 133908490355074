import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { applyFilterAvailabilityRules } from "../../_common/filters/utils/hubFilterAvailability";
import { IFilterSetsState } from "../../../state/reducers/FilterSets-Reducer";
import { IVisitDetailsState } from "../../../state/reducers/VisitDetails-Reducer";

export const VisitDetailsListPending = (state: RootState) =>
  state.VisitDetailsReducer.pending;

export const VisitDetailsListError = (state: RootState) =>
  state.VisitDetailsReducer.error;

export const AllVisits = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.visits;
  }
);

export const SelectedVisitId = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.selectedVisitId;
  }
);

export const TotalVisitCount = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.visitListItemCount;
  }
);

export const VisitsLastRefreshed = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.lastRefreshed;
  }
);

export const GetCurrentFilterSet = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (state: IFilterSetsState) => {
    return state.currentFilterSet;
  }
);

export const GetVisibleFilters = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (_: RootState, pageRef: string) => pageRef,
  (state, pageRef) => {
    const filters = state.currentFilters;
    const filterAvailability = state.filterSetConfiguration.filterAvailability;

    return applyFilterAvailabilityRules(filters, filterAvailability, pageRef);
  }
);

export const MoreVisitsAvailable = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.moreVisitsAvailable;
  }
);

export const AllTaskCounts = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitTaskCounts;
  }
);

export const GetUserCurrentBrands = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.currentBrands
    : [];
};
