import { ImageListItem, ImageListItemBar } from "@material-ui/core";
import { IVisitDetail } from "../../../state/types/VisitDetails";
import VisitPhoto from "../../inboxMedia/components/visitPhoto";
import { MediaStyles } from "../styles";
import LazyLoad from "react-lazyload";
import dayjs from "dayjs";
import { i18n } from "../../../localizations";

type Props = {
  visits: IVisitDetail[];
};

const PhotoGrid = (props: Props) => {
  const classes = MediaStyles();
  return (
    <>
      {props.visits.map((visit) => {
        if (visit.visitPhotos) {
          return visit.visitPhotos.map((photo) => {
            return (
              <LazyLoad className={classes.flexImage} overflow={true}>
                <ImageListItem>
                  <VisitPhoto visitId={visit.id} fileName={photo.filename} />
                  <ImageListItemBar
                    classes={{ subtitle: classes.mediaOverlay }}
                    subtitle={
                      <>
                        <span className={classes.flexRow}>
                          <span>{visit.id}</span>
                          <span>
                            {dayjs(visit.date).format(
                              i18n.translate(
                                "DATE_PERIOD_PICKER_Tooltip_Date_Format"
                              )
                            )}
                          </span>
                        </span>
                        <span className={classes.clippedText}>
                          {visit.branch}
                        </span>
                      </>
                    }
                  />
                </ImageListItem>
              </LazyLoad>
            );
          });
        }
      })}
    </>
  );
};

export default PhotoGrid;
