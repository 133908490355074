import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { getAppealAttachmentApi } from "../../../state/api/Appeals-API";
import {
  ICaseDetails,
  IMinimalCaseEventAttachment,
} from "../../../state/types/TaskCentreCases";
import { AttachmentsList } from "../../inboxViews/components/attachments";
import { i18n } from "../../../localizations";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type Props = {
  clientId: number;
  currentAppeal?: ICaseDetails | undefined;
  pendingAttachments?: File[] | undefined;
  deleteAttachment: (caseEventAttachmentId: number) => Promise<void>;
  userId: number;
  isAdmin: boolean;
  className?: string;
};

const AppealAttachments = (props: Props) => {
  const {
    clientId,
    currentAppeal,
    pendingAttachments,
    deleteAttachment,
    userId,
    isAdmin,
  } = props;

  const pendingAttachmentsMapped = pendingAttachments?.map(
    (attachment, index) => {
      return {
        id: index,
        filepath: attachment.name,
      };
    }
  );

  const currentAttachmentsMapped = currentAppeal?.events
    .flatMap(
      (x) =>
        x.caseEventAttachments?.map((y) => {
          return { ...y, authorId: x.authorId, authorName: x.authorName };
        }) ?? []
    )
    .sort((a, b) => b.id - a.id);

  const attachments: (IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  })[] = currentAttachmentsMapped ?? pendingAttachmentsMapped ?? [];

  const downloadAttachment = async (
    caseEventAttachment: IMinimalCaseEventAttachment,
    currentAppeal: ICaseDetails
  ) => {
    const response = await getAppealAttachmentApi(
      clientId,
      currentAppeal.vId,
      currentAppeal.id,
      caseEventAttachment.id,
      caseEventAttachment.filepath
    );

    return response.data;
  };

  return (
    <>
      {attachments.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {i18n.translate("ATTACHMENTS")}
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <AttachmentsList
              attachments={attachments}
              downloadAttachment={
                currentAppeal
                  ? (attachment) =>
                      downloadAttachment(attachment, currentAppeal)
                  : undefined
              }
              authorDeleteAttachment={(authorId) =>
                isAdmin ||
                authorId === userId ||
                (currentAppeal === undefined &&
                  pendingAttachments !== undefined)
                  ? deleteAttachment
                  : undefined
              }
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default AppealAttachments;
