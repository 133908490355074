import React from "react";
import { Icon } from "@material-ui/core";
import { DueDateStatus } from "../actionCentre/utils/dueDateStatus";
import { hgemColours } from "../../../themes/defaultTheme";

interface IProps {
  status: DueDateStatus;
}

const DueDateIcon = (props: IProps) => {
  const getClassName = () => {
    switch (props.status) {
      case DueDateStatus.Overdue:
        return "due-date_overdue";
      case DueDateStatus.DueSoon:
        return "due-date_due-soon";
      case DueDateStatus.Ok:
        return "due-date_ok";
      default:
        return "";
    }
  };

  return (
    <Icon
      className={`icon far fa-calendar-alt ${getClassName()}`}
      style={{ color: hgemColours.DarkGrey }}
    />
  );
};

export default DueDateIcon;
