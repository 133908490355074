export enum VoucherIntegrations {
  Toggle = 1,
  Hgem = 0,
}

export enum ToggleVoucherValues {
  default = 150,
}

export enum InactiveToggleProductNames {
  InStoreGift = "In-Store Gift",
}
export type VoucherResponse = {
  brandId: number;
  clientId: number;
  toggleAccountId: number;
  toggleVoucherProducts: toggleProductOption[];
  voucherSourceEnum: VoucherIntegrations;
  voucherUpperLimit: number | undefined;
};

export type toggleProductOption = {
  toggleProductDescription: string;
  toggleProductId: number;
  toggleProductOptions: toggleProduct[];
};

export type toggleProduct = {
  toggleProductOptionDescription: string;
  toggleProductOptionId: number;
  toggleProductOptionPrice: number;
};
