import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  selectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  selectContainer: {
    width: "100%",
  },
  WorkflowSelect: {
    width: "88.5%",
  },

  selectIcon: {
    marginRight: "10%",
    overflow: "visible",
    fontSize: "1.25em",
  },

  statusLabel: {
    fontSize: "75%",
    color: theme.palette.grey[400],
    marginBottom: "5px",
  },

  selectedButton: {
    border: `1px solid ${hgemColours.LimeGreen}`,
    marginRight: "10px",
    marginBottom: "5px",
    color: hgemColours.DarkGrey,
    backgroundColor: lighten(hgemColours.LimeGreen, 0.5),

    "&:hover": {
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: lighten(hgemColours.LimeGreen, 0.5),
      boxShadow: "none",
    },
  },

  deselectedButton: {
    marginRight: "10px",
    marginBottom: "5px",
    color: hgemColours.ExtraMediumGrey,
    border: `1px solid ${hgemColours.ExtraMediumGrey}`,
    "&:hover": {
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: lighten(hgemColours.LimeGreen, 0.5),
      boxShadow: "none",
    },
  },

  buttonText: {
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  flowIcon: {
    color: hgemColours.ExtraMediumGrey,
    fontSize: "2em",
    marginRight: "-1%",
  },
  mobileflowIcon: {
    color: hgemColours.ExtraMediumGrey,
    fontSize: "1.5em",
    marginLeft: "25%",
  },
  labelText: {
    color: hgemColours.DarkGrey,
    fontSize: "0.85em",
    alignSelf: "center",
    marginRight: "2%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  unSelectedIcon: {
    opacity: "0.5",
  },
}));

export const WorkflowStatusFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
