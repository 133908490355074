import { useDispatch } from "react-redux";
import {
  fetchInitialConversationVisitMessagesList,
  fetchLoadMoreConversationVisitMessagesList,
} from "../../../../state/actions/Conversations-Actions";
import { ConversationVisitState } from "../../../../state/reducers/Conversations-Reducer";
import ConversationMessagesCore from "./conversationMessagesCore";

type Props = {
  conversationVisit: ConversationVisitState;
};

const ConversationVisitMessages = (props: Props) => {
  const dispatch = useDispatch();

  return (
    <ConversationMessagesCore
      context={props.conversationVisit}
      fetchInitialMessagesList={() =>
        dispatch(
          fetchInitialConversationVisitMessagesList(
            props.conversationVisit.conversation.conversationId,
            props.conversationVisit.visit.visitId
          )
        )
      }
      fetchLoadMoreMessagesList={(scrollDistanceFromBottom) =>
        dispatch(
          fetchLoadMoreConversationVisitMessagesList(
            props.conversationVisit.conversation.conversationId,
            props.conversationVisit.visit.visitId,
            scrollDistanceFromBottom
          )
        )
      }
    />
  );
};

export default ConversationVisitMessages;
