import { i18n } from "../../../localizations";
import { ConversationSourceEnum } from "../../../state/types/Conversations";

export function mapConversationSourceIcon(source: ConversationSourceEnum) {
    switch (source) {
        case ConversationSourceEnum.Sms:
            return "fas fa-sms";
        case ConversationSourceEnum.WhatsApp:
            return "fab fa-whatsapp";
        case ConversationSourceEnum.Messenger:
            return "fab fa-facebook-messenger";
        case ConversationSourceEnum.Email:
            return "fas fa-envelope";
        case ConversationSourceEnum.Gbm:
            return "fab fa-google";
    }
}

export function mapConversationSourceDisplayName(source: ConversationSourceEnum) {
    switch (source) {
        case ConversationSourceEnum.Sms:
            return i18n.translate("CONVERSATION_SOURCE_SMS");
        case ConversationSourceEnum.WhatsApp:
            return i18n.translate("CONVERSATION_SOURCE_WHATSAPP");
        case ConversationSourceEnum.Messenger:
            return i18n.translate("CONVERSATION_SOURCE_MESSENGER");
        case ConversationSourceEnum.Email:
            return i18n.translate("CONVERSATION_SOURCE_EMAIL");
        case ConversationSourceEnum.Gbm:
            return i18n.translate("CONVERSATION_SOURCE_GOOGLE_BUSINESS_MESSENGER");
    }
}