import { LinearProgress } from "@material-ui/core";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../localizations/i18n";
import { fetchGuestDetails } from "../../state/actions/GuestDetails-Actions";
import { RootState } from "../../state/reducers/RootReducer";
import {
  CaseEventType,
  ICaseDetails,
  IMinimalCaseEventAttachment,
  WorkflowStatus,
} from "../../state/types/TaskCentreCases";
import { ContactGuestCommand } from "../../state/types/TaskCentreCommands";
import InboxTaskSelector from "../InboxTaskSelector/inboxTaskSelector";
import GuestContactField from "./components/guestContactField";
import {
  CurrentGuestDetailsId,
  GetActiveNotificationById,
  GuestDetailsByVisitId,
  GuestDetailsError,
  GuestDetailsPending,
  VisitTasksPending,
  VoucherConfiguration,
} from "./state/selectors";
import { VoucherIntegrations, VoucherResponse } from "../../state/types";
import { AllVisits } from "../inboxList/state/selectors";
import { IVisitDetail } from "../../state/types/VisitDetails";
import { ToggleVoucherValues } from "../../state/types/Voucher";
import { GuestContactStyles } from "./styles/guestContactStyles";
interface IProps {
  visitId: number | undefined;
  case: ICaseDetails | undefined;
  showTaskSelector?: boolean;
  isMobileView?: boolean;
}

const InboxGuestContact = (props: IProps) => {
  const guestContactStyles = GuestContactStyles();
  const dispatch = useDispatch();
  const casesPending = useSelector(VisitTasksPending);
  const guestDetailsVisitId = useSelector(CurrentGuestDetailsId);
  const guestDetailsPending = useSelector(GuestDetailsPending);
  const guestDetailsError = useSelector(GuestDetailsError);
  const guestContactDetails = useSelector((state: RootState) =>
    GuestDetailsByVisitId(state, props.visitId)
  );

  const voucherConfiguration = useSelector(VoucherConfiguration);
  const availableVisits = useSelector(AllVisits);
  const fieldNotification = useSelector((state: RootState) =>
    GetActiveNotificationById(
      state,
      `${props.case?.id}|${ContactGuestCommand.type}`
    )
  );

  const [currentVisit, setCurrentVisit] = useState<IVisitDetail | undefined>();

  useEffect(() => {
    const visit = availableVisits.find((visit) => visit.id === props.visitId);
    setCurrentVisit(visit);
  });

  const [vouchersActive, setVouchersActive] = useState<boolean>(false);
  const [voucherUpperLimit, setVoucherUpperLimit] = useState<number>(
    ToggleVoucherValues.default
  );

  const areToggleVouchersActive = (): void => {
    setVouchersActive(false);
    let config;
    if (voucherConfiguration) {
      config = voucherConfiguration.find((voucherConfig: VoucherResponse) => {
        if (
          voucherConfig.voucherSourceEnum === VoucherIntegrations.Toggle &&
          currentVisit?.brandId === voucherConfig.brandId
        ) {
          return voucherConfig;
        }
      });
    }
    if (config) {
      setVouchersActive(true);
    }
    if (
      config?.brandId &&
      config.brandId === currentVisit?.brandId &&
      config?.voucherUpperLimit
    ) {
      setVoucherUpperLimit(config.voucherUpperLimit);
    }
  };

  useEffect(() => {
    if (currentVisit) {
      areToggleVouchersActive();
    }
  }, [currentVisit, props.visitId, props.case]);

  useEffect(() => {
    if (props.visitId && guestDetailsVisitId !== props.visitId) {
      dispatch(fetchGuestDetails(props.visitId));
    }
  }, [dispatch, guestDetailsVisitId, props.visitId]);

  const filterAttachments = (): (IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  })[] => {
    return (
      props.case?.events
        .filter(
          (x) =>
            x.type === CaseEventType.MessageReceivedEvent ||
            x.type === CaseEventType.MessageSentEvent
        )
        .flatMap(
          (x) =>
            x.caseEventAttachments?.map((y) => {
              return {
                ...y,
                authorId: x.authorId,
                authorName:
                  x.type === CaseEventType.MessageReceivedEvent
                    ? x.sender
                    : x.authorName,
              };
            }) ?? []
        )
        .sort((a, b) => b.id - a.id) ?? []
    );
  };
  const [attachments, setAttachments] = useState<
    (IMinimalCaseEventAttachment & {
      authorId?: number | undefined;
      authorName?: string | undefined;
    })[]
  >();

  useEffect(() => {
    setAttachments(filterAttachments());
  }, [props.case]);
  return casesPending || guestDetailsPending || !currentVisit ? (
    <LinearProgress color="primary" />
  ) : (
    <div className={guestContactStyles.mainContainer}>
      {!casesPending &&
        props.case &&
        guestContactDetails?.contactDetails.email &&
        currentVisit && (
          <>
            {props.showTaskSelector && <InboxTaskSelector />}
            <GuestContactField
              caseDetails={props.case}
              guestContactDetails={guestContactDetails}
              guestDetailsError={guestDetailsError}
              guestDetailsPending={guestDetailsPending}
              appNotification={fieldNotification}
              isNewCase={false}
              vouchersActive={vouchersActive}
              currentVisit={currentVisit}
              voucherUpperLimit={voucherUpperLimit}
              attachments={attachments}
              isMobileView={props.isMobileView}
            />
          </>
        )}
      {!casesPending &&
        !props.case &&
        (guestContactDetails?.contactDetails.email ||
          guestContactDetails?.contactDetails.mobile) &&
        currentVisit && (
          <GuestContactField
            caseDetails={
              {
                vId: props.visitId,
                wfStatus: WorkflowStatus.ActionTaken,
                events: [] as unknown,
              } as ICaseDetails
            }
            guestContactDetails={guestContactDetails}
            guestDetailsError={guestDetailsError}
            guestDetailsPending={guestDetailsPending}
            appNotification={fieldNotification}
            isNewCase={true}
            vouchersActive={vouchersActive}
            currentVisit={currentVisit}
            voucherUpperLimit={voucherUpperLimit}
            attachments={attachments}
          />
        )}

      {!casesPending &&
        !guestDetailsPending &&
        !guestContactDetails?.contactDetails.email && (
          <p>{i18n.translate("INBOX_GUEST_CONTACT_No_contact_available")}</p>
        )}
    </div>
  );
};

export default InboxGuestContact;
