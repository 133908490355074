import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: "0",
    backgroundColor: hgemColours.LimeGreen,
    color: hgemColours.White,
    "&:hover": {
      backgroundColor: hgemColours.DarkGrey,
    },
  },
  selectionContainer: {
    width: "100%",
  },
  selectionSplitter: {
    display: "inline",
    margin: "0 10px",
  },
  checkbox: {
    color: theme.palette.grey[400],
  },
  simpleButton: {
    border: "none",
    background: "none",
    margin: "0 0 5px 0",
    padding: "0",

    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export const BrandSelectionFormStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
