import { FormControl, Select, MenuItem } from "@material-ui/core";
import {
  WorkflowStatus,
  WorkflowStatusType,
} from "../../../state/types/TaskCentreCases";
import WorkflowStatusIcon from "../../_common/Icons/workflowStatusIcon";
import { WorkflowStatusFieldStyles } from "../styles/inputs/workflowStatusFieldStyles";

type props = {
  statusValue: WorkflowStatus;
  changeStatus: (WorkflowStatus: WorkflowStatus) => void;
  workflowStatuses: WorkflowStatusType[];
};
const WorkflowStatusSelect = (props: props) => {
  const { statusValue, changeStatus, workflowStatuses } = props;
  const classes = WorkflowStatusFieldStyles();

  return (
    <div className={classes.selectContainer}>
      <FormControl classes={{ root: classes.WorkflowSelect }}>
        <Select
          labelId="workflow-label"
          value={statusValue}
          onChange={(event) =>
            changeStatus(event.target.value as WorkflowStatus)
          }
        >
          {workflowStatuses.map((WorkflowStatus: WorkflowStatusType) => (
            <MenuItem key={WorkflowStatus.value} value={WorkflowStatus.value}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                }}
              >
                <WorkflowStatusIcon
                  status={WorkflowStatus.value}
                  className={`${classes.selectIcon} selected`}
                />
                {WorkflowStatus.text}
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default WorkflowStatusSelect;
