import { IManager } from "../types/TaskCentreCases";

export interface IActiveManagersByClientIdVariables {
  clientId: number;
}

export interface IActiveManagersByClientId {
  activeManagersByClientId: IGraphManager[];
}

export interface IGraphManager {
  id: number;
  name: string;
}

class Mapper {
  public map = (data: IActiveManagersByClientId | undefined): IManager[] => {
    return data
      ? data.activeManagersByClientId.map((x) => {
          return {
            id: x.id,
            name: x.name,
          } as IManager;
        })
      : [];
  };
}

export const ActiveManagersClientIdMapper = new Mapper();
