import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BuildVisitCaseListQuery from "./utils/taskCentreVisitQueryBuilder";
import {
  clearTaskCentreCaseList,
  fetchTaskCentreCaseList,
} from "../../state/actions/TaskCentre-CaseList-Actions";
import { SelectedVisitId } from "./state/selectors";

export enum TaskScope {
  VisitId,
  PreLoadedTasks,
}

interface IProps {
  scope: TaskScope;
  children: JSX.Element;
}

const InboxTasksScope = (props: IProps) => {
  const dispatch = useDispatch();

  const [currentVisitId, setCurrentVisitId] = useState<number | undefined>(
    undefined
  );

  const visitId = useSelector(SelectedVisitId);

  useEffect(() => {
    if (props.scope === TaskScope.VisitId && currentVisitId !== visitId) {
      setCurrentVisitId(visitId);
      dispatch(clearTaskCentreCaseList());

      if (visitId) {
        const query = BuildVisitCaseListQuery(visitId);
        dispatch(fetchTaskCentreCaseList(query));
      }
    }
  }, [currentVisitId, dispatch, props.scope, visitId]);

  return <>{props.children}</>;
};

export default InboxTasksScope;
