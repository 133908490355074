import React from "react";
import Error from "../../../views/system/error";
import { Logging, SeverityLevel } from "../../../utils/logging";

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  unknown,
  IErrorBoundaryState
> {
  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    const additionalInfo = Logging.trySerializeErrorObject(errorInfo);
    Logging.captureError(
      "ApplicationError",
      error,
      SeverityLevel.Critical,
      additionalInfo
    );
  }

  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}
