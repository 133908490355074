import { Chip, Typography } from "@material-ui/core";
import { i18n } from "../../../localizations";
import { AppealsStyles } from "../styles";

type Props = {
  questions: (string | undefined)[];
};
const SelectedQuestionsList = (props: Props) => {
  const appealsClasses = AppealsStyles();

  return (
    <>
      <Typography
        className={`${appealsClasses.questionHeader} ${appealsClasses.boldText}`}
      >
        {i18n.translate("APPEALS_QUESTIONS_HEADER")}
      </Typography>
      <div className={appealsClasses.selectedQuestionChips}>
        {props.questions.map((question) => (
          <Chip variant="default" label={question} />
        ))}
      </div>
    </>
  );
};

export default SelectedQuestionsList;
