import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    backgroundColor: hgemColours.OffWhite,
    marginTop: "15%",
    borderColor: "#c8c8c8",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "7%",
    },
  },
  tabsContainer: {
    position: "relative",
    margin: "3%",
  },
  actionsTabsContainer: {
    position: "relative",
    margin: "2%",
  },
  tabs: {
    paddingBottom: "5px",
  },
  tabPanel: {
    marginTop: "3%",
  },
  visitReport: {
    height: "70vh",
    overflowY: "scroll",
    overflowX: "clip",
  },
}));

export const MobileViewStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
