import React from "react";
import { GemWheelStyles } from "../styles/gemWheelStyles";

interface IProps {
  selectedId: string;
  onSelected: (selectedId: string) => void;
}

const GemWheel = (props: IProps) => {
  const classes = GemWheelStyles();

  const handleSectionClick = (event: React.MouseEvent<SVGPathElement>) => {
    props.onSelected(event.currentTarget.id);
  };

  return (
    <div className={classes.root}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 477.07 477.07"
        className="circle circle-hover js-circle-hover"
        preserveAspectRatio="xMidYMin slice"
      >
        <g className="circle-sector-2 js-circle-sector">
          {/* <!-- PEOPLE --> */}
          <g className="circle-light circle-active gem-wheel-target">
            <path
              id="gem-wheel-welcome"
              d="M239.65,0V77.16a159.75,159.75,0,0,1,78.21,21.25l38.8-67.29A237.14,237.14,0,0,0,239.65,0Z"
              className={
                props.selectedId === "gem-wheel-welcome"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M270.22,43.2a.57.57,0,0,1-.63.34L268,43.31a.55.55,0,0,1-.5-.51l-.43-7.57a9.08,9.08,0,0,0-.05-1.16h-.3c-.12.37-.24.75-.38,1.1l-2.43,7.13a.55.55,0,0,1-.63.35l-1.62-.22a.56.56,0,0,1-.5-.5l-.58-9.87,1.54.22.38,7.84v1.3h.34c.12-.45.25-.85.39-1.24l2.44-7a.58.58,0,0,1,.62-.41l1.55.22a.58.58,0,0,1,.49.56l.42,7.37v1.29l.34,0c.13-.4.23-.84.36-1.22l2.5-7.47,1.54.21Z" />
            <path d="M279.08,44.11l-.05,1a8.59,8.59,0,0,1-2.86-.13c-2.44-.47-3.18-1.65-2.66-4.35s1.7-3.5,4-3.06,2.92,1.42,2.62,3c-.24,1.27-.92,1.91-2.1,1.69l-3.15-.62c-.16,1.56.39,2,1.74,2.25A22.7,22.7,0,0,0,279.08,44.11ZM275,40.47l2.8.54c.59.12.79-.37.88-.8.15-.85-.19-1.26-1.39-1.49C276,38.45,275.41,38.81,275,40.47Z" />
            <path d="M282.61,44a.83.83,0,0,0,.5,1.05.78.78,0,0,0,.21,0l.65.15-.09,1.11a4.52,4.52,0,0,1-1.37-.12,1.67,1.67,0,0,1-1.43-1.89,1.87,1.87,0,0,1,.08-.34l1.89-8.31,1.39.31Z" />
            <path d="M292,41.3l-.42.83c-.62-.2-1.37-.42-1.85-.55-1.58-.4-2.26,0-2.76,1.94s-.1,2.63,1.48,3a13.33,13.33,0,0,0,2.08.37l-.11,1a7.6,7.6,0,0,1-2.51-.24c-2.43-.63-3.06-1.82-2.37-4.49s1.83-3.42,4.25-2.8A7.44,7.44,0,0,1,292,41.3Z" />
            <path d="M298.85,46.85c-.78,2.62-2,3.33-4.34,2.62s-3-2-2.2-4.57,2-3.36,4.35-2.65S299.64,44.23,298.85,46.85Zm-5.12-1.55c-.57,1.9-.3,2.59,1.12,3s2,0,2.59-1.9.3-2.6-1.12-3S294.3,43.4,293.73,45.3Z" />
            <path d="M311.3,49.3l-1.8,5-1.32-.46,1.67-4.67c.27-.79.14-1.22-.58-1.47s-1.19-.11-2.44.25l-1.71,4.79-1.29-.46,1.67-4.66c.29-.83.08-1.24-.58-1.47a3.73,3.73,0,0,0-2.44.23l-1.71,4.81-1.33-.47,2.4-6.74,1.11.39-.3,1a4.48,4.48,0,0,1,3.14-.24,1.68,1.68,0,0,1,1.29,1.79,4.24,4.24,0,0,1,3.07-.23,1.75,1.75,0,0,1,1.36,2.07A1.69,1.69,0,0,1,311.3,49.3Z" />
            <path d="M317.1,56.23l-.24.92a8.31,8.31,0,0,1-2.77-.7c-2.3-1-2.79-2.26-1.73-4.8s2.36-3.08,4.5-2.19,2.57,2,2,3.42c-.5,1.2-1.29,1.69-2.4,1.23l-3-1.24c-.47,1.5,0,2,1.25,2.56C315.4,55.71,316.35,56,317.1,56.23Zm-3.24-4.38L316.5,53c.56.23.85-.21,1-.61.32-.81.07-1.27-1.06-1.74S314.57,50.3,313.86,51.85Z" />
          </g>
          <g className="circle-light circle-active gem-wheel-target">
            <path
              id="gem-wheel-behaviours"
              d="M444.56,118a239.54,239.54,0,0,0-85.41-85.43l-38.8,67.29a161.72,161.72,0,0,1,56.49,57.33Z"
              className={
                props.selectedId === "gem-wheel-behaviours"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M358.29,78.73c-1.22,1.69-2.29,1.6-4.18.23a30.52,30.52,0,0,1-3-2.33l5.94-8.2a31.19,31.19,0,0,1,2.8,1.89c2,1.46,2.41,2.43,1.19,4.12-.85,1.18-1.69,1.49-2.75.87v0C359.38,76.35,359.22,77.42,358.29,78.73Zm-1.07-1.07c.88-1.21.7-1.83-.57-2.77L355,73.68l-2.08,2.86c.47.36,1.08.8,1.74,1.28C355.89,78.71,356.46,78.74,357.22,77.66Zm.29-7.47-1.87,2.58L357.3,74c1.12.81,1.7.83,2.46-.21s.49-1.58-.74-2.48Z" />
            <path d="M363.5,84.54l-.48.82a8.31,8.31,0,0,1-2.48-1.43c-2-1.54-2.07-2.93-.36-5.09s3.11-2.32,4.93-.88,1.93,2.6,1,3.83c-.8,1-1.7,1.27-2.64.53l-2.52-2c-.85,1.31-.56,2,.52,2.8A21.73,21.73,0,0,0,363.5,84.54Zm-1.93-5.1,2.24,1.78c.47.37.87,0,1.15-.31.52-.69.41-1.21-.54-2s-1.74-.76-2.85.53Z" />
            <path d="M372.59,86.78l-3.51,4.08L368,89.93l3.24-3.76c.59-.69.6-1.06.08-1.5a5.17,5.17,0,0,0-2.69-.92l-3.32,3.86-1.08-.93,6.7-7.78,1.08.92-1.8,2.12a8.59,8.59,0,0,1-1,1A5.45,5.45,0,0,1,372.39,84a1.77,1.77,0,0,1,.46,2.45A1.48,1.48,0,0,1,372.59,86.78Z" />
            <path d="M378.29,92.58l-3.41,3.65-.81-.76.65-.94a4.12,4.12,0,0,1-3-1,2,2,0,0,1-.38-2.8c.05-.07.11-.13.17-.2L372,90a1.74,1.74,0,0,1,2.45-.19,1.52,1.52,0,0,1,.25.26l2.1,2,.38-.41c.59-.65.55-1.06-.25-1.81-.52-.48-1.47-1.25-2.34-2l.61-.85A24,24,0,0,1,378,89.17C379.22,90.3,379.52,91.3,378.29,92.58Zm-5.48.24a3.73,3.73,0,0,0,2.45.91l.87-.93L374.23,91a.77.77,0,0,0-1.08-.09,1.29,1.29,0,0,0-.16.18l-.26.27a.89.89,0,0,0-.15,1.24A.87.87,0,0,0,372.81,92.82Z" />
            <path d="M379.33,100.18a.52.52,0,0,1-.61-.11l-.89-.91a.48.48,0,0,1-.09-.59l3.05-6.6,1.06,1.08-2.33,4.65-.6,1.15.13.13c.4-.18.79-.39,1.18-.56l4.67-2.28L386,97.21Z" />
            <path d="M382.66,104.18l-1-1,5.26-4.88,1,1Zm7.18-6.65-.6.56a.37.37,0,0,1-.52.08l-.07-.08-.46-.49a.37.37,0,0,1-.07-.51.27.27,0,0,1,.07-.08l.6-.56a.38.38,0,0,1,.52-.06l.06.06.46.49a.37.37,0,0,1,.08.52.23.23,0,0,1-.07.07Z" />
            <path d="M391.08,108.3c-2.05,1.8-3.43,1.78-5.07-.09s-1.49-3.24.56-5,3.45-1.8,5.1.07S393.14,106.46,391.08,108.3Zm-3.53-4c-1.49,1.3-1.63,2-.65,3.15s1.72,1.06,3.2-.24,1.64-2,.66-3.16-1.7-1.13-3.21.25Z" />
            <path d="M398.94,112.2l-5.57,4.51-.71-.88.7-.7a5,5,0,0,1-2.83-1.63,1.76,1.76,0,0,1,0-2.49,2,2,0,0,1,.35-.28l4.17-3.38.9,1.11-3.85,3.12c-.7.54-.75.94-.27,1.54a4.67,4.67,0,0,0,2.24,1.25l4-3.26Z" />
            <path d="M403.22,117.58l-1.16.67-.26-.35a6.35,6.35,0,0,0-2.2-1.56l-4.16,3.12-.85-1.13,5.72-4.31.66.88-.79.78a5.06,5.06,0,0,1,2.84,1.63Z" />
            <path d="M407,123.3l-.91.5c-.49-.75-1-1.54-1.5-2.27-.74-1-1-1.36-1.61-.91s-.42.71-.19,1.37l.76,1.94a1.82,1.82,0,0,1-.62,2.49l-.07,0c-1.44,1-2.25.4-3.48-1.35a20.9,20.9,0,0,1-1.47-2.45l.93-.52a22.1,22.1,0,0,0,1.35,2c.9,1.28,1.28,1.57,1.88,1.15s.37-.76.12-1.42l-.74-1.91a1.84,1.84,0,0,1,.7-2.49l0,0c1.34-.94,2.13-.47,3.27,1.17A26.74,26.74,0,0,1,407,123.3Z" />
          </g>
          <g className="circle-light circle-active gem-wheel-target">
            <path
              id="gem-wheel-conversation"
              d="M477.07,237.18A237,237,0,0,0,446,120.5l-67.72,39.19a160.78,160.78,0,0,1,20.32,77.49Z"
              className={
                props.selectedId === "gem-wheel-conversation"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M425.53,149.87l-1.08.36a23.88,23.88,0,0,0-1.07-2.43c-.82-1.67-1.94-1.79-4.61-.47s-3.26,2.27-2.43,3.94a18,18,0,0,0,1.4,2.5l-.93.62a9.7,9.7,0,0,1-1.64-2.54c-1.31-2.64-.54-4.2,2.92-5.9s5.16-1.37,6.46,1.28A10.9,10.9,0,0,1,425.53,149.87Z" />
            <path d="M423.09,160.17c-2.49,1.1-3.8.67-4.81-1.61s-.45-3.53,2.05-4.64,3.83-.69,4.84,1.59S425.6,159.06,423.09,160.17Zm-2.16-4.9c-1.81.81-2.17,1.46-1.57,2.81s1.32,1.53,3.13.73,2.18-1.46,1.58-2.81-1.31-1.54-3.14-.7Z" />
            <path d="M427.8,167.1l-5,2-.53-1.32,4.61-1.82c.85-.33,1-.65.78-1.28a5.52,5.52,0,0,0-2-2.07l-4.72,1.87-.52-1.32,6.66-2.63.44,1.12-1,.48a5.38,5.38,0,0,1,2.35,2.48,1.76,1.76,0,0,1-.76,2.37A1.66,1.66,0,0,1,427.8,167.1Z" />
            <path d="M425.09,174.48a.5.5,0,0,1-.5-.36l-.42-1.2a.5.5,0,0,1,.18-.57l5.59-4.65.49,1.43-4.1,3.19c-.33.26-.69.52-1,.78l.07.18h6.5l.5,1.42Z" />
            <path d="M428.71,183.13l-.86.4a8.43,8.43,0,0,1-1.24-2.58c-.72-2.39,0-3.6,2.6-4.4s3.89-.14,4.56,2.07.12,3.25-1.38,3.71c-1.24.37-2.12.08-2.48-1.07l-.93-3.07c-1.45.59-1.58,1.27-1.17,2.59A23.73,23.73,0,0,0,428.71,183.13Zm1.31-5.29.83,2.73c.18.58.7.53,1.12.4.83-.27,1-.76.67-1.93-.42-1.33-1-1.64-2.62-1.2Z" />
            <path d="M436.34,187.81,435,188l-.11-.43a6.38,6.38,0,0,0-1.46-2.26l-5,1.34-.36-1.37,6.9-1.85.29,1.06-1,.43a5.07,5.07,0,0,1,2,2.56Z" />
            <path d="M437.69,194.53l-1,.12c-.17-.87-.34-1.78-.54-2.66-.29-1.24-.4-1.62-1.15-1.44s-.66.49-.69,1.19v2.09a1.8,1.8,0,0,1-1.58,2.09c-1.72.4-2.24-.48-2.72-2.56a19,19,0,0,1-.45-2.82l1.05-.14c.1.65.28,1.5.49,2.4.35,1.53.6,1.94,1.32,1.77s.62-.56.63-1.26v-2.06a1.83,1.83,0,0,1,1.61-2c1.59-.37,2.15.36,2.59,2.31A22.87,22.87,0,0,1,437.69,194.53Z" />
            <path d="M437.15,202.72l-4.89.94-.22-1.09,1.08-.38a4.09,4.09,0,0,1-1.78-2.56,2,2,0,0,1,1.36-2.48l.22-.05.73-.14c1.12-.22,1.89.38,2.14,1.64l.54,2.83.55-.11c.86-.18,1.08-.54.87-1.61-.14-.7-.45-1.87-.75-3l1-.33a23,23,0,0,1,1,3.35C439.3,201.41,438.92,202.38,437.15,202.72Zm-4.57-3A3.86,3.86,0,0,0,434,201.9l1.25-.24-.49-2.56a.77.77,0,0,0-.84-.71.78.78,0,0,0-.21,0l-.38.08c-.69.1-.92.47-.78,1.18Z" />
            <path d="M438.86,206.58l-3.94.62c-.67.1-.86.39-.75,1.07l.16,1-1.06.33a7,7,0,0,1-.45-1.63,1.74,1.74,0,0,1,1.28-2.1,1.92,1.92,0,0,1,.41-.05l4.13-.65-.21-1.29,1-.16.27,1.28,2.07-.33.22,1.39-2.07.33.35,2.18-1.09.17Z" />
            <path d="M433.58,212.43,433.4,211l7.08-1,.19,1.4Zm9.71-1.26-.81.11A.38.38,0,0,1,442,211s0-.06,0-.09l-.09-.67a.37.37,0,0,1,.26-.45l.11,0,.81-.1a.37.37,0,0,1,.45.25.34.34,0,0,1,0,.1l.09.66a.37.37,0,0,1-.25.46Z" />
            <path d="M438.08,220.52c-2.71.28-3.83-.53-4.09-3s.66-3.5,3.38-3.79,3.86.53,4.12,3S440.81,220.23,438.08,220.52Zm-.56-5.32c-2,.2-2.5.72-2.35,2.19s.79,1.86,2.75,1.66,2.52-.72,2.37-2.2S439.52,215,437.52,215.2Z" />
            <path d="M440.42,228.56l-5.35.34-.09-1.42,4.94-.31c.91-.06,1.17-.31,1.13-1a5.48,5.48,0,0,0-1.23-2.58l-5.07.33-.09-1.42,7.14-.45.08,1.2-1.08.16a5.32,5.32,0,0,1,1.48,3.08,1.75,1.75,0,0,1-1.42,2A1.61,1.61,0,0,1,440.42,228.56Z" />
          </g>
          <g className="circle-light">
            <path d="M319.46,237.18H395.7a157.89,157.89,0,0,0-156-157.13V156.3A81.65,81.65,0,0,1,319.46,237.18Z" />
            <g className="circle-icon">
              <path d="M312,137a5.29,5.29,0,1,0,5.29,5.28A5.29,5.29,0,0,0,312,137Z" />
              <path d="M341.84,137a5.29,5.29,0,1,0,5.29,5.28A5.29,5.29,0,0,0,341.84,137Z" />
              <path d="M326.87,143.23A8.08,8.08,0,1,0,335,151.3,8.08,8.08,0,0,0,326.87,143.23Z" />
              <path d="M342.77,166.32c-1-5.64-4.4-7.19-4.43-7.21a17.09,17.09,0,0,0-3.82-1.31,10,10,0,0,1-15.26,0,17.37,17.37,0,0,0-3.56,1.26c-.16.07-3.5,1.71-4.46,7.21-1,5.65-.14,7.79-.13,7.82a5.6,5.6,0,0,0,5.05,3.94h21.75a5.54,5.54,0,0,0,5-3.85l.06-.15S343.82,172,342.77,166.32Z" />
              <path d="M350,153.45c-.27-2.44-1.08-4.22-4.34-4.22l-9,.05a9.68,9.68,0,0,1,.21,2,10,10,0,0,1-1.2,4.74,19.36,19.36,0,0,1,3.45,1.29,9.62,9.62,0,0,1,4.06,4.27H346a4.32,4.32,0,0,0,4.48-4.16Z" />
              <path d="M314.86,157.32a19.31,19.31,0,0,1,3.2-1.21,9.88,9.88,0,0,1-1.23-4.81,10.33,10.33,0,0,1,.2-2l-8.23-.05c-3.26,0-4.08,1.78-4.35,4.22l-.39,4a.28.28,0,0,0,0,.09,4.31,4.31,0,0,0,4.56,4.06h2.14A9.85,9.85,0,0,1,314.86,157.32Z" />
            </g>
            <path d="M296,153.55h6.35a4.39,4.39,0,0,1,3,1.07c.8.71,1.21,2.06,1.21,4.06s-.42,3.39-1.24,4.13a4.26,4.26,0,0,1-3,1.12c-.44,0-1.11,0-2-.09a19.2,19.2,0,0,1-2.2-.17v5.55h-2.15Zm6,8.47c1.58,0,2.38-1.11,2.38-3.32s-.75-3.23-2.26-3.23h-4V162Z" />
            <path d="M310.33,168.82a3,3,0,0,1-1.51-1.79,10.6,10.6,0,0,1-.46-3.5,10.94,10.94,0,0,1,.46-3.55,3,3,0,0,1,1.5-1.77,6.8,6.8,0,0,1,2.89-.51,8,8,0,0,1,2.57.34,2.51,2.51,0,0,1,1.41,1.17,4.92,4.92,0,0,1,.44,2.3,2.57,2.57,0,0,1-.87,2.18,4.05,4.05,0,0,1-2.51.67h-3.76a5.88,5.88,0,0,0,.29,2,1.76,1.76,0,0,0,1,1,5.05,5.05,0,0,0,2,.31h3.56V169l-2.12.35c-.59.06-1.27.09-2,.09A6.55,6.55,0,0,1,310.33,168.82Zm3.73-5.8a2,2,0,0,0,1.27-.33,1.45,1.45,0,0,0,.39-1.16,2.3,2.3,0,0,0-.56-1.77,3.15,3.15,0,0,0-1.94-.46,3.56,3.56,0,0,0-1.67.32,1.88,1.88,0,0,0-.83,1.12,8.6,8.6,0,0,0-.24,2.31Z" />
            <path d="M321.86,168.84a3.2,3.2,0,0,1-1.6-1.77,9.63,9.63,0,0,1-.52-3.54,9.86,9.86,0,0,1,.53-3.61,3.16,3.16,0,0,1,1.61-1.74,7.28,7.28,0,0,1,2.88-.48,7.05,7.05,0,0,1,2.88.49,3.23,3.23,0,0,1,1.6,1.75,9.33,9.33,0,0,1,.53,3.59,9.56,9.56,0,0,1-.52,3.56,3.1,3.1,0,0,1-1.59,1.76,8.5,8.5,0,0,1-5.8,0Zm4.57-1.52a1.88,1.88,0,0,0,.91-1.17,11.53,11.53,0,0,0,0-5.29,1.89,1.89,0,0,0-.91-1.16,4.14,4.14,0,0,0-1.67-.28,4,4,0,0,0-1.67.28,1.91,1.91,0,0,0-.9,1.16,9,9,0,0,0-.3,2.64,8.91,8.91,0,0,0,.3,2.67,1.88,1.88,0,0,0,.9,1.16,4.88,4.88,0,0,0,3.34,0Z" />
            <path d="M332.22,157.85H334l.38,1.15a8.94,8.94,0,0,1,1.66-.91,5.11,5.11,0,0,1,1.89-.39,3.38,3.38,0,0,1,3.07,1.59,10,10,0,0,1,0,8.62,3.41,3.41,0,0,1-3.09,1.46,6.62,6.62,0,0,1-1.82-.26,7.88,7.88,0,0,1-1.73-.71v5.9h-2.11Zm7.61,5.68a5.27,5.27,0,0,0-.71-3.1,2.19,2.19,0,0,0-1.84-1,6.3,6.3,0,0,0-2.93.85v6.49a6.2,6.2,0,0,0,1.41.59,5.09,5.09,0,0,0,1.5.22c1.76,0,2.61-1.35,2.61-4.05Z" />
            <path d="M345.22,168.4a4.19,4.19,0,0,1-.77-2.83V153h2.12v12.3a3,3,0,0,0,.36,1.72,1.58,1.58,0,0,0,1.11.58l1.06.12v1.58h-1.48A3.12,3.12,0,0,1,345.22,168.4Z" />
            <path d="M352.35,168.82a3.06,3.06,0,0,1-1.52-1.79,14,14,0,0,1,0-7.05,3,3,0,0,1,1.5-1.77,6.87,6.87,0,0,1,2.9-.51,8,8,0,0,1,2.57.34,2.49,2.49,0,0,1,1.4,1.17,4.81,4.81,0,0,1,.45,2.3,2.57,2.57,0,0,1-.87,2.18,4.05,4.05,0,0,1-2.51.67H352.5a6.41,6.41,0,0,0,.29,2,1.79,1.79,0,0,0,1,1,5.05,5.05,0,0,0,2,.31h3.55V169l-2.06.27c-.6.06-1.27.09-2,.09A6.41,6.41,0,0,1,352.35,168.82Zm3.73-5.8a1.88,1.88,0,0,0,1.26-.33,1.45,1.45,0,0,0,.39-1.16,2.26,2.26,0,0,0-.56-1.77,3.05,3.05,0,0,0-1.95-.46,3.56,3.56,0,0,0-1.67.32,1.81,1.81,0,0,0-.83,1.12,8.23,8.23,0,0,0-.25,2.31Z" />
          </g>
        </g>

        {/* <!-- PROCESS --> */}
        <g className="circle-sector-4 js-circle-sector">
          <g className="circle-dark circle-active gem-wheel-target">
            <path
              id="gem-wheel-organisation"
              d="M398.56,240.06a160,160,0,0,1-20.8,77.08l68.15,39.57a237.09,237.09,0,0,0,31.16-116.65Z"
              className={
                props.selectedId === "gem-wheel-organisation"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M417.67,324.54c3.54,1.8,4.14,3.42,2.65,6.34s-3.16,3.37-6.7,1.56-4.14-3.42-2.65-6.33S414.13,322.73,417.67,324.54Zm-3.28,6.4c2.67,1.36,3.82,1.2,4.76-.65s.41-2.89-2.25-4.25-3.82-1.2-4.77.66-.4,2.88,2.26,4.24Z" />
            <path d="M418.55,317.09l1.14.72-.2.44a7.21,7.21,0,0,0-.43,2.91l4.72,2.16-.65,1.42-6.51-3,.5-1.1,1.07.31a5.87,5.87,0,0,1,.2-3.54Z" />
            <path d="M422.75,309.49l-.33,1.07a2.59,2.59,0,0,1,1.26.22c1.48.61,1.79,1.67.81,4a8,8,0,0,1-.71,1.4.67.67,0,0,0,1.23,0l1.48-2.53c.77-1.32,1.56-1.58,2.67-1.13,1.58.65,1.72,1.72.63,4.38s-2,3.36-3.51,2.72c-.74-.31-1-.82-1-1.79a1.38,1.38,0,0,1-1.87-.59,1.4,1.4,0,0,1-.13-.36,1.84,1.84,0,0,1-2.14.23c-1.54-.63-1.83-1.66-.84-4.06a7.7,7.7,0,0,1,.9-1.67l.93-2.25Zm.38,2.64c-.84-.35-1.31,0-1.88,1.35s-.48,2,.36,2.31,1.32,0,1.89-1.38.44-2-.37-2.28Zm4,2.67L425.89,317a1.45,1.45,0,0,0,.84,1.15c.9.37,1.21,0,1.92-1.73s.7-2.19-.16-2.54c-.61-.26-.79-.07-1.34.89Z" />
            <path d="M426.79,302.5l4.69,1.7-.42,1.15-1.12-.22a4.78,4.78,0,0,1,.12,3.39c-.49,1.35-1.5,2-2.72,1.57l-.71-.26c-1.07-.39-1.41-1.36-.92-2.7l1.07-3-.52-.19c-.83-.28-1.21-.06-1.62,1.08-.27.72-.64,2-1,3.2l-1-.22a28.65,28.65,0,0,1,1-3.71C424.22,302.6,425.09,301.88,426.79,302.5Zm2.24,5.3a4.32,4.32,0,0,0,0-2.84l-1.2-.44-1,2.7c-.22.7,0,1,.54,1.19l.36.13C428.37,308.78,428.77,308.55,429,307.8Z" />
            <path d="M429.22,293.53l5.12,1.59-.46,1.49-4.73-1.46c-.87-.27-1.22-.11-1.43.6a6.26,6.26,0,0,0,.15,3.1l4.85,1.49-.46,1.5-6.83-2.12.39-1.27,1.06.23a6.31,6.31,0,0,1-.19-3.7,1.85,1.85,0,0,1,2.1-1.56A2.26,2.26,0,0,1,429.22,293.53Z" />
            <path d="M426,288.59l.79.22c.27.07.37.25.29.55l-.2.71a.39.39,0,0,1-.42.36.23.23,0,0,1-.11,0l-.79-.22a.38.38,0,0,1-.3-.44l0-.1.19-.72a.4.4,0,0,1,.45-.34Zm9.43,2.64-.41,1.49-6.9-1.89.41-1.49Z" />
            <path d="M430.59,281.3l1,.37c-.26.95-.54,1.93-.77,2.88-.33,1.37-.42,1.8.33,2s.82-.19,1.18-.86l1-2.05c.59-1.17,1.17-1.61,2.38-1.32,1.71.41,1.76,1.52,1.21,3.81a22.17,22.17,0,0,1-.93,3l-1-.36c.22-.69.46-1.62.7-2.6.4-1.68.38-2.2-.33-2.37s-.82.26-1.17.94l-1,2c-.6,1.2-1.26,1.54-2.4,1.26-1.58-.38-1.74-1.36-1.21-3.49A27.78,27.78,0,0,1,430.59,281.3Z" />
            <path d="M434.35,273.3l4.89,1-.23,1.21-1.15-.05a4.7,4.7,0,0,1,.63,3.33c-.27,1.41-1.17,2.22-2.45,2l-.73-.14c-1.12-.22-1.6-1.14-1.33-2.53l.61-3.1-.54-.11c-.87-.15-1.21.13-1.44,1.31-.15.76-.32,2.09-.48,3.31l-1,0a30.75,30.75,0,0,1,.41-3.82C431.84,273.82,432.58,273,434.35,273.3Zm3,4.91a4.5,4.5,0,0,0-.48-2.81l-1.25-.25-.56,2.82c-.11.72.18,1,.71,1.09l.38.08c.72.16,1.07-.14,1.23-.91Z" />
            <path d="M434,268.78l3.94.62c.66.1.94-.13,1.06-.88l.18-1.13h1.11a9.59,9.59,0,0,1-.1,1.86,1.83,1.83,0,0,1-1.88,1.78,1.52,1.52,0,0,1-.42-.06l-4.13-.65-.23,1.42-1-.16.15-1.43-2.07-.33.24-1.53,2.07.33.38-2.41,1.09.17Z" />
            <path d="M431.07,262.76l.82.11c.27,0,.4.2.36.5l-.1.74a.38.38,0,0,1-.38.4h-.1l-.81-.11a.38.38,0,0,1-.36-.41.28.28,0,0,1,0-.09l.09-.74C430.65,262.85,430.82,262.73,431.07,262.76Zm9.71,1.33-.2,1.53-7.1-.93.2-1.54Z" />
            <path d="M438.27,254.11c2.72.27,3.64,1.38,3.37,4.11s-1.39,3.64-4.11,3.37-3.66-1.38-3.39-4.11S435.55,253.84,438.27,254.11Zm-.58,5.86c2,.2,2.61-.24,2.77-1.86s-.38-2.18-2.35-2.37-2.61.24-2.77,1.86.35,2.18,2.35,2.37Z" />
            <path d="M437,244.94l5.36.28-.08,1.57-4.95-.26c-.91-.05-1.2.19-1.24.93a6.21,6.21,0,0,0,.92,3l5.07.26-.09,1.56-7.15-.38.07-1.32H436a6.3,6.3,0,0,1-1.09-3.55C435,245.58,435.69,244.87,437,244.94Z" />
          </g>
          <g className="circle-dark circle-active gem-wheel-target">
            <path
              id="gem-wheel-pace"
              d="M376.31,319.63A161.44,161.44,0,0,1,320,376.17l39.09,68.38a239.46,239.46,0,0,0,85.42-85.36Z"
              className={
                props.selectedId === "gem-wheel-pace" ? "selected-segment" : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M370.22,384.43c1.65,1.91,1.42,3-.51,4.68a20.07,20.07,0,0,1-1.94,1.5l2.6,3-1.15,1.06L362.6,387c.9-.92,1.85-1.8,2.84-2.63C367.47,382.6,368.61,382.55,370.22,384.43ZM369,385.49c-1-1.16-1.65-1.13-2.82-.12-.64.56-1.22,1.05-1.67,1.47l2.53,2.92,1.69-1.45c1.18-1,1.3-1.65.29-2.82Z" />
            <path d="M377.67,380.3l3.43,3.62-.89.84-.9-.7a4.72,4.72,0,0,1-1.42,3.08c-1,1-2.24,1.14-3.13.19l-.52-.54c-.78-.84-.64-1.86.39-2.83l2.29-2.18-.38-.41c-.62-.62-1.06-.59-1.94.24-.56.53-1.47,1.52-2.3,2.42l-.82-.65A30.07,30.07,0,0,1,374,380.5C375.34,379.2,376.43,378.94,377.67,380.3Zm-.37,5.75a4.47,4.47,0,0,0,1.23-2.57l-.87-.92-2.09,2a.83.83,0,0,0-.21,1.14.87.87,0,0,0,.16.17l.26.27c.49.43,1,.4,1.52-.14Z" />
            <path d="M382.09,372.89l.5.78c-.53.49-1.15,1.09-1.53,1.48-1.25,1.29-1.26,2.12.17,3.51s2.26,1.35,3.51.07a15,15,0,0,0,1.5-1.78l.79.56a8.75,8.75,0,0,1-1.63,2.24c-1.92,2-3.33,2-5.31.08s-2-3.35-.08-5.32A8.83,8.83,0,0,1,382.09,372.89Z" />
            <path d="M391.8,370.76l.8.52a10,10,0,0,1-1.76,2.6c-1.83,2.05-3.28,2.15-5.34.32s-2.06-3.32-.36-5.22,2.92-2,4.09-1a1.78,1.78,0,0,1,.55,2.45,1.43,1.43,0,0,1-.3.35l-2.35,2.63c1.26.92,2,.63,3-.51C390.63,372.3,391.3,371.46,391.8,370.76Zm-5.52,2,2.1-2.34c.44-.5.14-.92-.19-1.22-.66-.56-1.21-.45-2.1.55s-1,1.8.19,3Z" />
          </g>
          <g className="circle-dark circle-active gem-wheel-target">
            <path
              id="gem-wheel-payment"
              d="M317.47,377.62a160,160,0,0,1-77.82,21.1V477h-.8v.11A237.13,237.13,0,0,0,356.56,446Z"
              className={
                props.selectedId === "gem-wheel-payment"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M265.78,433.21c.29,2.51-.52,3.3-3.06,3.6a20,20,0,0,1-2.45.15l.47,4-1.6.19L258,431.05a38.05,38.05,0,0,1,3.83-.58C264.5,430.14,265.48,430.74,265.78,433.21Zm-1.62.2c-.18-1.53-.73-1.87-2.27-1.69-.85.1-1.6.19-2.21.28l.46,3.84,2.21-.26c1.54-.19,2-.65,1.81-2.17Z" />
            <path d="M274.22,433.94l.82,4.91-1.21.2-.36-1.08a4.76,4.76,0,0,1-2.9,1.75c-1.42.24-2.49-.32-2.7-1.6l-.13-.74c-.18-1.13.51-1.9,1.91-2.13l3.12-.51-.09-.55c-.16-.86-.54-1.09-1.73-.89-.77.13-2.07.43-3.27.72l-.31-1a28.37,28.37,0,0,1,3.72-1C272.9,431.77,273.94,432.16,274.22,433.94Zm-3.53,4.54a4.36,4.36,0,0,0,2.46-1.43l-.2-1.26-2.84.47c-.71.15-.86.52-.77,1.05l.06.38c.12.69.52.92,1.3.79Z" />
            <path d="M283.06,429.89l-1.43,8a3.83,3.83,0,0,1-3,3.35l-.35-.88a3.16,3.16,0,0,0,1.88-2.24l.06-.29-.32.07a.51.51,0,0,1-.61-.24l-4.07-6.1,1.64-.35,3,4.69a6.41,6.41,0,0,1,.45.81l.22,0c.05-.31.09-.65.13-.93l.82-5.49Z" />
            <path d="M296.22,428.3l1.41,5.18-1.48.4-1.3-4.78c-.23-.8-.61-1.07-1.42-.85s-1.15.62-2.07,1.66l1.34,4.91-1.45.4-1.31-4.79c-.23-.85-.67-1.05-1.41-.84s-1.15.6-2.08,1.64l1.34,4.93-1.5.41-1.88-6.91,1.25-.34.36,1a5.11,5.11,0,0,1,2.72-2.07,1.87,1.87,0,0,1,2.26.68,5,5,0,0,1,2.66-2,1.84,1.84,0,0,1,2.43.94A1.64,1.64,0,0,1,296.22,428.3Z" />
            <path d="M305.81,429.45l.43.85a10.23,10.23,0,0,1-2.81,1.39c-2.6.87-3.92.24-4.79-2.37s-.15-3.91,2.26-4.72,3.54-.3,4,1.19a1.76,1.76,0,0,1-.74,2.39,1.4,1.4,0,0,1-.43.16l-3.35,1.12c.65,1.43,1.39,1.52,2.83,1A19.36,19.36,0,0,0,305.81,429.45Zm-5.77-1,3-1c.63-.21.58-.73.44-1.15-.29-.81-.83-1-2.1-.56-1.47.47-1.82,1.06-1.34,2.69Z" />
            <path d="M313.22,422.19l1.94,5-1.46.56-1.79-4.62c-.33-.85-.67-1-1.36-.75a6.42,6.42,0,0,0-2.34,2.05l1.85,4.73-1.46.56L306,423l1.23-.47.48,1a6.43,6.43,0,0,1,2.79-2.45,1.85,1.85,0,0,1,2.53.68A1.71,1.71,0,0,1,313.22,422.19Z" />
            <path d="M317.05,419.77l1.59,3.67c.27.62.61.72,1.31.42l1-.45.6.93a9.34,9.34,0,0,1-1.63.92,1.83,1.83,0,0,1-2.51-.63,1.56,1.56,0,0,1-.17-.37l-1.66-3.84-1.32.57-.41-.94,1.29-.64-.83-1.93,1.42-.61.83,1.92,2.24-1,.44,1Z" />
          </g>
          <g className="circle-dark">
            <path d="M319.43,240.06a81.65,81.65,0,0,1-79.78,79.51v76.24a157.88,157.88,0,0,0,156-155.75Z" />
            <g className="circle-icon">
              <path d="M346,324.7,339.15,320a13.56,13.56,0,0,0,.16-2h0a14.21,14.21,0,0,0-.15-2.07l6.57-4.91a.71.71,0,0,0,.18-.95l-4.41-7a.71.71,0,0,0-.93-.25l-7.07,3.73a14.53,14.53,0,0,0-2.92-1.67l-.65-8.24a.72.72,0,0,0-.71-.65H321a.73.73,0,0,0-.71.65l-.64,8.23a14.41,14.41,0,0,0-3.39,2L309,303.4a.71.71,0,0,0-.92.28L304,310.84a.7.7,0,0,0,.21.94l6.75,4.65a13.33,13.33,0,0,0-.1,1.56,13.92,13.92,0,0,0,.25,2.61l-6.67,5a.72.72,0,0,0-.17.95l4.41,7a.7.7,0,0,0,.93.25l7.56-4a13.87,13.87,0,0,0,2.4,1.3l.64,8.2a.71.71,0,0,0,.74.66h8.26a.72.72,0,0,0,.71-.66l.62-8.18a14.65,14.65,0,0,0,2.91-1.65l7.7,3.67a.71.71,0,0,0,.93-.29l4.11-7.15,0,0A.72.72,0,0,0,346,324.7Zm-20.9.21A6.92,6.92,0,1,1,332,318,6.92,6.92,0,0,1,325.09,324.91Z" />
            </g>
            <path d="M289.8,307.93h6.35a4.43,4.43,0,0,1,3,1.06c.8.71,1.2,2.07,1.2,4.07s-.41,3.39-1.23,4.13a4.26,4.26,0,0,1-3,1.12,18.73,18.73,0,0,1-2-.09c-.9-.09-1.62-.11-2.2-.17v5.55h-2.14Zm6,8.46c1.58,0,2.38-1.1,2.38-3.31s-.76-3.23-2.26-3.23h-4v6.54Z" />
            <path d="M302.58,312.23h1.64l.49,1.91a7.24,7.24,0,0,1,1.7-1.52,3.83,3.83,0,0,1,2-.54,3.71,3.71,0,0,1,1,.13v2.19a7.69,7.69,0,0,0-1.27-.11,4.28,4.28,0,0,0-1.83.37,6.07,6.07,0,0,0-1.62,1.18v7.76h-2.12Z" />
            <path d="M313.06,323.22a3.22,3.22,0,0,1-1.61-1.77,12.43,12.43,0,0,1,0-7.15,3.19,3.19,0,0,1,1.61-1.74,7.28,7.28,0,0,1,2.88-.48,7.05,7.05,0,0,1,2.88.49,3.16,3.16,0,0,1,1.6,1.75,9.51,9.51,0,0,1,.54,3.58,9.79,9.79,0,0,1-.52,3.57,3.11,3.11,0,0,1-1.6,1.76,7,7,0,0,1-2.9.5A6.78,6.78,0,0,1,313.06,323.22Zm4.56-1.52a1.85,1.85,0,0,0,.91-1.17,11.53,11.53,0,0,0,0-5.29,1.89,1.89,0,0,0-.91-1.16,4.14,4.14,0,0,0-1.67-.28,4,4,0,0,0-1.67.28,1.86,1.86,0,0,0-.89,1.16,8.42,8.42,0,0,0-.31,2.64,8.57,8.57,0,0,0,.31,2.67,1.81,1.81,0,0,0,.89,1.16,5,5,0,0,0,3.34,0Z" />
            <path d="M324.27,322.45q-1.2-1.26-1.2-4.5a9.36,9.36,0,0,1,.55-3.55,3.44,3.44,0,0,1,1.57-1.79,5.78,5.78,0,0,1,2.56-.51,18.71,18.71,0,0,1,3.81.36v1.26h-3.22a3.78,3.78,0,0,0-1.78.35,2.25,2.25,0,0,0-1,1.27,7.8,7.8,0,0,0-.34,2.63,7.56,7.56,0,0,0,.35,2.55,2.18,2.18,0,0,0,1,1.23,3.9,3.9,0,0,0,1.78.34h3.36v1.21a15.32,15.32,0,0,1-1.9.3,19.05,19.05,0,0,1-2.14.12A4.44,4.44,0,0,1,324.27,322.45Z" />
            <path d="M335.35,323.2a3,3,0,0,1-1.51-1.79,10.66,10.66,0,0,1-.46-3.51,10.94,10.94,0,0,1,.46-3.55,3.07,3.07,0,0,1,1.5-1.76,6.8,6.8,0,0,1,2.89-.51,8,8,0,0,1,2.57.34,2.41,2.41,0,0,1,1.41,1.17,4.91,4.91,0,0,1,.44,2.29,2.54,2.54,0,0,1-.87,2.18,3.93,3.93,0,0,1-2.51.67h-3.76a5.88,5.88,0,0,0,.29,2,1.73,1.73,0,0,0,1,1,4.89,4.89,0,0,0,2,.32h3.55v1.28c-.77.11-1.46.2-2.06.26a16.64,16.64,0,0,1-2,.1A6.45,6.45,0,0,1,335.35,323.2Zm3.73-5.81a2,2,0,0,0,1.27-.33,1.42,1.42,0,0,0,.39-1.15,2.3,2.3,0,0,0-.56-1.77,3.08,3.08,0,0,0-2-.49,3.56,3.56,0,0,0-1.67.32,1.85,1.85,0,0,0-.83,1.12,9,9,0,0,0-.24,2.3Z" />
            <path d="M346.68,323.63a9.83,9.83,0,0,1-1.94-.26v-1.28h4.78a3,3,0,0,0,1.51-.28,1.24,1.24,0,0,0,.49-1.16v-.47c0-.91-.61-1.36-1.83-1.36H348a4.2,4.2,0,0,1-2.53-.67,2.6,2.6,0,0,1-.89-2.22v-.63a2.89,2.89,0,0,1,1-2.41,5.16,5.16,0,0,1,3.2-.78q.93,0,2.19.12a17.89,17.89,0,0,1,1.93.26v1.28h-4.51a2.49,2.49,0,0,0-1.46.34,1.38,1.38,0,0,0-.47,1.19v.42a1.11,1.11,0,0,0,.46,1.07,3.09,3.09,0,0,0,1.47.25h1.71a3.54,3.54,0,0,1,2.43.75,2.7,2.7,0,0,1,.84,2.12v.88a2.45,2.45,0,0,1-1.07,2.28,6.24,6.24,0,0,1-3.18.65C348.41,323.73,347.6,323.7,346.68,323.63Z" />
            <path d="M357.22,323.63a10.44,10.44,0,0,1-2-.26v-1.28H360a3,3,0,0,0,1.51-.28,1.24,1.24,0,0,0,.49-1.16v-.47c0-.91-.61-1.36-1.83-1.36h-1.66a4.2,4.2,0,0,1-2.53-.67,2.6,2.6,0,0,1-.89-2.22v-.63a2.89,2.89,0,0,1,1-2.41,5.22,5.22,0,0,1,3.2-.78q.94,0,2.19.12a18.07,18.07,0,0,1,1.94.26v1.28h-4.51a2.52,2.52,0,0,0-1.47.34,1.39,1.39,0,0,0-.46,1.19v.42a1.13,1.13,0,0,0,.45,1.07,3.15,3.15,0,0,0,1.48.25h1.7a3.57,3.57,0,0,1,2.44.75,2.7,2.7,0,0,1,.84,2.12v.88a2.43,2.43,0,0,1-1.08,2.28,6.14,6.14,0,0,1-3.18.65C359,323.73,358.14,323.7,357.22,323.63Z" />
          </g>
        </g>

        {/* <!-- PRODUCT --> */}
        <g className="circle-sector-3 js-circle-sector">
          <g className="circle-light circle-active gem-wheel-target">
            <path
              id="gem-wheel-quality"
              d="M236.77,477V398.74a160.12,160.12,0,0,1-77.17-20.26l-39.14,67.46a237.09,237.09,0,0,0,117.78,31.12V477Z"
              className={
                props.selectedId === "gem-wheel-quality"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M170.42,425.3c-1.43,3.71-3,4.47-6,3.29s-3.69-2.8-2.26-6.51,3-4.46,6.05-3.28S171.86,421.58,170.42,425.3Zm-3.08,5.94-1,1.18a16,16,0,0,1-3.46-3l.73-.68a20.16,20.16,0,0,0,3.73,2.48Zm-3.62-8.54c-1.08,2.79-.81,3.92,1.13,4.67s2.91.11,4-2.68.8-3.92-1.14-4.67-2.91-.12-4,2.66Z" />
            <path d="M179.6,426.13l-2.25,6.79-1.17-.39.2-1a6,6,0,0,1-3.56.06,1.84,1.84,0,0,1-1.41-2.55l1.68-5.1,1.49.49L173,429.14c-.29.83-.09,1.2.7,1.47a5.5,5.5,0,0,0,2.79-.09l1.62-4.91Z" />
            <path d="M187.51,430.65l-1.35,4.8-1.18-.33.13-1.14a4.69,4.69,0,0,1-3.37.37c-1.38-.39-2.12-1.34-1.77-2.6l.21-.71c.31-1.11,1.26-1.51,2.62-1.13l3,.85.15-.53c.22-.85,0-1.21-1.2-1.54-.74-.21-2.05-.48-3.26-.73l.14-1a28.3,28.3,0,0,1,3.78.71C187.22,428.12,188,428.91,187.51,430.65Zm-5.13,2.65a4.37,4.37,0,0,0,2.83-.26l.35-1.22-2.77-.78c-.7-.17-1,.1-1.14.62l-.11.37C181.35,432.69,181.62,433.07,182.38,433.3Z" />
            <path d="M190.59,434.41a.86.86,0,0,0,.79,1.13l.7.17-.08,1.11a5.12,5.12,0,0,1-1.51-.17,1.77,1.77,0,0,1-1.55-2,1.94,1.94,0,0,1,.07-.33l2-8.27,1.52.37Z" />
            <path d="M195,437.54l-1.51-.34,1.53-7,1.51.33Zm2.14-9.57-.17.81a.39.39,0,0,1-.43.35.38.38,0,0,1-.1,0l-.73-.16a.39.39,0,0,1-.37-.4.5.5,0,0,1,0-.12l.18-.8a.4.4,0,0,1,.45-.32l.08,0,.72.16c.3.06.46.25.35.49Z" />
            <path d="M200.75,432.53l-.76,3.92c-.12.66.1.94.84,1.09l1.13.21v1.12a8.94,8.94,0,0,1-1.86-.17,1.82,1.82,0,0,1-1.71-1.92,2.14,2.14,0,0,1,.08-.44l.79-4.1-1.41-.27.19-1,1.43.2.39-2.06,1.52.3-.39,2.06,2.39.46-.21,1.08Z" />
            <path d="M211.9,433.3l-4.16,7a3.83,3.83,0,0,1-4,2.08v-.95A3.16,3.16,0,0,0,206.3,440l.15-.24-.32-.05a.5.5,0,0,1-.48-.45l-1.73-7.14,1.66.26,1.14,5.44a4.08,4.08,0,0,1,.13.91h.23c.15-.27.31-.58.45-.82l2.69-4.91Z" />
          </g>
          <g className="circle-light circle-active gem-wheel-target">
            <path
              id="gem-wheel-knowledge"
              d="M99.72,320.39,32.59,359.15A239.53,239.53,0,0,0,118,444.5l39.14-67.44A161.58,161.58,0,0,1,99.72,320.39Z"
              className={
                props.selectedId === "gem-wheel-knowledge"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M72.78,349.48l-.71-1.06a5.75,5.75,0,0,1-.8.68l-3,2-.9-1.33,8.35-5.69.91,1.34-2.57,1.75a4.83,4.83,0,0,1-1,.54l.7,1.05,5.36.31,1,1.46-5.19-.28a3.8,3.8,0,0,1-1-.16h0a3.82,3.82,0,0,1-.19,1.07l-1.86,5.21-1-1.49Z" />
            <path d="M81.38,360.16l-4.29,3.23-1-1.25,4-3c.72-.55.79-.92.35-1.51a6.41,6.41,0,0,0-2.62-1.69l-4,3.06-1-1.25,5.73-4.3.79,1.06-.81.73a6.34,6.34,0,0,1,3.12,2,1.84,1.84,0,0,1,.08,2.6A1.52,1.52,0,0,1,81.38,360.16Z" />
            <path d="M86,368.68c-2.11,1.74-3.54,1.61-5.29-.5s-1.61-3.55.49-5.29,3.56-1.64,5.31.48S88.12,366.93,86,368.68Zm-3.79-4.54c-1.52,1.27-1.66,2-.62,3.28s1.81,1.27,3.34,0,1.67-2,.63-3.28S83.8,362.86,82.22,364.14Z" />
            <path d="M91.5,379.42a.55.55,0,0,1-.69-.17l-1-1a.59.59,0,0,1-.07-.75l2.67-4c.23-.32.46-.63.68-1l-.13-.14L92,373l-4.2,2.35a.6.6,0,0,1-.75-.14l-.94-1a.56.56,0,0,1-.11-.7l3.45-6.22,1.09,1.18L87.75,373a8.39,8.39,0,0,1-.64.92l.14.16,1-.55,4.26-2.35a.57.57,0,0,1,.72.12l1,1a.58.58,0,0,1,.06.74l-2.67,4c-.22.3-.41.61-.62.94l.14.16a9.9,9.9,0,0,1,1-.57l4.82-2.4L98,376.4Z" />
            <path d="M96.6,382.14c-.42.43-.39.89.1,1.37l.51.51-.64.91a5.63,5.63,0,0,1-1.2-.92,1.76,1.76,0,0,1-.31-2.49,1.21,1.21,0,0,1,.21-.24l6-6.06,1.11,1.1Z" />
            <path d="M103.12,389.48l-.53.79a9.81,9.81,0,0,1-2.58-1.8c-2-1.86-2.1-3.31-.23-5.34s3.35-2,5.22-.29,2,3,.91,4.11a1.76,1.76,0,0,1-2.45.51,1.43,1.43,0,0,1-.35-.3l-2.6-2.39c-.93,1.25-.66,1.94.46,3A26.85,26.85,0,0,0,103.12,389.48Zm-1.9-5.54,2.31,2.13c.49.45.92.14,1.22-.18.57-.65.47-1.2-.51-2.11S102.43,382.73,101.22,383.94Z" />
            <path d="M116.06,388.59l-6.61,7.84-.94-.79.53-.83a5.08,5.08,0,0,1-3.12-1.11c-1.51-1.28-1.26-2.79.33-4.67,1.81-2.15,3.17-2.21,4.71-.93a6,6,0,0,1,1.65,2.24c.27-.39.54-.73.86-1.11l1.39-1.65Zm-8.93,4.51a4,4,0,0,0,2.38.83L112,391a9.08,9.08,0,0,0-1.5-1.72c-1-.84-1.65-.8-3,.76s-1.24,2.24-.33,3Z" />
            <path d="M121.29,397.52l-1-.59a2.32,2.32,0,0,1-.54,1.15c-1,1.27-2.07,1.3-4.1-.26a9.32,9.32,0,0,1-1.18-1,.67.67,0,0,0-.54.77.7.7,0,0,0,.26.41l2.06,2.1c1.08,1.08,1.13,1.91.39,2.87-1,1.35-2.1,1.21-4.38-.54s-2.73-2.77-1.72-4.09c.49-.64,1-.73,2-.53a1.4,1.4,0,0,1,1-1.67,1.72,1.72,0,0,1,.39,0,1.83,1.83,0,0,1,.34-2.12c1-1.32,2.07-1.33,4.13.25a7.09,7.09,0,0,1,1.38,1.3l1.94,1.48ZM115,400.39l-1.8-1.77a1.46,1.46,0,0,0-1.33.51c-.59.77-.32,1.16,1.17,2.3s1.93,1.24,2.49.51c.39-.55.25-.77-.53-1.55Zm3.63-3.19c.55-.72.36-1.26-.82-2.16s-1.78-1-2.33-.25-.34,1.27.85,2.18,1.76.93,2.3.23Z" />
            <path d="M124.76,406.3l-.42.85a9.58,9.58,0,0,1-2.79-1.43c-2.25-1.57-2.54-3-1-5.26s3-2.45,5.14-1,2.35,2.66,1.46,4a1.76,1.76,0,0,1-2.38.82,1.47,1.47,0,0,1-.37-.24l-2.9-2c-.76,1.37-.39,2,.86,2.89C123.06,405.3,124,405.89,124.76,406.3Zm-2.62-5.24,2.58,1.8c.54.38.93,0,1.18-.34.48-.72.31-1.25-.79-2-1.26-.89-1.89-.81-3,.55Z" />
          </g>
          <g className="circle-light circle-active gem-wheel-target">
            <path
              id="gem-wheel-selling"
              d="M0,240A237.06,237.06,0,0,0,31.16,356.66L98.27,317.9A160,160,0,0,1,77,240.05Z"
              className={
                props.selectedId === "gem-wheel-selling"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M46.59,269.76l-1.08.06c-.1-.93-.24-2.13-.39-3.17-.22-1.5-.36-2.06-1.7-1.87-1,.15-1.24.59-1.32,1.55l-.23,2.27a2.56,2.56,0,0,1-2.49,2.65h0c-2.3.34-2.91-.7-3.3-3.35a19.48,19.48,0,0,1-.22-3.5l1.09,0c.23,2,.3,2.57.42,3.4.25,1.61.54,2.05,1.79,1.87,1.09-.16,1.26-.61,1.34-1.53l.24-2.32a2.59,2.59,0,0,1,2.52-2.61c2.32-.34,2.81.75,3.17,3.24A15.76,15.76,0,0,1,46.59,269.76Z" />
            <path d="M39.53,279.86l-.89.32a10.2,10.2,0,0,1-1-3c-.52-2.7.28-3.92,3-4.44s3.9.37,4.38,2.87-.17,3.55-1.71,3.85A1.78,1.78,0,0,1,41,278.4a2.16,2.16,0,0,1-.1-.44l-.66-3.47c-1.5.45-1.69,1.18-1.4,2.67A22.11,22.11,0,0,0,39.53,279.86Zm1.78-5.58.59,3.09c.13.65.65.66,1.09.58.84-.18,1.09-.69.84-2-.3-1.51-.84-1.93-2.52-1.65Z" />
            <path d="M41.27,283.53c-.59.13-.81.55-.66,1.21l.17.71-1,.41a5.37,5.37,0,0,1-.52-1.42,1.77,1.77,0,0,1,1.06-2.26l.33-.09,8.3-1.9.35,1.53Z" />
            <path d="M42.43,288.3c-.58.14-.79.56-.63,1.22l.18.7-1,.45a5.39,5.39,0,0,1-.56-1.41,1.78,1.78,0,0,1,1-2.3,1.81,1.81,0,0,1,.31-.09l8.27-2.1.38,1.52Z" />
            <path d="M41.73,293.63l-.42-1.49,6.9-1.93.42,1.5ZM51.18,291l-.79.22c-.27.08-.45,0-.53-.32l-.2-.71c-.08-.3,0-.48.29-.55l.79-.22a.39.39,0,0,1,.5.24.24.24,0,0,1,0,.08l.2.72a.39.39,0,0,1-.18.52l-.06,0Z" />
            <path d="M49.67,301.24l-5.12,1.61-.47-1.49,4.72-1.49c.87-.27,1.06-.6.83-1.31a6.11,6.11,0,0,0-1.9-2.46l-4.83,1.54-.47-1.5L49.25,294l.4,1.26-1,.42a6.37,6.37,0,0,1,2.26,2.94,1.85,1.85,0,0,1-.8,2.48A1.76,1.76,0,0,1,49.67,301.24Z" />
            <path d="M54,310.09l-.48-1a2.24,2.24,0,0,1-1.08.68c-1.5.56-2.44,0-3.32-2.42a7.69,7.69,0,0,1-.43-1.51c-.49-.13-1,.12-.88.86l.62,2.86c.33,1.5-.07,2.23-1.2,2.65-1.6.58-2.43-.11-3.42-2.81s-.83-3.8.73-4.37c.76-.28,1.28-.06,2,.61a1.4,1.4,0,0,1,1.8-.83,1.29,1.29,0,0,1,.33.17,1.84,1.84,0,0,1,1.42-1.62c1.55-.57,2.46,0,3.36,2.42a6.92,6.92,0,0,1,.47,1.84l.85,2.29Zm-6.83-.93-.6-2.48a1.45,1.45,0,0,0-1.4-.28c-.9.33-.89.81-.24,2.56s1,2.09,1.84,1.77c.64-.24.64-.49.4-1.57Zm4.77-.76c.86-.31,1-.87.47-2.27s-1-1.77-1.84-1.45-1,.88-.45,2.29,1,1.73,1.82,1.43Z" />
          </g>
          <g className="circle-light">
            <path d="M156.17,240.05H79.92A157.89,157.89,0,0,0,236.77,395.83V319.59A81.66,81.66,0,0,1,156.17,240.05Z" />
            <g className="circle-icon">
              <path d="M165.79,300.81l0-.07H158.6a2.26,2.26,0,0,0-.58.09h-19.1a2.13,2.13,0,0,0-2.13,2.13v16.52a5.33,5.33,0,0,0,5.33,5.33h16.31a5.32,5.32,0,0,0,5.33-5.33v-.85h2a8.55,8.55,0,0,0,8.53-8.52v-.77A8.56,8.56,0,0,0,165.79,300.81Zm4,9.26a4,4,0,0,1-4,4h-2v-7.91a5.5,5.5,0,0,0-.09-.91h2.09l0,.05a4,4,0,0,1,4,4Z" />
              <path d="M130.77,327.89h41.89v.41a5.33,5.33,0,0,1-5.33,5.33H136.1a5.33,5.33,0,0,1-5.33-5.33v-.42Z" />
            </g>
            <path d="M115.11,308h6.36a4.43,4.43,0,0,1,3,1.06c.8.71,1.2,2.07,1.2,4.07s-.41,3.39-1.23,4.13a4.26,4.26,0,0,1-3,1.12,18.73,18.73,0,0,1-2-.09c-.9-.09-1.62-.11-2.2-.17v5.55h-2.15Zm6,8.46c1.59,0,2.38-1.1,2.38-3.31s-.75-3.23-2.25-3.23h-4v6.54Z" />
            <path d="M127.89,312.3h1.64l.49,1.91a6.85,6.85,0,0,1,1.7-1.52,3.8,3.8,0,0,1,2-.54,3.71,3.71,0,0,1,1,.13v2.19a9.08,9.08,0,0,0-1.28-.11,4.44,4.44,0,0,0-1.83.37,6.49,6.49,0,0,0-1.61,1.18v7.76h-2.13Z" />
            <path d="M138.37,323.3a3.2,3.2,0,0,1-1.6-1.77,12.43,12.43,0,0,1,0-7.15,3.17,3.17,0,0,1,1.6-1.74,7.28,7.28,0,0,1,2.88-.48,7.05,7.05,0,0,1,2.88.49,3.11,3.11,0,0,1,1.61,1.75,12.43,12.43,0,0,1,0,7.15,3.16,3.16,0,0,1-1.52,1.75,7,7,0,0,1-2.91.5A6.89,6.89,0,0,1,138.37,323.3Zm4.56-1.52a1.84,1.84,0,0,0,.92-1.17,11.53,11.53,0,0,0,0-5.29,1.82,1.82,0,0,0-.92-1.16,4.14,4.14,0,0,0-1.67-.28,4,4,0,0,0-1.67.28,1.89,1.89,0,0,0-.89,1.16,8.72,8.72,0,0,0-.31,2.64,8.92,8.92,0,0,0,.31,2.67,1.89,1.89,0,0,0,.89,1.16,4.66,4.66,0,0,0,3.34-.06Z" />
            <path d="M149.39,322.39a7.43,7.43,0,0,1-1-4.35,12.07,12.07,0,0,1,.41-3.43,3.44,3.44,0,0,1,1.35-1.89,4.68,4.68,0,0,1,2.53-.61,10.76,10.76,0,0,1,3.32.61v-5.31h2.13v16.22h-1.75l-.38-1.12a5.06,5.06,0,0,1-1.58.86,5.42,5.42,0,0,1-1.95.39A3.54,3.54,0,0,1,149.39,322.39Zm6.61-1.22V314.3l-.68-.21c-.33-.09-.66-.17-1-.23a6.63,6.63,0,0,0-1-.07,3.12,3.12,0,0,0-1.64.37,2.26,2.26,0,0,0-.87,1.27,9.55,9.55,0,0,0-.28,2.55,9.28,9.28,0,0,0,.26,2.41,2.2,2.2,0,0,0,.83,1.27,2.59,2.59,0,0,0,1.53.4A5.89,5.89,0,0,0,156,321.17Z" />
            <path d="M161.77,322.89a3.61,3.61,0,0,1-.86-2.62v-8H163v7.44a2.13,2.13,0,0,0,.55,1.69,2.48,2.48,0,0,0,1.68.48,5.79,5.79,0,0,0,3.15-1V312.3h2.12v11.37h-1.7l-.42-1.23a7.61,7.61,0,0,1-4.15,1.36A3.37,3.37,0,0,1,161.77,322.89Z" />
            <path d="M174.22,322.48q-1.2-1.28-1.2-4.5a9.17,9.17,0,0,1,.55-3.55,3.5,3.5,0,0,1,1.56-1.79,5.78,5.78,0,0,1,2.56-.51,18.71,18.71,0,0,1,3.81.36v1.26h-3.28a3.8,3.8,0,0,0-1.79.35,2.25,2.25,0,0,0-1,1.27,8.1,8.1,0,0,0-.34,2.63,7.1,7.1,0,0,0,.36,2.55,2.18,2.18,0,0,0,1,1.23,3.74,3.74,0,0,0,1.77.34h3.36v1.18a14.88,14.88,0,0,1-1.89.3,19.23,19.23,0,0,1-2.15.12A4.41,4.41,0,0,1,174.22,322.48Z" />
            <path d="M185,322.76a4.08,4.08,0,0,1-.76-2.91l.08-5.85h-1.8v-1.38l1.85-.36.31-3.19h1.68v3.23h3.09V314H186.4v5.83a2.25,2.25,0,0,0,.44,1.59,1.69,1.69,0,0,0,1.09.51l1.41.13v1.57h-1.72A3.33,3.33,0,0,1,185,322.76Z" />
          </g>
        </g>

        {/* <!-- PACE --> */}
        <g className="circle-sector-1 js-circle-sector">
          <g className="circle-dark circle-active gem-wheel-target">
            <path
              id="gem-wheel-appearance"
              d="M77,237.17a160.07,160.07,0,0,1,20.43-77.7L30.74,121.1A237,237,0,0,0,0,237.16Z"
              className={
                props.selectedId === "gem-wheel-appearance"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M38.7,219.17l-.34,4,3,1.2-.13,1.49-9.42-4a.56.56,0,0,1-.35-.58l.13-1.49a.51.51,0,0,1,.44-.49L42,216.94l-.13,1.51Zm-1.31.29-3.92.84-.84.18v.4l.8.3,3.73,1.5Z" />
            <path d="M39.43,208.85c2.75.35,3.57,1.37,3.33,3.18a4.68,4.68,0,0,1-1,2.48,14,14,0,0,1,1.45.14l2,.26L45,216.3,35,215l.15-1.17h1.1a4.47,4.47,0,0,1-.9-3.12C35.56,209.06,37,208.53,39.43,208.85Zm-.21,1.45c-1.9-.25-2.6.08-2.73,1.11a4.11,4.11,0,0,0,.73,2.48l3.61.46a9,9,0,0,0,.65-2.1C41.65,211.07,41.22,210.56,39.22,210.3Z" />
            <path d="M40.85,200.45c2.73.47,3.51,1.53,3.2,3.32a4.94,4.94,0,0,1-1.15,2.44,13.66,13.66,0,0,1,1.43.2l2,.34-.24,1.39-9.94-1.71.2-1.16,1.1.08a4.45,4.45,0,0,1-.76-3.15C37,200.49,38.44,200,40.85,200.45Zm-.26,1.44c-1.9-.32-2.6,0-2.78,1a4,4,0,0,0,.63,2.5L42,206a8.83,8.83,0,0,0,.75-2.07c.2-1.18-.17-1.7-2.18-2.05Z" />
            <path d="M44.73,193.45l1,.06a8.58,8.58,0,0,1-.18,2.86c-.52,2.44-1.71,3.15-4.41,2.58s-3.46-1.77-3-4,1.48-2.89,3-2.56c1.26.27,1.89,1,1.64,2.13l-.68,3.14c1.55.18,2-.35,2.29-1.7A23.3,23.3,0,0,0,44.73,193.45Zm-3.72,4,.6-2.79c.13-.59-.35-.8-.78-.89-.85-.17-1.26.16-1.52,1.35-.29,1.34,0,1.9,1.7,2.31Z" />
            <path d="M43,184.85l4.83,1.24-.28,1.08L46.41,187a4.1,4.1,0,0,1,.5,3.09,2,2,0,0,1-2.29,1.66l-.21,0-.72-.19a1.74,1.74,0,0,1-1.34-2.06,1.93,1.93,0,0,1,.11-.34l.71-2.79-.54-.14c-.85-.2-1.2,0-1.47,1.09-.18.68-.4,1.88-.59,3l-1-.13a22.5,22.5,0,0,1,.54-3.45C40.48,185.11,41.22,184.4,43,184.85Zm2.83,4.69a3.88,3.88,0,0,0-.37-2.59l-1.24-.32-.65,2.53a.72.72,0,0,0,.67,1l.37.09C45.28,190.48,45.64,190.24,45.82,189.54Z" />
            <path d="M42.61,177.69l1.24.54-.13.42a6.42,6.42,0,0,0,.06,2.69l5,1.47-.41,1.37-6.86-2,.31-1,1.09.17a5.12,5.12,0,0,1-.38-3.25Z" />
            <path d="M47.16,171.42,51.9,173l-.36,1.06-1.12-.2a4.11,4.11,0,0,1,.29,3.11,1.94,1.94,0,0,1-2.28,1.53,1.39,1.39,0,0,1-.33-.1l-.71-.23a1.74,1.74,0,0,1-1.2-2.15,2.44,2.44,0,0,1,.14-.34l.91-2.73-.53-.18c-.84-.26-1.2-.06-1.55,1-.22.66-.52,1.85-.79,2.93l-1-.2a22.43,22.43,0,0,1,.78-3.41C44.64,171.51,45.45,170.85,47.16,171.42Zm2.5,4.89a3.82,3.82,0,0,0-.19-2.62l-1.25-.39-.83,2.48a.78.78,0,0,0,.37,1,.79.79,0,0,0,.22.06l.37.12a.91.91,0,0,0,1.31-.69Z" />
            <path d="M49.81,163.3l5,1.91-.5,1.33-4.63-1.76c-.85-.32-1.2-.2-1.44.43a5.55,5.55,0,0,0,.06,2.86l4.75,1.78-.51,1.33-6.68-2.54.42-1.12,1,.3a5.45,5.45,0,0,1-.07-3.41,1.76,1.76,0,0,1,2.14-1.27A2.05,2.05,0,0,1,49.81,163.3Z" />
            <path d="M51.3,155.58l.76.53c-.3.59-.63,1.29-.83,1.75-.63,1.5-.33,2.24,1.51,3s2.58.49,3.21-1a12.39,12.39,0,0,0,.68-2l.93.25a7.61,7.61,0,0,1-.61,2.45c-1,2.3-2.26,2.75-4.79,1.67s-3.11-2.33-2.13-4.62A7.38,7.38,0,0,1,51.3,155.58Z" />
            <path d="M59.72,151.22l.9.28a8.2,8.2,0,0,1-.82,2.74c-1.06,2.26-2.38,2.69-4.88,1.52s-3-2.5-2-4.6,2.09-2.48,3.51-1.82c1.17.55,1.63,1.37,1.11,2.46l-1.32,2.9c1.47.53,2,.11,2.61-1.14C59.12,152.9,59.46,152,59.72,151.22Zm-4.5,3.08,1.22-2.59c.25-.54-.17-.85-.57-1-.79-.35-1.27-.13-1.78,1S53.67,153.48,55.22,154.3Z" />
          </g>
          <g className="circle-dark circle-active gem-wheel-target">
            <path
              id="gem-wheel-promotion"
              d="M117.9,32.58a239.52,239.52,0,0,0-85.73,86L98.84,157a161.54,161.54,0,0,1,57.85-57.89Z"
              className={
                props.selectedId === "gem-wheel-promotion"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M75.94,114c2,1.5,2.09,2.57.71,4.43a16.94,16.94,0,0,1-1.43,1.72l3.2,2.36-.87,1.18-8.14-6a30.59,30.59,0,0,1,2-2.91C72.88,112.72,73.93,112.48,75.94,114Zm-.88,1.19c-1.24-.91-1.83-.78-2.67.35-.46.63-.87,1.18-1.19,1.64l3.12,2.3,1.2-1.63C76.34,116.67,76.3,116.07,75.06,115.15Z" />
            <path d="M79.7,108.77l1,1-.28.34a6.42,6.42,0,0,0-1,2.52l4.06,3.24L82.55,117,77,112.52l.69-.86,1,.57a5.13,5.13,0,0,1,.86-3.16Z" />
            <path d="M87.59,105.3c2.08,1.77,2.26,3.14.64,5s-3,1.93-5.08.15-2.27-3.16-.65-5S85.51,103.54,87.59,105.3Zm-3.47,4.07c1.5,1.28,2.24,1.32,3.2.19s.82-1.85-.69-3.14-2.26-1.33-3.22-.2-.82,1.86.71,3.17Z" />
            <path d="M94.83,94.94l3.89,3.69-1,1-3.5-3.4c-.62-.56-1.06-.61-1.58-.06s-.58,1-.76,2.33l3.69,3.5-.94,1L91,99.59C90.4,99,90,99,89.46,99.53s-.58,1-.77,2.32l3.7,3.51-1,1-5.17-4.92.8-.85.84.7a4.46,4.46,0,0,1,1-3,1.7,1.7,0,0,1,2.16-.46,4.28,4.28,0,0,1,1-2.9,1.75,1.75,0,0,1,2.46-.29A2.13,2.13,0,0,1,94.83,94.94Z" />
            <path d="M102.41,90.23c1.88,2,1.91,3.36.1,5.08s-3.19,1.6-5.06-.38-1.94-3.38-.12-5.09S100.52,88.24,102.41,90.23Zm-3.89,3.69c1.36,1.43,2.1,1.55,3.17.53s1-1.76-.36-3.2-2.1-1.56-3.18-.54S97.14,92.46,98.52,93.92Z" />
            <path d="M103.61,85.84l2.64,3c.45.51.79.52,1.31.06l.78-.69.85.72a8.54,8.54,0,0,1-1.14,1.26,1.74,1.74,0,0,1-2.73-.27l-2.77-3.13-1,.87-.68-.77.92-.92L100.4,84.4l1.06-.94L102.85,85l1.65-1.46.73.82Z" />
            <path d="M105.14,79.58l.53.63a.37.37,0,0,1,.07.52.23.23,0,0,1-.07.07l-.52.43a.36.36,0,0,1-.51,0,.22.22,0,0,1-.07-.09l-.53-.62A.37.37,0,0,1,104,80L104,80l.52-.44a.37.37,0,0,1,.52,0Zm6.29,7.52-1.08.91-4.62-5.48,1.08-.9Z" />
            <path d="M115.9,78.91c1.7,2.13,1.61,3.51-.34,5.06s-3.31,1.32-5-.81-1.63-3.54.32-5.09S114.22,76.76,115.9,78.91Zm-4.19,3.33c1.23,1.55,2,1.72,3.11.8s1.15-1.66-.08-3.21-2-1.74-3.12-.82S110.46,80.66,111.71,82.24Z" />
            <path d="M121.48,72.57l3.16,4.34-1.15.83-2.91-4c-.54-.74-.89-.82-1.44-.43a5.52,5.52,0,0,0-1.51,2.43l3,4.09-1.15.84-4.26-5.79,1-.7.71.82a5.47,5.47,0,0,1,1.81-2.89,1.74,1.74,0,0,1,2.47.09A1.59,1.59,0,0,1,121.48,72.57Z" />
          </g>
          <g className="circle-dark circle-active gem-wheel-target">
            <path
              id="gem-wheel-word-of-mouth"
              d="M236.77,0A237.08,237.08,0,0,0,120.39,31.14l38.8,66.49a160.09,160.09,0,0,1,77.58-20.48Z"
              className={
                props.selectedId === "gem-wheel-word-of-mouth"
                  ? "selected-segment"
                  : ""
              }
              focusable
              role="button"
              onClick={handleSectionClick}
            ></path>
            <path d="M177.08,43.3a.56.56,0,0,1-.4.58l-1.55.52a.56.56,0,0,1-.68-.23l-3.77-6.58-.56-1-.29.1c0,.39.13.78.16,1.16l1,7.51a.56.56,0,0,1-.4.6l-1.56.52a.53.53,0,0,1-.66-.22l-4.94-8.57,1.48-.5L168.75,44c.2.35.37.74.58,1.17l.32-.11c-.09-.47-.16-.88-.2-1.3l-.94-7.31a.58.58,0,0,1,.37-.64l1.49-.5a.58.58,0,0,1,.68.28L174.73,42c.22.37.43.76.62,1.13l.32-.1c-.06-.42-.17-.86-.22-1.26L174.34,34l1.48-.5Z" />
            <path d="M185.38,37.67c.74,2.62.13,3.86-2.27,4.53s-3.57-.06-4.3-2.69-.14-3.89,2.26-4.56S184.64,35,185.38,37.67Zm-5.16,1.44c.54,1.91,1.13,2.35,2.56,2s1.7-1.09,1.17-3-1.14-2.36-2.56-2S179.69,37.18,180.22,39.11Z" />
            <path d="M190.73,32.47l.16,1.34-.43.1a6.36,6.36,0,0,0-2.3,1.41l1.23,5-1.39.34-1.69-7,1.07-.26.4,1a5.22,5.22,0,0,1,2.62-2Z" />
            <path d="M197.51,28.07l2.08,10-1.09.23-.28-.9a4.33,4.33,0,0,1-2.52,1.7c-1.76.37-2.72-.78-3.22-3.19-.57-2.76.14-3.85,1.92-4.21a4.87,4.87,0,0,1,2.54.13c-.14-.45-.24-.88-.35-1.37l-.43-2.11Zm-1.62,9.73a3.51,3.51,0,0,0,1.91-1.31L197,32.76a8.06,8.06,0,0,0-2.08.07c-1.15.24-1.49.77-1.07,2.77S194.83,38,195.89,37.8Z" />
            <path d="M205.42,33.88l-4.4.75-.18-1.07,4.39-.75Z" />
            <path d="M168.74,63.16c1.06,2.52.61,3.82-1.69,4.79s-3.54.38-4.6-2.13S161.83,62,164.12,61,167.68,60.64,168.74,63.16Zm-4.93,2.08c.77,1.83,1.42,2.19,2.78,1.62s1.56-1.3.79-3.12-1.42-2.2-2.79-1.63S163,63.39,163.81,65.24Z" />
            <path d="M170.69,57.43l.38,1,2.07-.79.39,1-2.07.78,2.14,5.66-1.33.51-2.14-5.66-1.2.45-.37-1,1.18-.52-.44-1.17a1.85,1.85,0,0,1,.68-2.51,2,2,0,0,1,.56-.21,19.4,19.4,0,0,1,2-.64l.27,1-1.36.52C170.58,56.3,170.4,56.68,170.69,57.43Z" />
            <path d="M179.54,59.66l-4.22,1.45-.35-1,4.21-1.45Z" />
            <path d="M191.14,53.88,192.63,59l-1.34.39-1.37-4.77c-.25-.8-.6-1.06-1.33-.85s-1,.6-1.84,1.63l1.41,4.89-1.31.38-1.37-4.77c-.24-.84-.65-1-1.33-.85s-1,.59-1.84,1.62l1.42,4.9-1.36.39-2-6.88,1.13-.32.37,1a4.41,4.41,0,0,1,2.41-2,1.69,1.69,0,0,1,2.09.69,4.27,4.27,0,0,1,2.36-2,1.75,1.75,0,0,1,2.29.94A1.88,1.88,0,0,1,191.14,53.88Z" />
            <path d="M200.08,53.71c.6,2.66-.07,3.86-2.51,4.42s-3.56-.25-4.16-2.91.06-3.9,2.49-4.45S199.47,51,200.08,53.71Zm-5.23,1.18c.44,1.93,1,2.41,2.46,2.08s1.76-1,1.32-2.94-1-2.41-2.46-2.08-1.76,1-1.32,2.94Z" />
            <path d="M207.31,48.7l1.28,7-1.11.2L207.2,55a5.12,5.12,0,0,1-2.79,1.7,1.74,1.74,0,0,1-2.19-1.15,1.63,1.63,0,0,1-.08-.45l-1-5.29,1.4-.25.88,4.88c.15.87.47,1.11,1.22,1A4.54,4.54,0,0,0,206.81,54l-.93-5.09Z" />
            <path d="M211.72,49.16l.57,4c.09.67.37.87,1.05.77l1-.15.31,1.06a7.21,7.21,0,0,1-1.64.43A1.72,1.72,0,0,1,210.93,54a1.61,1.61,0,0,1-.05-.44l-.6-4.14L209,49.6l-.14-1,1.37-.3-.3-2.08,1.39-.2.3,2.08,2.19-.31.16,1.08Z" />
            <path d="M221.87,48.67l.56,5.33-1.41.15-.52-4.93c-.09-.9-.36-1.16-1-1.09A5.2,5.2,0,0,0,217,49.46l.53,5.06-1.42.15L215,44.46l1.42-.15.3,2.76a8.07,8.07,0,0,1,0,1.39,5.49,5.49,0,0,1,3-1.56,1.75,1.75,0,0,1,2.08,1.37A1.36,1.36,0,0,1,221.87,48.67Z" />
          </g>
          <g className="circle-dark">
            <path d="M236.77,156.28V80A157.89,157.89,0,0,0,79.9,237.17h76.24A81.66,81.66,0,0,1,236.77,156.28Z" />
            <g className="circle-icon">
              <path d="M170.59,155.91l-6-5V138.3a1.47,1.47,0,0,0-1.47-1.47H158a1.47,1.47,0,0,0-1.47,1.47v5.88l-8.73-7.27c-.41-.34-.94-.79-1.41-.64-.47-.15-1,.3-1.41.64l-22.83,19a1.49,1.49,0,0,0-.19,2.08l1,1.26a1.48,1.48,0,0,0,2.08.19l21.3-17.75,21.31,17.75a1.48,1.48,0,0,0,2.08-.19l1-1.26A1.48,1.48,0,0,0,170.59,155.91Z" />
              <path d="M146.37,144.78l-18,14.64V175.3a1.47,1.47,0,0,0,1.47,1.47h12.6V164.69h7.81v12.08h12.6a1.47,1.47,0,0,0,1.47-1.47V159.42h.05Z" />
            </g>
            <path d="M121.91,153.46h6.36a4.43,4.43,0,0,1,3,1.06c.8.71,1.2,2.06,1.2,4.06s-.41,3.39-1.24,4.14a4.3,4.3,0,0,1-3,1.11c-.44,0-1.11,0-2-.08l-2.2-.17v5.55h-2.15Zm6,8.46c1.58,0,2.38-1.11,2.38-3.32s-.75-3.23-2.25-3.23h-4v6.55Z" />
            <path d="M135.46,168.3a4.19,4.19,0,0,1-.77-2.83V152.9h2.12v12.29a3,3,0,0,0,.36,1.73,1.57,1.57,0,0,0,1.11.57l1.06.13v1.53h-1.48A3.13,3.13,0,0,1,135.46,168.3Z" />
            <path d="M141.15,168.49a2.73,2.73,0,0,1-.81-2.08v-.9a2.78,2.78,0,0,1,.93-2.18,4.06,4.06,0,0,1,2.71-.82h3.55V161.3a2.46,2.46,0,0,0-.23-1.13,1.6,1.6,0,0,0-.84-.67,5.12,5.12,0,0,0-1.74-.24h-3.5V158a19.88,19.88,0,0,1,4.21-.45,5,5,0,0,1,3.2.84,3.4,3.4,0,0,1,1,2.75v8H148l-.36-1.28a3.87,3.87,0,0,1-.91.51A9.74,9.74,0,0,1,145,169a6.12,6.12,0,0,1-1.8.3A2.87,2.87,0,0,1,141.15,168.49Zm4.34-1c.48-.12.93-.25,1.35-.4a4.12,4.12,0,0,0,.69-.27v-3.27l-3,.13c-1.37.08-2.06.69-2.06,1.82V166a1.52,1.52,0,0,0,.51,1.27,2,2,0,0,0,1.26.39,5.28,5.28,0,0,0,1.25-.15Z" />
            <path d="M153.28,168c-.8-.85-1.2-2.35-1.2-4.51a9.17,9.17,0,0,1,.55-3.55,3.44,3.44,0,0,1,1.57-1.79,5.78,5.78,0,0,1,2.56-.51,17.94,17.94,0,0,1,3.8.37v1.25h-3.21a4,4,0,0,0-1.78.35,2.24,2.24,0,0,0-1,1.28,7.78,7.78,0,0,0-.34,2.62,7.09,7.09,0,0,0,.35,2.55,2.17,2.17,0,0,0,1,1.24,3.86,3.86,0,0,0,1.77.34h3.36v1.21a13.3,13.3,0,0,1-1.89.31,17.11,17.11,0,0,1-2.15.11A4.42,4.42,0,0,1,153.28,168Z" />
            <path d="M164.36,168.72a3.06,3.06,0,0,1-1.51-1.78,13.85,13.85,0,0,1,0-7.06,3,3,0,0,1,1.5-1.77,6.8,6.8,0,0,1,2.89-.51,8.26,8.26,0,0,1,2.57.34,2.51,2.51,0,0,1,1.41,1.17,4.92,4.92,0,0,1,.44,2.3,2.57,2.57,0,0,1-.87,2.18,4.05,4.05,0,0,1-2.51.67h-3.76a6.13,6.13,0,0,0,.29,2,1.76,1.76,0,0,0,1,1,5.06,5.06,0,0,0,2,.32h3.55v1.27c-.78.12-1.46.21-2.06.27s-1.27.09-2,.09A6.45,6.45,0,0,1,164.36,168.72Zm3.73-5.8a2,2,0,0,0,1.27-.33,1.45,1.45,0,0,0,.39-1.16,2.26,2.26,0,0,0-.56-1.76,3.08,3.08,0,0,0-2-.49,3.56,3.56,0,0,0-1.67.32,1.86,1.86,0,0,0-.83,1.11,8.6,8.6,0,0,0-.24,2.31Z" />
          </g>
        </g>

        <g className="circle-centre js-circle-centre gem-wheel-target">
          <g
            id="gem-wheel-elearning-home"
            className="circle-heart circle-active"
            focusable
            role="button"
            onClick={handleSectionClick}
          >
            <path
              d="M238.22,293.81l35.32-31.51h0a44.73,44.73,0,0,0,13.21-18.55c7.58-19.57,1.13-40.32-14.41-46.34-11.57-4.48-24.91.47-34.1,11.35-9.19-10.88-22.53-15.83-34.09-11.35-15.54,6-22,26.77-14.41,46.34A44.73,44.73,0,0,0,203,262.3Z"
              className={
                props.selectedId === "gem-wheel-elearning-home"
                  ? "selected-segment"
                  : ""
              }
            />
            <path d="M219,226.43c0,3.12-1.38,4-4.88,4a51.75,51.75,0,0,1-5.72-.18V215a50.22,50.22,0,0,1,5.06-.17c3.74,0,5.08.83,5.08,3.95,0,2.18-.75,3.3-2.57,3.48v.08C218.27,222.58,219,224,219,226.43Zm-8.44-9.7v4.78h3.08c2.06,0,2.79-.49,2.79-2.42s-.79-2.36-3.08-2.36Zm6.22,9.33c0-2.25-.77-2.84-3.12-2.86h-3.1v5.3h3.23c2.27,0,3-.44,3-2.44Z" />
            <path d="M228.48,219.2l-.24,2h-.66a9.52,9.52,0,0,0-3.85,1.23v7.78H221.6V219.48h1.62l.22,1.65a7.63,7.63,0,0,1,4.5-1.93Z" />
            <path d="M239.06,222.74v7.47h-1.67l-.24-1.69a6.18,6.18,0,0,1-4.29,1.89c-2,0-3.28-1.08-3.28-3V226.3c0-1.71,1.1-2.68,3-2.68h4.31v-.83c0-1.32-.49-1.74-2.14-1.74-1.05,0-2.88.13-4.55.26l-.2-1.56a36.6,36.6,0,0,1,5.22-.5C237.74,219.2,239.06,220,239.06,222.74Zm-5.76,5.87a5.71,5.71,0,0,0,3.63-1.52v-1.91H233c-1,0-1.27.55-1.27,1.36v.57C231.74,228.17,232.22,228.61,233.3,228.61Z" />
            <path d="M251.53,222.17v8H249.4v-7.43c0-1.36-.35-1.78-1.37-1.78-1.2,0-2.33.62-4,1.61v7.6h-2.14V219.48h1.81l.13,1.63a8.15,8.15,0,0,1,4.75-1.91,2.63,2.63,0,0,1,2.95,2.27A2.73,2.73,0,0,1,251.53,222.17Z" />
            <path d="M263.78,214.82v15.39h-1.67l-.17-1.47a6.65,6.65,0,0,1-4.23,1.74c-2.7,0-3.76-2-3.76-5.68,0-4.22,1.37-5.6,4.09-5.6a7.49,7.49,0,0,1,3.7,1c-.06-.7-.09-1.4-.09-2.11v-3.23Zm-5.34,13.81c1.14,0,1.82-.4,3.21-1.34v-5.72a11.91,11.91,0,0,0-3.08-.53c-1.76,0-2.42.69-2.42,3.74S256.81,228.63,258.44,228.63Z" />
            <path d="M210.32,243.1c0,3.78-1.23,4.81-4.7,4.81a28.81,28.81,0,0,1-3.35-.19v6h-2.2V238.5a49,49,0,0,1,5.28-.19C209.09,238.3,210.32,239.36,210.32,243.1Zm-2.22,0c0-2.31-.68-2.9-2.79-2.9h-3V246h3C207.42,246,208.1,245.41,208.1,243.1Z" />
            <path d="M219.45,242.66l-.24,2h-.66a9.52,9.52,0,0,0-3.85,1.23v7.79h-2.13V243h1.65l.22,1.64a7.63,7.63,0,0,1,4.5-1.93Z" />
            <path d="M230.65,248.3c0,4.09-1.39,5.63-5.13,5.63s-5.12-1.54-5.12-5.63,1.38-5.67,5.12-5.67S230.65,244.22,230.65,248.3Zm-8,0c0,3,.68,3.85,2.9,3.85s2.9-.88,2.9-3.85-.68-3.87-2.9-3.87-2.93.87-2.93,3.87Z" />
            <path d="M249.12,245.63v8.05H247v-7.44c0-1.25-.42-1.78-1.56-1.78s-1.74.44-3.32,1.59v7.63H240.1v-7.44c0-1.31-.5-1.78-1.56-1.78s-1.71.42-3.32,1.56v7.66h-2.11V243h1.76l.11,1.62a6.68,6.68,0,0,1,4.31-1.91,2.53,2.53,0,0,1,2.73,1.87,6.46,6.46,0,0,1,4.22-1.87C248.11,242.66,249.12,243.69,249.12,245.63Z" />
            <path d="M254.29,239v1.23c0,.42-.19.64-.61.64h-1a.55.55,0,0,1-.62-.49.38.38,0,0,1,0-.15V239a.57.57,0,0,1,.49-.62h1.13a.55.55,0,0,1,.61.48A.33.33,0,0,1,254.29,239Zm-.07,14.69h-2.1V243h2.1Z" />
            <path d="M265.51,243l-.18,1.54c-1.34,0-2.72-.11-4.06-.11-1.92,0-2.51,0-2.51,1.19s.5,1.12,1.52,1.41l3,.75c1.74.46,2.51,1.14,2.51,3,0,2.64-1.45,3.1-4.66,3.1a27.7,27.7,0,0,1-4.27-.31L257,252c1,.08,2.29.11,3.68.11,2.35,0,3-.22,3-1.32s-.61-1.1-1.63-1.37l-3-.74c-1.78-.47-2.44-1.3-2.44-3.06,0-2.44,1.26-3,4.25-3A35.85,35.85,0,0,1,265.51,243Z" />
            <path d="M276.86,251.9l.2,1.4a12.53,12.53,0,0,1-4.25.64c-3.73,0-5.16-1.52-5.16-5.65s1.49-5.63,5-5.63,4.7,1.25,4.7,3.61c0,1.93-.81,3.07-2.62,3.07h-4.81c.22,2.33,1.14,2.82,3.21,2.82C274.22,252.16,275.69,252,276.86,251.9Zm-7-4.2h4.28c.9,0,1.06-.77,1.06-1.43,0-1.3-.64-1.81-2.47-1.81-2.09,0-2.8.68-2.88,3.24Z" />
            <circle cx="237.8" cy="237.93" r="81.68" fill="transparent" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default GemWheel;
