import { useTheme, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  author: {
    fontWeight: 300,
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    margin: "0 10px",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  meta: {
    display: "inline",
    fontSize: "75%",
    listStyle: "square",
    color: theme.palette.grey[600],
    margin: "0 10px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      fontSize: "65%",
    },
  },
}));

export const EventHeaderStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
