export interface IFilterSetConfiguration {
  filterSets: IFilterSet[];
  filterAvailability: IFilterAvailability[];
  lastUpdated: Date | undefined;
}

export interface IFilterSet {
  name: string;
  isSystemDefault: boolean;
  isUserDefault: boolean;
  filters: IHubFilter[];
}

export interface ISerializedFilterSet {
  name: string;
  isSystemDefault: boolean;
  isUserDefault: boolean;
  filters: string;
}

export interface IKeyValuePair {
  key: number;
  value: string;
}

export enum HubFilterType {
  VisitType = "VisitType",
  VisitSource = "VisitSource",
  VisitId = "VisitId",
  VisitDate = "VisitDate",
  Locations = "Locations",
  AreasAndRegions = "AreasAndRegions",
  Segments = "Segments",
  LocationRank = "LocationRank",
  QuestionnaireType = "QuestionnaireType",
  Sections = "Sections",
  Questions = "Questions",
  QuestionCategory = "QuestionCategory",
  Benchmarks = "Benchmarks",
  QuestionScoreRange = "QuestionScoreRange",
  VisitScoreRange = "VisitScoreRange",
  NPS = "NPS",
  DayOfWeek = "DayOfWeek",
  DayPart = "DayPart",
  VisitAnalysisDepartment = "VisitAnalysisDepartment",
  VisitAnalysisRoom = "VisitAnalysisRoom",
  TaskCentreStatus = "TaskCentreStatus",
  AppealStatus = "AppealStatus",
  TaskCentreCategory = "TaskCentreCategory",
  TaskCentrePriority = "TaskCentrePriority",
  FeedbackCategory = "FeedbackCategory",
  FeedbackSubCategory = "FeedbackSubCategory",
  TaskAssignee = "TaskAssignee",
  VisitContactStatus = "VisitContactStatus",
  LengthOfService = "LengthOfService",
  AgeFilters = "AgeFilters",
  DishFilters = "DishFilters",
  BrandFilter = "BrandFilter",
  SegmentFilter = "SegmentFilter",
}

export interface ITableColumnMapping {
  table: string;
  column: string;
}

export interface IHubFilter {
  type: HubFilterType;
  alwaysVisible: boolean;
  alwaysVisibleInReports?: string[];
  userSetVisibility?: FilterVisibilityBehaviour;
}

export interface IHubTextFilter extends IHubFilter {
  value: string[];
}

export interface IHubKeyValueFilter extends IHubFilter {
  value: IKeyValuePair[];
}

export interface IHubNumericRangeFilter extends IHubFilter {
  minValue: number;
  maxValue: number;
}

export type DishFilterType = IHubKeyValueFilter;

export type ILocationFilter = IHubKeyValueFilter;

export interface IVisitDateFilter extends IHubFilter {
  dateSelectionType: VisitDateSelectionType;
  selectedPeriods: string[];
  selectedPeriodHierarchies: number[];
  expandedPeriodHierarchyNodes: string[] | null;
  customDateRange: IVIsitDateRange | null;
}

export type IAreaAndRegionFilter = IHubTextFilter;

export type ILocationSegmentFilter = IHubTextFilter;

export interface ILocationRankFilter extends IHubFilter {
  selectedLocation: IKeyValuePair | undefined;
}

export type IVisitTypeFilter = IHubTextFilter;

export type IQuestionnaireTypeFilter = IHubTextFilter;

export interface IQuestionsFilter extends IHubFilter {
  selectedQuestions: string[];
  selectedQuestionSubjects: string[];
}

export type IQuestionSectionFilter = IHubTextFilter;

export type IQuestionCategoryFilter = IHubTextFilter;

export type IBenchmarksFilter = IHubTextFilter;

export type IAnalysisTagsFilter = IHubTextFilter;

export type INetPromoterScoreFilter = IHubTextFilter;

export type IQuestionScoreRangeFilter = IHubNumericRangeFilter;

export type IVisitScoreRangeFilter = IHubNumericRangeFilter;

export type IDayOfWeekFilter = IHubTextFilter;

export type IDayPartFilter = IHubTextFilter;

export type IVisitAnalysisDepartmentFilter = IHubTextFilter;

export type IVisitAnalysisRoomFilter = IHubTextFilter;

export type ITaskCentreStatusFilter = IHubKeyValueFilter;

export type ITaskCentreCategoryFilter = IHubKeyValueFilter;

export type IAppealStatusFilter = IHubKeyValueFilter;

export type ITaskCentrePriorityFilter = IHubKeyValueFilter;

export type IVisitSourceFilter = IHubKeyValueFilter;

export type IFeedbackCategoryFilter = IHubTextFilter;

export type IFeedbackSubCategoryFilter = IHubTextFilter;

export interface IVisitIdFilter extends IHubFilter {
  value: number | undefined;
}

export type ITaskAssigneeFilter = IHubKeyValueFilter;

export type IVisitContactStatusFilter = IHubTextFilter;

export type ILengthOfServiceFilter = IHubTextFilter;

export class HubFilterConfigurationOptionGrouping {
  Scores: HubFilterType[] = [
    HubFilterType.VisitScoreRange,
    HubFilterType.QuestionScoreRange,
    HubFilterType.NPS,
  ];

  QuestionsAndAnswers: HubFilterType[] = [
    HubFilterType.QuestionnaireType,
    HubFilterType.Sections,
    HubFilterType.Questions,
    HubFilterType.QuestionCategory,
    HubFilterType.Benchmarks,
  ];

  Visit: HubFilterType[] = [
    HubFilterType.FeedbackCategory,
    HubFilterType.FeedbackSubCategory,
    HubFilterType.DishFilters,
  ];

  InboxTasks: HubFilterType[] = [
    HubFilterType.TaskCentreCategory,
    HubFilterType.TaskCentrePriority,
  ];

  Locations: HubFilterType[] = [
    HubFilterType.BrandFilter,
    HubFilterType.SegmentFilter,
  ];

  Time: HubFilterType[] = [HubFilterType.DayPart, HubFilterType.DayOfWeek];

  Other: HubFilterType[] = [
    HubFilterType.VisitAnalysisDepartment,
    HubFilterType.VisitAnalysisRoom,
    HubFilterType.LengthOfService,
  ];
}

export interface IVIsitDateRange {
  dateStart: Date;
  dateEnd: Date;
}

export enum VisitDateSelectionType {
  Custom = 0,
  LastWeek = 1,
  LastTwoWeeks = 2,
  LastFourWeeks = 3,
  LastTwelveWeeks = 4,

  // Legacy Client Periods
  ClientPeriods = 5,
  CurrentPeriod = 6,
  LastCompletePeriod = 7,
  LastTwoCompletePeriods = 8,
  LastThreeCompletePeriods = 9,
  CurrentYearToDate = 10,
  PreviousYear = 11,

  // Period Hierarchies
  reportingPeriods = 12,
}

export enum FilterVisibilityBehaviour {
  Hidden = 0,
  Visible = 1,
}

export interface IFilterAvailability {
  filterType: HubFilterType;
  isAvailable: boolean;
  excludePageRefs: string[];
}

export interface IFilterAvailabilityApiResponse {
  filterAvailability: IFilterAvailability[];
}

export interface IFilterSetApiResponse {
  filterSets: ISerializedFilterSet[];
}

export enum DaySelectionType {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum ContactStatusType {
  ShowAll = "ShowAll",
  WithContact = "WithContact",
  AwaitingContact = "AwaitingContact",
  ContactMade = "ContactMade",
  GuestReplied = "GuestReplied",
  WithNoContact = "WithNoContact",
  ReviewUnresponded = "ReviewUnresponded",
  ReviewResponded = "ReviewResponded",
}

export enum AnalysisTagKeys {
  FeedbackCategory = "Feedback Category",
  FeedbackSubCategory = "Sub Category",
  VisitAnalysisDepartment = "Department",
  VisitAnalysisRoom = "Room",
  LengthOfService = "Length of Service",
}
