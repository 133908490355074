import {
  VisitReportActions,
  VISIT_REPORT_CLEAR_ERROR,
  VISIT_REPORT_ERROR,
  VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES_SUCCESS,
  VISIT_REPORT_FETCH_VISIT_HTML,
  VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT,
  VISIT_REPORT_PENDING,
  VISIT_REPORT_SUCCESS,
  VISIT_REPORT_VISIT_CONTENT_SUCCESS,
} from "../actions/VisitReport-Actions";
import {
  IReviewResponseTemplate,
  IVisitReportContent,
  VisitReportContentType,
} from "../types/VisitReport";

export interface IVisitReportState {
  reportContent: IVisitReportContent | undefined;
  responseTemplates: IReviewResponseTemplate[];
  pending: boolean;
  error: string;
}

const INITIAL_STATE: IVisitReportState = {
  reportContent: undefined,
  responseTemplates: [],
  pending: false,
  error: "",
};

export default function VisitReportReducer(
  state: IVisitReportState = INITIAL_STATE,
  action: VisitReportActions
): IVisitReportState {
  switch (action.type) {
    case VISIT_REPORT_PENDING:
      return {
        ...state,
        pending: true,
        error: "",
      };
    case VISIT_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case VISIT_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
      };
    case VISIT_REPORT_FETCH_VISIT_HTML:
      return {
        ...state,
        reportContent: {
          contentType: VisitReportContentType.unknown,
          visitId: action.visitId,
          htmlContent: undefined,
          reviewContent: undefined,
        },
        pending: true,
        error: "",
      };
    case VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT:
      return {
        ...state,
        reportContent: {
          contentType: VisitReportContentType.unknown,
          visitId: action.visitId,
          htmlContent: undefined,
          reviewContent: undefined,
        },
        pending: true,
        error: "",
      };
    case VISIT_REPORT_VISIT_CONTENT_SUCCESS:
      return {
        ...state,
        reportContent: {
          contentType: action.contentType,
          htmlContent: action.html,
          reviewContent: action.reviewContent,
          visitId: action.visitId,
        },
        pending: false,
        error: "",
      };
    case VISIT_REPORT_CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };
    case VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES_SUCCESS:
      return {
        ...state,
        responseTemplates: action.templates,
      };
    default:
      return state;
  }
}
