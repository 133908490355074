import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  titleField: {
    width: "100%",
  },
  inputSize: {
    fontSize: "1.4em",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "0.9em",
    },
  },
}));

export const TitleFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
