import { getVisitDetailDateTime } from "../../utils/visitDetailUtils";
import {
  CLEAR_VISITDETAILS_LIST,
  ISetVisitListItemContactStatus,
  IUpdateVisitDetailsList,
  MERGE_VISIT_TASKS_COUNT,
  MERGE_VISIT_MEDIA,
  MERGE_VISIT_RECEIPTS,
  SET_SELECTED_VISITDETAILS_VISIT,
  UPDATE_VISITDETAILS_LIST,
  VisitDetailsActions,
  VISITDETAILS_LIST_ERROR,
  VISITDETAILS_LIST_PENDING,
  VISITDETAILS_LIST_SET_CONTACT_STATUS,
  VISITDETAILS_LIST_SUCCESS,
  FIND_AND_REPLACE_VISIT,
  IVisitSavedUpdateVisitDetailsList,
  VISIT_SAVED_UPDATE_VISITDETAILS_LIST,
  SET_VISITDETAILS_VISIT_COUNT,
} from "../actions/VisitDetails-Actions";
import {
  IVisitTaskCount,
  IVisitDetail,
  IVisitDetailsVisits,
  IVisitMedia,
  VoucherDetailsInterface,
} from "../types/VisitDetails";
import { IGetVisitDetailsQuery } from "../types/VisitDetailsQuery";

export interface IVisitDetailsState {
  visitDetails: IVisitDetailsVisits;
  visitTaskCounts: IVisitTaskCount[];
  visitMediaEntries: IVisitMedia[];
  visitReceiptEntries: IVisitMedia[];
  pending: boolean;
  error: string;
  query: IGetVisitDetailsQuery | undefined;
  totalCount: number | undefined;
}

const INITIAL_STATE: IVisitDetailsState = {
  visitDetails: {
    visits: [],
    lastRefreshed: undefined,
    moreVisitsAvailable: undefined,
    selectedVisitId: undefined,
    visitListItemCount: 0,
    visitListPageCount: 0,
  },
  visitTaskCounts: [],
  visitMediaEntries: [],
  visitReceiptEntries: [],
  pending: false,
  error: "",
  query: undefined,
  totalCount: undefined,
};

export default function TaskCentreCaseListReducer(
  state: IVisitDetailsState = INITIAL_STATE,
  action: VisitDetailsActions
): IVisitDetailsState {
  const batchValue = process.env.REACT_APP_VISIT_DETAILS_LIST_BATCH_SIZE;
  const batchSize = Number.parseInt(batchValue ? batchValue : "100");

  switch (action.type) {
    case VISITDETAILS_LIST_PENDING:
      return {
        ...state,
        pending: true,
        error: "",
        query: action.query,
      };
    case VISITDETAILS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
        visitDetails: action.visits,
      };
    case VISITDETAILS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CLEAR_VISITDETAILS_LIST:
      return {
        ...state,
        visitDetails: {
          visits: [],
          selectedVisitId: undefined,
          lastRefreshed: new Date(),
          visitListPageCount: 0,
          visitListItemCount: 0,
          moreVisitsAvailable: undefined,
        },
        pending: true,
        error: "",
      };
    case UPDATE_VISITDETAILS_LIST:
      return {
        ...state,
        visitDetails: updateVisitDetailsList(action, state, batchSize),
        error: "",
        pending: false,
      };
    case VISIT_SAVED_UPDATE_VISITDETAILS_LIST:
      return {
        ...state,
        visitDetails: visitSavedUpdateVisitDetailsList(action, state),
        error: "",
        pending: false,
      };
    case SET_SELECTED_VISITDETAILS_VISIT:
      return {
        ...state,
        visitDetails: setSelectedVisit(state.visitDetails, action.visitId),
        error: "",
      };
    case MERGE_VISIT_TASKS_COUNT:
      return {
        ...state,
        visitTaskCounts: mergeVisitTasksCount(
          state.visitTaskCounts,
          action.actionCounts
        ),
        error: "",
      };
    case MERGE_VISIT_MEDIA:
      return {
        ...state,
        visitMediaEntries: mergeVisitMediaItems(
          state.visitMediaEntries,
          action.visitMedia.visitId,
          action.visitMedia
        ),
        error: "",
      };
    case MERGE_VISIT_RECEIPTS:
      return {
        ...state,
        visitReceiptEntries: mergeVisitReceiptItems(
          state.visitReceiptEntries,
          action.visitReceipts.visitId,
          action.visitReceipts
        ),
        error: "",
      };
    case VISITDETAILS_LIST_SET_CONTACT_STATUS:
      return {
        ...state,
        visitDetails: updateVisitContactStatus(state, action),
      };
    case FIND_AND_REPLACE_VISIT: {
      return {
        ...state,
        visitDetails: {
          ...state.visitDetails,
          visits: findAndReplaceVisit(
            action.visit,
            action.voucherDetails,
            state
          ),
        },
      };
    }
    case SET_VISITDETAILS_VISIT_COUNT: {
      return {
        ...state,
        totalCount: action.count,
      };
    }
    default:
      return state;
  }
}

const setSelectedVisit = (
  visitDetailVisits: IVisitDetailsVisits,
  visitId: number
): IVisitDetailsVisits => {
  visitDetailVisits.selectedVisitId = visitId;
  return visitDetailVisits;
};

const updateVisitContactStatus = (
  state: IVisitDetailsState,
  action: ISetVisitListItemContactStatus
): IVisitDetailsVisits => {
  const visitDetails = state.visitDetails;
  const contactedVisits = [...state.visitDetails.visits];
  const contactedVisit = contactedVisits.find((x) => x.id === action.visitId);

  if (contactedVisit) {
    contactedVisit.contactMade = action.contacted;
  }

  visitDetails.visits = contactedVisits;
  return visitDetails;
};

const mergeVisitTasksCount = (
  actionCounts: IVisitTaskCount[],
  newTaskCounts: IVisitTaskCount[]
): IVisitTaskCount[] => {
  const mergedTaskCounts: IVisitTaskCount[] = actionCounts.map((x) => {
    const newTaskCount = newTaskCounts.find((y) => y.visitId === x.visitId);

    return { ...x, count: newTaskCount?.count ?? x.count };
  });

  newTaskCounts
    .filter(
      (x) => mergedTaskCounts.findIndex((y) => y.visitId === x.visitId) === -1
    )
    .forEach((x) => mergedTaskCounts.push(x));

  return mergedTaskCounts;
};

const mergeVisitMediaItems = (
  visitMedia: IVisitMedia[],
  visitId: number,
  newMedia: IVisitMedia
): IVisitMedia[] => {
  const existingReceiptEntry = visitMedia.find((x) => x.visitId === visitId);
  if (existingReceiptEntry) {
    existingReceiptEntry.fileNames = newMedia.fileNames;
  } else {
    visitMedia.push(newMedia);
  }

  return visitMedia;
};

const mergeVisitReceiptItems = (
  visitReceipts: IVisitMedia[],
  visitId: number,
  newReceipt: IVisitMedia
): IVisitMedia[] => {
  const existingReceiptEntry = visitReceipts.find((x) => x.visitId === visitId);
  if (existingReceiptEntry) {
    existingReceiptEntry.fileNames = newReceipt.fileNames;
  } else {
    visitReceipts.push(newReceipt);
  }

  return visitReceipts;
};

function updateVisitDetailsList(
  action: IUpdateVisitDetailsList,
  state: IVisitDetailsState,
  batchSize: number
) {
  const updateList = action.mergeWithExisting ? state.visitDetails.visits : [];

  action.visits.forEach((c) => {
    const existingVisitIndex = updateList.findIndex((x) => x.id === c.id);

    existingVisitIndex === -1
      ? updateList.push(c)
      : (updateList[existingVisitIndex] = c);
  });

  let selectedVisitId = state.visitDetails.selectedVisitId;

  if (!selectedVisitId && updateList.length > 0) {
    selectedVisitId = updateList[0].id;
  }

  const visitDetails: IVisitDetailsVisits = {
    visits: updateList,
    selectedVisitId: selectedVisitId,
    visitListItemCount: updateList.length,
    visitListPageCount: state.visitDetails.visitListPageCount++,
    lastRefreshed: new Date(),
    moreVisitsAvailable: action.visits.length === batchSize,
  };

  return visitDetails;
}

function visitSavedUpdateVisitDetailsList(
  action: IVisitSavedUpdateVisitDetailsList,
  state: IVisitDetailsState
) {
  const visit = action.visit;
  const visits = state.visitDetails.visits;

  const existingVisitIndex = visits.findIndex((x) => x.id === visit.id);

  if (existingVisitIndex === -1) {
    const visitDateTime = getVisitDetailDateTime(visit);

    const insertAtIndex = visits.findIndex((x) =>
      getVisitDetailDateTime(x).isBefore(visitDateTime)
    );

    if (insertAtIndex === -1) {
      visits.push(visit);
    } else {
      visits.splice(insertAtIndex, 0, visit);
    }
  } else {
    visits[existingVisitIndex] = visit;
  }

  const selectedVisitId = state.visitDetails.selectedVisitId ?? visit.id;

  const visitDetails: IVisitDetailsVisits = {
    ...state.visitDetails,
    visits: visits,
    selectedVisitId: selectedVisitId,
    visitListItemCount: visits.length,
  };

  return visitDetails;
}

function findAndReplaceVisit(
  visit: IVisitDetail | undefined,
  voucherDetails: VoucherDetailsInterface | undefined,
  state: IVisitDetailsState
) {
  const updatedVisit = {
    ...visit,
    hasVoucher: true,
    voucher: voucherDetails,
  } as IVisitDetail;
  const updatedVisitsList = state.visitDetails.visits.map((visit) =>
    visit.id === updatedVisit.id ? updatedVisit : visit
  );

  return updatedVisitsList;
}
