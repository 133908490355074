import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportViewerCurrentPage } from "../../../state/actions/ReportViewer-Actions";
import {
  GetBusyStatus,
  GetDefaultPage,
} from "../../reportPageTabs/state/selectors";
import { INavigationOption } from "../../../state/types/UserProfile";
import { ListItem, Slide, Typography } from "@material-ui/core";
import { NavigationOptionStyles } from "../styles/navigationOptionStyles";
import { Skeleton } from "@material-ui/lab";

type props = {
  navOption: INavigationOption;
  pages: string[];
  isMobile: boolean;
  setDrawerOpen?: (boolean: boolean) => void;
  setReportPageName: (pageName: string) => void;
};

const ReportPageNavigationOption = (props: props) => {
  const dispatch = useDispatch();
  const classes = NavigationOptionStyles();

  const reportIsBusy = useSelector(GetBusyStatus);
  const defaultPage = useSelector(GetDefaultPage);

  const changeReportPage = (pageName: string, event: any) => {
    event.stopPropagation();
    if (props.pages.indexOf(pageName) > -1) {
      dispatch(setReportViewerCurrentPage(pageName));
    }
    if (props.isMobile && props.setDrawerOpen) {
      props.setDrawerOpen(false);
    }

    setCurrentPage(pageName);
    props.setReportPageName(pageName);
  };
  const [currentPage, setCurrentPage] = useState<string>();

  useEffect(() => {
    const currentPage = props.pages[0];
    setCurrentPage(currentPage);
    props.setReportPageName(currentPage);
  }, [props.pages]);

  useEffect(() => {
    if (currentPage) {
      props.setReportPageName(currentPage);
    }
  }, [currentPage]);

  const loadingSpookyBoneMen = (numberOfSpookies: number) => {
    return Array.from({ length: numberOfSpookies }).map((_item, index) => (
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={25}
        animation="wave"
      />
    ));
  };
  return (
    <>
      {props.pages &&
        props.pages.length > 1 &&
        defaultPage &&
        !reportIsBusy && (
          <>
            {props.pages.map((page) => {
              return (
                <Slide
                  direction="down"
                  in={props.pages && props.pages.length > 1}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 600 }}
                >
                  <ListItem
                    onClick={(event) => changeReportPage(page, event)}
                    key={page}
                    className={`${classes.reportPageNavOption}`}
                    classes={{ selected: classes.selected }}
                    selected={page === currentPage}
                  >
                    <Typography variant="body2">{page}</Typography>
                  </ListItem>
                </Slide>
              );
            })}
          </>
        )}
      {props.pages.length < 1 &&
        reportIsBusy &&
        props.navOption.numberOfPages > 1 && (
          <>{loadingSpookyBoneMen(props.navOption.numberOfPages)}</>
        )}
    </>
  );
};

export default ReportPageNavigationOption;
