import { MsalAuthenticationResult } from "@azure/msal-react";
import { Grid, LinearProgress, makeStyles, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import i18n from "../../localizations/i18n";
import { routes } from "../../navigation/routes";
import { hgemColours, hgemFont } from "../../themes/defaultTheme";

const invalidIssuerErrorId = process.env.REACT_APP_B2C_INVALID_ISSUER_ERROR_ID;
const passwordResetErrorId = process.env.REACT_APP_B2C_PASSWORD_RESET_ERROR_ID;

const useStyles = makeStyles<Theme>(() => ({
  container: {
    padding: "20px",
    display: "block",
    color: hgemColours.DarkGrey,
    fontFamily: hgemFont,
  },
  logo: {
    maxWidth: "250px",
  },
  innerMessage: {
    "& p": {
      marginTop: "0px",
    },

    "& a": {},
  },
}));

const AuthError: React.FC<MsalAuthenticationResult> = ({ error }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const isMultiTenantLoginError = error?.errorMessage.includes(
    invalidIssuerErrorId ?? "AADB2C90238"
  );

  const isPasswordResetFlow = error?.errorMessage.includes(
    passwordResetErrorId ?? "AADB2C90118"
  );

  const subtitle = i18n.translate("AUTH_ERRORPAGE_Subtitle");
  const retryHint = i18n.translate("AUTH_ERRORPAGE_RetryHint");
  const subtitleMultiTenant = i18n.translate(
    "AUTH_ERRORPAGE_Subtitle_MultiTenant"
  );
  const retryHintMultiTenant = i18n.translate(
    "AUTH_ERRORPAGE_RetryHint_MultiTenant"
  );
  const resetPrefix = i18n.translate("ERRORPAGE_ResetPrefix");
  const resetLinkText = i18n.translate("ERRORPAGE_ResetLinkText");
  const resetLinkTextMultiTenant = i18n.translate(
    "AUTH_ERRORPAGE_ResetLinkTextMultiTenant"
  );
  const homePageLinkText = i18n.translate("ERRORPAGE_HomePage_Text");
  const contactMessage = i18n.translate("ERRORPAGE_Alternative_links");
  const contactLink = i18n.translate("ERRORPAGE_Alternative_contact_page");
  const contactPlaceholder = i18n.translate(
    "ERRORPAGE_Alternative_contact_page_token"
  );

  return isPasswordResetFlow ? (
    <>
      <span className="no-display">Processing Password Reset</span>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <LinearProgress />
        </Grid>
      </Grid>
    </>
  ) : (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="/hgem-logo-horizontal.png"
        alt="HGEM Logo"
      />
      <div>
        <h2>{i18n.translate("AUTH_ERRORPAGE_Title")}</h2>
        <div className={classes.innerMessage}>
          {isMultiTenantLoginError && (
            <>
              <p>{subtitleMultiTenant}</p>
              <p>
                {retryHintMultiTenant}
                <a href={routes.logout}>{resetLinkTextMultiTenant}</a>
              </p>
              <p>
                {contactMessage}
                <a target="_blank" rel="noopener noreferrer" href={contactLink}>
                  {contactPlaceholder}
                </a>
              </p>
            </>
          )}

          {!isMultiTenantLoginError && (
            <>
              <p>{subtitle}</p>
              <p>
                {retryHint}
                <a href={routes.resetSession}>{homePageLinkText}</a>
                {resetPrefix}
                <a href={routes.logout}>{resetLinkText}</a>
              </p>
              <p>
                {contactMessage}
                <a target="_blank" rel="noopener noreferrer" href={contactLink}>
                  {contactPlaceholder}
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthError;
