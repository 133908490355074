import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: "block",
    width: "200px",
    height: "200px",
    border: `1px solid ${theme.palette.grey[300]}`,
    float: "left",
    background: theme.palette.grey[200],
    [theme.breakpoints.down("md")]: {
      height: "190px",
      width: "190px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "160px",
      width: "100%",
    },
  },
  imagePending: {
    color: theme.palette.grey[500],
    position: "relative",
    top: "50%",
    left: "50%",
    margin: "-15px 0 0 -15px",
    width: "30px",
    height: "30px",
  },

  thumbnailImage: {
    width: "200px",
    height: "200px",
    objectFit: "cover",

    "&:hover": {
      opacity: 0.7,
      cursor: "pointer",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "260px",
    },
  },
}));

export const VisitMediaStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
