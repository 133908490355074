import { TextField } from "@material-ui/core";
import { i18n } from "../../../localizations";
import { GuestContactFieldStyles } from "../styles/guestContactFieldStyles";

type props = {
  messageSubject: string;
  onSubjectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  messageText: string;
  onMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sendVoucher: boolean;
  vouchersActive?: boolean;
  voucherHasBeenSent: boolean;
};

const GuestContactInputs = (props: props) => {
  const classes = GuestContactFieldStyles();

  const { messageSubject, onSubjectChange, messageText, onMessageChange } =
    props;
  return (
    <>
      <div className={classes.smallBottomMargin}>
        <TextField
          size="small"
          inputProps={{ maxLength: 100 }}
          className={classes.messageInput}
          label={i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Subject")}
          id={i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Subject")}
          value={messageSubject}
          onChange={onSubjectChange}
          variant="outlined"
          fullWidth
        />
        <TextField
          multiline
          className={classes.messageInput}
          label={i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Message")}
          id={i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Message")}
          value={messageText}
          onChange={onMessageChange}
          minRows={13}
          variant="outlined"
          fullWidth
        />
      </div>
    </>
  );
};

export default GuestContactInputs;
