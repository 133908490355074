import {
  IQuestionnaire,
  IQuestionnaireSection,
  IQuestionnaireFilterOption,
  ISectionQuestion,
  IQuestionnaireTagValue,
} from "../types/FilterOptions";
import { IQuestionnaireTag } from "./questionnaireTagsByClientId";

export interface IQuestionnairesByClientIdAndBrandVariables {
  clientId: number;
  brandId?: number;
}

export interface IQuestionnaireBrandLink {
  brand: string;
  questionnaire: string;
}

export interface IQuestionnairesByClientIdAndBrand {
  questionnairesByClientId: IGraphQuestionnaire[];
}

export interface IGraphQuestionnaire {
  id: number;
  visitType: string;
  description: string;
  questionnaireCategory?: IGraphQuestionnaireCategory;
  sections: IGraphSection[];
}

export interface IGraphQuestionnaireCategory {
  name: string;
}

export interface IGraphSection {
  sectionNo: number;
  name: string;
  questions: IGraphQuestion[];
  menuSectionUid?: number;
}

export interface IGraphQuestion {
  name: string;
  questionSubject?: string;
  analysisTag?: IGraphAnalysisTag;
  benchmark?: IGraphBenchmark;
  questCategory?: IGraphQuestionCategory;
}

export interface IGraphAnalysisTag {
  name: string;
}

export interface IGraphBenchmark {
  benchmarkType: string;
}

export interface IGraphQuestionCategory {
  name: string;
}

class Mapper {
  public map = (
    data: IQuestionnairesByClientIdAndBrand | undefined,
    brandLinks: IQuestionnaireBrandLink[],
    questionnaireTags: IQuestionnaireTag[]
  ): IQuestionnaireFilterOption[] => {
    const options: IQuestionnaireFilterOption[] = [];
    const distinctVisitTypes = new Set(
      data ? data.questionnairesByClientId.map((x) => x.visitType) : []
    );

    for (const visitType of distinctVisitTypes) {
      const questionnaires = data?.questionnairesByClientId
        .filter((x) => x.visitType === visitType)
        .filter((x) => isExcludedQuestionnaire(x))
        .map((o) => {
          return {
            name: o.description,
            applicableToBrands: brandLinks
              .filter((x) => x.questionnaire === o.description)
              .map((x) => x.brand),
            tags: buildQuestionnaireTagValues(questionnaireTags, o.id),
            category:
              o.questionnaireCategory && o.questionnaireCategory !== null
                ? o.questionnaireCategory.name
                : "",
            sections: o.sections.map((s) => {
              return {
                name: s.name,
                sectionNumber: s.sectionNo,
                isMenuSection: s.menuSectionUid ? true : false,
                analysisTags: cleanCollectionItems(
                  s.questions.map((a) => a.analysisTag?.name)
                ),
                benchmarks: cleanCollectionItems(
                  s.questions.map((b) => b.benchmark?.benchmarkType)
                ),
                questionCategories: cleanCollectionItems(
                  s.questions.map((c) => c.questCategory?.name)
                ),
                questions: s.questions.map((q) => {
                  return {
                    question: q.name,
                    subject: q.questionSubject,
                  } as ISectionQuestion;
                }),
              } as IQuestionnaireSection;
            }),
          } as IQuestionnaire;
        });

      options.push({
        visitType: visitType,
        questionnaires: questionnaires,
      } as IQuestionnaireFilterOption);
    }

    return options;
  };
}

const cleanCollectionItems = (items: (string | undefined | null)[]) => {
  return [
    ...new Set(
      items.filter((x) => {
        return x !== undefined && x !== null && x !== "";
      })
    ),
  ];
};

const isExcludedQuestionnaire = (
  questionnaire: IGraphQuestionnaire
): boolean => {
  const categoryCheck = new RegExp(/^(virtual|virtual questionnaire)$/, "i");
  const category = questionnaire.questionnaireCategory
    ? questionnaire.questionnaireCategory.name
    : "";

  return !categoryCheck.test(category);
};

const buildQuestionnaireTagValues = ( 
  questionnaireTags: IQuestionnaireTag[],
  questionnaireId: number
): IQuestionnaireTagValue[] => {
  const tags = questionnaireTags.filter(
    (x) => x.questionnaireId === questionnaireId
  );
  const tagNames = [...new Set(tags.map((x) => x.analysisTagName))];

  return tagNames.map((x) => {
    return {
      tag: x,
      values: tags
        .filter((t) => t.analysisTagName === x)
        .map((v) => v.analysisTagValue),
    } as IQuestionnaireTagValue;
  });
};

export const QuestionnairesByClientIdAndBrandMapper = new Mapper();
