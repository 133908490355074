import React from "react";
import { Button, Icon } from "@material-ui/core";
import AnnounceKit from "announcekit-react";
import { useUserId } from "../../../../state/hooks/clients/useUserId";
import { useClientId } from "../../../../state/hooks/clients/useClientId";
import { NotificationStyles } from "../../styles/notificationStyles";
import { useUserName } from "../../../../state/hooks/clients/useUserName";
const AnnounceKitWidget = () => {
  const userId = useUserId();
  const clientId = useClientId();
  const classes = NotificationStyles();
  const userName = useUserName();

  return (
    <Button className={classes.announceKitButton}>
      <AnnounceKit
        widget="https://updates.hub.hgem.com/widgets/v2/26Gm3u"
        trackLocationChanges={true}
        data={{
          clientId: `${clientId}`,
        }}
        user={{
          id: `${userId}`,
          name: userName,
        }}
        widgetStyle={{
          position: "absolute",
          left: "60%",
          top: "50%",
        }}
      >
        <Icon className={`fas fa-bell-plus ${classes.announcementIcon}`} />
      </AnnounceKit>
    </Button>
  );
};

export default AnnounceKitWidget;
