import { hgemColours } from "../../../themes/defaultTheme";
import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  contactConfirmationContainer: {
    marginBottom: "40px",
    clear: "both",
    float: "right",
    marginLeft: "100px",
    marginRight: "10px",
    textAlign: "right",
  },
  contactConfirmation: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    padding: "15px 10px",
    borderRadius: "20px",
    border: `1px solid ${lighten(hgemColours.LimeGreen, 0.6)}`,
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    marginTop: "5px",
    whiteSpace: "pre-line",
    fontSize: "0.9em",
  },
  confirmationIcon: {
    fontSize: "1.4em",
    color: theme.palette.grey[500],
    margin: "0 10px 0 5px",
  },
  confirmationText: {
    flexGrow: 10,
    maxHeight: "300px",
    overflow: "auto",
  },
  emptyNotes: {
    fontStyle: "italic",
    color: theme.palette.grey[500],
  },
}));

export const ContactConfirmedStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
