import { useEffect, useState } from "react";
import { AuthServiceInstance } from "../../../authentication/authService";

export const useUserId = () => {
  const [userId, setUserId] = useState<number>(0);

  useEffect(() => {
    if (!userId) {
      AuthServiceInstance.getUserId().then((userId) => {
        setUserId(userId);
      });
    }
  }, [userId, setUserId]);

  return userId;
};
