import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
    selectedDayValues: string[];
    applySelectionCallback: (selectedDays: string[]) => void;
}

const DayValues = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const DayPicker = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDayValues, setSelectedDayValues] = useState(
      [] as string[]
    );
  
    const handleToggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const getAppliedSelectionText = (): string => {
      let selectionText = "";
      if (
        selectedDayValues.length === 0 ||
        selectedDayValues.length === 7
      ) {
        selectionText = i18n.translate("DAY_OF_WEEK_PICKER_All_Selected");
      } else if (selectedDayValues.length === 1) {
        selectionText = selectedDayValues[0];
      } else {
        selectionText = `${selectedDayValues.length} ${i18n.translate(
          "DAY_OF_WEEK_PICKER_Selected"
        )}`;
      }
  
      return selectionText;
    };
  
    const buildSortedTooltipContent = (): string => {
      const selected: string[] = [];
      DayValues.forEach(d => {
        if (selectedDayValues.indexOf(d) > -1){
            selected.push(d);
        }
      });

      return selected.join(", ");
    };
  
    const applySelection = (selectedOptions: string[]) => {
      props.applySelectionCallback(selectedOptions);
      setIsOpen(false);
    };
  
    const cancelSelection = () => {
      setIsOpen(false);
    };
  
    useEffect(() => {
      setSelectedDayValues(props.selectedDayValues);
    }, [setSelectedDayValues, props.selectedDayValues]);
  
    return (
      <FilterWrapper
        label={i18n.translate("DAY_OF_WEEK_PICKER_Day")}
        selectionHint={getAppliedSelectionText()}
        isOpen={isOpen}
        toggleOpenState={handleToggleOpen}
        tooltip={buildSortedTooltipContent()}
      >
        <GenericMultiSelect
          useSelectAllOption={false}
          selectAllOptionText={i18n.translate(
            "DAY_OF_WEEK_PICKER_Select_All_Option"
          )}
          multiSelect={true}
          options={DayValues}
          selectedOptions={selectedDayValues}
          applySelectionCallback={applySelection}
          cancelSelectionCallback={cancelSelection}
          applyLocalisationToOptions={true}
          localisationPrefix="DAY_OF_WEEK_PICKER_"
          applySort={false}
        />
      </FilterWrapper>
    );
};

export default DayPicker;