import { ILocationFilterOption } from "../types/FilterOptions";

export interface IBranchesByClientIdAndManagerIdVariables {
  clientId: number;
  managerId: number;
}

export interface IBranchesByClientIdAndManagerId {
  branchesByClientIdAndManagerId: IGraphBranch[];
}

export interface IGraphBranch {
  id: number,
  name: string;
  brand?: IGraphBrand;
  segment?: IGraphSegment;
  clientRegion?: IGraphClientRegion;
}

export interface IGraphBrand {
  name: string;
}

export interface IGraphSegment {
    name: string;
  }

export interface IGraphClientRegion {
  name: string;
  parentRegion?: IGraphParentRegion;
}

export interface IGraphParentRegion {
  name: string;
}

class Mapper {
  public map = (
    data: IBranchesByClientIdAndManagerId | undefined,
    clientName: string | undefined
  ): ILocationFilterOption[] => {
    return data
      ? data.branchesByClientIdAndManagerId.map((x) => {
          return {
            branchId: x.id,
            branchName: x.name,
            segment: x.segment?.name,
            area: x.clientRegion?.name,
            region: x.clientRegion?.parentRegion?.name,
            brand: (x.brand && x.brand.name) ? x.brand.name : clientName
          } as ILocationFilterOption;
        })
      : [];
  };
}

export const BranchesByClientIdAndManagerIdMapper = new Mapper();
