import { makeStyles, Theme, useTheme, lighten } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    marginBottom: "40px",
    clear: "both",

    "&.my-comment": {
      float: "right",
      marginLeft: "100px",
      marginRight: "10px",
      textAlign: "right",

      "& .comment": {
        borderColor: lighten(hgemColours.LimeGreen, 0.6),
        backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: 0,
      },
    },

    "&.other-comment": {
      float: "left",
      marginRight: "100px",
      textAlign: "left",
    },
  },
  newAppealContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "25%",
    maxWidth: "fit-content",
    padding: "1% 2% 1% 2%",
    borderRadius: "2em",
    border: `1px solid ${lighten(hgemColours.LimeGreen, 0.6)}`,
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    fontSize: "0.9em",
  },
  newAppealText: {
    paddingRight: "1%",
  },
  editIcon: {
    color: hgemColours.DarkGrey,
    cursor: "pointer",
    fontSize: "0.9em",
    marginLeft: "auto",
  },
  conversationsFlexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  inBound: {
    order: 3,
    marginLeft: "2%",
  },

  outBound: {
    order: 1,
    marginRight: "2%",
  },
  commentText: {
    display: "inline-block",
    padding: "15px 10px",
    borderRadius: "20px",
    border: `2px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.grey[50],
    borderBottomLeftRadius: 0,
    marginTop: "5px",
    textAlign: "left",
    whiteSpace: "pre-line",
    fontSize: "0.9em",
    order: 2,

    "&.conversations": {
      minWidth: "200px",
    },

    "&.media": {
      minWidth: "200px",
    },
  },
}));

export const CommentStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
