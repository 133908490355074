import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import { ICaseSelect } from "../../../state/types/TaskCentreCases";

export const AvailableVisitTasks = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.cases.map((x) => {
      return { id: x.id, title: x.title } as ICaseSelect;
    });
  }
);

export const SelectedCaseId = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.selectedCaseId;
  }
);
