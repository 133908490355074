import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  userIconMobile: {
    color: theme.palette.text.primary,
    height: "20px",
    margin: "0 0 5% 25%",
  },
  userIconDesktop: {
    color: theme.palette.text.primary,
  },
  userMenu: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const UserMenuStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
