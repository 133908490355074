import axios, { AxiosResponse } from "axios";
import { IGraphQueryResponse } from "../graphs/types";
import { IGetCasesQuery } from "../types/TaskCentreCaseQuery";
import {
  ICaseDetails,
  ICaseCategory,
  IGetCasesModel,
} from "../types/TaskCentreCases";
import {
  CreateCaseCommand,
  IAttachableCaseCommand,
  ICaseCommand,
} from "../types/TaskCentreCommands";
import {
  IActiveManagersByClientId,
  IActiveManagersByClientIdVariables,
} from "../graphs/activeManagersByClientId";
import { MediaLinks } from "../../components/inboxViews/components/mediaMessages/types/mediaLinks";
import { RelatedGuestCommunicationModel } from "../../components/InboxGuestContact/models/relatedGuestCommunicationModel";

export const getCasesApi = (
  clientId: number,
  query: IGetCasesQuery
): Promise<AxiosResponse<IGetCasesModel>> => {
  return axios.post<IGetCasesModel>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/GetCases`,
    query
  );
};

export const executeCreateCaseCommandApi = (
  clientId: number,
  command: CreateCaseCommand
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/CreateCase`,
    command
  );
};

export const executeCaseCommandsApi = (
  clientId: number,
  caseId: number,
  commands: ICaseCommand[]
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.put<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/UpdateCase/${caseId}`,
    commands
  );
};

export const executeAttachableCaseCommandApi = (
  clientId: number,
  caseId: number,
  attachableCommand: IAttachableCaseCommand
): Promise<AxiosResponse<ICaseDetails>> => {
  const formData = new FormData();

  const attachmentsPropertyName: keyof IAttachableCaseCommand = "attachments";

  formData.append(
    "text",
    JSON.stringify(attachableCommand, (key, value) =>
      key === attachmentsPropertyName ? undefined : value
    )
  );

  if (attachableCommand.attachments) {
    attachableCommand.attachments.forEach((attachment, index) => {
      formData.append(`files[${index}]`, attachment);
    });
  }

  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/Case/${caseId}/UploadAttachment`,
    formData
  );
};

export const getCategoriesApi = (
  clientId: number
): Promise<AxiosResponse<ICaseCategory[]>> => {
  return axios.get<ICaseCategory[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/Categories`
  );
};

export const getManagersApi = (
  clientId: number
): Promise<AxiosResponse<IGraphQueryResponse<IActiveManagersByClientId>>> => {
  const variables: IActiveManagersByClientIdVariables = {
    clientId: clientId,
  };

  const query = "ActiveManagersByClientId";
  const vairableJson = JSON.stringify(variables);

  return axios.get<IGraphQueryResponse<IActiveManagersByClientId>>(
    `${
      process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL
    }${query}/variables/${encodeURI(vairableJson)}`
  );
};

export const getTaskEventAttachmentApi = (
  clientId: number,
  visitId: number,
  caseId: number,
  caseEventAttachmentId: number,
  filepath: string
): Promise<MediaLinks> => {
  const url = `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/Visit/${visitId}/Case/${caseId}/Attachment/${caseEventAttachmentId}/${filepath}`;

  return axios.get<Blob>(url, { responseType: "blob" }).then((response) => {
    const localUrl = URL.createObjectURL(response.data);

    return {
      localUrl: localUrl,
      blob: response.data,
    };
  });
};

export const downloadTaskAttachmentApi = (
  clientId: number,
  visitId: number,
  caseId: number,
  caseEventAttachmentId: number,
  filepath: string
): Promise<AxiosResponse<Blob>> => {
  return axios.get<Blob>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/Visit/${visitId}/Case/${caseId}/Attachment/${caseEventAttachmentId}/${filepath}`,
    { responseType: "blob" }
  );
};

export const deleteTaskAttachmentApi = (
  clientId: number,
  visitId: number,
  caseId: number,
  authorId: number | undefined,
  caseEventAttachmentId: number
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.delete<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/Case/${caseId}/DeleteAttachment`,
    {
      data: {
        authorId,
        visitId,
        caseEventAttachmentId,
      },
    }
  );
};

export const getAttachmentsDisallowedFileExtensionsApi = (): Promise<
  AxiosResponse<string[]>
> => {
  return axios.get<string[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/AttachmentsDisallowedFileExtensions`
  );
};

export const getRelatedGuestCommunicationCases = (
  clientId: number,
  caseId: number,
  guestEmail: string
): Promise<AxiosResponse<RelatedGuestCommunicationModel[]>> => {
  return axios.post<RelatedGuestCommunicationModel[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}CaseManagement/Client/${clientId}/GetRelatedGuestCommunicationCases`,
    {
      caseId,
      guestEmail,
    }
  );
};
