import { hgemColours, materialFont } from "../../../themes/defaultTheme";
import { Theme, makeStyles, useTheme, lighten } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    columnGap: "5%",
    minHeight: "30px",
    fontSize: "1rem",
    alignItems: "center",
  },
  simpleFlexRow: {
    display: "flex",
    flexDirection: "row",
  },
  actionCardFlexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1.5%",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  flexColumnRowGap: {
    rowGap: "8%",
  },
  iconFlexColumm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexSpan: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    columnGap: "25%",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      minWidth: "70px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "50px",
    },
    [theme.breakpoints.between(1280, 1400)]: {
      minWidth: "50px",
    },
  },
  fullWidthFlex: {
    width: "100%",
  },
  flexDl: {
    display: "flex",
    flexWrap: "wrap",
    columGap: "3%",
    margin: "0",
  },
  flexDt: {
    width: "40%",
  },
  flexDd: {
    width: "60%",
    marginLeft: "auto",
  },
  visitTitle: {
    fontSize: "1em",
    fontWeight: 300,
    width: "100%",
    alignSelf: "flex-start",
    [theme.breakpoints.between(1280, 1400)]: {
      fontSize: "0.75em",
    },
  },
  wideColumnVisibleOverflow: {
    width: "85%",
    overflow: "visible",
  },
  narrowColumn: {
    width: "50%",
  },

  inboxIcon: {
    overflow: "visible",
    color: "#bcc2c4",
  },
  score: {
    marginTop: "45%",
    fontWeight: 800,
    color: `${hgemColours.DarkGrey}`,
  },
  actionCardScore: {
    fontWeight: 800,
    color: `${hgemColours.DarkGrey}`,
  },
  visitDetailsItem: {
    color: theme.palette.text.primary,
    fontSize: "100%",
    display: "inline-block",
    position: "relative",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "5px",
    backgroundColor: hgemColours.White,
    cursor: "pointer",
    textAlign: "left",
    fontFamily: materialFont,
    padding: "2%",
    marginBottom: "2.5%",
    width: "calc(100% - 20px)",
    [theme.breakpoints.up("lg")]: {
      transition: "margin-left 0.5s ease 0s",
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "0.9em",
    },

    "&:hover": {
      backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
    },

    "&.selected": {
      border: `2px solid ${hgemColours.LimeGreen}`,
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
      backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    },
    "&.green": {
      borderLeft: `5px solid ${lighten(hgemColours.Green, 0.4)}`,
    },
    "&.amber": {
      borderLeft: `5px solid ${lighten(hgemColours.Yellow, 0.4)}`,
    },
    "&.red": {
      borderLeft: `5px solid ${lighten(hgemColours.Red, 0.4)}`,
    },
    "&.default": {
      borderLeft: `5px solid ${hgemColours.ExtraMediumGrey}`,
    },
  },
  infoBullet: {
    fontSize: "0.8em",
    color: theme.palette.grey[700],
  },

  actionsBadge: {
    fontWeight: "bolder",
    fontSize: "0.75em",
    backgroundColor: `${hgemColours.RgbColours.translucentBlue}`,
    color: "white",
  },
  grayIcon: {
    color: hgemColours.ExtraMediumGrey,
  },
  darkGrayIcon: {
    color: "#929b9f",
  },
  appealBlueIcon: {
    color: hgemColours.LimeGreen,
  },
  toolTip: {
    width: "220px",
  },
  smallTooltip: {
    width: "150px",
    textAlign: "center",
  },
  brandIcon: {
    overflow: "visible",
  },
  actionsIcon: {
    overflow: "visible",
    opacity: "0.7",
    fontSize: "1.25rem",
  },
  iconMargin: {
    marginRight: "5%",
  },
  Badge: {
    backgroundColor: "#f49949",
    color: `${hgemColours.White}`,
  },
  bottomRightIcons: {
    fontSize: "1.1em",
  },
  leftMargin: {
    marginLeft: "20%",
  },
  subText: {
    fontSize: "0.8em",
    color: "#9d9b9b",
  },
}));

export const InboxItemStyles = () => {
  const theme = useTheme();

  return useStyles(theme);
};
