import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {},

  responseInput: {
    backgroundColor: hgemColours.White,
    "& label": {
      color: theme.palette.grey[400],
    },

    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
  },
  charsRemaining: {
    fontSize: "0.8em",
    color: theme.palette.grey[500],

    "&.char-overflow": {
      color: hgemColours.Red,
      fontWeight: "bold",
    },
  },
  actionContainer: {
    position: "relative",
    marginTop: "5px",
    height: "40px",
  },
  postButton: {
    position: "absolute",
    right: 0,
  },
  postButtonError: {
    position: "absolute",
    right: 0,
    color: hgemColours.Red,
  },
  responseIcon: {
    display: "block",
    float: "left",
    fontSize: "2em",
    rotate: "180deg",
    marginLeft: "50px",
    color: theme.palette.grey[200],
  },
  templateTasks: {
    marginBottom: "15px",
  },
  templateButton: {
    marginRight: "5px",
  },
}));

export const ReviewResponseStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
