import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";

export const VisitTasksPending = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.pending;
  }
);
