import { IReportConfiguration } from "../types/ReportConfiguration";

export const REPORTCONFIGURATION_SUCCESS = "REPORTCONFIGURATION_SUCCESS";
export const REPORTCONFIGURATION_ERROR = "REPORTCONFIGURATION_ERROR";
export const REPORTCONFIGURATION_RESET = "REPORTCONFIGURATION_RESET";

export interface IReportConfigurationSuccess {
  type: typeof REPORTCONFIGURATION_SUCCESS;
  reportConfiguration: IReportConfiguration;
}

export function reportConfigurationSuccess(
  reportConfiguration: IReportConfiguration
): IReportConfigurationSuccess {
  return {
    type: REPORTCONFIGURATION_SUCCESS,
    reportConfiguration: reportConfiguration,
  };
}

export interface IReportConfigurationError {
  type: typeof REPORTCONFIGURATION_ERROR;
  error: string;
}

export function reportConfigurationError(
  error: string
): IReportConfigurationError {
  return {
    type: REPORTCONFIGURATION_ERROR,
    error: error,
  };
}

export interface IReportConfigurationReset {
  type: typeof REPORTCONFIGURATION_RESET;
}

export function reportConfigurationReset(): IReportConfigurationReset {
  return {
    type: REPORTCONFIGURATION_RESET,
  };
}

export type ReportConfigurationActions =
  | IReportConfigurationSuccess
  | IReportConfigurationError
  | IReportConfigurationReset;
