import { Button } from "@material-ui/core";
import { useRef } from "react";
import { i18n } from "../../../../../localizations";
import { BrandSelectionFormStyles } from "../../../../userToolbar/styles/brandSelectionFormStyles";

type Props = {
    onFileSelected: (file: File | undefined) => void,
    invalidFileSelected: (reason: string) => void,
    allowedMimetypes: string[],
    maxSizeMegabytes: number
};

const MediaSelection = (props: Props) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const classes = BrandSelectionFormStyles();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
    
        if (!fileList) {
          return;
        }
    
        const file = fileList[0];
        if (file) {
          if (!props.allowedMimetypes.some(x => x === file.type)) {
            props.invalidFileSelected(`${file.type} ${i18n.translate("CONVERSATION_MESSAGE_MEDIA_INVALID_FILETYPE")}`);
          }
          else if ((file.size/1024/1024) > props.maxSizeMegabytes) {
            props.invalidFileSelected(`${i18n.translate("CONVERSATION_MESSAGE_MEDIA_INVALID_FILESIZE_A")} ${props.maxSizeMegabytes}${i18n.translate("CONVERSATION_MESSAGE_MEDIA_INVALID_FILESIZE_B")}`);
          }
          else {
            props.onFileSelected(file);
          }
        }
    
        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }
    };

    return <>
        <input
            data-testid="conversation-media-upload-file-input"
            type="file"
            style={{ display: "none" }}
            multiple={false}
            onChange={handleFileChange}
            ref={fileInputRef}
            accept={props.allowedMimetypes.join(",")}
        />
        <Button
            className={classes.button}
            variant={"contained"}
            color="primary"
            onClick={() => fileInputRef?.current?.click()}
          >{i18n.translate("CONVERSATION_MESSAGE_MEDIA_SELECT_FILE")}</Button>
    </>
}

export default MediaSelection;