import { Dialog, Icon, IconButton } from "@material-ui/core";
import React from "react";
import i18n from "../../../localizations/i18n";
import { UnavailableStatus } from "../state/api";
import { VisitPhotoModalStyles } from "../styles/visitPhotoModalStyles";

interface IProps {
  visitId: number;
  receiptIndex: number;
  receiptImageData: string;
  onClose: () => void;
}

const VisitReceiptModal = (props: IProps) => {
  const classes = VisitPhotoModalStyles();

  const handleDownload = () => {
    const fullFilename = `${i18n.translate(
      "VISIT_DETAILS_PHOTO_MODAL_Filename_Prefix"
    )}-${props.receiptIndex}-${props.visitId}.jpg`;
    saveAs(props.receiptImageData, fullFilename);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog className={classes.dialog} open={true} onClose={props.onClose}>
      {props.receiptImageData !== UnavailableStatus &&
        props.receiptImageData.length > 0 && (
          <>
            <div className={classes.imageOptions}>
              <IconButton
                size="small"
                onClick={handleClose}
                title={i18n.translate("VISIT_DETAILS_PHOTO_MODAL_Close")}
              >
                <Icon className="far fa-window-close" />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleDownload}
                title={i18n.translate("VISIT_DETAILS_PHOTO_MODAL_Download")}
              >
                <Icon className="fas fa-download" />
              </IconButton>
            </div>
            <img
              src={props.receiptImageData}
              alt={i18n.translate("VISIT_DETAILS_MEDIA_Photo_Alt_Text")}
            />
          </>
        )}
    </Dialog>
  );
};

export default VisitReceiptModal;
