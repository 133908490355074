import axios, { AxiosError, AxiosRequestConfig } from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";
import { AuthServiceInstance } from "../authentication/authService";

const basicAuthEndpoints = ["v1/OpenStatus/GetStatuses"];

export const registerAxiosInterceptors = () => {
  axios.interceptors.request.use(
    async (request: AxiosRequestConfig) => {
      const isBasicAuthRequest = requestRequiresBasicAuth(request);
      if (isBasicAuthRequest) {
        addBasicAuth(request);
      } else {
        const tokenAdded = await addTokenAuth(request);
        if (!tokenAdded) {
          throw new axios.Cancel("Auth Token not added to request header");
        }
      }

      return request;
    },
    (error) => error
  );
};

export const registerAxiosRetry = (): void => {
  axiosRetry(axios, {
    retries: 3,
    retryDelay: retryExponentialDelay,
    retryCondition: retryFailedRequest,
  });
};

const addBasicAuth = (request: AxiosRequestConfig) => {
  const token = process.env.REACT_APP_VISIT_DETAILS_TASK_COUNT_TOKEN;
  request.headers.Authorization = token;
};

const addTokenAuth = async (request: AxiosRequestConfig): Promise<boolean> => {
  const token = await AuthServiceInstance.getAccessToken();
  const isAdmin = await AuthServiceInstance.isAdmin();
  let tokenAdded = false;

  if (request.headers && token) {
    request.headers.authorization = `Bearer ${token.accessToken}`;
    tokenAdded = true;

    if (isAdmin) {
      const userImpersonation = await AuthServiceInstance.getUserId();
      request.headers.ImpersonationManagerId = `${userImpersonation}`;
    }
  }

  return tokenAdded;
};

const requestRequiresBasicAuth = (request: AxiosRequestConfig): boolean => {
  const url = request.url ? request.url : "";
  const matchedBasicAuthEndpoint = basicAuthEndpoints.find(
    (x) => url.toLowerCase().indexOf(x.toLowerCase()) > -1
  );

  return matchedBasicAuthEndpoint !== undefined;
};

const retryFailedRequest = (error: AxiosError): boolean => {
  const isStandardError = isNetworkOrIdempotentRequestError(error);

  const isPermittedPostRequest =
    error.config.method?.toLowerCase() === "post" &&
    !error.config.data &&
    error.response !== undefined &&
    error.response.status >= 500 &&
    error.response.status <= 599;

  return isStandardError || isPermittedPostRequest;
};

const retryExponentialDelay = (retryCount: number): number => {
  const delay = Math.pow(2, retryCount) * 500;
  const randomSum = delay * 0.2 * Math.random();

  return delay + randomSum;
};
