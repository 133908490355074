import { RootState } from "../../../state/reducers/RootReducer";

// Report Configuration
export const ReportConfigurationLoaded = (state: RootState) => {
  const config = state.ReportConfigurationReducer.reportConfiguraton;
  return config.accessTokens.length > 0 && config.reportDetails.length > 0;
};

export const ReportTokenExpiration = (state: RootState) =>
  state.ReportConfigurationReducer.reportConfiguraton.tokensExpireAt;

export const ReportConfigErrors = (state: RootState) =>
  state.ReportConfigurationReducer.error;
  
export const ReportConfigLastUpdated = (state: RootState) =>
  state.ReportConfigurationReducer.reportConfiguraton.lastUpdated;

// User Profile
export const UserProfileLoaded = (state: RootState) => {
  const userProfile = state.UserProfileReducer.userProfile;
  return (
    userProfile &&
    userProfile.navigationOptions.length > 0 &&
    userProfile.availableClients.length > 0
  );
};

export const UserProfileErrors = (state: RootState) =>
  state.UserProfileReducer.error;

export const UserProfilePending = (state: RootState) =>
  state.UserProfileReducer.pending;

export const UserProfileLastUpdated = (state: RootState) =>
  state.UserProfileReducer.lastUpdated;

export const UserProfileAvailableBrands = (state: RootState) =>
  state.UserProfileReducer.userProfile?.availableBrands;

export const UserProfileCurrentClientId = (state: RootState) =>
  state.UserProfileReducer.userProfile?.currentClient;

export const UserProfileAvailableClients = (state: RootState) =>
  state.UserProfileReducer.userProfile?.availableClients;

// Filter Options
export const FilterOptionsLoaded = (state: RootState) => {
  const filterOptions = state.FilterOptionsReducer.filterOptions;
  return filterOptions && filterOptions.lastUpdated !== undefined;
};

export const FilterOptionsErrors = (state: RootState) =>
  state.FilterOptionsReducer.error;

export const FilterOptionsPending = (state: RootState) =>
  state.FilterOptionsReducer.pending;

export const FilterOptionsLastUpdated = (state: RootState) =>
  state.FilterOptionsReducer.filterOptions.lastUpdated;

// Filter Sets
export const FilterSetsLoaded = (state: RootState) => {
  const filterSets = state.FilterSetsReducer.filterSetConfiguration;
  return filterSets && filterSets.filterSets.length > 0;
};

export const FilterSetsErrors = (state: RootState) =>
  state.FilterSetsReducer.error;

export const FilterSetsPending = (state: RootState) =>
  state.FilterSetsReducer.pending;

export const FilterSetsLastUpdated = (state: RootState) =>
  state.FilterSetsReducer.filterSetConfiguration.lastUpdated;
