import i18n from "../../localizations/i18n";
import { IAppealReviewDecision } from "./Appeals";

export interface ITaskCentreCases {
  cases: ICaseDetails[];
  selectedCaseId: number | undefined;
  lastRefreshed: Date | undefined;
  caseListPageCount: number;
  caseListItemCount: number;
  moreCasesAvailable: boolean | undefined;
}

export interface ICaseDetails {
  id: number;
  vId: number;
  title: string;
  wfStatus: WorkflowStatus;
  priority: Priority;
  due?: string;
  category?: ICaseCategory;
  appealReviewDecision?: IAppealReviewDecision;
  assigneeId?: number;
  assigneeName?: string;
  cDate: string;
  mDate: string;
  events: ICaseEvent[];
  branch: string;
  contact: boolean;
  vType?: string;
  vScore?: number;
}

export interface ICaseEvent {
  id: number;
  title?: string;
  type: CaseEventType;
  wfStatus?: WorkflowStatus;
  priority?: Priority;
  comment?: string;
  due?: string;
  category?: ICaseCategory;
  assigneeId?: number;
  assigneeName?: string;
  authorId: number;
  authorName: string;
  timestamp: string;
  subject?: string;
  message?: string;
  sender?: string;
  recipient?: string;
  question?: IQuestion;
  deleted?: Date;
  isInternal?: boolean;
  bonusAppealOnly?: boolean | undefined;
  caseEventAttachments?: ICaseEventAttachment[] | undefined;
}

export interface ICaseCategory {
  id: number;
  name: string;
}

export interface IMinimalCaseEventAttachment {
  id: number;
  filepath: string;
}

export interface ICaseEventAttachment {
  id: number;
  filepath: string;
  uploadDate: Date;
  createdDate: Date;
}

export enum WorkflowStatus {
  Discussion = 1,
  ActionRequired = 2,
  InProgress = 3,
  ActionTaken = 4,
  DoNotAction = 5,
  AppealRaised = 6,
  AppealApprovedForReview = 7,
  AppealCancelled = 8,
  AppealUnderReview = 9,
  AppealRejected = 10,
  AppealGranted = 11,
}

export type WorkflowStatusType = {
  text: string;
  value: WorkflowStatus;
};

export const WorkflowStatuses: WorkflowStatusType[] = [
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_STATUS_Discussion"),
    value: WorkflowStatus.Discussion,
  },
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_STATUS_TaskRequired"),
    value: WorkflowStatus.ActionRequired,
  },
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_STATUS_InProgress"),
    value: WorkflowStatus.InProgress,
  },
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_STATUS_ActionTaken"),
    value: WorkflowStatus.ActionTaken,
  },
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_STATUS_DoNotAction"),
    value: WorkflowStatus.DoNotAction,
  },
];

export const AppealWorkflowStatuses = [
  {
    text: i18n.translate("APPEALS_STATUS_RAISED"),
    value: WorkflowStatus.AppealRaised,
  },
  {
    text: i18n.translate("APPEALS_STATUS_APPROVED_FOR_REVIEW"),
    value: WorkflowStatus.AppealApprovedForReview,
  },
  {
    text: i18n.translate("APPEALS_STATUS_REJECTED"),
    value: WorkflowStatus.AppealCancelled,
  },
  {
    text: i18n.translate("APPEALS_STATUS_UNDER_REVIEW"),
    value: WorkflowStatus.AppealUnderReview,
  },
  {
    text: i18n.translate("APPEALS_STATUS_REJECTED"),
    value: WorkflowStatus.AppealRejected,
  },
  {
    text: i18n.translate("APPEALS_STATUS_GRANTED"),
    value: WorkflowStatus.AppealGranted,
  },
];

export enum Priority {
  Low = 1,
  Normal = 2,
  High = 3,
}

export const Priorities = [
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_PRIORITY_Low"),
    value: Priority.Low,
  },
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_PRIORITY_Normal"),
    value: Priority.Normal,
  },
  {
    text: i18n.translate("TASK_CENTRE_WORKFLOW_PRIORITY_High"),
    value: Priority.High,
  },
];

export enum CaseStatus {
  NoTaskRequired = 1,
  Open = 2,
  Closed = 3,
}

export interface IManager {
  id: number;
  name: string;
}

export enum CaseEventType {
  CaseCreatedEvent = "CaseCreatedEvent",
  CaseAssignedEvent = "CaseAssignedEvent",
  CaseRecategorisedEvent = "CaseRecategorisedEvent",
  CaseRenamedEvent = "CaseRenamedEvent",
  CaseReprioritisedEvent = "CaseReprioritisedEvent",
  CaseUnassignedEvent = "CaseUnassignedEvent",
  DueDateChangedEvent = "DueDateChangedEvent",
  WorkflowStatusUpdatedEvent = "WorkflowStatusUpdatedEvent",
  CommentAddedEvent = "CommentAddedEvent",
  CaseDeletedEvent = "CaseDeletedEvent",
  MessageSentEvent = "MessageSentEvent",
  MessageReceivedEvent = "MessageReceivedEvent",
  GuestContactConfirmedEvent = "GuestContactConfirmedEvent",
  AppealRaisedEvent = "AppealRaisedEvent",
  AppealQuestionAddedEvent = "AppealQuestionAddedEvent",
  AppealReviewerAssignedEvent = "AppealReviewerAssignedEvent",
  AppealApprovedForReviewEvent = "AppealApprovedForReviewEvent",
  AppealCancelledEvent = "AppealCancelledEvent",
  AppealUnderReviewEvent = "AppealUnderReviewEvent",
  AppealRejectedEvent = "AppealRejectedEvent",
  AppealGrantedEvent = "AppealGrantedEvent",
  AppealReviewDecisionChangedEvent = "AppealReviewDecisionChangedEvent",
  AttachmentsAddedEvent = "AttachmentsAddedEvent",
  VoucherSent = "VoucherSent",
  AppealBonusAppealOnlySetEvent = "AppealBonusAppealOnlySetEvent",
  AppealQuestionDeletedEvent = "AppealQuestionDeletedEvent",
}

export interface ICaseSelect {
  id: number;
  title: string;
}

export interface IQuestion {
  id: number;
  questionText: string;
  questionSubject: string;
}

export interface IGetCasesModel {
  totalCount: number;
  cases: ICaseDetails[];
}
