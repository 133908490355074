export const SET_NEW_VERSION_INCOMING = "NewVersionIncoming";
export const SET_NEW_VERSION_AVAILABLE = "NewVersionAvailble";
export const NEW_VERSION_ACCEPTED = "NewVersionAccepted";

export function hubVersionUpdateIncoming() {
  return {
    type: SET_NEW_VERSION_INCOMING,
  };
}

export function newHubVersionAvailable() {
  return {
    type: SET_NEW_VERSION_AVAILABLE,
  };
}

export function versionUpdateAccepted() {
  return {
    type: NEW_VERSION_ACCEPTED,
  };
}

export type NewHubVersionIncoming = {
  type: typeof SET_NEW_VERSION_INCOMING;
};

export type NewHubVersionAvailable = {
  type: typeof SET_NEW_VERSION_AVAILABLE;
};

export type NewVersionAccepted = {
  type: typeof NEW_VERSION_ACCEPTED;
};

export type HubVersionUpdateActions =
  | NewHubVersionIncoming
  | NewHubVersionAvailable
  | NewVersionAccepted;
