import { DesktopViewStyles } from "./styles/desktopViewStyles";
import { MobileViewStyles } from "./styles/mobileViewStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  AllVisits,
  GetCurrentFilterSet,
  GetVisibleFilters,
  GetUserCurrentBrands,
  VisitsLastRefreshed,
  VisitDetailsListPending,
  MoreVisitsAvailable,
} from "../inboxList/state/selectors";
import { MediaStyles } from "./styles/mediaViewStyles";
import { useCallback, useEffect, useRef, useState } from "react";
import { RootState } from "../../state/reducers/RootReducer";
import {
  clearVisitDetailsList,
  fetchVisitDetailsList,
} from "../../state/actions/VisitDetails-Actions";
import {
  HubFilterType,
  IHubFilter,
  IVisitIdFilter,
} from "../../state/types/FilterSets";
import BuildVisitDetailsListQuery from "../inboxList/utils/visitDetailsListQueryBuilder";
import { LinearProgress, Typography } from "@material-ui/core";
import PhotoGrid from "./components/photoGrid";
import i18n from "../../localizations/i18n";

interface IProps {
  visitTypes: string[];
  pageRef: string;
  isMobileView: boolean;
  appealsFeatureActive: boolean;
}

const MediaView = (props: IProps) => {
  const dispatch = useDispatch();
  const classes = props.isMobileView ? MobileViewStyles() : DesktopViewStyles();
  const MediaClasses = MediaStyles();
  const visits = useSelector(AllVisits);
  const reportPending = useSelector(VisitDetailsListPending);
  const moreVisitsAvailable = useSelector(MoreVisitsAvailable);
  const currentFilterSet = useSelector(GetCurrentFilterSet);
  const visitsLastRefreshed = useSelector(VisitsLastRefreshed);
  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, props.pageRef)
  );
  const usersCurrentBrands = useSelector(GetUserCurrentBrands);
  const [filterState, setFilterState] = useState<IHubFilter[]>([]);
  const [visitTypes, setVisitTypes] = useState(props.visitTypes);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const selectedVisitId = (): number | undefined => {
    if (filters) {
      const selectedId = filters.find(
        (filter) => filter.type === HubFilterType.VisitId
      ) as IVisitIdFilter;
      if (selectedId) {
        return selectedId.value;
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (
      currentFilterSet &&
      (visitsLastRefreshed === undefined ||
        filterState !== filters ||
        visitTypes !== props.visitTypes ||
        selectedBrands !== usersCurrentBrands)
    ) {
      dispatch(clearVisitDetailsList());

      const query = BuildVisitDetailsListQuery(
        0,
        filters,
        props.visitTypes,
        usersCurrentBrands,
        selectedVisitId(),
        true
      );

      setFilterState(filters);
      setVisitTypes(props.visitTypes);
      setSelectedBrands(usersCurrentBrands);
      dispatch(fetchVisitDetailsList(query));
    }
  }, [
    currentFilterSet,
    dispatch,
    filterState,
    filters,
    visitTypes,
    visitTypes,
    visits,
    visitsLastRefreshed,
    selectedBrands,
    usersCurrentBrands,
  ]);
  const loadAdditionalVisits = (): void => {
    if (moreVisitsAvailable) {
      const query = BuildVisitDetailsListQuery(
        visits.length,
        filters,
        props.visitTypes,
        selectedBrands,
        selectedVisitId(),
        true
      );

      dispatch(fetchVisitDetailsList(query));
    }
  };

  const bottomBoundaryRef = useRef(null);
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            loadAdditionalVisits();
          }
        });
      }).observe(node);
    },
    [loadAdditionalVisits]
  );
  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef]);
  return (
    <>
      {reportPending && (
        <LinearProgress
          className={MediaClasses.mediaLoadingBar}
          color="primary"
        />
      )}
      {visits.length > 0 && (
        <div style={{ height: "99vh", overflow: "auto" }}>
          <div className={`${MediaClasses.gridContainer}`}>
            <PhotoGrid visits={visits} />

            {!moreVisitsAvailable && (
              <Typography className={`${MediaClasses.textMessage}`}>
                {i18n.translate("MEDIA_TAB_END_OF_PHOTOS")}
              </Typography>
            )}
            <div id="page-bottom-boundary" ref={bottomBoundaryRef}></div>
          </div>
        </div>
      )}
      {!reportPending && visits.length === 0 && (
        <div className={`${MediaClasses.gridContainer} ${classes.container}`}>
          <Typography className={`${MediaClasses.textMessage}`}>
            {i18n.translate("MEDIA_TAB_NO_MEDIA_MESSAGE")}
          </Typography>
        </div>
      )}
    </>
  );
};

export default MediaView;
