import axios, { AxiosResponse } from "axios";
import {
  IdAndName,
  BrandAccessList,
  INavigationOption,
  IAppSourceVersion,
} from "../types/UserProfile";

export const clientsApi = (
  clientId: number
): Promise<AxiosResponse<IdAndName[]>> => {
  return axios.get<IdAndName[]>(
    `${process.env.REACT_APP_HUB_API_BASEURL}clientAccess/client/${clientId}`
  );
};

export const brandsApi = (
  clientId: number
): Promise<AxiosResponse<BrandAccessList>> => {
  return axios.get<BrandAccessList>(
    `${process.env.REACT_APP_HUB_API_BASEURL}clientBrandAccess/client/${clientId}`
  );
};

export const navigationApi = (
  clientId: number
): Promise<AxiosResponse<INavigationOption[]>> => {
  return axios.get<INavigationOption[]>(
    `${process.env.REACT_APP_HUB_API_BASEURL}hubnavigation/client/${clientId}`
  );
};

export const appVersionApi = (): Promise<AxiosResponse<IAppSourceVersion>> => {
  return axios.get<IAppSourceVersion>(
    `${process.env.REACT_APP_HUB_API_BASEURL}sourceVersion/check`
  );
};
