import axios, { AxiosResponse } from "axios";
import {
  IReportConfigurationResponse,
  IReportConfigurationLastUpdatedResponse,
} from "../types/ReportConfiguration";

export const reportConfigurationApi = (
  clientId: number
): Promise<AxiosResponse<IReportConfigurationResponse>> => {
  return axios.post<IReportConfigurationResponse>(
    `${process.env.REACT_APP_HUB_API_BASEURL}reporting/client/${clientId}/configuration`
  );
};

export const reportConfigurationLastUpdatedApi = (
  clientId: number
): Promise<AxiosResponse<IReportConfigurationLastUpdatedResponse>> => {
  return axios.get<IReportConfigurationLastUpdatedResponse>(
    `${process.env.REACT_APP_HUB_API_BASEURL}reporting/client/${clientId}/configurationLastUpdated`
  );
};