import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  loader: {
    marginTop: "10px",
  },
}));

export const ConversationListSkeletonStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
