import { SagaMiddleware } from "redux-saga";
import { call, put, takeLeading } from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import {
  GET_IMPERSONATION_OPTIONS,
  IGetUserImpersonationOptions,
  userImpersonationSuccess,
  userImpersonationError,
  userImpersonationPending,
} from "../actions/UserImpersonation-Actions";
import { IdAndName, IUserImpersonation } from "../types/UserImpersonation";
import { managerListApi } from "../api/UserImpersonation-API";
import { Logging, SeverityLevel } from "../../utils/logging";

export function registerUserImpersonationSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLeading(GET_IMPERSONATION_OPTIONS, getUserImpersonationOptions);
  });
}

function* getUserImpersonationOptions(
  action: IGetUserImpersonationOptions
): unknown {
  try {
    const isAdmin: boolean = yield call(AuthServiceInstance.isAdmin);
    const clientId: number = action.clientId
      ? action.clientId
      : yield call(AuthServiceInstance.getClientId);

    if (isAdmin) {
      yield put(userImpersonationPending());

      const currentManager = yield call(AuthServiceInstance.getUserId);
      const managers: IdAndName[] = (yield call(managerListApi, clientId)).data;

      let defaultManager = undefined;
      if (managers.length > 0) {
        const matched = managers.filter((m) => {
          return m.id === currentManager;
        });

        defaultManager = matched.length === 1 ? currentManager : managers[0].id;
      }

      const userImpersonation: IUserImpersonation = {
        selectedClient: clientId,
        impersonationOptions: managers,
        defaultImpersonationOption: defaultManager,
        userCanSwitchManagers: isAdmin && managers.length > 0,
      };

      yield put(userImpersonationSuccess(userImpersonation));
    } else {
      yield put(
        userImpersonationSuccess({
          selectedClient: clientId,
          impersonationOptions: [],
          defaultImpersonationOption: undefined,
          userCanSwitchManagers: false,
        })
      );
    }
  } catch (e) {
    Logging.captureError(
      "Saga:getUserImpersonationOptions",
      e,
      SeverityLevel.Error
    );
    yield put(userImpersonationError("Failed to get impersonation options"));
  }
}
