import React from "react";
import { IconButton, Menu, Fade, MenuItem, Link } from "@material-ui/core";
import { UserMenuStyles } from "../styles/userMenuStyles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";
import i18n from "../../../localizations/i18n";
import { INavigationOption } from "../../../state/types/UserProfile";
import AccountSelectionDialog, {
  AccountSelectionProps,
} from "./accountSelectionDialog";

interface IUserToolbarProps {
  userName: string;
  userNavOptions: INavigationOption[];
  AccountSelectionProps: AccountSelectionProps;
}

const UserMenu = (props: IUserToolbarProps) => {
  const classes = UserMenuStyles();
  const history = useHistory();

  const [anchorElement, setAnchorElement] =
    React.useState<HTMLButtonElement | null>(null);
  const menuOpen = Boolean(anchorElement);

  const handleClick = (target: HTMLButtonElement) => {
    setAnchorElement(target);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  const handleRedirect = (route: string) => {
    setAnchorElement(null);
    history.push(route);
  };
  const [accountSelectionOpen, setAccountSelectionOpen] = React.useState(false);
  const toggleDialogState = () => {
    setAccountSelectionOpen(!accountSelectionOpen);
  };
  return (
    <>
      <IconButton
        aria-label="user menu"
        size="small"
        className={classes.userIconMobile}
        onClick={(e) => handleClick(e.currentTarget)}
        title={props.userName}
      >
        <AccountCircle />
      </IconButton>

      <Menu
        anchorEl={anchorElement}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {props.userNavOptions.map((navOption: INavigationOption, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleRedirect(navOption.path)}
            >
              {i18n.translate(`NAVIGATION_${navOption.name}`)}
            </MenuItem>
          );
        })}
        <MenuItem onClick={toggleDialogState}>
          {i18n.translate("USER_MENU_MY_ACCOUNTS")}
        </MenuItem>
        <MenuItem>
          <Link
            color="inherit"
            underline="none"
            href={i18n.translate("SUPPORT_OPTION_SupportLink")}
            target="_blank"
          >
            {i18n.translate(i18n.translate("SUPPORT_OPTION_Title"))}
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          {i18n.translate("GLOBAL_Logout")}
        </MenuItem>
      </Menu>
      {accountSelectionOpen && (
        <AccountSelectionDialog
          accountSelectionProps={props.AccountSelectionProps}
          toggleDialogState={toggleDialogState}
          accountSelectionOpen={accountSelectionOpen}
        />
      )}
    </>
  );
};

export default UserMenu;
