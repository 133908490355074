import { SagaMiddleware } from "redux-saga";
import { call, put, takeLeading } from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import { Logging, SeverityLevel } from "../../utils/logging";
import {
  appealsFeatureActiveError,
  appealsFeatureActivePending,
  appealsFeatureActiveSuccess,
  FETCH_APPEALS_FEATURE_ACTIVE,
} from "../actions/Appeals-Actions";
import { getAppealsFeatureActiveApi } from "../api/Appeals-API";
import { IAppealsFeatureActive } from "../types/Appeals";

export function registerAppealsSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_APPEALS_FEATURE_ACTIVE, fetchAppealsFeatureActive);
  });
}

function* fetchAppealsFeatureActive(): unknown {
  try {
    yield put(appealsFeatureActivePending());

    const clientId = yield call(AuthServiceInstance.getClientId);

    const appealsFeatureActiveResponse: IAppealsFeatureActive = (yield call(
      getAppealsFeatureActiveApi,
      clientId
    )).data;
    yield put(appealsFeatureActiveSuccess(appealsFeatureActiveResponse));
  } catch (e) {
    Logging.captureError(
      "Saga:fetchAppealsFeatureActive",
      e,
      SeverityLevel.Error
    );
    yield put(
      appealsFeatureActiveError("Error fetching appeals active configuration")
    );
  }
}
