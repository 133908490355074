import {
  IVisitTaskCount,
  IVisitDetail,
  IVisitDetailsVisits,
  IVisitMedia,
  VoucherDetailsInterface,
} from "../types/VisitDetails";
import { IGetVisitDetailsQuery } from "../types/VisitDetailsQuery";

export const VISITDETAILS_LIST_PENDING = "VISITDETAILS_LIST_PENDING";
export const VISITDETAILS_LIST_SUCCESS = "VISITDETAILS_LIST_SUCCESS";
export const VISITDETAILS_LIST_ERROR = "VISITDETAILS_LIST_ERROR";
export const CLEAR_VISITDETAILS_LIST = "CLEAR_VISITDETAILS_LIST";
export const FETCH_VISITDETAILS_LIST = "FETCH_VISITDETAILS_LIST ";
export const UPDATE_VISITDETAILS_LIST = "UPDATE_VISITDETAILS_LIST";
export const SET_VISITDETAILS_VISIT_COUNT = "SET_VISITDETAILS_VISIT_COUNT";
export const VISIT_SAVED_UPDATE_VISITDETAILS_LIST =
  "VISIT_SAVED_UPDATE_VISITDETAILS_LIST";
export const SET_SELECTED_VISITDETAILS_VISIT =
  "SET_SELECTED_VISITDETAILS_VISIT";
export const FETCH_VISIT_TASKS_COUNT = "FETCH_VISIT_TASKS_COUNT";
export const MERGE_VISIT_TASKS_COUNT = "MERGE_VISIT_TASKS_COUNT";
export const FETCH_VISIT_MEDIA = "FETCH_VISIT_MEDIA";
export const MERGE_VISIT_MEDIA = "MERGE_VISIT_MEDIA";
export const FETCH_VISIT_RECEIPTS = "FETCH_VISIT_RECEIPTS";
export const MERGE_VISIT_RECEIPTS = "MERGE_VISIT_RECEIPTS";
export const POST_REVIEW_RESPONSE = "POST_REVIEW_RESPONSE";
export const VISITDETAILS_LIST_SET_CONTACT_STATUS =
  "VISITDETAILS_LIST_SET_CONTACT_STATUS";
export const FIND_AND_REPLACE_VISIT = "FIND_AND_REPLACE_VISIT";

export interface IVisitDetailsListSuccess {
  type: typeof VISITDETAILS_LIST_SUCCESS;
  visits: IVisitDetailsVisits;
}

export function visitDetailListSuccess(
  visits: IVisitDetailsVisits
): IVisitDetailsListSuccess {
  return {
    type: VISITDETAILS_LIST_SUCCESS,
    visits,
  };
}

export interface IVisitDetailsListPending {
  type: typeof VISITDETAILS_LIST_PENDING;
  query: IGetVisitDetailsQuery;
}

export function visitDetailsListPending(
  query: IGetVisitDetailsQuery
): IVisitDetailsListPending {
  return {
    type: VISITDETAILS_LIST_PENDING,
    query: query,
  };
}

export interface IVisitDetailsListError {
  type: typeof VISITDETAILS_LIST_ERROR;
  error: string;
}

export function visitDetailsListError(error: string): IVisitDetailsListError {
  return {
    type: VISITDETAILS_LIST_ERROR,
    error: error,
  };
}

export interface IClearVisitDetailsList {
  type: typeof CLEAR_VISITDETAILS_LIST;
}

export function clearVisitDetailsList(): IClearVisitDetailsList {
  return {
    type: CLEAR_VISITDETAILS_LIST,
  };
}

export interface IFetchVisitDetailsList {
  type: typeof FETCH_VISITDETAILS_LIST;
  query: IGetVisitDetailsQuery;
}

export function fetchVisitDetailsList(
  query: IGetVisitDetailsQuery
): IFetchVisitDetailsList {
  return {
    type: FETCH_VISITDETAILS_LIST,
    query,
  };
}

export interface ISetVisitDetailsVisitCount {
  type: typeof SET_VISITDETAILS_VISIT_COUNT;
  count: number | undefined;
}

export function setVisitDetailsVisitCount(
  count: number | undefined
): ISetVisitDetailsVisitCount {
  return {
    type: SET_VISITDETAILS_VISIT_COUNT,
    count,
  };
}

export interface IUpdateVisitDetailsList {
  type: typeof UPDATE_VISITDETAILS_LIST;
  visits: IVisitDetail[];
  mergeWithExisting?: boolean;
}

export function updateVisitDetailsList(
  visits: IVisitDetail[],
  mergeWithExisting?: boolean
): IUpdateVisitDetailsList {
  return {
    type: UPDATE_VISITDETAILS_LIST,
    visits,
    mergeWithExisting,
  };
}

export interface IVisitSavedUpdateVisitDetailsList {
  type: typeof VISIT_SAVED_UPDATE_VISITDETAILS_LIST;
  visit: IVisitDetail;
}

export function visitSavedUpdateVisitDetailsList(
  visit: IVisitDetail
): IVisitSavedUpdateVisitDetailsList {
  return {
    type: VISIT_SAVED_UPDATE_VISITDETAILS_LIST,
    visit,
  };
}

export interface ISetSelectedVisitDetailId {
  type: typeof SET_SELECTED_VISITDETAILS_VISIT;
  visitId: number;
}

export function setSelectedVisitDetailId(
  visitId: number
): ISetSelectedVisitDetailId {
  return {
    type: SET_SELECTED_VISITDETAILS_VISIT,
    visitId,
  };
}

export interface IFetchVisitTasksCounts {
  type: typeof FETCH_VISIT_TASKS_COUNT;
  visitIds: number[];
}

export function fetchVisitTasksCounts(
  visitIds: number[]
): IFetchVisitTasksCounts {
  return {
    type: FETCH_VISIT_TASKS_COUNT,
    visitIds,
  };
}

export interface IMergeVisitTasksCounts {
  type: typeof MERGE_VISIT_TASKS_COUNT;
  actionCounts: IVisitTaskCount[];
}

export function mergeVisitTasksCounts(
  actionCounts: IVisitTaskCount[]
): IMergeVisitTasksCounts {
  return {
    type: MERGE_VISIT_TASKS_COUNT,
    actionCounts,
  };
}

export interface IFetchVisitMedia {
  type: typeof FETCH_VISIT_MEDIA;
  visitId: number;
}

export function fetchVisitMedia(visitId: number): IFetchVisitMedia {
  return {
    type: FETCH_VISIT_MEDIA,
    visitId,
  };
}

export interface IMergeVisitMediaItems {
  type: typeof MERGE_VISIT_MEDIA;
  visitMedia: IVisitMedia;
}

export function mergeVisitMediaItems(
  visitMedia: IVisitMedia
): IMergeVisitMediaItems {
  return {
    type: MERGE_VISIT_MEDIA,
    visitMedia,
  };
}

export interface IFetchVisitReceipts {
  type: typeof FETCH_VISIT_RECEIPTS;
  visitId: number;
}

export function fetchVisitReceipts(visitId: number): IFetchVisitReceipts {
  return {
    type: FETCH_VISIT_RECEIPTS,
    visitId,
  };
}

export interface IMergeVisitReceiptItems {
  type: typeof MERGE_VISIT_RECEIPTS;
  visitReceipts: IVisitMedia;
}

export function mergeVisitReceiptItems(
  visitReceipts: IVisitMedia
): IMergeVisitReceiptItems {
  return {
    type: MERGE_VISIT_RECEIPTS,
    visitReceipts,
  };
}

export interface IPostReviewResponse {
  type: typeof POST_REVIEW_RESPONSE;
  visitId: number;
  reviewId: string;
  responseText: string;
}

export function postReviewResponse(
  visitId: number,
  reviewId: string,
  responseText: string
): IPostReviewResponse {
  return {
    type: POST_REVIEW_RESPONSE,
    visitId,
    reviewId,
    responseText,
  };
}

export interface ISetVisitListItemContactStatus {
  type: typeof VISITDETAILS_LIST_SET_CONTACT_STATUS;
  visitId: number;
  contacted: boolean;
}

export function setVisitListItemContactStatus(
  visitId: number,
  contacted: boolean
): ISetVisitListItemContactStatus {
  return {
    type: VISITDETAILS_LIST_SET_CONTACT_STATUS,
    visitId,
    contacted,
  };
}
export interface FindAndReplaceVisit {
  type: typeof FIND_AND_REPLACE_VISIT;
  visit: IVisitDetail | undefined;
  voucherDetails: VoucherDetailsInterface | undefined;
}
export function findAndReplaceVisit(
  visit: IVisitDetail | undefined,
  voucherDetails?: VoucherDetailsInterface
): FindAndReplaceVisit {
  return {
    type: FIND_AND_REPLACE_VISIT,
    visit,
    voucherDetails,
  };
}

export type VisitDetailsActions =
  | IVisitDetailsListPending
  | IVisitDetailsListSuccess
  | IVisitDetailsListError
  | IClearVisitDetailsList
  | IFetchVisitDetailsList
  | ISetVisitDetailsVisitCount
  | IUpdateVisitDetailsList
  | IVisitSavedUpdateVisitDetailsList
  | ISetSelectedVisitDetailId
  | IFetchVisitTasksCounts
  | IMergeVisitTasksCounts
  | IFetchVisitMedia
  | IMergeVisitMediaItems
  | IFetchVisitReceipts
  | IMergeVisitReceiptItems
  | IPostReviewResponse
  | ISetVisitListItemContactStatus
  | FindAndReplaceVisit;
