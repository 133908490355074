import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  formControl: {
    width: "100%",
    margin: "10px 0 5px 0",

    "& label": {
      color: theme.palette.grey[800],
    },
  },

  inputFileExtension: {
    "& p": {
      color: theme.palette.grey[400],
    },
  },

  dialogTitle: {
    padding: "0",
  },
  dialogText: {
    color: theme.palette.text.primary,
    marginBottom: "25px",
  },
  dialogTextWarning: {
    color: hgemColours.Red,
    marginBottom: "25px",
  },
  dialogDivider: {
    padding: "25px",
  },

  button: {
    marginRight: "10px",
  },
}));

export const ReportDataExportStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
