import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  title: {
    float: "left",
    display: "flex",
  },
  pageTasks: {
    float: "left",
  },
  pageTasksIcon: {
    width: "auto",
    fontSize: "1.2rem",
  },

  flexItem: {
    width: "fit-content",
    color: hgemColours.DarkGrey,
  },
  smallerGutters: {
    paddingLeft: "25%",
  },
  pageActionsIcon: {
    overflow: "visible",
  },
  reducingMargin: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "-15%",
    },
  },
  removePadding: {
    padding: "0",
  },
}));

export const PageTitleStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
