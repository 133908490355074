import React, { useState, useEffect } from "react";
import FormField from "../../_common/formField/formField";
import { TextField } from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import {
  UnassignCaseCommand,
  AssignCaseCommand,
} from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { executeCaseCommand } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { IManager } from "../../../state/types/TaskCentreCases";
import AssigneeIcon from "../../_common/Icons/assigneeIcon";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AssigneeFieldStyles } from "../styles/inputs/assigneeFieldStyles";
import { EditCaseItemStyles } from "../styles/editCaseItemStyles";

interface IProps {
  caseId: number;
  assigneeId?: number;
  managers: IManager[];
}

const AssigneeField = (props: IProps) => {
  const classes = AssigneeFieldStyles();
  const inputClasses = EditCaseItemStyles();

  const dispatch = useDispatch();
  const [caseId, setCaseId] = useState<number | null>(null);
  const [managerValue, setManagerValue] = useState<IManager | null>(null);

  const onChange = (e: React.ChangeEvent<unknown>, value: IManager | null) => {
    if (managerValue?.id === value?.id) {
      return;
    }

    setManagerValue(value);

    const managerCommand =
      value !== null
        ? new AssignCaseCommand(props.caseId, value.id)
        : new UnassignCaseCommand(props.caseId);

    dispatch(executeCaseCommand(props.caseId, [managerCommand]));
  };

  useEffect(() => {
    const caseChanged =
      caseId !== props.caseId || managerValue?.id !== props.assigneeId;
    const hasManagers = props.managers.length > 0;

    if (hasManagers && caseChanged) {
      setCaseId(props.caseId);
      const matchedManager = props.managers.find(
        (x) => x.id === props.assigneeId
      );

      if (matchedManager) {
        setManagerValue(matchedManager);
      } else if (props.assigneeId) {
        const unknownManager: IManager = {
          id: props.assigneeId,
          name: `${i18n.translate(
            "TASK_CENTRE_ASSIGNEE_Unknown"
          )} ${props.assigneeId.toString()}`,
        };
        setManagerValue(unknownManager);
      } else {
        setManagerValue(null);
      }
    }
  }, [props.managers, dispatch, props.assigneeId, caseId, props.caseId]);

  return (
    <FormField
      before={<AssigneeIcon />}
      notificationIdentifier={`${props.caseId}|${AssignCaseCommand.type}`}
    >
      <>
        {props.managers.length > 0 && (
          <Autocomplete
            className={`${classes.assigneeField} ${inputClasses.editCaseItemInput}`}
            value={managerValue}
            onChange={onChange}
            options={props.managers}
            getOptionLabel={(option: IManager) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder={i18n.translate("TASK_CENTRE_ASSIGNEE_Unasigned")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        )}

        {props.managers.length === 0 && (
          <TextField
            fullWidth
            disabled
            className={classes.assigneeField}
            value={i18n.translate("TASK_CENTRE_ASSIGNEE_Loading")}
            InputLabelProps={{ shrink: true }}
          />
        )}
      </>
    </FormField>
  );
};

export default AssigneeField;
