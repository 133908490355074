import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  button: {
    border: "none",
    color: hgemColours.White,
    backgroundColor: hgemColours.LimeGreen,
  },
  dialogOption: {
    marginBottom: "20px",

    "& a": {
      fontWeight: "normal",
      color: hgemColours.LimeGreen,
    },
  },
  optionBold: {
    fontWeight: 600,
  },
}));

export const ReviewResponseLinkStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
