import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaskCentreCategories } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { CategoryCount } from "../state/selectors";

const TaskCategoriesLoader = () => {
  const dispatch = useDispatch();

  const categoryCount = useSelector(CategoryCount);

  useEffect(() => {
    if (categoryCount === 0) {
      dispatch(fetchTaskCentreCategories());
    }
  }, [dispatch, categoryCount]);

  return <></>;
};

export default TaskCategoriesLoader;
