export enum textSubstituionKey {
  ReviewAuthor = "ReviewAuthor",
  ReviewScore = "ReviewScore",
  BranchName = "BranchName",
  BrandName = "BrandName",
  ClientName = "ClientName",
  BrandOrClientName = "BrandOrClientName",
  ManagerName = "ManagerName",
  TaskId = "TaskId",
}

export class textSubstitution {
  public key: textSubstituionKey | undefined;
  public value: string | undefined;
}

const anyText = '[\\w="\\s\\|,\\.\\!\\?\\#\\-\\+\\~\\:\\/\\\\£\\$\\&\\*\\(\\)]'; // eslint-disable-line
const valuePlaceholder = "(\\[...\\])"; // eslint-disable-line
const alternativeValuePrefix = '("\\|\\|")'; // eslint-disable-line

export const ApplyTextSubstitutions = (
  text: string,
  substitutions: textSubstitution[]
): string => {
  let hasMatches = true;

  do {
    const substitueRegex = new RegExp(
      `(%${anyText}{1,}${valuePlaceholder}${anyText}{0,}${alternativeValuePrefix}${anyText}{1,}%)`,
      "g"
    );

    const matchedTextItems: string[] | null = substitueRegex.exec(text);

    if (matchedTextItems && matchedTextItems.length > 0) {
      const matchedText = matchedTextItems[0];

      const substitutionKey = matchedText.substring(
        1,
        matchedText.indexOf("=")
      );

      const valuePlaceholder = matchedText.substring(
        matchedText.indexOf('="') + 2,
        matchedText.indexOf('"||')
      );

      const alternativeValue = matchedText.substring(
        matchedText.indexOf('||"') + 3,
        matchedText.indexOf('"%')
      );

      const matchedSubstiution = substitutions.find(
        (x) => x.key?.toString() === substitutionKey
      );

      if (matchedSubstiution !== undefined && matchedSubstiution.value) {
        const newText = valuePlaceholder.replace(
          "[...]",
          matchedSubstiution.value
        );

        text = text.replace(matchedText, newText);
      } else {
        text = text.replace(matchedText, alternativeValue);
      }
    } else {
      hasMatches = false;
    }
  } while (hasMatches);

  return text;
};
