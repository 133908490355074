export interface IAppNotification {
  Identifier: string;
  Type: NotificationType;
  Source: NotificationSource;
  NotificationTimeout: number;
  ShowInNotificationCentre: boolean;
  Message?: string;
  AddedAt?: Date;
  IsSnoozed?: boolean;
}

export enum NotificationSource {
  TaskCentre_CaseUpdate = 1,
  FilterOptions_StateRefresh = 2,
  VisitDetails_PostReviewResponse = 3,
}

export enum NotificationType {
  Busy = 1,
  Error = 2,
  Success = 3,
  UserActionRequired = 4,
}

export enum NotificationIdentifiers {
  UseFilterOptionsObserver = "useFilterOptionsObserver",
}
