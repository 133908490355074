import { IGuestDetails } from "../types/GuestDetails";
import {
  GuestDetailsActions,
  GUEST_DETAILS_ERROR,
  GUEST_DETAILS_PENDING,
  GUEST_DETAILS_SUCCESS,
} from "../actions/GuestDetails-Actions";

export interface IGuestDetailsState {
  guestDetails: IGuestDetails | undefined;
  visitId: number | undefined;
  pending: boolean;
  error: string;
}

const INITIAL_STATE: IGuestDetailsState = {
  guestDetails: undefined,
  visitId: undefined,
  pending: false,
  error: "",
};

export default function GuestDetailsReducer(
  state: IGuestDetailsState = INITIAL_STATE,
  action: GuestDetailsActions
): IGuestDetailsState {
  switch (action.type) {
    case GUEST_DETAILS_PENDING:
      return {
        ...state,
        pending: true,
        visitId: action.visitId,
        guestDetails: undefined,
        error: "",
      };
    case GUEST_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
        guestDetails: action.guestDetails,
        visitId: action.visitId,
      };
    case GUEST_DETAILS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        visitId: action.visitId,
        guestDetails: undefined,
      };
    default:
      return state;
  }
}
