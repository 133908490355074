import React, { useEffect, useRef } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { VisitListSkeletonStyles } from "../styles/visitListSkeletonStyles";

const VisitListSkeleton = () => {
  const classes = VisitListSkeletonStyles();
  const scrollToRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToRef) {
      scrollToRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, []);

  return (
    <div>
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
      <Skeleton
        ref={scrollToRef}
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
    </div>
  );
};

export default VisitListSkeleton;
