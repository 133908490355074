import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { IVisitDetailsState } from "../../../state/reducers/VisitDetails-Reducer";

export const SelectedVisitId = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.selectedVisitId;
  }
);
