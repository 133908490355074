import { IAppealReferenceQuestion } from "../../../state/types";
import { SetStateAction, Dispatch } from "react";
import { i18n } from "../../../localizations";
import { AppealsStyles } from "../styles";
import AppealQuestionSelect from "./appealQuestionSelect";

interface Props {
  appealReferenceQuestions: IAppealReferenceQuestion[];
  selectedQuestions: string[];
  setSelectedQuestions: Dispatch<SetStateAction<string[]>>;
  setSelectedQuestionIds: Dispatch<SetStateAction<number[]>>;
  isInvalid: boolean;
  validate: () => void;
}
const AppealQuestionPicker = (props: Props) => {
  const appealsClasses = AppealsStyles();
  const {
    appealReferenceQuestions,
    selectedQuestions,
    setSelectedQuestionIds,
    setSelectedQuestions,
  } = props;

  return (
    <>
      <AppealQuestionSelect
        availableQuestions={appealReferenceQuestions.map((x) => x.question)}
        selectedQuestions={selectedQuestions}
        onApply={(questions) => {
          props.validate();
          setSelectedQuestions(questions);
          setSelectedQuestionIds(
            appealReferenceQuestions
              .filter((x) => questions.some((y) => y === x.question))
              .map((x) => x.questionId)
          );
        }}
      />

      {props.isInvalid && (
        <span className={`${appealsClasses.errorSpan}`}>
          {i18n.translate("APPEALS_VALIDATION_QUESTIONS")}
        </span>
      )}
    </>
  );
};

export default AppealQuestionPicker;
