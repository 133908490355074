import { withPendingHandling } from "../../../utils/pendingHandler";
import { Button, IconButton } from "@material-ui/core";
import { BrandSelectionFormStyles } from "../../userToolbar/styles/brandSelectionFormStyles";
import AttachFileIcon from "@material-ui/icons/AttachFile";

type ButtonProps = {
  name: string;
  variant?: "text" | "outlined" | "contained";
  promise: () => Promise<void>;
  isPending: boolean;
  setIsPending?: React.Dispatch<React.SetStateAction<boolean>>;
  validate?: () => void;
  associatedFormInputsValid?: boolean;
  className?: string;
  showIcon?: boolean;
  IconButton?: boolean;
};

const AppealsButton = (props: ButtonProps) => {
  const { name, promise, isPending, setIsPending, showIcon } = props;
  const classes = BrandSelectionFormStyles();

  return (
    <>
      {!props.IconButton && (
        <Button
          variant="contained"
          className={props.className || classes.button}
          disabled={isPending}
          onClick={() => {
            if (setIsPending) {
              withPendingHandling(setIsPending, promise);
            } else {
              promise();
            }
          }}
          startIcon={showIcon ? <AttachFileIcon fontSize={"small"} /> : null}
        >
          {name}
        </Button>
      )}
      {props.IconButton && (
        <IconButton
          className={`fak fa-regular-paperclip-circle-plus ${
            props.className || classes.button
          }`}
          disabled={isPending}
          onClick={() => {
            if (setIsPending) {
              withPendingHandling(setIsPending, promise);
            } else {
              promise();
            }
          }}
          color="primary"
        />
      )}
    </>
  );
};

export default AppealsButton;
