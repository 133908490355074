import { SagaMiddleware } from "redux-saga";
import { call, put, takeLeading } from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import {
  fetchUserProfileError,
  fetchUserProfilePending,
  fetchUserProfileSuccess,
  SWITCH_USERPROFILE,
  ISwitchUserProfile,
  SWITCH_USERBRANDS,
  ISwitchUserBrands,
  ICheckAppVersion,
  CHECK_APPVERSION,
  userProfileSessionExpired,
} from "../../state/actions/UserProfile-Actions";
import { IAppSourceVersion } from "../types/UserProfile";
import { appVersionApi } from "../api/UserProfile-API";
import { Logging, SeverityLevel } from "../../utils/logging";
import {
  UserProfileAppVersion,
  getUserProfileAsync,
} from "../functions/userProfileFunctions";

export function registerUserProfileSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLeading(SWITCH_USERPROFILE, switchUserProfile);
  });
  sagaMiddleware.run(function* () {
    yield takeLeading(SWITCH_USERBRANDS, switchUserBrands);
  });
  sagaMiddleware.run(function* () {
    yield takeLeading(CHECK_APPVERSION, checkAppVersion);
  });
}

function* getUserProfile(silentRefresh = false): unknown {
  if (!silentRefresh) {
    yield put(fetchUserProfilePending());
  }

  try {
    const userId = yield call(AuthServiceInstance.getUserId);
    const userName = yield call(AuthServiceInstance.getUserName);
    const clientId = yield call(AuthServiceInstance.getClientId);

    const userProfileAppVersion: UserProfileAppVersion = yield call(
      getUserProfileAsync,
      clientId,
      userId,
      userName
    );

    yield put(
      fetchUserProfileSuccess(
        userProfileAppVersion.userProfile,
        userProfileAppVersion.appVersion
      )
    );
  } catch (e) {
    if (!silentRefresh) {
      yield put(fetchUserProfileError("Failed to fetch user profile"));
    }
  }
}

function* switchUserProfile(action: ISwitchUserProfile): unknown {
  yield call(AuthServiceInstance.setClientId, action.clientId);
  yield call(AuthServiceInstance.setUserBrands, []);

  if (action.managerId) {
    yield call(AuthServiceInstance.setUserId, action.managerId);
  }

  if (action.callback) {
    yield call(action.callback);
  }
}

function* switchUserBrands(action: ISwitchUserBrands): unknown {
  yield call(AuthServiceInstance.setUserBrands, action.brands);
  yield call(getUserProfile, true);
}

function* checkAppVersion(action: ICheckAppVersion): unknown {
  try {
    const appVersion: IAppSourceVersion = (yield call(appVersionApi)).data;
    if (action.callback) {
      yield call(action.callback, appVersion);
    }
  } catch (e: any) {
    Logging.captureError("Saga:checkAppVersion", e, SeverityLevel.Information);

    if (e.response?.status === 401) {
      yield put(userProfileSessionExpired());
    }
  }
}
