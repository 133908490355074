import * as pbi from "powerbi-client";

class UndefinedSlicer {
  public setSlicerState = (_: pbi.models.ISlicerState) =>
    new Promise<unknown>((resolve: (value: unknown) => void) => {
      resolve(null);
    });
}

export default UndefinedSlicer;
