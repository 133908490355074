import { useEffect, useState } from "react";
import { getAppealReviewDecisionsApi } from "../../api/Appeals-API";
import { IAppealReviewDecision } from "../../types/Appeals";

interface IProps {
    clientId: number | undefined;
}

export const useAppealReviewDecisions = (props: IProps) => {
    const [appealReviewDecsisions, setAppealReviewDecision] = useState<IAppealReviewDecision[]>([]);
    useEffect(() => {
        if (props.clientId) {
            getAppealReviewDecisionsApi(props.clientId).then(response => {
                setAppealReviewDecision(response.data.sort((a, b) => a.displayOrder - b.displayOrder));
            })
        }
    }, [setAppealReviewDecision, props.clientId]);

    return appealReviewDecsisions;
}