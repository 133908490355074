import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialogText: {
    color: theme.palette.text.primary,
  },
  email: {
    fontWeight: 600,
  },
  flexList: {
    display: "flex",
    flexWrap: "wrap",
  },
  wideDialog: {
    width: "90%",
  },
}));

export const GuestMessageConfirmDialogStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
