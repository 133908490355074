import { LinearProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVisitConversationContext,
  sendConversationMessage,
} from "../../state/actions/Conversations-Actions";
import { getconversationVisitsAPI } from "../../state/api/Conversations-API";
import { useClientId } from "../../state/hooks/clients/useClientId";
import { ConversationVisitState } from "../../state/reducers/Conversations-Reducer";
import { ConversationVisits } from "../../state/selectors/Conversations-Selectors";
import { ConversationVisit } from "../../state/types/Conversations";
import ConversationContent from "../conversationsViews/components/conversationContent";
import { ConversationVisitMessages } from "../conversationsViews/components/messages";
import { ConversationsViewStyles } from "../conversationsViews/styles";
import { RelatedConversations } from "./components";

type Props = {
  visitId: number | undefined;
  isMobileView: boolean;
};

const ConversationVisitView = (props: Props) => {
  const conversationsViewStyles = ConversationsViewStyles();
  const conversationVisits = useSelector(ConversationVisits);
  const dispatch = useDispatch();
  const clientId = useClientId();

  const [conversationVisit, setConversationVisit] = useState<
    ConversationVisitState | undefined
  >(undefined);
  const [relatedConversationVisits, setRelatedConversationVisits] = useState<
    ConversationVisit[]
  >([]);

  const [enableSendingFromThisVisit, setEnableSendingFromThisVisit] =
    useState<boolean>(true);

  useEffect(() => {
    if (conversationVisit) {
      if (conversationVisit.visit.visitId !== props.visitId) {
        setConversationVisit(undefined);
        setRelatedConversationVisits([]);
      } else {
        getconversationVisitsAPI(
          clientId,
          conversationVisit.conversation.conversationId
        ).then((x) => setRelatedConversationVisits(x.data));
      }
    }

    if (props.visitId) {
      const cachedConversationVisit = conversationVisits.find(
        (x) => x.visit.visitId === props.visitId
      );

      if (!cachedConversationVisit) {
        dispatch(fetchVisitConversationContext(props.visitId));
      }

      if (cachedConversationVisit) {
        setConversationVisit(cachedConversationVisit);
      }
    }
  }, [conversationVisits, props.visitId]);

  useEffect(() => {
    if (conversationVisit && relatedConversationVisits.length === 0) {
      getconversationVisitsAPI(
        clientId,
        conversationVisit.conversation.conversationId
      ).then((x) => setRelatedConversationVisits(x.data));
    }

    if (conversationVisit && relatedConversationVisits.length > 0) {
      setEnableSendingFromThisVisit(
        conversationVisit.visit.visitId ===
          Math.max(...relatedConversationVisits.map((x) => x.visitId))
      );
    }
  }, [conversationVisit, relatedConversationVisits]);

  return !conversationVisit ? (
    <LinearProgress color="primary" />
  ) : (
    <div className={conversationsViewStyles.container}>
      <div className={conversationsViewStyles.conversationsContainer}>
        <ConversationContent
          context={conversationVisit}
          isMobileView={props.isMobileView}
          messages={() => (
            <ConversationVisitMessages conversationVisit={conversationVisit} />
          )}
          sendConversationMessage={(commentText, selectedFile) =>
            dispatch(
              sendConversationMessage(
                commentText,
                selectedFile,
                conversationVisit.conversation.conversationId,
                conversationVisit.visit.visitId
              )
            )
          }
          doNotShowSendMessageReason={
            !enableSendingFromThisVisit
              ? "Cannot send message for this visit. To respond, go to the latest visit for this conversation"
              : undefined
          }
        />
      </div>
      <div className={conversationsViewStyles.relatedConversationsContainer}>
        <RelatedConversations
          conversationVisit={conversationVisit}
          relatedConversationVisits={relatedConversationVisits}
        />
      </div>
    </div>
  );
};

export default ConversationVisitView;
