import React from "react";
import VisitReportAutoDownloader from "../components/visitReportAutoDownloader/visitReportAutoDownloader";

const VisitReportDownload = () => {
  return (
    <>
      <VisitReportAutoDownloader />
    </>
  );
};

export default VisitReportDownload;
