import { useSelector, useDispatch } from "react-redux";
import { AppVersionSelector } from "./state/selectors";
import { Icon, Button } from "@material-ui/core";
import i18n from "../../localizations/i18n";
import { SessionManagerStyles } from "./styles/sessionManagerStyles";
import { versionUpdateAccepted } from "../../state/actions/HubVersionUpdate-Actions";

const SessionManager = () => {
  const classes = SessionManagerStyles();
  const dispatch = useDispatch();

  const updateState = useSelector(AppVersionSelector);

  const messageText = () => {
    if (updateState.newVersionIncoming && !updateState.newVersionAvailable) {
      return i18n.translate("SESSION_MANAGER_Incoming_update_message");
    }
    return i18n.translate("SESSION_MANAGER_Refresh_Message");
  };

  const containerBackgroundColour = () => {
    if (updateState.newVersionIncoming && !updateState.newVersionAvailable) {
      return "amber";
    }
    return "green";
  };

  const refreshButtonClicked = () => {
    dispatch(versionUpdateAccepted());
    setTimeout(() => {
      window.location.href = "/reset-session";
    }, 100);
  };

  return (
    <div id="session-manager">
      {(updateState.newVersionIncoming || updateState.newVersionAvailable) && (
        <div
          className={`${classes.popupContainer} ${containerBackgroundColour()}`}
        >
          <div className={classes.popupContent}>
            <Icon className={`${classes.icon} fas fa-exclamation-circle`} />
            <span className={classes.message}>{messageText()}</span>
            {updateState.newVersionAvailable && (
              <Button
                className={classes.refreshButton}
                variant="outlined"
                onClick={refreshButtonClicked}
              >
                {i18n.translate("SESSION_MANAGER_Refresh_Button")}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionManager;
