import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import { IStateObserver, IStateObserverProps } from "./stateObserverHooks/IStateObserver";
import AnimatedHgemHands from "../_common/branding/animatedHgemHands";
import useReportConfigurationObserver from "./stateObserverHooks/useReportConfigurationObserver";
import useUserProfileObserver from "./stateObserverHooks/useUserProfileObserver";
import useFilterOptionsObserver from "./stateObserverHooks/useFilterOptionsObserver";
import useFilterSetsObserver from "./stateObserverHooks/useFilterSetsObserver";
import { Redirect } from "react-router-dom";
import { routes } from "../../navigation/routes";
import { useDispatch } from "react-redux";
import { resetPersistedStatePendingFlag } from "../../state/actions/Application-Actions";
import polly from "polly-js";
import { Logging, SeverityLevel } from "../../utils/logging";
import { AuthServiceInstance } from "../../authentication/authService";

interface IProps {
  children: React.ReactElement;
}

const oneMinuteIntervalMilliseconds = 1000 * 60;

enum InitialisationState {
  unknown,
  uninitialised,
  initialisationComplete,
  initialisationError,
}

const PreLoad = (props: IProps) => {
  const dispatch = useDispatch();

  const [initialisaionState, setInitialisationState] =
    useState<InitialisationState>(InitialisationState.unknown);

  const userProfileObserver = useUserProfileObserver();
  const reportConfigurationObserver = useReportConfigurationObserver();
  const filterOptionsObserver = useFilterOptionsObserver();
  const filterSetsObserver = useFilterSetsObserver();

  const observers: Array<IStateObserver> = useMemo(
    () => [
      userProfileObserver,
      reportConfigurationObserver,
      filterOptionsObserver,
      filterSetsObserver,
    ],
    [
      userProfileObserver,
      reportConfigurationObserver,
      filterOptionsObserver,
      filterSetsObserver,
    ]
  );

  const resolveStateObserverPropsAsync = async () => {
    let clientId: number, userId: number, userName: string;

    do {
      console.debug("loading client id");
      clientId = await AuthServiceInstance.getClientId();
    } while(!clientId)
    
    do {
      console.debug("loading user id");
      userId = await AuthServiceInstance.getUserId();
    } while(!userId)

    do {
      console.debug("loading user name");
      userName = await AuthServiceInstance.getUserName();
    } while (!userName);

    const result: IStateObserverProps = {
      clientId,
      userId,
      userName
    }

    return result;
  }

  useEffect(() => {
    const processInitialRender = () => {
      dispatch(resetPersistedStatePendingFlag());
      setInitialisationState(InitialisationState.uninitialised);
    };

    if (initialisaionState === InitialisationState.unknown) {
      processInitialRender();
    }
  }, [dispatch, initialisaionState]);

  useEffect(() => {
    const initialiseObserversAsync = async () => {
      const stateObserverProps = await resolveStateObserverPropsAsync();

      const uninitialisedObservers = observers.filter((x) => !x.isInitialised);

      if (uninitialisedObservers.length > 0) {
        for (const observer of uninitialisedObservers) {
          console.debug(`initializing ${observer.name}`);

          try {
            await polly()
            .logger(error => {
              Logging.captureError(observer.name, error, SeverityLevel.Critical);
            })
            .waitAndRetry(3)
            .executeForPromise(() => observer.initialiseState(stateObserverProps));
          } catch {
            setInitialisationState(InitialisationState.initialisationError);
          }
        }
      }

      const erroredObservers = observers.some((x) => x.hasErrors);

      if (erroredObservers) {
        setInitialisationState(InitialisationState.initialisationError);
      } else {
        setInitialisationState(InitialisationState.initialisationComplete);
      }
    };

    if (initialisaionState === InitialisationState.uninitialised) {
      initialiseObserversAsync();
    }
  }, [initialisaionState, observers]);

  useEffect(() => {
    let asyncRefreshInterval: NodeJS.Timeout | undefined = undefined;

    if (initialisaionState === InitialisationState.initialisationComplete) {
      asyncRefreshInterval = setInterval(async () => {
        const stateObserverProps = await resolveStateObserverPropsAsync();

        for (let i = 0; i < observers.length; i++) {
          const observer = observers[i];

          const requiresRefresh = await observer.stateRequiresRefresh(stateObserverProps);
          
          if (requiresRefresh) {
            await observer.refreshState(stateObserverProps);
          }
        }
      }, oneMinuteIntervalMilliseconds);
    }

    return () => {
      if (asyncRefreshInterval) {
        clearInterval(asyncRefreshInterval);
      }
    };
  }, [initialisaionState, observers]);

  return (
    <>
      {initialisaionState === InitialisationState.initialisationComplete &&
        props.children}

      {initialisaionState === InitialisationState.initialisationError && (
        <Redirect to={routes.error} />
      )}

      {initialisaionState === InitialisationState.uninitialised && (
        <div data-message="pre-load">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <Grid item>
              <AnimatedHgemHands />
            </Grid>
          </Grid>
          {observers.map((o) => {
            return (
              <input
                key={o.name}
                type="hidden"
                value={`${o.name}${!o.isInitialised && "...Loading"}${
                  o.isInitialised && "...Done"
                }`}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default PreLoad;
