import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import { IGuestDetailsState } from "../../../state/reducers/GuestDetails-Reducer";
import { IVisitDetailsState } from "../../../state/reducers/VisitDetails-Reducer";
import { VoucherState } from "../../../state/reducers/Vouchers-Reducer";
import { IUserProfileState } from "../../../state/reducers/UserProfile-Reducer";

export const SelectedCase = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    const caseId = state.taskCentreCases.selectedCaseId;
    const cases = state.taskCentreCases.cases;

    return caseId ? cases.find((x) => x.id === caseId) : undefined;
  }
);

export const SelectedVisitId = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.selectedVisitId;
  }
);

export const GuestDetailsByVisitId = createSelector(
  (state: RootState) => state.GuestDetailsReducer,
  (_: RootState, visitId: number | undefined) => visitId,
  (state: IGuestDetailsState, visitId: number | undefined) => {
    if (visitId === state.visitId && state.guestDetails) {
      return state.guestDetails.name.fullName ? state.guestDetails : undefined;
    }

    return undefined;
  }
);

export const CurrentGuestDetailsId = createSelector(
  (state: RootState) => state.GuestDetailsReducer,
  (state: IGuestDetailsState) => {
    return state.visitId;
  }
);

export const GuestDetailsPending = createSelector(
  (state: RootState) => state.GuestDetailsReducer,
  (state: IGuestDetailsState) => {
    return state.pending;
  }
);

export const GuestDetailsError = createSelector(
  (state: RootState) => state.GuestDetailsReducer,
  (state: IGuestDetailsState) => {
    return state.error;
  }
);

export const GetActiveNotificationById = createSelector(
  (state: RootState) => state.AppNotificationReducer,
  (_: RootState, id: string | undefined) => id,
  (state, id) => {
    return id
      ? state.notifications.find((x) => x.Identifier === id)
      : undefined;
  }
);

export const VisitTasksPending = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.pending;
  }
);

export const VoucherConfiguration = createSelector(
  (state: RootState) => state.VouchersReducer,
  (state: VoucherState) => {
    return state.voucherConfiguration;
  }
);

export const usersCurrentBrands = createSelector(
  (state: RootState) => state.UserProfileReducer,
  (state: IUserProfileState) => {
    return state.userProfile?.availableBrands;
  }
);
