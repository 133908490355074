export enum ConversationSourceEnum {
    Sms = 1,
    WhatsApp = 2,
    Messenger = 3,
    Email = 4,
    Gbm = 5
}

export type ConversationsFeatureEnabled = {
    isEnabled: boolean
}

export type ConversationsTokenPaged<TItem> = {
    items: TItem[],
    nextPageToken: number
}

export type Conversation = {
    conversationId: number;
    branchId: number;
    source: ConversationSourceEnum;
    sender: string;
    mostRecentMessageSender: string;
    mostRecentMessageCreatedDate: Date;
    mostRecentMessageText: string | undefined;
    mostRecentMessageIsInbound: boolean;
    mostRecentInboundMessageId: number | undefined;
    readCursorMessageId: number | undefined;
    visits: ConversationVisit[]
};

export type ConversationMessage = {
    conversationMessageId?: number | undefined;
    isInbound: boolean;
    sender?: string | undefined;
    messageText: string | undefined;
    createdDate?: Date | undefined;
    medias?: ConversationMessageMedia[] | undefined;
    createdByManagerId: number | undefined;
    senderCorrelationGuid?: string | undefined
};

export type ConversationMessageMedia = {
    conversationMessageMediaId: number;
    filename?: string | undefined;
    contentType: string;
    contentSize: number;
};

export type ConversationMessageMediaMetadata = {
    contentType: string,
    contentLength: number,
    filename: string | undefined
}

export type ConversationSourceConfig = {
    source: ConversationSourceEnum,
    replyTimeLimitHours: number | undefined,
    mediaSizeLimitMegabytes: number | undefined,
    mediaAllowedFileExtensions: string[] | undefined,
}

export type ConversationVisit = {
    visitId: number,
    startDate: Date,
    endDate: Date | undefined,
    firstMessageText: string | undefined
}

export type VisitConversationContext = {
    visit: ConversationVisit,
    conversation: Conversation
}

export type ConversationListFilters = {
    conversationId: number | undefined
}

export type UnreadConversations = {
    unreadCount: number
}