import { darken, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    minWidth: "280px",
  },
  scrollingMenu: {
    height: "300px",
    overflowX: "scroll",
  },
  menuItemGroup: {
    display: "inline-block",
    padding: "10px 0",
    width: "calc(100% - 2px)",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderLeft: "none",
    borderRight: "none",
    color: darken(hgemColours.LimeGreen, 0.2),
    textAlign: "center",
  },
  menuItemContent: {
    padding: "0px",
    cursor: "pointer",
    minHeight: "auto",

    "& label": {
      width: "100%",
      display: "inline-block",
      padding: "4px 10px 4px 36px",
      whiteSpace: "normal",
    },

    "& input": {
      opacity: 0,
      height: 0,
      width: 0,
      position: "absolute",

      "&:checked": {
        "& ~ span": {
          border: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary.main,
          backgroundImage:
            "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV+/qErFwSIiDkGqkwVREUepYhEslLZCqw4ml35Bk4YkxcVRcC04+LFYdXBx1tXBVRAEP0CcHJ0UXaTE/yWFFjEeHPfj3b3H3TvA26gwxfBPAIpq6ql4TMjmVoXgKwIYQDdG4BeZoSXSixm4jq97ePh6F+VZ7uf+HL1y3mCARyCeY5puEm8Qz2yaGud94jAriTLxOfG4ThckfuS65PAb56LNXp4Z1jOpeeIwsVDsYKmDWUlXiKeJI7KiUr4367DMeYuzUqmx1j35C0N5dSXNdZrDiGMJCSQhQEINZVRgIkqrSoqBFO3HXPxDtj9JLolcZTByLKAKBaLtB/+D390ahalJJykUAwIvlvUxCgR3gWbdsr6PLat5AviegSu17a82gNlP0uttLXIE9G0DF9dtTdoDLneAwSdN1EVb8tH0FgrA+xl9Uw7ovwV61pzeWvs4fQAy1NXyDXBwCIwVKXvd5d1dnb39e6bV3w9DZnKUvJuKyAAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAN1wAADdcBQiibeAAAAKJJREFUOMvFkzsOgzAQBQcKikDDvXKA3JA0KSIkOqQIrpTUk8ZWiMVHhCJPsmR7981aK2+mckQ5B/ULoAAaoAdq1D2rUFs/GvKdlW/AeXL33FP57rdGtUKt1V5tQuLWs1UfaqmCOkwCbQJZNUdAlyREyKY5AsoQSCGb5ghAPYU+LGlUq7kGTw9LkEVzCpiDrJpVsplhKoFr2F+A19rvyv4+jW8RC/VbiS3ATgAAAABJRU5ErkJggg==')",
        },
      },

      "&:hover": {
        "& span": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  checkmark: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "16px",
    width: "16px",
    border: `2px solid ${theme.palette.grey[500]}`,
    borderRadius: "2px",
    margin: "6px",

    "&:after": {
      content: "",
      position: "absolute",
      display: "none",
    },
  },
  actions: {
    margin: "10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  subTasks: {
    border: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: "20px",
  },
  status: {
    float: "left",
    color: theme.palette.grey[500],
  },
  optionSuffix: {
    color: theme.palette.grey[500],
    float: "right",
  },
}));

export const GenericMultiSelectStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
