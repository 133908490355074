import React, { useEffect, useState } from "react";
import i18n from "../../../localizations/i18n";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterWrapper from "./filterWrapper";
import {
  TextField,
  Button,
  useTheme,
  makeStyles,
  Chip,
  FormControlLabel,
  Switch,
  Theme,
} from "@material-ui/core";
import { CaseInsensitiveSort } from "../../../utils/sortnig";
import DisabledFilter from "./disabledFilter";

interface IProps {
  availableQuestions: string[];
  availableQuestionSubjects: string[];
  selectedQuestions: string[];
  selectedQuestionSubjects: string[];
  onApply: (questions: string[], questionSubjects: string[]) => void;
  emptySelectionText?: string | undefined;
  disableAutoSorting?: boolean | false;
}

enum PickerType {
  Questions = 0,
  QuestionSubjects = 1,
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    margin: "10px",

    "& label": {
      color: theme.palette.grey[300],
    },
  },
  actions: {
    margin: "20px 10px 10px 10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  status: {
    float: "left",
    color: theme.palette.grey[500],
  },
  switchLabel: {
    marginBottom: "20px",

    "& .MuiFormControlLabel-label": {
      color: theme.palette.grey[500],
    },
  },
}));

const QuestionPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);

  const [questionSubjectsAreAvailable, setQuestionSubjectsAreAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    setQuestionSubjectsAreAvailable(props.availableQuestionSubjects.length > 0);
  }, [props.availableQuestionSubjects]);

  const [initialPickerType, setInitialPickerType] = useState<PickerType>(
    PickerType.Questions
  );

  useEffect(() => {
    setInitialPickerType(
      questionSubjectsAreAvailable &&
        props.selectedQuestionSubjects.length >= 0 &&
        props.selectedQuestions.length === 0
        ? PickerType.QuestionSubjects
        : PickerType.Questions
    );
  }, [
    questionSubjectsAreAvailable,
    props.selectedQuestionSubjects,
    props.selectedQuestions,
  ]);

  const [selectedPickerType, setSelectedPickerType] =
    useState<PickerType>(initialPickerType);

  useEffect(() => {
    setSelectedPickerType(initialPickerType);
  }, [initialPickerType]);

  const [availableValues, setAvailableValues] = useState<string[]>([]);

  useEffect(() => {
    setAvailableValues(
      selectedPickerType === PickerType.Questions
        ? props.availableQuestions
        : props.availableQuestionSubjects
    );
  }, [
    selectedPickerType,
    props.availableQuestions,
    props.availableQuestionSubjects,
  ]);

  const [selectedQuestionValues, setSelectedQuestionValues] = useState<
    string[]
  >([]);

  useEffect(() => {
    const selectedValues =
      selectedPickerType === PickerType.Questions
        ? props.selectedQuestions
        : props.selectedQuestionSubjects;

    setSelectedQuestionValues(selectedValues);
  }, [
    selectedPickerType,
    props.selectedQuestions,
    props.selectedQuestionSubjects,
  ]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const applySelection = () => {
    if (selectedPickerType === PickerType.Questions) {
      props.onApply(selectedQuestionValues, []);
    } else {
      props.onApply([], selectedQuestionValues);
    }

    setIsOpen(false);
  };

  const cancelSelection = () => {
    resetDefaultSelection(initialPickerType === PickerType.Questions);
    setSelectedPickerType(initialPickerType);
    setIsOpen(false);
  };

  const onSelectionChange = (_e: unknown, value: string[]) => {
    setSelectedQuestionValues(value);
  };

  const swicthPickerType = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    resetDefaultSelection(checked);
    setSelectedPickerType(
      checked ? PickerType.Questions : PickerType.QuestionSubjects
    );
  };

  const resetDefaultSelection = (showingFullQuestions: boolean): void => {
    const resetSelections = showingFullQuestions
      ? props.selectedQuestions
      : props.selectedQuestionSubjects;
    setSelectedQuestionValues(resetSelections);
  };

  const getSelectionHint = () => {
    if (selectedQuestionValues.length === 0) {
      return (
        props.emptySelectionText ??
        i18n.translate("QUESTIONS_PICKER_All_Selected")
      );
    } else if (selectedQuestionValues.length === 1) {
      return selectedQuestionValues[0].length > 18
        ? `${selectedQuestionValues[0].substring(0, 15)}...`
        : selectedQuestionValues[0];
    } else {
      return `${selectedQuestionValues.length} ${i18n.translate(
        "QUESTIONS_PICKER_Selected"
      )}`;
    }
  };

  const buildTooltipContent = (): string => {
    if (selectedQuestionValues && selectedQuestionValues.length > 0) {
      const tooltipText = `${selectedQuestionValues.length} ${i18n.translate(
        "QUESTIONS_PICKER_Selected_Tooltip"
      )} `;
      let questions = (
        props.disableAutoSorting
          ? selectedQuestionValues
          : selectedQuestionValues.sort(CaseInsensitiveSort)
      ).join(", ");

      if (questions.length > 150) {
        questions = questions.substring(0, 150) + "...";
      }

      return tooltipText + questions;
    } else {
      return "";
    }
  };

  return (
    <>
      {availableValues.length > 0 && (
        <FilterWrapper
          label={i18n.translate("QUESTIONS_PICKER_Questions")}
          selectionHint={getSelectionHint()}
          isOpen={isOpen}
          toggleOpenState={handleToggleOpen}
          tooltip={buildTooltipContent()}
          isDisabled={availableValues.length === 0}
        >
          <div className={classes.container}>
            {questionSubjectsAreAvailable && (
              <div>
                <FormControlLabel
                  className={classes.switchLabel}
                  control={
                    <Switch
                      checked={selectedPickerType === PickerType.Questions}
                      onChange={swicthPickerType}
                      name="question-picker-type-swicth"
                      color="primary"
                    />
                  }
                  label={i18n.translate("QUESTIONS_PICKER_Show_Full_Text")}
                />
              </div>
            )}

            <Autocomplete
              id="questions-filter-options"
              data-testid={"questions-filter-options"}
              multiple
              filterSelectedOptions
              value={selectedQuestionValues}
              options={
                props.disableAutoSorting
                  ? availableValues
                  : availableValues.sort()
              }
              getOptionLabel={(option: string) => option}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n.translate("QUESTIONS_PICKER_Selected_Questions")}
                  variant="outlined"
                  placeholder={i18n.translate("QUESTIONS_PICKER_Search")}
                />
              )}
              getOptionSelected={(option: string) =>
                selectedQuestionValues.indexOf(option) > -1
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="default"
                    label={option}
                    title={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={onSelectionChange}
            />
          </div>
          <div className={classes.actions}>
            <div className={classes.status}>{`${
              selectedQuestionValues.length
            } ${i18n.translate("QUESTIONS_PICKER_Selected")}`}</div>
            <Button
              onClick={applySelection}
              variant="contained"
              color="primary"
            >
              {i18n.translate("QUESTIONS_PICKER_Apply")}
            </Button>
            <Button onClick={cancelSelection}>
              {i18n.translate("QUESTIONS_PICKER_Cancel")}
            </Button>
          </div>
        </FilterWrapper>
      )}
      {availableValues.length === 0 && (
        <DisabledFilter label={i18n.translate("QUESTIONS_PICKER_Questions")} />
      )}
    </>
  );
};

export default QuestionPicker;
