import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import { SearchTextInputStyles } from "./styles/searchTextInputStyles";

interface IProps {
  label: string;
  searchPlaceholder: string | undefined;
  onSearch: (searchValue: string) => void;
  onClearSearch: () => void;
}

const SearchTextInput = (props: IProps) => {
  const classes = SearchTextInputStyles();
  const [filterText, setFilterText] = useState("");

  const updateFilterText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue = event.target.value;
    if (filterValue.length === 0) {
      clearFilterText();
    } else {
      setFilterText(filterValue);
    }
  };

  const applySearchByKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const enterKeyCode = 13;
    if (event.keyCode === enterKeyCode) {
      applySearch();
    }
  };

  const clearFilterText = () => {
    setFilterText("");
    props.onClearSearch();
  };

  const applySearch = () => {
    props.onSearch(filterText);
  };

  return (
    <FormControl className={classes.filterInput}>
      {props.label.length > 0 && (
        <InputLabel
          className={classes.filterLabel}
          htmlFor="node-filter-intput"
        >
          {props.label}
        </InputLabel>
      )}
      <Input
        id="node-filter-intput"
        type="text"
        className={classes.textInput}
        placeholder={
          props.searchPlaceholder ? props.searchPlaceholder : "Filter Options"
        }
        value={filterText}
        onChange={updateFilterText}
        onKeyUp={applySearchByKeyPress}
        endAdornment={
          <>
            <InputAdornment position="end">
              <IconButton size="small" title="Search" onClick={applySearch}>
                <Search></Search>
              </IconButton>
            </InputAdornment>

            {filterText.length > 0 && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  title="Clear Filter"
                  onClick={clearFilterText}
                >
                  <Close></Close>
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
      />
    </FormControl>
  );
};

export default SearchTextInput;
