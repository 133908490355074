import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
  availableQuestionnaireTypes: string[];
  selectedQuestionnaireypes: string[];
  applySelectionCallback: (selections: string[]) => void;
}

const QuestionnaireTypePicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuestionnaireTypes, setSelectedQuestionnaireTypes] = useState(
    [] as string[]
  );

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedQuestionnaireTypes.length === 0 ||
      selectedQuestionnaireTypes.length ===
        props.availableQuestionnaireTypes.length
    ) {
      selectionText = i18n.translate("QUESTIONNAIRE_TYPE_PICKER_All_Selected");
    } else if (selectedQuestionnaireTypes.length === 1) {
      selectionText = selectedQuestionnaireTypes[0];
    } else {
      selectionText = `${selectedQuestionnaireTypes.length} ${i18n.translate(
        "QUESTIONNAIRE_TYPE_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    return selectedQuestionnaireTypes.join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    props.applySelectionCallback(selectedOptions);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedQuestionnaireTypes(props.selectedQuestionnaireypes);
  }, [setSelectedQuestionnaireTypes, props.selectedQuestionnaireypes]);

  return (
    <FilterWrapper
      label={i18n.translate("QUESTIONNAIRE_TYPE_PICKER_Questionnaire_Type")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "QUESTIONNAIRE_TYPE_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={props.availableQuestionnaireTypes}
        selectedOptions={selectedQuestionnaireTypes}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
        applyLocalisationToOptions={false}
      />
    </FilterWrapper>
  );
};

export default QuestionnaireTypePicker;
