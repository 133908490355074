import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FilterWrapper from "./filterWrapper";
import { i18n } from "../../../localizations";

interface IProps {
  selectedVisitId: number | undefined;
  applySelectionCallback: (visitId: number | undefined) => void;
  minInputLength?: number | undefined;
  label?: string | undefined;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  innerContainer: {
    width: "250px",
    padding: "10px",
    display: "inline-block",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  actions: {
    margin: "20px 10px 10px 10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  input: {
    width: "100%",
    float: "left",

    "& label": {
      color: theme.palette.grey[400],
    },
  },
}));

const VisitIdPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedVisitId, setSelectedVisitId] = useState("");
  const [isValidSearch, setIsValidSearch] = useState(true);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = i18n.translate("VISIT_ID_PICKER_No_Id_Set");
    if (props.selectedVisitId) {
      selectionText = props.selectedVisitId.toString();
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    let tooltipText = i18n.translate("VISIT_ID_PICKER_No_Id_Set");
    if (props.selectedVisitId) {
      tooltipText = `${props.label ?? i18n.translate(
        "VISIT_ID_PICKER_VisitId"
      )}: ${props.selectedVisitId.toString()}`;
    }

    return tooltipText;
  };

  const applySelection = () => {
    const visitId = Number(selectedVisitId);
    if (selectedVisitId.length === 0 || isNaN(visitId)) {
      props.applySelectionCallback(undefined);
    } else {
      props.applySelectionCallback(visitId);
    }

    setIsOpen(false);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();

    const numericValue = Number(value);
    isNaN(numericValue) && value.length > 0
      ? setIsValidSearch(false)
      : setIsValidSearch(true);

    setSelectedVisitId(value);
  };

  const clearSearch = () => {
    setSelectedVisitId("");
  };

  const cancelSelection = () => {
    setSelectedVisitId(
      props.selectedVisitId ? props.selectedVisitId.toString() : ""
    );
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedVisitId(
      props.selectedVisitId ? props.selectedVisitId.toString() : ""
    );
  }, [props.selectedVisitId]);

  return (
    <FilterWrapper
      label={props.label ?? i18n.translate("VISIT_ID_PICKER_VisitId")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      highlight={Boolean(props.selectedVisitId)}
    >
      <div className={classes.outerContainer}>
        <div className={classes.innerContainer}>
          <TextField
            label={props.label ?? i18n.translate("VISIT_ID_PICKER_VisitId")}
            value={selectedVisitId}
            onChange={handleValueChange}
            variant="outlined"
            size="small"
            className={classes.input}
            error={!isValidSearch && selectedVisitId.length > 0}
            inputProps={{ maxLength: 9 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={selectedVisitId.length === 0}
                  size="small"
                  title={i18n.translate("VISIT_ID_PICKER_ClearSearch")}
                  onClick={clearSearch}
                >
                  <CloseIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <div className={classes.actions}>
          <Button
            onClick={applySelection}
            variant="contained"
            color="primary"
            disabled={
              (!isValidSearch && selectedVisitId.length > 0) ||
              (selectedVisitId.length > 0 && selectedVisitId.length < (props.minInputLength ?? 5))
            }
          >
            {i18n.translate("VISIT_ID_PICKER_Apply")}
          </Button>
          <Button onClick={cancelSelection}>
            {i18n.translate("VISIT_ID_PICKER_Cancel")}
          </Button>
        </div>
      </div>
    </FilterWrapper>
  );
};

export default VisitIdPicker;
