const en = {
  GLOBAL_Menu_Open: "Open Menu",
  GLOBAL_Menu_Close: "Close Menu",
  GLOBAL_Logout: "Logout",
  GLOBAL_BROWSER_TITLE: "HGEM Hub:",
  GLOBAL_HGEM: "HGEM",

  USER_MENU_MY_ACCOUNTS: "My Accounts",

  COMMON_BUTTON_TEXT_submit: "Submit",
  COMMON_TEXT_CATEGORY: "category",   
  COMMON_TEXT_REVIEW_DECISION: "review decision",
  COMMON_BUTTON_TEXT_next: "Review & send",
  COMMON_CURRENCY_SYMBOL_UK: "£",
  COMMON_CHARACTERS_REMAINING: " characters remaining",
  COMMON_DATE_AND_TIME_FORMAT: "ddd DD MMM YY",
  COMMON_24_HR_TIME_FORMAT: "HH:mm",

  ACCOUNT_SELECTION_SwitchAccount: "Switch Account",
  ACCOUNT_SELECTION_ToolbarButton_Title: "My Accounts and Brands",
  ACCOUNT_SELECTION_DialogTitle_Accounts: "My Accounts:",
  ACCOUNT_SELECTION_DialogTitle_Brands: "My Brands:",
  ACCOUNT_SELECTION_DialogHint_Accounts:
    "You can switch between your available accounts by selecting one of the options below.",
  ACCOUNT_SELECTION_DialogHint_Brands:
    "Please select from one of your available brands.",
  ACCOUNT_SELECTION_Apply_Brand_Selection: "Apply",
  ACCOUNT_SELECTION_Cancel_Brand_Selection: "Cancel",
  ACCOUNT_SELECTION_SelectAll: "Select All",
  ACCOUNT_SELECTION_DeselectAll: "Deselect All",
  ACCOUNT_SELECTION_SelectAll_Split: "/",

  SUPPORT_OPTION_Title: "Knowledge Base & Support",
  SUPPORT_OPTION_SupportLink: "https://support.hgem.com",

  // Report Titles: Match with report/page configuration from Hub API
  "Report: MyDashboard": "My Dashboard",
  "Report: Dashboard": "Dashboard",
  "Report: AuditOverview": "Overview",
  "Report: SelfAudits": "Self Audits",
  "Report: ProjectAudits": "Project Audits",
  "Report: DishSpecs": "Dish Specs",
  "Report: AuditQuestions": "Audit Questions",
  "Report: AuditQuestionsHeatMap": "Audit Questions Heat Map",
  "Report: AssessorComments": "Assessor Comments",
  "Report: QuestionFailures": "Question Failures Heat Map",
  "Report: AuditSections": "Audit Sections Heat Map",
  "Report: Categories": "Categories Heat Map",
  "Report: FeedbackOverview": "Feedback Overview",
  "Report: FeedbackQuestions": "Feedback Questions",
  "Report: ReviewRatings": "Review Ratings",
  "Report: NetPromoterScore": "Net Promoter Score",
  "Report: DishRatings": "Dish Ratings",
  "Report: SurveyQuestions": "Survey Questions Heat Map",
  "Report: SurveySections": "Survey Sections Heat Map",
  "Report: ReviewHeatmap": "Reviews Heat Map",
  "Report: Leaderboards": "Leaderboards",
  "Report: Benchmarking": "Benchmarking",
  "Report: TeamEngagementTracker": "Team Engagement Tracker",
  "Report: ManagementStatistics": "Visit Statistics",
  "Report: ManagementFulfilment": "Visit Fulfilment",
  "Report: TrackAndTrace": "Track and Trace",
  "Report: KeyMeasures": "Key Measures",
  "Report: KeyMeasuresBreakdown": "Key Measures Breakdown",
  "Report: RawData": "Raw Data",
  "Report: GuestContactDetails": "Guest Contact Details",
  "Report: ServerScores": "Server Scores",
  "Report: ExponeaExtract": "Exponea Extract",
  "Report: TaskStatistics": "Task Statistics",
  "Report: Sentiment": "Sentiment",
  "Report: DeliveryReviews": "Delivery Reviews",
  "Report: NPSStatistics": "NPS Statistics",
  "Report: NPSAlignment": "NPS Alignment",
  "Report: AnswerTrends": "Answer Trends",
  "Report: AuditScores": "Audit Scores",
  "Report: SurveyScores": "Survey Scores",
  "Report: Reviews": "Reviews",
  "Report: SurveyDemographics": "Survey Demographics",
  "Report: DishHeatMaps": "Dish Heat Maps",
  "Report: SubjectOpinions": "Subject Opinions",
  "Report: SentimentHeatMaps": "Net Sentiment",
  "Report: GuestComments": "Guest Comments",
  "Report: DishComments": "Dish Comments",
  "Report: AnswerAnalysis": "Answer Analysis",
  "Report: QuestionnaireSections": "Questionnaire Sections",
  "Report: QuestionCategories": "Question Categories",
  "Report: NetSentiment": "Net Sentiment",
  "Report: TeamFeedback": "Team Feedback",
  "Report: TeamFeedbackHeatMaps": "Team Feedback Heat Maps",
  "Report: BenchmarkHeatMap": "Benchmark Heat Map",
  "Report: GuestDetails": "Guest Details",
  "Report: VisitStatistics": "Visit Statistics",
  "Report: VisitFulfilment": "Visit Fulfilment",

  HOMEPAGE_Title: "What's New",

  // Components
  SESSION_MANAGER_Refresh_Message:
    "A Hub update has been released that requires you to refresh your session.",
  SESSION_MANAGER_Refresh_Button: "Refresh Session",
  SESSION_MANAGER_Incoming_update_message:
    "A New version of the hub will be released in the next 5 - 10 minutes, you will be prompted to refresh your session when the new update arrives. The refresh will cause you to log out and have to log back in so the updates can take effect.",

  PERIOD_PICKER_Preset_Selections: "Select by Preset",
  PERIOD_PICKER_OPTION_CurrentPeriod: "Current Period",
  PERIOD_PICKER_OPTION_LastCompletePeriod: "Last Complete Period",
  PERIOD_PICKER_OPTION_LastTwoCompletePeriods: "Last 2 Complete Periods",
  PERIOD_PICKER_OPTION_LastThreeCompletePeriods: "Last 3 Complete Periods",
  PERIOD_PICKER_OPTION_CurrentYearToDate: "Current Year to Date",
  PERIOD_PICKER_OPTION_PreviousYear: "Previous Year",
  PERIOD_PICKER_OPTION_Filter_Periods: "Filter Periods",

  BRAND_PICKER_LABEL: "Brands",
  BRAND_PICKER_SELECTED: "Brand(s) selected",

  SEGMENT_PICKER_LABEL: "Segments",
  SEGMENT_PICKER_SELECTED: "Segments selected",
  SEGMENT_PICKER__ALL_SELECTED: "All",
  DATE_RANGE_PICKER_Display_Format: "DD/MM/YYYY",
  DATE_RANGE_PICKER_Start_Date: "Start Date",
  DATE_RANGE_PICKER_Change_Start_Date: "Change Start Date",
  DATE_RANGE_PICKER_End_Date: "End Date",
  DATE_RANGE_PICKER_Change_End_Date: "Change End Date",
  DATE_RANGE_PICKER_Apply: "Apply",
  DATE_RANGE_PICKER_Cancel: "Cancel",

  DATE_PERIOD_PICKER_Dates: "Dates",
  DATE_PERIOD_PICKER_RelativeDate: "Relative to Today",
  DATE_PERIOD_PICKER_Custom_Date_Range: "Custom Date Range",
  DATE_PERIOD_PICKER_Reporting_Period: "Reporting Periods",
  DATE_PERIOD_PICKER_LastTwelveWeeks: "Last 12 Weeks",
  DATE_PERIOD_PICKER_LastFourWeeks: "Last 4 Weeks",
  DATE_PERIOD_PICKER_LastTwoWeeks: "Last 2 Weeks",
  DATE_PERIOD_PICKER_LastWeek: "Last 7 Days",
  DATE_PERIOD_PICKER_CurrentPeriod: "Current Period",
  DATE_PERIOD_PICKER_LastCompletePeriod: "Last Completed Period",
  DATE_PERIOD_PICKER_LastTwoCompletePeriods: "Last 2 Completed Periods",
  DATE_PERIOD_PICKER_LastThreeCompletePeriods: "Last 3 Completed Periods",
  DATE_PERIOD_PICKER_CurrentYearToDate: "Current Year to Date",
  DATE_PERIOD_PICKER_PreviousYear: "Previous Year",
  DATE_PERIOD_PICKER_Tooltip_Date_Format: "ddd DD MMM YY",
  DATE_PERIOD_PICKER_Periods_Selected: "Periods Selected:",
  DATE_PERIOD_PICKER_Num_Periods_Selected: "Period(s) Selected",
  DATE_PERIOD_PICKER_Num_Reporting_Periods_Selected: "Period(s) Selected",
  DATE_PERIOD_PICKER_Calendar: "Calendar",
  DATE_PERIOD_PICKER_Visit_Schedules: "Visit Schedules",
  DATE_PERIOD_PICKER_Cancel: "Cancel",
  DATE_PERIOD_PICKER_Which_do_I_Choose: "Which option should I use?",
  DATE_PERIOD_PICKER_Weeks_Selected: "Period(s) Selected",
  DATE_PERIOD_PICKER_Undefined_Brand_Name: "Default",

  DATE_PERIOD_PICKER_HELP_ReportingPeriod_Title: "Reporting Periods:",
  DATE_PERIOD_PICKER_HELP_VisitScheule_Title: "Visit Schedules:",
  DATE_PERIOD_PICKER_HELP_Relative_Title: "Relative to Today:",
  DATE_PERIOD_PICKER_HELP_Custom_Title: "Custom Date Range:",
  DATE_PERIOD_PICKER_HELP_ReportingPeriod_Description:
    "Quickly select a range of calendar periods, including weeks, months, and quarters.",
  DATE_PERIOD_PICKER_HELP_VisitScheule_Description:
    "Select from configured audit schedules and custom periods.",
  DATE_PERIOD_PICKER_HELP_Relative_Description:
    "Quick selection of recent date ranges, all including 'today'.",
  DATE_PERIOD_PICKER_HELP_Custom_Description:
    "Select a custom range by specifying a start and end date.",
  DATE_PERIOD_PICKER_Back: "Return to options",

  LOCATION_PICKER_All_Selected: "All",
  LOCATION_PICKER_Selected: "Selected",
  LOCATION_PICKER_Locations_Selected: "Location(s) Selected:",
  LOCATION_PICKER_All_Locations_Selected: "All Locations Selected",
  LOCATION_PICKER_Locations: "Locations",
  LOCATION_PICKER_Preset_Selections: "Select by Location Type",
  LOCATION_PICKER_Blank_Segment_Name: "* Unknown Location Type *",

  AREA_REGION_PICKER_Area_and_Regions: "Areas and Regions",
  AREA_REGION_PICKER_All_Areas_Selected: "All Areas/Regions Selected",
  AREA_REGION_PICKER_Areas_Selected: "Area(s) Selected",
  AREA_REGION_PICKER_Selected: "Selected",
  AREA_REGION_PICKER_All_Selected: "All",

  LOCATION_SEGMENT_PICKER_Location_Type: "Location Type",
  LOCATION_SEGMENTS_PICKER_All_Selected: "All",
  LOCATION_SEGMENTS_PICKER_Selected: "Selected",
  LOCATION_SEGMENT_PICKER_Select_All_Option: "Select All",

  GENERIC_TREE_VIEW_All_selected: "All",
  GENERIC_TREE_VIEW_selected: "Selected",
  GENERIC_TREE_VIEW_Apply: "Apply",
  GENERIC_TREE_VIEW_Cancel: "Cancel",
  GENERIC_TREE_VIEW_ExpandAll: "Expand All",
  GENERIC_TREE_VIEW_Collapse_All: "Collapse All",
  GENERIC_TREE_VIEW_Select_All: "Select All",
  GENERIC_TREE_VIEW_Clear_Selections: "Clear Selections",
  GENERIC_TREE_VIEW_Showing: "Showing",
  GENERIC_TREE_VIEW_Of: "of",
  GENERIC_TREE_VIEW_Items: "Items",

  GENERIC_MULTI_SELECT_All_selected: "All",
  GENERIC_MULTI_SELECT_Selected: "Selected",
  GENERIC_MULTI_SELECT_Select_All: "Select All",
  GENERIC_MULTI_SELECT_Clear_Selections: "Clear Selections",
  GENERIC_MULTI_SELECT_Apply: "Apply",
  GENERIC_MULTI_SELECT_Cancel: "Cancel",

  LOCATION_RANK_PICKER_Location_Rank: "Rank by Location",

  VISIT_TYPE_PICKER_All_Selected: "All",
  VISIT_TYPE_PICKER_Selected: "Selected",
  VISIT_TYPE_PICKER_Visit_Type: "Visit Type",
  VISIT_TYPE_PICKER_Select_All_Option: "Select All",
  VISIT_TYPE_OPTION_MV: "Mystery Visit",
  VISIT_TYPE_OPTION_OF: "Guest Survey",
  VISIT_TYPE_OPTION_CC: "Comment Card",
  VISIT_TYPE_OPTION_RV: "Social Review",
  VISIT_TYPE_OPTION_ES: "Employee Survey",
  VISIT_TYPE_OPTION_TA: "Telephone Audit",
  VISIT_TYPE_OPTION_PA: "Project Audit",
  VISIT_TYPE_OPTION_DA: "Delivery Audit",
  VISIT_TYPE_OPTION_DR: "Delivery Review",
  VISIT_TYPE_OPTION_PS: "Product Survey",
  VISIT_TYPE_OPTION_EF: "External Feedback",
  VISIT_TYPE_OPTION_TT: "Track and Trace",
  VISIT_TYPE_OPTION_RC: "Internal Audit",
  VISIT_TYPE_OPTION_INVALID_VISIT_TYPE: "Unknown Visit Type",

  VISIT_SOURCE_OPTION_brownbook: "Brownbook",
  VISIT_SOURCE_OPTION_complaint: "Complaint",
  VISIT_SOURCE_OPTION_facebook: "Facebook",
  VISIT_SOURCE_OPTION_factual: "Factual",
  VISIT_SOURCE_OPTION_foursquare: "FourSquare",
  VISIT_SOURCE_OPTION_google: "Google",
  VISIT_SOURCE_OPTION_hotfrog: "HotFrog",
  VISIT_SOURCE_OPTION_scoot: "Scoot",
  VISIT_SOURCE_OPTION_thomsonlocal: "Thomson Local",
  VISIT_SOURCE_OPTION_touchlocal: "Touch Local",
  VISIT_SOURCE_OPTION_tripadvisor: "TripAdvisor",
  VISIT_SOURCE_OPTION_yahoo: "Yahoo! Local",
  VISIT_SOURCE_OPTION_yell: "Yell",
  VISIT_SOURCE_OPTION_yelp: "Yelp",
  VISIT_SOURCE_OPTION_other: "Other",
  VISIT_SOURCE_OPTION_opentable: "OpenTable",
  VISIT_SOURCE_OPTION_bookatable: "Bookatable",
  "VISIT_SOURCE_OPTION_Design My Night": "Design My Night",
  VISIT_SOURCE_OPTION_zomato: "Zomato",
  VISIT_SOURCE_OPTION_expedia: "Expedia",
  VISIT_SOURCE_OPTION_booking: "Booking.com",
  VISIT_SOURCE_OPTION_ResDiary: "ResDiary",
  VISIT_SOURCE_OPTION_DishCult: "Dish Cult",
  VISIT_SOURCE_OPTION_trustpilot: "Trustpilot",
  VISIT_SOURCE_OPTION_Deliveroo: "Deliveroo Reviews",
  VISIT_SOURCE_OPTION_peachcommentcards: "Peach Comment Cards",
  VISIT_SOURCE_OPTION_pretasia: "Pret Asia",
  VISIT_SOURCE_OPTION_pretmiddleeast: "Pret Middle East",
  VISIT_SOURCE_OPTION_pretus: "Pret US",
  VISIT_SOURCE_OPTION_foodpanda: "FoodPanda",
  VISIT_SOURCE_OPTION_Email: "Email",
  VISIT_SOURCE_OPTION_Website: "Website",
  VISIT_SOURCE_OPTION_Phone: "Phone",
  VISIT_SOURCE_OPTION_Twitter: "Twitter",
  VISIT_SOURCE_OPTION_Instagram: "Instagram",
  VISIT_SOURCE_OPTION_Kiosk: "Kiosk",
  VISIT_SOURCE_OPTION_Restaurant: "Restaurant",
  "VISIT_SOURCE_OPTION_Smart Order": "Smart Order",
  VISIT_SOURCE_OPTION_Counter: "Counter",
  VISIT_SOURCE_OPTION_Delivery: "Delivery",
  "VISIT_SOURCE_OPTION_Deliveroo DS": "Deliveroo",
  "VISIT_SOURCE_OPTION_Uber Eats DS": "Uber Eats",
  "VISIT_SOURCE_OPTION_Just Eat DS": "Just Eat",
  "VISIT_SOURCE_OPTION_Click and Collect": "Click and Collect",
  VISIT_SOURCE_OPTION_Takeaway: "Takeaway",
  "VISIT_SOURCE_OPTION_Eat In": "Eat In",
  VISIT_SOURCE_OPTION_Reservation: "Reservation",
  VISIT_SOURCE_OPTION_WIFI: "WIFI",
  "VISIT_SOURCE_OPTION_Voucher Redemption": "Voucher Redemption",
  VISIT_SOURCE_OPTION_Receipt: "Receipt",
  "VISIT_SOURCE_OPTION_Pay at Table": "Pay at Table",
  VISIT_SOURCE_OPTION_GrubHub: "GrubHub",
  VISIT_SOURCE_OPTION_Seamless: "Seamless",
  "VISIT_SOURCE_OPTION_The Fork": "The Fork",
  VISIT_SOURCE_OPTION_Trip: "Trip",
  VISIT_SOURCE_OPTION_STN: "STN",
  VISIT_SOURCE_OPTION_Loyalty: "Loyalty",
  VISIT_SOURCE_OPTION_Orderbee: "Orderbee",
  VISIT_SOURCE_OPTION_pretfrance: "Pret France",
  VISIT_SOURCE_OPTION_pretbelgium: "Pret Belgium",
  VISIT_SOURCE_OPTION_Orbitz: "Orbitz",
  VISIT_SOURCE_OPTION_Hotels: "Hotels.com",
  VISIT_SOURCE_OPTION_Travelocity: "Travelocity",
  "VISIT_SOURCE_OPTION_Just Eat": "Just Eat",
  "VISIT_SOURCE_OPTION_Uber Eats": "Uber Eats",
  "VISIT_SOURCE_OPTION_Milano Receipt": "Milano Receipt",

  QUESTIONNAIRE_TYPE_PICKER_All_Selected: "All",
  QUESTIONNAIRE_TYPE_PICKER_Selected: "Selected",
  QUESTIONNAIRE_TYPE_PICKER_Questionnaire_Type: "Questionnaire Type",
  QUESTIONNAIRE_TYPE_PICKER_Select_All_Option: "Select All",

  QUESTIONS_PICKER_Questions: "Questions",
  QUESTIONS_PICKER_Selected_Questions: "Selected Questions",
  QUESTIONS_PICKER_Search: "Search",
  QUESTIONS_PICKER_Selected: "Selected",
  QUESTIONS_PICKER_Apply: "Apply",
  QUESTIONS_PICKER_Cancel: "Cancel",
  QUESTIONS_PICKER_All_Selected: "All",
  QUESTIONS_PICKER_Selected_Tooltip: "Selected:",
  QUESTIONS_PICKER_Show_Full_Text: "Show full Question Text",

  QUESTION_SECTIONS_PICKER_All_Selected: "All",
  QUESTION_SECTIONS_PICKER_Selected: "Selected",
  QUESTION_SECTIONS_PICKER_Sections: "Sections",
  QUESTION_SECTIONS_PICKER_Select_All_Option: "All",
  QUESTION_SECTIONS_PICKER_Show_Groupings: "Show Questionnaire Types",

  QUESTION_CATEGORY_PICKER_All_Selected: "All",
  QUESTION_CATEGORY_PICKER_Selected: "Selected",
  QUESTION_CATEGORY_PICKER_Question_Categrory: "Question Category",
  QUESTION_CATEGORY_PICKER_Select_All_Option: "All",

  QUESTION_BENCHMARKS_PICKER_All_Selected: "All",
  QUESTION_BENCHMARKS_PICKER_Selected: "Selected",
  QUESTION_BENCHMARKS_PICKER_Benchmarks: "Benchmarks",
  QUESTION_BENCHMARKS_PICKER_Select_All_Option: "All",

  QUESTION_ANALYSIS_TAGS_PICKER_All_Selected: "All",
  QUESTION_ANALYSIS_TAGS_PICKER_Selected: "Selected",
  QUESTION_ANALYSIS_TAGS_PICKER_Tags: "Question Tags",
  QUESTION_ANALYSIS_TAGS_PICKER_Select_All_Option: "All",

  QUESTION_NPS_PICKER_VALUES_Promoter: "Promoter",
  QUESTION_NPS_PICKER_VALUES_Neutral: "Neutral",
  QUESTION_NPS_PICKER_VALUES_Detractor: "Detractor",
  QUESTION_NPS_PICKER_NetPromoter: "NPS Classification",
  QUESTION_NPS_PICKER_All_Selected: "All",
  QUESTION_NPS_PICKER_Selected: "Selected",
  QUESTION_NPS_PICKER_Select_All_Option: "All",

  VISIT_SCORE_RANGE_PICKER_Visit_Score: "Visit Score",
  VISIT_SCORE_RANGE_PICKER_Range: "Range: ",
  VISIT_SCORE_RANGE_PICKER_Apply: "Apply",
  VISIT_SCORE_RANGE_PICKER_Cancel: "Cancel",

  QUESTION_SCORE_RANGE_PICKER_Question_Score: "Question Score",
  QUESTION_SCORE_RANGE_PICKER_Range: "Range: ",
  QUESTION_SCORE_RANGE_PICKER_Apply: "Apply",
  QUESTION_SCORE_RANGE_PICKER_Cancel: "Cancel",

  DAY_OF_WEEK_PICKER_All_Selected: "All",
  DAY_OF_WEEK_PICKER_Selected: "Selected",
  DAY_OF_WEEK_PICKER_Day: "Day",
  DAY_OF_WEEK_PICKER_Select_All_Option: "Select All",
  DAY_OF_WEEK_PICKER_Monday: "Monday",
  DAY_OF_WEEK_PICKER_Tuesday: "Tuesday",
  DAY_OF_WEEK_PICKER_Wednesday: "Wednesday",
  DAY_OF_WEEK_PICKER_Thursday: "Thursday",
  DAY_OF_WEEK_PICKER_Friday: "Friday",
  DAY_OF_WEEK_PICKER_Saturday: "Saturday",
  DAY_OF_WEEK_PICKER_Sunday: "Sunday",

  DAY_PART_PICKER_All_Selected: "All",
  DAY_PART_PICKER_Selected: "Selected",
  DAY_PART_PICKER_DayPart: "Day Part",
  DAY_PART_PICKER_Select_All_Option: "Select All",
  "DAY_PART_PICKER_All Day": "All Day",
  DAY_PART_PICKER_Morning: "Morning",
  DAY_PART_PICKER_Lunch: "Lunch",
  DAY_PART_PICKER_Afternoon: "Afternoon",
  DAY_PART_PICKER_Evening: "Evening",
  "DAY_PART_PICKER_Late Night": "Late Night",
  "DAY_PART_PICKER_TOOLTIP_All Day": "",
  DAY_PART_PICKER_TOOLTIP_Morning: "5:00am - 11:30am",
  DAY_PART_PICKER_TOOLTIP_Lunch: "11:30am - 2:30pm",
  DAY_PART_PICKER_TOOLTIP_Afternoon: "2:30pm - 5:00pm",
  DAY_PART_PICKER_TOOLTIP_Evening: "5:00pm - 12:00am",
  "DAY_PART_PICKER_TOOLTIP_Late Night": "12:00am - 5:00am",

  TASK_CENTRE_TASK_ID: "Task id",
  TASK_CENTRE_STATUS_PICKER_All_Selected: "All",
  TASK_CENTRE_STATUS_PICKER_Selected: "Selected",
  TASK_CENTRE_STATUS_PICKER_Status: "Task Status",
  TASK_CENTRE_STATUS_PICKER_Select_All_Option: "Select All",

  TASK_CENTRE_CATEGORY_PICKER_All_Selected: "All",
  TASK_CENTRE_CATEGORY_PICKER_Selected: "Selected",
  TASK_CENTRE_CATEGORY_PICKER_Category: "Category",
  TASK_CENTRE_CATEGORY_PICKER_Select_All_Option: "Select All",

  TASK_CENTRE_PRIORITY_PICKER_All_Selected: "All",
  TASK_CENTRE_PRIORITY_PICKER_Selected: "Selected",
  TASK_CENTRE_PRIORITY_PICKER_Priority: "Priority",
  TASK_CENTRE_PRIORITY_PICKER_Select_All_Option: "Select All",

  TASK_CENTRE_NO_TASKS: "This visit has no tasks",

  APPEAL_STATUS_PICKER_All_Selected: "All",
  APPEAL_STATUS_PICKER_Please_Select: "Please Select",
  APPEAL_STATUS_PICKER_Selected: "Selected",
  APPEAL_STATUS_PICKER_Status: "Appeal Status",
  TASK_STATUS_PICKER_Select_All_Option: "Select All",

  VISIT_ID_PICKER_VisitId: "Visit ID",
  VISIT_ID_PICKER_No_Id_Set: "n/a",
  VISIT_ID_PICKER_ClearSearch: "Clear",
  VISIT_ID_PICKER_Apply: "Apply",
  VISIT_ID_PICKER_Cancel: "Cancel",

  VISIT_SOURCE_PICKER_Visit_Source: "Visit Source",
  VISIT_SOURCE_PICKER_Select_All_Option: "Select All",
  VISIT_SOURCE_PICKER_All_Selected: "All",
  VISIT_SOURCE_PICKER_Selected: "Selected",

  VISIT_DISH_NAME_PICKER_All_Selected: "Any",
  VISIT_DISH_FILTER_label: "Dish name",
  VISIT_DISH_FILTER_NUMBER_SELECT_label: "Dish(es) Selected:",

  ANALYSIS_TAG_VALUE_PICKER_Feedback_Category: "Feedback Category",
  "ANALYSIS_TAG_VALUE_PICKER_Feedback Category": "Feedback Category",
  ANALYSIS_TAG_VALUE_PICKER_Feedback_Sub_Category: "Sub Category",
  "ANALYSIS_TAG_VALUE_PICKER_Sub Category": "Sub Category",
  ANALYSIS_TAG_VALUE_PICKER_Department: "Department",
  ANALYSIS_TAG_VALUE_PICKER_Room: "Room/Area",
  ANALYSIS_TAG_VALUE_PICKER_Length_of_Service: "Length of Service",
  "ANALYSIS_TAG_VALUE_PICKER_Length of Service": "Length of Service",
  ANALYSIS_TAG_VALUE_PICKER_Select_All_Option: "Select All",
  ANALYSIS_TAG_VALUE_PICKER_All_Selected: "All",
  ANALYSIS_TAG_VALUE_PICKER_Selected: "Selected",

  TASK_ASSIGNEE_PICKER_All_Selected: "All",
  TASK_ASSIGNEE_PICKER_Selected: "Selected",
  TASK_ASSIGNEE_PICKER_Assignee: "Task Assignee",
  TASK_ASSIGNEE_PICKER_Search: "Search",
  TASK_ASSIGNEE_PICKER_SearchByName: "Name",
  TASK_ASSIGNEE_PICKER_Select_All_Option: "Select All",

  VISIT_CONTACT_STATUS_PICKER_Contact_Status: "Contact Status",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ShowAll: "Any",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_WithContact:
    "Survey: Guest Details Available",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_WithNoContact:
    "Survey: Guest Details Not Available",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_AwaitingContact:
    "Survey: Awaiting First Contact",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ContactMade: "Survey: Contact Made",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_GuestReplied: "Survey: Guest Replied",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ReviewUnresponded: "Review: Unresponded",
  VISIT_CONTACT_STATUS_PICKER_PREFIX_ReviewResponded: "Review: Responded",

  FILTERS_ERROR_LOADING: "Could not load filter",
  FILTERS_RETRY:
    "Click to retry, please contact support if the problem persists",

  FILTER_CONFIGURATION_OPTIONS_Add_Filter: "More Filters",
  FILTER_ACCORDION_TITLE: "Show Filters",
  FILTER_CONFIGURATION_OPTIONS_Add_Filters_Title: "More Filters:",
  FILTER_CONFIGURATION_OPTIONS_Add_Filters_Description:
    "Pick any combination of additional filters below. These will be applied in addition to the Date and Location filters.",
  FILTER_CONFIGURATION_OPTIONS_Add_Filters_Note:
    "Note: Some Filter + Report combinations are not applicable. In these instances, the filter options below will be disabled and will have no impact on the report.",
  FILTER_CONFIGURATION_OPTIONS_Questions_and_Answers: "Questionnaires:",
  FILTER_CONFIGURATION_OPTIONS_InboxTasks: "Tasks & Communications:",
  FILTER_CONFIGURATION_OPTIONS_Visit: "Visit:",
  FILTER_CONFIGURATION_OPTIONS_Scores: "Scores:",
  FILTER_CONFIGURATION_OPTIONS_Time: "Time:",
  FILTER_CONFIGURATION_OPTIONS_Other: "Other:",
  FILTER_CONFIGURATION_OPTIONS_Apply: "Apply",
  FILTER_CONFIGURATION_OPTIONS_Cancel: "Cancel",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitType: "Visit Type",
  FILTER_CONFIGURATION_OPTIONS_ENUM_QuestionnaireType: "Questionnaire Type",
  FILTER_CONFIGURATION_OPTIONS_ENUM_Sections: "Questionnaire Sections",
  FILTER_CONFIGURATION_OPTIONS_ENUM_Questions: "Questions",
  FILTER_CONFIGURATION_OPTIONS_ENUM_QuestionCategory: "Question Category",
  FILTER_CONFIGURATION_OPTIONS_ENUM_Benchmarks: "Benchmarks",
  FILTER_CONFIGURATION_OPTIONS_ENUM_AnalysisTags: "Question Tags",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitScoreRange: "Visit Score",
  FILTER_CONFIGURATION_OPTIONS_ENUM_QuestionScoreRange: "Question Score",
  FILTER_CONFIGURATION_OPTIONS_ENUM_NPS: "NPS Classification",
  FILTER_CONFIGURATION_OPTIONS_ENUM_DayPart: "Day Part",
  FILTER_CONFIGURATION_OPTIONS_ENUM_DayOfWeek: "Day of Week",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitAnalysisDepartment: "Department",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitAnalysisRoom: "Room/Area",
  FILTER_CONFIGURATION_OPTIONS_ENUM_VisitSource: "Visit Source",
  FILTER_CONFIGURATION_OPTIONS_ENUM_FeedbackCategory: "Feedback Category",
  FILTER_CONFIGURATION_OPTIONS_ENUM_FeedbackSubCategory: "Feedback Subcategory",
  FILTER_CONFIGURATION_OPTIONS_ENUM_TaskCentreCategory: "Task Category",
  FILTER_CONFIGURATION_OPTIONS_ENUM_TaskCentrePriority: "Task Priority",
  FILTER_CONFIGURATION_OPTIONS_ENUM_LengthOfService: "Length of Service",
  FILTER_CONFIGURATION_OPTIONS_ENUM_DishFilters: "Dish Name",
  FILTER_CONFIGURATION_OPTIONS_ENUM_BrandFilter: "Brands",
  FILTER_CONFIGURATION_OPTIONS_ENUM_SegmentFilter: "Segments",

  REPORT_DATA_EXPORT_Export_Data: "Export Data:",
  REPORT_DATA_EXPORT_Exports_Help_Message:
    "Exports are capped at 30,000 rows. If you need more than this, please contact support@hgem.com",
  REPORT_DATA_EXPORT_Export: "Export",
  REPORT_DATA_EXPORT_Cancel: "Cancel",
  REPORT_DATA_EXPORT_File_Name: "File Name",
  REPORT_DATA_EXPORT_FileName_HelpText:
    "File Name can only contain alphanumeric characters, underscores, and hyphens.",
  REPORT_DATA_EXPORT_Source: "Source",
  REPORT_DATA_EXPORT_FileDateFormat: "YYYY-MM-DD__HH-mm",
  REPORT_DATA_EXPORT_File_Extension_Adornment: ".CSV",
  REPORT_DATA_EXPORT_No_visuals_available:
    "There is no exportable data in the current report page. Please try another page or report.",

  PAGE_TASKS_Print_Page: "Print Page",
  PAGE_TASKS_Export_Data: "Export Data",

  // Power BI Data Model & Report Internals
  POWERBI_FILTER_Client_Id_TABLE: "Client",
  POWERBI_FILTER_Client_Id_COLUMN: "RemoteId",
  POWERBI_FILTER_Brand_Name_TABLE: "BranchLocations",
  POWERBI_FILTER_Brand_Name_COLUMN: "Brand",
  POWERBI_FILTER_Locations_TABLE: "BranchLocations",
  POWERBI_FILTER_Locations_COLUMN: "Id",
  POWERBI_FILTER_VisitDate_TABLE: "Dates",
  POWERBI_FILTER_VisitDate_COLUMN: "Date",
  POWERBI_FILTER_Period_Name_TABLE: "ClientPeriods",
  POWERBI_FILTER_Period_Name_COLUMN: "Name",
  POWERBI_FILTER_AreasAndRegions_TABLE: "BranchLocations",
  POWERBI_FILTER_AreasAndRegions_COLUMN: "Area",
  POWERBI_FILTER_Segments_TABLE: "BranchLocations",
  POWERBI_FILTER_Segments_COLUMN: "Segment",
  POWERBI_FILTER_LocationRank_TABLE: "BranchLocations",
  POWERBI_FILTER_LocationRank_COLUMN: "Id",
  POWERBI_FILTER_VisitType_TABLE: "VisitTypes",
  POWERBI_FILTER_VisitType_COLUMN: "TypeCode",
  POWERBI_FILTER_QuestionnaireType_TABLE: "Questions",
  POWERBI_FILTER_QuestionnaireType_COLUMN: "QuestionnaireType",
  POWERBI_FILTER_Questions_TABLE: "Questions",
  POWERBI_FILTER_Questions_COLUMN: "QuestionText",
  POWERBI_FILTER_VISIT_DATE_FORMAT_START: "YYYY-MM-DDT00:00:00",
  POWERBI_FILTER_VISIT_DATE_FORMAT_END: "YYYY-MM-DDT23:59:59",
  POWERBI_FILTER_Question_Subject_TABLE: "Questions",
  POWERBI_FILTER_Question_Subject_COLUMN: "QuestionSubject",
  POWERBI_FILTER_Sections_TABLE: "Questions",
  POWERBI_FILTER_Sections_COLUMN: "Section",
  POWERBI_FILTER_QuestionCategory_TABLE: "Questions",
  POWERBI_FILTER_QuestionCategory_COLUMN: "Category",
  POWERBI_FILTER_Benchmarks_TABLE: "Questions",
  POWERBI_FILTER_Benchmarks_COLUMN: "BenchmarkType",
  POWERBI_FILTER_AnalysisTags_TABLE: "Questions",
  POWERBI_FILTER_AnalysisTags_COLUMN: "AnalysisTag",
  POWERBI_FILTER_NPS_TABLE: "VisitScores",
  POWERBI_FILTER_NPS_COLUMN: "NPSClassification",
  POWERBI_FILTER_VisitScoreRange_TABLE: "VisitScores",
  POWERBI_FILTER_VisitScoreRange_COLUMN: "CalculatedScore",
  POWERBI_FILTER_QuestionScoreRange_TABLE: "QuestionScores",
  POWERBI_FILTER_QuestionScoreRange_COLUMN: "ScoreValue",
  POWERBI_FILTER_DayOfWeek_TABLE: "Dates",
  POWERBI_FILTER_DayOfWeek_COLUMN: "WeekDay",
  POWERBI_FILTER_DayPart_TABLE: "TimeframeNames",
  POWERBI_FILTER_DayPart_COLUMN: "Timeframe",
  POWERBI_FILTER_VisitAnalysisDepartment_TABLE: "AnalysisTags",
  POWERBI_FILTER_VisitAnalysisDepartment_COLUMN: "Department",
  POWERBI_FILTER_VisitAnalysisRoom_TABLE: "AnalysisTags",
  POWERBI_FILTER_VisitAnalysisRoom_COLUMN: "Room",
  POWERBI_FILTER_TaskCentreStatus_TABLE: "ActionCentre",
  POWERBI_FILTER_TaskCentreStatus_COLUMN: "WorkflowStatus",
  POWERBI_FILTER_TaskCentreCategory_TABLE: "ActionCentre",
  POWERBI_FILTER_TaskCentreCategory_COLUMN: "Category",
  POWERBI_FILTER_TaskCentrePriority_TABLE: "ActionCentre",
  POWERBI_FILTER_TaskCentrePriority_COLUMN: "Priority",
  POWERBI_FILTER_VisitSource_TABLE: "Visits",
  POWERBI_FILTER_VisitSource_COLUMN: "VisitSource",
  POWERBI_FILTER_FeedbackCategory_TABLE: "AnalysisTags",
  POWERBI_FILTER_FeedbackCategory_COLUMN: "Feedback Category",
  POWERBI_FILTER_FeedbackSubCategory_TABLE: "AnalysisTags",
  POWERBI_FILTER_FeedbackSubCategory_COLUMN: "Sub Category",
  POWERBI_FILTER_LengthOfService_TABLE: "AnalysisTags",
  POWERBI_FILTER_LengthOfService_COLUMN: "Length of Service",
  POWERBI_FILTER_PeriodHierarchy_Id_TABLE: "ReportingPeriods",
  POWERBI_FILTER_PeriodHierarchy_Id_COLUMN: "PeriodReporting_WeekId",

  // Render Errors
  REPORT_RENDER_Render_Error_Title:
    "An error has occurred while rendering your report.",
  REPORT_RENDER_Render_Error_Header:
    "Our team has been notified.\nPlease refresh the page and try again.",
  REPORT_RENDER_Render_Error_Message:
    "If this doesn’t work, please wait a few moments and then try again. If it’s still stuck, please contact **support-email**",
  REPORT_RENDER_Render_Error_AdditionalNotice: "",
  REPORT_RENDER_Refresh_Page: "Refresh Page",
  REPORT_RENDER_Copy_Email: "Copy Support Email to clipboard",
  REPORT_RENDER_Render_Error_Support_Email: "support@hgem.com",

  FORM_FIELD_ERROR_Default_Title: "Error Updating:",
  FORM_FIELD_ERROR_Close: "Close",

  // Task Centre
  TASK_CENTRE_Title: "Task Centre",
  TASK_CENTRE_PAGE_Manage_Tasks: "Manage Tasks",
  TASK_CENTRE_PAGE_Statistics: "Statistics",
  TASK_CENTRE_WORKFLOW_STATUS_Discussion: "Discussion",
  TASK_CENTRE_WORKFLOW_STATUS_TaskRequired: "Action Required",
  TASK_CENTRE_WORKFLOW_STATUS_ActionRequired: "Action Required",
  TASK_CENTRE_WORKFLOW_STATUS_InProgress: "In Progress",
  TASK_CENTRE_WORKFLOW_STATUS_ActionTaken: "Action Taken",
  TASK_CENTRE_WORKFLOW_STATUS_DoNotAction: "Do Not Action",
  TASK_CENTRE_WORKFLOW_PRIORITY_Normal: "Normal",
  TASK_CENTRE_WORKFLOW_PRIORITY_High: "High",
  TASK_CENTRE_WORKFLOW_PRIORITY_Low: "Low",
  TASK_CENTRE_CATEGORY_OPTION_None: "None",
  TASK_CENTRE_CASE_LIST_Error_Message:
    "An error has occured. Please refresh the page and try again.",
  TASK_CENTRE_CASE_LIST_Refresh_Page: "Refresh Page",
  TASK_CENTRE_CASE_LIST_Load_More_Items: "Load more items",
  TASK_CENTRE_CASE_LIST_No_More_Items: "You have no further Tasks.",
  TASK_CENTRE_CASE_LIST_Empty_List_Message:
    "You have no visible Tasks. Please check the currently applied filters.",
  TASK_CENTRE_TABS_Details: "Details",
  TASK_CENTRE_TABS_Comments: "Comments",
  TASK_CENTRE_TABS_GuestContact: "Guest Contact",

  TASK_CENTRE_FIELD_Title: "Title",
  TASK_CENTRE_FIELD_Title_Error_Blank: "Title cannot be blank.",
  TASK_CENTRE_FIELD_Title_Error_Length:
    "Title cannot be more than 100 characters long.",
  TASK_CENTRE_FIELD_WorkflowStatus: "Status",
  TASK_CENTRE_FIELD_Category: "Category",
  TASK_CENTRE_CATEGORY_Empty: "No Category Set",
  TASK_CENTRE_FIELD_Priorities: "Priority",
  TASK_CENTRE_ASSIGNEE_Assignee: "Assignee (will receive an email alert)",
  TASK_CENTRE_ASSIGNEE_Unasigned:
    "Select Assignee (will receive an email alert)",
  TASK_CENTRE_ASSIGNEE_Unknown: "Unknown Manager:",
  TASK_CENTRE_ASSIGNEE_Loading: "Fetching Manager Details...",
  TASK_CENTRE_FIELD_Due_Date: "Due Date",
  TASK_CENTRE_DUEDATE_No_Due_Date: "No Due Date",
  TASK_CENTRE_NOTIFICATION_SUCCESS: "Task updated successfully.",
  TASK_CENTRE_NOTIFICATION_ERROR:
    "An error occured whilst updating the action. Please try again.",
  TASK_CENTRE_Timestamp_Format: "ddd DD MMM YYYY HH:mm",
  TASK_CENTRE_FIELD_Comments: "Add Comment...",
  TASK_CENTRE_COMMENTS_Submit_Comment: "Post Comment",
  TASK_CENTRE_FIELD_Comments_Remaining_Chars: "characters remaining",
  TASK_CENTRE_COMMENTS_Leave_a_Comment: "Leave a Comment:",
  TASK_CENTRE_COMMENTS_Internal: " (Internal)",

  TASK_CENTRE_GENERIC_Error_Title: "Error Updating Task",
  TASK_CENTRE_ERROR_CONFIRMATION_Close: "Close",
  TASK_CENTRE_COMMENTS_Error_Message:
    "Sorry, An error occurred whilst posting your comment. Please try again.",
  TASK_CENTRE_Comment_Count: "Comments: ",
  TASK_CENTRE_Guest_Contact_Available: "Contact Status: ",
  TASK_CENTRE_Guest_Contact_Confirmation_Found: "Contact Confirmed",
  TASK_CENTRE_Guest_Contact_Message_sent: "Message Sent",
  TASK_CENTRE_Guest_Contact_Message_sent_and_received:
    "Message Sent + Received",
  TASK_CENTRE_Guest_Contact_Message_not_sent: "Awaiting Contact",
  TASK_CENTRE_Internal_Notes: "Internal notes",
  TASK_CENTRE_FIELD_Guest_Contact_Pending: "Loading guest contact details...",
  TASK_CENTRE_FIELD_Guest_Contact_Error_Fetching:
    "An error has occured whilst retrieving the contact details.",
  TASK_CENTRE_FIELD_Guest_Contact_Retry_Fetching:
    "Reload Guest Contact Details",
  TASK_CENTRE_FIELD_Guest_Contact_Subject: "Subject",
  TASK_CENTRE_PLACEHOLDER_Guest_contact_subject: "Your recent visit to ",
  TASK_CENTRE_FIELD_Guest_Contact_Message: "Message",
  TASK_CENTRE_GUEST_CONTACT_Confirm_Contact: "Notes",
  TASK_CENTRE_GUEST_CONTACT_Submit_Message: "Send Direct Message to Guest",
  TASK_CENTRE_GUEST_CONTACT_Error_Title: "Error Sending Message",
  TASK_CENTRE_GUEST_CONTACT_Error_Message:
    "An error occured whilst sending the message to the guest.",
  TASK_CENTRE_FIELD_Guest_Contact_Name: "Name:",
  TASK_CENTRE_GUEST_CONTACT_ATTACHMENT_SIZE_CANNOT_EXCEED:
    "Total size of all files cannot exceed",

  TASK_CENTRE_GUEST_CONTACT_NAME_FALLBACK: "[INSERT GUEST NAME/TITLE]",
  TASK_CENTRE_GUEST_CONTACT_BRANCH_NAME_FALLBACK: "[INSERT BRANCH NAME]",

  TASK_CENTRE_GUEST_CONTACT_ALSO_FROM_THIS_PERSON: "Also from this person:",
  TASK_CENTRE_GUEST_CONTACT_ALSO_FROM_THIS_PERSON_SUMMARY_CARD_DATE_FORMAT:
    "ddd DD MMM YY",

  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_Title: "Guest Contact:",
  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_Description:
    "Message recipient: ",
  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_CancelTask: "Cancel",
  TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_SendTask: "Send Message",

  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Title: "Guest Contact:",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Description:
    "You can use this to confirm that the guest has been contacted outside of the Hub direct messaging feature, either by phone or by email.",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Notes: "Notes (optional)",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_CancelTask: "Cancel",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_ConfirmTask:
    "Confirm Guest Contacted",
  TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Notes_Error:
    "Notes cannot be more than 1000 characters in length.",

  TASK_CENTRE_Message_Sent_To_Guest: "Message Sent to Guest",
  TASK_CENTRE_Message_Received_From_Guest: "Message Received",
  TASK_CENTRE_Contact_Confirmed: "Guest Contact Confirmed",
  TASK_CENTRE_Contact_Confirmed_No_Comment: "No Notes Provided",

  TASK_CENTRE_VISIT_DETAILS_PREF_DownloadVisitReport: "Download Visit Report",
  TASK_CENTRE_VISIT_DETAILS_PREF_ViewReportInInbox: "Open in new Inbox Tab",
  TASK_CENTRE_VISIT_DETAILS_Score: "Score: ",
  TASK_CENTRE_VISIT_DETAILS_NullScore: "Score: n/a",

  TASK_CENTRE_EDIT_CASE_DELETE_Title: "Confirm Delete:",
  TASK_CENTRE_EDIT_CASE_DELETE_Message:
    "Are you sure you want to delete the following action?",
  TASK_CENTRE_EDIT_CASE_DELETE_Cancel: "Cancel",
  TASK_CENTRE_EDIT_CASE_DELETE_Delete: "Delete",

  TASK_CENTRE_CASE_LIST_FILTER_PlaceholderText:
    "Search Title, Comment, or Message",
  TASK_CENTRE_CASE_LIST_FILTER_OrderText: "Sorted by most recent communication",
  TASK_CENTRE_CASE_LIST_FILTER_ClearSearch: "Clear Search",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_Prefix: "Contact Status: ",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_AllCases: "All Tasks",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_CasesAwaitingContact:
    "Awaiting Contact",
  TASK_CENTRE_CASE_LIST_FILTER_ContactFilter_CasesWithCompletedContacts:
    "Guest Contact Made",
  TASK_CENTRE_CASE_LIST_FILTER_Showing: "Showing ",
  TASK_CENTRE_CASE_LIST_FILTER_Cases_out_of: " action(s): of ",

  TASK_CENTRE_CASE_LIST_RESULTS_MATCHING_FILTERS: " result(s) matching filters",

  TASK_CENTRE_CASE_ITEM_Date_Format: "ddd DD MMM YYYY",
  TASK_CENTRE_CASE_ITEM_Created: "Created: ",
  TASK_CENTRE_CASE_ITEM_Visit: "Visit: ",

  TASK_CENTRE_LIST_Select_Task: "Selected Task",
  TASK_CENTRE_LIST_NoTaskSelected: "n/a",

  TASK_CENTRE_Quick_Task_Create: "Set a new Task status for this visit",
  TASK_CENTRE_Create_Additional_Task: "Create additional Task",
  TASK_CENTRE_Delete_Task: "Delete Task",
  TASK_CENTRE_VIEW_ATTACHMENTS: "View Attachments",
  TASK_CENTRE_VIEW_COMMENTS: "View comments",
  TASK_CENTRE_CARD_no_contact_details: "No Contact Details available",
  TASK_CENTRE_CARD_awaiting_first_contact: "Awaiting first contact",
  TASK_CENTRE_CARD_awaiting_reply: "Guest awaiting reply",
  TASK_CENTRE_CARD_guest_contacted: "Guest has been contacted",
  TASK_CENTRE_CARD_visit_score: "Visit score",
  TASK_CENTRE_CARD_last_communication: "Last message:",
  TASK_CENTRE_CARD_assignee: "Current assignee",
  TASK_CENTRE_CARD_no_assignee: "No one is currently assigned to this task",
  TASK_CENTRE_CARD_voucher: "Voucher has been sent",
  TASK_CENTRE_CARD_number_of_messages: "Number of messages",
  TASK_CENTRE_CREATED_DATE: "Created:",
  TASK_CENTRE_CARD_Timestamp_Format: "DD MMM YYYY",
  TASK_CENTRE_CARD_Days_open: "days open",
  TASK_CENTRE_CARD_Day_open: "day open",
  // E-Learning
  ELEARNING_Title: "Little GEM learning modules",
  ELEARNING_TEMP_Message:
    "Our e-Learning modules will be available again soon.",

  // 404 Not Found + Error Page
  NOTFOUND_Title: "Sorry, the page you are looking for has not been found...",
  NOTFOUND_HomePage_Text: "Click here to go to the homepage",
  NOTFOUND_Alternative_links:
    "Alternatively, if the problem persists, please contact HGEM Client Support at support@hgem.com or via our ",
  NOTFOUND_Alternative_contact_page: "https://www.hgem.com/contact-us",
  NOTFOUND_Alternative_contact_page_token: "contact page.",

  ERRORPAGE_Title: "Oops!",
  ERRORPAGE_Subtitle: "We have logged an error and will look into it.",
  ERRORPAGE_BackButtonHint: "Please try to",
  ERRORPAGE_HomePage_Text: " reset ",
  ERRORPAGE_Alternative_links:
    "Failing that, contact us via chat (below) or email ",
  ERRORPAGE_Alternative_contact_page: "support@hgem.com",
  ERRORPAGE_Alternative_contact_page_token: " support@hgem.com",
  ERRORPAGE_ResetPrefix: "this session or use a different browser",
  ERRORPAGE_ResetLinkText: "Reset your Hub session",

  AUTH_ERRORPAGE_Title:
    "Sorry, an error has occurred whilst processing your login.",
  AUTH_ERRORPAGE_Subtitle: "This has been logged and we will look into it.",
  AUTH_ERRORPAGE_Subtitle_MultiTenant:
    "If you used the 'Sign in with Microsoft' option, confirm that your organisation is configured to use this feature.",
  AUTH_ERRORPAGE_RetryHint:
    "This might be a temporary site issue, or it might be an issue with your account, please check your details and ",
  AUTH_ERRORPAGE_RetryHint_MultiTenant:
    "Please check your login details and click here to ",
  AUTH_ERRORPAGE_ResetLinkTextMultiTenant: "restart your Hub session.",

  // Visit Report - Direct Download
  VISIT_REPORT_DOWNLOAD_Title: "Download Visit Report",
  VISIT_REPORT_DOWNLOAD_Download_Running:
    "Your visit report is being downloaded...",
  VISIT_REPORT_DOWNLOAD_Download_Try_Again_Message:
    "If the download does not complete automatically, please wait a few moments and ",
  VISIT_REPORT_DOWNLOAD_Download_Try_Again: "click here to retry the download.",
  VISIT_REPORT_DOWNLOAD_Download_Error:
    "An error has occured whilst creating your visit report.",
  VISIT_REPORT_DOWNLOAD_Parse_URL_Error:
    "An error has occured. The visit report reference provided is invalid.",
  VISIT_REPORT_DOWNLOAD_Download_Complete: "Download Complete.",
  VISIT_REPORT_DOWNLOAD_View_More_Reports:
    "View all of your visit reports by going to ",
  VISIT_REPORT_DOWNLOAD_Visit_Details: "Visit Details",

  INBOX_TABS_VisitReport: "Visit Report",
  INBOX_TABS_Media: "Image & Audio",
  INBOX_TABS_Tasks: "Tasks",
  INBOX_TABS_Comments: "Comments",
  INBOX_TABS_GuestCommunications: "Guest Communications",
  INBOX_TABS_Appeals: "Appeals",

  VISIT_DETAILS_LIST_Refresh_Page: "Refresh Page",
  VISIT_DETAILS_LIST_Error_Message: "Error Loading Visits",
  VISIT_DETAILS_LIST_Empty_List_Message:
    "You have no visible visits. Please check the currently applied filters.",
  VISIT_DETAILS_LIST_Load_More_Items: "Load more visits",
  VISIT_DETAILS_LIST_No_More_Items: "You have no further visits.",
  VISIT_DETAILS_LIST_RESULTS_MATCHING_FILTERS: " result(s) matching filters",

  VISIT_DETAILS_LIST_ITEM_Score: "Score:",
  VISIT_DETAILS_LIST_ITEM_Type: "Type:",
  VISIT_DETAILS_LIST_ITEM_HasMedia: "Images and Audio",
  VISIT_DETAILS_LIST_ITEM_HasAppeal: "Appeal",
  VISIT_DETAILS_LIST_ITEM_TaskCount_TooltipSuffix:
    " Task(s) linked to this visit",

  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_Review: "Review Status",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_GuestContact: "Contact Status",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_Review_Responded: "Responded",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_GuestContact_ContactMade:
    "Contact Made",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_Review_Pending_Response:
    "Awaiting Response",
  VISIT_DETAILS_LIST_ITEM_GUEST_CONTACT_GuestContact_Awaiting_Contact:
    "Awaiting Contact",

  VISIT_DETAILS_LIST_NoVisitSelected: "n/a",
  VISIT_DETAILS_LIST_Select_Visit: "Selected Visit",
  VISIT_DETAILS_LIST_Date_Format: "ddd DD MMM YYYY",

  VISIT_DETAILS_TASK_SELECTOR_SelectorSuffix: "Change Selected Task",

  VISIT_DETAILS_TASK_CREATOR_New_Task:
    "Add an additional action for this visit",
  VISIT_DETAILS_TASK_CREATOR_Create_New_Task: "New Task",
  VISIT_DETAILS_TASK_CREATOR_HelpText:
    "What would you like to achieve with this task?",
  VISIT_DETAILS_TASK_CREATOR_HelpText_examples:
    "For example: Implement regular checks of toilet cleanliness",
  VISIT_DETAILS_TASK_CREATOR_CreateTask: "Create",
  VISIT_DETAILS_TASK_CREATOR_Cancel: "Cancel",
  VISIT_DETAILS_TASK_CREATOR_TaskTitleLabel: "Task Title",

  VISIT_DETAILS_TASKS_Comments: "Comments",
  VISIT_DETAILS_TASKS_GuestContact: "Guest Contact",
  VISIT_DETAILS_MEDIA_Photo_Enlarge: "Click to View Image",
  VISIT_DETAILS_MEDIA_Photo_Alt_Text: "Visit Photo",
  VISIT_DETAILS_PHOTO_MODAL_Close: "Close",
  VISIT_DETAILS_PHOTO_MODAL_Download: "Download Image",
  VISIT_DETAILS_PHOTO_MODAL_Filename_Prefix: "VisitPhoto",
  VISIT_DETAILS_PHOTO_MODAL_Metadata_Date_Prefix: "Date taken:",
  VISIT_DETAILS_PHOTO_MODAL_Metadata_Date_Format: "ddd DD MMM YY",
  VISIT_DETAILS_PHOTO_MODAL_Metadata_Location_Prefix: "Location:",

  VISIT_DETAILS_AUDIO_TileLabel: "Audio File",
  VISIT_DETAILS_AUDIO_MODAL_Title: "Audio Playback:",
  VISIT_DETAILS_AUDIO_MODAL_No_Audio_Support:
    "Sorry, the browser you are using does not support audio playback.",
  VISIT_DETAILS_AUDIO_MODAL_Download: "Download File",
  VISIT_DETAILS_AUDIO_MODAL_Close: "Close",
  VISIT_DETAILS_AUDIO_MODAL_Filename_Prefix: "Visit_Audiio",
  VISIT_DETAILS_AUDIO_PlayAudio: "Play Audio",

  VISIT_DETAILS_REVIEW_HEADER_Date_Format: "dddd DD MMMM YYYY HH:mm",
  VISIT_DETAILS_REVIEW_HEADER_Area_Prefix: "Area: ",
  VISIT_DETAILS_REVIEW_HEADER_Source_Prefix: "Source: ",
  VISIT_DETAILS_REVIEW_HEADER_Period_Prefix: "Period: ",
  VISIT_DETAILS_REVIEW_HEADER_VisitId_Prefix: "Visit: ",
  VISIT_DETAILS_REVIEW_HEADER_EmptyComment_Placeholder: "No Comments",
  VISIT_DETAILS_REVIEW_HEADER_ScoreSuffix: "/5",
  VISIT_DETAILS_REVIEW_HEADER_Response_Title: "Response: ",
  VISIT_DETAILS_REVIEW_HEADER_Response_Date_Format: "dddd DD MMMM YYYY HH:mm",

  VISIT_DETAILS_REVIEW_RESPONSE_Remaining_Chars: "characters remaining",
  VISIT_DETAILS_REVIEW_RESPONSE_Respond_to_Review: "Respond to Review",
  VISIT_DETAILS_REVIEW_RESPONSE_Post_Response: "Post Response",
  VISIT_DETAILS_REVIEW_RESPONSE_Error_Title: "Error Posting Review Response",
  VISIT_DETAILS_REVIEW_RESPONSE_Error_Message:
    "We were unable to post your response to this review. Please try submitting the response again.",
  VISIT_DETAILS_REVIEW_RESPONSE_View_Only: "View in ",

  VISIT_DETAILS_REVIEW_RESPONSE_View_Respond: "View or Respond in ",
  VISIT_DETAILS_REVIEW_RESPONSE_Title: "View or Respond to Review:",
  VISIT_DETAILS_REVIEW_RESPONSE_Close: "Close",
  VISIT_DETAILS_REVIEW_RESPONSE_ViewReview: "View: ",
  VISIT_DETAILS_REVIEW_RESPONSE_RespondReview: "Respond: ",
  VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage:
    "It looks like we don’t have permission to post responses for this review page – ask your administrator to ",
  VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage_ContactUs: "contact us",
  VISIT_DETAILS_REVIEW_RESPONSE_UnavailableMessage_ContactLink:
    "mailto:support@hgem.com",

  VISIT_DETAILS_REVIEW_RESPONSE_Use_Template: "Response templates",
  VISIT_DETAILS_REVIEW_RESPONSE_Insert_Introduction: "Use Introduction",
  VISIT_DETAILS_REVIEW_RESPONSE_Insert_Signature: "Use Signature",
  VISIT_DETAILS_REVIEW_RESPONSE_Reset: "Reset",
  VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Instructions: "Insert Response Text:",
  VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Select: "Select",
  VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Close: "Close",

  VISIT_DETAILS_REPORT_ERROR_Title: "Error Fetching Visit Report",
  VISIT_DETAILS_REPORT_ERROR_Message:
    "Please try reselecting the current visit or selecing an alternative visit. If the proplem persists, try refreshing the page.",
  VISIT_DETAILS_REPORT_PDF_Download: "Download PDF",

  INBOX_PAGE_Title: "Inbox",
  INBOX_Tabname: "Inbox",
  INBOX_Tasks_Tabname: "Tasks",
  INBOX_Images_Tabname: "Images",
  INBOX_Appeals_Tabname: "Appeals",

  INBOX_GUEST_CONTACT_No_contact_available:
    "Guest Contact Details are not available for this visit.",
  INBOX_MEDIA_no_media_available:
    "No Photo/Media files available for this visit.",
  INBOX_MEDIA_Photos: "Photos:",
  INBOX_MEDIA_AudioFiles: "Audio Files:",
  INBOX_MEDIA_Receipts: "Receipts:",
  INBOX_CARD_visit_number: "Visit number:",
  INBOX_CARD_visit_source: "Visit source:",
  INBOX_CARD_visit_type: "Visit type:",
  INBOX_GUEST_CONTACT_previous_communications: "Previous communications from ",

  // Vouchers
  TOGGLE_VOUCHER_TYPE: "Voucher",
  TOGGLE_SELECT_OPTION: "Select Voucher option",
  TOGGLE_SELECT_PRODUCT: "Select Voucher type",
  GIFT_CARD_DIALOG_CANCEL: "Cancel",
  GIFT_CARD_DIALOG_ACCEPT: "Accept",
  GIFT_CARD_FINAL_DIALOG_TITLE: "Final confirmation Dialog",
  GIFT_FINAL_DIALOG_CANCEL: "Cancel",
  GIFT_CARD_FINAL_DIALOG_CONFIRM_AND_SEND: "Confirm and send",
  GIFT_CARD_BUTTON_NAME_TOGGLE: "Gift card",
  GIFT_CARD_AMOUNT: "Gift card value:",
  GIFT_CARD_MESSAGE_TO_GUEST: "Message to guest:",
  GIFT_CARD_REASON_FOR_CARD: "Reason for gift card:",
  GIFT_CARD_REASON_FOR_GIFT_CARD_CONFIRM: "Reason",
  GIFT_CARD_TO: "To:",
  GIFT_CARD_VALUE_EMPTY: "No voucher value Set",
  GIFT_CARD_ALREADY_SENT: "You have already sent a gift card to this guest",
  GIFT_CARD_MESSAGE_APPEND_TOGGLE:
    "The text 'N.B. A Toggle voucher will be sent to you seperately from no-reply@mytoggle.io shortly' will be appended to your email",
  GIFT_CARD_MESSAGE_APPENDIX:
    "N.B. A Toggle voucher will be sent to you seperately from no-reply@mytoggle.io shortly",
  GIFT_CARD_CUSTOM_VALUE_PLACEHOLDER: "Please enter a value for the gift card",
  GIFT_CARD_CUSTOM_VALUE_LABEL: "Custom value",
  GIFT_CARD_CUSTOM_VALUE_VALIDATION_ERROR_TEXT:
    "The value must be 1 or more and a whole number",
  GIFT_CARD_VALUE_UPPER_LIMIT_ERROR: "Value must not exceed ",
  VOUCHER_DETAILS_SENT_HEADER: "Toggle voucher sent",
  VOUCHER_DETAILS_SENT_REASON: "Reason for voucher:",
  VOUCHER_DETAILS_SENT_AMOUNT: "Voucher Amount:",

  // E-Learning
  "ELearning-AppearanceTitle": "Pride in Our Appearance",
  "ELearning-AppearanceDescription":
    "<p><span class='highlight'>Business aspiration:</span> I don’t want any guest to notice lack of pride in appearance of our place or people at any point during their experience</p><p><span class='highlight'>Consider</span></p><ul><li>Why attention to detail and pride in our place so important (people don’t notice when things are clean but they really notice then things aren’t!)</li><li>Having a mental note of the things to keep an eye on, and consciously being aware of clutter, external appearance, personal appearance, surfaces – things you may not see at first glance</li><li>Toilets toilets toilets – always keep in your focus</li><li>Cleaning as you go, keeping all workstations tidy</li><li>Understand your brand guidelines</li><li>Clean menus, flower boxes, boards</li><li>Put yourself in guests shoes – go and stand outside and look, or sit at a table and see things from that level</li><li>Build relationships that make it easy for guests to report if things are wrong and know how to deal with and respond to complaints</li><li>Make it easy for guests to find what they need (cutlery, condiments etc)</li><li>Look in the mirror and watch out for your colleagues – clean hair, teeth and nails</li></ul>",
  "ELearning-BehavioursTitle":
    "Delivering a Friendly and Personalised Guest Experience",
  "ELearning-BehavioursDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want every guest to feel we were both friendly and attentive to their needs, rather than just following a process</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>What great behaviours look like</li> <li>Everyone is special</li> <li>Be warm and approachable to colleagues as well as guests</li> <li>Be available and approachable to all guests even if you’ve not had contact with them</li> <li>Be mindful about guests and recognise their needs, what’s acceptable for that table</li> <li>Be aware of how you look when you’re not at the table</li> <li>Look up not down</li> <li>Be approachable throughout the whole experience</li> <li>Glide don’t run, be calm and in control</li> <li>Body language – eye contact, smiling, open relaxed body, mirroring</li> <li>How this influences the guest’s own behaviours and emotions</li> <li>Acting like a host</li> <li>Being attentive</li>  </ul>",
  "ELearning-ConversationTitle": "Building Relationships Through Conversation",
  "ELearning-ConversationDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want every guest to feel that our conversations were engaging and relevant, rather than scripted</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>Read, then engage creatively</li> <li>Recognising rapport when you see it or feel it</li> <li>Identify when people want to be left alone</li> <li>How engaging conversations make guests feel (relaxed/ special/ welcome)</li> <li>Don’t sound scripted</li> <li>Treat guests as individuals</li> <li>Be confident at treating guests differently depending on who they are</li> <li>How it can relax you as well as the guest</li> <li>Mini relationships</li> <li>The potential impact on what the guest spends (and what they tip)</li> <li>If your English is poor, use role play to get over barriers and build confidence</li>  </ul>",
  "ELearning-HomeTitle": "",
  "ELearning-HomeDescription":
    "<p>Guest Experience Management (GEM) is at the heart of any hospitality operation that wants to drive sales. The other pages on this site show how you are doing. But what can you do to improve your results? This page is designed to help, with access to a suite of ‘Little GEM’ elearning modules on the key parts of the guest experience. Think of them as inspiration rather than training.</p>  <p>Everything starts with your brand promise – the experience you want for your guests, and how it stands out from your competitors. This can be defined using the segments in the GEM Wheel (see image) which are common to most guest operations and often used as a framework for operational assessments and team training. Your vocabulary may be slightly different, but the principles are the same.</p>  <p>Each of the outer segments on the wheel links to a related elearning module. Each one takes about 25 minutes to complete and could work well in team meeting sessions where several team members could discuss what the subject matter means to them. The modules are interactive, with an overview video and situational questions to test and reinforce knowledge.</p>",
  "ELearning-KnowledgeTitle": "Showing Passion for What We Offer",
  "ELearning-KnowledgeDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want guests to feel our team are enthusiastic and able to advise confidently on the contents of the menu and of specific dishes</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>The impact you can make on someone’s experience by confidently guiding them through menu choices – and how that might feel</li> <li>Some top tips on how to share that passion with guests (food descriptions, knowing how dishes are prepared and the provenance of ingredients etc.)</li> <li>Show interest in the chef’s work, be curious and ask how you would make it at home</li> <li>Learn for next time when you don’t know the answer to a guest’s question</li> <li>What’s your favourite dish (or what would you order if you haven’t tried the dishes) – rehearse it if necessary </li> <li>Best ways to learn about what your brand offers (menu training, tasting & trying, memory aids, ask the chef)</li>  </ul>",
  "ELearning-OrganisationTitle": "Delivering Effortless Service",
  "ELearning-OrganisationDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want our team to work together so efficiently in pursuit of every guest’s needs that the service appears effortless</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>“I go visit places where I know it’s going to be easy”</li> <li>Taking all the sticking points out of the experience so there’s nothing for the guest to worry about</li> <li>Rehearsing the steps of service so you can relax into a structure and nail it every time (knowing them like the back of your hand)</li> <li>Awareness of how you impact other team members and vice versa </li> <li>Be aware of what everyone else is doing</li> <li>Leave things as you would want to find them - respect for colleagues</li> <li>Recognising bottlenecks (stock, cutlery etc)</li> <li>Knowing when to step away from the process</li> <li>Recognise when to set up a tab</li> <li>What great looks like to the guest</li> <li>Being one step ahead and anticipating things before they happen</li> <li>Prep for opening/ handover/ closure and pre-shift meetings</li>  </ul>",
  "ELearning-PaceTitle": "Getting The Timings Right",
  "ELearning-PaceDescription":
    "<p><span class='highlight'>Business aspiration:</span> I don’t want any guest to feel they were either rushed or left waiting at any stage of the experience</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>Knowing the expectations for different parts of your brand’s experience</li> <li>If table is need back by a particular time then provide service to match</li> <li>Delivering to kids more quickly can be helpful for families</li> <li>Recognise when to reprioritise orders for BOH</li> <li>Communicate with kitchen</li> <li>If there’s no rush then offer something while they wait </li> <li>It’s hard to exceed expectations, but easy to disappoint</li> <li>Judging the needs of different guests and reacting accordingly</li> <li>It’s ok to ask whether it’s a business lunch or pre-theatre</li> <li>Be aware when to deliver the bill</li> <li>Be positive and focussed on the guests to the end – don’t start laying for breakfast during dinner</li> <li>What great feels like</li>  </ul>",
  "ELearning-PaymentTitle": "Efficient Payment & Friendly Goodbyes",
  "ELearning-PaymentDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want every guest to feel that paying the bill was easy, efficient & appreciated, and that we would be pleased to see them again</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>Make the link between this stage of the experience and, if not done right, leaving a lasting bad impression</li> <li>How to identify when guests are ready to pay their bill - if people are looking around then you’ve failed</li> <li>It’s hard to exceed expectations, but easy to disappoint</li> <li>Things you can do to make it easy and efficient (e.g. knowing the core process but also the variations such as payment apps)</li> <li>Facilitating separate payments</li> <li>Checking the bill first</li> <li>Finding out how guests wish to pay before processing bill</li> <li>Checking for vouchers</li> <li>Being clear about whether service charge is included</li> <li>Goodbyes – not just when guests are walking out but also during payment, from the person they had a relationship with</li>  </ul>",
  "ELearning-PromotionTitle": "Getting Guests Through the Door",
  "ELearning-PromotionDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want potential guests to be encouraged to visit us as a result of either kerb appeal, promotions or brand digital presence </p> <p><span class='highlight'>Consider</span></p> <ul>  <li>Things that can influence kerb appeal (windows, doorways, A-boards, flower baskets etc.)</li> <li>Being creative and catching people’s attention</li> <li>Maintaining your social presence (if that’s part of your role), expressing the brand personality online (not your own), and showing you care when things go wrong</li> <li>Knowing the process to follow when complaints come through on social media</li> <li>Look at what your competitors are doing</li> <li>Be interested in your own social media</li> <li>Think about what hooks people to come in</li> <li>Tell people about future events</li> <li>Relationship with head office support team</li>  </ul>",
  "ELearning-QualityTitle": "Ensuring Excellent Quality Products",
  "ELearning-QualityDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want to ensure guest satisfaction with every dish delivered matching the agreed specification for quality, temperature and presentation</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>The importance of the specification for consistency and managing expectations</li> <li>Take responsibility and ownership </li> <li>Commit to the spec and make sure we’ve delivered as a team</li> <li>Ensure every guest gets what’s expected</li> <li>Look out for your team and colleagues</li> <li>Understand boundaries of responsibility (e.g. whether you can offer an alternative if guest doesn’t like something)</li> <li>How to build up your knowledge/experience of the specifications and what to look out for</li> <li>What to do if you notice something isn’t right before serving? (send it back, speak to boh, managing the guest communication)</li> <li>How to do a genuine checkback (rather than a robotic one) and the things you might pick up</li> <li>Showing that you care when you identify that a guest isn’t happy</li> <li>Creativity when dealing with complaints</li>  </ul>",
  "ELearning-SellingTitle": "Happier Guests Spend More",
  "ELearning-SellingDescription":
    "<p><span class='highlight'>Business aspiration:</span>I want every guest to willingly spend more, and to feel that their experience was enhanced as a result</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>What upselling is and what it may also be known as</li> <li>What’s in it for the business (revenue)/ the guest (enhanced experience)/ and for you (tips & pride in a job well done)</li> <li>Typical opportunities where you can upsell items: Snacks, bread/ olives, starters, aperitif, side orders, a bottle of wine if two glasses are ordered, a premium option/ more expensive dish, desserts, extra drinks, coffees, water</li> <li>Drinks – get 1st drink delivered quickly then recognise chance to offer a 2nd (most people have 1.5 drinks)</li> <li>Notice empty glasses and act on it straight away</li> <li>The most successful ways to upsell (recommendations, food & drink pairings, knowing the menu inside out etc.)</li> <li>What’s the worst that can happen – guest says no (don’t worry about their budgets, everyone has chance to say no but most people aren’t offered in first place) </li> <li>Be appropriate and personal</li>  </ul>",
  "ELearning-WelcomeTitle": "Making Every Guest Feel Welcome",
  "ELearning-WelcomeDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want every guest to feel that we will look after them well as a result of the welcome they received</p> <p><span class='highlight'>Consider</span></p> <ul> <li>What a great welcome looks like, wherever it happens (not just at the door, but the first impression on your Facebook page, or the way you answer a call)</li> <li>Choosing your behaviours for the day</li> <li>Being ready for the welcome – names, highchairs etc.</li> <li>Personalise the welcome for the guest</li> <li>Appearing ready even when you’re not</li> <li>Taking the lead and making guests feel looked after and relaxed</li> <li>Awareness of the door & walk-ins (especially when there’s not a host)</li> <li>How a great welcome sets the scene for a great experience</li> <li>Top tips for nailing the welcome every single time even when you’re tired/ busy or when guests are rude</li> </ul>",
  "ELearning-WordOfMouthTitle": "Encouraging Recommendations",
  "ELearning-WordOfMouthDescription":
    "<p><span class='highlight'>Business aspiration:</span> I want to give every guest positive ‘social currency’ during their experience that they will recommend us when talking to friends</p> <p><span class='highlight'>Consider</span></p> <ul>  <li>People share stories not information – “build stories by creating theatre”</li> <li>Make someone’s day – think about how you can do this</li> <li>There are not many industries where you get the opportunity to change people’s day everyday</li> <li>Every one of us can make a difference to our guests</li> <li>Take a genuine interest in guests</li> <li>People recognise when you’ve gone above and beyond</li> <li>People recommend when something has been exceptional, so we need to actively create memorable highlights in a guest’s experience</li> <li>Think about your own emotions from a recent experience and how these have translated into stories you have told</li> <li>The element of surprise</li>  </ul>",
  "gem-wheel-people":
    "<h2><i class='fa fa-users' aria-hidden='true'></i>People</h2> <p>Your people are the ambassadors for your brand. So ensuring that they know what is expected of them is essential to deliver the experience you want. This begins with the welcome people receive on arrival and continues through the way the team behaves and interacts with guests during the visit. Find ways to get guests talking about your service.</p>",
  "gem-wheel-process":
    "<h2><i class='fa fa-cog' aria-hidden='true'></i>Process</h2> <p>A good experience should feel effortless and well-timed to the guest, yet efficient and profitable for your business. It can be a tough balance to make, particularly when demand is unpredictable. Think about how you will notice if timings are slipping and what you could put in place to prevent this.</p>",
  "gem-wheel-product":
    "<h2><i class='fa fa-coffee' aria-hidden='true'></i>Product</h2> <p>The product, usually food, is the most tangible part of the experience and is commonly referred to in social reviews. Teams need to be able recognise what the product should look like and be able to answer questions from guests. It’s a great opportunity for engagement, but also for selling more – the key is how you do it.</p>",
  "gem-wheel-place":
    "<h2><i class='fa fa-home' aria-hidden='true'></i>Place</h2> <p>Guests will form an impression of you before they even arrive through your online presence, promotions and kerb appeal. They will notice things that are not clean, particularly toilets. And they will leave with an overall perception that, positive or negative, will get shared.</p>",
  ELEARNING_VIEWER_Back_to_Selection: "Back to Module Selection",
  ELEARNING_VIEWER_Launch_Module: "Launch Module",
  ELEARNING_VIEWER_Error_Message:
    "An error has occured, please refresh the page and try again.",
  ELearning_Description_Title: "Module Description",
  ELearning_Module: "Module",
  // Notification Centre
  NOTIFICATION_CENTRE_ICON_Title: "Notifications",
  NOTIFICATION_CENTRE_DIALOG_Notifications: "My Notifications:",
  NOTIFICATION_CENTRE_DIALOG_Empty_Notifications:
    "You have no new notifications for this session",
  NOTIFICATION_CENTRE_DIALOG_Close: "Close",
  NOTIFICATION_CENTRE_ICON_New_Popup_Tooltip: "New Notification",
  NOTIFICATION_CENTRE_Filter_Refresh_Title: "Hub Session Duration",
  NOTIFICATION_CENTRE_Filter_Refresh_Message:
    "During an extended Hub session, filter settings and other data can fall behind the latest visit content.",
  NOTIFICATION_CENTRE_Filter_Refresh_Instructions:
    "Refresh your Session to ensure you have the latest data. If you are in the middle of something, you can defer this to a later time.",
  NOTIFICATION_CENTRE_Filter_Refresh_Refresh_Settings: "Refresh Settings Now",
  NOTIFICATION_CENTRE_Filter_Refresh_Remind_Later: "Remind Me Later",

  // Appeals

  APPEALS_STATUS_HEADER: "Status: ",
  APPEALS_QUESTIONS_HEADER: "Which question(s) do you wish to appeal:",
  APPEALS_QUESTIONS_WHOLE_REPORT_SELECTED: "Whole report selected",
  APPEALS_QUESTIONS_BONUS_APPEAL_ONLY: "Bonus appeal only",
  APPEALS_APPROVER_HEADER: "Approver: ",
  APPEALS_CATEGORY_HEADER: "Category: ",
  APPEALS_REVIEW_DECISION_HEADER: "Review Decision: ",
  APPEALS_COMMENTS_HEADER: "What would you like to say?",
  APPEALS_REVIEW_DECISION: "Review decision: ",
  APPEALS_REVIEWING: "Review",
  APPEALS_CANCEL: "Reject",
  APPEALS_EDIT: "Edit",
  APPEALS_GRANT: "Complete",
  APPEALS_REJECT: "Reject",
  APPEALS_CATEGORIES_SELECT_LABEL: "Categories: ",
  APPEALS_SEND_NOTIFICATION_CHECKBOX_LABEL: "Notify",
  APPEALS_WHOLE_REPORT_LABEL: "Whole report - no specific question",
  APPEALS_STATUS_NEW: "New",
  APPEALS_STATUS_RAISED: "Raised",
  APPEALS_STATUS_APPROVED_FOR_REVIEW: "Approved for review",
  APPEALS_STATUS_CANCELLED: "Cancelled",
  APPEALS_STATUS_UNDER_REVIEW: "Under review",
  APPEALS_STATUS_REJECTED: "Rejected",
  APPEALS_STATUS_GRANTED: "Reviewed",
  APPEALS_VALIDATION_QUESTIONS: "You must select at least one question",
  APPEALS_VALIDATION_SELECT: "Please select a ",
  APPEALS_VALIDATION_APPROVER: "You must select an approver",
  APPEALS_WORKFLOW_STATUS_AppealRaised: "Raised",
  APPEALS_WORKFLOW_STATUS_AppealApprovedForReview: "Approved for review",
  APPEALS_WORKFLOW_STATUS_AppealCancelled: "Rejected",
  APPEALS_WORKFLOW_STATUS_AppealUnderReview: "Under review",
  APPEALS_WORKFLOW_STATUS_AppealRejected: "Rejected",
  APPEALS_WORKFLOW_STATUS_AppealGranted: "Reviewed",
  APPEALS_EDIT_BUTTON_TEXT: "Edit",
  APPEALS_Edit_SAVE_BUTTON_TEXT: "Save",
  APPEALS_Edit_CANCEL_BUTTON_TEXT: "Cancel",
  APPEALS_COMPLETE_REVIEW_BUTTON_TEXT: "Complete",
  APPEALS_APPROVE_BUTTON_TEXT: "Approve",
  APPEALS_REJECT_BUTTON_TEXT: "Reject",
  ATTACHMENT_DOWNLOAD: "Download",
  ATTACHMENT_DELETE: "Delete",
  ATTACHMENT_INVALID_EXTENSION: "files are not allowed to be uploaded.",
  ATTACHMENT_INVALID_MAX_SIZE_PART_A: "Files greater than",
  ATTACHMENT_INVALID_MAX_SIZE_PART_B: "MB are not allowed to be uploaded.",
  ATTACHMENT_CONFIRM_DELETE: "Are you sure you want to delete attachment",
  ATTACHMENT_SELECT_FILE: "Attach",
  ATTACHMENT_UPLOAD: "Upload",
  ATTACHMENT_UPLOAD_ATTACHMENT: "Upload attachment",
  ATTACHMENT_DELETE_ATTACHMENT: "Delete attachment",
  ATTACHMENT_CANCEL: "Cancel",
  ATTACHMENTS: "Attachments",
  ATTACHMENT: "Attachment",
  ATTACHMENTS_PENDING: "Attached files",
  ATTACHMENT_CONFIRM_UPLOAD: "Are you sure you want to upload attachment",
  ATTACHMENT_UPLOADED_BY: "Uploaded by",
  // Media tab

  MEDIA_TAB_NO_MEDIA_MESSAGE: "There is no media to display",
  MEDIA_TAB_END_OF_PHOTOS: "No more media to display",

  // Conversations

  CONVERSATIONS_LOAD_MORE: "Load more conversations",
  CONVERSATIONS_NO_MORE: "There are no further conversations.",
  CONVERSATIONS_NONE: "There are no conversations to view",
  CONVERSATION_MESSAGES_LOADING_MORE: "Loading more messages...",
  CONVERSATION_MESSAGES_NO_MORE: "There are no further messages.",
  CONVERSATION_MESSAGE_MEDIA_INVALID_FILETYPE:
    "files are not allowed to be uploaded",
  CONVERSATION_MESSAGE_MEDIA_INVALID_FILESIZE_A: "files greater than",
  CONVERSATION_MESSAGE_MEDIA_INVALID_FILESIZE_B:
    "MB are not allowed to be uploaded",
  CONVERSATION_MESSAGE_MEDIA_SELECT_FILE: "Select file",
  CONVERSATION_MESSAGE_MEDIA_LOADING_VIDEO: "Loading video...",
  CONVERSATION_MESSAGE_MEDIA_LOADING_IMAGE: "Loading image...",
  MEDIA_MESSAGE_MEDIA_LOADING_AUDIO: "Loading audio...",
  MEDIA_MESSAGE_MEDIA_FILE: "File:",
  CONVERSATIONS_SELECT: "Select a conversation",
  CONVERSATIONS_SENT_PRIVATELY_TO: "Sent privately to",
  CONVERSATIONS_SENT_PRIVATELY_VIA: "via",
  CONVERSATION_MESSAGE_ADD_MESSAGE_TEXT: "Add message text...",
  CONVERSATION_MESSAGE_SEND: "Send",
  CONVERSATION_MESSAGE_CANNOT_SEND:
    "Cannot send message due to time limit passed",
  CONVERSATION_MESSAGE_SENDING: "sending...",
  CONVERSATION_MESSAGE_YESTERDAY: "yesterday",
  CONVERSATION_SOURCE_SMS: "SMS",
  CONVERSATION_SOURCE_WHATSAPP: "WhatsApp",
  CONVERSATION_SOURCE_MESSENGER: "Facebook Messenger",
  CONVERSATION_SOURCE_EMAIL: "Email",
  CONVERSATION_SOURCE_GOOGLE_BUSINESS_MESSENGER: "Google Business Messenger",
  CONVERSATION_SELECTED: "Selected conversation",
  CONVERSATION_MESSAGE_TIME_FORMAT: "HH:mm",
  CONVERSATION_MESSAGE_DATE_FORMAT: "ddd DD MMM YY",
  CONVERSATION_MESSAGE_DATE_TIME_FORMAT_LONG: "HH:mm ddd DD MMM YYYY",
  MEDIA_MESSAGE_IMAGE_THUMBNAIL_Alt_Text: "Message Image",
  MEDIA_MESSAGE_IMAGE_THUMBNAIL_Enlarge: "Click to View Image",
  MEDIA_MESSAGE_IMAGE_MODAL_Close: "Close",
  MEDIA_MESSAGE_IMAGE_MODAL_Download: "Download Image",
  MEDIA_MESSAGE_IMAGE_Alt_Text: "Message Image",
  MEDIA_MESSAGE_VIDEO_THUMBNAIL_Alt_Text: "Message Video",
  MEDIA_MESSAGE_VIDEO_THUMBNAIL_Enlarge: "Click to View Video",
  MEDIA_MESSAGE_VIDEO_MODAL_Close: "Close",
  MEDIA_MESSAGE_VIDEO_MODAL_Download: "Download Video",
  MEDIA_MESSAGE_VIDEO_No_Video_Support:
    "Sorry, the browser you are using does not support audio playback.",
  INBOX_TABS_Conversation: "Conversation",
  VISIT_TYPE_OPTION_CV: "Conversation",
};

export default en;
