import { Icon } from "@material-ui/core";
import { useRef, useState } from "react";
import { i18n } from "../../../../localizations";
import { useAttachmentsDisallowedFileExtensions } from "../../../../state/hooks/appeals/useAttachmentsDisallowedFileExtensions";
import { AppealsButton } from "../../../InboxAppeals/components";
import { AppealsStyles } from "../../../InboxAppeals/styles";
import { FormFieldStyles } from "../../../_common/formField/styles/formFieldStyles";
import AttachmentFileSelectConfirmDialog from "./attachmentFileSelectConfirmDialog";

type Props = {
  onFileSelected: (files: File, caseId?: number) => void | Promise<void>;
  maxFileSize?: number | undefined;
  evaluateIfFileIsInvalid?: ((file: File) => string | undefined) | undefined;
  className?: string;
  iconButtonOnly?: boolean;
};

const AttachmentFileSelector = (props: Props) => {
  const attachmentsDisallowedFileExtensions =
    useAttachmentsDisallowedFileExtensions();

  const appealsClasses = AppealsStyles();
  const formFieldClasses = FormFieldStyles();

  const [isSelectingFile, setIsSelectingFile] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const [selectedFileInvalidReason, setSelectedFileInvalidReason] = useState<
    string | undefined
  >(undefined);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (!fileList) {
      return;
    }

    const file = fileList[0];

    const extension = file.name.split(".").pop();

    const invalidExtension =
      extension &&
      attachmentsDisallowedFileExtensions?.find((x) =>
        x.toLowerCase().includes(extension?.toLowerCase())
      );

    const maxFileSizeBytes =
      props.maxFileSize ??
      (process.env.REACT_APP_APPEALS_ATTACHMENT_MAX_FILE_SIZE_BYTES
        ? parseInt(process.env.REACT_APP_APPEALS_ATTACHMENT_MAX_FILE_SIZE_BYTES)
        : 0);

    const maxFileSizeMegabytes = maxFileSizeBytes / 1024 / 1024;

    const maxFileSizeExceeded = file.size > maxFileSizeBytes;

    let propsFileInvalidReason: string | undefined = undefined;
    if (props.evaluateIfFileIsInvalid) {
      propsFileInvalidReason = props.evaluateIfFileIsInvalid(file);
    }

    if (invalidExtension) {
      setSelectedFileInvalidReason(
        `${invalidExtension} ${i18n.translate("ATTACHMENT_INVALID_EXTENSION")}`
      );
    } else if (maxFileSizeExceeded) {
      setSelectedFileInvalidReason(
        `${i18n.translate(
          "ATTACHMENT_INVALID_MAX_SIZE_PART_A"
        )} ${maxFileSizeMegabytes}${i18n.translate(
          "ATTACHMENT_INVALID_MAX_SIZE_PART_B"
        )}`
      );
    } else if (propsFileInvalidReason) {
      setSelectedFileInvalidReason(propsFileInvalidReason);
    } else {
      setSelectedFileInvalidReason(undefined);
      setSelectedFile(file);
      setIsUploadDialogOpen(true);
    }

    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <input
        data-testid="attachments-upload-file-input"
        type="file"
        style={{ display: "none" }}
        multiple={false}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      {selectedFileInvalidReason && (
        <div className={`${appealsClasses.flexRow}`}>
          <span className={`${appealsClasses.errorSpan}`}>
            {selectedFileInvalidReason}
          </span>
        </div>
      )}
      <div className={props.className}>
        <AppealsButton
          name={i18n.translate("ATTACHMENT_SELECT_FILE")}
          promise={() => {
            fileInputRef?.current?.click();
            return Promise.resolve();
          }}
          isPending={isSelectingFile}
          setIsPending={setIsSelectingFile}
          className={props.className}
          showIcon={true}
          IconButton={props.iconButtonOnly}
        />
        <span>
          {isSelectingFile && (
            <Icon
              className={`icon fas fa-spinner fa-spin fa-3x fa-fw ${formFieldClasses.iconStatus} ${appealsClasses.smallIcon}`}
            />
          )}
        </span>
      </div>
      <AttachmentFileSelectConfirmDialog
        isOpen={isUploadDialogOpen}
        setIsOpen={setIsUploadDialogOpen}
        selectedFile={selectedFile}
        onFileSelected={props.onFileSelected}
      />
    </>
  );
};

export default AttachmentFileSelector;
