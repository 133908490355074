import { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { UpdateWorkflowStatusCommand } from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { executeCaseCommand } from "../../../state/actions/TaskCentre-CaseList-Actions";
import {
  WorkflowStatus,
  WorkflowStatuses,
} from "../../../state/types/TaskCentreCases";
import WorkflowStatusButtons from "./WorkflowStatusButtons";
import WorkflowStatusSelect from "./workflowStatusSelect";

interface IProps {
  caseId?: number;
  status?: WorkflowStatus;
  onStatusSelected?: (newStatus: WorkflowStatus) => void;
}

const WorkflowStatusField = (props: IProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [caseId, setCaseId] = useState(props.caseId);
  const [statusValue, setStatusValue] = useState(props.status);

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );

  const changeStatus = (newStatus: WorkflowStatus) => {
    setStatusValue(newStatus);

    const noChange = newStatus === props.status;
    if (noChange) {
      return;
    }

    if (props.onStatusSelected) {
      props.onStatusSelected(newStatus);
    }

    if (props.caseId) {
      const statusCommand = new UpdateWorkflowStatusCommand(
        props.caseId,
        newStatus
      );
      dispatch(executeCaseCommand(props.caseId, [statusCommand]));
    }
  };

  useEffect(() => {
    if (caseId !== props.caseId) {
      setCaseId(props.caseId);
    }

    setStatusValue(props.status);
  }, [caseId, props.caseId, props.status]);

  return (
    <>
      {isMobileView &&
        WorkflowStatuses &&
        WorkflowStatuses.length > 0 &&
        statusValue && (
          <WorkflowStatusSelect
            statusValue={statusValue}
            changeStatus={changeStatus}
            workflowStatuses={WorkflowStatuses}
          />
        )}
      {!isMobileView && statusValue && (
        <WorkflowStatusButtons
          statusValue={statusValue}
          changeStatus={changeStatus}
          workflowStatuses={WorkflowStatuses}
        />
      )}
    </>
  );
};

export default WorkflowStatusField;
