import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { GuestMessageConfirmDialogStyles } from "../styles/guestMessageConfirmDialogStyles";
import { CardDetailsConfirmation } from "./";

interface IProps {
  isOpen: boolean;
  reason?: string | undefined;
  recipient: string;
  voucherValue?: string | undefined;
  messageText?: string | undefined;
  sendVoucher: boolean;
  onClosing: () => void;
  onConfirmation: () => void;
  attachments: File[] | undefined;
  voucherDescription: string | undefined;
}

const GuestMessageConfirmDialog = (props: IProps) => {
  const classes = GuestMessageConfirmDialogStyles();

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClosing}
      aria-labelledby="guest-contact-confirm-title"
      aria-describedby="guest-contact-confirm-description"
      PaperProps={{ classes: { root: classes.wideDialog } }}
    >
      <DialogTitle id="guest-contact-confirm-title">
        {i18n.translate("TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_Title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="guest-contact-confirm-description"
          className={classes.dialogText}
          component={"span"}
        >
          <dl className={classes.flexList}>
            <CardDetailsConfirmation
              label={i18n.translate(
                "TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_Description"
              )}
              labelText={props.recipient}
            />
            {!props.sendVoucher && (
              <CardDetailsConfirmation
                label={i18n.translate("GIFT_CARD_MESSAGE_TO_GUEST")}
                labelText={props.messageText ?? ""}
              />
            )}
            {props.sendVoucher && (
              <CardDetailsConfirmation
                label={i18n.translate("GIFT_CARD_MESSAGE_TO_GUEST")}
                labelText={`
                ${props.messageText ?? ""}
                
                ${i18n.translate("GIFT_CARD_MESSAGE_APPENDIX")}
                `}
              />
            )}
            {props.sendVoucher && (
              <CardDetailsConfirmation
                label={i18n.translate("GIFT_CARD_REASON_FOR_CARD")}
                labelText={props.reason ?? ""}
              />
            )}

            {props.sendVoucher && props.voucherDescription && (
              <CardDetailsConfirmation
                label={i18n.translate("TOGGLE_VOUCHER_TYPE")}
                labelText={props.voucherDescription}
              />
            )}

            {props.sendVoucher && (
              <CardDetailsConfirmation
                label={i18n.translate("GIFT_CARD_AMOUNT")}
                labelText={`${props.voucherValue}` ?? ""}
              />
            )}
            {props.attachments && props.attachments.length > 0 && (
              <>
                {props.attachments.map((attachment, index) => {
                  return (
                    <CardDetailsConfirmation
                      label={`${i18n.translate("ATTACHMENT")} ${index + 1}`}
                      labelText={attachment.name}
                    />
                  );
                })}
              </>
            )}
          </dl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClosing}>
          {i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_CancelTask"
          )}
        </Button>
        <Button
          onClick={props.onConfirmation}
          color="primary"
          autoFocus
          variant="contained"
        >
          {i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Message_Confirmation_SendTask"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuestMessageConfirmDialog;
