import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  deletionCaseTitle: {
    display: "block",
    marginTop: "10px",
    fontWeight: 900,
  },
}));

export const DeleteTaskDialogStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
