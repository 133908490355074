import React from "react";
import { useSelector } from "react-redux";
import TaskCentreEditor from "../taskCentreEditor/taskCentreEditor";
import { VisitTasksPending } from "./state/selectors";
import InboxTaskSelector from "../InboxTaskSelector/inboxTaskSelector";
import { LinearProgress } from "@material-ui/core";
import { ICaseDetails } from "../../state/types/TaskCentreCases";

interface IProps {
  case: ICaseDetails | undefined;
  visitId: number | undefined;
  showTaskSelector?: boolean;
  isMobileView: boolean;
}

const InboxTaskDetails = (props: IProps) => {
  const casesPending = useSelector(VisitTasksPending);

  return (
    <>
      {casesPending && (
        <div>
          <LinearProgress color="primary" />
        </div>
      )}

      {!casesPending && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0.5%",
          }}
        >
          {props.showTaskSelector && <InboxTaskSelector />}

          <TaskCentreEditor
            hideDeleteTask={false}
            case={props.case}
            selectedVisitId={props.visitId}
            isMobileView={props.isMobileView}
          />
        </div>
      )}
    </>
  );
};

export default InboxTaskDetails;
