import { IGuestDetails } from "../types/GuestDetails";

export const GUEST_DETAILS_PENDING = "GUEST_DETAILS_PENDING";
export const GUEST_DETAILS_SUCCESS = "GUEST_DETAILS_SUCCESS";
export const GUEST_DETAILS_ERROR = "GUEST_DETAILS_ERROR ";
export const FETCH_GUEST_DETAILS = "FETCH_GUEST_DETAILS";

export interface IGuestDetailsSuccess {
  type: typeof GUEST_DETAILS_SUCCESS;
  guestDetails: IGuestDetails;
  visitId: number;
}

export function guestDetailsSuccess(
  guestDetails: IGuestDetails,
  visitId: number
): IGuestDetailsSuccess {
  return {
    type: GUEST_DETAILS_SUCCESS,
    guestDetails,
    visitId,
  };
}

export interface IGuestDetailsPending {
  type: typeof GUEST_DETAILS_PENDING;
  visitId: number;
}

export function guestDetailsPending(visitId: number): IGuestDetailsPending {
  return {
    type: GUEST_DETAILS_PENDING,
    visitId,
  };
}

export interface IGuestDetailsError {
  type: typeof GUEST_DETAILS_ERROR;
  visitId: number;
  error: string;
}

export function guestDetailsError(
  visitId: number,
  error: string
): IGuestDetailsError {
  return {
    type: GUEST_DETAILS_ERROR,
    visitId,
    error: error,
  };
}

export interface IFetchGuestDetails {
  type: typeof FETCH_GUEST_DETAILS;
  visitId: number;
}

export function fetchGuestDetails(visitId: number): IFetchGuestDetails {
  return {
    type: FETCH_GUEST_DETAILS,
    visitId,
  };
}

export type GuestDetailsActions =
  | IGuestDetailsPending
  | IGuestDetailsSuccess
  | IGuestDetailsError
  | IFetchGuestDetails;
