import Alert from "@material-ui/lab/Alert";
import { i18n } from "../../../localizations";
import { ConversationListEmptyStyles } from "../styles";

const ConversationListEmpty = () => {
  const classes = ConversationListEmptyStyles();

  return (
    <div className={classes.emptyVisitsMessage}>
      <Alert elevation={1} variant="filled" severity="info">
        {i18n.translate("CONVERSATIONS_NONE")}
      </Alert>
    </div>
  );
};

export default ConversationListEmpty;
