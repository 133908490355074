import { ConversationSourceEnum } from "./Conversations";

export enum SignalRNotificationTypes {
    ConversationMessageAdded,
    VisitSaved,
    CaseSaved,
    HubUiUpdateStarting,
    HubUiUpdateCompleted
}

export type ConversationMessageAddedSignalRNotification = {
    notificationType: SignalRNotificationTypes.ConversationMessageAdded;
    conversationId: number;
    branchId: number;
    conversationMessageId: number;
    source: ConversationSourceEnum;
    isInbound: boolean;
    sender: string;
    messageText: string | undefined;
    createdDate: Date;
    medias: ConversationMessageAddedSignalRNotificationMedia[];
    visit: ConversationMessageAddedVisit;
    createdByManagerId: number | undefined;
    senderCorrelationGuid: string | undefined;
};

export type ConversationMessageAddedSignalRNotificationMedia = {
    id: number,
    filename?: string | undefined,
    contentType: string,
    contentSize: number
};

export type ConversationMessageAddedVisit = {
    visitId: number,
    startDate: Date,
    endDate: Date | undefined,
    firstMessageText: string | undefined
};

export type VisitSavedSignalRNotification = {
    notificationType: SignalRNotificationTypes.VisitSaved;
    visitId: number;
    visitStatus: string
};

export type CaseSavedSignalRNotification = {
    notificationType: SignalRNotificationTypes.CaseSaved;
    caseId: number;
    visitId: number;
};

export type HubUiUpdateStartingSignalRNotification = {
    notificationType: SignalRNotificationTypes.HubUiUpdateStarting;
};

export type HubUiUpdateCompletedSignalRNotification = {
    notificationType: SignalRNotificationTypes.HubUiUpdateCompleted;
};

export type SignalRNotifications = 
    | ConversationMessageAddedSignalRNotification
    | VisitSavedSignalRNotification
    | CaseSavedSignalRNotification
    | HubUiUpdateStartingSignalRNotification
    | HubUiUpdateCompletedSignalRNotification;
