import { Button, Icon, useMediaQuery, useTheme } from "@material-ui/core";
import { i18n } from "../../../localizations";
import { AttachmentFileSelector } from "../../inboxViews/components/attachments";
import { TasksStyles } from "../../taskCentreEditor/styles/tasksStyles";
import { IGuestDetails } from "../../../state/types/GuestDetails";
import { IReviewResponseTemplate } from "../../../state/types/VisitReport";
import { useSelector } from "react-redux";
import { CurrentUserId } from "../../taskCentreEvents/state/selectors";
import { IAppNotification } from "../../../state/types/AppNotification";
import DescriptionIcon from "@material-ui/icons/Description";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import SendIcon from "@material-ui/icons/Send";
type props = {
  hasError: boolean;
  guestContactDetails: IGuestDetails | undefined;
  isButtonDisabled: boolean;
  next: () => void;
  contactMade: boolean;
  setContactConfirmDialogOpen: (boolean: boolean) => void;
  availableTemplates: IReviewResponseTemplate[];
  handleTemplateSelect: () => void;
  onFileSelected: (file: File) => void;
  setVouchersActive: () => void;
  vouchersActive?: boolean;
  voucherHasBeenSent: boolean;
  sendVoucher: boolean;
  messageText: string;
  appNotification: IAppNotification | undefined;
};
const GuestContactButtonBar = (props: props) => {
  const {
    hasError,
    guestContactDetails,
    isButtonDisabled,
    next,
    setContactConfirmDialogOpen,
    availableTemplates,
    handleTemplateSelect,
    setVouchersActive,
    vouchersActive,
    voucherHasBeenSent,
    sendVoucher,
    messageText,
    appNotification,
    onFileSelected,
  } = props;

  const buttonClasses = TasksStyles();
  const currentUserId = useSelector(CurrentUserId);
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );


  return (
    <div className={buttonClasses.guestContactButtonRowContainer}>
      {availableTemplates && availableTemplates.length > 0 && (
        <Button
          size="small"
          variant="contained"
          className={` ${buttonClasses.guestContactButton}`}
          onClick={() => handleTemplateSelect()}
          startIcon={<DescriptionIcon className={buttonClasses.buttonIcon} />}
        >
          <span className={buttonClasses.buttonLabel}>
            {i18n.translate("VISIT_DETAILS_REVIEW_RESPONSE_Use_Template")}
          </span>
        </Button>
      )}
      {currentUserId && (
        <AttachmentFileSelector
          iconButtonOnly={isMobileView}
          onFileSelected={onFileSelected}
          className={`${buttonClasses.guestContactAttachmentsButton} ${buttonClasses.guestContactButton} ${buttonClasses.attachmentInput} `}
        />
      )}

      <Button
        className={
          sendVoucher
            ? `${buttonClasses.activeVoucherButton} ${buttonClasses.guestContactButton}`
            : ` ${buttonClasses.guestContactButton}`
        }
        size="small"
        variant="contained"
        onClick={() => setVouchersActive()}
        disabled={!vouchersActive || voucherHasBeenSent === true}
        startIcon={<CardGiftcardIcon className={buttonClasses.buttonIcon} />}
      >
        <span className={buttonClasses.buttonLabel}>
          {i18n.translate("GIFT_CARD_BUTTON_NAME_TOGGLE")}
        </span>
      </Button>
      <Button
        className={` ${buttonClasses.guestContactButton}`}
        size="small"
        variant="contained"
        disabled={
          messageText.trim().length > 0 || appNotification !== undefined
        }
        onClick={() => setContactConfirmDialogOpen(true)}
        startIcon={
          <Icon className={`fad fa-file-pen ${buttonClasses.buttonIcon}`} />
        }
      >
        <span className={buttonClasses.buttonLabel}>
          {i18n.translate("TASK_CENTRE_GUEST_CONTACT_Confirm_Contact")}
        </span>
      </Button>
      {!hasError &&
        guestContactDetails &&
        guestContactDetails.contactDetails.email && (
          <Button
            className={`  ${buttonClasses.guestContactButton}`}
            size="small"
            variant="contained"
            disabled={isButtonDisabled}
            onClick={() => next()}
            startIcon={<SendIcon className={buttonClasses.buttonIcon} />}
          >
            <span className={buttonClasses.buttonLabel}>
              {i18n.translate("COMMON_BUTTON_TEXT_next")}
            </span>
          </Button>
        )}
    </div>
  );
};

export default GuestContactButtonBar;
