import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  filterInput: {
    width: "calc(100% - 10px)",
    margin: "5px",
  },
  filterLabel: {
    color: theme.palette.grey[500],
  },
  textInput: {
    paddingLeft: "2px",
  },
}));

export const SearchTextInputStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
