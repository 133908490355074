import axios, { AxiosResponse } from "axios";
import { IGraphQueryResponse } from "../graphs/types";
import {
  IClientPeriodByClientId,
  IClientPeriodByClientIdVariables,
} from "../graphs/clientPeriodByClientId";
import {
  IBranchesByClientIdAndManagerId,
  IBranchesByClientIdAndManagerIdVariables,
} from "../graphs/branchesByClientIdAndManagerId";
import {
  IQuestionnairesByClientIdAndBrand,
  IQuestionnairesByClientIdAndBrandVariables,
} from "../graphs/questionnairesByClientId";
import {
  IQuestionnaireTagsByClientId,
  IQuestionnaireTagsByClientIdVariables,
} from "../graphs/questionnaireTagsByClientId";
import {
  IPeriodHierarchyByClientIdAndBrand,
  IPeriodHierarchyByClientIdAndBrandVariables,
} from "../graphs/periodHierarchyByClientId";
import { DishFilterOptions, IVisitSourceOption } from "../types/FilterOptions";

export const visitDatesApi = (
  clientId: number
): Promise<AxiosResponse<IGraphQueryResponse<IClientPeriodByClientId>>> => {
  const variables: IClientPeriodByClientIdVariables = {
    clientId: clientId,
  };

  const query = "ClientPeriodByClientId";
  const vairableJson = JSON.stringify(variables);

  return axios.get<IGraphQueryResponse<IClientPeriodByClientId>>(
    `${
      process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL
    }${query}/variables/${encodeURI(vairableJson)}`
  );
};

export const locationsApi = (
  clientId: number,
  userId: number
): Promise<
  AxiosResponse<IGraphQueryResponse<IBranchesByClientIdAndManagerId>>
> => {
  const variables: IBranchesByClientIdAndManagerIdVariables = {
    clientId: clientId,
    managerId: userId,
  };

  const query = "BranchesByClientIdAndManagerId";
  const vairableJson = JSON.stringify(variables);

  return axios.get<IGraphQueryResponse<IBranchesByClientIdAndManagerId>>(
    `${
      process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL
    }${query}/variables/${encodeURI(vairableJson)}`
  );
};

export const questionnaireAPI = (
  clientId: number,
  brandId: number | undefined
): Promise<
  AxiosResponse<IGraphQueryResponse<IQuestionnairesByClientIdAndBrand>>
> => {
  const variables: IQuestionnairesByClientIdAndBrandVariables = {
    clientId,
    brandId: brandId,
  };

  const query = "QuestionnairesByClientId";
  const vairableJson = JSON.stringify(variables);

  return axios.get<IGraphQueryResponse<IQuestionnairesByClientIdAndBrand>>(
    `${
      process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL
    }${query}/variables/${encodeURI(vairableJson)}`
  );
};

export const questionnaireTagsAPI = (
  clientId: number
): Promise<
  AxiosResponse<IGraphQueryResponse<IQuestionnaireTagsByClientId>>
> => {
  const variables: IQuestionnaireTagsByClientIdVariables = {
    clientId,
  };

  const query = "QuestionnaireTagsByClientId";
  const vairableJson = JSON.stringify(variables);

  return axios.get<IGraphQueryResponse<IQuestionnaireTagsByClientId>>(
    `${
      process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL
    }${query}/variables/${encodeURI(vairableJson)}`
  );
};

export const periodHierarchyByClientAndBrandAPI = (
  clientId: number,
  brandId: number | undefined
): Promise<
  AxiosResponse<IGraphQueryResponse<IPeriodHierarchyByClientIdAndBrand>>
> => {
  const variables: IPeriodHierarchyByClientIdAndBrandVariables = {
    clientId,
    brandId: brandId,
  };

  const query = "PeriodHierarchyByClientId";
  const vairableJson = JSON.stringify(variables);

  return axios.get<IGraphQueryResponse<IPeriodHierarchyByClientIdAndBrand>>(
    `${
      process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL
    }${query}/variables/${encodeURI(vairableJson)}`
  );
};

export const periodHierarchyGlobalDefaultAPI = (): Promise<
  AxiosResponse<IGraphQueryResponse<IPeriodHierarchyByClientIdAndBrand>>
> => {
  const query = "PeriodHierarchyGlobalDefault";

  return axios.get<IGraphQueryResponse<IPeriodHierarchyByClientIdAndBrand>>(
    `${process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL}${query}`
  );
};

export const visitSourcesAPI = (
  managerId: number
): Promise<
  AxiosResponse<
    IGraphQueryResponse<{ visitSourceByManagerId: IVisitSourceOption[] }>
  >
> => {
  const query = "VisitSourceByManagerId";
  const variables = { managerId };
  const encodedVariables = encodeURI(JSON.stringify(variables));
  return axios.get<
    IGraphQueryResponse<{ visitSourceByManagerId: IVisitSourceOption[] }>
  >(
    `${process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL}${query}/variables/${encodedVariables}`
  );
};

interface DishFiltersResponse {
  dishNamesByManagerId: DishFilterOptions[];
}

export const getDishFiltersApi = (managerId: number) => {
  const query = "DishNamesByManagerId";
  const variables = {
    managerId,
  };
  const encodedVariables = encodeURI(JSON.stringify(variables));

  return axios.get<IGraphQueryResponse<DishFiltersResponse>>(
    `${process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL}${query}/variables/${encodedVariables}`
  );
};
