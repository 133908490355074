import { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import InboxView from "../components/inboxViews/inboxView";
import TasksView from "../components/inboxViews/tasksView";
import MediaView from "../components/inboxViews/mediaView";

import { InboxVisitTypes } from "../utils/hgem";
import i18n from "../localizations/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  AppealsFeatureActive,
  SelectedVisitId,
} from "../components/inboxViews/state/selectors";
import { fetchAppealsFeatureActive } from "../state/actions/Appeals-Actions";
import { useCanAccessAppeal } from "../state/hooks/appeals/useCanAccessAppeal";
import { useClientId } from "../state/hooks/clients/useClientId";
import AppealsView from "../components/inboxViews/appealsView";
import ConversationsView from "../components/inboxViews/conversationsView";
import { initializeConversations } from "../state/actions/Conversations-Actions";
import { useLocation } from "react-router-dom";
import { ClientBorderThreshold } from "../state/api";
import getBorderColourThresholds from "../utils/borderColourThresholds";

type TabSubPath = "default" | "tasks" | "images" | "appeals" | "conversations";

const Inbox = () => {
  const pageRef = "Inbox";
  const clientId = useClientId();
  const selectedVisitId = useSelector(SelectedVisitId);
  const location = useLocation();
  const dispatch = useDispatch();
  const inboxTabName = i18n.translate("INBOX_Tabname");
  const inboxTasksTabName = i18n.translate("INBOX_Tasks_Tabname");
  const inboxMediaTabName = i18n.translate("INBOX_Images_Tabname");
  const inboxAppealsTabName = i18n.translate("INBOX_Appeals_Tabname");
  const inboxConversationsTabName = "Conversations";

  const tabPathMappings: { name: string; path: TabSubPath | undefined }[] = [
    { name: inboxTabName, path: "default" },
    { name: inboxTasksTabName, path: "tasks" },
    { name: inboxMediaTabName, path: "images" },
    { name: inboxAppealsTabName, path: "appeals" },
    { name: inboxConversationsTabName, path: "conversations" },
  ];

  useEffect(() => {
    const targetTab =
      tabPathMappings.find(
        (tab) => tab.path && location.pathname.includes(tab.path)
      ) ?? tabPathMappings.filter((tab) => tab.path === "default")[0];

    if (targetTab.name !== currentTab) {
      setCurrentTab(targetTab.name);
    }
  }, [location]);

  const appealsFeatureActive = useSelector(AppealsFeatureActive);
  const appealsAccess = useCanAccessAppeal({
    clientId: clientId,
    visitId: selectedVisitId,
  });

  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.xs)
  );
  useEffect(() => {
    dispatch(fetchAppealsFeatureActive());
    dispatch(initializeConversations());
  }, [dispatch]);

  const [borderColorThresholds, setBorderColorThresholds] =
    useState<ClientBorderThreshold>();
  const [currentTab, setCurrentTab] = useState(inboxTabName);

  useEffect(() => {
    const fetchThresholds = async () => {
      const borderThresholds = await getBorderColourThresholds(clientId);
      setBorderColorThresholds(borderThresholds);
    };
    fetchThresholds();
  }, [clientId]);

  return (
    <>
      {currentTab === inboxTabName && (
        <>
          <InboxView
            visitTypes={InboxVisitTypes}
            pageRef={pageRef}
            isMobileView={isMobileView}
            appealsFeatureActive={appealsFeatureActive}
            hasAppealsAccess={appealsAccess}
            borderColorThresholds={borderColorThresholds}
          />
        </>
      )}

      {currentTab === inboxMediaTabName && (
        <>
          <MediaView
            visitTypes={InboxVisitTypes}
            pageRef={pageRef}
            isMobileView={isMobileView}
            appealsFeatureActive={appealsFeatureActive}
          />
        </>
      )}
      {currentTab === inboxTasksTabName && (
        <>
          <TasksView visitTypes={InboxVisitTypes} isMobileView={isMobileView} />
        </>
      )}
      {currentTab === inboxAppealsTabName && (
        <>
          <AppealsView
            visitTypes={InboxVisitTypes}
            pageRef={pageRef}
            isMobileView={isMobileView}
            appealsFeatureActive={appealsFeatureActive}
            hasAppealsAccess={appealsAccess}
            borderColorThresholds={borderColorThresholds}
          />
        </>
      )}
      {currentTab === inboxConversationsTabName && (
        <>
          <ConversationsView pageRef={pageRef} isMobileView={isMobileView} />
        </>
      )}
    </>
  );
};

export default Inbox;
