import { useState } from "react";
import { useSelector } from "react-redux";
import { AppealsSelect, AppealInformation } from ".";
import { i18n } from "../../../localizations";
import { useAppealReviewDecisions } from "../../../state/hooks/appeals/useAppealReviewDecisions";
import { AllCategories } from "../../taskCentreEditor/state/selectors";
import { AppealsStyles } from "../styles";
import { ICaseDetails } from "../../../state/types/TaskCentreCases";
import { Button, Divider, FormControlLabel, Switch } from "@material-ui/core";
import EditableAppealInformation from "./editableAppealInformation";
import { useUserId } from "../../../state/hooks/clients/useUserId";
import { useIsAdmin } from "../../../state/hooks/clients/useIsAdmin";
import { appealCanEdit } from "../utils";
import { TasksStyles } from "../../taskCentreEditor/styles/tasksStyles";

type Props = {
  clientId: number | undefined;
  postCategory: (value: number) => void;
  categoryId: number | undefined;
  postAppealDecision: (value: number) => void;
  appealDecisionId: number | undefined;
  currentAppeal: ICaseDetails;
  isPendingAction: boolean;
  setIsPendingAction: React.Dispatch<React.SetStateAction<boolean>>;
  grantAppeal: (sendNotification: boolean) => Promise<void>;
  isApprovalManager: boolean;
  editAppeal: (params: {
    bonusAppealOnly: boolean | undefined;
    reviewerId: number | undefined;
    deleteQuestionIds: number[] | undefined;
    addQuestionIds: number[] | undefined;
  }) => Promise<void>;
};

const AppealsTriage = (props: Props) => {
  const {
    clientId,
    postCategory,
    categoryId,
    postAppealDecision,
    appealDecisionId,
    currentAppeal,
    isPendingAction,
    setIsPendingAction,
    grantAppeal,
    isApprovalManager,
    editAppeal,
  } = props;
  const categories = useSelector(AllCategories);
  const appealReviewDecisions = useAppealReviewDecisions({
    clientId: clientId,
  });
  const taskStyles = TasksStyles();

  const userId = useUserId();
  const isAdmin = useIsAdmin();

  const [reviewDecisionIsInvalid, setReviewDecisionIsInvalid] =
    useState<boolean>(false);
  const [reviewDecisionCategoryIsInvalid, setReviewDecisionCategoryInvalid] =
    useState<boolean>(false);

  const [sendNotification, setSendNotification] = useState<boolean>(true);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const renderEditButton =
    !isEditing && appealCanEdit(currentAppeal, userId, isAdmin);

  const categoryIsValid = (): void => {
    if (currentAppeal) {
      if (!currentAppeal.category) {
        setReviewDecisionCategoryInvalid(true);
      }
      setReviewDecisionCategoryInvalid(false);
    }
  };
  const finalReviewDecisionIsValid = (): void => {
    if (currentAppeal) {
      if (!currentAppeal.appealReviewDecision) {
        setReviewDecisionIsInvalid(true);
      }
      setReviewDecisionIsInvalid(false);
    }
  };
  const validate = () => {
    return new Promise<void>((resolve, reject) => {
      if (
        "category" in currentAppeal &&
        "appealReviewDecision" in currentAppeal
      ) {
        setReviewDecisionCategoryInvalid(false);
        setReviewDecisionIsInvalid(false);
        resolve();
        return;
      }

      if ("category" in currentAppeal === false) {
        setReviewDecisionCategoryInvalid(true);
      }

      if ("appealReviewDecision" in currentAppeal === false) {
        setReviewDecisionIsInvalid(true);
      }
      setIsPendingAction(false);
      reject();
    });
  };
  const appealsClasses = AppealsStyles();
  const completeAppeal = () => {
    setIsPendingAction(true);
    validate().then(() => {
      grantAppeal(sendNotification);
    });
  };
  const buttonComponent = () => {
    return (
      <span
        className={`${appealsClasses.flexRow} ${appealsClasses.flexRowWithIcon}`}
      >
        <FormControlLabel
          label={i18n.translate("APPEALS_SEND_NOTIFICATION_CHECKBOX_LABEL")}
          labelPlacement="end"
          control={
            <Switch
              size="medium"
              checked={sendNotification}
              onChange={(event) => setSendNotification(event.target.checked)}
            />
          }
        />
        <Button
          className={taskStyles.tasksButton}
          onClick={() => completeAppeal()}
        >
          {i18n.translate("APPEALS_COMPLETE_REVIEW_BUTTON_TEXT")}
        </Button>

        {renderEditButton && (
          <Button
            className={taskStyles.tasksButton}
            onClick={() => setIsEditing(true)}
          >
            {i18n.translate("APPEALS_EDIT_BUTTON_TEXT")}
          </Button>
        )}
      </span>
    );
  };
  return (
    <>
      {!isEditing && (
        <>
          <AppealInformation
            currentAppeal={currentAppeal}
            buttonComponent={buttonComponent()}
          />
          <Divider
            light={true}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          />
          <span className={`${appealsClasses.flexRow}`}>
            <span className={`${appealsClasses.flexItem}`}>
              <AppealsSelect
                errorType={i18n.translate("COMMON_TEXT_CATEGORY")}
                selectLabel={i18n.translate("APPEALS_CATEGORIES_SELECT_LABEL")}
                categoryOptions={categories}
                onChange={postCategory}
                selectedId={categoryId}
                isInvalid={reviewDecisionCategoryIsInvalid}
                validate={categoryIsValid}
              />
            </span>
            <span className={`${appealsClasses.flexItem}`}>
              <AppealsSelect
                errorType={i18n.translate("COMMON_TEXT_REVIEW_DECISION")}
                selectLabel={i18n.translate("APPEALS_REVIEW_DECISION")}
                reviewDecisionOptions={appealReviewDecisions}
                onChange={postAppealDecision}
                selectedId={appealDecisionId}
                isInvalid={reviewDecisionIsInvalid}
                validate={finalReviewDecisionIsValid}
              />
            </span>
          </span>
        </>
      )}
      {isEditing && (
        <EditableAppealInformation
          currentAppeal={currentAppeal}
          isApprovalManager={isApprovalManager}
          isPendingAction={isPendingAction}
          setIsPendingAction={setIsPendingAction}
          save={(params) => editAppeal(params).then(() => setIsEditing(false))}
          cancel={() => setIsEditing(false)}
        />
      )}
    </>
  );
};

export default AppealsTriage;
