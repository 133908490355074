import React from "react";
import { useSelector } from "react-redux";
import { VisitDetailError, VisitReportPending } from "./state/selectors";
import PandoraReport from "./components/pandoraReport";
import ReviewReport from "./components/reviewReport";
import ReportError from "./components/reportError";
import { LinearProgress } from "@material-ui/core";
import { HGEMVisitType } from "../../utils/hgem";

const minReportContainerWidth = 700;

interface IProps {
  visitId: number | undefined;
  visitType: string;
}

const InboxReport = (props: IProps) => {
  const visitDetailError = useSelector(VisitDetailError);
  const reportPending = useSelector(VisitReportPending);

  const isReviewType =
    props.visitType.toUpperCase() === HGEMVisitType.Review ||
    props.visitType.toUpperCase() === HGEMVisitType.DeliveryReview;

  return (
    <>
      {props.visitId && reportPending && <LinearProgress color="primary" />}

      {!visitDetailError && !isReviewType && props.visitId && (
        <PandoraReport
          minContainerWidth={minReportContainerWidth}
          visitId={props.visitId}
        />
      )}

      {!visitDetailError && isReviewType && props.visitId && (
        <ReviewReport
          visitId={props.visitId}
          showResponseOption={
            props.visitType.toUpperCase() === HGEMVisitType.Review
          }
        />
      )}

      {visitDetailError && <ReportError />}
    </>
  );
};

export default InboxReport;
