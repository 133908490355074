import { Button, Divider } from "@material-ui/core";
import { AppealsButton, AppealInformation } from ".";
import { ICaseDetails } from "../../../state/types/TaskCentreCases";
import { AppealsStyles } from "../styles";
import EditableAppealInformation from "./editableAppealInformation";
import { useState } from "react";
import { useUserId } from "../../../state/hooks/clients/useUserId";
import { useIsAdmin } from "../../../state/hooks/clients/useIsAdmin";
import { appealCanEdit } from "../utils";
import { i18n } from "../../../localizations";
import { TasksStyles } from "../../taskCentreEditor/styles/tasksStyles";

type Props = {
  currentAppeal: ICaseDetails;
  isPendingAction: boolean;
  setIsPendingAction: React.Dispatch<React.SetStateAction<boolean>>;
  postApprovedForReview: () => Promise<void>;
  postCancelledAppeal: () => Promise<void>;
  approvalButtonText: string;
  cancelButtonText: string;
  isApprovalManager: boolean;
  editAppeal: (params: {
    bonusAppealOnly: boolean | undefined;
    reviewerId: number | undefined;
    deleteQuestionIds: number[] | undefined;
    addQuestionIds: number[] | undefined;
  }) => Promise<void>;
};

const ManagerApproval = (props: Props) => {
  const {
    currentAppeal,
    isPendingAction,
    setIsPendingAction,
    postApprovedForReview,
    postCancelledAppeal,
    approvalButtonText,
    cancelButtonText,
    isApprovalManager,
    editAppeal,
  } = props;

  const appealsClasses = AppealsStyles();
  const taskStyles = TasksStyles();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const userId = useUserId();

  const isAdmin = useIsAdmin();

  const renderEditButton =
    !isEditing && appealCanEdit(currentAppeal, userId, isAdmin);

  const renderApprovalButtons =
    !isEditing && (userId === currentAppeal.assigneeId || isAdmin);

  const buttonComponent = () => {
    return (
      <span
        className={`${appealsClasses.flexRow} ${appealsClasses.flexRowWithIcon}`}
      >
        {renderApprovalButtons && (
          <>
            <AppealsButton
              name={approvalButtonText}
              promise={() => postApprovedForReview()}
              isPending={isPendingAction}
              setIsPending={setIsPendingAction}
              className={taskStyles.tasksButton}
            />
            <AppealsButton
              name={cancelButtonText}
              variant={"outlined"}
              isPending={isPendingAction}
              setIsPending={setIsPendingAction}
              promise={() => postCancelledAppeal()}
              className={taskStyles.tasksButton}
            />
          </>
        )}
        {renderEditButton && (
          <Button
            className={taskStyles.tasksButton}
            onClick={() => setIsEditing(true)}
          >
            {i18n.translate("APPEALS_EDIT_BUTTON_TEXT")}
          </Button>
        )}
      </span>
    );
  };
  return (
    <>
      {!isEditing && (
        <>
          <AppealInformation
            currentAppeal={currentAppeal}
            buttonComponent={buttonComponent()}
          />
          <Divider
            light={true}
            style={{ marginTop: "1%", marginBottom: "1%" }}
          />
        </>
      )}
      {isEditing && (
        <EditableAppealInformation
          currentAppeal={currentAppeal}
          isApprovalManager={isApprovalManager}
          isPendingAction={isPendingAction}
          setIsPendingAction={setIsPendingAction}
          save={(params) => editAppeal(params).then(() => setIsEditing(false))}
          cancel={() => setIsEditing(false)}
        />
      )}
    </>
  );
};

export default ManagerApproval;
