import {
  ApplicationActions,
  RESET_PERSISTED_STATE_PENDING_FLAG,
} from "../actions/Application-Actions";
import {
  USERIMPERSONATION_SUCCESS,
  USERIMPERSONATION_PENDING,
  USERIMPERSONATION_ERROR,
  RESET_IMPERSONATION_OPTIONS,
  UserImpersonationActions,
} from "../actions/UserImpersonation-Actions";
import { IUserImpersonation } from "../types/UserImpersonation";

export interface IUserImpersonationState {
  userImpersonation: IUserImpersonation;
  pending: boolean;
  error: string;
}

const INITIAL_STATE: IUserImpersonationState = {
  userImpersonation: {
    selectedClient: undefined,
    userCanImpersonate: false,
    impersonationOptions: [],
    defaultImpersonationOption: undefined,
    userCanSwitchManagers: false,
  } as IUserImpersonation,
  pending: false,
  error: "",
};

export default function UserProfileReducer(
  state: IUserImpersonationState = INITIAL_STATE,
  action: UserImpersonationActions | ApplicationActions
): IUserImpersonationState {
  switch (action.type) {
    case USERIMPERSONATION_PENDING:
      return {
        ...state,
        pending: true,
        error: "",
      };
    case USERIMPERSONATION_SUCCESS:
      return {
        ...state,
        pending: false,
        userImpersonation: action.userImpersonation,
        error: "",
      };
    case USERIMPERSONATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case RESET_IMPERSONATION_OPTIONS:
      return INITIAL_STATE;
    case RESET_PERSISTED_STATE_PENDING_FLAG:
      return {
        ...state,
        pending: false,
        error: "",
      };
    default:
      return state;
  }
}
