import {
  VoucherConfigurationSetting,
  VOUCHERS_CONFIGURATION,
} from "../actions/Vouchers-Actions";
import { VoucherResponse } from "../types";

export interface VoucherState {
  voucherConfiguration: VoucherResponse[];
}
const INITIAL_STATE = {
  voucherConfiguration: [],
};

export default function VouchersReducer(
  state: VoucherState = INITIAL_STATE,
  action: VoucherConfigurationSetting
) {
  switch (action.type) {
    case VOUCHERS_CONFIGURATION:
      return {
        ...state,
        voucherConfiguration: action.voucherConfiguration,
      };
    default:
      return state;
  }
}
