import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchUserBrands } from "../../../state/actions/UserProfile-Actions";
import FilterWrapper from "./filterWrapper";
import { i18n } from "../../../localizations";
import {
  UserBrandsSelector,
  UserCurrentBrandsSelector,
} from "../../userToolbar/state/selectors";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";

const BrandPicker = () => {
  const dispatch = useDispatch();
  const availableBrands = useSelector(UserBrandsSelector);
  const currentBrands = useSelector(UserCurrentBrandsSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const applySelectionCallback = (brands: string[]) => {
    dispatch(switchUserBrands(brands));
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const buildTooltip = () => {
    if (currentBrands.length === 0) {
      return i18n.translate("VISIT_DISH_NAME_PICKER_All_Selected");
    } else {
      const text = currentBrands.join(", ");
      return text.length > 150
        ? `${currentBrands.length} ${i18n.translate(
            "BRAND_PICKER_SELECTED"
          )} ${text.substring(0, 150)}...`
        : text;
    }
  };
  const getSelectionHint = () => {
    if (currentBrands.length < availableBrands.length) {
      if (currentBrands.length > 1) {
        return `${currentBrands.length} ${i18n.translate(
          "VISIT_TYPE_PICKER_Selected"
        )}`;
      } else {
        return `${currentBrands[0]}`;
      }
    }
    return i18n.translate("VISIT_TYPE_PICKER_All_Selected");
  };
  return (
    <FilterWrapper
      isOpen={isOpen}
      label={i18n.translate("BRAND_PICKER_LABEL")}
      selectionHint={getSelectionHint()}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltip()}
      isDisabled={currentBrands.length === 0}
    >
      <GenericMultiSelect
        applySelectionCallback={applySelectionCallback}
        cancelSelectionCallback={cancelSelection}
        multiSelect={true}
        options={availableBrands}
        useSelectAllOption={true}
        selectAllOptionText={i18n.translate(
          "VISIT_SOURCE_PICKER_Select_All_Option"
        )}
        selectedOptions={currentBrands}
      />
    </FilterWrapper>
  );
};
export default BrandPicker;
