import axios from "axios";

export const trackHubUsageApi = (managerId: number, navPath: string) => {
  const variables = JSON.stringify({
    id: "Hub_RecordInteraction",
    variables: {
      dto: {
        managerId: managerId,
        interactionSourceEnum: "HUB",
        url: navPath,
      },
    },
  });

  return axios.post(
    `${process.env.REACT_APP_GRAPH_CLIENTS_API_MUTATIONURL}`,
    variables,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
