import { useEffect, useState } from "react";
import { getApprovalManagersApi } from "../../api/Appeals-API";
import { IManager } from "../../types/TaskCentreCases";

interface IProps {
  visitId: number | undefined;
  clientId: number | undefined;
}

export const useApprovalManagers = (props: IProps) => {
  const [approvalManagers, setApprovalManagers] = useState<IManager[]>([]);

  useEffect(() => {
    if (props.clientId && props.visitId) {
      getApprovalManagersApi(props.clientId, props.visitId).then((response) => {
        setApprovalManagers(response.data);
      });
    }
  }, [setApprovalManagers, props.clientId, props.visitId]);

  return approvalManagers;
};
