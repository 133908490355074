import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  container: {
    display: "block",
    float: "left",
    clear: "both",
    margin: "40px 10px",
    color: hgemColours.DarkGrey,
  },
  header: {
    fontSize: "2em",
    fontStyle: "italic",
    margin: 0,
  },
}));

export const VisitReportAutoDownloaderStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
