import React, { useEffect, useState } from "react";
import {
  CaseEventType,
  ICaseDetails,
  IMinimalCaseEventAttachment,
  WorkflowStatus,
} from "../../../state/types/TaskCentreCases";
import { Button, Icon, Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import i18n from "../../../localizations/i18n";
import {
  ContactGuestCommand,
  GuestContactNotes,
  VoucherSentCommand,
} from "../../../state/types/TaskCentreCommands";
import {
  createNewTaskCentreCase,
  executeCaseCommand,
} from "../../../state/actions/TaskCentre-CaseList-Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  IAppNotification,
  NotificationType,
  NotificationSource,
} from "../../../state/types/AppNotification";
import {
  deleteAppNotification,
  sendInternalAppNotification,
} from "../../../state/actions/AppNotification-Actions";
import { fetchGuestDetails } from "../../../state/actions/GuestDetails-Actions";
import GuestMessageConfirmDialog from "./guestMessageConfirmDialog";
import GuestContactConfirmDialog from "./guestContactConfirmDialog";
import { GuestContactFieldStyles } from "../styles/guestContactFieldStyles";
import { IGuestDetails } from "../../../state/types/GuestDetails";
import ConfirmationDialog from "../../_common/confirmationDialog/confirmationDialog";
import {
  findAndReplaceVisit,
  setVisitListItemContactStatus,
} from "../../../state/actions/VisitDetails-Actions";
import { VoucherConfiguration, usersCurrentBrands } from "../state/selectors";
import { postNewVoucher } from "../../../state/api";
import { CurrentUserId } from "../../taskCentreEvents/state/selectors";
import { CardDetails, GuestContactHeader } from "./";
import { IVisitDetail } from "../../../state/types/VisitDetails";
import { fetchEmailResponseTemplatesApi } from "../../../state/api/VisitReport-API";
import { BrandIdAndName } from "../../../state/types/UserProfile";
import { IReviewResponseTemplate } from "../../../state/types/VisitReport";
import { AllVisits } from "../../inboxList/state/selectors";
import ReviewTemplateDialog from "../../inboxReport/components/reviewTemplateDialog";
import { UserNameSelector } from "../../userToolbar/state/selectors";
import { useClientId } from "../../../state/hooks/clients/useClientId";
import _ from "lodash";
import { MessageSentStyles } from "../../taskCentreEvents/styles/messageSentStyles";
import { findVoucherDetails } from "../../taskCentreEvents/utils";
import dayjs from "dayjs";
import { AttachmentsList } from "../../inboxViews/components/attachments";
import { getTaskEventAttachmentApi } from "../../../state/api/TaskCentre-API";
import GuestContactContainer from "./guestContactContainer";
import RelatedGuestContacts from "./relatedGuestContacts";
import { GuestContactStyles } from "../styles/guestContactStyles";
import GuestContactButtonBar from "./guestContactButtonBar";
import PendingAttachmentsList from "./pendingAttachmentsList";
import GuestContactInputs from "./guestContactInputs";
import { findBrandName, templateTextFormat } from "./utils";
import GuestContactNotesContainer from "./guestContactNotes";
import {
  VoucherResponse,
  toggleProduct,
  toggleProductOption,
} from "../../../state/types/Voucher";

interface IProps {
  caseDetails: ICaseDetails;
  guestDetailsPending: boolean;
  guestDetailsError: string;
  guestContactDetails: IGuestDetails | undefined;
  appNotification?: IAppNotification;
  isNewCase: boolean;
  vouchersActive: boolean | undefined;
  currentVisit: IVisitDetail | undefined;
  voucherUpperLimit: number;
  attachments?: (IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  })[];
  isMobileView?: boolean;
}

const GuestContactField = (props: IProps) => {
  const classes = GuestContactFieldStyles();
  const messageClasses = MessageSentStyles();
  const guestContactStyles = GuestContactStyles();
  const visitId = props.currentVisit?.id || props.caseDetails.vId;
  const currentUserId = useSelector(CurrentUserId);
  const currentBrands: BrandIdAndName[] | undefined =
    useSelector(usersCurrentBrands);
  const currentUser = useSelector(UserNameSelector);
  const currentVisits = useSelector(AllVisits);

  const dispatch = useDispatch();
  const clientId = useClientId();
  const brandId = currentBrands?.find(
    (brand) => brand.id === props.currentVisit?.brandId
  )?.id;

  const voucherConfig = useSelector(VoucherConfiguration);
  const voucherOptions = voucherConfig.find(
    (config) => config.brandId === brandId
  );

  const defaultMessageSubject =
    i18n.translate("TASK_CENTRE_PLACEHOLDER_Guest_contact_subject") +
    props.currentVisit?.branch;
  const [voucherReason, setVoucherReason] = useState<string | undefined>();
  const [voucherValue, setVoucherValue] = useState<number | undefined>();
  const [messageSubject, setMessageSubject] = useState(defaultMessageSubject);
  const [selectedToggleProduct, setSelectedToggleProduct] = useState<
    toggleProductOption | undefined
  >();
  const [selectedToggleProductOption, setSelectedToggleProductOption] =
    useState<toggleProduct | undefined>();
  const [messageText, setMessageText] = useState("");
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [contactConfirmDialogOpen, setContactConfirmDialogOpen] =
    useState(false);
  const [sendVoucher, setSendVoucher] = React.useState(false);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  const hasError = props.appNotification
    ? props.appNotification.Type === NotificationType.Error
    : false;

  const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageSubject(event.target.value);
  };

  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageText(event.target.value);
  };
  const setVouchersActive = () => {
    setSendVoucher(!sendVoucher);
  };

  const next = () => {
    setMessageDialogOpen(true);
  };
  const voucherDetails = () => {
    if (props.currentVisit) {
      return findVoucherDetails(currentVisits, props.currentVisit.id);
    }
  };

  const removeFile = (fileName: string) => {
    const newFileList = uploadFiles.filter((file) => {
      file.name !== fileName;
    });
    setUploadFiles(newFileList);
  };
  const [availableTemplates, setAvailableTemplates] = useState<
    IReviewResponseTemplate[]
  >([]);

  const [showTemplateSelect, setShowTemplateSelect] = useState<boolean>(false);

  const handleTemplateSelect = () => {
    setShowTemplateSelect(true);
  };

  const selectTemplate = (template: IReviewResponseTemplate) => {
    setMessageText("");
    if (availableTemplates && availableTemplates.length > 0) {
      const delayedTextUpdate = _.debounce(
        () => {
          setMessageText(template.template);
        },
        300,
        {
          maxWait: 500,
        }
      );
      delayedTextUpdate();
    }
    setShowTemplateSelect(false);
  };

  const formatText = (templatesList: IReviewResponseTemplate[]) => {
    const brandName = findBrandName(
      props.currentVisit,
      currentBrands,
      props.caseDetails
    );
    const textReplacementParams = {
      guestContactDetails: props.guestContactDetails,
      currentVisit: props.currentVisit,
      brandName: brandName,
      currentUser: currentUser,
      caseDetails: props.caseDetails,
      templatesList: templatesList,
    };
    return templateTextFormat(textReplacementParams);
  };

  useEffect(() => {
    if (clientId !== 0) {
      fetchEmailResponseTemplatesApi(clientId, brandId as number).then(
        (res) => {
          if (res.data && res.data.length > 0) {
            const formattedTemplates = formatText(res.data);
            setAvailableTemplates(formattedTemplates);
          }
        }
      );
    }
  }, [currentUserId, clientId]);

  const createSendGuestMessage =
    (email: string) => (voucherCommand?: VoucherSentCommand) => {
      const contactCommand = new ContactGuestCommand(
        props.caseDetails.id,
        email,
        messageSubject,
        voucherCommand ? appendGiftCardMessage(messageText) : messageText,
        uploadFiles
      );
      const commands = voucherCommand
        ? [contactCommand, voucherCommand]
        : [contactCommand];

      if (props.isNewCase) {
        dispatch(
          createNewTaskCentreCase(
            props.caseDetails.vId,
            "",
            WorkflowStatus.ActionTaken,
            commands,
            updateVisitListStatus
          )
        );
      } else {
        dispatch(
          executeCaseCommand(
            props.caseDetails.id,
            commands,
            updateVisitListStatus
          )
        );
      }

      setUploadFiles([]);
    };

  const appendGiftCardMessage = (messageText: string) => {
    return `${messageText}
        
          ${i18n.translate("GIFT_CARD_MESSAGE_APPENDIX")}
        `;
  };

  const createVoucherError = (
    isNewCase: boolean,
    caseDetails: ICaseDetails
  ) => {
    dispatch(
      sendInternalAppNotification({
        Identifier: `${isNewCase ? caseDetails.vId : caseDetails.id}|${
          ContactGuestCommand.type
        }`,
        Source: NotificationSource.TaskCentre_CaseUpdate,
        Type: NotificationType.Error,
        Message: i18n.translate("TASK_CENTRE_NOTIFICATION_ERROR"),
        ShowInNotificationCentre: false,
        NotificationTimeout: 0,
      })
    );
  };

  const submitGuestMessage = () => {
    setMessageDialogOpen(false);
    const guestEmail =
      props.guestContactDetails &&
      props.guestContactDetails?.contactDetails.email;

    if (guestEmail) {
      const sendGuestMessage = createSendGuestMessage(guestEmail);
      if (
        sendVoucher &&
        voucherReason &&
        selectedToggleProduct &&
        selectedToggleProductOption
      ) {
        postNewVoucher({
          visitId: visitId,
          reason: voucherReason,
          voucherValue:
            voucherValue ||
            selectedToggleProductOption.toggleProductOptionPrice,
          userId: currentUserId,
          productId: selectedToggleProduct?.toggleProductId,
          optionId: selectedToggleProductOption?.toggleProductOptionId,
        })
          .then((response) => {
            if (response.status === 200) {
              const voucherCommand = new VoucherSentCommand(
                props.caseDetails.id
              );
              const voucherDetails = {
                reason: voucherReason,
                userId: currentUserId,
                voucherCreatedDate: new Date(),
                voucherValue:
                  voucherValue ||
                  selectedToggleProductOption.toggleProductOptionDescription ||
                  selectedToggleProduct.toggleProductDescription,
              };
              dispatch(findAndReplaceVisit(props.currentVisit, voucherDetails));
              sendGuestMessage(voucherCommand);
            } else {
              createVoucherError(props.isNewCase, props.caseDetails);
            }
          })
          .catch(() => {
            createVoucherError(props.isNewCase, props.caseDetails);
          });
      } else {
        sendGuestMessage();
      }

      setMessageSubject(defaultMessageSubject);
      setMessageText("");
      setVoucherReason("");
      setVoucherValue(undefined);
      setSelectedToggleProduct(undefined);
      setSelectedToggleProductOption(undefined);
    }
  };

  const submitGuestContactConfirmation = (comment: string) => {
    setContactConfirmDialogOpen(false);
    if (props.guestContactDetails) {
      const contactConfirmCommand = new GuestContactNotes(
        props.caseDetails.id,
        comment
      );

      if (props.isNewCase) {
        dispatch(
          createNewTaskCentreCase(
            props.caseDetails.vId,
            "",
            WorkflowStatus.ActionTaken,
            [contactConfirmCommand],
            updateVisitListStatus
          )
        );
      } else {
        dispatch(
          executeCaseCommand(
            props.caseDetails.id,
            [contactConfirmCommand],
            updateVisitListStatus
          )
        );
      }
    }
  };

  const updateVisitListStatus = () => {
    dispatch(setVisitListItemContactStatus(props.caseDetails.vId, true));
  };

  const aknowledgeError = () => {
    if (
      props.appNotification &&
      props.appNotification.Type === NotificationType.Error
    ) {
      dispatch(deleteAppNotification(props.appNotification));
    }
  };

  const retryGuestDetailsFetch = () => {
    dispatch(fetchGuestDetails(props.caseDetails.vId));
  };

  const contactTypes = [
    CaseEventType.MessageSentEvent,
    CaseEventType.MessageReceivedEvent,
    CaseEventType.GuestContactConfirmedEvent,
  ];

  const contactMade = props.caseDetails.events?.find((x) => {
    return contactTypes.indexOf(x.type) > -1;
  });

  const voucherInputIsInvalid = (): boolean => {
    if (props.vouchersActive && sendVoucher) {
      if (voucherHasBeenSent()) {
        return false;
      }
      if (voucherReason === undefined) {
        return true;
      }
    }
    return false;
  };
  const messageInputIsInvalid = (): boolean => {
    if (
      messageText.length === 0 ||
      messageSubject.length === 0 ||
      messageSubject.length > 100
    ) {
      return true;
    }
    return false;
  };
  const appNotificationInProgress = (): boolean => {
    return props.appNotification !== undefined;
  };
  const isButtonDisabled = (): boolean => {
    return (
      appNotificationInProgress() ||
      messageInputIsInvalid() ||
      voucherInputIsInvalid()
    );
  };

  useEffect(() => {
    const voucherSentAlready = voucherHasBeenSent();
    if (voucherSentAlready === true) {
      setSendVoucher(false);
    }
  }, [props.caseDetails, props.currentVisit]);

  const voucherHasBeenSent = () => !!props.currentVisit?.hasVoucher;
  const previousMessages = props.caseDetails.events.filter((caseEvent) => {
    const messageTypes = [
      CaseEventType.MessageReceivedEvent,
      CaseEventType.MessageSentEvent,
      CaseEventType.VoucherSent,
    ];

    return messageTypes.includes(caseEvent.type);
  });

  const contactNotes = props.caseDetails.events.filter(
    (caseEvent) => caseEvent.type === CaseEventType.GuestContactConfirmedEvent
  );

  const voucherOptionHasDescription =
    selectedToggleProductOption?.toggleProductOptionDescription === null;

  const voucherOptionHasPrice =
    selectedToggleProductOption?.toggleProductOptionPrice === 0;

  const voucherValueConfirmationText = () => {
    if (voucherOptionHasPrice && voucherValue) {
      const renderValue = voucherValue / 100;
      return `£${renderValue?.toString()}.00`;
    }
    if (voucherOptionHasDescription) {
      return `£${selectedToggleProductOption.toggleProductOptionPrice / 100}`;
    }
    return selectedToggleProductOption?.toggleProductOptionDescription;
  };
  return (
    <div>
      {props.guestDetailsPending && (
        <div className={classes.pendingText}>
          {i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Pending")}
        </div>
      )}

      {props.guestDetailsError && props.guestDetailsError.length > 0 && (
        <div>
          {i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Error_Fetching")}
          <Button
            className={classes.retryButton}
            onClick={retryGuestDetailsFetch}
            variant="contained"
          >
            {i18n.translate("TASK_CENTRE_FIELD_Guest_Contact_Retry_Fetching")}
          </Button>
        </div>
      )}

      {props.guestContactDetails && (
        <>
          <div className={`${classes.flexRow} ${classes.wideGap}`}>
            <div className={classes.flexColumn}>
              <GuestContactHeader
                guestName={props.guestContactDetails.name.fullName}
                emailAddress={props.guestContactDetails.contactDetails.email}
                mobileNumber={props.guestContactDetails.contactDetails.mobile}
              />

              <span
                className={`${classes.flexRow} ${messageClasses.messageIcon}`}
              >
                {props.vouchersActive && voucherHasBeenSent() && (
                  <span className={classes.headerRow}>
                    <Icon
                      className={`icon far fa-gift-card ${classes.headerIcon}`}
                      fontSize="small"
                    />
                    {i18n.translate("VOUCHER_DETAILS_SENT_HEADER")}{" "}
                    {dayjs(voucherDetails()?.voucherCreatedDate).format(
                      i18n.translate("COMMON_DATE_AND_TIME_FORMAT")
                    )}
                  </span>
                )}
              </span>
            </div>
            <GuestContactButtonBar
              hasError={hasError}
              guestContactDetails={props.guestContactDetails}
              isButtonDisabled={isButtonDisabled()}
              next={next}
              contactMade={contactMade ? true : false}
              setContactConfirmDialogOpen={setContactConfirmDialogOpen}
              availableTemplates={availableTemplates}
              handleTemplateSelect={handleTemplateSelect}
              onFileSelected={(file) => setUploadFiles([file, ...uploadFiles])}
              setVouchersActive={setVouchersActive}
              vouchersActive={props.vouchersActive}
              voucherHasBeenSent={voucherHasBeenSent() === true}
              sendVoucher={sendVoucher}
              messageText={messageText}
              appNotification={props.appNotification}
            />
            {showTemplateSelect && (
              <ReviewTemplateDialog
                showSelections={true}
                templates={availableTemplates}
                substitutions={[]}
                onSelect={selectTemplate}
                onClose={() => setShowTemplateSelect(false)}
              />
            )}
          </div>
          {sendVoucher && (
            <label className={classes.smallLightGreyText}>
              {i18n.translate("GIFT_CARD_MESSAGE_APPEND_TOGGLE")}
            </label>
          )}
          <div className={classes.scrollContainer}>
            <>
              {props.guestContactDetails &&
                props.guestContactDetails.contactDetails.email && (
                  <>
                    <div className={classes.smallBottomMargin}>
                      <GuestContactInputs
                        messageSubject={messageSubject}
                        onSubjectChange={onSubjectChange}
                        messageText={messageText}
                        onMessageChange={onMessageChange}
                        sendVoucher={sendVoucher}
                        vouchersActive={props.vouchersActive}
                        voucherHasBeenSent={voucherHasBeenSent() === false}
                      />

                      {sendVoucher &&
                        props.vouchersActive &&
                        voucherHasBeenSent() === false && (
                          <CardDetails
                            labelId="card-value"
                            labelText={i18n.translate("CARD_AMOUNT")}
                            fieldDefaultText={i18n.translate(
                              "CARD_VALUE_EMPTY"
                            )}
                            voucherReason={voucherReason}
                            onTextInput={setVoucherReason}
                            voucherValue={voucherValue}
                            onSelectToggleProduct={setSelectedToggleProduct}
                            setVoucherValue={setVoucherValue}
                            setToggleOption={setSelectedToggleProductOption}
                            voucherUpperLimit={props.voucherUpperLimit}
                            voucherOptions={voucherOptions}
                            currentlySelectedToggleProduct={
                              selectedToggleProduct
                            }
                            currentlySelectedToggleOption={
                              selectedToggleProductOption
                            }
                          />
                        )}
                    </div>
                  </>
                )}
              {props.attachments && props.attachments.length > 0 && (
                <Accordion className={guestContactStyles.accordionRow}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {i18n.translate("ATTACHMENTS")}
                  </AccordionSummary>

                  <AttachmentsList
                    attachments={props.attachments}
                    authorDeleteAttachment={(_) => undefined}
                    downloadAttachment={
                      clientId
                        ? (caseEventAttachmnent) => {
                            const case0 = props.caseDetails as ICaseDetails;

                            return getTaskEventAttachmentApi(
                              clientId,
                              case0.vId,
                              case0.id,
                              caseEventAttachmnent.id,
                              caseEventAttachmnent.filepath
                            ).then((x) => x.blob);
                          }
                        : undefined
                    }
                  />
                </Accordion>
              )}
              {uploadFiles.length > 0 && (
                <PendingAttachmentsList
                  removeFile={removeFile}
                  uploadFiles={uploadFiles}
                />
              )}
              {previousMessages.length > 0 && (
                <GuestContactContainer
                  messages={previousMessages}
                  isMobileView={props.isMobileView}
                  title={`${i18n.translate(
                    "INBOX_GUEST_CONTACT_previous_communications"
                  )}${props.guestContactDetails.name.fullName} 
                `}
                />
              )}

              <div className={guestContactStyles.relatedContainer}>
                <RelatedGuestContacts
                  case={props.caseDetails}
                  guestContactDetails={props.guestContactDetails}
                />
              </div>
            </>
            {contactNotes.length > 0 && (
              <GuestContactNotesContainer
                isMobileView={props.isMobileView}
                notes={contactNotes}
              />
            )}
          </div>
          <ConfirmationDialog
            title={i18n.translate("TASK_CENTRE_GUEST_CONTACT_Error_Title")}
            message={i18n.translate("TASK_CENTRE_GUEST_CONTACT_Error_Message")}
            showConfirmation={hasError}
            onConfirmCallback={aknowledgeError}
            confirmButtonText={i18n.translate(
              "TASK_CENTRE_ERROR_CONFIRMATION_Close"
            )}
          />
          {props.guestContactDetails.contactDetails.email &&
            sendVoucher === false && (
              <GuestMessageConfirmDialog
                isOpen={messageDialogOpen}
                recipient={props.guestContactDetails.contactDetails.email}
                onClosing={() => setMessageDialogOpen(false)}
                onConfirmation={submitGuestMessage}
                sendVoucher={sendVoucher}
                attachments={uploadFiles}
                voucherDescription={
                  selectedToggleProduct?.toggleProductDescription
                }
                voucherValue={voucherValueConfirmationText()}
              />
            )}

          {props.guestContactDetails && (
            <GuestContactConfirmDialog
              isOpen={contactConfirmDialogOpen}
              onClosing={() => setContactConfirmDialogOpen(false)}
              onConfirmation={submitGuestContactConfirmation}
            />
          )}
          <GuestMessageConfirmDialog
            isOpen={messageDialogOpen}
            onClosing={() => setMessageDialogOpen(false)}
            onConfirmation={submitGuestMessage}
            reason={voucherReason}
            voucherValue={voucherValueConfirmationText()}
            recipient={props.guestContactDetails.contactDetails.email}
            messageText={messageText}
            sendVoucher={(props.vouchersActive && sendVoucher) || false}
            attachments={uploadFiles}
            voucherDescription={`${selectedToggleProduct?.toggleProductDescription}`}
          />
        </>
      )}
    </div>
  );
};

export default GuestContactField;
