import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import FilterWrapper from "./filterWrapper";
import {
  useTheme,
  makeStyles,
  Theme,
  Button,
  TextField,
} from "@material-ui/core";
import { i18n } from "../../../localizations";

interface IProps {
  availableMinValue: number;
  availableMaxValue: number;
  selectedMinValue: number;
  selectedMaxValue: number;
  applySelectionCallback: (rangeMin: number, rangeMax: number) => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  innerContainer: {
    width: "360px",
    padding: "10px",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  actions: {
    margin: "20px 10px 10px 10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  numInput: {
    width: "55px",
    margin: "-7px 5px 5px 0px",
    float: "left",

    "& label": {
      color: theme.palette.grey[400],
    },
  },
  inputContainer: {
    display: "inline-block",
    marginTop: "20px",
  },

  sliderContainer: {
    width: "210px",
    display: "inline-block",
    float: "left",
    margin: "0px 15px",
  },
}));

const QuestionScorePicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);
  const [rangeValue, setRangeValue] = React.useState([
    props.selectedMinValue,
    props.selectedMaxValue,
  ]);

  const [rangeTextInputValue, setRangeTextInputValue] = React.useState([
    props.selectedMinValue,
    props.selectedMaxValue,
  ]);

  const handleToggleOpen = () => {
    if (isOpen) {
      resetRangeSelection();
    }

    setIsOpen(!isOpen);
  };

  const handleRangeChange = (
    _event: React.ChangeEvent<unknown>,
    value: number | number[]
  ) => {
    if (typeof value === typeof []) {
      setRangeValue(value as number[]);
      setRangeTextInputValue(value as number[]);
    }
  };

  const setTextInputValue = (itemValue: string, itemIndex: number) => {
    let numericInput = itemValue.replace(/\D/g, "");
    if (numericInput.length <= 0) {
      numericInput = itemIndex === 0? "0" : "100";
    }

    const value = Number(numericInput);
    
    const newValues = rangeValue.map((x) => x);
    newValues[itemIndex] = value;

    setRangeTextInputValue(newValues);
  }

  const handleMinInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInputValue(event.target.value, 0);
  };

  const handleMaxInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInputValue(event.target.value, 1);
  };

  const applyTextInputUpdate = () => {
      const textInputValues = rangeTextInputValue;

      if (textInputValues[0] < 0){
          textInputValues[0] = 0;
      }

      if (textInputValues[1] > 100){
          textInputValues[1] = 100;
      }
      else if (textInputValues[1] < textInputValues[0]){
          textInputValues[1] = textInputValues[0];
      }

      setRangeTextInputValue(textInputValues);
      setRangeValue(textInputValues);
  }

  const applySelection = () => {
    props.applySelectionCallback(rangeValue[0], rangeValue[1]);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    resetRangeSelection();
    setIsOpen(false);
  };

  const resetRangeSelection = () => {
    setRangeValue([props.selectedMinValue, props.selectedMaxValue]);
    setRangeTextInputValue([props.selectedMinValue, props.selectedMaxValue]);
  };

  const getAppliedSelectionText = () => {
    return `${props.selectedMinValue} - ${props.selectedMaxValue}`;
  };

  function getValuetext(value: number) {
    return `${value}%`;
  }

  return (
    <FilterWrapper
      label={i18n.translate("QUESTION_SCORE_RANGE_PICKER_Question_Score")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={getAppliedSelectionText()}
    >
      <div className={classes.outerContainer}>
        <div className={classes.innerContainer}>
          <Typography id="question-score-range-slider" gutterBottom>
            {i18n.translate("QUESTION_SCORE_RANGE_PICKER_Question_Score")}
          </Typography>
          <div className={classes.inputContainer}>
            <TextField
              label="Min"
              value={rangeTextInputValue[0]}
              onChange={handleMinInputChange}
              onBlur={applyTextInputUpdate}
              variant="outlined"
              size="small"
              className={classes.numInput}
              aria-labelledby="question-score-range-slider"
            />
            <div className={classes.sliderContainer}>
              <Slider
                value={rangeValue}
                min={0}
                max={100}
                onChange={handleRangeChange}
                valueLabelDisplay="off"
                aria-labelledby="question-score-range-slider"
                getAriaValueText={getValuetext}
              />
            </div>
            <TextField
              label="Max"
              value={rangeTextInputValue[1]}
              onChange={handleMaxInputChange}
              onBlur={applyTextInputUpdate}
              variant="outlined"
              size="small"
              className={classes.numInput}
              aria-labelledby="question-score-range-slider"
            />
          </div>
        </div>
        <div className={classes.actions}>
          <Button onClick={applySelection} variant="contained" color="primary">
            {i18n.translate("QUESTION_SCORE_RANGE_PICKER_Apply")}
          </Button>
          <Button onClick={cancelSelection}>
            {i18n.translate("QUESTION_SCORE_RANGE_PICKER_Cancel")}
          </Button>
        </div>
      </div>
    </FilterWrapper>
  );
};

export default QuestionScorePicker;
