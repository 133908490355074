import { Collapse, List } from "@material-ui/core";
import { INavigationOption } from "../../../state/types/UserProfile";
import ReportPageNavigationOption from "./reportPageNavigationOption";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetPages } from "../../reportPageTabs/state/selectors";

type props = {
  navOption: INavigationOption;
  childNavigationOption: INavigationOption;
  currentChildNav?: INavigationOption;
  isMobile: boolean;
  setDrawerOpen?: (boolean: boolean) => void;
  setReportPageName: (pageName: string) => void;
};

const ReportPageNavigationList = (props: props) => {
  const { navOption, childNavigationOption, currentChildNav } = props;
  const [minHeight, setMinHeight] = useState<string>("45px");
  const pages = useSelector(GetPages);
  useEffect(() => {
    if (pages.length > 0) {
      setMinHeight("0px");
    }
  }, [pages]);
  return (
    <Collapse
      key={navOption.name}
      in={childNavigationOption.name === currentChildNav?.name}
    >
      <List
        component="ul"
        style={{
          minHeight: minHeight,
          overflowX: "visible",
          overflowY: "clip",
        }}
      >
        <ReportPageNavigationOption
          navOption={childNavigationOption}
          pages={pages}
          isMobile={props.isMobile}
          setDrawerOpen={props.setDrawerOpen}
          setReportPageName={props.setReportPageName}
        />
      </List>
    </Collapse>
  );
};

export default ReportPageNavigationList;
