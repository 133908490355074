import { VoucherResponse } from "../types";

export const VOUCHERS_CONFIGURATION = "VOUCHERS_CONFIGURATION";

export interface VoucherConfigurationSetting {
  type: typeof VOUCHERS_CONFIGURATION;
  voucherConfiguration: VoucherResponse[];
}
export function voucherConfiguration(
  voucherConfiguration: VoucherResponse[]
): VoucherConfigurationSetting {
  return {
    type: VOUCHERS_CONFIGURATION,
    voucherConfiguration: voucherConfiguration,
  };
}
