import { SignalRNotifications } from "../types/SignalRNotifications";

export const SIGNALR_NOTIFICATION = "SIGNALR_NOTIFICATION";

export type SignalRNotificationAction = {
  type: typeof SIGNALR_NOTIFICATION;
  notification: SignalRNotifications;
};

export function signalRNotification(
  notification: SignalRNotifications
): SignalRNotificationAction {
  return {
    type: SIGNALR_NOTIFICATION,
    notification: notification,
  };
}

export type SignalRNotificationsActions = SignalRNotificationAction;
