import { extension, lookup } from "mime-types";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { shouldRender } from "../functions";
import { MediaLinks } from "../types/mediaLinks";

type UseMediaProps = {
  filename: string | undefined;
  getMedia: (() => Promise<MediaLinks>) | undefined;
  mediaLinks: [
    MediaLinks | undefined,
    React.Dispatch<React.SetStateAction<MediaLinks | undefined>>
  ];
  isDownloading: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  contentType?: string | undefined;
};

type Media = {
  contentType: string | false;
  shouldRenderMedia: boolean;
  links: MediaLinks | undefined;
};

const useMedia = (props: UseMediaProps): Media => {
  const [mediaLinks, setMediaLinks] = props.mediaLinks;
  const [isDownloading, setIsDownloading] = props.isDownloading;

  const contentTypeToUse =
    props.contentType ?? (props.filename ? lookup(props.filename) : false);

  const shouldRenderMedia = contentTypeToUse
    ? shouldRender(contentTypeToUse)
    : false;

  const saveMedia = (mediaLinks: MediaLinks) => {
    const filename =
      props.filename ??
      (contentTypeToUse
        ? `${uuidv4()}.${extension(contentTypeToUse)}`
        : undefined);

    saveAs(mediaLinks.localUrl, filename);
  };

  useEffect(() => {
    if (props.getMedia && !mediaLinks && (shouldRenderMedia || isDownloading)) {
      props.getMedia().then((x) => {
        setMediaLinks(x);
      });
    }

    if (mediaLinks && isDownloading) {
      saveMedia(mediaLinks);

      setIsDownloading(false);
    }
  }, [shouldRenderMedia, isDownloading, mediaLinks, props.getMedia]);

  return {
    contentType: contentTypeToUse,
    shouldRenderMedia: shouldRenderMedia,
    links: mediaLinks,
  };
};

export default useMedia;
