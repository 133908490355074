import { Redirect, Route } from "react-router-dom";
import { routes, getRedirectionForUrl } from "./routes";

interface routeProps {
  location: Location;
}

const HubRedirection = (props: routeProps) => {
  const redirection = getRedirectionForUrl(props.location.pathname);

  return (
    <Route>
      <Redirect to={redirection ? redirection : routes.inbox} />
    </Route>
  );
};

export default HubRedirection;
