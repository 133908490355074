import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tasksButton: {
    background: hgemColours.LimeGreen,
    fontSize: "0.8rem",
    color: "white",
    borderRadius: "4px",
    height: "3em",
    marginRight: "2%",
    "&:hover": {
      background: hgemColours.DarkGrey,
    },
    [theme.breakpoints.down("xs")]: {
      width: "20%",
      marginRight: "0.5%",
    },
  },
  tasksIconButton: {
    background: hgemColours.LimeGreen,
    fontSize: "1em",
    width: "fit-content",
    color: "white",
    borderRadius: "4px",
    marginRight: "2%",
    "&:hover": {
      background: hgemColours.DarkGrey,
    },
  },
  deleteButton: {
    backgroundColor: hgemColours.Orange,
  },
  newTaskButtonContainer: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignContent: "space-between",
  },
  newTaskbutton: {
    marginTop: "3%",
    width: "15%",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  guestContactButton: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "10%",
      maxWidth: "20%",
      paddingLeft: "5%",
      marginRight: "5%",
    },
  },
  guestContactAttachmentsButton: {
    background: hgemColours.LimeGreen,
    fontSize: "0.8rem",
    color: "white",
    borderRadius: "4px",
    height: "3em",
    "&:hover": {
      background: hgemColours.DarkGrey,
    },
  },
  buttonIcon: {
    fontSize: "0.9em",
    lineHeight: "1em",
  },
  buttonRowContainer: {
    float: "right",
    width: "60%",
  },
  activeVoucherButton: {
    background: hgemColours.DarkGrey,
    color: "white",
    borderRadius: "4px",
    "&:hover": {
      background: hgemColours.LimeGreen,
    },
  },
  buttonLabel: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  attachmentInput: {
    [theme.breakpoints.down("xs")]: {},
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  guestContactButtonRowContainer: {
    display: "flex",
    flexDirection: "row",
    width: "95%",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "space-evenly",
    },
  },

  TasksButtonRow: {
    width: "16%",
    justifyContent: "flex-start",
    gap: "2%",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "1.5% 0 1.5% 0",
      width: "95%",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
}));

export const TasksStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
