import {
  USERPROFILE_ERROR,
  USERPROFILE_SUCCESS,
  USERPROFILE_PENDING,
  RESET_USERPROFILE,
  USERPROFILE_SESSION_EXPIRED,
  UserProfileActions,
} from "../actions/UserProfile-Actions";
import { IUserProfile, IAppSourceVersion } from "../types/UserProfile";

export interface IUserProfileState {
  userProfile: IUserProfile | undefined;
  appVersion: IAppSourceVersion | undefined;
  pending: boolean;
  error: string;
  sessionExpired: boolean;
  lastUpdated: Date | undefined;
}

export const INITIAL_STATE: IUserProfileState = {
  userProfile: undefined,
  appVersion: undefined,
  pending: false,
  error: "",
  sessionExpired: false,
  lastUpdated: undefined,
};

export default function UserProfileReducer(
  state: IUserProfileState = INITIAL_STATE,
  action: UserProfileActions
): IUserProfileState {
  switch (action.type) {
    case USERPROFILE_PENDING:
      return {
        ...state,
        pending: true,
        sessionExpired: false,
        error: "",
      };
    case USERPROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        sessionExpired: false,
        error: "",
        userProfile: action.userProfile,
        appVersion: action.appVersion,
        lastUpdated: new Date(),
      };
    case USERPROFILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        sessionExpired: false,
      };
    case USERPROFILE_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      };
    case RESET_USERPROFILE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
