import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  mediaSelection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  conversationsHeaderText: {
    fontStyle: "italic",
    color: `${hgemColours.DarkGrey}`,
    fontSize: "1em",
    textAlign: "center",
    marginBottom: "8px",
  },
  sentPrivatelyToText: {
    marginBottom: "8px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  conversationsContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      width: "74.4%",
    },
  },
  relatedConversationsContainer: {
    [theme.breakpoints.up("xs")]: {
      width: "25.6%",
    },
  },
  invalidFileSelected: {
    color: hgemColours.Red,
  },
}));

export const ConversationsViewStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
