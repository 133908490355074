import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { IVisitReportState } from "../../../state/reducers/VisitReport-Reducer";
import {
  IReviewContent,
  IReviewResponseTemplate,
} from "../../../state/types/VisitReport";

export const VisitReportContent = createSelector(
  (state: RootState) => state.VisitReportReducer,
  (state: IVisitReportState) => {
    return state.reportContent;
  }
);

export const VisitReportPending = createSelector(
  (state: RootState) => state.VisitReportReducer,
  (state: IVisitReportState) => {
    return state.pending;
  }
);

export const VisitDetailError = createSelector(
  (state: RootState) => state.VisitReportReducer,
  (state: IVisitReportState) => {
    return state.error;
  }
);

export const GetActiveNotificationById = createSelector(
  (state: RootState) => state.AppNotificationReducer,
  (_: RootState, id: string | undefined) => id,
  (state, id) => {
    return id
      ? state.notifications.find((x) => x.Identifier === id)
      : undefined;
  }
);

export const GetResponseTemplatesForReview = createSelector(
  (state: RootState) => state.VisitReportReducer,
  (_: RootState, review: IReviewContent | undefined) => review,
  (state, review) => {
    if (review?.score) {
      const score = review.score;
      const commentIsEmpty = !review?.reviewText;

      return state.responseTemplates.filter(
        (template) =>
          template.applicableScoreMin <= score &&
          template.applicableScoreMax >= score &&
          useWithEmptyComments(template, commentIsEmpty)
      );
    } else {
      return [];
    }
  }
);

const useWithEmptyComments = (
  template: IReviewResponseTemplate,
  commentIsEmpty: boolean
) => {
  if (commentIsEmpty && template.useWithEmptyComments) {
    return true;
  }
  if (!commentIsEmpty && template.useWithPopulatedComments) {
    return true;
  }
  return false;
};
