import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  frame: {
      width: "100%",
      minHeight: "200px",
      height: "calc(100vh - 150px)",
      border: "none",
      boxShadow: `0px 5px 25px -9px ${hgemColours.DarkGrey}`
  }
}));

export const ModuleViewerStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
