import { IMinimalCaseEventAttachment } from "../../../../state/types/TaskCentreCases";
import { AppealsStyles } from "../../../InboxAppeals/styles";
import Attachment from "./attachment";

type Props = {
  attachments: (IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  })[];
  downloadAttachment:
    | ((caseEventAttachment: IMinimalCaseEventAttachment) => Promise<Blob>)
    | undefined;
  authorDeleteAttachment: (
    authorId: number | undefined
  ) => ((caseEventAttachmentId: number) => Promise<void>) | undefined;
};

const AttachmentsList = (props: Props) => {
  const { attachments, downloadAttachment, authorDeleteAttachment } = props;

  const appealsClasses = AppealsStyles();

  return (
    <>
      {attachments && attachments.length > 0 && (
        <div
          className={`${appealsClasses.flexColumn}`}
          style={{ width: "100%" }}
        >
          {attachments.map((attachment) => (
            <Attachment
              key={attachment.id}
              attachment={attachment}
              downloadAttachment={downloadAttachment}
              authorDeleteAttachment={authorDeleteAttachment}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AttachmentsList;
