import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  navOption: {
    borderRadius: "5px",
    cursor: "pointer",

    "&.Mui-selected": {
      transition: "0.5s ease",
    },
  },

  navOptionExpander: {
    float: "right",
  },
  navOptionExpanderButton: {
    color: theme.hubSettings.navigation.textColor,
    width: "inherit",
    margin: "1px 5px",
    display: "inline-block",
    padding: "1.5% 1.5% 1.5% 1.5%",
    transition: "background-color 0.7s ease",
    borderRadius: "5px",
    cursor: "pointer",
  },
  expanderButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    "&:hover": {
      background: hgemColours.LightGrey,
    },
  },
  selectedNavOptionExpanderButton: {
    color: theme.hubSettings.navigation.textColor,
    width: "inherit",
    display: "inline-block",
    padding: "4% 4% 0% 4%",
    transition: "background-color 0.7s ease",
    borderRadius: "5px",
  },
  navOptionIcon: {
    marginRight: "15px",
    verticalAlign: "sub",
    width: "1.3em",
    float: "left",
    color: hgemColours.ExtraMediumGrey,
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  iconContainer: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  labelContainer: {
    width: "70%",
    marginLeft: "5%",
  },
  isSelected: {
    color: hgemColours.LimeGreen,
  },
  isNotSelected: {
    color: hgemColours.DarkGrey,
  },
  ExpandMoreIcon: {
    marginLeft: "auto",
    transition: "250ms all linear",
  },
  ExpandLessIcon: {
    marginLeft: "auto",
    color: hgemColours.LimeGreen,
    transform: "rotate(180deg)",
    transition: "250ms all linear",
  },
  accordionRoot: {
    backgroundColor: theme.hubSettings.navigation.backgroundColor,
    background: theme.hubSettings.navigation.background,

    boxShadow: "none",
    border: "none",
    "&:not(:last-child)": {
      borderBottom: "0",
      backgroundColor: hgemColours.Orange,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "&:hover": {
      backgroundColor: theme.hubSettings.navigation.backgroundColor,
      background: theme.hubSettings.navigation.background,
    },
  },
  accordionSummary: {
    minHeight: "40px",
    fontFamily: "'Open Sans', Tahoma, Geneva, Verdana, sans-serif",
    fontWeight: 200,
    fontSize: "0.9em",
    height: "40px",
    color: theme.hubSettings.navigation.textColor,
    "&:hover": {
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
    },
    "&.Mui-expanded": {
      marginBottom: "1%",
      minHeight: "40px",
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
      borderRadius: "4px",
    },
  },

  accordionDetails: {
    padding: "0",
  },
  noPadding: {
    padding: "0",
  },
}));

export const ExpanderOptionStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
