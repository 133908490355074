import { Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { i18n } from "../../../localizations";
import { ConversationMessagesState } from "../../../state/reducers/Conversations-Reducer";
import { ConversationSourceConfigs } from "../../../state/selectors/Conversations-Selectors";
import {
  Conversation,
  ConversationSourceConfig,
} from "../../../state/types/Conversations";
import { ConversationLocations } from "../../inboxViews/state/selectors";
import CommonCommentField from "../../_common/comments/components/commonCommentField";
import { ConversationsViewStyles } from "../styles";
import { mapConversationSourceDisplayName } from "../utils";
import { MediaSelection } from "./messages/media";
import { CommonStyleClasses } from "../../../assets";

type Props = {
  context:
    | { conversation: Conversation; messagesState: ConversationMessagesState }
    | undefined;
  isMobileView: boolean;
  messages: () => JSX.Element;
  sendConversationMessage: (
    commentText: string,
    selectedFile: File | undefined
  ) => void;
  doNotShowSendMessageReason?: string | undefined;
};

const ConversationContent = (props: Props) => {
  const conversationSourceConfigs = useSelector(ConversationSourceConfigs);
  const locations = useSelector(ConversationLocations);
  const commonClasses = CommonStyleClasses();
  const conversationsViewStyles = ConversationsViewStyles();

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [invalidFileError, setInvalidFileError] = useState<string | undefined>(
    undefined
  );
  const [showSendMessage, setShowSendMessage] = useState<boolean | undefined>(
    undefined
  );
  const [conversationSourceConfig, setConversationSourceConfig] = useState<
    ConversationSourceConfig | undefined
  >();

  const location = locations.find(
    (x) => x.branchId === props.context?.conversation.branchId
  );

  useEffect(() => {
    setConversationSourceConfig(
      props.context
        ? conversationSourceConfigs.find(
            (x) => x.source === props.context?.conversation.source
          )
        : undefined
    );
  }, [
    conversationSourceConfigs,
    props.context,
    props.context?.conversation.source,
  ]);

  useEffect(() => {
    const inboundMessages =
      props.context?.messagesState.messages.filter((x) => x.isInbound) ?? [];

    const lastInboundMessage =
      inboundMessages.length > 0
        ? inboundMessages[inboundMessages.length - 1]
        : undefined;

    if (lastInboundMessage && conversationSourceConfig) {
      const hoursConfig = conversationSourceConfig.replyTimeLimitHours;

      if (hoursConfig) {
        const hoursDifference = dayjs().diff(
          dayjs(lastInboundMessage?.createdDate, { utc: true }).local(),
          "hours"
        );

        setShowSendMessage(hoursDifference < hoursConfig);
      } else {
        setShowSendMessage(true);
      }
    } else {
      setShowSendMessage(undefined);
    }
  }, [
    props.context,
    conversationSourceConfig,
    props.context?.messagesState.messages,
  ]);

  return (
    <>
      {(props.doNotShowSendMessageReason || showSendMessage === false) && (
        <>
          <Typography
            className={`${conversationsViewStyles.conversationsHeaderText} ${commonClasses.darkGreyText}`}
          >
            {props.doNotShowSendMessageReason ??
              i18n.translate("CONVERSATION_MESSAGE_CANNOT_SEND")}
          </Typography>
        </>
      )}
      <Typography className={`${commonClasses.darkGreyText} ${conversationsViewStyles.sentPrivatelyToText}`}>
        {i18n.translate("CONVERSATIONS_SENT_PRIVATELY_TO")}{" "}
        {location?.branchName}{" "}
        {i18n.translate("CONVERSATIONS_SENT_PRIVATELY_VIA")}{" "}
        {props.context &&
          mapConversationSourceDisplayName(props.context.conversation.source)}
      </Typography>
      <props.messages />
      {!props.doNotShowSendMessageReason && showSendMessage === true && (
        <>
          {conversationSourceConfig?.mediaAllowedFileExtensions &&
            conversationSourceConfig?.mediaSizeLimitMegabytes && (
              <div className={`${conversationsViewStyles.mediaSelection}`}>
                <MediaSelection
                  onFileSelected={(file) => {
                    setSelectedFile(file);
                    setInvalidFileError(undefined);
                  }}
                  invalidFileSelected={(reason) => {
                    setInvalidFileError(reason);
                    setSelectedFile(undefined);
                  }}
                  allowedMimetypes={
                    conversationSourceConfig.mediaAllowedFileExtensions
                  }
                  maxSizeMegabytes={
                    conversationSourceConfig.mediaSizeLimitMegabytes
                  }
                />
                <div>
                  {selectedFile && <span>{selectedFile.name}</span>}
                  {invalidFileError && (
                    <span
                      className={conversationsViewStyles.invalidFileSelected}
                    >
                      {invalidFileError}
                    </span>
                  )}
                </div>
              </div>
            )}
          <CommonCommentField
            fieldNotification={undefined}
            submitInternalOption={false}
            placeholder={i18n.translate(
              "CONVERSATION_MESSAGE_ADD_MESSAGE_TEXT"
            )}
            buttonText={i18n.translate("CONVERSATION_MESSAGE_SEND")}
            textRequired={selectedFile === undefined}
            onSubmit={(commentText) => {
              props.sendConversationMessage(commentText, selectedFile);
              setSelectedFile(undefined);
              setInvalidFileError(undefined);
            }}
          />
        </>
      )}
    </>
  );
};

export default ConversationContent;
