import {
  IFilterSetConfiguration,
  HubFilterType,
  IHubFilter,
} from "../types/FilterSets";

export const FILTERSETS_PENDING = "FILTERSETS_PENDING";
export const FILTERSETS_SUCCESS = "FILTERSETS_SUCCESS";
export const FILTERSETS_ERROR = "FILTERSETS_ERROR";
export const RESET_FILTER_SETS = "RESET_FILTER_SETS";
export const UPDATE_CURRENT_FILTERS_ENTRY = "UPDATE_CURRENT_FILTERS_ENTRY";
export const SET_CURRENT_FILTER_SET_TO_DEFAULT =
  "SET_CURRENT_FILTER_SET_TO_DEFAULT";
export const CHANGE_FILTER_SET_FILTER_VISIBILITY =
  "CHANGE_FILTER_SET_FILTER_VISIBILITY";

export interface IFilterSetsSuccess {
  type: typeof FILTERSETS_SUCCESS;
  filterSetConfiguration: IFilterSetConfiguration;
}

export function filterSetsSuccess(
  filterSetConfiguration: IFilterSetConfiguration
): IFilterSetsSuccess {
  return {
    type: FILTERSETS_SUCCESS,
    filterSetConfiguration,
  };
}

export interface IFilterSetsPending {
  type: typeof FILTERSETS_PENDING;
}

export function filterSetsPending(): IFilterSetsPending {
  return {
    type: FILTERSETS_PENDING,
  };
}

export interface IFilterSetsError {
  type: typeof FILTERSETS_ERROR;
  error: string;
}

export function filterSetsError(error: string): IFilterSetsError {
  return {
    type: FILTERSETS_ERROR,
    error: error,
  };
}

export interface IResetFilterSets {
  type: typeof RESET_FILTER_SETS;
}

export function resetFilterSets(): IResetFilterSets {
  return {
    type: RESET_FILTER_SETS,
  };
}

export interface IUpdateCurrentFiltersEntry {
  type: typeof UPDATE_CURRENT_FILTERS_ENTRY;
  filterType: HubFilterType;
  applyUpdate: (filter: IHubFilter) => void;
}

export function updateCurrentFiltersEntry(
  filterType: HubFilterType,
  applyUpdate: (filter: any) => void
): IUpdateCurrentFiltersEntry {
  return {
    type: UPDATE_CURRENT_FILTERS_ENTRY,
    filterType,
    applyUpdate,
  };
}

export interface ISetCurrentFilterSetToDefault {
  type: typeof SET_CURRENT_FILTER_SET_TO_DEFAULT;
  pageRef: string;
}

export function setCurrentFilterSetToDefault(
  pageRef: string
): ISetCurrentFilterSetToDefault {
  return {
    type: SET_CURRENT_FILTER_SET_TO_DEFAULT,
    pageRef,
  };
}

export interface IChangeFilterSetFilterVisibility {
  type: typeof CHANGE_FILTER_SET_FILTER_VISIBILITY;
  filterSet: string;
  visibleFilters: string[];
}

export function changeFilterSetFilterVisibility(
  filterSet: string,
  visibleFilters: string[]
): IChangeFilterSetFilterVisibility {
  return {
    type: CHANGE_FILTER_SET_FILTER_VISIBILITY,
    filterSet,
    visibleFilters,
  };
}

export type FilterSetActions =
  | IFilterSetsPending
  | IFilterSetsSuccess
  | IFilterSetsError
  | IResetFilterSets
  | IUpdateCurrentFiltersEntry
  | ISetCurrentFilterSetToDefault
  | IChangeFilterSetFilterVisibility;
