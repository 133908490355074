import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  messageSentMediaContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: "40px",
    marginLeft: "auto",
    marginRight: "10px",
    textAlign: "right",
    minWidth: "200px",
  },
  accordionSummary: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "2%",
    marginRight: "20%",
    justifyContent: "flex-end",
    height: "1em",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
      marginRight: "3.5%",
    },
  },
  messageSentContainer: {
    marginBottom: "40px",
    width: "calc(100% - 100px)",
    marginLeft: "125px",
    marginRight: "10px",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "25px",
      marginRight: "0",
      width: "100%",
    },
  },
  message: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    padding: "15px 10px",
    borderRadius: "20px",
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    marginTop: "5px",
    whiteSpace: "pre-line",
    fontSize: "0.9em",
  },
  messageIcon: {
    fontSize: "0.8em",
    color: theme.palette.grey[500],
    margin: "0 10px 0 5px",
    width: "200%",
  },
  iconWidth: {
    width: "4%",
    [theme.breakpoints.down("md")]: {
      width: "6%",
    },
  },
  messageContent: {
    display: "grid",
    flexGrow: 10,
  },
  messageText: {
    marginTop: "15px",
    textWrap: "pretty",
  },
  messageMedia: {
    marginTop: "15px",
  },
  messageSubject: {
    fontWeight: 600,
  },
  outBound: {
    order: -1,
    marginRight: "2%",
  },
}));

export const MessageSentStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
