import React from "react";
import { Button, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import i18n from "../../../localizations/i18n";
import { RenderErrorMessageStyles } from "../styles/renderErrorMessageStyles";

const RenderErrorMessage = () => {
  const classes = RenderErrorMessageStyles();

  const refreshPageAfterError = (): void => {
    window.location.reload();
  };

  const copyEmailToClipboard = (): void => {
    const email = document.getElementById(
      "error-support-email"
    ) as HTMLInputElement;
    try {
      if (email) {
        email.select();
        email.setSelectionRange(0, 99999);

        document.execCommand("copy");
      }
    } catch { console.log("Failed to copy email address")}
  };

  const getMessageText = (): string => {
    let message = i18n.translate("REPORT_RENDER_Render_Error_Message");
    const supportEmail = i18n.translate(
      "REPORT_RENDER_Render_Error_Support_Email"
    );
    const supportPhone = i18n.translate(
      "REPORT_RENDER_Render_Error_Support_Phone"
    );

    message = message.replace("**support-email**", supportEmail);
    message = message.replace("**support-phone**", supportPhone);

    return message;
  };

  const additionalNotice = i18n.translate(
    "REPORT_RENDER_Render_Error_AdditionalNotice"
  );

  return (
    <div className={classes.errorContainer}>
      <Typography variant="h5">
        <ErrorOutlineIcon fontSize="large" />
        {i18n.translate("REPORT_RENDER_Render_Error_Title")}
      </Typography>
      <p>{i18n.translate("REPORT_RENDER_Render_Error_Header")}</p>
      <p>{getMessageText()}</p>

      {additionalNotice && additionalNotice.length > 0 && (
        <p className={classes.additionalNotice}>{additionalNotice}</p>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={refreshPageAfterError}
      >
        {i18n.translate("REPORT_RENDER_Refresh_Page")}
      </Button>
      <Button onClick={copyEmailToClipboard}>
        {i18n.translate("REPORT_RENDER_Copy_Email")}
      </Button>
      <input
        aria-hidden="true"
        id="error-support-email"
        type="text"
        defaultValue={i18n.translate(
          "REPORT_RENDER_Render_Error_Support_Email"
        )}
      />
    </div>
  );
};

export default RenderErrorMessage;
