import {
  IHubFilter,
  IFilterAvailability,
  FilterVisibilityBehaviour,
} from "../../../../state/types/FilterSets";

const removeUnavailableFilters = (
  filters: IHubFilter[],
  filterAvailabilityList: IFilterAvailability[],
  pageInternalRef: string
): IHubFilter[] => {
  return filters.filter((filter) => {
    let filterIsAvailable = true;
    const filterAvailability = filterAvailabilityList.find(
      (x) => x.filterType === filter.type
    );
    if (filterAvailability) {
      filterIsAvailable = filterAvailability.isAvailable;

      let exclusionExists = false;
      filterAvailability.excludePageRefs.forEach((ref) => {
        if (ref.toLowerCase() === pageInternalRef.toLowerCase()) {
          exclusionExists = true;
        }
      });

      if (exclusionExists) {
        filterIsAvailable = !filterIsAvailable;
      }
    }

    return filterIsAvailable;
  });
};

const removeHiddenFilters = (
  pageRef: string,
  filters: IHubFilter[]
): IHubFilter[] => {
  return filters.filter((filter) => {
    if (
      filter.alwaysVisible ||
      (filter.alwaysVisibleInReports &&
        filter.alwaysVisibleInReports?.indexOf(pageRef) > -1) ||
      filter.userSetVisibility === FilterVisibilityBehaviour.Visible
    ) {
      return true;
    } else {
      return false;
    }
  });
};

export const applyFilterAvailabilityRules = (
  filters: IHubFilter[],
  filterAvailabilityList: IFilterAvailability[],
  pageInternalRef: string,
  removeUserHiddenFiltersFromResults = true
): IHubFilter[] => {
  const availableFilters = removeUnavailableFilters(
    filters,
    filterAvailabilityList,
    pageInternalRef
  );

  if (removeUserHiddenFiltersFromResults) {
    return removeHiddenFilters(pageInternalRef, availableFilters);
  } else {
    return availableFilters;
  }
};
