import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
  availableValues: string[];
  selectedValues: string[];
  tagName: string;
  applySelectionCallback: (selections: string[]) => void;
}

const AnalysisTagValuePicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([] as string[]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedValues.length === 0 ||
      selectedValues.length === props.availableValues.length
    ) {
      selectionText = i18n.translate("ANALYSIS_TAG_VALUE_PICKER_All_Selected");
    } else if (selectedValues.length === 1) {
      selectionText = selectedValues[0];
    } else {
      selectionText = `${selectedValues.length} ${i18n.translate(
        "ANALYSIS_TAG_VALUE_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    return selectedValues.join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    props.applySelectionCallback(selectedOptions);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedValues(props.selectedValues);
  }, [setSelectedValues, props.selectedValues]);

  return (
    <FilterWrapper
      label={i18n.translate(`ANALYSIS_TAG_VALUE_PICKER_${props.tagName}`)}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      isDisabled={props.availableValues.length === 0}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "ANALYSIS_TAG_VALUE_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={props.availableValues}
        selectedOptions={selectedValues}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
      
      />
    </FilterWrapper>
  );
};

export default AnalysisTagValuePicker;
