import { SagaMiddleware } from "redux-saga";
import { saveAs } from "file-saver";
import { call, put, takeLeading } from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import { Logging, SeverityLevel } from "../../utils/logging";
import {
  IDownloadVisitReportPdf,
  IFetchVisitReportHtml,
  IOpenVisitReportInInbox,
  visitReportError,
  visitReportContentSuccess,
  visitReportPending,
  visitReportSuccess,
  VISIT_REPORT_DOWNLOAD_PDF,
  VISIT_REPORT_FETCH_VISIT_HTML,
  VISIT_REPORT_OPEN_VISIT_DETAIL,
  VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT,
  fetchReviewResponseTemplatesSuccess,
  VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES,
  IFetchReviewResponseTemplates,
} from "../actions/VisitReport-Actions";
import {
  downloadVisitReportApi,
  fetchReviewResponseTemplatesApi,
  fetchVisitReportHtmlApi,
  fetchVisitReportReviewContentApi,
} from "../api/VisitReport-API";
import {
  IReviewContent,
  IReviewResponseTemplate,
  VisitReportContentType,
} from "../types/VisitReport";
import { routes } from "../../navigation/routes";

export function registerVisitReportSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLeading(VISIT_REPORT_DOWNLOAD_PDF, downloadVisitReportPdf);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(VISIT_REPORT_OPEN_VISIT_DETAIL, openVisitReportInInbox);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(VISIT_REPORT_FETCH_VISIT_HTML, fetchVisitHtml);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(
      VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT,
      fetchVisitReviewContent
    );
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(
      VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES,
      fetchReviewResponseTemplates
    );
  });
}

function* downloadVisitReportPdf(action: IDownloadVisitReportPdf): unknown {
  yield put(visitReportPending());

  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const reportResponse: Blob = (yield call(
      downloadVisitReportApi,
      clientId,
      action.visitId
    )).data;

    const fullFilename = `VisitReport-${action.visitId}.pdf`;
    saveAs(reportResponse, fullFilename);

    yield put(visitReportSuccess());
  } catch (e) {
    Logging.captureError("Saga:downloadVisitReportPdf", e, SeverityLevel.Error);
    yield put(visitReportError("Failed to download PDF"));
  }
}

function* openVisitReportInInbox(action: IOpenVisitReportInInbox): unknown {
  yield put(visitReportPending());

  const inboxPath = routes.inbox;
  window.open(`${inboxPath}?visitid=${action.visitId}`);
  yield put(visitReportSuccess());
}

function* fetchVisitHtml(action: IFetchVisitReportHtml): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const reportResponse: string = (yield call(
      fetchVisitReportHtmlApi,
      clientId,
      action.visitId
    )).data;

    validateRawHtml(reportResponse);

    yield put(
      visitReportContentSuccess(
        action.visitId,
        reportResponse,
        undefined,
        VisitReportContentType.html
      )
    );
  } catch (e) {
    Logging.captureError("Saga:fetchVisitHtml", e, SeverityLevel.Error);
    yield put(visitReportError("Failed to fetch HTML"));
  }
}

function* fetchVisitReviewContent(action: IFetchVisitReportHtml): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const reviewContent: IReviewContent = (yield call(
      fetchVisitReportReviewContentApi,
      clientId,
      action.visitId
    )).data;

    yield put(
      visitReportContentSuccess(
        action.visitId,
        undefined,
        reviewContent,
        VisitReportContentType.reviewContent
      )
    );
  } catch (e) {
    Logging.captureError(
      "Saga:fetchVisitReviewContent",
      e,
      SeverityLevel.Error
    );
    yield put(visitReportError("Failed to fetch review content"));
  }
}

function* fetchReviewResponseTemplates(
  action: IFetchReviewResponseTemplates
): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const templates: IReviewResponseTemplate[] = (yield call(
      fetchReviewResponseTemplatesApi,
      clientId,
      action.brandId
    )).data;

    yield put(fetchReviewResponseTemplatesSuccess(templates));
  } catch (e) {
    Logging.captureError(
      "Saga:fetchReviewResponseTemplates",
      e,
      SeverityLevel.Error
    );
  }
}

const validateRawHtml = (html: string): void => {
  const scriptTest = /(<script src=)|(<script>)|(<script type=)/g;
  const errorOnScriptDetected =
    process.env.REACT_APP_ERROR_ON_HTML_SCRIPT_DETECTION?.toLowerCase();

  const containsScript = scriptTest.test(html);

  if (containsScript) {
    Logging.captureEvent(
      "Saga:fetchVisitHtml:validateRawHtml",
      "Raw HTML Contains Script Tags."
    );

    if (errorOnScriptDetected === "true") {
      throw new Error(
        "Raw HTML retrieved for Visit Report contains dissalowed script tags."
      );
    }
  }
};
