import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
    selectedDayPartValues: string[];
    applySelectionCallback: (selectedDayParts: string[]) => void;
}

const DayPartValues = ["Morning", "Lunch", "Afternoon", "Evening", "Late Night"];

const DayPartPicker = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDayPartValues, setSelectedDayPartValues] = useState(
      [] as string[]
    );
  
    const handleToggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const getAppliedSelectionText = (): string => {
      let selectionText = "";
      if (
        selectedDayPartValues.length === 0 ||
        selectedDayPartValues.length === 7
      ) {
        selectionText = i18n.translate("DAY_PART_PICKER_All_Selected");
      } else if (selectedDayPartValues.length === 1) {
        selectionText = selectedDayPartValues[0];
      } else {
        selectionText = `${selectedDayPartValues.length} ${i18n.translate(
          "DAY_PART_PICKER_Selected"
        )}`;
      }
  
      return selectionText;
    };
  
    const buildSortedTooltipContent = (): string => {
        const selected: string[] = [];
        DayPartValues.forEach(d => {
          if (selectedDayPartValues.indexOf(d) > -1){
              selected.push(d);
          }
        });
  
        return selected.join(", ");
      };
  
    const applySelection = (selectedOptions: string[]) => {
      props.applySelectionCallback(selectedOptions);
      setIsOpen(false);
    };
  
    const cancelSelection = () => {
      setIsOpen(false);
    };
  
    useEffect(() => {
      setSelectedDayPartValues(props.selectedDayPartValues);
    }, [setSelectedDayPartValues, props.selectedDayPartValues]);
  
    return (
      <FilterWrapper
        label={i18n.translate("DAY_PART_PICKER_DayPart")}
        selectionHint={getAppliedSelectionText()}
        isOpen={isOpen}
        toggleOpenState={handleToggleOpen}
        tooltip={buildSortedTooltipContent()}
      >
        <GenericMultiSelect
          useSelectAllOption={false}
          selectAllOptionText={i18n.translate(
            "DAY_PART_PICKER_Select_All_Option"
          )}
          multiSelect={true}
          options={DayPartValues}
          selectedOptions={selectedDayPartValues}
          applySelectionCallback={applySelection}
          cancelSelectionCallback={cancelSelection}
          applyLocalisationToOptions={true}
          localisationPrefix="DAY_PART_PICKER_"
          applySort={false}
          showOptionTooltips={true}
          tooltipLocalisationPrefix="DAY_PART_PICKER_TOOLTIP_"
          showTooltipsAsOptionSuffix={true}
        />
      </FilterWrapper>
    );
};

export default DayPartPicker;