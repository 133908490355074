import {
  ITaskCentreCases,
  ICaseDetails,
  ICaseCategory,
  IManager,
  WorkflowStatus,
} from "../types/TaskCentreCases";
import { IGetCasesQuery } from "../types/TaskCentreCaseQuery";
import { ICaseCommand } from "../types/TaskCentreCommands";

export const TASKCENTRE_CASE_LIST_PENDING = "TASKCENTRE_CASE_LIST_PENDING";
export const TASKCENTRE_CASE_LIST_SUCCESS = "TASKCENTRE_CASE_LIST_SUCCESS";
export const TASKCENTRE_CASE_LIST_ERROR = "TASKCENTRE_CASE_LIST_ERROR ";
export const CLEAR_TASKCENTRE_CASE_LIST = "CLEAR_TASKCENTRE_CASE_LIST";
export const FETCH_TASKCENTRE_CATEGORIES = "FETCH_TASKCENTRE_CATEGORIES ";
export const UPDATE_TASKCENTRE_CATEGORIES = "UPDATE_TASKCENTRE_CATEGORIES";
export const FETCH_TASKCENTRE_CASE_LIST = "FETCH_TASKCENTRE_CASE_LIST ";
export const SET_TASKCENTRE_CASE_COUNT = "SET_TASKCENTRE_CASE_COUNT";
export const MERGE_TASKCENTRE_CASE_LIST = "MERGE_TASKCENTRE_CASE_LIST";
export const REMOVE_ITEM_FROM_TASKCENTRE_CASE_LIST =
  "REMOVE_ITEM_FROM_TASKCENTRE_CASE_LIST";
export const SET_SELECTED_TASKCENTRE_CASE = "SET_SELECTED_TASKCENTRE_CASE";
export const EXECUTE_CASE_COMMANDS = "EXECUTE_CASE_COMMANDS";
export const FETCH_TASKCENTRE_MANAGERS = "FETCH_TASKCENTRE_MANAGERS";
export const UPDATE_TASKCENTRE_MANAGERS = "UPDATE_TASKCENTRE_MANAGERS";
export const FILTER_TASKCENTRE_CASE_LIST = "FILTER_TASKCENTRE_CASE_LIST";
export const TASKCENTRE_CREATE_NEW_CASE = "TASKCENTRE_CREATE_NEW_CASE";

export interface ITaskCentreCaseListSuccess {
  type: typeof TASKCENTRE_CASE_LIST_SUCCESS;
  taskCentreCases: ITaskCentreCases;
}

export function actionCentreCaseListSuccess(
  taskCentreCases: ITaskCentreCases
): ITaskCentreCaseListSuccess {
  return {
    type: TASKCENTRE_CASE_LIST_SUCCESS,
    taskCentreCases: taskCentreCases,
  };
}

export interface ITaskCentreCaseListPending {
  type: typeof TASKCENTRE_CASE_LIST_PENDING;
  query: IGetCasesQuery | undefined;
  silent: boolean;
}

export function actionCentreCaseListPending(
  query: IGetCasesQuery | undefined,
  silent = false
): ITaskCentreCaseListPending {
  return {
    type: TASKCENTRE_CASE_LIST_PENDING,
    query: query,
    silent: silent,
  };
}

export interface ITaskCentreCaseListError {
  type: typeof TASKCENTRE_CASE_LIST_ERROR;
  error: string;
}

export function actionCentreCaseListError(
  error: string
): ITaskCentreCaseListError {
  return {
    type: TASKCENTRE_CASE_LIST_ERROR,
    error: error,
  };
}

export interface IClearTaskCentreCaseList {
  type: typeof CLEAR_TASKCENTRE_CASE_LIST;
}

export function clearTaskCentreCaseList(): IClearTaskCentreCaseList {
  return {
    type: CLEAR_TASKCENTRE_CASE_LIST,
  };
}

export interface IFetchTaskCentreCaseList {
  type: typeof FETCH_TASKCENTRE_CASE_LIST;
  query: IGetCasesQuery;
  silent: boolean;
}

export function fetchTaskCentreCaseList(
  query: IGetCasesQuery,
  silent = false
): IFetchTaskCentreCaseList {
  return {
    type: FETCH_TASKCENTRE_CASE_LIST,
    query,
    silent,
  };
}

export interface ISetTaskCentreCaseCount {
  type: typeof SET_TASKCENTRE_CASE_COUNT;
  count: number | undefined;
}

export function setTaskCentreCaseCount(
  count: number | undefined
): ISetTaskCentreCaseCount {
  return {
    type: SET_TASKCENTRE_CASE_COUNT,
    count,
  };
}

export interface IMergeTaskCentreCaseList {
  type: typeof MERGE_TASKCENTRE_CASE_LIST;
  caseList: ICaseDetails[];
  selectedCaseId: number | undefined;
}

export function mergeTaskCentreCaseLists(
  caseList: ICaseDetails[],
  selectedCaseId: number | undefined
): IMergeTaskCentreCaseList {
  return {
    type: MERGE_TASKCENTRE_CASE_LIST,
    caseList,
    selectedCaseId,
  };
}

export interface ISetSelectedTaskCentreCase {
  type: typeof SET_SELECTED_TASKCENTRE_CASE;
  caseId: number;
}

export function setSelectedTaskCentreCase(
  caseId: number
): ISetSelectedTaskCentreCase {
  return {
    type: SET_SELECTED_TASKCENTRE_CASE,
    caseId,
  };
}

export interface IExecuteCaseCommands {
  type: typeof EXECUTE_CASE_COMMANDS;
  caseId: number;
  commands: ICaseCommand[];
  callback?: () => void;
}

export function executeCaseCommand(
  caseId: number,
  commands: ICaseCommand[],
  callback?: () => void
): IExecuteCaseCommands {
  return {
    type: EXECUTE_CASE_COMMANDS,
    caseId,
    commands,
    callback,
  };
}

export interface IFetchTaskCentreCategories {
  type: typeof FETCH_TASKCENTRE_CATEGORIES;
}

export function fetchTaskCentreCategories(): IFetchTaskCentreCategories {
  return {
    type: FETCH_TASKCENTRE_CATEGORIES,
  };
}

export interface IUpdatectionCentreCategories {
  type: typeof UPDATE_TASKCENTRE_CATEGORIES;
  categories: ICaseCategory[];
}

export function updateTaskCentreCategories(
  categories: ICaseCategory[]
): IUpdatectionCentreCategories {
  return {
    type: UPDATE_TASKCENTRE_CATEGORIES,
    categories,
  };
}

export interface IFetchTaskCentreManagers {
  type: typeof FETCH_TASKCENTRE_MANAGERS;
}

export function fetchTaskCentreManagers(): IFetchTaskCentreManagers {
  return {
    type: FETCH_TASKCENTRE_MANAGERS,
  };
}

export interface IUpdatectionCentreManagers {
  type: typeof UPDATE_TASKCENTRE_MANAGERS;
  managers: IManager[];
}

export function updateTaskCentreManagers(
  managers: IManager[]
): IUpdatectionCentreManagers {
  return {
    type: UPDATE_TASKCENTRE_MANAGERS,
    managers,
  };
}

export interface IRemoveTaskCaseItem {
  type: typeof REMOVE_ITEM_FROM_TASKCENTRE_CASE_LIST;
  caseId: number;
}

export function removeTaskCaseItem(caseId: number): IRemoveTaskCaseItem {
  return {
    type: REMOVE_ITEM_FROM_TASKCENTRE_CASE_LIST,
    caseId,
  };
}

export interface IFilterTaskCaseItems {
  type: typeof FILTER_TASKCENTRE_CASE_LIST;
  searchText: string;
}

export function filterTaskCaseItems(searchText: string): IFilterTaskCaseItems {
  return {
    type: FILTER_TASKCENTRE_CASE_LIST,
    searchText,
  };
}

export interface ICreateNewTaskCentreCase {
  type: typeof TASKCENTRE_CREATE_NEW_CASE;
  visitId: number;
  title: string;
  status: WorkflowStatus;
  commands?: ICaseCommand[];
  callback?: () => void;
}

export function createNewTaskCentreCase(
  visitId: number,
  title: string,
  status: WorkflowStatus,
  commands?: ICaseCommand[],
  callback?: () => void
): ICreateNewTaskCentreCase {
  return {
    type: TASKCENTRE_CREATE_NEW_CASE,
    visitId,
    title,
    status,
    commands,
    callback,
  };
}

export type TaskCentreListActions =
  | ITaskCentreCaseListPending
  | ITaskCentreCaseListSuccess
  | ITaskCentreCaseListError
  | IClearTaskCentreCaseList
  | IFetchTaskCentreCaseList
  | ISetTaskCentreCaseCount
  | IMergeTaskCentreCaseList
  | ISetSelectedTaskCentreCase
  | IExecuteCaseCommands
  | IFetchTaskCentreCategories
  | IUpdatectionCentreCategories
  | IFetchTaskCentreManagers
  | IUpdatectionCentreManagers
  | IRemoveTaskCaseItem
  | IFilterTaskCaseItems
  | ICreateNewTaskCentreCase;
