import React, { useEffect, useState } from "react";
import FormField from "../../_common/formField/formField";
import { TextField } from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { ChangeCaseTitleCommand } from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { executeCaseCommand } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { TitleFieldStyles } from "../styles/inputs/titleFieldStyles";
import { hgemColours } from "../../../themes/defaultTheme";

interface IProps {
  caseId: number;
  title: string;
}

const TitleField = (props: IProps) => {
  const classes = TitleFieldStyles();
  const dispatch = useDispatch();

  const [caseId, setCaseId] = useState(props.caseId);
  const [titleValue, setTitleValue] = useState(props.title);
  const [error, setError] = useState<string | undefined>(undefined);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validate(value);
    setTitleValue(value);
  };

  const validate = (value: string) => {
    if (!value.trim()) {
      setError(i18n.translate("TASK_CENTRE_FIELD_Title_Error_Blank"));
    } else if (value.length > 100) {
      setError(i18n.translate("TASK_CENTRE_FIELD_Title_Error_Length"));
    } else {
      setError(undefined);
    }
  };

  const onBlur = () => {
    const noChange = titleValue === props.title;
    if ((error ? true : false) || noChange) {
      return;
    }

    const titleCommand = new ChangeCaseTitleCommand(props.caseId, titleValue);
    dispatch(executeCaseCommand(props.caseId, [titleCommand]));
  };

  useEffect(() => {
    if (caseId !== props.caseId) {
      setCaseId(props.caseId);
    }

    setTitleValue(props.title);
  }, [caseId, props.caseId, props.title]);

  return (
    <FormField
      notificationIdentifier={`${props.caseId}|${ChangeCaseTitleCommand.type}`}
    >
      <TextField
        style={{ backgroundColor: hgemColours.White }}
        inputProps={{ className: classes.inputSize }}
        aria-label={i18n.translate("TASK_CENTRE_FIELD_Title")}
        className={classes.titleField}
        value={titleValue}
        fullWidth
        size={"medium"}
        helperText={error}
        error={error !== undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormField>
  );
};

export default TitleField;
