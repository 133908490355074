import { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericTreeView from "../genericTreeView/genericTreeView";
import { ILocationFilterOption } from "../../../state/types/FilterOptions";
import { CaseInsensitiveSort } from "../../../utils/sortnig";
import { IKeyValuePair } from "../../../state/types/FilterSets";
import { i18n } from "../../../localizations";

interface IProps {
  availableLocations: ILocationFilterOption[];
  selectedLocations: IKeyValuePair[];
  applySelectionCallback: (selections: IKeyValuePair[]) => void;
}

const LocationPicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  const [selectedLocations, setSelectedLocations] = useState([] as string[]);

  const getAppliedSelectionText = (): string => {
    const nodes = props.selectedLocations.length;

    if (nodes === 1) {
      return props.selectedLocations[0].value;
    }

    return nodes === 0
      ? i18n.translate("LOCATION_PICKER_All_Selected")
      : `${nodes} ${i18n.translate("LOCATION_PICKER_Selected")}`;
  };

  const buildTooltipContent = (): string => {
    if (props.selectedLocations && props.selectedLocations.length > 0) {
      const tooltipText = `${props.selectedLocations.length} ${i18n.translate(
        "LOCATION_PICKER_Locations_Selected"
      )} `;

      const selectedLocations = props.selectedLocations.map((x) => x.value);
      let locationsConcatenated = selectedLocations
        .sort(CaseInsensitiveSort)
        .join(", ");

      if (locationsConcatenated.length > 150) {
        locationsConcatenated = locationsConcatenated.substring(0, 150) + "...";
      }

      return tooltipText + locationsConcatenated;
    } else {
      return i18n.translate("LOCATION_PICKER_All_Locations_Selected");
    }
  };

  const handleSelection = (selectedBranchNames: string[]): void => {
    const selectedLocations = props.availableLocations
      .filter((x) => selectedBranchNames.indexOf(x.branchName) > -1)
      .map((x) => {
        return {
          key: x.branchId,
          value: x.branchName,
        } as IKeyValuePair;
      });

    props.applySelectionCallback(selectedLocations);
  };

  const getNodeSequence = (): string[] => {
    const nodes: string[] = [];

    const regions = new Set(props.availableLocations.map((x) => x.region));
    const areas = new Set(props.availableLocations.map((x) => x.area));

    if (regions.size > 1) {
      nodes.push("region");
    }

    if (areas.size > 1) {
      nodes.push("area");
    }

    nodes.push("branchName");
    return nodes;
  };

  useEffect(() => {
    setSelectedLocations(props.selectedLocations.map((x) => x.value));
  }, [setSelectedLocations, props.selectedLocations]);

  return (
    <FilterWrapper
      label={i18n.translate("LOCATION_PICKER_Locations")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      <GenericTreeView
        applySelectionCallback={handleSelection}
        cancelSelectionCallback={cancelSelection}
        nodeOptions={props.availableLocations}
        selectedNodes={selectedLocations}
        nodeSequence={getNodeSequence()}
        sortNodes={true}
      />
    </FilterWrapper>
  );
};

export default LocationPicker;
