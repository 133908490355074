import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { StorageInstance } from "../utils/localStorage";
import { Logging } from "../utils/logging";
import {
  msalInstance,
  authority_login,
  b2c_redirect_uri,
  token_refresh_redirect_uri,
  api_token_scope,
  token_userType_claim,
  token_allowedClients_claim,
  token_managerDetails_claim,
  default_gem_user,
  default_gem_client,
  email_claim,
  preferred_account_authority,
  b2c_passwordResetErrorId,
  handlePasswordResetRedirect,
  fallback_password_reset_error_id,
} from "./msalConfig";

const AdmUserKey = "hgem_adm_usr";
const AdmClientKey = "hgem_adm_client";
const UsrBrandKey = "hgem_brands";
const UserClientKey = "hgem_client";

enum UserType {
  Manager = 1,
  Admin = 2,
}

interface IClientIdAndName {
  clientIdAndNames: IIdAndName[];
}

interface IManagerIdAndName {
  managerIdAndName: IIdAndName;
}

interface IIdAndName {
  id: number;
  name: string;
}

class AuthService {
  public isAuthenticated = async (): Promise<boolean> => {
    await this.handleRedirectPromiseAndPasswordResets();

    const accounts = msalInstance.getAllAccounts();
    return accounts && accounts.length > 0;
  };

  public logout = async (): Promise<void> => {
    await this.handleRedirectPromiseAndPasswordResets();

    await msalInstance
      .logoutRedirect({
        authority: authority_login,
        postLogoutRedirectUri: b2c_redirect_uri,
      })
      .catch((e) => {
        const message = Logging.trySerializeErrorObject(e);
        Logging.captureEvent("Auth-Flow-Event", message);
      });
  };

  public getAccessToken = async (): Promise<AuthenticationResult | void> => {
    await this.handleRedirectPromiseAndPasswordResets();
    const account = await this.getAccount();

    const authResult = account
      ? await msalInstance
          .acquireTokenSilent({
            authority: authority_login,
            scopes: [api_token_scope],
            account: account,
            redirectUri: token_refresh_redirect_uri,
          })
          .catch((e) => {
            const message = Logging.trySerializeErrorObject(e);
            Logging.captureEvent("Auth-Flow-Event", message);
          })
      : undefined;

    if (!authResult) {
      const request = {
        authority: authority_login,
        scopes: [api_token_scope],
      };

      await this.handleRedirectPromiseAndPasswordResets();
      await msalInstance.acquireTokenRedirect(request);
    }

    return authResult;
  };

  public isAdmin = async (): Promise<boolean> => {
    const account = await this.getAccount();
    if (account === null) {
      return false;
    }

    if (account.idTokenClaims) {
      const claims = account.idTokenClaims as any;
      return Number.parseInt(claims[token_userType_claim]) === UserType.Admin;
    }

    return false;
  };

  public getUserId = async (): Promise<number> => {
    const account = await this.getAccount();
    if (account === null) {
      return 0;
    }

    if (account.idTokenClaims) {
      const claims = account.idTokenClaims as any;
      if (Number.parseInt(claims[token_userType_claim]) === UserType.Admin) {
        const id = StorageInstance.getValueOrDefault(
          AdmUserKey,
          default_gem_user.toString()
        );
        return Number.parseInt(id);
      } else {
        const rawClaim = claims[token_managerDetails_claim];
        const claimObject = JSON.parse(rawClaim) as IManagerIdAndName;

        return claimObject.managerIdAndName.id;
      }
    }

    Logging.captureEvent("Auth-Flow-Event", "Invalid Account Claims");
    throw new Error("Invalid Account Claims");
  };

  public setUserId = async (userId: number): Promise<void> => {
    const account = await this.getAccount();
    if (account === null) {
      return;
    }

    if (account.idTokenClaims) {
      const claims = account.idTokenClaims as any;
      if (Number.parseInt(claims[token_userType_claim]) === UserType.Admin) {
        StorageInstance.setValue(AdmUserKey, userId.toString());
      }
    }
  };

  public getUserName = async (): Promise<string> => {
    const account = await this.getAccount();
    if (account === null) {
      return "";
    }

    if (account.idTokenClaims) {
      const claims = account.idTokenClaims as any;
      if (Number.parseInt(claims[token_userType_claim]) === UserType.Admin) {
        const emailClaim = claims[email_claim];
        return emailClaim ? emailClaim.toString() : "HGEM";
      } else {
        const rawClaim = claims[token_managerDetails_claim];
        const claimObject = JSON.parse(rawClaim) as IManagerIdAndName;

        return claimObject.managerIdAndName.name;
      }
    }

    return "";
  };

  public getClientId = async (): Promise<number> => {
    const account = await this.getAccount();
    if (account === null) {
      return 0;
    }

    if (account.idTokenClaims) {
      const claims = account.idTokenClaims as any;
      if (Number.parseInt(claims[token_userType_claim]) === UserType.Admin) {
        const id = StorageInstance.getValueOrDefault(
          AdmClientKey,
          default_gem_client.toString()
        );
        return Number.parseInt(id);
      } else {
        const rawClaim = claims[token_allowedClients_claim];
        const claimObject = JSON.parse(rawClaim) as IClientIdAndName;

        const allClients = claimObject.clientIdAndNames.map((c) => c.id);
        const primaryClient = allClients[0];

        const storedClient = Number.parseInt(
          StorageInstance.getValueOrDefault(
            UserClientKey,
            primaryClient.toString()
          )
        );
        const storedClientIsValid = allClients.indexOf(storedClient) > -1;

        return storedClientIsValid ? storedClient : primaryClient;
      }
    }

    Logging.captureEvent("Auth-Flow-Event", "Invalid Account Claims");
    throw new Error("Invalid Account Claims");
  };

  public setClientId = async (clientId: number): Promise<void> => {
    const account = await this.getAccount();
    if (account === null) {
      return;
    }

    if (account.idTokenClaims) {
      const claims = account.idTokenClaims as any;
      if (Number.parseInt(claims[token_userType_claim]) === UserType.Admin) {
        StorageInstance.setValue(AdmClientKey, clientId.toString());
      } else {
        const rawClaim = claims[token_allowedClients_claim];
        const claimObject = JSON.parse(
          rawClaim ? rawClaim : "{}"
        ) as IClientIdAndName;

        const allClients = claimObject.clientIdAndNames.map((c) => c.id);
        const primaryClient = allClients[0];

        if (allClients.indexOf(clientId) > -1) {
          StorageInstance.setValue(UserClientKey, clientId.toString());
        } else {
          StorageInstance.setValue(UserClientKey, primaryClient.toString());
        }
      }
    }
  };

  public getUserBrands = (permittedBrands: Array<string>): Array<string> => {
    const storedBrands = StorageInstance.getValueOrDefault(UsrBrandKey, "");
    const brands = storedBrands.split("|").filter((b) => {
      return permittedBrands.indexOf(b) > -1;
    });

    const validatedBrands = brands.length > 0 ? brands : permittedBrands;
    this.setUserBrands(validatedBrands);

    return validatedBrands;
  };

  public setUserBrands = (brands: Array<string>): void => {
    let storedBrands = "";
    if (brands.length > 0) {
      brands.forEach((b) => {
        storedBrands += `${b}|`;
      });
    }

    StorageInstance.setValue(UsrBrandKey, storedBrands);
  };

  public getUsersCurrentBrands = async (): Promise<string[]> => {
    const brandValue = StorageInstance.getValue(UsrBrandKey);
    return brandValue.split("|");
  };

  getAccount = async (): Promise<AccountInfo | null> => {
    await this.handleRedirectPromiseAndPasswordResets();

    const accounts = msalInstance.getAllAccounts();
    if (!accounts || accounts.length === 0) {
      return null;
    } else {
      const preferredAccount = accounts.find(
        (x) => x.environment.toLowerCase() === preferred_account_authority
      );

      return preferredAccount ?? accounts[0];
    }
  };

  handleRedirectPromiseAndPasswordResets = async (): Promise<void> => {
    await msalInstance.handleRedirectPromise().catch((error: Error) => {
      const message = (error?.message ?? "").toUpperCase();

      if (
        message.includes(
          b2c_passwordResetErrorId ?? fallback_password_reset_error_id
        )
      ) {
        handlePasswordResetRedirect();
      }
    });
  };
}

export const AuthServiceInstance = new AuthService();
