import { Typography } from "@material-ui/core";
import { i18n } from "../../../../localizations";
import { MediaLinks } from "./types/mediaLinks";

type Props = {
    mediaLinks: MediaLinks | undefined;
    contentType: string
};

const MediaMessageAudio = (props: Props) => {
    return <>
        {props.mediaLinks && 
            <audio controls controlsList="nodownload" role="application"><source src={props.mediaLinks.localUrl} type={props.contentType}></source></audio>}
        {!props.mediaLinks && <Typography>{i18n.translate("MEDIA_MESSAGE_MEDIA_LOADING_AUDIO")}</Typography>}
    </>;
}

export default MediaMessageAudio;