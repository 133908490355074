import { Route, Switch } from "react-router-dom";
import Reports from "../views/reports";
import ELearning from "../views/eLearning";
import VisitReportDownload from "../views/visitReportDownload";
import Inbox from "../views/inbox";
import Logout from "../views/system/logout";
import ResetSession from "../views/system/resetSession";
import NotFound from "../views/system/notFound";
import Error from "../views/system/error";
import UnknownRoute from "./unknownRoute";
import { getRedirectionPaths, routes } from "./routes";
import SiteRoot from "../views/system/siteRoot";
import AuthLoader from "../views/system/authLoader";
import AuthError from "../views/system/authError";
import HubRedirection from "./hubRedirection";
import AppInsightsWrapper from "./appInsightsWrapper";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance, requestScope } from "../authentication/msalConfig";
import { SignalRNotificationHubConnection as SignalRNotificationsHubConnection } from "../signalr";
import HubLayout from "../views/layouts/hubLayout";
import { ProductFruits } from "react-product-fruits";
import { useUserId } from "../state/hooks/clients/useUserId";

const Routing = () => {
  const userId = useUserId();
  return (
    <MsalProvider instance={msalInstance}>
      <ProductFruits
        workspaceCode="Jmdh1848Zl2n7alB"
        language="en"
        user={{ username: `${userId}` }}
      />
      <Switch>
        <Route path={routes.logout} component={Logout} />
        <Route path={routes.error} component={Error} />

        <Route path={routes.resetSession}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={AuthError}
            loadingComponent={AuthLoader}
            authenticationRequest={requestScope}
          >
            <ResetSession />
          </MsalAuthenticationTemplate>
        </Route>
        <Route
          path={[
            routes.reports,
            routes.eLearning,
            routes.visitReportDownload,
            routes.inbox,
          ]}
        >
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={AuthError}
            loadingComponent={AuthLoader}
            authenticationRequest={requestScope}
          >
            <AppInsightsWrapper>
              <SignalRNotificationsHubConnection>
                <HubLayout>
                  <>
                    <Route path={routes.inbox} component={Inbox} />
                    <Route path={routes.reports} component={Reports} />
                    <Route path={routes.eLearning} component={ELearning} />
                    <Route
                      path={routes.visitReportDownload}
                      component={VisitReportDownload}
                    />
                  </>
                </HubLayout>
              </SignalRNotificationsHubConnection>
            </AppInsightsWrapper>
          </MsalAuthenticationTemplate>
        </Route>

        <Route path={getRedirectionPaths()}>
          <HubRedirection location={window.location} />
        </Route>

        <UnknownRoute
          location={window.location}
          notFoundComponent={NotFound}
          siteRootComponent={SiteRoot}
        />
      </Switch>
    </MsalProvider>
  );
};

export default Routing;
