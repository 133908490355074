import { AuthenticationResult } from "@azure/msal-browser";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthServiceInstance } from "../authentication/authService";
import { signalRNotification } from "../state/actions/SignalRNotifications-Actions";
import { useClientId } from "../state/hooks/clients/useClientId";
import { useUserId } from "../state/hooks/clients/useUserId";
import { SignalRNotifications } from "../state/types/SignalRNotifications";

type Props = {
  children: React.ReactElement;
};

const SignalRNotificationHubConnection = (props: Props) => {
  const dispatch = useDispatch();
  const clientId = useClientId();
  const userId = useUserId();

  useEffect(() => {
    if (!clientId || !userId) {
      return;
    }

    const connection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_NOTIFICATIONS_API_BASEURL}signalr/hubs/notifications`,
        {
          headers: {
            ["clientid"]: clientId.toString(),
            ["impersonationManagerId"]: userId.toString(),
          },
          accessTokenFactory: () =>
            AuthServiceInstance.getAccessToken().then(
              (response) => (response as AuthenticationResult).accessToken
            ),
        }
      )
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    connection.on("onNotification", (messageData: string) => {
      const notification = JSON.parse(messageData) as SignalRNotifications;

      dispatch(signalRNotification(notification));
    });

    connection.start();

    return () => {
      connection.stop();
    };
  }, [clientId, userId]);

  return <>{props.children}</>;
};

export default SignalRNotificationHubConnection;
