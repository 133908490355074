import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetApplicationState } from "../../state/actions/Application-Actions";
import { StorageInstance } from "../../utils/localStorage";

const ResetSession = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      resetApplicationState(() => {
        window.setTimeout(() => {
          StorageInstance.clearAllData();
          window.location.replace("/");
        }, 1000);
      })
    );
  });

  return <></>;
};

export default ResetSession;
