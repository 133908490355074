import React from "react";
import dayjs from "dayjs";
import i18n from "../../../localizations/i18n";
import { EventHeaderStyles } from "../styles/eventHeaderStyles";
import { IManager } from "../../../state/types/TaskCentreCases";

interface IProps {
  authorId?: number;
  alternativeAuthorName?: string;
  timestamp: string;
  description?: string;
  managers: IManager[];
  isInternal: boolean;
}

const EventHeader = (props: IProps) => {
  const classes = EventHeaderStyles();

  const author = props.authorId
    ? props.managers.find((x) => x.id === props.authorId)
    : undefined;

  const authorName = author ? author.name : props.alternativeAuthorName;
  const timestamp = dayjs(props.timestamp).format(
    i18n.translate("TASK_CENTRE_Timestamp_Format")
  );

  return (
    <div>
      {authorName && authorName.length > 0 && (
        <span className={classes.author}>
          {authorName}
          {props.isInternal
            ? i18n.translate("TASK_CENTRE_COMMENTS_Internal")
            : ""}
        </span>
      )}
      <span className={classes.meta}>{timestamp}</span>
      {props.description && (
        <span className={classes.meta}>{props.description}</span>
      )}
    </div>
  );
};

export default EventHeader;
