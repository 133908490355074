import { i18n } from "../../../../localizations";
import { IConversationState } from "../../../../state/reducers/Conversations-Reducer";
import { ConversationListLoaderStyles } from "../../styles";

interface IProps {
  conversationState: IConversationState;
}

const MessagesListLoadingStatus = (props: IProps) => {
  const classes = ConversationListLoaderStyles();

  if (props.conversationState.messagesState.isLoading) {
    return <div className={classes.noMoreItemsContainer}>
      {i18n.translate("CONVERSATION_MESSAGES_LOADING_MORE")}
    </div>;
  }

  if (props.conversationState.messagesState.hasMorePages) {
    return <div className={classes.noMoreItemsContainer}>&nbsp;</div>
  }

  return <div className={classes.noMoreItemsContainer}>
    {i18n.translate("CONVERSATION_MESSAGES_NO_MORE")}
  </div>;
};

export default MessagesListLoadingStatus;
