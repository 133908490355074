import React, { useEffect } from "react";
import { AuthServiceInstance } from "../../authentication/authService";
import { StorageInstance } from "../../utils/localStorage";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { resetApplicationState } from "../../state/actions/Application-Actions";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const processLogout = async () => {
      dispatch(
        resetApplicationState(() => {
          window.setTimeout(async () => {
            StorageInstance.clearAllData();
            await AuthServiceInstance.logout();
          }, 1000);
        })
      );
    };

    processLogout();
  });

  return (
    <>
      <span className="no-display">Redirecting to logout</span>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <LinearProgress />
        </Grid>
      </Grid>
    </>
  );
};

export default Logout;
