export interface IVisitReportContent {
  visitId: number;
  htmlContent?: string;
  reviewContent?: IReviewContent;
  contentType: VisitReportContentType;
}

export enum VisitReportContentType {
  unknown = "unknown",
  html = "html",
  reviewContent = "reviewContent",
}

export interface IReviewContent {
  score?: number;
  date?: Date;
  location?: string;
  client?: string;
  brand?: string;
  area?: string;
  period?: string;
  reviewId?: string;
  reviewText?: string;
  reviewSubject?: string;
  reviewSource?: string;
  author?: string;
  response: IReviewResponse[];
  pendingResponse: IReviewResponsePending[];
}

export interface IReviewResponse {
  responseText?: string;
  responseDate?: Date;
  responseTime?: number;
}

export interface IReviewResponsePending {
  responseText?: string;
  createdDate?: Date;
  managerId: number;
}

export enum ReviewResponseTemplateType {
  Introduction = "Introduction",
  Signature = "Signature",
  Full = "Full",
}

export interface IReviewResponseTemplate {
  name: string;
  template: string;
  applicableScoreMin: number;
  applicableScoreMax: number;
  templateType: ReviewResponseTemplateType;
  useWithEmptyComments: boolean;
  useWithPopulatedComments: boolean;
}
