export enum ClientName {
  Wagamama = "Wagamama",
  Cote = "Cote",
  PizzaExpress = "PizzaExpress",
  Default = "Default",
}

export const clientSelector = (clientId: number) => {
  switch (clientId) {
    case 209:
      return ClientName.Wagamama;
    case 1293:
      return ClientName.Wagamama;
    case 167969:
      return ClientName.Cote;
    case 171667:
      return ClientName.PizzaExpress;

    default:
      return ClientName.Default;
  }
};
