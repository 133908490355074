import {
  ITaskCentreCases,
  ICaseCategory,
  ICaseDetails,
  IManager,
} from "../types/TaskCentreCases";
import {
  TaskCentreListActions,
  TASKCENTRE_CASE_LIST_PENDING,
  TASKCENTRE_CASE_LIST_SUCCESS,
  TASKCENTRE_CASE_LIST_ERROR,
  MERGE_TASKCENTRE_CASE_LIST,
  SET_SELECTED_TASKCENTRE_CASE,
  UPDATE_TASKCENTRE_CATEGORIES,
  UPDATE_TASKCENTRE_MANAGERS,
  REMOVE_ITEM_FROM_TASKCENTRE_CASE_LIST,
  CLEAR_TASKCENTRE_CASE_LIST,
  FILTER_TASKCENTRE_CASE_LIST,
  SET_TASKCENTRE_CASE_COUNT,
} from "../actions/TaskCentre-CaseList-Actions";
import { IGetCasesQuery } from "../types/TaskCentreCaseQuery";

export interface ITaskCentreCaseListState {
  taskCentreCases: ITaskCentreCases;
  categories: ICaseCategory[];
  managers: IManager[];
  searchText: string;
  pending: boolean;
  error: string;
  query: IGetCasesQuery | undefined;
  totalCount: number | undefined;
}

const INITIAL_STATE: ITaskCentreCaseListState = {
  taskCentreCases: {
    cases: [],
    selectedCaseId: undefined,
    lastRefreshed: undefined,
    caseListItemCount: 0,
    caseListPageCount: 0,
    moreCasesAvailable: true,
  },
  categories: [],
  managers: [],
  searchText: "",
  pending: false,
  error: "",
  query: undefined,
  totalCount: undefined,
};

export default function TaskCentreCaseListReducer(
  state: ITaskCentreCaseListState = INITIAL_STATE,
  action: TaskCentreListActions
): ITaskCentreCaseListState {
  const batchValue = process.env.REACT_APP_TASK_CENTRE_LIST_BATCH_SIZE;
  const batchSize = Number.parseInt(batchValue ? batchValue : "100");

  switch (action.type) {
    case TASKCENTRE_CASE_LIST_PENDING:
      return {
        ...state,
        pending: action.silent ? state.pending : true,
        error: "",
        query: action.query !== undefined ? action.query : state.query,
      };
    case TASKCENTRE_CASE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
        taskCentreCases: action.taskCentreCases,
      };
    case TASKCENTRE_CASE_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case CLEAR_TASKCENTRE_CASE_LIST:
      return {
        ...state,
        taskCentreCases: getEmptyCases(state.taskCentreCases),
        error: "",
      };
    case SET_TASKCENTRE_CASE_COUNT:
      return {
        ...state,
        totalCount: action.count,
      };
    case MERGE_TASKCENTRE_CASE_LIST:
      return {
        ...state,
        taskCentreCases: mergeTaskCentreCaseLists(
          state.taskCentreCases,
          action.caseList,
          action.selectedCaseId,
          batchSize
        ),
        error: "",
        pending: false,
      };
    case REMOVE_ITEM_FROM_TASKCENTRE_CASE_LIST:
      return {
        ...state,
        taskCentreCases: deleteCase(state.taskCentreCases, action.caseId),
        error: "",
        pending: false,
      };
    case SET_SELECTED_TASKCENTRE_CASE:
      return {
        ...state,
        taskCentreCases: setSelectedCase(state.taskCentreCases, action.caseId),
      };
    case UPDATE_TASKCENTRE_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case UPDATE_TASKCENTRE_MANAGERS:
      return {
        ...state,
        managers: action.managers,
      };
    case FILTER_TASKCENTRE_CASE_LIST:
      return {
        ...state,
        searchText: action.searchText,
      };
    default:
      return state;
  }
}

const getEmptyCases = (state: ITaskCentreCases): ITaskCentreCases => {
  return {
    cases: [],
    selectedCaseId: state.selectedCaseId,
    lastRefreshed: new Date(),
    caseListPageCount: 0,
    caseListItemCount: 0,
    moreCasesAvailable: undefined,
  };
};

const setSelectedCase = (
  taskCentreCases: ITaskCentreCases,
  caseId: number
): ITaskCentreCases => {
  taskCentreCases.selectedCaseId = caseId;
  return taskCentreCases;
};

const mergeTaskCentreCaseLists = (
  taskCentreCases: ITaskCentreCases,
  newCases: ICaseDetails[],
  selectedCaseId: number | undefined,
  batchSize: number
): ITaskCentreCases => {
  const fullList = taskCentreCases.cases;

  newCases.forEach((c) => {
    const existingCaseIndex = fullList.findIndex((x) => x.id === c.id);

    existingCaseIndex === -1
      ? fullList.push(c)
      : (fullList[existingCaseIndex] = c);
  });

  const selectedCaseStillExists = fullList.find(
    (actionCase) => actionCase.id === selectedCaseId
  );

  if (!selectedCaseStillExists && fullList.length > 0) {
    selectedCaseId = fullList[0].id;
  }

  return {
    ...taskCentreCases,
    cases: fullList,
    selectedCaseId: selectedCaseId,
    caseListItemCount: fullList.length,
    caseListPageCount: taskCentreCases.caseListPageCount++,
    lastRefreshed: new Date(),
    moreCasesAvailable: newCases.length === batchSize,
  };
};

const deleteCase = (
  taskCentreCases: ITaskCentreCases,
  deletedCaseId: number
): ITaskCentreCases => {
  const deleteedCase = taskCentreCases.cases.find(
    (x) => x.id === deletedCaseId
  );

  if (deleteedCase) {
    taskCentreCases.cases = taskCentreCases.cases.filter(
      (x) => x.id !== deletedCaseId
    );

    taskCentreCases.caseListItemCount = taskCentreCases.cases.length;

    const nextTaskId = taskCentreCases.cases.find(
      (x) => x.vId === deleteedCase.vId
    );

    if (nextTaskId) {
      taskCentreCases.selectedCaseId = nextTaskId.id;
    } else {
      taskCentreCases.selectedCaseId = undefined;
    }
  }

  return taskCentreCases;
};
