import React from "react";
import {
  CaseEventType,
  ICaseEvent,
  ICaseEventAttachment,
  IManager,
} from "../../../state/types/TaskCentreCases";
import Comment from "./comment";
import MessageSent from "./messageSent";
import ContactConfirmed from "./contactConfirmed";
import EventHeader from "./eventHeader";
import i18n from "../../../localizations/i18n";
import MessageReceived from "./messageReceived";

interface IProps {
  visitId: number;
  caseId: number;
  caseEvent: ICaseEvent;
  caseEventAttachment: ICaseEventAttachment | undefined;
  currentUserId: number;
  managers: IManager[];
}

const CaseEvent = (props: IProps) => {
  const renderCaseEvent = () => {
    switch (props.caseEvent.type) {
      case CaseEventType.AppealRaisedEvent:
      case CaseEventType.AppealReviewerAssignedEvent:
      case CaseEventType.AppealCancelledEvent:
      case CaseEventType.AppealApprovedForReviewEvent:
      case CaseEventType.AppealQuestionAddedEvent:
      case CaseEventType.AppealGrantedEvent:
      case CaseEventType.AppealRejectedEvent:
      case CaseEventType.AppealUnderReviewEvent:
      case CaseEventType.CommentAddedEvent:
      case CaseEventType.AppealReviewDecisionChangedEvent:
        return (
          <Comment
            {...props}
            currentUser={props.currentUserId}
            eventHeader={
              <EventHeader
                authorId={props.caseEvent.authorId}
                managers={props.managers}
                timestamp={props.caseEvent.timestamp}
                isInternal={props.caseEvent.isInternal ?? false}
                alternativeAuthorName={i18n.translate("GLOBAL_HGEM")}
              />
            }
          />
        );
      case CaseEventType.MessageReceivedEvent:
        return (
          <MessageReceived
            {...props}
            eventHeader={
              <EventHeader
                managers={[]}
                alternativeAuthorName={props.caseEvent.sender}
                timestamp={props.caseEvent.timestamp}
                description={i18n.translate(
                  "TASK_CENTRE_Message_Received_From_Guest"
                )}
                isInternal={props.caseEvent.isInternal ?? false}
              />
            }
          />
        );
      case CaseEventType.MessageSentEvent:
        return (
          <MessageSent
            {...props}
            eventHeader={
              <EventHeader
                authorId={props.caseEvent.authorId}
                managers={props.managers}
                timestamp={props.caseEvent.timestamp}
                description={i18n.translate(
                  "TASK_CENTRE_Message_Sent_To_Guest"
                )}
                isInternal={props.caseEvent.isInternal ?? false}
              />
            }
          />
        );
      case CaseEventType.GuestContactConfirmedEvent:
        return (
          <ContactConfirmed
            caseEvent={props.caseEvent}
            eventHeader={
              <EventHeader
                authorId={props.caseEvent.authorId}
                managers={props.managers}
                timestamp={props.caseEvent.timestamp}
                description={i18n.translate("TASK_CENTRE_Contact_Confirmed")}
                isInternal={props.caseEvent.isInternal ?? false}
              />
            }
          />
        );
      default:
        return null;
    }
  };

  return renderCaseEvent();
};

export default CaseEvent;
