import { DesktopViewStyles, MobileViewStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ConversationStates,
  SelectedConversationId,
} from "../../state/selectors/Conversations-Selectors";
import { i18n } from "../../localizations";
import { Typography } from "@material-ui/core";
import { ConversationsViewStyles } from "../conversationsViews/styles";
import { ConversationList } from "../conversationsViews";
import { ConversationMessages } from "../conversationsViews/components/messages";
import ConversationContent from "../conversationsViews/components/conversationContent";
import { sendConversationMessage } from "../../state/actions/Conversations-Actions";

interface IProps {
  pageRef: string;
  isMobileView: boolean;
}

const ConversationsView = (props: IProps) => {
  const conversationStates = useSelector(ConversationStates);
  const selectedConversationId = useSelector(SelectedConversationId);

  const classes = props.isMobileView ? MobileViewStyles() : DesktopViewStyles();

  const conversationsViewStyles = ConversationsViewStyles();

  const dispatch = useDispatch();

  const contentHeight = props.isMobileView
    ? "calc(100vh - 50px)"
    : "calc(100vh - 220px)";

  const selectedConversationState = conversationStates.find(
    (x) => x.conversation.conversationId === selectedConversationId
  );

  return (
    <div className={classes.container}>
      <div className={classes.listOuterContainer}>
        <ConversationList
          listHeightOffset={250}
          pageRef={props.pageRef}
          showAsPopupList={props.isMobileView}
        />
      </div>
      <div
        className={`${classes.tabsContainer} ${conversationsViewStyles.conversationsContainer}`}
        style={{ height: contentHeight }}
      >
        {!selectedConversationState && (
          <Typography
            className={`${conversationsViewStyles.conversationsHeaderText}`}
          >
            {i18n.translate("CONVERSATIONS_SELECT")}
          </Typography>
        )}

        {selectedConversationId && selectedConversationState && (
          <ConversationContent
            context={selectedConversationState}
            isMobileView={props.isMobileView}
            messages={() => <ConversationMessages />}
            sendConversationMessage={(commentText, selectedFile) =>
              dispatch(sendConversationMessage(commentText, selectedFile))
            }
          />
        )}
      </div>
    </div>
  );
};

export default ConversationsView;
