import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  emptyVisitsMessage: {

    [theme.breakpoints.up("lg")]: {
      margin: "0 20px 10px 0",
    },

    [theme.breakpoints.down("md")]: {
      margin: "5px"
    }
  },
}));

export const VisitListEmptyStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
