import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { createNewTaskCentreCase } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { TaskCreatorStyles } from "../styles/taskCreatorStyles";
import { WorkflowStatus } from "../../../state/types/TaskCentreCases";

interface IProps {
  visitId: number;
  dialogOpen: boolean;
  onDialogClose: () => void;
}

const CreateTaskDialog = (props: IProps) => {
  const classes = TaskCreatorStyles();

  const dispatch = useDispatch();
  const [taskTitle, setTaskTitle] = useState("");

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.trim().length > 0) {
      setTaskTitle(value.trimStart());
    } else {
      setTaskTitle("");
    }
  };

  const onCreateTask = () => {
    if (props.visitId && taskTitle) {
      dispatch(
        createNewTaskCentreCase(
          props.visitId,
          taskTitle,
          WorkflowStatus.InProgress
        )
      );
    }

    props.onDialogClose();
  };

  return (
    <>
      <Dialog open={props.dialogOpen} onClose={props.onDialogClose}>
        <DialogContent>
          {i18n.translate("VISIT_DETAILS_TASK_CREATOR_HelpText")}
          <br />
          {i18n.translate("VISIT_DETAILS_TASK_CREATOR_HelpText_examples")}

          <div className={classes.titleInput}>
            <TextField
              fullWidth
              label={i18n.translate(
                "VISIT_DETAILS_TASK_CREATOR_TaskTitleLabel"
              )}
              size="small"
              variant="outlined"
              value={taskTitle}
              onChange={onTitleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateTask}
            disabled={taskTitle.length === 0}
          >
            {i18n.translate("VISIT_DETAILS_TASK_CREATOR_CreateTask")}
          </Button>
          <Button variant="text" onClick={props.onDialogClose}>
            {i18n.translate("VISIT_DETAILS_TASK_CREATOR_Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateTaskDialog;
