import React from "react";
import { Typography } from "@material-ui/core";
import i18n from "../../../localizations/i18n";

const ReportError = () => {
  return (
    <div>
      <Typography variant="h4" paragraph>
        {i18n.translate("VISIT_DETAILS_REPORT_ERROR_Title")}
      </Typography>
      <Typography variant="body1" paragraph>
        {i18n.translate("VISIT_DETAILS_REPORT_ERROR_Message")}
      </Typography>
    </div>
  );
};

export default ReportError;
