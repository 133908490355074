import { useEffect, useState } from "react";
import i18n from "../../../localizations/i18n";
import { RecategoriseCaseCommand } from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { executeCaseCommand } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { ICaseCategory } from "../../../state/types/TaskCentreCases";
import CategoryIcon from "../../_common/Icons/categoryIcon";
import { CategoryFieldStyles } from "../styles/inputs/categoryFieldStyles";
import CommonDropdownField from "../../_common/dropdown/commonDropdownField";
import { EditCaseItemStyles } from "../styles/editCaseItemStyles";

interface IProps {
  caseId: number;
  category?: ICaseCategory;
  availableCategories: ICaseCategory[];
}

const CategoryField = (props: IProps) => {
  const classes = CategoryFieldStyles();
  const inputClasses = EditCaseItemStyles();

  const dispatch = useDispatch();

  const [caseId, setCaseId] = useState(props.caseId);

  useEffect(() => {
    if (caseId !== props.caseId) {
      setCaseId(props.caseId);
    }
  }, [caseId, props.caseId]);

  return (
    <CommonDropdownField
      availableValues={props.availableCategories}
      onChange={(value) => {
        const categoryCommand = new RecategoriseCaseCommand(
          props.caseId,
          value.id
        );
        dispatch(executeCaseCommand(props.caseId, [categoryCommand]));
      }}
      value={props.category}
      fieldClassName={`${classes.categoryField} ${inputClasses.editCaseItemInput}`}
      fieldDefaultText={i18n.translate("TASK_CENTRE_CATEGORY_Empty")}
      notificationIdentifier={`${props.caseId}|${RecategoriseCaseCommand.type}`}
      before={<CategoryIcon />}
      displayEmpty={props.category ? true : false}
      placeholder={i18n.translate("TASK_CENTRE_CATEGORY_Empty")}
    />
  );
};

export default CategoryField;
