import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialog: {
    "& div[role=dialog]": {
      maxWidth: "calc(100vw - 100px)!important",
      minHeight: "200px",
      minWidth: "200px",
      width: "auto",
      height: "auto",

      [theme.breakpoints.down("md")]: {
        maxWidth: "100vw!important",
        margin: "5px",
      },
    },
  },
  imagePending: {
    color: theme.palette.grey[500],
    position: "relative",
    top: "50%",
    left: "50%",
    margin: "-15px 0 0 -15px",
    width: "30px",
    height: "30px",
  },
  pendingContainer: {
    width: "200px",
    height: "200px",
  },
  imageOptions: {
    width: "100%",
    background: hgemColours.MediumGrey,
    float: "right",

    "& button": {
      color: hgemColours.White,
      float: "right",
    },
  },
  overlayText: {
    display: "block",
    wordWrap: "break-word",
    whiteSpace: "normal"
  }
}));

export const VisitPhotoModalStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
