import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  listContainer: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "scroll",
    overflowX: "clip",

    paddingBottom: "2%",
  },
  popupSelection: {
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    border: `1px solid ${hgemColours.LimeGreen}`,
    borderRadius: "5px",
  },
  popupSelectionHint: {
    fontWeight: 300,
  },
  popupSelectionDialog: {
    "& .MuiDialogContent-root": {
      padding: "10px",
    },
  },
}));

export const ConversationListStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
