import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import { applyFilterAvailabilityRules } from "../../_common/filters/utils/hubFilterAvailability";
import { IFilterSetsState } from "../../../state/reducers/FilterSets-Reducer";

export const TaskCentreCaseListPending = (state: RootState) =>
  state.TaskCentreCaseListReducer.pending;

export const TaskCentreCaseListError = (state: RootState) =>
  state.TaskCentreCaseListReducer.error;

export const AvailableCases = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return getFilteredCases(state);
  }
);

export const SelectedCaseId = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.selectedCaseId;
  }
);

export const TotalCaseCount = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.caseListItemCount;
  }
);

export const CasesLastRefreshed = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.lastRefreshed;
  }
);

export const GetCurrentFilterSet = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (state: IFilterSetsState) => {
    return state.currentFilterSet;
  }
);

export const GetVisibleFilters = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (_: RootState, pageRef: string) => pageRef,
  (state, pageRef) => {
    const filters = state.currentFilters;
    const filterAvailability = state.filterSetConfiguration.filterAvailability;

    return applyFilterAvailabilityRules(filters, filterAvailability, pageRef);
  }
);

export const MoreCasesAvailable = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.taskCentreCases.moreCasesAvailable;
  }
);

export const AllManagers = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.managers;
  }
);

export const CurrentSearchText = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.searchText;
  }
);

export const GetUserCurrentBrands = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.currentBrands
    : [];
};

const getFilteredCases = (state: ITaskCentreCaseListState) => {
  const searchText = state.searchText.trim();
  const cases = state.taskCentreCases.cases;
  return cases.filter((x) => {
    const matchesTitle = valueContains(x.title, searchText);
    const matchesEvents = x.events.some((x) => {
      return (
        valueContains(x.comment, searchText) ||
        valueContains(x.message, searchText) ||
        valueContains(x.recipient, searchText) ||
        valueContains(x.sender, searchText)
      );
    });

    return matchesTitle || matchesEvents;
  });
};

const valueContains = (
  value: string | undefined,
  searchValue: string
): boolean => {
  if (searchValue.trim().length === 0) {
    return true;
  }

  return value
    ? value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    : false;
};
