import axios, { AxiosResponse } from "axios";
import { VoucherResponse } from "../types";

type Props = {
  visitId: number | undefined;
  reason: string;
  voucherValue: number;
  userId: number | undefined;
  productId: number;
  optionId: number;
};
export const postNewVoucher = (
  voucherDetails: Props
): Promise<AxiosResponse<VoucherResponse>> => {
  const { visitId, reason, voucherValue, userId, productId, optionId } =
    voucherDetails;
  return axios.post(
    `${process.env.REACT_APP_VOUCHER_API_BASEURL}Voucher/ForVisit?VisitId=${visitId}&Reason=${reason}&VoucherValue=${voucherValue}&UserId=${userId}&ToggleProductId=${productId}&ToggleProductOptionId=${optionId}`
  );
};

export const voucherIsActive = (
  clientId: number
): Promise<AxiosResponse<VoucherResponse[]>> => {
  return axios.get(
    `${process.env.REACT_APP_VOUCHER_API_BASEURL}Voucher/ToggleOptions?clientId=${clientId}`
  );
};
