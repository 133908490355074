import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  noteContainer: {
    marginBottom: "3%",
    minWidth: "25%",
    textAlign: "left",
  },
  note: {
    display: "flex",
    padding: "2%",
    borderRadius: "0.7rem",
    border: `1px solid ${lighten(hgemColours.LimeGreen, 0.6)}`,
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    marginTop: "2%",
    whiteSpace: "pre-wrap",
    fontSize: "0.9em",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0.4em",
    },
  },
}));

export const GuestContactNoteStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
