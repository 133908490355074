import { Icon } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getVideoCover } from "@rajesh896/video-thumbnails-generator";
import Resizer from "react-image-file-resizer";
import MediaMessageVideoModal from "./mediaMessageVideoModel";
import { i18n } from "../../../../localizations";
import { MediaLinks } from "./types/mediaLinks";
import { VisitMediaStyles } from "../../../inboxMedia/styles/visitMediaStyles";

type Props = {
  mediaLinks: MediaLinks | undefined;
  contentType: string;
  onDownload: () => void;
};

const MediaMessageVideo = (props: Props) => {
  const classes = VisitMediaStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnailBase64, setThumbnailBase64] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (props.mediaLinks) {
      getVideoCover(props.mediaLinks.localUrl).then(
        async (videoCoverBase64) => {
          const videoCoverResponse = await fetch(videoCoverBase64);

          const blob = await videoCoverResponse.blob();

          Resizer.imageFileResizer(
            blob,
            200,
            200,
            "JPEG",
            100,
            0,
            (base64) => {
              if (typeof base64 === "string") setThumbnailBase64(base64);
            },
            "base64"
          );
        }
      );
    }
  }, [props.mediaLinks, setThumbnailBase64]);

  const showImage = () => {
    setModalOpen(true);
  };

  const closeImage = () => {
    setTimeout(() => {
      setModalOpen(false);
    }, 100);
  };

  return (
    <div className={classes.MessageImageContainer}>
      {thumbnailBase64 && (
        <>
          <img
            role="presentation"
            onClick={showImage}
            className={`${classes.thumbnailImage} ${classes.smallMargin}`}
            src={thumbnailBase64}
            alt={i18n.translate("MEDIA_MESSAGE_VIDEO_THUMBNAIL_Alt_Text")}
            title={i18n.translate("MEDIA_MESSAGE_VIDEO_THUMBNAIL_Enlarge")}
          />
          <Icon className={`fas fa-play-circle ${classes.videoIconOverlay}`} />
        </>
      )}
      {!thumbnailBase64 && (
        <Icon
          className={`fas fa-video ${classes.imagePending} ${classes.mediaLoadingOverlay}`}
        />
      )}

      {modalOpen && (
        <MediaMessageVideoModal
          mediaLinks={props.mediaLinks}
          contentType={props.contentType}
          onClose={closeImage}
          onDownload={props.onDownload}
        />
      )}
    </div>
  );
};

export default MediaMessageVideo;
