export interface IUserProfile {
  navigationOptions: Array<INavigationOption>;
  Id: number;
  UserName: string;
  currentClient: number;
  currentBrands: Array<string>;
  availableClients: Array<IdAndName>;
  availableBrands: Array<BrandIdAndName>;
  userCanSwitchClients: boolean;
  userCanSwitchBrands: boolean;
}

export interface INavigationOption {
  id: number;
  name: string;
  path: string;
  icon?: string;
  parent?: string | undefined;
  navigationType: NavigationType;
  children?: INavigationOption[];
  numberOfPages: number;
  navigationName: NavigationNames;
}

export interface NavigationNames {
  [key: string]: string
}

export enum NavigationType {
  Primary = "Primary",
  UserContext = "UserContext",
}

export interface BrandAccessList {
  brandAccessList: BrandAccess[];
}

export interface BrandAccess {
  id?: number;
  name: string;
  userHasAccess: boolean;
}

export interface IdAndName {
  id: number;
  name: string;
}

export interface BrandIdAndName {
  id?: number;
  name: string;
}

export interface IAppSourceVersion {
  appVersion: string;
  navigationLastUpdated: Date | undefined;
}
