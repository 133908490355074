import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  priorityField: {
    width: "100%",
  },
  priorityLabel: {
    fontSize: "75%",
    color: hgemColours.DarkGrey,
  },
}));

export const PriorityFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
