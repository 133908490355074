import { Icon } from "@material-ui/core";
import { useEffect, useState } from "react";
import MediaMessageImageModal from "./mediaMessageImageModel";
import Resizer from "react-image-file-resizer";
import { i18n } from "../../../../localizations";
import { MediaLinks } from "./types/mediaLinks";
import { VisitMediaStyles } from "../../../inboxMedia/styles/visitMediaStyles";

type Props = {
  mediaLinks: MediaLinks | undefined;
  onDownload: () => void;
};

const MediaMessageImage = (props: Props) => {
  const classes = VisitMediaStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnailBase64, setThumbnailBase64] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (props.mediaLinks) {
      Resizer.imageFileResizer(
        props.mediaLinks.blob,
        200,
        200,
        "JPEG",
        100,
        0,
        (base64) => {
          if (typeof base64 === "string") setThumbnailBase64(base64);
        },
        "base64"
      );
    }
  }, [props.mediaLinks, setThumbnailBase64]);

  const showImage = () => {
    setModalOpen(true);
  };

  const closeImage = () => {
    setTimeout(() => {
      setModalOpen(false);
    }, 100);
  };

  return (
    <div className={`${classes.MessageImageContainer}`}>
      {thumbnailBase64 && (
        <img
          role="presentation"
          onClick={showImage}
          className={`${classes.thumbnailImage} ${classes.smallMargin}`}
          src={thumbnailBase64}
          alt={i18n.translate("MEDIA_MESSAGE_IMAGE_THUMBNAIL_Alt_Text")}
          title={i18n.translate("MEDIA_MESSAGE_IMAGE_THUMBNAIL_Enlarge")}
        />
      )}
      {!thumbnailBase64 && (
        <Icon
          className={`fas fa-camera ${classes.imagePending} ${classes.mediaLoadingOverlay}`}
        />
      )}
      {modalOpen && (
        <MediaMessageImageModal
          mediaLinks={props.mediaLinks}
          onClose={closeImage}
          onDownload={props.onDownload}
        />
      )}
    </div>
  );
};

export default MediaMessageImage;
