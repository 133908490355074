import React from "react";
import {
  useTheme,
  makeStyles,
  Theme,
  Button,
  LinearProgress,
} from "@material-ui/core";

interface IProps {
  label: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  filterWrapper: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "5px",

    "& button": {
      padding: "3px 6px",
    },
  },
  loading: {
    fontWeight: 300,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: "10px",
    paddingLeft: "10px",
    width: "80px",
    height: "25px",
  },
  progress: {
    marginTop: "10px",
    backgroundColor: theme.palette.grey[300],

    "&& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

const DisabledFilter = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.filterWrapper}>
      <Button>
        {props.label}
        <div className={classes.loading}>
          <LinearProgress className={classes.progress} />
        </div>
      </Button>
    </div>
  );
};

export default DisabledFilter;
