import { useTheme, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  categoryField: {
    width: "100%",
  },
  categoryLabel: {
    fontSize: "75%",
    color: theme.palette.grey[400],
  },
}));

export const CommonDropdownFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
