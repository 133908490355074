import {
  IGetCasesQuery,
  SortBy,
  SortOrder,
} from "../../../state/types/TaskCentreCaseQuery";
import { ContactStatusType } from "../../../state/types/FilterSets";

const BuildVisitCaseListQuery = (visitId: number): IGetCasesQuery => {
  const query: IGetCasesQuery = {
    visitId: visitId,
    categories: [],
    priorities: [],
    createdBetween: null,
    visitPeriods: [],
    periodHierarchies: [],
    pagination: { itemsPerPage: 100, pageIndex: 0 },
    sort: { order: SortOrder.Descending, property: SortBy.CreatedDate },
    workflowStatuses: [],
    locationIds: [],
    brands: [],
    visitTypes: [],
    visitSources: [],
    feedbackCategories: [],
    feedbackSubCategories: [],
    daysOfWeek: [],
    questionnaireTypes: [],
    assignees: [],
    contactStatus: ContactStatusType.ShowAll,
    visitScoreRange: undefined,
    npsScores: [],
    // dishes: [],
  };

  return query;
};

export default BuildVisitCaseListQuery;
