import {
  FILTERSETS_PENDING,
  FILTERSETS_SUCCESS,
  FILTERSETS_ERROR,
  FilterSetActions,
  UPDATE_CURRENT_FILTERS_ENTRY,
  SET_CURRENT_FILTER_SET_TO_DEFAULT,
  RESET_FILTER_SETS,
  CHANGE_FILTER_SET_FILTER_VISIBILITY,
  IChangeFilterSetFilterVisibility,
  IUpdateCurrentFiltersEntry,
} from "../actions/FilterSets-Actions";
import {
  IFilterSetConfiguration,
  IFilterSet,
  IHubFilter,
  FilterVisibilityBehaviour,
} from "../types/FilterSets";
import {
  initialiseFilterValueFromDefault,
  resetFiltersFromFilterSet,
} from "../../utils/hubFilterInitialiser";
import {
  ApplicationActions,
  RESET_PERSISTED_STATE_PENDING_FLAG,
} from "../actions/Application-Actions";

export interface IFilterSetsState {
  filterSetConfiguration: IFilterSetConfiguration;
  currentFilterSet: IFilterSet | undefined;
  currentFilters: IHubFilter[];
  pending: boolean;
  error: string;
}

const INITIAL_STATE: IFilterSetsState = {
  filterSetConfiguration: {
    filterSets: [],
    filterAvailability: [],
    lastUpdated: undefined,
  } as IFilterSetConfiguration,
  currentFilterSet: undefined,
  currentFilters: [],
  pending: false,
  error: "",
};

export default function FilterSetsReducer(
  state: IFilterSetsState = INITIAL_STATE,
  action: FilterSetActions | ApplicationActions
): IFilterSetsState {
  switch (action.type) {
    case FILTERSETS_PENDING:
      return {
        ...state,
        pending: true,
        error: "",
      };
    case FILTERSETS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
        filterSetConfiguration: action.filterSetConfiguration,
      };
    case FILTERSETS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case UPDATE_CURRENT_FILTERS_ENTRY:
      return applyFilterUpdates(state, action);
    case SET_CURRENT_FILTER_SET_TO_DEFAULT:
      return setDefaultFilterSet(state);
    case CHANGE_FILTER_SET_FILTER_VISIBILITY:
      return changeFilterVisibility(state, action);
    case RESET_FILTER_SETS:
      return INITIAL_STATE;
    case RESET_PERSISTED_STATE_PENDING_FLAG:
      return {
        ...state,
        pending: false,
        error: "",
      };
    default:
      return state;
  }
}

const applyFilterUpdates = (
  state: IFilterSetsState,
  action: IUpdateCurrentFiltersEntry
): IFilterSetsState => {
  const newFilters = state.currentFilters.map((x: IHubFilter) => {
    if (x.type !== action.filterType) {
      return x;
    } else {
      action.applyUpdate(x);
      return x;
    }
  });

  return {
    ...state,
    currentFilters: newFilters,
    pending: false,
    error: "",
  };
};

const setDefaultFilterSet = (state: IFilterSetsState): IFilterSetsState => {
  // Here is where we would pick the users preferred 'default' when user defined sets are available
  // For now - always set to 'System Default' => TODO
  const currentFilterSet = state.filterSetConfiguration.filterSets.find(
    (x) => x.isSystemDefault
  );
  let currentFilters: IHubFilter[] = [];
  if (currentFilterSet) {
    currentFilters = resetFiltersFromFilterSet(currentFilterSet);
  }

  return {
    ...state,
    currentFilterSet: currentFilterSet,
    currentFilters: currentFilters,
    pending: false,
    error: "",
  };
};

const changeFilterVisibility = (
  state: IFilterSetsState,
  action: IChangeFilterSetFilterVisibility
): IFilterSetsState => {
  const currentFilterSet = state.filterSetConfiguration.filterSets.find(
    (x) => x.name === action.filterSet
  );
  let updatedFilters = state.currentFilters;

  if (currentFilterSet) {
    updatedFilters = updatedFilters.map((f) => {
      const newVisibilityState =
        action.visibleFilters.indexOf(f.type) > -1
          ? FilterVisibilityBehaviour.Visible
          : FilterVisibilityBehaviour.Hidden;

      if (f.userSetVisibility !== newVisibilityState && !f.alwaysVisible) {
        f = initialiseFilterValueFromDefault(f.type);
      }

      f.userSetVisibility = newVisibilityState;

      return f;
    });

    currentFilterSet.filters = updatedFilters;
  }

  return {
    ...state,
    currentFilterSet: currentFilterSet,
    currentFilters: updatedFilters,
  };
};
