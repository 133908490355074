import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  spacer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

export const PageContainerStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
