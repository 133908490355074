import { Icon } from "@material-ui/core";
import { useEffect, useState } from "react";
import i18n from "../../../localizations/i18n";
import { getReceiptData, UnavailableStatus } from "../state/api";
import VisitReceiptModal from "./visitReceiptModal";
import { VisitMediaStyles } from "../styles/visitMediaStyles";

interface IProps {
  visitId: number;
  fileName: string;
  receiptIndex: number;
}

const VisitReceipt = (props: IProps) => {
  const classes = VisitMediaStyles();

  const [receiptData, setReceiptData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const showReceipt = () => {
    setModalOpen(true);
  };

  const closeReceipt = () => {
    setTimeout(() => {
      setModalOpen(false);
    }, 100);
  };

  useEffect(() => {
    const fetchReceipt = async () => {
      const receipt = await getReceiptData(props.visitId, props.fileName);
      setReceiptData(receipt);
    };

    if (receiptData.length === 0) {
      fetchReceipt();
    }
  }, [props.fileName, props.visitId, receiptData.length]);

  return (
    <div className={classes.container}>
      {receiptData !== UnavailableStatus && receiptData.length > 0 && (
        <img
          onClick={showReceipt}
          className={classes.thumbnailImage}
          src={receiptData}
          alt={i18n.translate("VISIT_DETAILS_MEDIA_Photo_Alt_Text")}
          title={i18n.translate("VISIT_DETAILS_MEDIA_Photo_Enlarge")}
        />
      )}
      {(receiptData === UnavailableStatus || receiptData.length === 0) && (
        <Icon className={`fas fa-receipt ${classes.imagePending}`} />
      )}

      {modalOpen && (
        <VisitReceiptModal
          visitId={props.visitId}
          receiptIndex={props.receiptIndex}
          receiptImageData={receiptData}
          onClose={closeReceipt}
        />
      )}
    </div>
  );
};

export default VisitReceipt;
