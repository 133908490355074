import { useDispatch, useSelector } from "react-redux";
import {
  fetchInitialConversationMessagesList,
  fetchLoadMoreConversationMessagesList,
} from "../../../../state/actions/Conversations-Actions";
import {
  ConversationStates,
  SelectedConversationId,
} from "../../../../state/selectors/Conversations-Selectors";
import ConversationMessagesCore from "./conversationMessagesCore";

const ConversationMessages = () => {
  const conversationStates = useSelector(ConversationStates);
  const selectedConversationId = useSelector(SelectedConversationId);

  const selectedConversationState = conversationStates.find(
    (x) => x.conversation.conversationId === selectedConversationId
  );

  const dispatch = useDispatch();

  return (
    <ConversationMessagesCore
      context={selectedConversationState}
      fetchInitialMessagesList={() =>
        dispatch(fetchInitialConversationMessagesList())
      }
      fetchLoadMoreMessagesList={(scrollDistanceFromBottom) =>
        dispatch(
          fetchLoadMoreConversationMessagesList(scrollDistanceFromBottom)
        )
      }
    />
  );
};

export default ConversationMessages;
