import { useTheme, makeStyles, Theme } from "@material-ui/core";
import { hgemColours } from "../../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  assigneeField: {
    backgroundColor: hgemColours.White,
    borderRadius: "4px",
    paddingLft: "1.5%",

    width: "100%",

    "& label": {
      color: hgemColours.DarkGrey,
    },
  },
  assigneeIcon: {
    color: theme.palette.grey[700],
  },
}));

export const AssigneeFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
