import React, { useState } from "react";
import { getTaskEventAttachmentApi } from "../../../state/api/TaskCentre-API";
import { useClientId } from "../../../state/hooks/clients/useClientId";
import {
  ICaseEvent,
  ICaseEventAttachment,
} from "../../../state/types/TaskCentreCases";
import {
  MediaMessageContent,
  MediaMessageSideContent,
  useMedia,
} from "../../inboxViews/components/mediaMessages";
import { MediaLinks } from "../../inboxViews/components/mediaMessages/types/mediaLinks";
import { MessageReceivedStyles } from "../styles/messageReceivedStyles";

interface IProps {
  visitId: number;
  caseId: number;
  caseEvent: ICaseEvent;
  caseEventAttachment: ICaseEventAttachment | undefined;
  eventHeader: React.ReactElement;
}

const MessageReceived = (props: IProps) => {
  return props.caseEventAttachment ? (
    <MessageReceivedAttachment
      visitId={props.visitId}
      caseId={props.caseId}
      caseEventEventAttachmentId={props.caseEventAttachment.id}
      filepath={props.caseEventAttachment.filepath}
      subject={props.caseEvent.subject}
      eventHeader={props.eventHeader}
    />
  ) : (
    <MessageReceivedMessage
      subject={props.caseEvent.subject}
      message={props.caseEvent.message}
      eventHeader={props.eventHeader}
    />
  );
};

type MessageReceivedMessageProps = {
  subject: string | undefined;
  message: string | undefined;
  eventHeader: React.ReactElement;
};

const MessageReceivedMessage = (props: MessageReceivedMessageProps) => {
  const classes = MessageReceivedStyles();

  return (
    <>
      {!!props.message?.trim() && (
        <div className={classes.messageSentContainer}>
          {props.eventHeader}
          <div>
            <span className={classes.message}>
              <span
                className={`icon far fa-envelope-open ${classes.messageIcon}`}
              ></span>
              <div className={classes.messageContent}>
                <div className={classes.messageSubject}>{props.subject}</div>
                <div className={classes.messageText}>{props.message}</div>
              </div>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

type MessageReceivedAttachmentProps = {
  visitId: number;
  caseId: number;
  caseEventEventAttachmentId: number;
  filepath: string;
  subject: string | undefined;
  eventHeader: React.ReactElement;
};

const MessageReceivedAttachment = (props: MessageReceivedAttachmentProps) => {
  const classes = MessageReceivedStyles();

  const clientId = useClientId();

  const [mediaLinks, setMediaLinks] = useState<MediaLinks | undefined>();
  const [isDownloading, setIsDownloading] = useState(false);

  const media = useMedia({
    filename: props.filepath,
    getMedia: clientId
      ? () =>
          getTaskEventAttachmentApi(
            clientId,
            props.visitId,
            props.caseId,
            props.caseEventEventAttachmentId,
            props.filepath
          )
      : undefined,
    mediaLinks: [mediaLinks, setMediaLinks],
    isDownloading: [isDownloading, setIsDownloading],
  });

  return (
    <div className={classes.messageSentMediaContainer}>
      <div>
        {props.eventHeader}
        <div className={classes.message}>
          <span
            className={`icon far fa-envelope-open ${classes.messageIcon}`}
          ></span>
          <div>
            <div className={classes.messageSubject}>{props.subject}</div>
            <div className={classes.messageMedia}>
              <MediaMessageContent
                shouldRenderMedia={media.shouldRenderMedia}
                filename={props.filepath}
                contentType={media.contentType}
                download={() => setIsDownloading(true)}
                mediaLinks={media.links}
              />
            </div>
          </div>
        </div>
      </div>
      <span className={classes.inBound}>
        <MediaMessageSideContent
          isDownloading={isDownloading}
          download={() => setIsDownloading(true)}
        />
      </span>
    </div>
  );
};

export default MessageReceived;
