import { TextField } from "@material-ui/core";
import { IManager } from "../../../state/types/TaskCentreCases";
import { SetStateAction, Dispatch } from "react";
import { AssigneeIcon } from "../../_common";
import { Autocomplete } from "@material-ui/lab";
import { i18n } from "../../../localizations";
import { AppealsStyles } from "../styles";
import { AssigneeFieldStyles } from "../../taskCentreEditor/styles/inputs/assigneeFieldStyles";
interface Props {
  selectedManager?: IManager;
  managers: IManager[];
  selectChange: Dispatch<SetStateAction<IManager | undefined>>;
  isInvalid: boolean;
  validate: () => void;
}
const ApproverSelect = (props: Props) => {
  const appealsClasses = AppealsStyles();
  const classes = AssigneeFieldStyles();
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: IManager | null
  ) => {
    props.validate();
    if (value) {
      const selection = props.managers.find(
        (manager) => value.id === manager.id
      );
      props.selectChange(selection);
    } else {
      props.selectChange(undefined);
    }
  };
  return (
    <>
      <div
        className={`${appealsClasses.flexRow} ${appealsClasses.flexRowWithIcon}`}
      >
        <span className={classes.assigneeIcon}>
          <AssigneeIcon />
        </span>
        <Autocomplete
          data-testid={"approver-select"}
          className={classes.assigneeField}
          onChange={handleChange}
          options={props.managers}
          getOptionLabel={(option: IManager) => option.name}
          value={props.selectedManager ?? null}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={i18n.translate("TASK_CENTRE_ASSIGNEE_Unasigned")}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          )}
        ></Autocomplete>
      </div>
      {props.isInvalid && (
        <span className={`${appealsClasses.errorSpan}`}>
          {i18n.translate("APPEALS_VALIDATION_APPROVER")}
        </span>
      )}
    </>
  );
};

export default ApproverSelect;
