import {
  AppealsActions,
  APPEALS_FEATURE_ACTIVE_SUCCESS,
} from "../actions/Appeals-Actions";
import { IAppealsFeatureActive } from "../types/Appeals";

export interface IAppealsState {
  appealsFeatureActive: IAppealsFeatureActive;
  pending: boolean;
  error: string;
}

const INITIAL_STATE: IAppealsState = {
  appealsFeatureActive: { isActive: false },
  pending: false,
  error: "",
};

export default function AppealsReducer(
  state: IAppealsState = INITIAL_STATE,
  action: AppealsActions
): IAppealsState {
  switch (action.type) {
    case APPEALS_FEATURE_ACTIVE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
        appealsFeatureActive: action.appealsFeatureActive,
      };
    default:
      return state;
  }
}
