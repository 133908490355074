import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
    messagesScrollWindow: {
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: "auto",
      overflow: "auto",
      height: "100%"
    },
    boundaryTop: {
      marginBottom: "0px"
    },
    boundaryBottom: {
      marginTop: "0px"
    }
  }));
  
export const ConversationsMessagesStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
  