import {
  CLEAR_CONVERSATION_LIST,
  ConversationActions,
  ConversationMessageAdded,
  ConversationMessageSending,
  ConversationMessagesListPending,
  ConversationMessagesListSuccess,
  ConversationMessagesListUpdateReason,
  ConversationsListPending,
  ConversationsListSuccess,
  CONVERSATIONS_LIST_PENDING,
  CONVERSATIONS_LIST_SUCCESS,
  ConversationVisitMessagesListPending,
  ConversationVisitMessagesListSuccess,
  CONVERSATION_MESSAGES_LIST_PENDING,
  CONVERSATION_MESSAGES_LIST_SUCCESS,
  CONVERSATION_MESSAGE_ADDED,
  CONVERSATION_MESSAGE_SENDING,
  CONVERSATION_VISIT_MESSAGES_LIST_PENDING,
  CONVERSATION_VISIT_MESSAGES_LIST_SUCCESS,
  SelectConversation,
  SELECT_CONVERSATION,
  SetConversationsFeatureActive,
  SetConversationsSourceConfigs,
  SetUnreadConversations,
  SET_CONVERSATIONS_FEATURE_ACTIVE,
  SET_CONVERSATIONS_SOURCE_CONFIGS,
  SET_UNREAD_CONVERSATIONS,
  UpdateConversationReadCursorMessageId,
  UPDATE_CONVERSATION_READ_CURSOR_MESSAGE_ID,
  VisitConversationContextSuccess,
  VISIT_CONVERSATION_CONTEXT_SUCCESS,
} from "../actions/Conversations-Actions";
import {
  Conversation,
  ConversationListFilters,
  ConversationMessage,
  ConversationSourceConfig,
  ConversationVisit,
  UnreadConversations,
} from "../types/Conversations";

export type ConversationMessagesState = {
  messages: ConversationMessage[];
  isLoading: boolean;
  nextPageToken: number | undefined;
  hasMorePages: boolean;
  initialListLoaded: boolean;
  updateReason: ConversationMessagesListUpdateReason | undefined;
  lastScrollDistanceFromBottom: number | undefined;
};

export interface IConversationState {
  conversation: Conversation;
  messagesState: ConversationMessagesState;
}

export interface ConversationVisitState {
  conversation: Conversation;
  visit: ConversationVisit;
  messagesState: ConversationMessagesState;
}

export interface IConversationCollectionState {
  selectedConversationId: number | undefined;
  filters: ConversationListFilters;
  conversationStates: IConversationState[];
  isLoading: boolean;
  nextPageToken: number | undefined;
  hasMorePages: boolean;
  initialListLoaded: boolean;
}

export interface IConversationsState {
  featureActive: boolean;
  sourceConfigs: ConversationSourceConfig[];
  conversations: IConversationCollectionState;
  conversationVisits: ConversationVisitState[];
  unreadConversations: UnreadConversations;
}

const INITIAL_STATE: IConversationsState = {
  featureActive: false,
  sourceConfigs: [],
  conversations: {
    selectedConversationId: undefined,
    filters: {
      conversationId: undefined,
    },
    conversationStates: [],
    isLoading: false,
    nextPageToken: undefined,
    hasMorePages: true,
    initialListLoaded: false,
  },
  conversationVisits: [],
  unreadConversations: {
    unreadCount: 0,
  },
};

export default function ConversationsReducer(
  state: IConversationsState = INITIAL_STATE,
  action: ConversationActions
): IConversationsState {
  switch (action.type) {
    case SET_CONVERSATIONS_FEATURE_ACTIVE:
      return processSetConversationsFeatureActive(state, action);
    case SET_CONVERSATIONS_SOURCE_CONFIGS:
      return processSetConversationsSourceConfigs(state, action);
    case CONVERSATION_MESSAGE_ADDED:
      return processConversationMessageAdded(state, action);
    case CONVERSATIONS_LIST_PENDING:
      return processConversationListPending(state, action);
    case CONVERSATIONS_LIST_SUCCESS:
      return processConversationListSuccess(state, action);
    case CLEAR_CONVERSATION_LIST:
      return processClearConversationList(state);
    case CONVERSATION_MESSAGES_LIST_PENDING:
      return processConversationMessagesListPending(state, action);
    case CONVERSATION_MESSAGES_LIST_SUCCESS:
      return processConversationMessagesListSuccess(state, action);
    case VISIT_CONVERSATION_CONTEXT_SUCCESS:
      return processVisitConversationContextSuccess(state, action);
    case CONVERSATION_VISIT_MESSAGES_LIST_PENDING:
      return processConversationVisitMessagesListPending(state, action);
    case CONVERSATION_VISIT_MESSAGES_LIST_SUCCESS:
      return processConversationVisitMessagesListSuccess(state, action);
    case SELECT_CONVERSATION:
      return processSelectConversation(state, action);
    case CONVERSATION_MESSAGE_SENDING:
      return processConversationMessageSending(state, action);
    case SET_UNREAD_CONVERSATIONS:
      return processSetUnreadConversations(state, action);
    case UPDATE_CONVERSATION_READ_CURSOR_MESSAGE_ID:
      return processUpdateConversationReadCursorMessageId(state, action);
    default:
      return state;
  }
}

const processSetConversationsFeatureActive = (
  state: IConversationsState,
  action: SetConversationsFeatureActive
): IConversationsState => {
  return {
    ...state,
    featureActive: action.featureActive,
  };
};

const processSetConversationsSourceConfigs = (
  state: IConversationsState,
  action: SetConversationsSourceConfigs
): IConversationsState => {
  return {
    ...state,
    sourceConfigs: action.sourceConfigs,
  };
};

const processConversationListPending = (
  state: IConversationsState,
  action: ConversationsListPending
): IConversationsState => {
  return {
    ...state,
    conversations: {
      ...state.conversations,
      isLoading: true,
      filters: action.filters,
    },
  };
};

const processClearConversationList = (
  state: IConversationsState
): IConversationsState => {
  return {
    ...state,
    conversations: {
      selectedConversationId: undefined,
      filters: {
        conversationId: undefined,
      },
      conversationStates: [],
      isLoading: false,
      nextPageToken: undefined,
      hasMorePages: true,
      initialListLoaded: false,
    },
  };
};

const processConversationMessageAdded = (
  state: IConversationsState,
  action: ConversationMessageAdded
): IConversationsState => {
  const createConversationState = (): IConversationState => {
    return {
      conversation: {
        conversationId: action.notification.conversationId,
        branchId: action.notification.branchId,
        source: action.notification.source,
        sender: action.notification.sender,
        mostRecentMessageSender: action.notification.sender,
        mostRecentMessageCreatedDate: action.notification.createdDate,
        mostRecentMessageText: action.notification.messageText,
        mostRecentMessageIsInbound: action.notification.isInbound,
        mostRecentInboundMessageId: action.notification.isInbound
          ? action.notification.conversationMessageId
          : undefined,
        readCursorMessageId: undefined,
        visits: [action.notification.visit],
      },
      messagesState: {
        messages: [conversationMessage],
        isLoading: false,
        hasMorePages: true,
        nextPageToken: action.notification.conversationMessageId,
        initialListLoaded: false,
        updateReason: ConversationMessagesListUpdateReason.MessageAdded,
        lastScrollDistanceFromBottom: undefined,
      },
    };
  };

  const createConversationVisitState = (): ConversationVisitState => {
    return {
      conversation: {
        conversationId: action.notification.conversationId,
        branchId: action.notification.branchId,
        source: action.notification.source,
        sender: action.notification.sender,
        mostRecentMessageSender: action.notification.sender,
        mostRecentMessageCreatedDate: action.notification.createdDate,
        mostRecentMessageText: action.notification.messageText,
        mostRecentMessageIsInbound: action.notification.isInbound,
        mostRecentInboundMessageId: action.notification.isInbound
          ? action.notification.conversationMessageId
          : undefined,
        readCursorMessageId: undefined,
        visits: [action.notification.visit],
      },
      visit: action.notification.visit,
      messagesState: {
        messages: [conversationMessage],
        isLoading: false,
        hasMorePages: true,
        nextPageToken: action.notification.conversationMessageId,
        initialListLoaded: false,
        updateReason: ConversationMessagesListUpdateReason.MessageAdded,
        lastScrollDistanceFromBottom: undefined,
      },
    };
  };

  const updateState = <TState>(
    state: TState & {
      conversation: Conversation;
      messagesState: ConversationMessagesState;
    }
  ): TState & {
    conversation: Conversation;
    messagesState: ConversationMessagesState;
  } => {
    const previousConversationMessages = action.notification
      .senderCorrelationGuid
      ? state.messagesState.messages.filter(
          (x) =>
            x.senderCorrelationGuid !==
            action.notification.senderCorrelationGuid
        )
      : state.messagesState.messages;

    if (previousConversationMessages.length === 0) {
      state.messagesState.nextPageToken =
        action.notification.conversationMessageId;
    }

    return {
      ...state,
      conversation: {
        ...state.conversation,
        mostRecentMessageSender: action.notification.sender,
        mostRecentMessageCreatedDate: action.notification.createdDate,
        mostRecentMessageText: action.notification.messageText,
        mostRecentMessageIsInbound: action.notification.isInbound,
        mostRecentInboundMessageId: action.notification.isInbound
          ? action.notification.conversationMessageId
          : state.conversation.mostRecentInboundMessageId,
      },
      messagesState: {
        ...state.messagesState,
        updateReason: ConversationMessagesListUpdateReason.MessageAdded,
        messages: reduceMediaMessages([
          ...previousConversationMessages,
          conversationMessage,
        ]),
      },
    };
  };

  const conversationMessage: ConversationMessage = {
    conversationMessageId: action.notification.conversationMessageId,
    isInbound: action.notification.isInbound,
    sender: action.notification.sender,
    messageText: action.notification.messageText,
    createdDate: action.notification.createdDate,
    medias: action.notification.medias.map((x) => {
      return {
        conversationMessageMediaId: x.id,
        filename: x.filename,
        contentType: x.contentType,
        contentSize: x.contentSize,
      };
    }),
    createdByManagerId: action.notification.createdByManagerId,
  };

  let conversationState = state.conversations.conversationStates.find(
    (x) => x.conversation.conversationId == action.notification.conversationId
  );

  let conversationVisitState = state.conversationVisits.find(
    (x) =>
      x.conversation.conversationId == action.notification.conversationId &&
      x.visit.visitId === action.notification.visit.visitId
  );

  if (conversationState) {
    conversationState = updateState(conversationState);
  } else {
    if (
      !state.conversations.filters.conversationId ||
      state.conversations.filters.conversationId ===
        action.notification.conversationId
    ) {
      conversationState = createConversationState();
    }
  }

  if (conversationVisitState) {
    conversationVisitState = updateState(conversationVisitState);
  } else {
    conversationVisitState = createConversationVisitState();
  }

  const nextPageToken =
    state.conversations.conversationStates.length === 0
      ? action.notification.conversationMessageId
      : state.conversations.nextPageToken;

  return {
    ...state,
    conversations: {
      ...state.conversations,
      conversationStates: conversationState
        ? [
            conversationState,
            ...state.conversations.conversationStates.filter(
              (x) =>
                x.conversation.conversationId !==
                conversationState?.conversation.conversationId
            ),
          ]
        : state.conversations.conversationStates,
      nextPageToken: nextPageToken,
    },
    conversationVisits: conversationVisitState
      ? [
          conversationVisitState,
          ...state.conversationVisits.filter(
            (x) =>
              x.conversation.conversationId !==
                conversationVisitState?.conversation.conversationId &&
              x.visit.visitId !== conversationVisitState?.visit.visitId
          ),
        ]
      : state.conversationVisits,
  };
};

const processConversationListSuccess = (
  state: IConversationsState,
  action: ConversationsListSuccess
): IConversationsState => {
  const conversationStates =
    action.conversationsTokenPaged.items.map<IConversationState>((x) => {
      return {
        conversation: x,
        messagesState: {
          messages: [],
          isLoading: false,
          hasMorePages: true,
          nextPageToken: undefined,
          initialListLoaded: false,
          updateReason: undefined,
          lastScrollDistanceFromBottom: undefined,
        },
      };
    });

  return {
    ...state,
    conversations: {
      ...state.conversations,
      selectedConversationId: state.conversations.filters.conversationId
        ? state.conversations.filters.conversationId
        : state.conversations.selectedConversationId,
      isLoading: false,
      nextPageToken: action.conversationsTokenPaged.nextPageToken,
      hasMorePages: action.conversationsTokenPaged.nextPageToken ? true : false,
      initialListLoaded: true,
      conversationStates: [
        ...state.conversations.conversationStates,
        ...conversationStates,
      ],
    },
  };
};

const processConversationMessagesListPending = (
  state: IConversationsState,
  action: ConversationMessagesListPending
): IConversationsState => {
  const conversationStates = state.conversations.conversationStates.map((x) => {
    if (x.conversation.conversationId === action.conversationId) {
      return {
        ...x,
        messagesState: {
          ...x.messagesState,
          isLoading: true,
        },
      };
    }

    return x;
  });

  return {
    ...state,
    conversations: {
      ...state.conversations,
      conversationStates,
    },
  };
};

const processConversationMessagesListSuccess = (
  state: IConversationsState,
  action: ConversationMessagesListSuccess
): IConversationsState => {
  const conversationStates = state.conversations.conversationStates.map((x) => {
    if (x.conversation.conversationId === action.conversationId) {
      return {
        ...x,
        messagesState: {
          ...x.messagesState,
          isLoading: false,
          nextPageToken: action.conversationMessagesTokenPaged.nextPageToken,
          hasMorePages: action.conversationMessagesTokenPaged.nextPageToken
            ? true
            : false,
          initialListLoaded: true,
          messages: reduceMediaMessages([
            ...action.conversationMessagesTokenPaged.items.reverse(),
            ...x.messagesState.messages,
          ]),
          updateReason: action.reason,
          lastScrollDistanceFromBottom: action.scrollDistanceFromBottom,
        },
      };
    }

    return x;
  });

  return {
    ...state,
    conversations: {
      ...state.conversations,
      conversationStates: conversationStates,
    },
  };
};

const processConversationVisitMessagesListPending = (
  state: IConversationsState,
  action: ConversationVisitMessagesListPending
): IConversationsState => {
  const conversationVisitStates = state.conversationVisits.map((x) => {
    if (
      x.conversation.conversationId === action.conversationId &&
      x.visit.visitId === action.visitId
    ) {
      return {
        ...x,
        messagesState: {
          ...x.messagesState,
          isLoading: true,
        },
      };
    }

    return x;
  });

  return {
    ...state,
    conversationVisits: conversationVisitStates,
  };
};

const processConversationVisitMessagesListSuccess = (
  state: IConversationsState,
  action: ConversationVisitMessagesListSuccess
): IConversationsState => {
  const conversationVisitStates = state.conversationVisits.map((x) => {
    if (
      x.conversation.conversationId === action.conversationId &&
      x.visit.visitId === action.visitId
    ) {
      return {
        ...x,
        messagesState: {
          ...x.messagesState,
          isLoading: false,
          nextPageToken: action.conversationMessagesTokenPaged.nextPageToken,
          hasMorePages: action.conversationMessagesTokenPaged.nextPageToken
            ? true
            : false,
          initialListLoaded: true,
          messages: reduceMediaMessages([
            ...action.conversationMessagesTokenPaged.items.reverse(),
            ...x.messagesState.messages,
          ]),
          updateReason: action.reason,
          lastScrollDistanceFromBottom: action.scrollDistanceFromBottom,
        },
      };
    }

    return x;
  });

  return {
    ...state,
    conversationVisits: conversationVisitStates,
  };
};

const processSelectConversation = (
  state: IConversationsState,
  action: SelectConversation
): IConversationsState => {
  const conversationState = state.conversations.conversationStates.filter(
    (x) => x.conversation.conversationId == action.conversationId
  )[0];
  conversationState.messagesState.lastScrollDistanceFromBottom = undefined;

  const conversationStates = state.conversations.conversationStates.map((x) => {
    if (x.conversation.conversationId === action.conversationId) {
      return conversationState;
    }

    return x;
  });

  return {
    ...state,
    conversations: {
      ...state.conversations,
      selectedConversationId: action.conversationId,
      conversationStates: conversationStates,
    },
  };
};

const processConversationMessageSending = (
  state: IConversationsState,
  action: ConversationMessageSending
): IConversationsState => {
  let conversationStates = state.conversations.conversationStates;

  const conversationState = state.conversations.conversationStates.find(
    (x) => x.conversation.conversationId === action.conversationId
  );

  if (conversationState) {
    conversationState.messagesState.messages = [
      ...conversationState.messagesState.messages,
      action.conversationMessage,
    ];

    conversationState.messagesState.updateReason =
      ConversationMessagesListUpdateReason.MessageSending;

    conversationStates = state.conversations.conversationStates.map((x) => {
      if (x.conversation.conversationId === action.conversationId) {
        return conversationState;
      }

      return x;
    });
  }

  let conversationVisitStates = state.conversationVisits;

  const conversationVisitState = state.conversationVisits.find(
    (x) =>
      x.conversation.conversationId === action.conversationId &&
      x.visit.visitId === action.visitId
  );

  if (conversationVisitState) {
    conversationVisitState.messagesState.messages = [
      ...conversationVisitState.messagesState.messages,
      action.conversationMessage,
    ];

    conversationVisitState.messagesState.updateReason =
      ConversationMessagesListUpdateReason.MessageSending;

    conversationVisitStates = conversationVisitStates.map((x) => {
      if (
        x.conversation.conversationId === action.conversationId &&
        x.visit.visitId === action.visitId
      ) {
        return conversationVisitState;
      }

      return x;
    });
  }

  return {
    ...state,
    conversations: {
      ...state.conversations,
      conversationStates: conversationStates,
    },
    conversationVisits: conversationVisitStates,
  };
};

const processVisitConversationContextSuccess = (
  state: IConversationsState,
  action: VisitConversationContextSuccess
): IConversationsState => {
  const conversationVisitState: ConversationVisitState = {
    conversation: action.context.conversation,
    visit: action.context.visit,
    messagesState: {
      messages: [],
      isLoading: false,
      hasMorePages: true,
      nextPageToken: undefined,
      initialListLoaded: false,
      updateReason: undefined,
      lastScrollDistanceFromBottom: undefined,
    },
  };

  return {
    ...state,
    conversationVisits: [...state.conversationVisits, conversationVisitState],
  };
};

const processSetUnreadConversations = (
  state: IConversationsState,
  action: SetUnreadConversations
): IConversationsState => {
  return {
    ...state,
    unreadConversations: action.unreadConversations,
  };
};

const processUpdateConversationReadCursorMessageId = (
  state: IConversationsState,
  action: UpdateConversationReadCursorMessageId
): IConversationsState => {
  const conversationStates = state.conversations.conversationStates.map((x) => {
    if (x.conversation.conversationId === action.conversationId) {
      return {
        ...x,
        conversation: {
          ...x.conversation,
          readCursorMessageId: action.readCursorMessageId,
        },
      };
    }

    return x;
  });

  const conversationVisitStates = state.conversationVisits.map((x) => {
    if (x.conversation.conversationId === action.conversationId) {
      return {
        ...x,
        conversation: {
          ...x.conversation,
          readCursorMessageId: action.readCursorMessageId,
        },
      };
    }

    return x;
  });

  return {
    ...state,
    conversations: {
      ...state.conversations,
      conversationStates: conversationStates,
    },
    conversationVisits: conversationVisitStates,
  };
};

const reduceMediaMessages = (
  conversationMessages: ConversationMessage[]
): ConversationMessage[] => {
  const reduced: ConversationMessage[] = [];

  for (let i = 0; i < conversationMessages.length; i++) {
    const previousValue = i > 0 ? conversationMessages[i - 1] : undefined;

    const currentValue = conversationMessages[i];

    const nextValue =
      i + 1 < conversationMessages.length
        ? conversationMessages[i + 1]
        : undefined;

    if (
      currentValue.messageText &&
      (nextValue?.medias?.some(
        (x) => x.filename === currentValue.messageText
      ) ||
        previousValue?.medias?.some(
          (x) => x.filename === currentValue.messageText
        ))
    ) {
      continue;
    }

    reduced.push(currentValue);
  }

  return reduced;
};
