import {
  CaseEventType,
  ICaseEvent,
} from "../../../state/types/TaskCentreCases";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GuestContactStyles } from "../styles/guestContactStyles";
import dayjs from "dayjs";
import GuestContactNote from "./guestContactNote";
import { i18n } from "../../../localizations";
type props = {
  notes: ICaseEvent[];
  isMobileView?: boolean;
};
const GuestContactNotesContainer = (props: props) => {
  const classes = GuestContactStyles();

  const sortedMessageList = props.notes.sort((eventA, eventB): number => {
    return dayjs(eventA.timestamp).isBefore(eventB.timestamp) ? 1 : -1;
  });
  const messageList = sortedMessageList.map((messageEvent) => {
    if (messageEvent.type === CaseEventType.GuestContactConfirmedEvent)
      return (
        <GuestContactNote
          note={messageEvent}
          key={messageEvent.id}
          isMobileView={props.isMobileView}
        />
      );
  });
  return (
    <Accordion className={classes.accordionRow}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {i18n.translate("TASK_CENTRE_Internal_Notes")}
      </AccordionSummary>
      <div>{messageList}</div>
    </Accordion>
  );
};

export default GuestContactNotesContainer;
