import { useState } from "react";
import i18n from "../../../localizations/i18n";
import CreateTaskDialog from "./createTaskDialog";
import { Button, Typography } from "@material-ui/core";
import { TasksStyles } from "../styles/tasksStyles";
interface IProps {
  visitId: number;
}

const QuickTaskCreator = (props: IProps) => {
  const classes = TasksStyles();

  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

  return (
    <div className={classes.newTaskButtonContainer}>
      <Typography color="textPrimary">
        {i18n.translate("TASK_CENTRE_NO_TASKS")}
      </Typography>
      <Button
        className={`${classes.tasksButton} ${classes.newTaskbutton}`}
        onClick={() => setCreateDialogOpen(true)}
      >
        {i18n.translate("VISIT_DETAILS_TASK_CREATOR_Create_New_Task")}
      </Button>
      <CreateTaskDialog
        visitId={props.visitId}
        dialogOpen={createDialogOpen}
        onDialogClose={() => setCreateDialogOpen(false)}
      />
    </div>
  );
};

export default QuickTaskCreator;
