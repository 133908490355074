import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import i18n from "../../localizations/i18n";
import { InboxTaskSelectorStyles } from "./styles/inboxTaskSelectorStyles";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTaskCentreCase } from "../../state/actions/TaskCentre-CaseList-Actions";
import { AvailableVisitTasks, SelectedCaseId } from "./state/selectors";

const InboxTaskSelector = () => {
  const classes = InboxTaskSelectorStyles();
  const dispatch = useDispatch();

  const [selectorAnchor, setSelectorAnchor] =
    useState<HTMLButtonElement | null>(null);

  const availableTasks = useSelector(AvailableVisitTasks);
  const selectedCaseId = useSelector(SelectedCaseId);

  const onSelectionChanged = (caseId: number | undefined) => {
    if (caseId && !Number.isNaN(caseId)) {
      setSelectorAnchor(null);
      dispatch(setSelectedTaskCentreCase(caseId));
    }
  };

  const handleSelectorOpen = (target: HTMLButtonElement) => {
    setSelectorAnchor(target);
  };

  const handleSelectorClose = () => {
    setSelectorAnchor(null);
  };

  const getCaseDisplayName = (caseId: number) => {
    const matchedCase = availableTasks.find((x) => x.id === caseId);
    const displayName = matchedCase ? matchedCase.title : "";

    return displayName.length > 40
      ? `${displayName.substring(0, 37)}...`
      : displayName;
  };

  return (
    <>
      {availableTasks && availableTasks.length > 1 && selectedCaseId && (
        <div className={classes.selection}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => handleSelectorOpen(e.currentTarget)}
          >
            {selectedCaseId && (
              <div className={classes.selectionName}>
                {getCaseDisplayName(selectedCaseId)}
              </div>
            )}
            {i18n.translate("VISIT_DETAILS_TASK_SELECTOR_SelectorSuffix")}
          </Button>
          <Menu
            anchorEl={selectorAnchor}
            keepMounted
            open={Boolean(selectorAnchor)}
            onClose={handleSelectorClose}
          >
            {availableTasks.map((x) => {
              return (
                <MenuItem
                  key={x.id}
                  data-sheet-name={x.title}
                  onClick={() => onSelectionChanged(x.id)}
                >
                  {x.title}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )}
    </>
  );
};

export default InboxTaskSelector;
