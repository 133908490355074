import { grey } from "@material-ui/core/colors";
import { createTheme, lighten } from "@material-ui/core/styles";
export const hgemColours = {
  LimeGreen: "#b6ce35",
  Turquoise: "#62bec4",
  White: "#fff",
  OffWhite: "#f4f4f4",
  SlightlyMoreOffWhiteKindOfGrey: "#e0e0e0",
  DarkGrey: "#4f575c",
  MediumGrey: "#3b4145",
  LightGrey: "#eaeaea",
  LighterGrey: "#bdbdbd",
  ExtraMediumGrey: "#929b9f",
  LighterMediumGrey: "#F2F2F2",
  GreyReportBackground: "#eaeaea",
  Red: "#EB3E39",
  Yellow: "#EBB139",
  Green: "#2DB936",
  Orange: "#F49949",
  RgbColours: {
    translucentBlue: "rgb(16, 141, 204, 0.7)",
  },
};

export const hgemFont = "'Open Sans', Tahoma, Geneva, Verdana, sans-serif";
export const materialFont = "'Roboto', 'Helvetica', 'Arial', sans-serif;";

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 960,
      sm: 1080,
      md: 1160,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: hgemColours.LimeGreen,
    },
    secondary: {
      main: hgemColours.Turquoise,
    },
    background: {
      paper: hgemColours.White,
      default: hgemColours.MediumGrey,
    },
    text: {
      primary: hgemColours.DarkGrey,
      secondary: hgemColours.White,
    },
    warning: {
      main: hgemColours.Orange,
    },
    error: {
      main: hgemColours.Orange,
    },
  },

  hubSettings: {
    body: {
      forceLowerCaseText: false,
    },
    logo: {
      path: "URL('/hub_logo_header_alt.png')",
      position: "45px center",
      size: "140px",
      mobileSize: "140px",
      iconColor: hgemColours.DarkGrey,
    },
    navigation: {
      textColor: hgemColours.DarkGrey,
      backgroundColor: hgemColours.White,
      background:
        "linear-gradient(90deg, rgba(233,233,233,1) 0%, rgba(238,238,238,1) 20%, rgba(255,255,255,1) 100%)",
      optionBackgroundColor: "rgba(59,65,69, 0.05)",
      optionBackgroundColorHover: "rgba(59,65,69, 0.08)",
    },
  },

  // Component Global Overrides
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
      },
    },

    // Button
    MuiButton: {
      root: {
        textTransform: "none",
      },

      contained: {
        boxShadow: "none",
        backgroundColor: hgemColours.LimeGreen,
        color: hgemColours.White,

        "&:hover": {
          backgroundColor: hgemColours.DarkGrey,
          boxShadow: "none",
        },
      },

      containedPrimary: {
        backgroundColor: hgemColours.LimeGreen,
        color: hgemColours.White,

        "&:hover": {
          backgroundColor: hgemColours.DarkGrey,
        },
      },

      containedSecondary: {
        color: hgemColours.DarkGrey,
        border: `1px solid ${lighten(hgemColours.Turquoise, 0.5)}`,
        backgroundColor: lighten(hgemColours.Turquoise, 0.8),

        "&:hover": {
          border: `1px solid ${lighten(hgemColours.Turquoise, 0.5)}`,
          backgroundColor: lighten(hgemColours.Turquoise, 0.6),
        },
      },
    },

    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
    },

    MuiChip: {
      root: {
        backgroundColor: hgemColours.LimeGreen,
        color: hgemColours.White,
      },
    },

    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: lighten(hgemColours.DarkGrey, 0.7),
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: hgemColours.LimeGreen,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        backgroundColor: lighten(hgemColours.DarkGrey, 0.25),
        "$checked$checked + &": {
          // Controls checked color for the track
          backgroundColor: lighten(hgemColours.LimeGreen, 0.25),
        },
      },
    },

    // Typography
    MuiTypography: {
      h1: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h2: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h3: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h4: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h5: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      h6: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
      },
      subtitle1: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
        fontSize: "16px",
      },
      body1: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
        fontSize: "14px",
      },
      body2: {
        fontFamily: hgemFont,
        color: hgemColours.DarkGrey,
        fontSize: "12px",
      },
    },

    // Checkbox
    MuiCheckbox: {
      colorSecondary: {
        color: hgemColours.LimeGreen,
        "&$checked": {
          color: hgemColours.LimeGreen,
        },
      },
    },

    // Dialog
    MuiDialogContent: {
      root: {
        paddingBottom: "20px",
      },
    },
    MuiDialogTitle: {
      root: {
        marginBottom: "20px",
      },
    },

    // TextField
    MuiInput: {
      underline: {
        "&::before": {
          borderColor: "#eaeaea",
        },
      },
    },

    // Tabs
    MuiTab: {
      root: {
        textTransform: "none",
        backgroundColor: grey[50],
        border: `1px solid ${lighten(hgemColours.LightGrey, 0.1)}`,
        borderRadius: "5px",
        marginRight: "5px",
        padding: "10px 10px 5px 10px",
        minHeight: "40px",

        "&&.Mui-disabled": {
          backgroundColor: "transparent",
          opacity: "0.4",
        },
      },
    },

    MuiTabs: {
      root: {
        minHeight: "40px",
      },
      scrollable: {
        overflowX: "hidden",
      },
      scrollButtons: {
        width: "30px",
        transition: "width 300ms",

        "&&.Mui-disabled": {
          width: "0px",
        },
      },
    },

    MuiList: {
      root: {
        "&:focus": {
          outlineColor: hgemColours.LightGrey,
        },
      },
    },
  },
});

/* 
  Theme Extension for custom Hub properties 
*/
declare module "@material-ui/core/styles" {
  export interface Theme {
    hubSettings: {
      body: HubBody;
      logo: HubLogo;
      navigation: HubNavigation;
    };
  }

  export interface ThemeOptions {
    hubSettings?: {
      body?: HubBody;
      logo?: HubLogo;
      navigation?: HubNavigation;
    };
  }

  export interface HubBody {
    forceLowerCaseText: boolean;
  }

  export interface HubLogo {
    path: string;
    position: string;
    size: string;
    mobileSize: string;
    iconColor: string;
    minHeight?: string;
  }

  export interface HubNavigation {
    textColor: string;
    background: string;
    backgroundColor: string;
    optionBackgroundColor: string;
    optionBackgroundColorHover: string;
  }
}
