import { SagaMiddleware } from "redux-saga";
import { put, takeLeading, call } from "redux-saga/effects";
import {
  APPLICATION_RESET,
  IApplicationReset,
} from "../actions/Application-Actions";
import { resetFilterOptions } from "../actions/FilterOptions-Actions";
import { resetFilterSets } from "../actions/FilterSets-Actions";
import { reportConfigurationReset } from "../actions/ReportConfiguration-Actions";
import { resetUserImpersonationOptions } from "../actions/UserImpersonation-Actions";
import { resetUserProfile } from "../actions/UserProfile-Actions";

export function registerApplicationSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLeading(APPLICATION_RESET, resetApplicationState);
  });
}

function* resetApplicationState(action: IApplicationReset): unknown {
  yield put(resetUserProfile());
  yield put(resetUserImpersonationOptions());
  yield put(reportConfigurationReset());
  yield put(resetFilterOptions());
  yield put(resetFilterSets());

  if (action.callback) {
    yield call(action.callback);
  }
}
