import React, { useEffect, useState } from "react";
import {
  Dialog,
  Icon,
  IconButton,
  ImageListItemBar,
  LinearProgress,
} from "@material-ui/core";
import { getPhotoData, VisitPhotoData, UnavailableStatus } from "../state/api";
import i18n from "../../../localizations/i18n";
import { VisitPhotoModalStyles } from "../styles/visitPhotoModalStyles";
import dayjs from "dayjs";
import { MediaStyles } from "../../inboxViews/styles";
import IsAuthorised from "../../../authorisation/permissions";
import { PermissionType } from "../../../authorisation/permissionsUtils";

interface IProps {
  visitId: number;
  fileName: string;
  onClose: () => void;
}

const VisitPhotoModal = (props: IProps) => {
  const classes = VisitPhotoModalStyles();
  const mediaClasses = MediaStyles();
  const [photoData, setPhotoData] = useState<VisitPhotoData | undefined>(
    undefined
  );
  const [showVisitPhotoMetadata, setShowVisitPhotoMetadata] =
    useState<boolean>(false);

  const hasMetadataToShow = () => {
    return (
      photoData?.metadata?.dateTaken ||
      photoData?.metadata?.address ||
      (photoData?.metadata?.latitude && photoData?.metadata?.longitude)
    );
  };

  const handleDownload = () => {
    if (!photoData) {
      return;
    }

    const fullFilename = `${i18n.translate(
      "VISIT_DETAILS_PHOTO_MODAL_Filename_Prefix"
    )}-${props.visitId}.jpg`;
    saveAs(photoData.data, fullFilename);
  };

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    const checkUserCanSeeVisitPhotoMetadata = async () => {
      const canSeeVisitPhotoMetadata = await IsAuthorised(
        PermissionType.SeeVisitPhotoMetadata
      );

      if (canSeeVisitPhotoMetadata) {
        setShowVisitPhotoMetadata(canSeeVisitPhotoMetadata);
      }
    };
    checkUserCanSeeVisitPhotoMetadata();
  });

  useEffect(() => {
    const fetchPhoto = async () => {
      const photoData = await getPhotoData(
        props.visitId,
        props.fileName,
        false
      );
      setPhotoData(photoData);
    };

    if (!photoData) {
      fetchPhoto();
    }
  }, [photoData, props.fileName, props.visitId]);

  return (
    <Dialog className={classes.dialog} open={true} onClose={props.onClose}>
      {photoData &&
        photoData.data !== UnavailableStatus &&
        photoData.data.length > 0 && (
          <>
            <div className={classes.imageOptions}>
              <IconButton
                size="small"
                onClick={handleClose}
                title={i18n.translate("VISIT_DETAILS_PHOTO_MODAL_Close")}
              >
                <Icon className="far fa-window-close" />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleDownload}
                title={i18n.translate("VISIT_DETAILS_PHOTO_MODAL_Download")}
              >
                <Icon className="fas fa-download" />
              </IconButton>
            </div>
            <img
              src={photoData.data.toString()}
              alt={i18n.translate("VISIT_DETAILS_MEDIA_Photo_Alt_Text")}
            />
            {showVisitPhotoMetadata && hasMetadataToShow() && (
              <ImageListItemBar
                classes={{ subtitle: mediaClasses.mediaOverlay }}
                subtitle={
                  photoData?.metadata && (
                    <>
                      {photoData.metadata.dateTaken && (
                        <div className={classes.overlayText}>
                          {i18n.translate(
                            "VISIT_DETAILS_PHOTO_MODAL_Metadata_Date_Prefix"
                          )}
                          &nbsp;
                          {dayjs(photoData.metadata.dateTaken).format(
                            i18n.translate(
                              "VISIT_DETAILS_PHOTO_MODAL_Metadata_Date_Format"
                            )
                          )}
                        </div>
                      )}
                      {photoData.metadata.address && (
                        <div className={classes.overlayText}>
                          {i18n.translate(
                            "VISIT_DETAILS_PHOTO_MODAL_Metadata_Location_Prefix"
                          )}
                          &nbsp;
                          {photoData.metadata.address}
                        </div>
                      )}
                      {!photoData.metadata.address &&
                        photoData.metadata.latitude &&
                        photoData.metadata.longitude && (
                          <div className={classes.overlayText}>
                            {i18n.translate(
                              "VISIT_DETAILS_PHOTO_MODAL_Metadata_Location_Prefix"
                            )}
                            &nbsp;
                            {photoData.metadata.latitude}&nbsp;
                            {photoData.metadata.longitude}
                          </div>
                        )}
                    </>
                  )
                }
              />
            )}
          </>
        )}
      {!photoData ||
        (photoData.data.length === 0 && (
          <div className={classes.pendingContainer}>
            <LinearProgress color="primary" />
            <Icon className={`fas fa-camera ${classes.imagePending}`} />
          </div>
        ))}
    </Dialog>
  );
};

export default VisitPhotoModal;
