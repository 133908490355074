import { ICaseEvent } from "../../../state/types/TaskCentreCases";
import {
  Accordion,
  AccordionSummary,
  Icon,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import dayjs from "dayjs";
import { i18n } from "../../../localizations";
import { GuestContactStyles } from "../styles/guestContactStyles";
import { GuestContactNoteStyles } from "../styles/guestContactNoteStyles";

type props = {
  note: ICaseEvent;
  isMobileView?: boolean;
};
const GuestContactNote = (props: props) => {
  const { note } = props;
  const classes = GuestContactStyles();
  const noteStyles = GuestContactNoteStyles();
  return (
    <>
      <Accordion
        key={note.id}
        className={`${classes.accordionRow}`}
        defaultExpanded={props.isMobileView ? false : true}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className={`${classes.accordionSummary}`}>
            <span>
              <Icon className={`fad fa-file-pen ${classes.inboxIcon}`} />
            </span>
            <div className={classes.titleContainer}>
              <div className={classes.messageSubject}>
                <div className={classes.dateLine}>
                  {dayjs(note.timestamp).format(
                    i18n.translate("TASK_CENTRE_Timestamp_Format")
                  )}
                  - {note.authorName}
                </div>
              </div>
            </div>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className={noteStyles.noteContainer}>
            <span className={noteStyles.note}>{note.comment}</span>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GuestContactNote;
