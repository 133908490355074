import { useEffect, useState } from "react";
import { getReferenceQuestionsApi } from "../../api/Appeals-API";
import { IAppealReferenceQuestion } from "../../types/Appeals";

interface IProps {
    visitId: number | undefined;
    clientId: number | undefined;
}

export const useAppealReferenceQuestions = (props: IProps) => {
    const [appealReferenceQuestions, setAppealReferenceQuestions] = useState<IAppealReferenceQuestion[]>([]);
    useEffect(() => {
        if (props.clientId && props.visitId) {
            getReferenceQuestionsApi(props.clientId, props.visitId).then(response => {
                setAppealReferenceQuestions(response.data);
            })
        }
    }, [setAppealReferenceQuestions, props.clientId, props.visitId]);

    return appealReferenceQuestions;
}