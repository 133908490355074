import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/reducers/RootReducer";
import CommentField from "./components/commentField";
import {
  GetActiveNotificationById,
  VisitTasksPending,
} from "./state/selectors";
import { AddCommentCommand } from "../../state/types/TaskCentreCommands";
import { LinearProgress } from "@material-ui/core";
import {
  CaseEventType,
  ICaseDetails,
  IMinimalCaseEventAttachment,
  WorkflowStatus,
} from "../../state/types/TaskCentreCases";
import TaskCentreEvents from "../taskCentreEvents/taskCentreEvents";
import InboxTaskSelector from "../InboxTaskSelector/inboxTaskSelector";
import { getTaskEventAttachmentApi } from "../../state/api/TaskCentre-API";
import { useClientId } from "../../state/hooks/clients/useClientId";
import {
  AttachmentsContainer,
  AttachmentsList,
} from "../inboxViews/components/attachments";
import { CommentFieldStyles } from "../_common/comments/styles/commentFieldStyles";

interface IProps {
  case: ICaseDetails | undefined;
  visitId: number | undefined;
  showTaskSelector?: boolean;
  hideAttachments?: boolean;
  hideInput?: boolean;
}

const InboxComments = (props: IProps) => {
  const classes = CommentFieldStyles();
  const casesPending = useSelector(VisitTasksPending);
  const fieldNotification = useSelector((state: RootState) =>
    GetActiveNotificationById(
      state,
      `${props.case?.id}|${AddCommentCommand.type}`
    )
  );

  const clientId = useClientId();

  const attachments: (IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  })[] =
    props.case?.events
      .filter((x) => x.type === CaseEventType.CommentAddedEvent)
      .flatMap(
        (x) =>
          x.caseEventAttachments?.map((y) => {
            return { ...y, authorId: x.authorId, authorName: x.authorName };
          }) ?? []
      )
      .sort((a, b) => b.id - a.id) ?? [];

  return (
    <>
      {casesPending && (
        <div>
          <LinearProgress color="primary" />
        </div>
      )}

      {!casesPending && props.case && (
        <>
          {props.showTaskSelector && <InboxTaskSelector />}
          <div className={classes.commentsContainer}>
            <TaskCentreEvents
              visitId={props.case.vId}
              caseId={props.case.id}
              eventTypes={[CaseEventType.CommentAddedEvent]}
            />
            {!props.hideInput && (
              <CommentField
                caseDetails={props.case}
                fieldNotification={fieldNotification}
                isNewCase={false}
                hideAttachments={props.hideAttachments}
              />
            )}
          </div>
          {attachments.length > 0 && !props.hideAttachments && (
            <AttachmentsContainer>
              <AttachmentsList
                attachments={attachments}
                authorDeleteAttachment={(_) => undefined}
                downloadAttachment={
                  clientId
                    ? (caseEventAttachmnent) => {
                        const case0 = props.case as ICaseDetails;

                        return getTaskEventAttachmentApi(
                          clientId,
                          case0.vId,
                          case0.id,
                          caseEventAttachmnent.id,
                          caseEventAttachmnent.filepath
                        ).then((x) => x.blob);
                      }
                    : undefined
                }
              />
            </AttachmentsContainer>
          )}
        </>
      )}

      {!casesPending && !props.case && props.visitId && (
        <CommentField
          caseDetails={
            {
              vId: props.visitId,
              wfStatus: WorkflowStatus.Discussion,
            } as ICaseDetails
          }
          fieldNotification={fieldNotification}
          isNewCase={true}
        />
      )}
    </>
  );
};

export default InboxComments;
