import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    minWidth: "280px",
    minHeight: "300px",
  },
  treeContainer: {
    overflowX: "hidden",
    minHeight: "200px",
    maxHeight: "300px",
  },
  treeView: {
    "&& ul": {
      marginLeft: "0",
    },
    "&& li ul": {
      marginLeft: "0",
    },
    "&& .MuiTreeItem-label:hover": {
      background: "none",
    },
  },
  treeItem: {
    marginLeft: 7,
    paddingLeft: 6,
    borderLeft: `dashed 1px ${theme.palette.grey[400]}`,
  },
  rootTreeItem: {
    marginLeft: 7,
    paddingLeft: 6,
  },
  treeItemContent: {
    width: "100%",
  },
  expandIcons: {
    padding: "5px",
  },
  filterDescription: {
    margin: "5px",
    color: theme.palette.grey[500],
    fontSize: "0.8em",
  },
  actions: {
    margin: "10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  subTasks: {
    border: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: "20px",
  },
  status: {
    float: "left",
    color: theme.palette.grey[500],
  },
}));

export const GenericTreeViewStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
