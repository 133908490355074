import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { IFilterSetsState } from "../../../state/reducers/FilterSets-Reducer";
import { applyFilterAvailabilityRules } from "../../_common/filters/utils/hubFilterAvailability";
import { IFilterOptionsState } from "../../../state/reducers/FilterOptions-Reducer";
import { IReportViewerState } from "../../../state/reducers/ReportViewer-Reducer";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import { ICaseCategory } from "../../../state/types/TaskCentreCases";

export const GetCurrentFilterSet = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (state: IFilterSetsState) => {
    return state.currentFilterSet;
  }
);

export const GetVisibleFilters = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (_: RootState, pageRef: string) => pageRef,
  (state, pageRef) => {
    const filters = state.currentFilters;
    const filterAvailability = state.filterSetConfiguration.filterAvailability;

    return applyFilterAvailabilityRules(filters, filterAvailability, pageRef);
  }
);

export const GetAllFilters = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (_: RootState, pageRef: string) => pageRef,
  (state, pageRef) => {
    const filters = state.currentFilters;
    const filterAvailability = state.filterSetConfiguration.filterAvailability;

    return applyFilterAvailabilityRules(
      filters,
      filterAvailability,
      pageRef,
      false
    );
  }
);

export const RepotIsBusy = createSelector(
  (state: RootState) => state.ReportViewerReducer,
  (state: IReportViewerState) => {
    return state.isBusy;
  }
);

export const GetUserCurrentBrands = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.currentBrands
    : [];
};

export const GetLocationFilterOptions = createSelector(
  (state: RootState) => state,
  (_: RootState, brands: string[]) => brands,
  (state, brands) => {
    const currentClient = state.UserProfileReducer.userProfile?.currentClient;
    const clientName =
      state.UserProfileReducer.userProfile?.availableClients.find((x) =>
        x.id === currentClient ? currentClient : 0
      );

    if (brands && brands.length > 0) {
      let includeBlankBrands = false;
      if (clientName && brands.indexOf(clientName.name) > -1) {
        includeBlankBrands = true;
      }

      return state.FilterOptionsReducer.filterOptions.locationOptions.filter(
        (x) =>
          (x.brand !== undefined && brands.indexOf(x.brand) > -1) ||
          (includeBlankBrands && (x.brand === undefined || x.brand === ""))
      );
    } else {
      return state.FilterOptionsReducer.filterOptions.locationOptions;
    }
  }
);

export const GetVisitDateFilterOptions = createSelector(
  (state: RootState) => state.FilterOptionsReducer,
  (state: IFilterOptionsState) => {
    return state.filterOptions.visitDateOptions;
  }
);

export const GetApplicableVisitTypesByRef = createSelector(
  (state: RootState) => state,
  (_: RootState, pageRef: string) => pageRef,
  (state, pageRef) => {
    const visitTypes: string[] = [];

    const reportConfiguration =
      state.ReportConfigurationReducer.reportConfiguraton.reportDetails.find(
        (x) => x.internalRef?.toLowerCase() === pageRef.toLowerCase()
      );

    if (reportConfiguration && reportConfiguration.applicableVisitTypes) {
      visitTypes.push(...reportConfiguration.applicableVisitTypes);
    }

    return visitTypes;
  }
);

export const GetQuestionnaireFilterOptionsByVisitTypes = createSelector(
  (state: RootState) => state,
  (_: RootState, visitTypes: string[]) => visitTypes,
  (state, visitTypes) => {
    return state.FilterOptionsReducer.filterOptions.questionnaireOptions.filter(
      (x) => visitTypes.indexOf(x.visitType) > -1
    );
  }
);

export const AllTaskCategories = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.categories.map((x) => {
      return {
        id: x.id,
        name: x.name,
      } as ICaseCategory;
    });
  }
);

export const AllUsedQuestionnaireTags = createSelector(
  (state: RootState) => state.FilterOptionsReducer,
  (state: IFilterOptionsState) => {
    const tags: string[] = [];
    const options = state.filterOptions.questionnaireOptions;

    options.forEach((o) => {
      o.questionnaires.forEach((q) => {
        if (q.tags) {
          tags.push(...q.tags.map((t) => t.tag));
        }
      });
    });

    return [...new Set(tags)];
  }
);

export const GetVisitSourceOptions = createSelector(
  (state: RootState) => state.FilterOptionsReducer,
  (state: IFilterOptionsState) => {
    const visitSources = state.filterOptions.visitSourceOptions;
    return [...new Set(visitSources)];
  }
);

// export const GetAgeRangeOptions = createSelector(
//   (state: RootState) => state.FilterOptionsReducer,
//   (state: IFilterOptionsState) => {
//     return state.filterOptions.ageFilters;
//   }
// );

// export const GetDishFilterOptions = createSelector(
//   (state: RootState) => state.FilterOptionsReducer,
//   (state: IFilterOptionsState) => {
//     return state.filterOptions.dishFilters;
//   }
// );
export const AllTaskAssignees = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return [...new Set(state.managers)];
  }
);

export const GetPeriodHierarchyOptions = createSelector(
  (state: RootState) => state,
  (_: RootState, brands: string[]) => brands,
  (state, brands) => {
    const periodHierarchies =
      state.FilterOptionsReducer.filterOptions.periodHierarchyOptions ?? [];

    return periodHierarchies.filter(
      (x) => brands.indexOf(x.brandName ?? "") > -1 || x.brandId === undefined
    );
  }
);
