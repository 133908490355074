import React, { useEffect, useState } from "react";
import FormField from "../../_common/formField/formField";
import { MenuItem, Select } from "@material-ui/core";
import { ReprioritiseCaseCommand } from "../../../state/types/TaskCentreCommands";
import { useDispatch } from "react-redux";
import { Priority, Priorities } from "../../../state/types/TaskCentreCases";
import PriorityIcon from "../../_common/Icons/priorityIcon";
import { executeCaseCommand } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { PriorityFieldStyles } from "../styles/inputs/priorityFieldStyles";
import { EditCaseItemStyles } from "../styles/editCaseItemStyles";

interface IProps {
  caseId: number;
  priority: Priority;
}

const PriorityField = (props: IProps) => {
  const classes = PriorityFieldStyles();
  const inputClasses = EditCaseItemStyles();
  const dispatch = useDispatch();
  const [caseId, setCaseId] = useState(props.caseId);
  const [priority, setPriority] = useState(props.priority);

  const onChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value: Priority = e.target.value as number;
    setPriority(value);

    const noChange = value === props.priority;
    if (noChange) {
      return;
    }

    const statusCommand = new ReprioritiseCaseCommand(props.caseId, value);
    dispatch(executeCaseCommand(props.caseId, [statusCommand]));
  };

  useEffect(() => {
    if (caseId !== props.caseId) {
      setCaseId(props.caseId);
    }

    setPriority(props.priority);
  }, [caseId, props.caseId, props.priority]);

  return (
    <FormField
      before={
        <>
          <PriorityIcon priority={props.priority} />
        </>
      }
      notificationIdentifier={`${props.caseId}|${ReprioritiseCaseCommand.type}`}
    >
      <>
        <Select
          labelId="action-priority-label"
          fullWidth
          value={priority}
          onChange={onChange}
          className={`${classes.priorityField} ${inputClasses.editCaseItemInput}`}
        >
          {Priorities.map((x) => {
            return (
              <MenuItem key={x.value} value={x.value}>
                {x.text}
              </MenuItem>
            );
          })}
        </Select>
      </>
    </FormField>
  );
};

export default PriorityField;
