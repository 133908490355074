import { useCallback, useEffect, useRef, useState } from "react";
import {
  ICaseEvent,
  ICaseEventAttachment,
  IManager,
} from "../../../state/types/TaskCentreCases";
import CaseEvent from "./caseEvent";
import { useTrail, animated } from "react-spring";
import dayjs from "dayjs";
import { CaseEventListStyles } from "../styles/caseEventListStyles";
import objectHash from "object-hash";
interface IProps {
  visitId: number;
  caseId: number;
  caseEvents: ICaseEvent[];
  currentUserId: number;
  managers?: IManager[];
}

const CaseEventList = (props: IProps) => {
  const classes = CaseEventListStyles();
  const autoScrollDestination = useRef<null | HTMLDivElement>(null);
  const [events, setEvents] = useState([] as ICaseEvent[]);

  const sortEvents = (a: ICaseEvent, b: ICaseEvent): number => {
    return dayjs(b.timestamp).isAfter(dayjs(a.timestamp)) ? -1 : 1;
  };

  const animationConfig = {
    mass: 1,
    tension: 1000,
    friction: 180,
  };

  const listItemAnimations = useTrail(
    props.caseEvents.length +
      props.caseEvents.flatMap((x) => x.caseEventAttachments).length,
    {
      config: animationConfig,
      opacity: 1,
      from: { opacity: 0 },
      delay: 0,
    }
  );

  useEffect(() => {
    if (props.caseEvents !== events) {
      setEvents(props.caseEvents);
    }
  }, [events, props.caseEvents]);

  const scrollToMostRecent = () => {
    autoScrollDestination.current?.scrollTo({
      top: autoScrollDestination.current.scrollHeight,
      behavior: "smooth",
    });
  };
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            scrollToMostRecent();
          }
        });
      }).observe(node);
    },
    [scrollToMostRecent]
  );

  useEffect(() => {
    scrollObserver(autoScrollDestination.current);
  }, [events]);

  const renderCaseEvent = (
    caseEvent: ICaseEvent,
    caseEventAttachment: ICaseEventAttachment | undefined,
    index: number
  ) => {
    const hash = objectHash({ caseEvent, caseEventAttachment, index });
    return (
      <>
        <animated.div key={hash} style={listItemAnimations[index]}>
          <CaseEvent
            visitId={props.visitId}
            caseId={props.caseId}
            caseEvent={caseEvent}
            currentUserId={props.currentUserId}
            managers={props.managers ? props.managers : []}
            caseEventAttachment={caseEventAttachment}
          />
        </animated.div>
      </>
    );
  };

  let index = 0;

  return (
    <div className={classes.caseList} ref={autoScrollDestination}>
      {events.sort(sortEvents).flatMap((caseEvent) => {
        const caseEvents = [renderCaseEvent(caseEvent, undefined, index++)];

        if (caseEvent.caseEventAttachments) {
          for (const caseEventAttachment of caseEvent.caseEventAttachments) {
            caseEvents.push(
              renderCaseEvent(caseEvent, caseEventAttachment, index++)
            );
          }
        }

        return caseEvents;
      })}
    </div>
  );
};

export default CaseEventList;
