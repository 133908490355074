import { IFilterOptions } from "../types/FilterOptions";

export const FILTEROPTIONS_PENDING = "FILTEROPTIONS_PENDING";
export const FILTEROPTIONS_SUCCESS = "FILTEROPTIONS_SUCCESS";
export const FILTEROPTIONS_ERROR = "FILTEROPTIONS_ERROR";
export const FETCH_FILTER_OPTIONS = "FETCH_FILTER_OPTIONS";
export const RESET_FILTER_OPTIONS = "RESET_FILTER_OPTIONS";

export interface IFilterOptionsSuccess {
  type: typeof FILTEROPTIONS_SUCCESS;
  filterOptions: IFilterOptions;
}

export function filterOptionsSuccess(
  filterOptions: IFilterOptions
): IFilterOptionsSuccess {
  return {
    type: FILTEROPTIONS_SUCCESS,
    filterOptions: filterOptions,
  };
}

export interface IFilterOptionsPending {
  type: typeof FILTEROPTIONS_PENDING;
}

export function filterOptionsPending(): IFilterOptionsPending {
  return {
    type: FILTEROPTIONS_PENDING,
  };
}

export interface IFilterOptionsError {
  type: typeof FILTEROPTIONS_ERROR;
  error: string;
}

export function filterOptionsError(error: string): IFilterOptionsError {
  return {
    type: FILTEROPTIONS_ERROR,
    error: error,
  };
}

export interface IFetchFilterOptions {
  type: typeof FETCH_FILTER_OPTIONS;
}

export function fetchFilterOptions(): IFetchFilterOptions {
  return {
    type: FETCH_FILTER_OPTIONS,
  };
}

export interface IResetFilterOptions {
  type: typeof RESET_FILTER_OPTIONS;
}

export function resetFilterOptions(): IResetFilterOptions {
  return {
    type: RESET_FILTER_OPTIONS,
  };
}

export type FilterOptionActions =
  | IFilterOptionsPending
  | IFilterOptionsSuccess
  | IFilterOptionsError
  | IFetchFilterOptions
  | IResetFilterOptions;
