import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { IKeyValuePair } from "../../../state/types/FilterSets";
import { ICaseCategory } from "../../../state/types/TaskCentreCases";
import { i18n } from "../../../localizations";

interface IProps {
  availableCategories: ICaseCategory[];
  selectedTaskCategories: IKeyValuePair[];
  applySelectionCallback: (selectedTaskCategories: IKeyValuePair[]) => void;
}

const TaskCentreCategoryPicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(
    [] as IKeyValuePair[]
  );

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedCategories.length === 0 ||
      selectedCategories.length === props.availableCategories.length
    ) {
      selectionText = i18n.translate(
        "TASK_CENTRE_CATEGORY_PICKER_All_Selected"
      );
    } else if (selectedCategories.length === 1) {
      selectionText = selectedCategories[0].value;
    } else {
      selectionText = `${selectedCategories.length} ${i18n.translate(
        "TASK_CENTRE_CATEGORY_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    const categoryNames = selectedCategories.map((x) => x.value);
    return categoryNames.join(", ");
  };

  const applySelection = (selectedOptions: string[]) => {
    const selectedIds = props.availableCategories
      .map((x) => {
        return {
          key: x.id,
          value: x.name,
        } as IKeyValuePair;
      })
      .filter((x) => selectedOptions.indexOf(x.value) > -1);

    props.applySelectionCallback(selectedIds);
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedCategories(props.selectedTaskCategories);
  }, [props.selectedTaskCategories]);

  return (
    <FilterWrapper
      label={i18n.translate("TASK_CENTRE_CATEGORY_PICKER_Category")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      isDisabled={props.availableCategories.length === 0}
    >
      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "TASK_CENTRE_CATEGORY_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={props.availableCategories.map((x) => x.name)}
        selectedOptions={selectedCategories.map((x) => x.value)}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
        applyLocalisationToOptions={false}
        applySort={false}
      />
    </FilterWrapper>
  );
};

export default TaskCentreCategoryPicker;
