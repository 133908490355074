import axios, { AxiosResponse } from "axios";

export interface eLearningModule {
  Id: string;
  Title: string;
  Description: string;
  Section: string;
  Content: eLearningModuleContent[];
}

export interface eLearningModuleContent {
  Title: string;
  ModulePath: string;
}

export const getAccessToken = (
  clientId: number,
  userId: number
): Promise<AxiosResponse<string>> => {
  return axios.get<string>(
    `${process.env.REACT_APP_ELEARNING_API_BASEURL}client/${clientId}/user/${userId}/token`
  );
};

export const getModules = (
  accessToken: string
): Promise<AxiosResponse<eLearningModule[]>> => {
  return axios.get<eLearningModule[]>(
    `${process.env.REACT_APP_ELEARNING_CONTENT_BASEURL}${accessToken}/modules.json`
  );
};
