import { makeStyles, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import i18n from "../../localizations/i18n";
import { routes } from "../../navigation/routes";
import { hgemColours, hgemFont } from "../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  container: {
    padding: "20px",
    display: "block",
    color: hgemColours.DarkGrey,
    fontFamily: hgemFont,
  },
  logo: {
    maxWidth: "250px",
  },
  innerMessage: {
    "& p": {
      marginTop: "0px",
    },

    "& a": {},
  },
}));

const Error = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const buildContactMessage = () => {
    const subtitle = i18n.translate("ERRORPAGE_Subtitle");
    const backButtonHint = i18n.translate("ERRORPAGE_BackButtonHint");
    const resetPrefix = i18n.translate("ERRORPAGE_ResetPrefix");
    const homePageLinkText = i18n.translate("ERRORPAGE_HomePage_Text");
    const contactMessage = i18n.translate("ERRORPAGE_Alternative_links");
    const contactLink = i18n.translate("ERRORPAGE_Alternative_contact_page");

    return (
      <div className={classes.innerMessage}>
        <p>{subtitle}</p>
        <p>
          {backButtonHint}
          <a href={routes.resetSession}>{homePageLinkText}</a>
          {resetPrefix}
        </p>
        <p>
          {contactMessage}
          <a href={`mailto:${contactLink}`}>{contactLink}</a>
        </p>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="/hub_logo_header_alt.png"
        alt="HGEM Logo"
      />
      <div>
        <h2>{i18n.translate("ERRORPAGE_Title")}</h2>
        {buildContactMessage()}
      </div>
    </div>
  );
};

export default Error;
