import axios, { AxiosResponse } from "axios";
import { IdAndName } from "../types/UserImpersonation";

export const managerListApi= async (
  clientId: number
): Promise<AxiosResponse<IdAndName[]>> => {
  return axios.get<IdAndName[]>(
    `${process.env.REACT_APP_HUB_API_BASEURL}managerAccess/client/${clientId}/managers`
  );
};
