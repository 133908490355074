import React from "react";
import { Icon } from "@material-ui/core";
import { Priority } from "../../../state/types/TaskCentreCases";
import { hgemColours } from "../../../themes/defaultTheme";

interface IProps {
  priority: Priority;
}

const PriorityIcon = (props: IProps) => {
  const getClassName = () => {
    switch (props.priority) {
      case Priority.Low:
        return "priority_low";
      case Priority.Normal:
        return "priority_normal";
      case Priority.High:
        return "priority_high";
      default:
        return "";
    }
  };

  return (
    <Icon
      className={`icon fas fa-flag ${getClassName()}`}
      style={{ color: hgemColours.DarkGrey }}
    />
  );
};

export default PriorityIcon;
