import { useTheme, makeStyles, Theme } from "@material-ui/core";
import FilterWrapper from "./filterWrapper";
import { i18n } from "../../../localizations";

interface IProps {
  label: string;
  retry: () => void;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  filterWrapper: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "5px",

    "& button": {},
  },
  error: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    color: `red`,
    fontSize: "10px",
    fontWeight: 300,
    marginLeft: "3%",
  },
}));

const ErrorFilter = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.filterWrapper}>
      <FilterWrapper
        label={props.label}
        isOpen={false}
        toggleOpenState={props.retry}
        isDisabled={false}
        children={undefined}
        error={true}
        tooltip={i18n.translate("FILTERS_RETRY")}
      />
    </div>
  );
};

export default ErrorFilter;
