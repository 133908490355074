import React, { Ref } from "react";
import { withStyles } from "@material-ui/core";
import PowerBIRenderUtils, {
  IVisualExportTarget,
} from "../utils/pbiReportRendering";
import { IHubFilter, IFilterSet } from "../../../state/types/FilterSets";
import RenderErrorMessage from "./renderErrorMessage";
import ReportDataExport from "./reportDataExport";
import { ReportRenderStyles } from "../styles/reportRenderStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

interface IProps {
  classes: ClassNameMap<string>;
  pageInternalRef: string;
  reportId: string;
  reportUrl: string;
  accessToken: string;
  clientId: number;
  selectedBrands: Array<string> | undefined;
  availableLocationIds: number[];
  applyDatesAsPageFilters: boolean;
  filters: IHubFilter[];
  filterSet: IFilterSet;
  width: number;
  height: number;
  exportableVisualTypes: string[];
  exportableVisualNames?: string[];
  exportIsOpen: boolean;
  ref?: Ref<IReportRenderFunctions>;
  onReportPagesLoaded?: (pages: string[], defaultPage: string) => void;
  onReportLoaded?: () => void;
  onExportClose?: () => void;
}

interface IState {
  reportLoading: boolean;
  hasError: boolean;
  reportPages: string[];
  initialReportPage: string;
  accessToken: string;
  reportId: string;
  clientId: number;
  brands: string[] | undefined;
  availableLocationIds: number[];
  filters: IHubFilter[];
  dataExportTargets: IVisualExportTarget[];
  showDataExport: boolean;
}

export interface IReportRenderFunctions {
  ChangeReportPage: (pageName: string) => void;
}

class ReportRender
  extends React.Component<IProps, IState, unknown>
  implements IReportRenderFunctions
{
  private componentMounted = false;
  private reportContainerRef = React.createRef<HTMLDivElement>();
  private pbiRenderUtils: PowerBIRenderUtils | null;

  constructor(props: IProps) {
    super(props);

    this.pbiRenderUtils = new PowerBIRenderUtils(
      this.reportContainerRef,
      this.reportLoaded,
      this.pagesLoaded,
      this.reportError
    );

    this.state = {
      reportLoading: true,
      hasError: false,
      reportPages: [],
      initialReportPage: "",
      accessToken: "",
      reportId: "",
      clientId: 0,
      brands: [],
      availableLocationIds: [],
      filters: [],
      dataExportTargets: [],
      showDataExport: false,
    } as IState;
  }

  componentDidMount() {
    this.componentMounted = true;

    this.pbiRenderUtils?.renderReport(
      this.props.reportId,
      this.props.reportUrl,
      this.props.accessToken,
      this.props.clientId,
      this.props.selectedBrands,
      this.props.availableLocationIds,
      this.props.filters,
      false,
      this.props.applyDatesAsPageFilters
    );

    this.setState({
      reportLoading: true,
      hasError: false,
      reportPages: [],
      initialReportPage: "",
      accessToken: this.props.accessToken,
      reportId: this.props.reportId,
      clientId: this.props.clientId,
      brands: this.props.selectedBrands,
      availableLocationIds: this.props.availableLocationIds,
      filters: this.props.filters,
    });
  }

  componentDidUpdate() {
    if (this.props.reportId !== this.state.reportId) {
      this.pbiRenderUtils?.renderReport(
        this.props.reportId,
        this.props.reportUrl,
        this.props.accessToken,
        this.props.clientId,
        this.props.selectedBrands,
        this.props.availableLocationIds,
        this.props.filters,
        false,
        this.props.applyDatesAsPageFilters
      );

      this.setState({
        reportLoading: true,
        hasError: false,
        reportPages: [],
        accessToken: this.props.accessToken,
        reportId: this.props.reportId,
        clientId: this.props.clientId,
        brands: this.props.selectedBrands,
        availableLocationIds: this.props.availableLocationIds,
        filters: this.props.filters,
      });
    } else {
      if (this.props.accessToken !== this.state.accessToken) {
        this.pbiRenderUtils?.updateAccessToken(this.props.accessToken);
        this.setState({
          accessToken: this.props.accessToken,
        });
      }

      if (this.props.filters !== this.state.filters) {
        this.pbiRenderUtils?.reapplyFilters(
          this.state.clientId,
          this.state.brands,
          this.props.availableLocationIds,
          this.props.filters,
          this.props.applyDatesAsPageFilters
        );
        this.setState({
          filters: this.props.filters,
        });
      }

      if (
        this.props.selectedBrands !== this.state.brands ||
        this.props.availableLocationIds !== this.state.availableLocationIds
      ) {
        this.pbiRenderUtils?.reapplyFilters(
          this.state.clientId,
          this.props.selectedBrands,
          this.props.availableLocationIds,
          this.props.filters,
          this.props.applyDatesAsPageFilters
        );
        this.setState({
          availableLocationIds: this.props.availableLocationIds,
          brands: this.props.selectedBrands,
        });
      }

      if (this.props.exportIsOpen !== this.state.showDataExport) {
        this.setState({
          showDataExport: this.props.exportIsOpen
            ? this.props.exportIsOpen
            : false,
        });

        if (this.props.exportIsOpen) {
          this.LaunchDataExport();
        }
      }
    }
  }

  componentWillUnmount() {
    this.componentMounted = false;
    this.pbiRenderUtils?.cleanup();
    this.pbiRenderUtils = null;
  }

  public ChangeReportPage = (pageName: string) => {
    if (this.componentMounted && !this.state.reportLoading) {
      setTimeout(() => {
        this.pbiRenderUtils?.setCurrentPage(pageName);
      }, 250);
    }
  };

  private LaunchDataExport = (): void => {
    if (this.componentMounted) {
      this.pbiRenderUtils
        ?.getDataExportTargets(
          this.props.exportableVisualTypes,
          this.props.exportableVisualNames
        )
        .then((visuals) => {
          this.setState({
            dataExportTargets: visuals,
          });
        });
    }
  };

  public CloseDataExport = () => {
    this.setState({
      dataExportTargets: [],
    });
    if (this.props.onExportClose) {
      this.props.onExportClose();
    }
  };

  private RunDataExport = (visualName: string): Promise<string> | undefined => {
    return this.pbiRenderUtils?.getDataExport(visualName);
  };

  private reportLoaded = (): void => {
    if (this.componentMounted) {
      this.setState({
        reportLoading: false,
      });

      if (this.props.onReportLoaded) {
        this.props.onReportLoaded();
      }
    }
  };

  private pagesLoaded = (pages: string[], defaultPage: string): void => {
    if (this.componentMounted) {
      this.setState({
        reportPages: pages,
        initialReportPage: defaultPage,
      });

      if (this.props.onReportPagesLoaded) {
        this.props.onReportPagesLoaded(pages, defaultPage);
      }
    }
  };

  private reportError = (): void => {
    if (this.componentMounted) {
      this.setState({
        hasError: true,
      });
    }
  };

  render() {
    return (
      <div className={this.props.classes.scrollContainer}>
        <div className={this.props.classes.outer}>
          {!this.state.hasError && (
            <div
              style={{ height: this.props.height, width: "100%" }}
              id={`report-container-for-${this.props.reportId}`}
              className={`hub-print-report-container ${this.props.classes.container}`}
              ref={this.reportContainerRef}
              data-report-loading={this.state.reportLoading}
            ></div>
          )}

          {this.state.hasError && <RenderErrorMessage />}

          {this.state.dataExportTargets && this.state.showDataExport && (
            <ReportDataExport
              sourceVisuals={this.state.dataExportTargets}
              onClose={this.CloseDataExport}
              runExport={this.RunDataExport}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(ReportRenderStyles, { withTheme: true })(
  ReportRender
);
