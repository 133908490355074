import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  emptyTasksMessage: {
    margin: "0 20px 10px 0",

    [theme.breakpoints.down("md")]: {
      margin: "0 0 10px 0",
    },
  },
}));

export const CaseListEmptyStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
