import axios, { AxiosResponse } from "axios";
import { Logging, SeverityLevel } from "../../../utils/logging";
import { AuthServiceInstance } from "../../../authentication/authService";

export const UnavailableStatus = "unavailable";
export const ThumbnailSize = "200x200";
export const FullSize = "1280x720";

export type VisitPhotoMetadata = {
  dateTaken: Date | undefined,
  latitude: number | undefined,
  longitude: number | undefined,
  address: string | undefined 
};

export type VisitPhotoData = {
  data: string,
  metadata: VisitPhotoMetadata | undefined
};

export const getPhotoData = async (
  visitId: number,
  fileName: string,
  asThumbnail = true
): Promise<VisitPhotoData> => {
  const clientId: number = await AuthServiceInstance.getClientId();
  const path = `${process.env.REACT_APP_VISIT_MEDIA_API_BASEURL}visitphotos/${clientId}/${visitId}/photo/${fileName}`;

  return asThumbnail ? callGetPhotoApi(`${path}/${ThumbnailSize}`) : callGetPhotoApi(`${path}/${FullSize}`);
};

export const getAudioData = async (
  visitId: number,
  fileName: string
): Promise<string> => {
  const clientId: number = await AuthServiceInstance.getClientId();
  const path = `${process.env.REACT_APP_VISIT_MEDIA_API_BASEURL}visitphotos/${clientId}/${visitId}/audio/${fileName}`;

  return callGetBlobApi(path);
};

export const getReceiptData = async (
  visitId: number,
  fileName: string
): Promise<string> => {
  const clientId: number = await AuthServiceInstance.getClientId();
  const path = `${process.env.REACT_APP_VISIT_MEDIA_API_BASEURL}visitreceipts/${clientId}/${visitId}/receipt/${fileName}`;

  return callGetBlobApi(path);
};

const readBlobData = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise<string>((x) => {
    reader.onloadend = () => {
      x(
        reader.result !== null
          ? reader.result.toString()
          : UnavailableStatus
      );
    };
  });
};

const callApi = (path: string): Promise<AxiosResponse<Blob>> => {
  return axios.get<Blob>(path, { responseType: "blob" });
};

const callGetBlobApi  = (path: string): Promise<string> => {
  return callApi(path)
    .then(response => readBlobData(response.data))
    .catch((error) => {
      logError(error);
      return UnavailableStatus;
    });
};

const callGetPhotoApi = async (path: string): Promise<VisitPhotoData> => {
  try {
    const response = await callApi(path);

    const blobData = await readBlobData(response.data);

    return {
      data: blobData,
      metadata: {
        dateTaken: response.headers["hgem_datetaken"],
        latitude: response.headers["hgem_latitude"],
        longitude: response.headers["hgem_longitude"],
        address: response.headers["hgem_address"]
      }
    };
  }
  catch (error) {
    logError(error as string);
    return {
      data: UnavailableStatus,
      metadata: undefined
    };
  }
}

const logError = (error: string) => {
  Logging.captureError(
    "Failed to fetch visit media",
    new Error(error),
    SeverityLevel.Warning
  );
};
