import React from "react";
import { WorkflowStatus } from "../../../state/types/TaskCentreCases";
import { Icon } from "@material-ui/core";

interface IProps {
  status: WorkflowStatus;
  monochrome?: boolean;
  className?: string;
}

const WorkflowStatusIcon = (props: IProps) => {
  const getClassName = () => {
    switch (props.status) {
      case WorkflowStatus.Discussion:
        return "workflow-status_no-action fas fa-comments";
      case WorkflowStatus.ActionRequired:
        return "workflow-status_required fas fa-exclamation-circle";
      case WorkflowStatus.InProgress:
        return "workflow-status_open fas fa-clock";
      case WorkflowStatus.ActionTaken:
        return "workflow-status_closed fas fa-check-circle";
      case WorkflowStatus.DoNotAction:
        return "workflow-status_no-action fas fa-ban";
      default:
        return "";
    }
  };

  const monochromeClassName = props.monochrome
    ? "workflow-status-monochrome"
    : "";

  return (
    <Icon
      className={`icon ${getClassName()} ${monochromeClassName} ${
        props.className
      }`}
    />
  );
};

export default WorkflowStatusIcon;
