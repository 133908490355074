import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IHubFilter } from "../../state/types/FilterSets";
import {
  TaskCentreCaseListError,
  TaskCentreCaseListPending,
  AvailableCases,
  CasesLastRefreshed,
  CurrentSearchText,
  GetCurrentFilterSet,
  GetUserCurrentBrands,
  GetVisibleFilters,
  MoreCasesAvailable,
  SelectedCaseId,
  TotalCaseCount,
} from "./state/selectors";
import { InboxTasksListStyles } from "./styles/inboxTasksListStyles";
import CaseList from "./components/caseList";
import {
  clearTaskCentreCaseList,
  fetchTaskCentreCaseList,
  setSelectedTaskCentreCase,
} from "../../state/actions/TaskCentre-CaseList-Actions";
import { RootState } from "../../state/reducers/RootReducer";
import BuildCaseListQuery from "./utils/taskCentreQueryBuilder";
import CaseListSkeleton from "./components/caseListSkeleton";
import CaseListError from "./components/caseListError";
import CaseListEmpty from "./components/caseListEmpty";
import { setCurrentFilterSetToDefault } from "../../state/actions/FilterSets-Actions";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import i18n from "../../localizations/i18n";
import { Skeleton } from "@material-ui/lab";
import TaskCard from "./components/taskCard";
import getBorderColourThresholds from "../../utils/borderColourThresholds";
import { ClientBorderThreshold } from "../../state/api";
import { useClientId } from "../../state/hooks/clients/useClientId";

interface IProps {
  showAsPopupList?: boolean;
  applicableVisitTypes: string[];
  requestedVisitId?: number | undefined;
}

const InboxTasksList = (props: IProps) => {
  const classes = InboxTasksListStyles();
  const taskCentrePageRef = "Tasks";
  const clientId = useClientId();
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState([] as IHubFilter[]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [popupListOpen, setPopupListOpen] = useState(false);
  const [actionsListPageNumber, setTasksListPageNumber] = useState<number>(0);
  const usersCurrentBrands = useSelector(GetUserCurrentBrands);
  const currentFilterSet = useSelector(GetCurrentFilterSet);
  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, taskCentrePageRef)
  );

  const taskCentreCases = useSelector(AvailableCases);
  const selectedCaseId = useSelector(SelectedCaseId);
  const casesTotalCount = useSelector(TotalCaseCount);
  const casesLastRefreshed = useSelector(CasesLastRefreshed);
  const moreCasesAvailable = useSelector(MoreCasesAvailable);
  const caseListPending = useSelector(TaskCentreCaseListPending);
  const caseListError = useSelector(TaskCentreCaseListError);
  const appliedSearchText = useSelector(CurrentSearchText);

  const applyCaseSelection = (caseId: number): void => {
    if (caseId > 0) {
      setPopupListOpen(false);
      dispatch(setSelectedTaskCentreCase(caseId));
    }
  };

  const loadAdditionalCases = (): void => {
    setTasksListPageNumber(actionsListPageNumber + 1);
  };

  useEffect(() => {
    const query = BuildCaseListQuery(
      actionsListPageNumber,
      filters,
      usersCurrentBrands,
      props.applicableVisitTypes,
      undefined
    );
    dispatch(fetchTaskCentreCaseList(query));
  }, [actionsListPageNumber]);

  useEffect(() => {
    if (!currentFilterSet) {
      dispatch(setCurrentFilterSetToDefault(taskCentrePageRef));
    }
  }, [currentFilterSet, dispatch, taskCentrePageRef]);

  useEffect(() => {
    if (
      currentFilterSet &&
      (casesLastRefreshed === undefined ||
        filterState !== filters ||
        selectedBrands !== usersCurrentBrands)
    ) {
      dispatch(clearTaskCentreCaseList());

      const query = BuildCaseListQuery(
        actionsListPageNumber,
        filters,
        usersCurrentBrands,
        props.applicableVisitTypes,
        props.requestedVisitId
      );

      setFilterState(filters);
      setSelectedBrands(usersCurrentBrands);
      dispatch(fetchTaskCentreCaseList(query));
    }
  }, [
    taskCentreCases,
    casesLastRefreshed,
    currentFilterSet,
    dispatch,
    filterState,
    filters,
    props.applicableVisitTypes,
    props.requestedVisitId,
    selectedBrands,
    selectedCaseId,
    usersCurrentBrands,
  ]);

  const refs = props.showAsPopupList
    ? taskCentreCases.reduce((acc: any, value) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {} as any)
    : ({} as any);

  const scrollToSelected = (): void => {
    if (selectedCaseId && refs) {
      refs[selectedCaseId].current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };
  const [borderColorThresholds, setBorderColorThresholds] =
    useState<ClientBorderThreshold>();
  useEffect(() => {
    const fetchThresholds = async () => {
      const borderThresholds = await getBorderColourThresholds(clientId);
      setBorderColorThresholds(borderThresholds);
    };
    fetchThresholds();
  }, [clientId]);

  const caseList = (): JSX.Element => {
    return (
      <CaseList
        fetchMoreCases={loadAdditionalCases}
        moreCasesAvailable={moreCasesAvailable || false}
        cases={taskCentreCases}
        isLoading={caseListPending}
        skeleton={<CaseListSkeleton />}
        caseView={(caseDetails) => {
          return (
            <div key={caseDetails.id} ref={refs[caseDetails.id]}>
              <TaskCard
                caseDetails={caseDetails}
                isSelected={caseDetails.id === selectedCaseId}
                onItemSelected={applyCaseSelection}
                borderColourThresholds={borderColorThresholds}
              />
            </div>
          );
        }}
        emptyCase={<CaseListEmpty />}
        showFilter={true}
        appliedSearchText={appliedSearchText}
        totalCasesLoaded={casesTotalCount}
      />
    );
  };

  const popupSelectorLabel = () => {
    let labelText = i18n.translate("TASK_CENTRE_LIST_NoTaskSelected");

    if (!caseListPending && taskCentreCases && selectedCaseId) {
      const selectedTask = taskCentreCases.find((x) => x.id === selectedCaseId);
      if (selectedTask) {
        labelText = `${selectedTask.title}`;
      }
    }

    return labelText;
  };

  return (
    <div className={classes.listContainer}>
      {caseListError && !caseListPending && <CaseListError />}
      {props.showAsPopupList && !caseListPending && (
        <>
          <Button
            className={classes.popupSelection}
            onClick={() => setPopupListOpen(true)}
          >
            <span>
              {i18n.translate("TASK_CENTRE_LIST_Select_Task")}
              <div className={classes.popupSelectionHint}>
                {popupSelectorLabel()}
              </div>
            </span>
          </Button>
          <Dialog
            className={classes.popupSelectionDialog}
            fullWidth
            onClose={() => setPopupListOpen(false)}
            onEntered={() => scrollToSelected()}
            open={popupListOpen}
          >
            <DialogContent>{caseList()}</DialogContent>
          </Dialog>
        </>
      )}

      {props.showAsPopupList && caseListPending && (
        <>
          <Skeleton
            className={classes.loader}
            variant="rect"
            height={40}
            animation="wave"
          />
        </>
      )}

      {!props.showAsPopupList && caseList()}
    </div>
  );
};

export default InboxTasksList;
