import { RootState } from "../../state/reducers/RootReducer";
import { defaultTheme } from "../defaultTheme";
import { wagamamaTheme } from "../wagamamaTheme";
import { pizzaExpressTheme } from "../pizzaExpressTheme";
import { coteTheme } from "../coteTheme";
import { ClientName, clientSelector } from "../utils/clientSelector";

export const HubThemeSelector = (state: RootState) => {
  const clientId = state.UserProfileReducer.userProfile?.currentClient;

  if (clientId) {
    const client = clientSelector(clientId);

    switch (client) {
      case ClientName.Wagamama:
        return wagamamaTheme;
      case ClientName.Cote:
        return coteTheme;
      case ClientName.PizzaExpress:
        return pizzaExpressTheme;
      default:
        return defaultTheme;
    }
  }

  return defaultTheme;
};
