import dayjs from "dayjs";
import { IVisitDetail } from "../state/types/VisitDetails";

export const getVisitDetailDateTime = (visitDetail: IVisitDetail) => {
    const [date, time] = [dayjs(visitDetail.date), dayjs(visitDetail.time)];

    return date
        .add(time.hour(), "hour")
        .add(time.minute(), "minute")
        .add(time.second(), "second")
        .add(time.millisecond(), "millisecond");
};