import React, { useState } from "react";
import { getTaskEventAttachmentApi } from "../../../state/api/TaskCentre-API";
import { useClientId } from "../../../state/hooks/clients/useClientId";
import {
  ICaseEvent,
  ICaseEventAttachment,
} from "../../../state/types/TaskCentreCases";
import {
  MediaMessageContent,
  MediaMessageSideContent,
  useMedia,
} from "../../inboxViews/components/mediaMessages";
import { MediaLinks } from "../../inboxViews/components/mediaMessages/types/mediaLinks";
import { MessageSentStyles } from "../styles/messageSentStyles";

interface IProps {
  visitId: number;
  caseId: number;
  caseEvent: ICaseEvent;
  caseEventAttachment: ICaseEventAttachment | undefined;
  eventHeader: React.ReactElement;
}

const MessageSent = (props: IProps) => {
  return props.caseEventAttachment ? (
    <MessageSentAttachment
      visitId={props.visitId}
      caseId={props.caseId}
      caseEventEventAttachmentId={props.caseEventAttachment.id}
      filepath={props.caseEventAttachment.filepath}
      subject={props.caseEvent.subject}
      eventHeader={props.eventHeader}
    />
  ) : (
    <MessageSentMessage
      subject={props.caseEvent.subject}
      message={props.caseEvent.message}
      eventHeader={props.eventHeader}
    />
  );
};

type MessageSentMessageProps = {
  subject: string | undefined;
  message: string | undefined;
  eventHeader: React.ReactElement;
};

const MessageSentMessage = (props: MessageSentMessageProps) => {
  const classes = MessageSentStyles();

  return (
    <div className={classes.messageSentContainer}>
      {props.eventHeader}
      <div>
        <span className={classes.message}>
          <span
            className={`icon far fa-envelope ${classes.messageIcon}`}
          ></span>
          <div className={classes.messageContent}>
            <div className={classes.messageSubject}>{props.subject}</div>
            <div className={classes.messageText}>{props.message}</div>
          </div>
        </span>
      </div>
    </div>
  );
};

type MessageSentAttachmentProps = {
  visitId: number;
  caseId: number;
  caseEventEventAttachmentId: number;
  filepath: string;
  subject: string | undefined;
  eventHeader: React.ReactElement;
};

const MessageSentAttachment = (props: MessageSentAttachmentProps) => {
  const classes = MessageSentStyles();

  const clientId = useClientId();

  const [mediaLinks, setMediaLinks] = useState<MediaLinks | undefined>();
  const [isDownloading, setIsDownloading] = useState(false);

  const media = useMedia({
    filename: props.filepath,
    getMedia: clientId
      ? () =>
          getTaskEventAttachmentApi(
            clientId,
            props.visitId,
            props.caseId,
            props.caseEventEventAttachmentId,
            props.filepath
          )
      : undefined,
    mediaLinks: [mediaLinks, setMediaLinks],
    isDownloading: [isDownloading, setIsDownloading],
  });

  return (
    <>
      <div className={classes.messageSentMediaContainer}>
        <div>
          {props.eventHeader}
          <div className={classes.message}>
            <span
              className={`icon far fa-envelope ${classes.messageIcon}`}
            ></span>
            <div>
              <div className={classes.messageSubject}>{props.subject}</div>
              <div className={classes.messageMedia}>
                <MediaMessageContent
                  shouldRenderMedia={media.shouldRenderMedia}
                  filename={props.filepath}
                  contentType={media.contentType}
                  download={() => setIsDownloading(true)}
                  mediaLinks={media.links}
                />
              </div>
            </div>
          </div>
        </div>
        <span className={classes.outBound}>
          <MediaMessageSideContent
            isDownloading={isDownloading}
            download={() => setIsDownloading(true)}
          />
        </span>
      </div>
    </>
  );
};

export default MessageSent;
