import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { i18n } from "../../../localizations";

interface IProps {
    availableCategories: string[];
    selectedCategories: string[];
    applySelectionCallback: (selectedCategories: string[]) => void;
}

const QuestionCategoryPicker = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(
      [] as string[]
    );
  
    const handleToggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    const getAppliedSelectionText = (): string => {
      let selectionText = "";
      if (
        selectedCategories.length === 0 ||
        selectedCategories.length ===
          props.availableCategories.length
      ) {
        selectionText = i18n.translate("QUESTION_CATEGORY_PICKER_All_Selected");
      } else if (selectedCategories.length === 1) {
        selectionText = selectedCategories[0];
      } else {
        selectionText = `${selectedCategories.length} ${i18n.translate(
          "QUESTION_CATEGORY_PICKER_Selected"
        )}`;
      }
  
      return selectionText;
    };
  
    const buildTooltipContent = (): string => {
      return selectedCategories.join(", ");
    };
  
    const applySelection = (selectedOptions: string[]) => {
      props.applySelectionCallback(selectedOptions);
      setIsOpen(false);
    };
  
    const cancelSelection = () => {
      setIsOpen(false);
    };
  
    useEffect(() => {
      setSelectedCategories(props.selectedCategories);
    }, [setSelectedCategories, props.selectedCategories]);
  
    return (
      <FilterWrapper
        label={i18n.translate("QUESTION_CATEGORY_PICKER_Question_Categrory")}
        selectionHint={getAppliedSelectionText()}
        isOpen={isOpen}
        toggleOpenState={handleToggleOpen}
        tooltip={buildTooltipContent()}
        isDisabled={props.availableCategories.length === 0}
      >
        <GenericMultiSelect
          useSelectAllOption={false}
          selectAllOptionText={i18n.translate(
            "QUESTION_CATEGORY_PICKER_Select_All_Option"
          )}
          multiSelect={true}
          options={props.availableCategories}
          selectedOptions={selectedCategories}
          applySelectionCallback={applySelection}
          cancelSelectionCallback={cancelSelection}
          applyLocalisationToOptions={false}
        />
      </FilterWrapper>
    );
}

export default QuestionCategoryPicker;