import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { IFilterSetsState } from "../../../state/reducers/FilterSets-Reducer";
import { applyFilterAvailabilityRules } from "../../_common/filters/utils/hubFilterAvailability";
import PbiContainerSizing from "../../../utils/pbiContainerSizing";
import { IReportViewerState } from "../../../state/reducers/ReportViewer-Reducer";

export const ReportConfigurationByRef = createSelector(
  (state: RootState) => state.ReportConfigurationReducer,
  (_: RootState, reference: string) => reference,
  (state, reference) => {
    return state.reportConfiguraton.reportDetails.find(
      (x) => x.internalRef?.toLowerCase() === reference.toLowerCase()
    );
  }
);

export const GetReportExportableVisualTypes = createSelector(
  (state: RootState) => state.ReportConfigurationReducer,
  (_: RootState, reference: string) => reference,
  (state, reference) => {
    const reportConfig = state.reportConfiguraton.reportDetails.find(
      (x) => x.internalRef?.toLowerCase() === reference.toLowerCase()
    );

    if (reportConfig) {
      return reportConfig.allowExportFromVisualTypes;
    } else {
      return [] as string[];
    }
  }
);

export const GetReportExportableVisualNames = createSelector(
  (state: RootState) => state.ReportConfigurationReducer,
  (_: RootState, reference: string) => reference,
  (state, reference) => {
    const reportConfig = state.reportConfiguraton.reportDetails.find(
      (x) => x.internalRef?.toLowerCase() === reference.toLowerCase()
    );

    if (reportConfig) {
      return reportConfig.onlyAllowExportFromNamedVisuals;
    } else {
      return [] as string[];
    }
  }
);

export const ReportAccessTokenByReportAndDataset = createSelector(
  (state: RootState) => state.ReportConfigurationReducer,
  (
    _: RootState,
    reportId: string | undefined,
    datasetId: string | undefined
  ) => [reportId, datasetId],
  (state, [reportId, datasetId]): string => {
    const matchedToken = state.reportConfiguraton.accessTokens.find(
      (x) =>
        x.applicableDatasets.indexOf(
          datasetId ? datasetId : "_unknown_dataset_"
        ) > -1 &&
        x.applicableReports.indexOf(reportId ? reportId : "_unknown_report_") >
          -1
    );

    return matchedToken ? matchedToken.token : "";
  }
);

export const GetCurrentFilterSet = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (state: IFilterSetsState) => {
    return state.currentFilterSet;
  }
);

export const GetFilters = createSelector(
  (state: RootState) => state.FilterSetsReducer,
  (_: RootState, pageRef: string) => pageRef,
  (state, pageRef) => {
    const filters = state.currentFilters;
    const filterAvailability = state.filterSetConfiguration.filterAvailability;

    return applyFilterAvailabilityRules(filters, filterAvailability, pageRef);
  }
);

export const GetLayout = createSelector(
  (state: RootState) => state.ReportConfigurationReducer,
  (_: RootState, pageRef: string, pageWidth: string) => [pageRef, pageWidth],
  (state, [pageRef, pageWidth]) => {
    const reportConfiguration = state.reportConfiguraton.reportDetails.find(
      (x) => x.internalRef?.toLowerCase() === pageRef.toLowerCase()
    );

    const width = Number.parseInt(pageWidth);

    if (reportConfiguration && reportConfiguration.layout && !isNaN(width)) {
      const pbiContainerSizing: PbiContainerSizing = new PbiContainerSizing();
      return pbiContainerSizing.getContainerLayout(
        reportConfiguration?.layout?.width,
        reportConfiguration?.layout?.height,
        width
      );
    } else {
      return undefined;
    }
  }
);

export const GetUserCurrentBrands = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.currentBrands
    : [];
};

export const GetUserCurrentClient = (state: RootState) => {
  return state.UserProfileReducer.userProfile
    ? state.UserProfileReducer.userProfile.currentClient
    : 0;
};

export const GetCurrentPage = createSelector(
  (state: RootState) => state.ReportViewerReducer,
  (state: IReportViewerState) => {
    return state.currentPage;
  }
);

export const GetDataExportIsOpen = (state: RootState) => {
  return state.ReportViewerReducer.dataExportOpen;
};

export const AvailableLocationIdsByBrand = createSelector(
  (state: RootState) => state.FilterOptionsReducer,
  (_: RootState, brands: string[]) => brands,
  (state, brands) => {
    if (brands && brands.length > 0){
      return state.filterOptions.locationOptions
        .filter(x => x.brand !== undefined && brands.indexOf(x.brand) > -1)
        .map(x => x.branchId);
    }
    else {
      return state.filterOptions.locationOptions.map(x => x.branchId);
    }
  }
);
