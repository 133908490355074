import dayjs from "dayjs";

const config_refreshMinsDefault = "45";
const config_refreshMins =
  process.env.REACT_APP_POWERBI_REFRESH_TOKEN_AFTER_MINUTES_USED;

export const isTokenCloseToExpiration = (
  tokenExpiresAt: Date | undefined
): boolean => {
  let closeToExpiration = false;
  const tokenRefreshesAfterMinutes = Number.parseInt(
    config_refreshMins ? config_refreshMins : config_refreshMinsDefault
  );

  if (tokenExpiresAt !== undefined) {
    const timeNow = dayjs();
    if (timeNow.add(60 - tokenRefreshesAfterMinutes, "m").isAfter(tokenExpiresAt)) {
      closeToExpiration = true;
    }
  }

  return closeToExpiration;
};
