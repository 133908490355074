import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  miniCard: {
    border: `1px solid ${hgemColours.LightGrey}`,
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    margin: "3% 0 3% 0",
    padding: "5%",
  },
  headerText: {
    color: hgemColours.DarkGrey,
    fontSize: "1em",
  },
  bodyText: {
    fontSize: "75%",
    color: hgemColours.ExtraMediumGrey,
  },
  trimText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  accordionRow: {
    borderRadius: "4px",
    margin: "0.25% 0.25% 0 0.25%",
    padding: "0.25% 0.25% 0 0.25%",
    boxShadow: "none",
  },
  scrollingDiv: {
    height: "40%",
    overflowX: "scroll",
  },

  relatedRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "baseline",
    padding: "1% 0% 1% 0%",
  },
  newTabIcon: {
    overflow: "visible",
    color: hgemColours.ExtraMediumGrey,
    "&:hover": {
      color: hgemColours.LimeGreen,
    },
  },
  messageReceived: {
    width: "90%",
    float: "left",
    borderRadius: "4px",
    border: "none !important",
    boxShadow: "none !important",
  },
  messageSent: {
    borderRadius: "4px",
    width: "90%",
    float: "right",
    border: "none !important",
    boxShadow: "none !important",
  },
  inboxIcon: {
    overflow: "visible",
    opacity: "0.70",
    fontSize: "1.5em",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  dateLine: {
    fontSize: "0.9em",
    color: lighten(hgemColours.DarkGrey, 0.2),
  },
  messageSubject: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7em",
    },
  },
}));

export const GuestContactStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
