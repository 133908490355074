import { createSelector } from "reselect";
import { RootState } from "../reducers/RootReducer";
import { IVisitDetailsState } from "../reducers/VisitDetails-Reducer";

export const VisitDetailsQuery = createSelector(
    (state: RootState) => state.VisitDetailsReducer,
    (state: IVisitDetailsState) => {
      return state.query;
    }
  );

  export const VisitDetails = createSelector(
    (state: RootState) => state.VisitDetailsReducer,
    (state: IVisitDetailsState) => {
      return state.visitDetails;
    }
  );

  export const VisitDetailsCount = createSelector(
    (state: RootState) => state.VisitDetailsReducer,
    (state: IVisitDetailsState) => {
      return state.totalCount;
    }
  );
