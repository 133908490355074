import { useDispatch, useSelector } from "react-redux";
import { IStateObserver, IStateObserverProps } from "./IStateObserver";
import {
  FilterSetsErrors,
  FilterSetsLastUpdated,
  FilterSetsLoaded,
} from "../state/selectors";
import {
  filterSetsError,
  filterSetsPending,
  filterSetsSuccess,
} from "../../../state/actions/FilterSets-Actions";
import {
  filterAvailabilityApi,
  filterSetsApi,
} from "../../../state/api/FilterSetsApi";
import { IFilterSetConfiguration } from "../../../state/types/FilterSets";
import { deserialiseFilterSets } from "../../../utils/hubFilterInitialiser";

const useFilterSetsObserver = () => {
  const dispatch = useDispatch();

  const filterSetsLoaded = useSelector(FilterSetsLoaded);
  const filterSetsErrors = useSelector(FilterSetsErrors);
  const filterSetsLastUpdated = useSelector(FilterSetsLastUpdated);

  const stateRequiresRefresh = async (): Promise<boolean> => {
    return !filterSetsLoaded || filterSetsErrors.length > 0;
  };

  const fetchFilterSets = async (props: IStateObserverProps) => {
    const { clientId } = props;

    dispatch(filterSetsPending());

    try {
      const filterSetsResponse = await filterSetsApi(clientId);

      const filterSetsAvailabilityResponse = await filterAvailabilityApi(
        clientId
      );

      const filterSetConfig: IFilterSetConfiguration = {
        filterSets: deserialiseFilterSets(filterSetsResponse.data.filterSets),
        filterAvailability:
          filterSetsAvailabilityResponse.data.filterAvailability,
        lastUpdated: new Date(),
      };

      dispatch(filterSetsSuccess(filterSetConfig));
    } catch (e) {
      dispatch(filterSetsError("Failed to fetch filter sets"));
      throw e;
    }
  };

  const observer: IStateObserver = {
    name: "useFilterSetsObserver",
    isInitialised: filterSetsLoaded ? filterSetsLoaded : false,
    hasErrors: filterSetsErrors.length > 0,
    lastRefresh: filterSetsLastUpdated,
    initialiseState: fetchFilterSets,
    stateRequiresRefresh,
    refreshState: fetchFilterSets,
  };

  return observer;
};

export default useFilterSetsObserver;
