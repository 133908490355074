import dayjs from "dayjs";
import {
  VisitDateSelectionType,
  IVIsitDateRange,
} from "../state/types/FilterSets";

export const visitDateSelectionTypeIsRelativeDate = (
  selectionType: VisitDateSelectionType
): boolean => {
  switch (selectionType) {
    case VisitDateSelectionType.LastWeek:
    case VisitDateSelectionType.LastTwoWeeks:
    case VisitDateSelectionType.LastFourWeeks:
    case VisitDateSelectionType.LastTwelveWeeks:
      return true;
    default:
      return false;
  }
};

export const visitDateSelectionTypeIsPresetPeriod = (
  selectionType: VisitDateSelectionType
): boolean => {
  switch (selectionType) {
    case VisitDateSelectionType.CurrentPeriod:
    case VisitDateSelectionType.LastCompletePeriod:
    case VisitDateSelectionType.LastTwoCompletePeriods:
    case VisitDateSelectionType.LastThreeCompletePeriods:
    case VisitDateSelectionType.CurrentYearToDate:
    case VisitDateSelectionType.PreviousYear:
      return true;
    default:
      return false;
  }
};

export const generateRelativeDateFromSelectionType = (
  selectionType: VisitDateSelectionType
): IVIsitDateRange => {
  let dayOffset = 0;
  switch (selectionType) {
    case VisitDateSelectionType.LastWeek:
      dayOffset = -6;
      break;
    case VisitDateSelectionType.LastTwoWeeks:
      dayOffset = -13;
      break;
    case VisitDateSelectionType.LastTwelveWeeks:
      dayOffset = -83;
      break;
    default:
    case VisitDateSelectionType.LastFourWeeks:
      dayOffset = -27;
      break;
  }

  const dateStart = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(dayOffset, "day")
    .toDate();

  const dateEnd = dayjs()
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(999)
    .toDate();

  return { dateStart, dateEnd } as IVIsitDateRange;
};
