import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
} from "@material-ui/core";
import i18n from "../../../../localizations/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  IAppNotification,
  NotificationType,
} from "../../../../state/types/AppNotification";
import ConfirmationDialog from "../../confirmationDialog/confirmationDialog";
import { deleteAppNotification } from "../../../../state/actions/AppNotification-Actions";
import { CommentFieldStyles } from "../styles/commentFieldStyles";
import { CurrentUserId } from "../../../taskCentreEvents/state/selectors";
import { useIsAdmin } from "../../../../state/hooks/clients/useIsAdmin";
import CommonAttachmentsInput from "./commonAttachmentsInput";
import { ICaseDetails } from "../../../../state/types/TaskCentreCases";

interface IProps {
  fieldNotification?: IAppNotification | undefined;
  placeholder?: string | undefined;
  buttonText?: string | undefined;
  textRequired?: boolean | undefined;
  onSubmit?: (
    commentText: string,
    internal: boolean,
    files: File[]
  ) => void | undefined;
  onChange?: (commentText: string) => void | undefined;
  submitInternalOption?: boolean;
  submitFiles?: boolean;
  hideAttachmentsInput?: boolean;
  currentCase?: ICaseDetails;
  editableText?: string;
}

const CommonCommentField = (props: IProps) => {
  const classes = CommentFieldStyles();

  const dispatch = useDispatch();
  const currentUserId = useSelector(CurrentUserId);
  const isAdmin = useIsAdmin();

  const [commentText, setCommentText] = useState("");
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  const [internal, setInternal] = useState<boolean>(false);

  const hasError = props.fieldNotification
    ? props.fieldNotification.Type === NotificationType.Error
    : false;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };
  useEffect(() => {
    if (props.currentCase) {
      setCommentText("");
    }
  }, [props.currentCase]);
  const label = () => {
    const remaining = 1000 - commentText.length;
    const statusClass = remaining < 0 ? "char-overflow" : "";
    const label =
      props.placeholder || i18n.translate("TASK_CENTRE_FIELD_Comments");
    return (
      <span className={`${classes.charsRemaining} ${statusClass}`}>
        {label}
        {remaining}{" "}
        {i18n.translate("TASK_CENTRE_FIELD_Comments_Remaining_Chars")}
      </span>
    );
  };

  const aknowledgeError = () => {
    if (
      props.fieldNotification &&
      props.fieldNotification.Type === NotificationType.Error
    ) {
      dispatch(deleteAppNotification(props.fieldNotification));
    }
  };
  const buttonDisabled = () => {
    return (
      (props.textRequired === false ? false : commentText.length === 0) ||
      commentText.length > 1000 ||
      props.fieldNotification !== undefined
    );
  };
  return (
    <div>
      <div className={classes.CommentInputContainer}>
        <TextField
          multiline
          label={label()}
          value={props.editableText || commentText}
          onChange={onChange}
          minRows={2}
          variant="outlined"
          fullWidth
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          InputProps={{ className: classes.textfield }}
        />
        <Button
          size="small"
          className={classes.iconButton}
          disabled={buttonDisabled()}
          onClick={() => {
            if (props.onSubmit) {
              props.onSubmit(commentText, internal, uploadFiles);
              setCommentText("");
              setUploadFiles([]);
              setInternal(false);
            }
          }}
        >
          <Icon
            className={
              buttonDisabled()
                ? `far fa-paper-plane ${classes.disabledIconButton}`
                : `far fa-paper-plane ${classes.sendIcon}`
            }
          />
        </Button>
      </div>
      {props.submitFiles && currentUserId && !props.hideAttachmentsInput && (
        <CommonAttachmentsInput
          files={uploadFiles}
          onFileSelected={(file) => setUploadFiles([file, ...uploadFiles])}
          removeFile={(file) =>
            setUploadFiles(uploadFiles.filter((x) => x !== file))
          }
          userId={currentUserId}
          isAdmin={isAdmin}
        />
      )}
      {!hasError && props.onSubmit && (
        <>
          {props.submitInternalOption && (
            <FormControlLabel
              label="Internal"
              labelPlacement="end"
              control={
                <Checkbox
                  className={classes.internalCheckbox}
                  checked={internal}
                  onChange={(event) => setInternal(event.target.checked)}
                />
              }
            />
          )}
        </>
      )}
      <ConfirmationDialog
        title={i18n.translate("TASK_CENTRE_GENERIC_Error_Title")}
        message={i18n.translate("TASK_CENTRE_COMMENTS_Error_Message")}
        showConfirmation={hasError}
        onConfirmCallback={aknowledgeError}
        confirmButtonText={i18n.translate(
          "TASK_CENTRE_ERROR_CONFIRMATION_Close"
        )}
      />
    </div>
  );
};

export default CommonCommentField;
