import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  listContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "scroll",
    overflowX: "clip",
    paddingBottom: "2%",
  },
  popupSelection: {
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    border: `1px solid ${hgemColours.LimeGreen}`,
    borderRadius: "5px",
  },
  popupSelectionHint: {
    fontWeight: 300,
  },
  popupSelectionDialog: {
    "& .MuiDialogContent-root": {
      padding: "10px",
    },
  },
  visitCountText: {
    color: theme.palette.grey[500],
    fontSize: "0.8em",
    margin: "5px 0",
  },
}));

export const InboxListStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
