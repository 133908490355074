import { useDispatch, useSelector } from "react-redux";
import { IStateObserver, IStateObserverProps } from "./IStateObserver";
import {
  ReportConfigErrors,
  ReportConfigLastUpdated,
  ReportConfigurationLoaded,
  ReportTokenExpiration,
} from "../state/selectors";
import { reportConfigurationSuccess } from "../../../state/actions/ReportConfiguration-Actions";
import { reportConfigLastUpdatedApi } from "../api/reportConfigLastUpdatedApi";
import { isTokenCloseToExpiration } from "../utils/pbiTokenManagement";
import { reportConfigurationApi } from "../../../state/api/ReportConfiguration-API";
import { IReportConfiguration } from "../../../state/types/ReportConfiguration";

const useReportConfigurationObserver = () => {
  const dispatch = useDispatch();

  const reportConfigurationLoaded = useSelector(ReportConfigurationLoaded);
  const reportTokenExpiration = useSelector(ReportTokenExpiration);
  const reportConfigHasErrors = useSelector(ReportConfigErrors);
  const reportConfigLastUpdated = useSelector(ReportConfigLastUpdated);

  const stateRequiresRefresh = async (props: IStateObserverProps) => {
    if (
      reportTokenExpiration === undefined ||
      isTokenCloseToExpiration(reportTokenExpiration)
    ) {
      return true;
    }

    let requiresRefresh = false;

    const { clientId } = props;

    const response = await reportConfigLastUpdatedApi(clientId);

    if (response.status === 200 && response.data && reportConfigLastUpdated) {
      requiresRefresh = response.data.lastUpdated > reportConfigLastUpdated;
    }

    return requiresRefresh;
  };

  const silentRefreshReportConfiguration = async (
    props: IStateObserverProps
  ) => {
    const { clientId } = props;

    const reportConfigurationResponse = await reportConfigurationApi(clientId);

    if (reportConfigurationResponse.status !== 200) {
      throw new Error(reportConfigurationResponse.statusText);
    }

    const tokenExpiration = reportConfigurationResponse.data.accessTokens
      .map((t) => t.expiresAtUtc)
      .sort();

    const reportConfig: IReportConfiguration = {
      reportDetails: reportConfigurationResponse.data.reportConfigurations,
      accessTokens: reportConfigurationResponse.data.accessTokens,
      lastUpdated: reportConfigurationResponse.data.lastUpdated,
      tokensExpireAt:
        tokenExpiration.length > 0 ? tokenExpiration[0] : undefined,
    };

    dispatch(reportConfigurationSuccess(reportConfig));
  };

  const observer: IStateObserver = {
    name: "useReportConfigurationObserver",
    isInitialised: reportConfigurationLoaded
      ? reportConfigurationLoaded &&
        isTokenCloseToExpiration(reportTokenExpiration) === false
      : false,
    hasErrors: reportConfigHasErrors.length > 0,
    lastRefresh: reportConfigLastUpdated,
    initialiseState: silentRefreshReportConfiguration,
    stateRequiresRefresh,
    refreshState: silentRefreshReportConfiguration,
  };

  return observer;
};

export default useReportConfigurationObserver;
