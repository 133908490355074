import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericTreeView from "../genericTreeView/genericTreeView";
import { ILocationFilterOption } from "../../../state/types/FilterOptions";
import { i18n } from "../../../localizations";

interface IProps {
  availableLocations: ILocationFilterOption[];
  selectedAreas: string[];
  applySelectionCallback: (selections: string[]) => void;
}

const AreaRegionPicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const cancelSelection = () => {
    setIsOpen(false);
  };

  const [selectedAreas, setSelectedAreas] = useState([] as string[]);

  const getAppliedSelectionText = (): string => {
    const nodes = props.selectedAreas.length;
    return nodes === 0
      ? i18n.translate("AREA_REGION_PICKER_All_Selected")
      : `${nodes} ${i18n.translate("AREA_REGION_PICKER_Selected")}`;
  };

  const buildTooltipContent = (): string => {
    if (props.selectedAreas && props.selectedAreas.length > 0) {
      const tooltipText = `${props.selectedAreas.length} ${i18n.translate(
        "AREA_REGION_PICKER_Areas_Selected"
      )} `;
      let locations = props.selectedAreas.join(", ");

      if (locations.length > 150) {
        locations = locations.substring(0, 150) + "...";
      }

      return tooltipText + locations;
    } else {
      return i18n.translate("AREA_REGION_PICKER_All_Areas_Selected");
    }
  };

  const getNodeSequence = (): string[] => {
    const nodes: string[] = [];
    const regions = new Set(props.availableLocations.map((x) => x.region));

    if (regions.size > 1) {
      nodes.push("region");
    }

    nodes.push("area");
    return nodes;
  };

  useEffect(() => {
    setSelectedAreas(props.selectedAreas);
  }, [setSelectedAreas, props.selectedAreas]);

  return (
    <FilterWrapper
      label={i18n.translate("AREA_REGION_PICKER_Area_and_Regions")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
    >
      <GenericTreeView
        applySelectionCallback={props.applySelectionCallback}
        cancelSelectionCallback={cancelSelection}
        nodeOptions={props.availableLocations}
        selectedNodes={selectedAreas}
        nodeSequence={getNodeSequence()}
        sortNodes={true}
      />
    </FilterWrapper>
  );
};

export default AreaRegionPicker;
