import { useEffect, useState } from "react";
import { getCanAccessAppealApi } from "../../api/Appeals-API";
import { IAppealsAcess } from "../../types/Appeals";

interface IProps {
    visitId: number | undefined;
    clientId: number | undefined;
}

export const useCanAccessAppeal = (props: IProps) => {
    const [appealsAccess, setAppealsAccess] = useState<IAppealsAcess | undefined>();
    useEffect(() => {
        if (props.clientId && props.visitId) {
            getCanAccessAppealApi(props.clientId, props.visitId).then(response => {
                setAppealsAccess(response.data);
            })
        }
    }, [setAppealsAccess, props.clientId, props.visitId]);

    return appealsAccess;
}