import { lighten, makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  headerContainer: {
    margin: "5px",
    fontSize: "0.9em",

    "& span": {
      display: "block",
    },
  },
  headerLocation: {
    fontSize: "1.4em",
    fontWeight: 600,
    display: "flex !important",
    flexDirection: "row",
    alignItems: "baseline",
  },
  headerDate: {
    margin: "2px 0",
  },
  reviewDetails: {
    marginBottom: "15px",

    "& dt": {
      float: "left",
      clear: "left",
      width: "60px",
      textAlign: "left",
    },
  },
  scoreContainer: {
    width: `calc(100% - 40px)`,
    display: "inline-block",
    margin: "20px 0",
    padding: "20px",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "10px",
    backgroundColor: theme.palette.grey[50],
  },
  scoreContainerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    float: "left",
    marginRight: "10px",

    "& img": {
      width: "auto",
      height: "30px",
    },
  },
  stars: {
    fontSize: "1.6em",
    padding: "5px",
    marginRight: "10px",
    marginLeft: "1%",
    display: "inline",
    color: hgemColours.Yellow,
  },
  score: {
    fontSize: "1.6em",
    padding: "5px",
    display: "inline",
  },
  comments: {
    display: "block",
    marginTop: "15px",
    fontSize: "0.9em",
  },
  emptyComments: {
    display: "block",
    marginTop: "15px",
    color: theme.palette.grey[600],
    fontSize: "0.9em",
  },
  responseWrapper: {
    width: "100%",
  },
  responseContainer: {
    padding: "20px",
    marginLeft: "100px",
    border: "1px solid",
    borderColor: lighten(hgemColours.LimeGreen, 0.6),
    backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
    borderRadius: "10px",
    fontSize: "0.9em",
  },
  respponseHeader: {
    display: "block",
    color: theme.palette.grey[600],
    fontSize: "0.8em",
    marginBottom: "10px",
  },
  responseIcon: {
    display: "block",
    float: "left",
    fontSize: "2em",
    rotate: "180deg",
    marginLeft: "50px",
    color: theme.palette.grey[200],
  },
  author: {
    display: "inline-block",
    fontSize: "1em",
    color: hgemColours.MediumGrey,
    margin: "2px 0 5px 10px",
    padding: "4px 15px 4px 4px",
    border: `1px solid ${hgemColours.LightGrey}`,
    borderRadius: "15px",
    width: "auto",
    verticalAlign: "top",
  },
  authorIcon: {
    margin: "0 5px",
    color: theme.palette.grey[500],
  },
  divider: {
    display: "inline-block",
    width: "100%",
    borderBottom: `1px solid ${hgemColours.LightGrey}`,
  },
  reportIcon: {
    height: "1.5em",
    width: "auto",
  },

  subject: {
    fontSize: "1em",
    fontWeight: 600,
    color: hgemColours.DarkGrey,
    margin: "10px 0",
  },
}));

export const ReviewReportStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
