import {
  IFilterSet,
  IHubFilter,
  HubFilterType,
  IVisitDateFilter,
  IHubNumericRangeFilter,
  IQuestionsFilter,
  IHubTextFilter,
  VisitDateSelectionType,
  ISerializedFilterSet,
  IHubKeyValueFilter,
  ILocationFilter,
  ILocationRankFilter,
  IVisitIdFilter,
} from "../state/types/FilterSets";
import {
  visitDateSelectionTypeIsRelativeDate,
  generateRelativeDateFromSelectionType,
} from "./visitDateFilterUtils";

export const initialiseFilterValueFromDefault = (
  filterType: HubFilterType
): IHubFilter => {
  let filter = {
    type: HubFilterType[filterType],
    alwaysVisible: false,
  } as IHubFilter;

  switch (filterType) {
    case HubFilterType.VisitDate:
      filter = initialiseVisitDateFilter({
        type: HubFilterType.VisitDate,
        alwaysVisible: true,
        dateSelectionType: VisitDateSelectionType.LastTwelveWeeks,
      } as IVisitDateFilter);
      break;
    case HubFilterType.DishFilters:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: false,
        value: [],
      } as IHubTextFilter;
      break;
    case HubFilterType.Locations:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        value: [],
        allLocations: [],
      } as ILocationFilter;
      break;
    case HubFilterType.LocationRank:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        selectedLocation: undefined,
      } as ILocationRankFilter;
      break;
    case HubFilterType.VisitType:
    case HubFilterType.AreasAndRegions:
    case HubFilterType.VisitContactStatus:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        value: [],
      } as IHubTextFilter;
      break;
    case HubFilterType.Segments:
    case HubFilterType.SegmentFilter:
    case HubFilterType.QuestionnaireType:
    case HubFilterType.QuestionCategory:
    case HubFilterType.Benchmarks:
    case HubFilterType.NPS:
    case HubFilterType.DayOfWeek:
    case HubFilterType.DayPart:
    case HubFilterType.VisitAnalysisDepartment:
    case HubFilterType.VisitAnalysisRoom:
    case HubFilterType.FeedbackCategory:
    case HubFilterType.FeedbackSubCategory:
    case HubFilterType.LengthOfService:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: false,
        value: [],
      } as IHubTextFilter;
      break;
    case HubFilterType.Sections:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        value: [],
      } as IHubTextFilter;
      break;
    case HubFilterType.Questions:
      filter = {
        type: HubFilterType.Questions,
        alwaysVisible: true,
        selectedQuestions: [],
        selectedQuestionSubjects: [],
      } as IQuestionsFilter;
      break;
    case HubFilterType.VisitScoreRange:
    case HubFilterType.QuestionScoreRange:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: false,
        minValue: 0,
        maxValue: 100,
      } as IHubNumericRangeFilter;
      break;
    case HubFilterType.TaskAssignee:
    case HubFilterType.AppealStatus:
    case HubFilterType.TaskCentreStatus:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        value: [],
      } as IHubKeyValueFilter;
      break;
    case HubFilterType.TaskCentreCategory:
    case HubFilterType.TaskCentrePriority:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: false,
        value: [],
      } as IHubKeyValueFilter;
      break;
    case HubFilterType.VisitId:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        value: undefined,
      } as IVisitIdFilter;
      break;
    case HubFilterType.VisitSource:
      filter = {
        type: HubFilterType[filterType],
        alwaysVisible: true,
        value: [],
      } as IHubKeyValueFilter;
      break;
    default:
      break;
  }

  return filter;
};

export const resetFiltersFromFilterSet = (
  filterSet: IFilterSet
): IHubFilter[] => {
  const hubFilters = filterSet.filters.map((filter) => {
    switch (filter.type) {
      case HubFilterType.VisitDate:
        return initialiseVisitDateFilter(filter as IVisitDateFilter);
      default:
        return filter;
    }
  });

  return hubFilters;
};

export const deserialiseFilterSets = (
  filterSets: ISerializedFilterSet[]
): IFilterSet[] => {
  return filterSets.map((s) => {
    //TODO: This is where we will handle deserialization of custom filter sets
    //Merge sets in with default values

    const filterTypes = Object.values(HubFilterType);
    const mappedFilters: IHubFilter[] = [];

    filterTypes.forEach((type) => {
      const filter = initialiseFilterValueFromDefault(HubFilterType[type]);
      mappedFilters.push(filter);
    });

    return {
      name: s.name,
      isSystemDefault: s.isSystemDefault,
      isUserDefault: s.isUserDefault,
      filters: mappedFilters,
    };
  });
};

const initialiseVisitDateFilter = (
  filter: IVisitDateFilter
): IVisitDateFilter => {
  const isRelativeDateType = visitDateSelectionTypeIsRelativeDate(
    filter.dateSelectionType
  );

  if (isRelativeDateType) {
    const dateRange = generateRelativeDateFromSelectionType(
      filter.dateSelectionType
    );
    filter.customDateRange = dateRange;
  }

  return filter;
};
