import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: "block",
    width: "200px",
    height: "200px",
    margin: "2px",
    border: `1px solid ${theme.palette.grey[300]}`,
    float: "left",
    background: theme.palette.grey[200],
    color: theme.palette.grey[500],

    "&:hover": {
      cursor: "pointer",
      color: theme.palette.grey[700],
    },

    [theme.breakpoints.down("md")]: {
      width: "160px",
      height: "160px",
    },
  },
  audioPending: {
    position: "relative",
    top: "50%",
    left: "50%",
    margin: "-15px 0 0 -15px",
    width: "30px",
    height: "30px",
  },
  audioPendingLabel: {
    position: "relative",
    top: "50%",
    width: "100%",
    textAlign: "center",
    display: "block",
  },
}));

export const VisitAudioStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
