import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  templateButton: {
    width: "100%",
    padding: "10px",
    border: `1px solid ${hgemColours.LightGrey}`,
    marginBottom: "10px",
    textAlign: "left",
    fontSize: "1rem",

    "&& span": {
      width: "100%",
      display: "inline-block",
    },
  },

  templateText: {
    width: "100%",
    display: "inline-block",
    fontWeight: 100,
    color: hgemColours.MediumGrey,
    fontSize: "0.9rem",
    textTransform: "none",
  },

  templateButtonSelected: {
    borderLeft: `3px solid ${hgemColours.LimeGreen}`,
  },
}));

export const ReviewTemplateDialogStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
