import {
  ICaseCategory,
  ICaseDetails,
  IManager,
} from "../../../state/types/TaskCentreCases";
import CaseItem, { CaseLayout } from "../../_common/actionCentre/caseItem";
import WorkflowStatusField from "../Inputs/workflowStatusField";
import PriorityField from "../Inputs/priorityField";
import CategoryField from "../Inputs/categoryField";
import DueDateField from "../Inputs/dueDateField";
import AssigneeField from "../Inputs/assigneeField";
import { EditCaseItemStyles } from "../styles/editCaseItemStyles";
import { Grid } from "@material-ui/core";

interface IProps {
  caseDetails: ICaseDetails;
  showDelete: boolean;
  showAddNewTask: boolean;
  managers: IManager[];
  categories: ICaseCategory[];
}

const EditCaseItem = (props: IProps) => {
  const classes = EditCaseItemStyles();

  const status = (caseDetails: ICaseDetails) => {
    return (
      <Grid item xs={12}>
        <div className={classes.statusField}>
          <WorkflowStatusField
            status={caseDetails.wfStatus}
            caseId={caseDetails.id}
          />
        </div>
      </Grid>
    );
  };

  const priority = (caseDetails: ICaseDetails) => {
    return (
      <Grid item xs={12} sm={6}>
        <PriorityField
          priority={caseDetails.priority}
          caseId={caseDetails.id}
        />
      </Grid>
    );
  };

  const category = (caseDetails: ICaseDetails) => {
    return (
      <Grid item xs={12} sm={6}>
        <CategoryField
          category={caseDetails.category}
          availableCategories={props.categories}
          caseId={caseDetails.id}
        />
      </Grid>
    );
  };

  const dueDate = (caseDetails: ICaseDetails) => {
    return (
      <Grid item xs={12} sm={6}>
        <DueDateField
          dueDate={caseDetails.due}
          workflowStatus={caseDetails.wfStatus}
          caseId={caseDetails.id}
        />
      </Grid>
    );
  };

  const assignee = (caseDetails: ICaseDetails) => {
    return (
      <Grid item xs={12} sm={6}>
        <AssigneeField
          assigneeId={caseDetails.assigneeId}
          caseId={caseDetails.id}
          managers={props.managers}
        />
      </Grid>
    );
  };

  return (
    <div className={classes.caseInfoContainer}>
      {props.caseDetails !== undefined && (
        <>
          <CaseItem
            key={props.caseDetails.id}
            caseDetails={props.caseDetails}
            status={status}
            assignee={assignee}
            priority={priority}
            category={category}
            dueDate={dueDate}
            caseLayout={CaseLayout.grid}
          />
        </>
      )}
    </div>
  );
};

export default EditCaseItem;
