import {
  Conversation,
  ConversationListFilters,
  ConversationMessage,
  ConversationSourceConfig,
  ConversationsTokenPaged,
  UnreadConversations,
  VisitConversationContext,
} from "../types/Conversations";
import { ConversationMessageAddedSignalRNotification } from "../types/SignalRNotifications";

export const INITIALIZE_CONVERSATIONS = "INITIALIZE_CONVERSATIONS";
export const SET_CONVERSATIONS_FEATURE_ACTIVE =
  "SET_CONVERSATIONS_FEATURE_ACTIVE";
export const SET_CONVERSATIONS_SOURCE_CONFIGS =
  "SET_CONVERSATIONS_SOURCE_CONFIGS";
export const SET_UNREAD_CONVERSATIONS = "SET_UNREAD_CONVERSATIONS";

export const CONVERSATION_MESSAGE_ADDED = "CONVERSATION_MESSAGE_ADDED";

export const FETCH_CONVERSATIONS_LIST = "FETCH_CONVERSATIONS_LIST";
export const FETCH_CONVERSATION_MESSAGES_LIST =
  "FETCH_CONVERSATION_MESSAGES_LIST";
export const FETCH_VISIT_CONVERSATION_CONTEXT =
  "FETCH_VISIT_CONVERSATION_CONTEXT";
export const FETCH_CONVERSATION_VISIT_MESSAGES_LIST =
  "FETCH_CONVERSATION_VISIT_MESSAGES_LIST";

export const CLEAR_CONVERSATION_LIST = "CLEAR_CONVERSATION_LIST";

export const CONVERSATIONS_LIST_PENDING = "CONVERSATIONS_LIST_PENDING";
export const CONVERSATIONS_LIST_SUCCESS = "CONVERSATIONS_LIST_SUCCESS";

export const CONVERSATION_MESSAGES_LIST_PENDING =
  "CONVERSATION_MESSAGES_LIST_PENDING";
export const CONVERSATION_MESSAGES_LIST_SUCCESS =
  "CONVERSATION_MESSAGES_LIST_SUCCESS";

export const VISIT_CONVERSATION_CONTEXT_SUCCESS =
  "VISIT_CONVERSATION_CONTEXT_SUCCESS";

export const CONVERSATION_VISIT_MESSAGES_LIST_PENDING =
  "CONVERSATION_VISIT_MESSAGES_LIST_PENDING";
export const CONVERSATION_VISIT_MESSAGES_LIST_SUCCESS =
  "CONVERSATION_VISIT_MESSAGES_LIST_SUCCESS";

export const SELECT_CONVERSATION = "SELECT_CONVERSATION";

export const SEND_CONVERSATION_MESSAGE = "SEND_CONVERSATION_MESSAGE";
export const SEND_CONVERSATION_VISIT_MESSAGE =
  "SEND_CONVERSATION_VISIT_MESSAGE";
export const CONVERSATION_MESSAGE_SENDING = "CONVERSATION_MESSAGE_SENDING";
export const CONVERSATION_VISIT_MESSAGE_SENDING =
  "CONVERSATION_VISIT_MESSAGE_SENDING";

export const POST_MARK_CONVERSATION_MESSAGE_AS_READ =
  "POST_MARK_CONVERSATION_MESSAGE_AS_READ";
export const UPDATE_CONVERSATION_READ_CURSOR_MESSAGE_ID =
  "UPDATE_CONVERSATION_READ_CURSOR_MESSAGE_ID";

export type InitializeConversations = {
  type: typeof INITIALIZE_CONVERSATIONS;
};

export function initializeConversations(): InitializeConversations {
  return {
    type: INITIALIZE_CONVERSATIONS,
  };
}

export type SetConversationsFeatureActive = {
  type: typeof SET_CONVERSATIONS_FEATURE_ACTIVE;
  featureActive: boolean;
};

export function setConversationsFeatureActive(
  featureActive: boolean
): SetConversationsFeatureActive {
  return {
    type: SET_CONVERSATIONS_FEATURE_ACTIVE,
    featureActive: featureActive,
  };
}

export type SetConversationsSourceConfigs = {
  type: typeof SET_CONVERSATIONS_SOURCE_CONFIGS;
  sourceConfigs: ConversationSourceConfig[];
};

export function setConversationsSourceConfigs(
  sourceConfigs: ConversationSourceConfig[]
): SetConversationsSourceConfigs {
  return {
    type: SET_CONVERSATIONS_SOURCE_CONFIGS,
    sourceConfigs: sourceConfigs,
  };
}

export type SetUnreadConversations = {
  type: typeof SET_UNREAD_CONVERSATIONS;
  unreadConversations: UnreadConversations;
};

export function setUnreadConversations(
  unreadConversations: UnreadConversations
): SetUnreadConversations {
  return {
    type: SET_UNREAD_CONVERSATIONS,
    unreadConversations: unreadConversations,
  };
}

export type ConversationMessageAdded = {
  type: typeof CONVERSATION_MESSAGE_ADDED;
  notification: ConversationMessageAddedSignalRNotification;
};

export function conversationMessageAdded(
  notification: ConversationMessageAddedSignalRNotification
): ConversationMessageAdded {
  return {
    type: CONVERSATION_MESSAGE_ADDED,
    notification: notification,
  };
}

export type FetchConversationsList = {
  type: typeof FETCH_CONVERSATIONS_LIST;
  filters: ConversationListFilters;
};

export function fetchConversationsList(
  filters: ConversationListFilters
): FetchConversationsList {
  return {
    type: FETCH_CONVERSATIONS_LIST,
    filters: filters,
  };
}

export type ConversationsListPending = {
  type: typeof CONVERSATIONS_LIST_PENDING;
  filters: ConversationListFilters;
};

export function conversationsListPending(
  filters: ConversationListFilters
): ConversationsListPending {
  return {
    type: CONVERSATIONS_LIST_PENDING,
    filters: filters,
  };
}

export type ConversationsListSuccess = {
  type: typeof CONVERSATIONS_LIST_SUCCESS;
  conversationsTokenPaged: ConversationsTokenPaged<Conversation>;
};

export function conversationsListSuccess(
  conversationsTokenPaged: ConversationsTokenPaged<Conversation>
): ConversationsListSuccess {
  return {
    type: CONVERSATIONS_LIST_SUCCESS,
    conversationsTokenPaged: conversationsTokenPaged,
  };
}

export type ClearConversationsList = {
  type: typeof CLEAR_CONVERSATION_LIST;
};

export function clearConversationsList(): ClearConversationsList {
  return {
    type: CLEAR_CONVERSATION_LIST,
  };
}

export enum ConversationMessagesListUpdateReason {
  InitialLoad,
  MessageAdded,
  LoadMore,
  MessageSending,
}

export type FetchConversationMessagesList = {
  type: typeof FETCH_CONVERSATION_MESSAGES_LIST;
  reason: ConversationMessagesListUpdateReason;
  scrollDistanceFromBottom: number | undefined;
};

export function fetchInitialConversationMessagesList(): FetchConversationMessagesList {
  return {
    type: FETCH_CONVERSATION_MESSAGES_LIST,
    reason: ConversationMessagesListUpdateReason.InitialLoad,
    scrollDistanceFromBottom: undefined,
  };
}

export function fetchLoadMoreConversationMessagesList(
  scrollDistanceFromBottom: number | undefined
): FetchConversationMessagesList {
  return {
    type: FETCH_CONVERSATION_MESSAGES_LIST,
    reason: ConversationMessagesListUpdateReason.LoadMore,
    scrollDistanceFromBottom: scrollDistanceFromBottom,
  };
}

export type ConversationMessagesListPending = {
  type: typeof CONVERSATION_MESSAGES_LIST_PENDING;
  conversationId: number;
};

export function conversationMessagesListPending(
  conversationId: number
): ConversationMessagesListPending {
  return {
    type: CONVERSATION_MESSAGES_LIST_PENDING,
    conversationId: conversationId,
  };
}

export type ConversationMessagesListSuccess = {
  type: typeof CONVERSATION_MESSAGES_LIST_SUCCESS;
  conversationId: number;
  conversationMessagesTokenPaged: ConversationsTokenPaged<ConversationMessage>;
  reason: ConversationMessagesListUpdateReason;
  scrollDistanceFromBottom: number | undefined;
};

export function conversationMessagesListSuccess(
  conversationId: number,
  conversationMessagesTokenPaged: ConversationsTokenPaged<ConversationMessage>,
  reason: ConversationMessagesListUpdateReason,
  scrollDistanceFromBottom: number | undefined
): ConversationMessagesListSuccess {
  return {
    type: CONVERSATION_MESSAGES_LIST_SUCCESS,
    conversationId: conversationId,
    conversationMessagesTokenPaged: conversationMessagesTokenPaged,
    reason: reason,
    scrollDistanceFromBottom: scrollDistanceFromBottom,
  };
}

export type FetchVisitConversationContext = {
  type: typeof FETCH_VISIT_CONVERSATION_CONTEXT;
  visitId: number;
};

export function fetchVisitConversationContext(
  visitId: number
): FetchVisitConversationContext {
  return {
    type: FETCH_VISIT_CONVERSATION_CONTEXT,
    visitId: visitId,
  };
}

export type VisitConversationContextSuccess = {
  type: typeof VISIT_CONVERSATION_CONTEXT_SUCCESS;
  context: VisitConversationContext;
};

export function visitConversationContextSuccess(
  context: VisitConversationContext
): VisitConversationContextSuccess {
  return {
    type: VISIT_CONVERSATION_CONTEXT_SUCCESS,
    context: context,
  };
}

export type FetchConversationVisitMessagesList = {
  type: typeof FETCH_CONVERSATION_VISIT_MESSAGES_LIST;
  conversationId: number;
  visitId: number;
  reason: ConversationMessagesListUpdateReason;
  scrollDistanceFromBottom: number | undefined;
};

export function fetchInitialConversationVisitMessagesList(
  conversationId: number,
  visitId: number
): FetchConversationVisitMessagesList {
  return {
    type: FETCH_CONVERSATION_VISIT_MESSAGES_LIST,
    conversationId: conversationId,
    visitId: visitId,
    reason: ConversationMessagesListUpdateReason.InitialLoad,
    scrollDistanceFromBottom: undefined,
  };
}

export function fetchLoadMoreConversationVisitMessagesList(
  conversationId: number,
  visitId: number,
  scrollDistanceFromBottom: number | undefined
): FetchConversationVisitMessagesList {
  return {
    type: FETCH_CONVERSATION_VISIT_MESSAGES_LIST,
    conversationId: conversationId,
    visitId: visitId,
    reason: ConversationMessagesListUpdateReason.LoadMore,
    scrollDistanceFromBottom: scrollDistanceFromBottom,
  };
}

export type ConversationVisitMessagesListPending = {
  type: typeof CONVERSATION_VISIT_MESSAGES_LIST_PENDING;
  conversationId: number;
  visitId: number;
};

export function conversationVisitMessagesListPending(
  conversationId: number,
  visitId: number
): ConversationVisitMessagesListPending {
  return {
    type: CONVERSATION_VISIT_MESSAGES_LIST_PENDING,
    conversationId: conversationId,
    visitId: visitId,
  };
}

export type ConversationVisitMessagesListSuccess = {
  type: typeof CONVERSATION_VISIT_MESSAGES_LIST_SUCCESS;
  conversationId: number;
  visitId: number;
  conversationMessagesTokenPaged: ConversationsTokenPaged<ConversationMessage>;
  reason: ConversationMessagesListUpdateReason;
  scrollDistanceFromBottom: number | undefined;
};

export function conversationVisitMessagesListSuccess(
  conversationId: number,
  visitId: number,
  conversationMessagesTokenPaged: ConversationsTokenPaged<ConversationMessage>,
  reason: ConversationMessagesListUpdateReason,
  scrollDistanceFromBottom: number | undefined
): ConversationVisitMessagesListSuccess {
  return {
    type: CONVERSATION_VISIT_MESSAGES_LIST_SUCCESS,
    conversationId: conversationId,
    visitId: visitId,
    conversationMessagesTokenPaged: conversationMessagesTokenPaged,
    reason: reason,
    scrollDistanceFromBottom: scrollDistanceFromBottom,
  };
}

export type SelectConversation = {
  type: typeof SELECT_CONVERSATION;
  conversationId: number;
};

export function selectConversation(conversationId: number): SelectConversation {
  return {
    type: SELECT_CONVERSATION,
    conversationId: conversationId,
  };
}

export type SendConversationMessage = {
  type: typeof SEND_CONVERSATION_MESSAGE;
  messageText: string;
  file: File | undefined;
  conversationId?: number | undefined;
  visitId?: number | undefined;
};

export function sendConversationMessage(
  messageText: string,
  file: File | undefined,
  conversationId?: number | undefined,
  visitId?: number | undefined
): SendConversationMessage {
  return {
    type: SEND_CONVERSATION_MESSAGE,
    messageText: messageText,
    file: file,
    conversationId: conversationId,
    visitId: visitId,
  };
}

export type ConversationMessageSending = {
  type: typeof CONVERSATION_MESSAGE_SENDING;
  conversationId: number;
  visitId: number;
  conversationMessage: ConversationMessage;
};

export function conversationMessageSending(
  conversationId: number,
  visitId: number,
  conversationMessage: ConversationMessage
): ConversationMessageSending {
  return {
    type: CONVERSATION_MESSAGE_SENDING,
    conversationId: conversationId,
    visitId: visitId,
    conversationMessage: conversationMessage,
  };
}

export type PostMarkConversationMessageAsRead = {
  type: typeof POST_MARK_CONVERSATION_MESSAGE_AS_READ;
  conversationId: number;
  conversationMessageId: number;
};

export function postMarkConversationMessageAsRead(
  conversationId: number,
  conversationMessageId: number
): PostMarkConversationMessageAsRead {
  return {
    type: POST_MARK_CONVERSATION_MESSAGE_AS_READ,
    conversationId: conversationId,
    conversationMessageId: conversationMessageId,
  };
}

export type UpdateConversationReadCursorMessageId = {
  type: typeof UPDATE_CONVERSATION_READ_CURSOR_MESSAGE_ID;
  conversationId: number;
  readCursorMessageId: number;
};

export function updateConversationReadCursorMessageId(
  conversationId: number,
  readCursorMessageId: number
): UpdateConversationReadCursorMessageId {
  return {
    type: UPDATE_CONVERSATION_READ_CURSOR_MESSAGE_ID,
    conversationId: conversationId,
    readCursorMessageId: readCursorMessageId,
  };
}

export type ConversationActions =
  | SetConversationsFeatureActive
  | SetConversationsSourceConfigs
  | SetUnreadConversations
  | ConversationMessageAdded
  | FetchConversationsList
  | ClearConversationsList
  | ConversationsListPending
  | ConversationsListSuccess
  | FetchConversationMessagesList
  | ConversationMessagesListPending
  | ConversationMessagesListSuccess
  | VisitConversationContextSuccess
  | ConversationVisitMessagesListPending
  | ConversationVisitMessagesListSuccess
  | SelectConversation
  | SendConversationMessage
  | ConversationMessageSending
  | PostMarkConversationMessageAsRead
  | UpdateConversationReadCursorMessageId;
