import { SagaMiddleware } from "redux-saga";
import { put, delay, fork, takeEvery } from "redux-saga/effects";
import {
  APP_NOTIFICATION_SEND_INTERNAL,
  IAppNotificationSendInternal,
  addAppNotification,
  deleteAppNotification,
  addAppNotificationIfNotExists,
  IAppNotificationSnooze,
  APP_NOTIFICATION_SNOOZE,
} from "../actions/AppNotification-Actions";
import { IAppNotification } from "../types/AppNotification";

export function registerAppNotificationSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeEvery(APP_NOTIFICATION_SEND_INTERNAL, sendInternalNotification);
  });
  sagaMiddleware.run(function* () {
    yield takeEvery(APP_NOTIFICATION_SNOOZE, snoozeNotification);
  });
}

function* sendInternalNotification(
  action: IAppNotificationSendInternal
): unknown {
  if (!action.notification.AddedAt) {
    action.notification.AddedAt = new Date();
  }

  if (action.overwriteExisting) {
    yield put(addAppNotification(action.notification));
  } else {
    yield put(addAppNotificationIfNotExists(action.notification));
  }

  if (action.notification.NotificationTimeout > 0) {
    yield fork(deleteNotification, action.notification);
  }
}

function* deleteNotification(notification: IAppNotification): unknown {
  yield delay(notification.NotificationTimeout);
  yield put(deleteAppNotification(notification));
}

function* snoozeNotification(action: IAppNotificationSnooze): unknown {
  action.notification.IsSnoozed = true;
  yield put(addAppNotification(action.notification));

  yield delay(action.durationMilliseconds);

  action.notification.IsSnoozed = false;
  yield put(addAppNotification(action.notification));
}
