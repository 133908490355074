import {
  Accordion,
  AccordionSummary,
  Badge,
  Icon,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { RelatedGuestCommunicationModel } from "../models/relatedGuestCommunicationModel";
import { GuestContactStyles } from "../styles/guestContactStyles";
import { InboxItemStyles } from "../../inboxList/styles/inboxItemStyles";
import { useEffect, useState } from "react";
import { ICaseDetails } from "../../../state/types/TaskCentreCases";
import { IGuestDetails } from "../../../state/types/GuestDetails";
import { useClientId } from "../../../state/hooks/clients/useClientId";
import { getRelatedGuestCommunicationCases } from "../../../state/api/TaskCentre-API";
import { i18n } from "../../../localizations";

type Props = {
  case: ICaseDetails | undefined;
  guestContactDetails: IGuestDetails | undefined;
};

const RelatedGuestContacts = (props: Props) => {
  const guestContactStyles = GuestContactStyles();
  const inboxItemStyles = InboxItemStyles();

  const clientId = useClientId();

  const [relatedGuestCommunicationModels, setRelatedGuestCommunicationModels] =
    useState<RelatedGuestCommunicationModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!clientId || !props.guestContactDetails) {
      return;
    }

    const email =
      props.case?.events.filter((x) => x.recipient !== undefined)[0]
        ?.recipient ?? props.guestContactDetails?.contactDetails.email;

    if (!email) {
      return;
    }

    setRelatedGuestCommunicationModels([]);

    setIsLoading(true);

    getRelatedGuestCommunicationCases(clientId, props.case?.id ?? 0, email)
      .then((response) => {
        setRelatedGuestCommunicationModels(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [clientId, props.case, props.guestContactDetails]);

  const formatDate = (dateString: Date) => {
    return dayjs(dateString, { utc: true })
      .local()
      .format(
        i18n.translate(
          "TASK_CENTRE_GUEST_CONTACT_ALSO_FROM_THIS_PERSON_SUMMARY_CARD_DATE_FORMAT"
        )
      );
  };

  const relatedConversationRows = relatedGuestCommunicationModels.map(
    (relatedGuestCommunication) => {
      return (
        <>
          <div
            className={`${guestContactStyles.accordionRow} ${guestContactStyles.relatedRow}`}
          >
            <Typography className={guestContactStyles.headerText}>
              {relatedGuestCommunication.branchName}
            </Typography>
            <Typography
              className={`${guestContactStyles.bodyText} ${guestContactStyles.trimText}`}
            >
              {relatedGuestCommunication.subject}
            </Typography>
            <Typography className={guestContactStyles.bodyText}>
              {formatDate(relatedGuestCommunication.timestamp)}
            </Typography>
            <Typography className={guestContactStyles.bodyText}>
              {relatedGuestCommunication.voucherValue && (
                <Tooltip
                  title={i18n.translate("TASK_CENTRE_CARD_voucher")}
                  enterDelay={800}
                  placement="bottom"
                  PopperProps={{ disablePortal: true }}
                  arrow={true}
                >
                  <Badge
                    badgeContent={`£${relatedGuestCommunication.voucherValue}`}
                    classes={{ badge: inboxItemStyles.actionsBadge }}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    style={{ left: 15 }}
                  />
                </Tooltip>
              )}
            </Typography>
            <Link
              key={relatedGuestCommunication.visitId}
              style={{ textDecoration: "none" }}
              target={"_none"}
              to={`/inbox?visitId=${relatedGuestCommunication.visitId}&inboxtab=3`}
            >
              <Icon
                className={`fas fa-share-square ${guestContactStyles.newTabIcon}`}
                fontSize="small"
              />
            </Link>
          </div>
        </>
      );
    }
  );

  return (
    <>
      {isLoading && <LinearProgress color="primary" />}
      {!isLoading && relatedGuestCommunicationModels.length > 0 && (
        <Accordion className={guestContactStyles.accordionRow}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {" "}
            <Typography className={guestContactStyles.subHeader}>
              {i18n.translate(
                "TASK_CENTRE_GUEST_CONTACT_ALSO_FROM_THIS_PERSON"
              )}
            </Typography>
          </AccordionSummary>

          <div>{relatedConversationRows}</div>
        </Accordion>
      )}
    </>
  );
};

export default RelatedGuestContacts;
