import { Typography } from "@material-ui/core";
import { i18n } from "../../../../localizations";
import MediaMessageAudio from "./mediaMessageAudio";
import MediaMessageImage from "./mediaMessageImage";
import MediaMessageVideo from "./mediaMessageVideo";
import { isAudio, isImage, isVideo } from "./functions";
import { MediaLinks } from "./types/mediaLinks";

type Props = {
    shouldRenderMedia: boolean;
    filename?: string | undefined;
    contentType: string | false;
    mediaLinks: MediaLinks | undefined;
    download: () => void;
};

const MediaMessageContent = (props: Props) => {
    let result: JSX.Element | undefined = undefined;

    if (props.shouldRenderMedia && props.contentType) {
        if (isImage(props.contentType)) {
            result = <MediaMessageImage mediaLinks={props.mediaLinks} onDownload={props.download} />;
        }
        else if (isVideo(props.contentType)) {
            result = <MediaMessageVideo mediaLinks={props.mediaLinks} contentType={props.contentType} onDownload={props.download} />;
        }
        else if (isAudio(props.contentType)) {
            result = <MediaMessageAudio mediaLinks={props.mediaLinks} contentType={props.contentType} />;
        }
    }

    if (!result) {
        result = <Typography><strong>{i18n.translate("MEDIA_MESSAGE_MEDIA_FILE")}</strong> {props.filename ?? props.contentType}</Typography>;
    }

    return result;
};

export default MediaMessageContent;