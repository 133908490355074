import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  errorContainer: {
    margin: "10px",
  },
  errorTask: {
    float: "left",
  },
}));

export const ConversationListErrorStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
