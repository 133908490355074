import React, { useState, useEffect, useRef } from "react";
import { MenuList, MenuItem, Button } from "@material-ui/core";
import { CaseInsensitiveSort } from "../../../utils/sortnig";
import { GenericMultiSelectStyles } from "./styles/genericMultiSelectStyles";
import { i18n } from "../../../localizations";

interface IProps {
  options: string[];
  selectedOptions: string[];
  multiSelect: boolean;
  useSelectAllOption: boolean;
  selectAllOptionText?: string;
  applySelectionCallback: (selections: string[]) => void;
  cancelSelectionCallback: () => void;
  applyLocalisationToOptions?: boolean;
  localisationPrefix?: string;
  applySort?: boolean;
  showOptionTooltips?: boolean;
  tooltipLocalisationPrefix?: string;
  showTooltipsAsOptionSuffix?: boolean;
  optionGroupings?: Array<{ key: string; values: Array<string> }>;
}

const GenericMultiSelect = (props: IProps) => {
  const classes = GenericMultiSelectStyles();

  const multiSelectRef = useRef<HTMLUListElement>(null);
  const sortSelectionOptions =
    props.applySort === undefined ? true : props.applySort;

  const [selectedOptions, setSelectedOptions] = useState([] as string[]);

  const optionGroupings: Array<{
    key: string;
    values: Array<string>;
  }> = props.optionGroupings
    ? props.optionGroupings
    : [
        {
          key: "",
          values: sortSelectionOptions
            ? [...new Set(props.options.sort(CaseInsensitiveSort))]
            : [...new Set(props.options)],
        },
      ];

  const renderOptionGroupings = optionGroupings.length > 1;

  const applySingleSelection = (selection: string) => {
    props.applySelectionCallback([selection]);
  };

  const applySelectAll = () => {
    props.applySelectionCallback([]);
  };

  const applyMultiSelect = () => {
    props.applySelectionCallback(selectedOptions);
  };

  const cancelMultiSelect = () => {
    props.cancelSelectionCallback();
  };

  const multiSelectItemIsChecked = (option: string): boolean => {
    return selectedOptions.indexOf(option) >= 0;
  };

  const updateMultiSelectItem = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let mergeStates = selectedOptions.map((x) => x);

    if (event.target.checked) {
      mergeStates.push(event.target.value);
    } else {
      mergeStates = mergeStates.filter((x) => x !== event.target.value);
    }

    setSelectedOptions(mergeStates);
  };

  const getMultiSelectionText = () => {
    const nodes = [...new Set(selectedOptions)].length;
    return nodes === 0
      ? i18n.translate("GENERIC_MULTI_SELECT_All_selected")
      : `${nodes} ${i18n.translate("GENERIC_MULTI_SELECT_Selected")}`;
  };

  const handleSelectAll = () => {
    setSelectedOptions(props.options);
  };

  const handleClearSelections = () => {
    setSelectedOptions([]);
  };

  useEffect(() => {
    setSelectedOptions(props.selectedOptions);
    if (multiSelectRef && multiSelectRef.current) {
      multiSelectRef.current.focus();
    }
  }, [setSelectedOptions, props.selectedOptions, multiSelectRef]);

  const buildOptionSuffix = (suffixValue: string) => {
    if (props.showOptionTooltips && props.showTooltipsAsOptionSuffix) {
      return <div className={classes.optionSuffix}>{suffixValue}</div>;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className={classes.scrollingMenu}>
        <MenuList ref={multiSelectRef}>
          {optionGroupings.length > 0 &&
            props.useSelectAllOption &&
            props.selectAllOptionText && (
              <MenuItem key={"__select__all__"} onClick={applySelectAll}>
                {props.selectAllOptionText}
              </MenuItem>
            )}

          {optionGroupings.length > 0 &&
            optionGroupings.map((g) => {
              return (
                <div key={g.key}>
                  {g.key && renderOptionGroupings && (
                    <span className={classes.menuItemGroup}>{g.key}</span>
                  )}

                  {g.values.map((x) => {
                    const displayValue = props.applyLocalisationToOptions
                      ? i18n.translate(`${props.localisationPrefix}${x}`)
                      : x;

                    const tooltipValue = props.showOptionTooltips
                      ? i18n.translate(`${props.tooltipLocalisationPrefix}${x}`)
                      : "";

                    if (props.multiSelect) {
                      const checkStatus = multiSelectItemIsChecked(x);

                      return (
                        <MenuItem
                          key={x}
                          className={classes.menuItemContent}
                          title={tooltipValue}
                        >
                          <label>
                            <input
                              type="checkbox"
                              value={x}
                              name={x}
                              onChange={updateMultiSelectItem}
                              checked={checkStatus}
                            />
                            <span className={classes.checkmark}></span>
                            {displayValue}
                            {buildOptionSuffix(tooltipValue)}
                          </label>
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem
                          key={x}
                          onClick={() => applySingleSelection(x)}
                          title={tooltipValue}
                        >
                          {displayValue}
                          {buildOptionSuffix(tooltipValue)}
                        </MenuItem>
                      );
                    }
                  })}
                </div>
              );
            })}
        </MenuList>
      </div>

      {props.multiSelect && (
        <>
          <div className={classes.subTasks}>
            <Button size="small" onClick={handleSelectAll}>
              {i18n.translate("GENERIC_MULTI_SELECT_Select_All")}
            </Button>
            <Button size="small" onClick={handleClearSelections}>
              {i18n.translate("GENERIC_MULTI_SELECT_Clear_Selections")}
            </Button>
          </div>
          <div className={classes.actions}>
            <div className={classes.status}>{getMultiSelectionText()}</div>
            <Button
              onClick={applyMultiSelect}
              variant="contained"
              color="primary"
            >
              {i18n.translate("GENERIC_MULTI_SELECT_Apply")}
            </Button>
            <Button onClick={cancelMultiSelect}>
              {i18n.translate("GENERIC_MULTI_SELECT_Cancel")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default GenericMultiSelect;
