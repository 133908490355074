import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  errorContainer: {
    maxWidth: "640px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 0px 5px 1px rgba(130,130,130,0.24)",
    borderColor: "#c8c8c8",
    marginLeft: "10px",
    padding: "20px",
    borderRadius: "3px",

    "& h5 > svg": {
      color: hgemColours.DarkGrey,
      verticalAlign: "text-bottom",
      marginRight: "10px",
    },

    "& input": {
      height: "0px",
      width: "0px",
      border: "none",
    },

    "& button": {
      marginRight: "10px",
    },
  },
  additionalNotice: {
    fontWeight: "bold",
  },
}));

export const RenderErrorMessageStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
