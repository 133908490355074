import React from "react";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import i18n from "../../../localizations/i18n";
import { CaseListErrorStyles } from "../styles/caseListErrorStyles";

const CaseListError = () => {
  const classes = CaseListErrorStyles();

  return (
    <div className={classes.errorContainer}>
      <Alert
        elevation={4}
        variant="filled"
        severity="warning"
        action={
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {i18n.translate("TASK_CENTRE_CASE_LIST_Refresh_Page")}
          </Button>
        }
      >
        {i18n.translate("TASK_CENTRE_CASE_LIST_Error_Message")}
      </Alert>
    </div>
  );
};

export default CaseListError;
