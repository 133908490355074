import axios, { AxiosResponse } from "axios";
import {
  IFilterAvailabilityApiResponse,
  IFilterSetApiResponse,
} from "../types/FilterSets";

export const filterSetsApi = (
  clientId: number
): Promise<AxiosResponse<IFilterSetApiResponse>> => {
  return axios.post<IFilterSetApiResponse>(
    `${process.env.REACT_APP_HUB_API_BASEURL}filterSets/client/${clientId}/filterSets`
  );
};

export const filterAvailabilityApi = (
  clientId: number
): Promise<AxiosResponse<IFilterAvailabilityApiResponse>> => {
  return axios.post<IFilterAvailabilityApiResponse>(
    `${process.env.REACT_APP_HUB_API_BASEURL}filterSets/client/${clientId}/filterAvailability`
  );
};
