import axios, { AxiosResponse } from "axios";
import buildUrl from "build-url-ts";
import { MediaLinks } from "../../components/inboxViews/components/mediaMessages/types/mediaLinks";
import { Conversation, ConversationMessage, ConversationsFeatureEnabled, ConversationSourceConfig, ConversationsTokenPaged, ConversationVisit, UnreadConversations, VisitConversationContext } from "../types/Conversations";

export const getConversationsFeatureActiveAPI = (
    clientId: number
  ): Promise<AxiosResponse<ConversationsFeatureEnabled>> => {
    return axios.get<ConversationsFeatureEnabled>(
      `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/is-enabled`
    );
  };

export const getConversationsSourceConfigsAPI = (
    clientId: number
  ): Promise<AxiosResponse<ConversationSourceConfig[]>> => {
    return axios.get<ConversationSourceConfig[]>(
      `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/sources-config`
    );
  };

export const getConversationsAPI = (
    clientId: number,
    conversationId: number | undefined,
    pageToken?: number | undefined
  ): Promise<AxiosResponse<ConversationsTokenPaged<Conversation>>> => {
    const url = buildUrl(`${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations`, {
      queryParams: {
        conversationId: conversationId,
        pageToken: pageToken
      }
    });

    return axios.get<ConversationsTokenPaged<Conversation>>(url);
  };

  export const getConversationMessagesAPI = (
    clientId: number,
    conversationId: number,
    pageToken?: number | undefined
  ): Promise<AxiosResponse<ConversationsTokenPaged<ConversationMessage>>> => {
    let url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/${conversationId}/messages`;

    if (pageToken) {
        url += `?pageToken=${pageToken}`
    }

    return axios.get<ConversationsTokenPaged<ConversationMessage>>(url);
  };

  export const sendConversationMessageAPI = ( 
    clientId: number, 
    conversationId: number, 
    messageText: string, 
    file: File | undefined,
    senderCorrelationGuid: string 
  ): Promise<AxiosResponse> => { 
    const url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/${conversationId}/messages`; 

    const formData = new FormData();

    formData.append("body", messageText);
    formData.append("senderCorrelationGuid", senderCorrelationGuid);

    if (file) {
      formData.append("", file);
    }

    return axios.post(url, formData);
  };

  export const getConversationMessageMediaAPI = (
    clientId: number,
    conversationId: number,
    conversationMessageId: number,
    conversationMessageMediaId: number
  ): Promise<MediaLinks> => {
    const url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/${conversationId}/messages/${conversationMessageId}/media/${conversationMessageMediaId}`;

    return axios.get<Blob>(url, { responseType: "blob" }).then(response => {
      const localUrl = URL.createObjectURL(response.data);

      return {
        localUrl: localUrl,
        blob: response.data
      }
    });
  };

  export const getvisitConversationContextAPI = (
    clientId: number,
    visitId: number
  ): Promise<AxiosResponse<VisitConversationContext>> => {
    const url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/visits/${visitId}/conversation`;

    return axios.get<VisitConversationContext>(url);
  };

  export const getconversationVisitsAPI = (
    clientId: number,
    conversationId: number
  ): Promise<AxiosResponse<ConversationVisit[]>> => {
    const url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/${conversationId}/visits`;

    return axios.get<ConversationVisit[]>(url);
  };

  export const getConversationVisitMessagesAPI = (
    clientId: number,
    conversationId: number,
    visitId: number,
    pageToken?: number | undefined
  ): Promise<AxiosResponse<ConversationsTokenPaged<ConversationMessage>>> => {
    let url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/${conversationId}/visits/${visitId}/messages`;

    if (pageToken) {
        url += `?pageToken=${pageToken}`
    }

    return axios.get<ConversationsTokenPaged<ConversationMessage>>(url);
  };

  export const getUnreadConversationsAPI = (
    clientId: number
  ): Promise<AxiosResponse<UnreadConversations>> => {
    const url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/unread`;

    return axios.get<UnreadConversations>(url);
  };

  export const postMarkConverstionMessageReadAPI = (
    clientId: number,
    conversationId: number,
    conversationMessageId: number
  ): Promise<AxiosResponse<UnreadConversations>> => {
    const url = `${process.env.REACT_APP_CONVERSATIONS_API_BASEURL}client/${clientId}/conversations/${conversationId}/messages/${conversationMessageId}/mark-read`;

    return axios.post<UnreadConversations>(url);
  };