import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { IReportViewerState } from "../../../state/reducers/ReportViewer-Reducer";

export const GetBusyStatus = createSelector(
  (state: RootState) => state.ReportViewerReducer,
  (state: IReportViewerState) => {
    return state.isBusy;
  }
);

export const GetPages = createSelector(
  (state: RootState) => state.ReportViewerReducer,
  (state: IReportViewerState) => {
    return state.pages;
  }
);

export const GetDefaultPage = createSelector(
  (state: RootState) => state.ReportViewerReducer,
  (state: IReportViewerState) => {
    return state.defaultPage;
  }
);
