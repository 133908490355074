import { INavigationOption } from "../../../state/types/UserProfile";

enum ControlledFeatures {
  Appeals = "Appeals",
  Conversations = "Conversations",
}

const removeInactiveFeatures = (inactiveFeatureOptions: formattingOptions) => {
  const {
    appealsFeatureActive,
    conversationsFeatureActive,
    userNavigationOptions,
  } = inactiveFeatureOptions;

  let filteredNavOptions = userNavigationOptions;

  if (!appealsFeatureActive) {
    filteredNavOptions = filteredNavOptions.filter((navOption) => {
      return navOption.name !== ControlledFeatures.Appeals;
    });
  }
  if (!conversationsFeatureActive) {
    filteredNavOptions = filteredNavOptions.filter((navOption) => {
      return navOption.name !== ControlledFeatures.Conversations;
    });
  }
  return filteredNavOptions;
};

type formattingOptions = {
  appealsFeatureActive: boolean;
  conversationsFeatureActive: boolean;
  userNavigationOptions: INavigationOption[];
};

export const formatUserNavigationOptions = (
  formattingOptions: formattingOptions
) => {
  const inactiveFeaturesFiltered = removeInactiveFeatures(formattingOptions);
  const parents = inactiveFeaturesFiltered.filter((navOption) => {
    return !navOption.parent;
  });
  const parentsWithChildren = parents.map((parentNavOption) => {
    const children = inactiveFeaturesFiltered.filter((childNavOption) => {
      return childNavOption.parent === parentNavOption.name;
    });

    return { ...parentNavOption, children: children };
  });

  return parentsWithChildren;
};
