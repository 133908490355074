import * as msal from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { Logging } from "../utils/logging";

const b2c_clientId = `${process.env.REACT_APP_B2C_CLIENT_ID}`;
const b2c_tenantId = `${process.env.REACT_APP_B2C_TENANT_ID}`;
const b2c_authority = `${process.env.REACT_APP_B2C_AUTHORITY}`;
const b2c_knownAuthorities = `${process.env.REACT_APP_B2C_KNOWN_AUTHORITIES}`;
const b2c_login_policy = `${process.env.REACT_APP_B2C_AUTHORITY_LOGIN}`;
const b2c_password_reset_policy = `${process.env.REACT_APP_B2C_AUTHORITY_PASSWORD_RESET}`;

export const preferred_account_authority = b2c_authority.toLowerCase();
export const authority_login = `https://${b2c_authority}/tfp/${b2c_tenantId}/${b2c_login_policy}`;
export const authority_password_reset = `https://${b2c_authority}/tfp/${b2c_tenantId}/${b2c_password_reset_policy}`;
export const api_token_scope = `${process.env.REACT_APP_B2C_API_TOKEN_SCOPE}`;
export const b2c_redirect_uri = `${process.env.REACT_APP_B2C_REDIRECT_URI}`;
export const b2c_password_reset_redirect_uri = `${process.env.REACT_APP_B2C_PASSWORD_RESET_REDIRECT_URI}`;
export const b2c_passwordResetErrorId = `${process.env.REACT_APP_B2C_PASSWORD_RESET_ERROR_ID}`;
export const token_refresh_redirect_uri = `${process.env.REACT_APP_B2C_TOKEN_REFRESH_REDIRECT_URI}`;
export const token_id_claim = "name";
export const email_claim = "email";
export const token_userType_claim = "hgemUserType";
export const token_managerDetails_claim = "hgemManagerDetails";
export const token_allowedClients_claim = "hgemManagerAllowedClients";
export const default_gem_user = 126945;
export const default_gem_client = 126914;

// B2C Error codes: https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
// Fallback used to ensure we capture B2C password reset flows
export const fallback_password_reset_error_id = "AADB2C90118";

const localStorageAvailable = (): boolean => {
  const localStorage = window["localStorage"];

  try {
    const x = "__storage_test__";
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === "QuotaExceededError" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      localStorage &&
      localStorage.length !== 0
    );
  }
};

const browserRequiresCookieState = (): boolean => {
  let requiresCookie = false;

  try {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    const msie11 = ua.indexOf("Trident/");
    const msedge = ua.indexOf("Edge/");
    const isIE = msie > 0 || msie11 > 0;
    const isEdge = msedge > 0;
    const canUseLocalStorage = localStorageAvailable();

    requiresCookie = isIE || isEdge || !canUseLocalStorage;
  } catch (error) {
    console.log(
      "Config Warning: Failed to check browser auth-cookie requirements"
    );
  }

  return requiresCookie;
};

const msalLogLevel = msal.LogLevel.Error;
const msalConfiguration = (): Configuration => {
  return {
    auth: {
      clientId: b2c_clientId,
      authority: authority_login,
      knownAuthorities: b2c_knownAuthorities.split("|"),
      redirectUri: b2c_redirect_uri,
      postLogoutRedirectUri: b2c_redirect_uri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: browserRequiresCookieState(),
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          if (level <= msalLogLevel) {
            try {
              if (Logging) {
                Logging.captureEvent(`MSAL Logger: ${level}`, message);
              }
            } catch {
              console.log("msal: logging error");
            }
          }
        },
        piiLoggingEnabled: false,
        logLevel: msalLogLevel,
      },
      iframeHashTimeout: 10000,
    },
  };
};

export const msalInstance = new msal.PublicClientApplication(
  msalConfiguration()
);
export const requestScope = {
  scopes: [api_token_scope],
};

msalInstance.addEventCallback((callback: msal.EventMessage) => {
  if (callback.eventType === msal.EventType.LOGIN_FAILURE) {
    const message = (callback.error?.message ?? "").toUpperCase();

    if (
      message.includes(
        b2c_passwordResetErrorId ?? fallback_password_reset_error_id
      )
    ) {
      handlePasswordResetRedirect();
    } else if (Logging) {
      Logging.captureError(
        `MSAL Login Failure: ${message}`,
        new Error(callback.error?.toString()),
        SeverityLevel.Error
      );
    }
  }

  if (callback.eventType === msal.EventType.LOGIN_SUCCESS) {
    const authResult = callback.payload as msal.AuthenticationResult;

    if (
      authResult.authority &&
      authResult.authority
        .toLowerCase()
        .indexOf(authority_password_reset.toLowerCase()) >= 0
    ) {
      handlePostPasswordResetRedirect();
    }
  }

  if (callback.error) {
    console.error(callback.error);
  }
});

export const handlePasswordResetRedirect = (): void => {
  msalInstance
    .acquireTokenRedirect({
      authority: authority_password_reset,
      scopes: [api_token_scope],
      redirectStartPage: b2c_redirect_uri,
      redirectUri: b2c_redirect_uri,
    })
    .catch((e) => {
      const message = Logging.trySerializeErrorObject(e);
      Logging.captureEvent("Auth-Flow-Callback-Event", message);
    });
};

const handlePostPasswordResetRedirect = (): void => {
  msalInstance
    .logoutRedirect({
      authority: authority_login,
      postLogoutRedirectUri: b2c_redirect_uri,
    })
    .catch((e) => {
      const message = Logging.trySerializeErrorObject(e);
      Logging.captureEvent("Auth-Flow-Callback-Event", message);
    });
};
