import React from "react";
import Alert from "@material-ui/lab/Alert";
import i18n from "../../../localizations/i18n";
import { VisitListEmptyStyles } from "../styles/visitListEmptyStyles";

const VisitListEmpty = () => {
  const classes = VisitListEmptyStyles();

  return (
    <div className={classes.emptyVisitsMessage}>
      <Alert elevation={1} variant="filled" severity="info">
        {i18n.translate("VISIT_DETAILS_LIST_Empty_List_Message")}
      </Alert>
    </div>
  );
};

export default VisitListEmpty;
