import { ButtonBase, Badge, Tooltip } from "@material-ui/core";
import { IVisitDetail } from "../../../state/types/VisitDetails";
import { InboxItemStyles } from "../styles/inboxItemStyles";
import i18n from "../../../localizations/i18n";
import dayjs from "dayjs";
import InboxListIcon, { iconMap } from "./inboxListIcons";
import { textColourFinder, customIconMap } from "../utils";
import { HGEMVisitType } from "../../../utils/hgem";
import { ClientBorderThreshold } from "../../../state/api";
import ConversationIcon from "../../conversationsViews/components/conversationIcon";

interface IProps {
  visitDetails: IVisitDetail;
  isSelected: boolean;
  borderColorThresholds: ClientBorderThreshold;
  tasksCount: number | undefined;
  onItemSelected: (id: number) => void;
  showGuestContact?: boolean;
}

const InboxItem = (props: IProps) => {
  const classes = InboxItemStyles();
  const selectedStyle = props.isSelected ? "selected" : "";

  const visitType = (visitDetails: IVisitDetail) => {
    if (
      visitDetails.type === HGEMVisitType.Conversation &&
      visitDetails.conversationSource
    ) {
      return <ConversationIcon source={visitDetails.conversationSource} />;
    }

    let icon: keyof typeof iconMap | keyof typeof customIconMap | undefined;
    if (
      visitDetails.source &&
      visitDetails.source.toLowerCase() in customIconMap
    ) {
      icon = visitDetails.source as
        | keyof typeof iconMap
        | keyof typeof customIconMap;
    } else {
      icon = visitDetails.type as
        | keyof typeof iconMap
        | keyof typeof customIconMap;
    }
    return visitDetails.type ? (
      <InboxListIcon icon={icon} className={`${classes.inboxIcon}`} />
    ) : undefined;
  };
  const borderColor = () => {
    return textColourFinder(
      props.visitDetails.score,
      props.borderColorThresholds
    );
  };
  const title = (visitDetails: IVisitDetail) => {
    return (
      <span className={`${classes.visitTitle}`}>{visitDetails.branch}</span>
    );
  };

  const score = (visitDetails: IVisitDetail) => {
    return (
      <span className={`${classes.score}`}>
        {Math.round(visitDetails.displayScore as number)}
      </span>
    );
  };

  const visitDate = (visitDetails: IVisitDetail) => {
    const dateTimeString = `${dayjs(visitDetails.date).format(
      i18n.translate("COMMON_DATE_AND_TIME_FORMAT")
    )} ${dayjs(visitDetails.time).format(
      i18n.translate("COMMON_24_HR_TIME_FORMAT")
    )}`;

    return (
      <span
        className={`${classes.infoBullet} ${classes.wideColumnVisibleOverflow}`}
      >
        {dateTimeString}
      </span>
    );
  };

  const actions = (actionCount: number) => {
    return actionCount > 0 ? (
      <Badge
        badgeContent={actionCount}
        classes={{ badge: classes.actionsBadge }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <InboxListIcon
          icon="list"
          className={`${classes.inboxIcon} ${classes.bottomRightIcons} ${classes.grayIcon}`}
        />
      </Badge>
    ) : undefined;
  };

  const media = (visitDetails: IVisitDetail) => {
    return visitDetails.hasMedia || visitDetails.hasReceipt ? (
      <InboxListIcon
        icon="media"
        className={`${classes.inboxIcon} ${classes.bottomRightIcons} ${classes.grayIcon}`}
      />
    ) : undefined;
  };

  const appeal = (visitDetails: IVisitDetail) => {
    const className = visitDetails.hasAppealForReview
      ? `${classes.inboxIcon} ${classes.bottomRightIcons} ${classes.blueIcon}`
      : `${classes.inboxIcon} ${classes.bottomRightIcons} ${classes.grayIcon}`;
    return visitDetails.hasAppeal ? (
      <InboxListIcon icon="appeal" className={className} />
    ) : undefined;
  };

  return (
    <Tooltip
      classes={{ tooltip: classes.toolTip }}
      enterDelay={1500}
      placement="bottom"
      PopperProps={{ disablePortal: true }}
      arrow={true}
      title={
        <dl className={`${classes.flexDl}`}>
          <dt className={`${classes.flexDt}`}>
            {i18n.translate("INBOX_CARD_visit_number")}
          </dt>
          <dd className={`${classes.flexDd}`}>{props.visitDetails.id}</dd>
          {props.visitDetails.source && (
            <>
              <dt className={`${classes.flexDt}`}>
                {i18n.translate("INBOX_CARD_visit_source")}
              </dt>
              <dd className={`${classes.flexDd}`}>
                {i18n.translate(
                  `VISIT_SOURCE_OPTION_${props.visitDetails.source}`
                )}
              </dd>
            </>
          )}
          <dt className={`${classes.flexDt}`}>
            {i18n.translate("INBOX_CARD_visit_type")}
          </dt>
          <dd className={`${classes.flexDd}`}>
            {i18n.translate(`VISIT_TYPE_OPTION_${props.visitDetails.type}`)}
          </dd>
          <dt className={`${classes.flexDt}`}>
            {i18n.translate(`VISIT_DETAILS_REVIEW_HEADER_Area_Prefix`)}
          </dt>
          <dd className={`${classes.flexDd}`}>{props.visitDetails.area}</dd>
        </dl>
      }
    >
      <ButtonBase
        onClick={() => props.onItemSelected(props.visitDetails.id)}
        className={`${
          classes.visitDetailsItem
        } ${selectedStyle} ${borderColor()}`}
      >
        <div className={classes.simpleFlexRow}>
          <div className={`${classes.iconFlexColumm} ${classes.iconMargin}`}>
            {visitType(props.visitDetails)}
            {score(props.visitDetails)}
          </div>
          <div className={`${classes.flexColumn} ${classes.fullWidthFlex}`}>
            <div className={`${classes.flexRow}`}>
              {title(props.visitDetails)}
            </div>
            <div className={classes.flexRow}>
              {visitDate(props.visitDetails)}
              <span className={`${classes.flexSpan}`}>
                {media(props.visitDetails)}
                {actions(props.tasksCount ? props.tasksCount : 0)}

                {appeal(props.visitDetails)}
              </span>
            </div>
          </div>
        </div>
      </ButtonBase>
    </Tooltip>
  );
};

export default InboxItem;
