import { SagaMiddleware } from "redux-saga";
import { put, takeEvery } from "redux-saga/effects";
import {
  hubVersionUpdateIncoming,
  newHubVersionAvailable,
} from "../actions/HubVersionUpdate-Actions";
import {
  SignalRNotificationAction,
  SIGNALR_NOTIFICATION,
} from "../actions/SignalRNotifications-Actions";
import { SignalRNotificationTypes } from "../types/SignalRNotifications";

export function registerHubVersionUpdateSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeEvery(SIGNALR_NOTIFICATION, signalRNotification);
  });
}

function* signalRNotification(action: SignalRNotificationAction): unknown {
  const notification = action.notification;

  if (
    notification.notificationType ===
    SignalRNotificationTypes.HubUiUpdateStarting
  ) {
    yield put(hubVersionUpdateIncoming());
  }

  if (
    notification.notificationType ===
    SignalRNotificationTypes.HubUiUpdateCompleted
  ) {
    yield put(newHubVersionAvailable());
  }
}
