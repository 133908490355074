import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { getAudioData, UnavailableStatus } from "../state/api";
import i18n from "../../../localizations/i18n";
import { VisitAudioModalStyles } from "../styles/visitAudioModalStyles";
import { saveAs } from "file-saver";

interface IProps {
  visitId: number;
  fileName: string;
  onClose: () => void;
}

const VisitAudioModal = (props: IProps) => {
  const classes = VisitAudioModalStyles();
  const [audioData, setAudioData] = useState("");

  const handleDownload = () => {
    const fullFilename = `${i18n.translate(
      "VISIT_DETAILS_AUDIO_MODAL_Filename_Prefix"
    )}-${props.visitId}.mp3`;
    saveAs(audioData, fullFilename);
  };

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    const fetchAudio = async () => {
      const audio = await getAudioData(props.visitId, props.fileName);
      setAudioData(audio);
    };

    if (audioData.length === 0) {
      fetchAudio();
    }
  }, [audioData.length, props.fileName, props.visitId]);

  return (
    <Dialog className={classes.dialog} open={true} onClose={props.onClose}>
      <DialogTitle>
        {i18n.translate("VISIT_DETAILS_AUDIO_MODAL_Title")}
      </DialogTitle>
      <DialogContent>
        {audioData !== UnavailableStatus && audioData.length > 0 && (
          <>
            <audio controls className={classes.audioPlayer}>
              <source src={audioData} type="audio/mpeg" />
              {i18n.translate("VISIT_DETAILS_AUDIO_MODAL_No_Audio_Support")}
            </audio>
          </>
        )}
        {audioData.length === 0 && (
          <div className={classes.pendingContainer}>
            <LinearProgress color="primary" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleDownload}>
          {i18n.translate("VISIT_DETAILS_AUDIO_MODAL_Download")}
        </Button>
        <Button variant="contained" onClick={handleClose}>
          {i18n.translate("VISIT_DETAILS_AUDIO_MODAL_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisitAudioModal;
