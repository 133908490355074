import { Dispatch } from "redux";
import { setReportViewerDataExportState } from "../../../state/actions/ReportViewer-Actions";
import { i18n } from "../../../localizations";

export interface IPageAction {
  optionText: string;
  optionIcon: string;
  onSelect: () => void;
}

class PageActions {
  private pageTasks: IPageAction[] = [];
  private dispatch: Dispatch | undefined;

  constructor(dispatch?: Dispatch) {
    if (dispatch) {
      this.dispatch = dispatch;
    }
  }

  public WithDataExport = (): PageActions => {
    if (this.dispatch) {
      this.pageTasks.push({
        optionText: i18n.translate("PAGE_TASKS_Export_Data"),
        optionIcon: "fas fa-cloud-download-alt",
        onSelect: () => {
          if (this.dispatch) {
            this.dispatch(setReportViewerDataExportState(true));
          }
        },
      } as IPageAction);
    }

    return this;
  };

  public GetTasks = (): IPageAction[] => {
    return this.pageTasks;
  };
}

export default PageActions;
