import { IReportLayout } from "../state/types/ReportConfiguration";

class PbiContainerSizing {
  private resizePercentage = 0.2;
  private containerHeightPixelPadding = 30;
  private availableWidthPixelOffset = 50;

  public getContainerLayout(
    reportWidth: number | undefined,
    reportHeight: number | undefined,
    availableWidth: number
  ): IReportLayout {
    if (!reportWidth || !reportHeight) {
      return { width: 0, height: 0 } as IReportLayout;
    }

    let width = reportWidth;
    let height = reportHeight;

    availableWidth = availableWidth - this.availableWidthPixelOffset;
    const reportAspectRatio = reportHeight / reportWidth;

    const reportWidthLarge = reportWidth + reportWidth * this.resizePercentage;
    const reportWidthSmall = reportWidth - reportWidth * this.resizePercentage;

    if (availableWidth > reportWidthLarge) {
      width = reportWidthLarge;
      height = Math.round(reportWidthLarge * reportAspectRatio);
    } else if (availableWidth < reportWidthSmall) {
      width = reportWidthSmall;
      height = Math.round(reportWidthSmall * reportAspectRatio);
    } else {
      width = availableWidth;
      height = Math.round(availableWidth * reportAspectRatio);
    }

    return {
      width,
      height: height + this.containerHeightPixelPadding,
    } as IReportLayout;
  }
}

export default PbiContainerSizing;
