import React from "react";

interface IProps {
  children: React.ReactElement;
  value: number;
  index: number;
}

const TabPanel = (props: IProps) => {
  if (props.value === props.index) {
    return props.children;
  } else {
    return null;
  }
};

export default TabPanel;
