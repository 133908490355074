import { IAppNotification } from "../types/AppNotification";
import {
  AppNotificationActions,
  APP_NOTIFICATION_DELETE,
  APP_NOTIFICATION_ADD,
  APP_NOTIFICATION_ADD_IF_NOT_EXISTS,
  APP_NOTIFICATION_DELETE_BY_ID,
  APP_NOTIFICATION_USER_ACKNOWLEDGED,
} from "../actions/AppNotification-Actions";

export interface IAppNotificationState {
  notifications: IAppNotification[];
  hasUnreadNotifications: boolean;
}

const INITIAL_STATE: IAppNotificationState = {
  notifications: [],
  hasUnreadNotifications: false,
};

export default function AppNotificationReducer(
  state: IAppNotificationState = INITIAL_STATE,
  action: AppNotificationActions
): IAppNotificationState {
  switch (action.type) {
    case APP_NOTIFICATION_ADD:
      return {
        notifications: addNotification(
          state.notifications,
          action.notification
        ),
        hasUnreadNotifications:
          action.notification.ShowInNotificationCentre ||
          state.hasUnreadNotifications,
      };
    case APP_NOTIFICATION_ADD_IF_NOT_EXISTS:
      return {
        hasUnreadNotifications:
          action.notification.ShowInNotificationCentre ||
          state.hasUnreadNotifications,
        notifications: addNotificationIfNotExists(
          state.notifications,
          action.notification
        ),
      };
    case APP_NOTIFICATION_DELETE:
      return {
        ...state,
        notifications: state.notifications.filter(
          (x) => x !== action.notification
        ),
      };
    case APP_NOTIFICATION_DELETE_BY_ID:
      return {
        ...state,
        notifications: state.notifications.filter(
          (x) => x.Identifier !== action.identifier
        ),
      };
    case APP_NOTIFICATION_USER_ACKNOWLEDGED:
      return {
        ...state,
        hasUnreadNotifications: false,
      };
    default:
      return state;
  }
}

const addNotification = (
  notifications: IAppNotification[],
  newNotification: IAppNotification
): IAppNotification[] => {
  const n = notifications.filter(
    (x) => x.Identifier !== newNotification.Identifier
  );

  n.push(newNotification);
  return n;
};

const addNotificationIfNotExists = (
  notifications: IAppNotification[],
  newNotification: IAppNotification
): IAppNotification[] => {
  const existingNotification = notifications.find(
    (x) =>
      x.Identifier === newNotification.Identifier &&
      x.Type === newNotification.Type
  );

  return existingNotification
    ? [...notifications]
    : [...notifications, newNotification];
};
