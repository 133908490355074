import { makeStyles, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import i18n from "../../localizations/i18n";
import { routes } from "../../navigation/routes";
import { hgemColours, hgemFont } from "../../themes/defaultTheme";

const useStyles = makeStyles<Theme>(() => ({
  container: {
    padding: "20px",
    display: "block",
    color: hgemColours.DarkGrey,
    fontFamily: hgemFont,
  },
  logo: {
    maxWidth: "250px",
  },
  innerMessage: {
    "& p": {
      marginTop: "0px",
    },
  },
}));

const NotFound = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const buildContactMessage = () => {
    const homePageLinkText = i18n.translate("NOTFOUND_HomePage_Text");
    const contactMessage = i18n.translate("NOTFOUND_Alternative_links");
    const contactLink = i18n.translate("NOTFOUND_Alternative_contact_page");
    const contactPlaceholder = i18n.translate(
      "NOTFOUND_Alternative_contact_page_token"
    );

    return (
      <div className={classes.innerMessage}>
        <a href={routes.inbox}>{homePageLinkText}</a>
        <p>
          {contactMessage}
          <a target="_blank" rel="noopener noreferrer" href={contactLink}>
            {contactPlaceholder}
          </a>
        </p>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="/hgem-logo-horizontal.png"
        alt="HGEM Logo"
      />
      <div>
        <h2>{i18n.translate("NOTFOUND_Title")}</h2>
        {buildContactMessage()}
      </div>
    </div>
  );
};

export default NotFound;
