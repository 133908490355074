import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  caseList: {
    padding: "10px",
    maxHeight: "45vh",
    overflowY: "scroll",
    overflowX: "clip",
  },
}));

export const CaseEventListStyles = () => {
  return useStyles();
};
