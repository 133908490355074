import { useSpring, animated, useChain, useSpringRef } from "react-spring";

const AnimatedHgemHands = () => {
  const dashRef = useSpringRef();
  const dashProps = useSpring({
    to: {
      x: 0,
      opacity: 1,
    },
    from: {
      x: 300,
      opacity: 0.6,
    },
    config: { duration: 2500 },
    ref: dashRef,
  });

  const opacityRef = useSpringRef();
  const opacityProps = useSpring({
    to: { x: 1 },
    from: { x: 0 },
    config: { duration: 500 },
    ref: opacityRef,
  });

  useChain([dashRef, opacityRef]);

  return (
    <>
      <animated.svg
        strokeDashoffset={dashProps.x}
        strokeOpacity={dashProps.opacity}
        fillOpacity={opacityProps.x}
        style={{ margin: "20px", width: "300px", height: "300px" }}
        viewBox="0 0 75 75"
        strokeWidth="1"
        fill="#4f575c"
        stroke="#4f575c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="300"
      >
        <path
          d="M 39.77,43.66
           C 39.77,43.66 39.77,61.09 39.77,61.09
             39.77,64.89 36.67,68.00 32.87,68.00
             32.87,68.00 23.78,68.00 23.78,68.00
             20.58,68.00 16.89,65.90 15.29,63.09
             15.29,63.09 1.70,39.66 1.70,39.66
             0.10,36.85 0.10,32.55 1.70,29.74
             1.70,29.74 17.29,2.90 17.29,2.90
             18.09,1.50 19.88,1.00 21.28,1.80
             22.68,2.60 23.18,4.41 22.38,5.81
             22.38,5.81 6.79,32.65 6.79,32.65
             6.20,33.65 6.20,35.65 6.79,36.65
             6.79,36.65 20.28,60.19 20.28,60.19
             20.88,61.19 22.68,62.19 23.78,62.19
             23.78,62.19 32.87,62.19 32.87,62.19
             33.47,62.19 33.97,61.69 33.97,61.09
             33.97,61.09 33.97,43.56 33.97,43.56
             33.97,41.96 35.27,40.66 36.87,40.66
             38.47,40.66 39.77,41.96 39.77,43.66 Z"
        />

        <path
          d="M 33.97,25.74
           C 33.97,25.74 33.97,8.31 33.97,8.31
             33.97,4.51 37.07,1.40 40.87,1.40
             40.87,1.40 49.96,1.40 49.96,1.40
             53.16,1.40 56.86,3.51 58.45,6.31
             58.45,6.31 71.94,29.74 71.94,29.74
             73.54,32.55 73.54,36.85 71.94,39.66 
             71.94,39.66 56.46,66.50 56.46,66.50
             55.96,67.50 54.96,68.00 53.96,68.00
             53.46,68.00 52.96,67.90 52.46,67.60
             51.06,66.80 50.56,64.99 51.36,63.59
             51.36,63.59 66.95,36.65 66.95,36.65
             67.55,35.65 67.55,33.65 66.95,32.65
             66.95,32.65 53.46,9.21 53.46,9.21
             52.86,8.21 51.06,7.21 49.96,7.21
             49.96,7.21 40.87,7.21 40.87,7.21
             40.27,7.21 39.77,7.71 39.77,8.31
             39.77,8.31 39.77,25.74 39.77,25.74
             39.77,27.34 38.47,28.64 36.87,28.64
             35.27,28.64 33.97,27.34 33.97,25.74
             Z"
        />
      </animated.svg>
    </>
  );
};

export default AnimatedHgemHands;
