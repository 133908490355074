import { Grid } from "@material-ui/core";
import React from "react";
import { ICaseDetails } from "../../../state/types/TaskCentreCases";

export enum CaseLayout {
  fullWidth,
  grid,
}

interface IProps {
  before?: (caseDetails: ICaseDetails) => React.ReactElement;
  className?: string;
  caseDetails: ICaseDetails;
  status: (caseDetails: ICaseDetails) => React.ReactElement;
  assignee: (caseDetails: ICaseDetails) => React.ReactElement;
  priority: (caseDetails: ICaseDetails) => React.ReactElement;
  category: (caseDetails: ICaseDetails) => React.ReactElement;
  dueDate: (caseDetails: ICaseDetails) => React.ReactElement;
  comments?: (caseDetails: ICaseDetails) => React.ReactElement | undefined;
  guestContact?: (caseDetails: ICaseDetails) => React.ReactElement | undefined;
  after?: (caseDetails: ICaseDetails) => React.ReactElement;
  onSelected?: (id: number) => void;
  caseLayout?: CaseLayout;
}

const CaseItem = (props: IProps) => {
  if (props.caseLayout && props.caseLayout === CaseLayout.grid) {
    return (
      <div className={props.className} data-id={props.caseDetails.id}>
        {props.before ? props.before(props.caseDetails) : null}
        <Grid container alignItems="baseline">
          {props.status(props.caseDetails)}
          {props.assignee(props.caseDetails)}
          {props.priority(props.caseDetails)}
          {props.category(props.caseDetails)}
          {props.dueDate(props.caseDetails)}
          {props.comments ? props.comments(props.caseDetails) : null}
          {props.guestContact ? props.guestContact(props.caseDetails) : null}
        </Grid>
        {props.after ? props.after(props.caseDetails) : null}
      </div>
    );
  } else {
    return (
      <div className={props.className} data-id={props.caseDetails.id}>
        {props.before ? props.before(props.caseDetails) : null}
        {props.status(props.caseDetails)}
        {props.assignee(props.caseDetails)}
        {props.priority(props.caseDetails)}
        {props.category(props.caseDetails)}
        {props.dueDate(props.caseDetails)}
        {props.comments ? props.comments(props.caseDetails) : null}
        {props.guestContact ? props.guestContact(props.caseDetails) : null}
        {props.after ? props.after(props.caseDetails) : null}
      </div>
    );
  }
};

export default CaseItem;
