import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  titleInput: {
    minWidth: "300px",
    marginTop: "15px",

    "& label": {
      color: theme.palette.grey[400],
    },
  },
}));

export const TaskCreatorStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
