import { Dialog, Icon, IconButton, LinearProgress } from "@material-ui/core";
import { i18n } from "../../../../localizations";
import { VisitPhotoModalStyles } from "../../../inboxMedia/styles/visitPhotoModalStyles";
import { MediaLinks } from "./types/mediaLinks";

interface IProps {
  mediaLinks: MediaLinks | undefined;
  onClose: () => void;
  onDownload: () => void;
}

const MediaMessageImageModal = (props: IProps) => {
  const classes = VisitPhotoModalStyles();

  return (
    <Dialog className={classes.dialog} open={true} onClose={props.onClose}>
      {props.mediaLinks && (
        <>
          <div className={classes.imageOptions}>
            <IconButton
              size="small"
              onClick={props.onClose}
              title={i18n.translate("MEDIA_MESSAGE_IMAGE_MODAL_Close")}
            >
              <Icon className="far fa-window-close" />
            </IconButton>
            <IconButton
              size="small"
              onClick={props.onDownload}
              title={i18n.translate("MEDIA_MESSAGE_IMAGE_MODAL_Download")}
            >
              <Icon className="fas fa-download" />
            </IconButton>
          </div>
          <img
            src={props.mediaLinks.localUrl}
            alt={i18n.translate("MEDIA_MESSAGE_IMAGE_Alt_Text")}
          />
        </>
      )}
      {!props.mediaLinks && (
        <div className={classes.pendingContainer}>
          <LinearProgress color="primary" />
          <Icon className={`fas fa-camera ${classes.imagePending}`} />
        </div>
      )}
    </Dialog>
  );
};

export default MediaMessageImageModal;
