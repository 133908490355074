import { hgemColours } from "../../../../themes/defaultTheme";
import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  commentsContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "40vh",
    overflow: "auto",
  },

  CommentInputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  disabledIconButton: {
    borderRadius: "10%",
    fontSize: "2.5em",
    display: "flex",
    alignItems: "center",
  },
  sendIcon: {
    color: hgemColours.LimeGreen,
    borderRadius: "10%",
    fontSize: "2.5em",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  iconButton: {
    alignSelf: "center",
    padding: "3%",
  },
  textfield: {
    backgroundColor: hgemColours.White,
  },
  commentInput: {
    marginTop: "10px",

    "& label": {
      color: theme.palette.grey[400],
    },

    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
  },
  charsRemaining: {
    fontSize: "0.8em",
    color: theme.palette.grey[500],
    alignSelf: "flex-start",

    "&.char-overflow": {
      color: hgemColours.Red,
      fontWeight: "bold",
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  postButtonError: {
    position: "absolute",
    right: 0,
    color: hgemColours.Red,
  },

  flexSpan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "flex-end",
    marginTop: "1%",
  },
}));

export const CommentFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
