import { Grid, LinearProgress } from "@material-ui/core";

const AuthLoader = () => {
  return (
    <>
      <span className="no-display">Processing Authentication...</span>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <LinearProgress />
        </Grid>
      </Grid>
    </>
  );
};

export default AuthLoader;
