import { combineReducers } from "redux";
import UserProfileReducer from "./UserProfile-Reducer";
import UserImpersonationReducer from "./UserImpersonation-Reducer";
import ReportConfigurationReducer from "./ReportConfiguration-Reducer";
import FilterOptionsReducer from "./FilterOptions-Reducer";
import FilterSetsReducer from "./FilterSets-Reducer";
import TaskCentreCaseListReducer from "./TaskCentre-CaseList-Reducer";
import AppNotificationReducer from "./AppNotification-Reducer";
import GuestDetailsReducer from "./GuestDetails-Reducer";
import VisitReportReducer from "./VisitReport-Reducer";
import ReportViewerReducer from "./ReportViewer-Reducer";
import VisitDetailsReducer from "./VisitDetails-Reducer";
import AppealsReducer from "./Appeals-Reducer";
import VouchersReducer from "./Vouchers-Reducer";
import ConversationsReducer from "./Conversations-Reducer";
import HubVersionUpdateReducer from "./HubVersionUpdate-Reducer";

const rootReducer = combineReducers({
  UserProfileReducer,
  UserImpersonationReducer,
  ReportConfigurationReducer,
  FilterOptionsReducer,
  FilterSetsReducer,
  TaskCentreCaseListReducer,
  AppNotificationReducer,
  GuestDetailsReducer,
  VisitReportReducer,
  ReportViewerReducer,
  VisitDetailsReducer,
  AppealsReducer,
  VouchersReducer,
  ConversationsReducer,
  HubVersionUpdateReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
