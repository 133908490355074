import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { i18n } from "../../../../localizations";
import { ConversationManagers } from "../../../../state/selectors/Conversations-Selectors";
import {
  ConversationMessage,
  ConversationSourceEnum,
} from "../../../../state/types/Conversations";
import { EventHeaderStyles } from "../../../taskCentreEvents/styles/eventHeaderStyles";
import { formatSender } from "../../utils";

type MessageHeaderProps = {
  message: ConversationMessage;
  source: ConversationSourceEnum | undefined;
};

const MessageHeader = (props: MessageHeaderProps) => {
  const classes = EventHeaderStyles();

  const managers = useSelector(ConversationManagers);

  const authorName = props.message.isInbound
    ? formatSender(props.message.sender, props.source)
    : managers.find((x) => x.id == props.message.createdByManagerId)?.name;
  const dayJsDate = dayjs(props.message.createdDate, {
    utc: true,
  });

  let dateText: string | undefined;
  let dateFormat: string | undefined;

  if (props.message.createdDate) {
    const daysAgo = dayJsDate.diff(dayjs(), "day");

    switch (daysAgo) {
      case 0:
        dateFormat = i18n.translate("CONVERSATION_MESSAGE_TIME_FORMAT");
        break;
      case 1:
        dateText = i18n.translate("CONVERSATION_MESSAGE_YESTERDAY");
        break;
      default:
        dateFormat = i18n.translate("CONVERSATION_MESSAGE_DATE_FORMAT");
        break;
    }
  } else {
    dateText = i18n.translate("CONVERSATION_MESSAGE_SENDING");
  }

  return (
    <div>
      {authorName && authorName.length > 0 && (
        <span className={classes.author}>{authorName}</span>
      )}
      <span className={classes.meta}>
        {dateText ?? dayJsDate.local().format(dateFormat)}
      </span>
    </div>
  );
};

export default MessageHeader;
