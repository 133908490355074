import React from "react";
import Alert from "@material-ui/lab/Alert";
import i18n from "../../../localizations/i18n";
import { CaseListEmptyStyles } from "../styles/caseListEmptyStyles";

const CaseListEmpty = () => {
  const classes = CaseListEmptyStyles();

  return (
    <div className={classes.emptyTasksMessage}>
      <Alert elevation={1} variant="filled" severity="info">
        {i18n.translate("TASK_CENTRE_CASE_LIST_Empty_List_Message")}
      </Alert>
    </div>
  );
};

export default CaseListEmpty;
