import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  navOption: {
    borderRadius: "5px",
    width: "100%",
    height: "40px",
    padding: "2%",
    cursor: "pointer",
    borderLeft: `2.5px solid ${hgemColours.LightGrey}`,
    fontFamily: "'Open Sans', Tahoma, Geneva, Verdana, sans-serif",
    fontWeight: 200,
    fontSize: "0.9em",
    color: theme.hubSettings.navigation.textColor,

    "&:hover": {
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
    },
    "&.Mui-selected": {
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
      margin: "0 3.5% 0 0%",
      paddingLeft: "2%",
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
    },
  },
  navOptionIcon: {
    verticalAlign: "sub",
    width: "1.2em",
    color: hgemColours.ExtraMediumGrey,
  },

  loader: {
    marginTop: "2%",
    marginLeft: "17.5%",
  },
  subNavOption: {
    marginBottom: "2%",
    marginLeft: "8%",
    padding: "0.5%",
    borderRadius: "5px",
    width: "100%",
    maxHeight: "30px",
    "&.Mui-selected": {
      marginBottom: "2%",

      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
    },
    "&:hover": {
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
    },
  },
  reportPageNavOption: {
    width: "100%",
    fontSize: "0.85em",
    marginLeft: "17.5%",
    cursor: "pointer",
    marginTop: "0.5%",
    maxHeight: "25px",
    "&.Mui-selected": {
      paddingLeft: "4.5%",
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
      borderRadius: "5px",
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
      transition: "border-left ease 0.5s",
    },
    "&:hover": {
      backgroundColor: theme.hubSettings.navigation.optionBackgroundColorHover,
    },
  },

  labelContainer: {
    marginLeft: "10.25%",
  },
  navOptionList: {
    marginTop: "5%",
  },
  hideBadge: {
    display: "none",
  },
  navigationLink: {
    color: hgemColours.DarkGrey,
    overflow: "visible",
    fontSize: "0.7rem",
    padding: "5%",
  },
  selectedNavigationLink: {
    paddingLeft: "3%",
  },
  stupidWeirdIconMargins: {
    marginRight: "2%",
    marginLeft: "3%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1% !important",
      marginRight: "0",
    },
  },
  stupidWeirdTemperatureIconMargins: {
    marginLeft: "5%",
    paddingRight: "1.5%",
    [theme.breakpoints.down("md")]: {
      width: "0.5em",
      marginLeft: "2.5%",
      marginRight: "3%",
      paddingRight: "0",
      overflow: "visible",
    },
  },
}));

export const NavigationOptionStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
