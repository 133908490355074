import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  selection: {
    float: "left",
    marginLeft: "5px",
  },
  selectionName: {
    fontWeight: 300,
    borderRight: `1px solid ${theme.palette.grey[800]}`,
    marginRight: "10px",
    paddingRight: "10px",
  },
}));

export const InboxTaskSelectorStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
