import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  noMoreItemsContainer: {
    margin: "10px 0",
    display: "flex",
    color: theme.palette.grey[600],
    fontSize: "0.8em",
  },
}));

export const ConversationListLoaderStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
