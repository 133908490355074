import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IAccountSelectionFormValues } from "./AccountSelectionFormValues";
import { Select, MenuItem, Button, Grid } from "@material-ui/core";
import i18n from "../../../localizations/i18n";

interface IProps {
  formValues: IAccountSelectionFormValues;
  showManagerSelection: boolean;
  onClientChange: (clientId: number) => void;
  onSubmit: (clientId: number, managerId: number | undefined) => void;
}

type FormData = {
  clientId: number;
  managerId: number;
}

const AccountSelectionForm = (props: IProps) => {
  const { handleSubmit, setValue, register } = useForm<FormData>();
  const onSubmit = (data: FormData) => props.onSubmit(data.clientId, data.managerId);

  const [clientId, setClientId] = useState(props.formValues.clientId);
  const handleClientChange = (e: React.ChangeEvent<{ value: unknown}>) => {
    const clientId =  e.target.value as number;
    setValue("ClientId", clientId);
    setClientId(clientId);

    if (clientId !== props.formValues.clientId) {
      props.onClientChange(clientId);
    }
  };

  const [managerId, setManagerId] = useState(props.formValues.managerId);
  const handleManagerChange = (e: React.ChangeEvent<{ value: unknown}>) => {
    const managerId = e.target.value as number;
    setValue("ManagerId", managerId);
    setManagerId(managerId);
  };

  const enforceManagerSelection = (): void => {
    const matched = props.formValues.availableManagers.filter((m) => {
      return m.id === managerId;
    });
    if (matched.length === 0) {
      setManagerId(props.formValues.managerId);
    }
  };

  useEffect(() => {
    register({ name: "clientId" });
    register({ name: "managerId" });
    setValue("clientId", clientId);
    setValue("managerId", managerId);
  }, [register, setValue, clientId, managerId]);

  if (props.showManagerSelection) {
    enforceManagerSelection();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={props.showManagerSelection ? 6 : 12}>
          <Select
            name="clientId"
            fullWidth
            onChange={handleClientChange}
            value={clientId}
          >
            {props.formValues.availableClients.map((c) => {
              return (
                <MenuItem value={c.id} key={c.id}>
                  {c.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        {props.showManagerSelection && (
          <Grid item xs={12} sm={6}>
            <Select
              fullWidth
              name="managerId"
              onChange={handleManagerChange}
              value={managerId}
            >
              {props.formValues.availableManagers.map((c) => {
                return (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {i18n.translate("ACCOUNT_SELECTION_SwitchAccount")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AccountSelectionForm;
