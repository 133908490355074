import React, { useRef, useCallback, useEffect } from "react";
import VisitListSkeleton from "../../inboxList/components/visitListSkeleton";

interface IProps {
  children: React.ReactElement;
  scrollCallback?: () => void;
  isLoading?: boolean;
  shouldFetchMore?: boolean;
}

const InfiniteScrollWindow = (props: IProps) => {
  const infiniteScrollActivate = useRef(null);
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            if (props.scrollCallback && !props.isLoading) {
              props.scrollCallback();
            }
          }
        });
      }).observe(node);
    },
    [props.scrollCallback]
  );

  useEffect(() => {
    if (infiniteScrollActivate.current && props.shouldFetchMore) {
      scrollObserver(infiniteScrollActivate.current);
    }
  }, [scrollObserver, infiniteScrollActivate, props.shouldFetchMore]);
  return (
    <>
      {props.children}
      {!props.isLoading && <div ref={infiniteScrollActivate}></div>}
      {props.isLoading && <VisitListSkeleton />}
    </>
  );
};

export default InfiniteScrollWindow;
