import axios, { AxiosResponse } from "axios";
import { IGraphQueryResponse } from "../graphs/types";

export interface ClientBorderThresholdsResponse{
    queryForClient : ClientBorderThresholdOptions;
}

export interface ClientBorderThresholdOptions{
    nodes : ClientBorderThreshold[];
}

export interface ClientBorderThreshold {
    highThreshold : number,
    lowThreshold : number
}

export const clientBorderColorThresholds = (
    clientId: number
): Promise<AxiosResponse<IGraphQueryResponse<ClientBorderThresholdsResponse>>> => {
    const variables = { clientId };

    const query = "GetClientThresholdsByClientId";
    const variableJson = JSON.stringify(variables);

    return axios.get<IGraphQueryResponse<ClientBorderThresholdsResponse>>(
        `${process.env.REACT_APP_GRAPH_CLIENTS_API_BASEURL}${query}/variables/${encodeURI(variableJson)}`
    );
};