import { Badge, ButtonBase, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  CaseEventType,
  ICaseDetails,
  ICaseEvent,
  WorkflowStatuses,
} from "../../../state/types/TaskCentreCases";
import { InboxItemStyles } from "../../inboxList/styles/inboxItemStyles";
import WorkflowStatusIcon from "../../_common/Icons/workflowStatusIcon";
import InboxListIcon, {
  iconMap,
} from "../../inboxList/components/inboxListIcons";
import { i18n } from "../../../localizations";
import { ClientBorderThreshold } from "../../../state/api";
import dayjs from "dayjs";
import { customIconMap } from "../../inboxList/utils";
type props = {
  caseDetails: ICaseDetails;
  isSelected: boolean;
  onItemSelected: (id: number) => void;
  borderColourThresholds: ClientBorderThreshold | undefined;
};

const TaskCard = (props: props) => {
  const classes = InboxItemStyles();
  const selectedStyle = props.isSelected ? "selected" : "";
  const [contactStatusIcon, setContactStatusIcon] = useState<
    keyof typeof iconMap | keyof typeof customIconMap | undefined
  >();
  const [contactStatus, setContactStatus] = useState<string>("");
  const [lastCommunicationDate, setLastCommunicationDate] = useState<
    string | undefined
  >();

  const guestAwaitingReply = () => {
    const messages = findMessageEvents();
    const sortedMessages = messages.sort((eventA, eventB): number => {
      return dayjs(eventA.timestamp).isBefore(eventB.timestamp) ? 1 : -1;
    });
    if (sortedMessages[0].type === CaseEventType.MessageReceivedEvent) {
      return true;
    }
    return false;
  };

  const daysOpen = (): number => {
    const dateOpened = dayjs(props.caseDetails.cDate);
    const now = dayjs();
    const diff = now.diff(dateOpened, "d");
    return diff;
  };

  const daysOpenText = () => {
    const days = daysOpen();
    return days === 1
      ? i18n.translate("TASK_CENTRE_CARD_Day_open")
      : i18n.translate("TASK_CENTRE_CARD_Days_open");
  };

  useEffect(() => {
    if (props.caseDetails.contact === false) {
      setContactStatusIcon("stop");
      setContactStatus(i18n.translate("TASK_CENTRE_CARD_no_contact_details"));
    } else {
      if (findMessageEvents().length === 0) {
        setContactStatusIcon("hourglass");
        setContactStatus(
          i18n.translate("TASK_CENTRE_CARD_awaiting_first_contact")
        );
      }
      if (findMessageEvents().length > 0 && guestAwaitingReply()) {
        setContactStatusIcon("comments");
        setContactStatus(i18n.translate("TASK_CENTRE_CARD_awaiting_reply"));
      }
      if (findMessageEvents().length > 0 && !guestAwaitingReply()) {
        setContactStatusIcon("sentemail");
        setContactStatus(i18n.translate("TASK_CENTRE_CARD_guest_contacted"));
      }
    }
  });

  const eventIsMessageBased = (caseEvent: ICaseEvent) => {
    return (
      caseEvent.type === CaseEventType.MessageSentEvent ||
      caseEvent.type === CaseEventType.MessageReceivedEvent
    );
  };

  const findMessageEvents = () => {
    return props.caseDetails.events.filter((caseEvent) =>
      eventIsMessageBased(caseEvent)
    );
  };
  useEffect(() => {
    const messageEventsArray = findMessageEvents();

    const sortedComms = messageEventsArray.sort((eventA, eventB) => {
      return dayjs(eventA.timestamp).isBefore(eventB.timestamp) ? 1 : -1;
    });

    if (sortedComms.length > 0) {
      setLastCommunicationDate(
        dayjs(sortedComms[0].timestamp).format(
          i18n.translate("TASK_CENTRE_Timestamp_Format")
        )
      );
    }
  }, [props.caseDetails]);

  const numberOfMessages = findMessageEvents().length;

  const voucherSent = props.caseDetails.events.some(
    (caseEvent) => caseEvent.type === CaseEventType.VoucherSent
  );

  const findWorkFlowStatusText = () => {
    const workflowStatus = WorkflowStatuses.filter(
      (status) => status.value === props.caseDetails.wfStatus
    );
    return workflowStatus[0].text;
  };

  return (
    <ButtonBase
      onClick={() => props.onItemSelected(props.caseDetails.id)}
      className={`${classes.visitDetailsItem} ${selectedStyle}`}
    >
      <div className={classes.simpleFlexRow}>
        <div
          className={`${classes.iconFlexColumm} ${classes.iconMargin} ${classes.flexColumnRowGap}`}
        >
          <Tooltip
            classes={{ tooltip: classes.smallTooltip }}
            enterDelay={1500}
            placement="bottom"
            PopperProps={{ disablePortal: true }}
            arrow={true}
            title={findWorkFlowStatusText()}
          >
            <span className={`${classes.visitIcon} ${classes.actionsIcon}`}>
              <WorkflowStatusIcon
                status={props.caseDetails.wfStatus}
                className={classes.actionsIcon}
              />
            </span>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.smallTooltip }}
            enterDelay={1500}
            placement="bottom"
            PopperProps={{ disablePortal: true }}
            arrow={true}
            title={contactStatus}
          >
            <span
              className={`${classes.actionsIcon} ${classes.darkGrayIcon} ${classes.leftMargin}`}
            >
              <InboxListIcon
                icon={contactStatusIcon}
                className={`${classes.darkGrayIcon}`}
              />
            </span>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.smallTooltip }}
            enterDelay={1500}
            placement="bottom"
            PopperProps={{ disablePortal: true }}
            arrow={true}
            title={i18n.translate("TASK_CENTRE_CARD_visit_score")}
          >
            <span className={`${classes.actionCardScore}`}>
              {Math.round(props.caseDetails.vScore as number) || 0}
            </span>
          </Tooltip>
        </div>
        <div className={`${classes.flexColumn} ${classes.fullWidthFlex}`}>
          <div className={`${classes.flexRow}`}>{props.caseDetails.title}</div>

          <Tooltip
            classes={{ tooltip: classes.smallTooltip }}
            enterDelay={1500}
            placement="bottom"
            PopperProps={{ disablePortal: true }}
            disableHoverListener={!lastCommunicationDate}
            arrow={true}
            title={
              <>
                {lastCommunicationDate && (
                  <span>
                    {i18n.translate("TASK_CENTRE_CARD_last_communication")}
                    <br />
                    {lastCommunicationDate}
                  </span>
                )}
              </>
            }
          >
            <div className={`${classes.flexRow}`}>
              <span>
                {i18n.translate("TASK_CENTRE_CREATED_DATE")}{" "}
                {dayjs(props.caseDetails.cDate).format(
                  i18n.translate("TASK_CENTRE_CARD_Timestamp_Format")
                )}{" "}
                <span className={classes.subText}>
                  {`${daysOpen()} ${daysOpenText()}`}
                </span>
              </span>
            </div>
          </Tooltip>

          <div className={`${classes.flexRow}`}>
            <Tooltip
              classes={{ tooltip: classes.smallTooltip }}
              enterDelay={1500}
              placement="bottom"
              PopperProps={{ disablePortal: true }}
              arrow={true}
              title={i18n.translate("TASK_CENTRE_CARD_assignee")}
            >
              <span
                className={`${classes.infoBullet} ${classes.wideColumnVisibleOverflow}`}
              >
                <strong>{props.caseDetails.branch} | </strong>
                {props.caseDetails.assigneeName ||
                  i18n.translate("TASK_CENTRE_CARD_no_assignee")}
              </span>
            </Tooltip>
            <span className={`${classes.flexSpan}`}>
              {voucherSent && (
                <Tooltip
                  classes={{ tooltip: classes.smallTooltip }}
                  enterDelay={1500}
                  placement="bottom"
                  PopperProps={{ disablePortal: true }}
                  arrow={true}
                  title={i18n.translate("TASK_CENTRE_CARD_voucher")}
                >
                  <span>
                    <InboxListIcon
                      icon="gift"
                      className={`${classes.inboxIcon} ${classes.bottomRightIcons} ${classes.darkGrayIcon}`}
                    />
                  </span>
                </Tooltip>
              )}
              {numberOfMessages > 0 && (
                <Tooltip
                  classes={{ tooltip: classes.smallTooltip }}
                  enterDelay={1500}
                  placement="bottom"
                  PopperProps={{ disablePortal: true }}
                  arrow={true}
                  title={i18n.translate("TASK_CENTRE_CARD_number_of_messages")}
                >
                  <span>
                    <Badge
                      badgeContent={numberOfMessages}
                      classes={{ badge: classes.Badge }}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      <InboxListIcon
                        icon="Email"
                        className={`${classes.inboxIcon} ${classes.bottomRightIcons} ${classes.darkGrayIcon}`}
                      />
                    </Badge>
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
        </div>
      </div>
    </ButtonBase>
  );
};

export default TaskCard;
