import { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { DesktopViewStyles } from "./styles/desktopViewStyles";
import i18n from "../../localizations/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectedCase,
  SelectedCaseCanUseGuestContact,
  SelectedCaseMessageCount,
  SelectedVisitHasMediaOrReceipts,
  SelectedVisitId,
  SelectedVisitType,
} from "./state/selectors";
import InboxTaskDetails from "../inboxTaskDetails/inboxTaskDetails";
import InboxMedia from "../inboxMedia/inboxMedia";
import InboxReport from "../inboxReport/inboxReport";
import TabPanel from "./components/tabPanel";
import TabLabel from "./components/tabLabel";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import { updateCurrentFiltersEntry } from "../../state/actions/FilterSets-Actions";
import { HubFilterType, IVisitIdFilter } from "../../state/types/FilterSets";
import IsAuthorised from "../../authorisation/permissions";
import { PermissionType } from "../../authorisation/permissionsUtils";

import InboxGuestContact from "../InboxGuestContact/inboxGuestContact";
import InboxAppealsList from "../inboxAppealsList/inboxAppealsList";
import TaskCategoriesLoader from "./components/taskCategoriesLoader";
import TaskManagersLoader from "./components/taskManagersLoader";
import { MobileViewStyles } from "./styles/mobileViewStyles";
import InboxAppeals from "../InboxAppeals/inboxAppeals";
import { IAppealsAcess } from "../../state/types/Appeals";
import { ClientBorderThreshold } from "../../state/api";
import { PageTabsStyles } from "../_common/pageTabs/styles/pageTabsStyles";
import { getVisitsApi } from "../../state/api/VisitDetails-API";
import { IVisitDetail } from "../../state/types/VisitDetails";
import { HGEMVisitType } from "../../utils/hgem";
import BuildVisitDetailsListQuery from "../inboxList/utils/visitDetailsListQueryBuilder";
import { RootState } from "../../state/reducers/RootReducer";
import { GetVisibleFilters } from "../reportFilterPanel/state/selectors";
import { useClientId } from "../../state/hooks/clients/useClientId";
import { useUserId } from "../../state/hooks/clients/useUserId";

interface IProps {
  pageRef: string;
  visitTypes: string[];
  isMobileView: boolean;
  appealsFeatureActive: boolean | undefined;
  hasAppealsAccess: IAppealsAcess | undefined;
  borderColorThresholds: ClientBorderThreshold | undefined;
}

enum TabIndex {
  Appeals = 0,
  Tasks = 1,
  GuestContact = 2,
  VisitReport = 3,
  Media = 4,
}

const AppealsView = (props: IProps) => {
  const classes = props.isMobileView ? MobileViewStyles() : DesktopViewStyles();
  const tabClassses = PageTabsStyles();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const [userCanReadGuestDetails, setUserCanReadGuestDetails] = useState(false);
  const [associatedVisit, setAssociatedVisit] = useState<IVisitDetail>();

  const queryVisitId = getUrlQueryValueNumber("visitid");
  const clientId = useClientId();
  const userId = useUserId();

  const selectedCase = useSelector(SelectedCase);
  const messageCount = useSelector(SelectedCaseMessageCount);
  const selectedVisitId = useSelector(SelectedVisitId);
  const selectedVisitType = useSelector(SelectedVisitType);
  const selectedVisitHasMediaOrReceipts = useSelector(
    SelectedVisitHasMediaOrReceipts
  );
  const currentlySelectedTab = () => {
    return tabIndex;
  };

  useEffect(() => {
    dispatch(
      updateCurrentFiltersEntry(HubFilterType.VisitId, (x: IVisitIdFilter) => {
        x.value = queryVisitId;
      })
    );
  }, [dispatch, queryVisitId]);

  useEffect(() => {
    const checkUserCanReadGuestDetails = async () => {
      const userCanReadDetails = await IsAuthorised(
        PermissionType.ReadGuestDetails
      );
      setUserCanReadGuestDetails(userCanReadDetails);
    };

    checkUserCanReadGuestDetails();
  });

  const mediaTabActive = selectedVisitHasMediaOrReceipts;
  const guestContactTabActive =
    (associatedVisit?.type === HGEMVisitType.Review ||
      associatedVisit?.type === HGEMVisitType.Conversation) &&
    associatedVisit?.contactAvailable &&
    userCanReadGuestDetails;

  const filters = useSelector((state: RootState) =>
    GetVisibleFilters(state, props.pageRef)
  );
  useEffect(() => {
    const fetchRelatedVisit = async () => {
      let relatedVisitQuery = BuildVisitDetailsListQuery(
        1,
        filters,
        [],
        [],
        selectedVisitId,
        undefined
      );
      relatedVisitQuery = { ...relatedVisitQuery, userId: userId };
      const visit = await getVisitsApi(clientId, relatedVisitQuery);

      setAssociatedVisit(visit.data.visits[0]);
    };
    fetchRelatedVisit();
  }, [dispatch, selectedVisitId, selectedCase]);

  useEffect(() => {
    if (
      (!mediaTabActive && tabIndex === TabIndex.Media) ||
      (!guestContactTabActive && tabIndex === TabIndex.GuestContact)
    ) {
      setTabIndex(TabIndex.Appeals);
    }
  }, [selectedVisitId]);
  return (
    <div className={classes.container}>
      <TaskCategoriesLoader />
      <TaskManagersLoader />
      <div className={classes.actionListOuterContainer}>
        <InboxAppealsList
          visitTypes={props.visitTypes}
          borderColorThresholds={props.borderColorThresholds}
          requestedVisitId={queryVisitId}
          listHeightOffset={250}
          pageRef={props.pageRef}
          showAsPopupList={props.isMobileView}
        />
      </div>
      <div className={classes.actionsTabsContainer}>
        <Tabs
          className={classes.tabs}
          variant="scrollable"
          scrollButtons={props.isMobileView ? "on" : "off"}
          indicatorColor="primary"
          classes={{ indicator: tabClassses.blankIndicator }}
          onChange={(_, value) => setTabIndex(value)}
          value={currentlySelectedTab()}
        >
          <Tab
            value={TabIndex.Appeals}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
            label={
              <TabLabel labelText={i18n.translate("INBOX_Appeals_Tabname")} />
            }
          />
          <Tab
            value={TabIndex.Tasks}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
            label={<TabLabel labelText={i18n.translate("INBOX_TABS_Tasks")} />}
          />
          {guestContactTabActive && (
            <Tab
              value={TabIndex.GuestContact}
              classes={{
                selected: tabClassses.selectedTab,
                root: tabClassses.inactiveTab,
              }}
              label={
                <TabLabel
                  labelText={i18n.translate("INBOX_TABS_GuestCommunications")}
                  showBadge={messageCount > 0}
                />
              }
            />
          )}

          <Tab
            value={TabIndex.VisitReport}
            classes={{
              selected: tabClassses.selectedTab,
              root: tabClassses.inactiveTab,
            }}
            label={i18n.translate("INBOX_TABS_VisitReport")}
          />
          {mediaTabActive && (
            <Tab
              value={TabIndex.Media}
              classes={{
                selected: tabClassses.selectedTab,
                root: tabClassses.inactiveTab,
              }}
              label={
                <TabLabel labelText={i18n.translate("INBOX_TABS_Media")} />
              }
            />
          )}
        </Tabs>
        <div style={{ height: "80vh", overflow: "auto" }}>
          {selectedVisitId && (
            <div className={classes.tabPanel}>
              <TabPanel value={tabIndex} index={TabIndex.Appeals}>
                <InboxAppeals visitId={selectedVisitId} />
              </TabPanel>

              <TabPanel value={tabIndex} index={TabIndex.Tasks}>
                <>
                  <InboxTaskDetails
                    visitId={selectedVisitId}
                    case={selectedCase}
                    showTaskSelector={false}
                    isMobileView={props.isMobileView}
                  />
                </>
              </TabPanel>
              <TabPanel value={tabIndex} index={TabIndex.GuestContact}>
                <>
                  {userCanReadGuestDetails && (
                    <InboxGuestContact
                      visitId={selectedVisitId}
                      case={selectedCase}
                    />
                  )}
                </>
              </TabPanel>
              <TabPanel value={tabIndex} index={TabIndex.VisitReport}>
                <InboxReport
                  visitId={selectedVisitId}
                  visitType={selectedVisitType}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={TabIndex.Media}>
                <InboxMedia visitId={selectedVisitId} />
              </TabPanel>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppealsView;
