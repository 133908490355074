import { hgemColours } from "../../../themes/defaultTheme";
import { Theme, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignContent: "space-evenly",
    paddingLeft: "1%",
    margin: "0% 0.5% 1% 0.5%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "5%",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "5%",
      alignItems: "flex-start",
    },
  },
  flexRowWithIcon: {
    alignItems: "center",
    gap: "2%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  flexItem: {
    flexGrow: 2,
    flexShrink: 2,
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  appealsQuestionRow: {
    marginTop: "3%",
  },
  wholeReportSwitch: {
    width: "29%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  appealBlueIcon: {
    color: "#62BEC4 !important",
  },
  selectedQuestionChips: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "2%",
    marginTop: "2%",
  },
  staticTextBlock: {
    width: "45%",
    fontSize: "1em",
    fontWeight: 400,
    color: `${hgemColours.DarkGrey}`,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  categoryTextBlock: {
    fontSize: "1em",
    fontWeight: 400,
    color: `${hgemColours.DarkGrey}`,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
    marginBottom: "2%",
  },
  appealClosedInfoRow: {
    alignItems: "baseline",
    marginTop: "1.5%",
    marginBottom: "1%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  smallGutter: {
    marginBottom: "5%",
  },
  flexOrderOne: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  flexOrderTwo: {
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  errorSpan: {
    color: `${hgemColours.Red}`,
    fontSize: "0.9em",
    margin: "1% 0 3% 0",
  },
  questionHeader: {
    fontSize: "1.1em",
    color: `${hgemColours.DarkGrey}`,
  },
  fullWidth: {
    width: "100%",
  },
  greyText: {
    color: `${hgemColours.DarkGrey}`,
  },
  boldText: {
    fontWeight: "bold",
  },

  attachmentsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    borderBottom: `1px solid ${hgemColours.LightGrey}`,
    width: "100%",
  },
  italicText: {
    fontStyle: "italic",
  },
  attachmentOptionButton: {
    width: "25%",
  },
  majorityWidth: {
    [theme.breakpoints.up("lg")]: {
      width: "55%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  buttonGroup: {
    width: "45%",
    marginLeft: "10%",
  },
  fileUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },

  verticalSpacing: {
    marginTop: "1%",
    marginBottom: "1%",
  },
  buttonAndIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      paddingLeft: "20%",
      width: "350%",
      marginTop: "5%",
    },
  },
  smallMargin: {
    alignItems: "center",
    marginLeft: "31.5%",
  },
  attachmentsContainer: {
    marginTop: "3%",
    marginBottom: "3%",
  },
  lightBorder: {
    border: `1px solid ${hgemColours.LightGrey}`,
  },
  appealStatus: {
    width: "45%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
  },
  appealsApproverHeader: {
    width: "100%",
    color: hgemColours.DarkGrey,
    fontSize: "1em",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
  },
  selfAlignmentStart: {
    alignSelf: "flex-start",
  },
  topRow: {
    alignItems: "center",
    marginTop: "1.5%",
    marginBottom: "1%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  newAppealButtonsBar: {
    display: "flex",
    flexDirection: "row",
    width: "15%",
    gap: "10%",
    margin: "auto",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gap: "0",
      width: "35%",
      float: "right",
    },
  },
  deleteIcon: {
    color: hgemColours.Orange,
  },
  saveIcon: {
    color: hgemColours.DarkGrey,
  },
}));

export const AppealsStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
