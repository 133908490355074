import { useEffect, useState } from "react";
import { getAttachmentsDisallowedFileExtensionsApi } from "../../api/Appeals-API";

export const useAttachmentsDisallowedFileExtensions = () => {
    const [fileExtensions, setFileExtensions] = useState<string[] | undefined>();

    useEffect(() => {
      if (!fileExtensions) {
        getAttachmentsDisallowedFileExtensionsApi().then((response) => {
          setFileExtensions(response.data);
        });
      }
    }, [fileExtensions, setFileExtensions]);

    return fileExtensions;
}