import { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteTaskDialog from "./deleteTaskDialog";
import CreateTaskDialog from "./createTaskDialog";
import i18n from "../../../localizations/i18n";
import { TasksStyles } from "../styles/tasksStyles";
interface IProps {
  caseId: number;
  caseTitle: string;
  visitId: number;
  allowDelete: boolean;
  allowCreateNew: boolean;
  postComment?: () => void;
  onFileSelected: (files: File) => void | Promise<void>;
  scroll: () => void;
  isMobileView: boolean;
}

const TasksButtonGroup = (props: IProps) => {
  const classes = TasksStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreateTask = () => {
    setCreateDialogOpen(true);
  };

  const handleDeleteTask = () => {
    setDeleteDialogOpen(true);
  };

  return (
    <span className={`${classes.buttonRow} ${classes.TasksButtonRow}`}>
      <Tooltip
        enterDelay={1500}
        placement="bottom"
        PopperProps={{ disablePortal: true }}
        arrow={true}
        title={i18n.translate("VISIT_DETAILS_TASK_CREATOR_Create_New_Task")}
      >
        <IconButton
          className={`fas fa-plus ${classes.tasksIconButton}`}
          onClick={() => handleCreateTask()}
        />
      </Tooltip>
      <Tooltip
        enterDelay={1500}
        placement="bottom"
        PopperProps={{ disablePortal: true }}
        arrow={true}
        title={i18n.translate("TASK_CENTRE_VIEW_ATTACHMENTS")}
      >
        <IconButton
          className={`fak fa-regular-paperclip-magnifying-glass ${classes.tasksIconButton}`}
          onClick={() => props.scroll()}
        />
      </Tooltip>

      {props.isMobileView && (
        <IconButton
          className={`fak fa-regular-comment-magnifying-glass ${classes.tasksIconButton}`}
          onClick={() => props.scroll()}
        />
      )}
      <Tooltip
        enterDelay={1500}
        placement="bottom"
        PopperProps={{ disablePortal: true }}
        arrow={true}
        title={i18n.translate("TASK_CENTRE_Delete_Task")}
      >
        <IconButton
          className={`fas fa-trash ${classes.tasksIconButton} ${classes.deleteButton}`}
          onClick={() => handleDeleteTask()}
        />
      </Tooltip>
      <CreateTaskDialog
        visitId={props.visitId}
        dialogOpen={createDialogOpen}
        onDialogClose={() => setCreateDialogOpen(false)}
      />
      <DeleteTaskDialog
        caseId={props.caseId}
        title={props.caseTitle}
        dialogOpen={deleteDialogOpen}
        onDialogClose={() => setDeleteDialogOpen(false)}
      />
    </span>
  );
};

export default TasksButtonGroup;
