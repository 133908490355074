import { hgemColours, materialFont } from "../../../themes/defaultTheme";
import { Theme, makeStyles, useTheme, lighten } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    columnGap: "5%",
    minHeight: "30px",
    fontSize: "1rem",
    alignItems: "center",
  },
  simpleFlexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  iconFlexColumm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fullWidthFlex: {
    width: "100%",
  },
  visitTitle: {
    fontSize: "1em",
    fontWeight: 300,
    width: "100%",
    alignSelf: "flex-start",
    [theme.breakpoints.between(1280, 1400)]: {
      fontSize: "0.75em",
    },
  },
  wideColumnVisibleOverflow: {
    width: "80%",
    overflow: "visible",
  },
  inboxIcon: {
    overflow: "visible",
    opacity: "0.5",
  },
  score: {
    marginTop: "45%",
    fontWeight: 800,
    color: `${hgemColours.ExtraMediumGrey}`,
  },
  visitDetailsItem: {
    fontSize: "100%",
    display: "inline-block",
    position: "relative",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "5px",
    backgroundColor: theme.palette.grey[50],
    cursor: "pointer",
    textAlign: "left",
    fontFamily: materialFont,
    padding: "2%",
    marginBottom: "2.5%",
    width: "calc(100% - 20px)",
    [theme.breakpoints.up("lg")]: {
      transition: "margin-left 0.5s ease 0s",
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "0.9em",
    },

    "&:hover": {
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
      marginLeft: "10px",
    },

    "&.selected": {
      backgroundColor: lighten(hgemColours.LimeGreen, 0.9),
      border: `2px solid ${hgemColours.LimeGreen}`,
      borderLeft: `5px solid ${hgemColours.LimeGreen}`,
    },
    "&.green": {
      borderLeft: `5px solid ${lighten(hgemColours.Green, 0.4)}`,
    },
    "&.amber": {
      borderLeft: `5px solid ${lighten(hgemColours.Yellow, 0.4)}`,
    },
    "&.red": {
      borderLeft: `5px solid ${lighten(hgemColours.Red, 0.4)}`,
    },
    "&.default": {
      borderLeft: `5px solid ${hgemColours.ExtraMediumGrey}`,
    },
  },
  infoBullet: {
    fontSize: "0.8em",
    color: theme.palette.grey[700],
  },
  toolTip: {
    width: "220px",
  },
  iconMargin: {
    marginRight: "5%",
  },
  highlightUnread: {
    fontWeight: 800,
    color: theme.palette.grey[700],
  },
}));

export const ConverationCardStyles = () => {
  const theme = useTheme();

  return useStyles(theme);
};
