import { ICaseDetails } from "../../../../state/types/TaskCentreCases";
import { BrandIdAndName } from "../../../../state/types/UserProfile";
import { IVisitDetail } from "../../../../state/types/VisitDetails";

export const findBrandName = (
  currentVisit?: IVisitDetail,
  currentBrands?: BrandIdAndName[],
  caseDetails?: ICaseDetails
) => {
  let currentBrand: BrandIdAndName | undefined = undefined;
  if (currentVisit) {
    currentBrand = currentBrands?.filter(
      (brand: BrandIdAndName) => brand.id === currentVisit?.brandId
    )[0];
  }
  if (caseDetails) {
    currentBrand = currentBrands?.filter(
      (brand: BrandIdAndName) => brand.id === currentVisit?.brandId
    )[0];
  }
  if (currentBrand) {
    return currentBrand.name;
  }
};
