import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialogText: {
    color: theme.palette.text.primary,
  },
  email: {
    fontWeight: 600,
  },
  notesInput: {
    marginTop: "10px",

    "& label": {
      color: theme.palette.grey[400],
    },

    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
  },
}));

export const GuestContactConfirmDialogStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
