import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVisitMedia,
  fetchVisitReceipts,
} from "../../state/actions/VisitDetails-Actions";
import {
  AllPhotoPaths,
  AllAudioPaths,
  AllReceiptPaths,
} from "./state/selectors";
import VisitPhoto from "./components/visitPhoto";
import VisitAudio from "./components/visitAudio";
import VisitReceipt from "./components/visitReceipt";
import { InboxMediaStyles } from "./styles/inboxMediaStyles";
import { Icon, LinearProgress } from "@material-ui/core";
import { RootState } from "../../state/reducers/RootReducer";
import i18n from "../../localizations/i18n";
interface IProps {
  visitId: number | undefined;
}

const InboxMedia = (props: IProps) => {
  const classes = InboxMediaStyles();
  const dispatch = useDispatch();

  const photoPaths = useSelector((state: RootState) =>
    AllPhotoPaths(state, props.visitId)
  );

  const audioPaths = useSelector((state: RootState) =>
    AllAudioPaths(state, props.visitId)
  );

  const receiptPaths = useSelector((state: RootState) =>
    AllReceiptPaths(state, props.visitId)
  );

  useEffect(() => {
    if (props.visitId) {
      dispatch(fetchVisitMedia(props.visitId));
      dispatch(fetchVisitReceipts(props.visitId));
    }
  }, [dispatch, props.visitId]);

  return (
    <div className={classes.container}>
      {props.visitId &&
        (photoPaths === undefined || receiptPaths === undefined) && (
          <div>
            <LinearProgress color="primary" />
          </div>
        )}

      {props.visitId &&
        photoPaths?.length === 0 &&
        audioPaths?.length === 0 &&
        receiptPaths?.fileNames.length === 0 && (
          <p>{i18n.translate("INBOX_MEDIA_no_media_available")}</p>
        )}

      {photoPaths && photoPaths.length > 0 && (
        <div className={classes.sectionContainer}>
          <p>
            <Icon className={"fas fa-camera"} />
            {i18n.translate("INBOX_MEDIA_Photos")}
          </p>
          {photoPaths.map((x) => (
            <VisitPhoto key={x} visitId={props.visitId ?? 0} fileName={x} />
          ))}
        </div>
      )}

      {audioPaths && audioPaths.length > 0 && (
        <div className={classes.sectionContainer}>
          <p>
            <Icon className={"fas fa-volume-up"} />
            {i18n.translate("INBOX_MEDIA_AudioFiles")}
          </p>
          {audioPaths.map((x) => (
            <VisitAudio key={x} visitId={props.visitId ?? 0} fileName={x} />
          ))}
        </div>
      )}

      {receiptPaths && receiptPaths.fileNames.length > 0 && (
        <div className={classes.sectionContainer}>
          <p>
            <Icon className={"fas fa-receipt"} />
            {i18n.translate("INBOX_MEDIA_Receipts")}
          </p>
          {receiptPaths?.fileNames.map((x: string, index: number) => {
            return (
              <VisitReceipt
                key={x}
                visitId={receiptPaths.visitId}
                fileName={x}
                receiptIndex={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InboxMedia;
