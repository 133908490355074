import {
  IPeriodHierarchy,
  IPeriodHierarchyOption,
} from "../types/FilterOptions";

export interface IPeriodHierarchBrandLink {
  brandId: number | undefined;
  brandName: string | undefined;
  hierarchyIds: number[];
}

export interface IPeriodHierarchyByClientIdAndBrandVariables {
  clientId: number;
  brandId?: number;
}

export interface IPeriodHierarchyByClientIdAndBrand {
  periodHierarchyByClientId: IGraphPeriodHierarchy[];
  periodHierarchyGlobalDefault: IGraphPeriodHierarchy[];
}

export interface IGraphPeriodHierarchy {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  isDefault: boolean;
  inverseParent: IGraphPeriodHierarchy[];
}

export const mapPeriodHierarchies = (
  data: IGraphPeriodHierarchy[],
  brandLinks: IPeriodHierarchBrandLink[]
): IPeriodHierarchyOption[] => {
  return brandLinks.map((b) => {
    return {
      brandId: b.brandId,
      brandName: b.brandName,
      periods: mapRecusrsivePeriods(
        data.filter((x) => b.hierarchyIds.indexOf(x.id) > -1)
      ),
    } as IPeriodHierarchyOption;
  });
};

const mapRecusrsivePeriods = (
  periods: IGraphPeriodHierarchy[] | undefined
): IPeriodHierarchy[] => {
  const mappedPeriods: IPeriodHierarchy[] = [];

  periods?.forEach((x) => {
    mappedPeriods.push({
      id: x.id,
      name: x.name,
      startDate: x.startDate,
      endDate: x.endDate,
      isDefault: x.isDefault,
      children: mapRecusrsivePeriods(x.inverseParent),
    });
  });

  return mappedPeriods;
};
