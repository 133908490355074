import Box from "@material-ui/core/box";
import { Icon, Tooltip, Button } from "@material-ui/core";
import { PageTitleStyles } from "./styles/pageTitleStyles";
import PageActions from "./utils/pageActions";
import { useDispatch } from "react-redux";

const PageTitle = () => {
  const classes = PageTitleStyles();
  const handleMenuItemSelection = (selectionHandler: () => void): void => {
    selectionHandler();
  };
  const dispatch = useDispatch();
  const pageActions = new PageActions(dispatch).WithDataExport().GetTasks();

  return (
    <Box m={1} style={{ margin: 0 }}>
      {pageActions && pageActions.length > 0 && (
        <span className={`hub-no-print ${classes.pageActions} `}>
          {pageActions.map((x) => {
            return (
              <Tooltip
                title={`${x.optionText}`}
                aria-label={`${x.optionText}`}
                key={x.optionText}
              >
                <span className={`${classes.flexItem}`}>
                  <Button
                    aria-label={x.optionText}
                    onClick={() => handleMenuItemSelection(x.onSelect)}
                    classes={{ text: classes.removePadding }}
                  >
                    <Icon
                      className={`${classes.pageActionsIcon} ${x.optionIcon}`}
                    ></Icon>
                  </Button>
                </span>
              </Tooltip>
            );
          })}
        </span>
      )}
    </Box>
  );
};

export default PageTitle;
