import { ConversationSourceEnum } from "../../../state/types/Conversations"

export const formatSender = (sender: string | undefined, source: ConversationSourceEnum | undefined) => {
    if (!sender) {
        return "";
    }

    let formattedSender = sender.replace(":", ": ");

    switch (source) {
        case ConversationSourceEnum.Gbm:
            formattedSender = formattedSender.substring(0, 13);
            break;
        default:
            break;
    }
    
    return formattedSender;
}