import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";

export const GetActiveNotificationById = createSelector(
  (state: RootState) => state.AppNotificationReducer,
  (_: RootState, id: string | undefined) => id,
  (state, id) => {
    return id
      ? state.notifications.find((x) => x.Identifier === id)
      : undefined;
  }
);

export const VisitTasksPending = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.pending;
  }
);
