import { Hidden, Icon, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { i18n } from "../../../../localizations";
import { IMinimalCaseEventAttachment } from "../../../../state/types/TaskCentreCases";
import { withPendingHandling } from "../../../../utils/pendingHandler";
import { AppealsButton } from "../../../InboxAppeals/components";
import { AppealsStyles } from "../../../InboxAppeals/styles";
import { PageTitleStyles } from "../../../pageTitle/styles/pageTitleStyles";
import { FormFieldStyles } from "../../../_common/formField/styles/formFieldStyles";
import AppealAttachmentDeleteDialog from "./attachmentDeleteDialog";

type Props = {
  attachment: IMinimalCaseEventAttachment & {
    authorId?: number | undefined;
    authorName?: string | undefined;
  };
  downloadAttachment:
    | ((caseEventAttachment: IMinimalCaseEventAttachment) => Promise<Blob>)
    | undefined;
  authorDeleteAttachment: (
    authorId: number | undefined
  ) => ((caseEventAttachmentId: number) => Promise<void>) | undefined;
};

const Attachment = (props: Props) => {
  const { attachment, downloadAttachment, authorDeleteAttachment } = props;

  const deleteAttachment = authorDeleteAttachment(attachment.authorId);

  const appealsClasses = AppealsStyles();
  const formFieldClasses = FormFieldStyles();

  const [isPendingDownloadDelete, setIsPendingDownloadDelete] =
    useState<boolean>(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const handleMenuItemSelection = (
    selectionHandler: () => Promise<void>
  ): void => {
    setMenuAnchor(null);
    selectionHandler();
  };

  const canSaveAttachment = downloadAttachment !== undefined;

  const saveAttachment = async () => {
    if (downloadAttachment) {
      const blob = await downloadAttachment(attachment);

      saveAs(blob, attachment.filepath);
    }
  };

  return (
    <>
      <div className={`${appealsClasses.attachmentsRow}`}>
        <Typography
          className={`${appealsClasses.italicText} ${appealsClasses.majorityWidth}`}
        >
          {attachment.filepath}{" "}
          {attachment.authorName && (
            <small>
              ({i18n.translate("ATTACHMENT_UPLOADED_BY")}{" "}
              {attachment.authorName})
            </small>
          )}
        </Typography>
        <Hidden xsUp implementation="css">
          <div className={`${appealsClasses.flexColumn}`}>
            <div>
              {canSaveAttachment && (
                <IconButton
                  className={`fas fa-floppy-disk ${appealsClasses.saveIcon}`}
                  onClick={() => handleMenuItemSelection(saveAttachment)}
                />
              )}

              {deleteAttachment && (
                <IconButton
                  className={`fas fa-trash ${appealsClasses.deleteIcon}`}
                  onClick={() =>
                    handleMenuItemSelection(() => {
                      setIsDeleteDialogOpen(true);
                      return Promise.resolve();
                    })
                  }
                />
              )}
            </div>
            <div>
              {isPendingDownloadDelete && (
                <Icon
                  className={`icon fas fa-spinner fa-spin fa-3x fa-fw ${formFieldClasses.iconStatus} ${appealsClasses.smallIcon} ${appealsClasses.smallMargin}`}
                />
              )}
            </div>
          </div>
        </Hidden>
        <Hidden xsDown implementation="js">
          <span className={`${appealsClasses.buttonGroup}`}>
            {canSaveAttachment && (
              <span className={`${appealsClasses.attachmentOptionButton}`}>
                <AppealsButton
                  name={i18n.translate("ATTACHMENT_DOWNLOAD")}
                  promise={saveAttachment}
                  isPending={isPendingDownloadDelete}
                  setIsPending={setIsPendingDownloadDelete}
                />
              </span>
            )}
            <span className={`${appealsClasses.attachmentOptionButton}`}>
              {deleteAttachment && (
                <AppealsButton
                  name={i18n.translate("ATTACHMENT_DELETE")}
                  promise={() => {
                    setIsDeleteDialogOpen(true);
                    return Promise.resolve();
                  }}
                  variant={"outlined"}
                  isPending={isPendingDownloadDelete}
                  setIsPending={setIsPendingDownloadDelete}
                />
              )}
            </span>

            <span>
              {isPendingDownloadDelete && (
                <Icon
                  className={`icon fas fa-spinner fa-spin fa-3x fa-fw ${formFieldClasses.iconStatus} ${appealsClasses.smallIcon}`}
                />
              )}
            </span>
          </span>
        </Hidden>
      </div>
      {deleteAttachment && (
        <AppealAttachmentDeleteDialog
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
          attachment={attachment}
          deleteAttachment={() =>
            withPendingHandling(setIsPendingDownloadDelete, () =>
              deleteAttachment(attachment.id)
            )
          }
        />
      )}
    </>
  );
};

export default Attachment;
