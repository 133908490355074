import { CommentStyles } from "../../../taskCentreEvents/styles/commentStyles";
import { useCallback, useEffect, useRef } from "react";
import {
  ConversationMessagesListUpdateReason,
  postMarkConversationMessageAsRead,
} from "../../../../state/actions/Conversations-Actions";
import { v4 as uuidv4 } from "uuid";
import SimpleBar from "simplebar-react";
import {
  calculateScrollDistanceFromBottom,
  calculateScrollTopFromScrollDistanceFromBottom,
  MediaMessage,
} from "./media";
import { DefaultMessage, MessageHeader, MessagesListLoadingStatus } from ".";
import { ConversationsMessagesStyles } from "../../styles";
import { Conversation } from "../../../../state/types/Conversations";
import { ConversationMessagesState } from "../../../../state/reducers/Conversations-Reducer";
import { useDispatch } from "react-redux";

type Props = {
  context:
    | { conversation: Conversation; messagesState: ConversationMessagesState }
    | undefined;
  fetchInitialMessagesList: () => void;
  fetchLoadMoreMessagesList: (
    scrollDistanceFromBottom: number | undefined
  ) => void;
};

const ConversationMessagesCore = (props: Props) => {
  const classes = CommentStyles();
  const conversationMessagesStyles = ConversationsMessagesStyles();

  const dispatch = useDispatch();

  const bottomBoundaryRef = useRef<HTMLDivElement | null>(null);
  const topBoundaryRef = useRef<HTMLDivElement | null>(null);
  const scrollableNodeRef = useRef<SimpleBar | null>(null);

  useEffect(() => {
    if (
      props.context &&
      !props.context.messagesState.isLoading &&
      !props.context.messagesState.initialListLoaded
    ) {
      props.fetchInitialMessagesList();
    }
  }, [props.context, props.fetchInitialMessagesList]);

  useEffect(() => {
    const scrollElement = scrollableNodeRef.current?.getScrollElement();

    const contentElement = scrollableNodeRef.current?.getContentElement();

    if (props.context && scrollElement && contentElement) {
      switch (props.context.messagesState.updateReason) {
        case ConversationMessagesListUpdateReason.LoadMore:
          if (props.context.messagesState.lastScrollDistanceFromBottom) {
            const scrollTop = calculateScrollTopFromScrollDistanceFromBottom(
              scrollElement,
              contentElement,
              props.context.messagesState.lastScrollDistanceFromBottom
            );

            scrollElement.scrollTo({ top: scrollTop });
          } else {
            scrollElement.scrollTo({ top: scrollElement.scrollHeight });
          }

          break;
        default:
          scrollElement.scrollTo({ top: scrollElement.scrollHeight });
          break;
      }
    }
  }, [
    props.context,
    props.context?.messagesState.messages,
    props.context?.messagesState.updateReason,
    props.context?.messagesState.lastScrollDistanceFromBottom,
  ]);

  const loadMoreMessages = () => {
    if (
      props.context &&
      !props.context.messagesState.isLoading &&
      props.context.messagesState.hasMorePages
    ) {
      const scrollElement = scrollableNodeRef.current?.getScrollElement();

      const contentElement = scrollableNodeRef.current?.getContentElement();

      let scrollDistanceFromBottom: number | undefined;

      if (scrollElement && contentElement) {
        scrollDistanceFromBottom = calculateScrollDistanceFromBottom(
          scrollElement,
          contentElement
        );
      }

      props.fetchLoadMoreMessagesList(scrollDistanceFromBottom);
    }
  };

  const topBoundaryScrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            loadMoreMessages();
          }
        });
      }).observe(node);
    },
    [props.context, loadMoreMessages]
  );

  useEffect(() => {
    if (topBoundaryRef.current) {
      topBoundaryScrollObserver(topBoundaryRef.current);
    }
  }, [topBoundaryScrollObserver, topBoundaryRef]);

  const bottomBoundaryScrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0 && props.context !== undefined) {
            const lastMessage = props.context.messagesState.messages.at(-1);

            if (lastMessage && lastMessage.conversationMessageId) {
              const conversation = props.context.conversation;

              if (
                !conversation.readCursorMessageId ||
                lastMessage.conversationMessageId >
                  conversation.readCursorMessageId
              ) {
                dispatch(
                  postMarkConversationMessageAsRead(
                    conversation.conversationId,
                    lastMessage.conversationMessageId
                  )
                );
              }
            }
          }
        });
      }).observe(node);
    },
    [props.context, props.context?.messagesState.messages]
  );

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      bottomBoundaryScrollObserver(bottomBoundaryRef.current);
    }
  }, [bottomBoundaryScrollObserver, bottomBoundaryRef]);

  return (
    <SimpleBar
      autoHide={true}
      className={conversationMessagesStyles.messagesScrollWindow}
      ref={scrollableNodeRef}
    >
      {props.context !== undefined && (
        <>
          {props.context.messagesState.isLoading && <p>Loading...</p>}
          {!props.context.messagesState.isLoading && (
            <MessagesListLoadingStatus conversationState={props.context} />
          )}
          {props.context.messagesState.messages && (
            <>
              <div
                className={`${classes.container} ${conversationMessagesStyles.boundaryTop}`}
                ref={topBoundaryRef}
              >
                &nbsp;
              </div>
              {props.context.messagesState.messages.map((x) => (
                <div key={x.conversationMessageId ?? uuidv4()}>
                  {x.medias &&
                    x.medias.length > 0 &&
                    x.medias.map((y) => (
                      <MediaMessage
                        key={`media-${y}`}
                        conversationId={
                          props.context?.conversation.conversationId ?? 0
                        }
                        message={x}
                        messageHeader={
                          <MessageHeader
                            message={x}
                            source={props.context?.conversation.source}
                          />
                        }
                        media={y}
                      />
                    ))}
                  {(!x.medias || x.medias.length == 0) && (
                    <DefaultMessage
                      conversationId={
                        props.context?.conversation.conversationId ?? 0
                      }
                      message={x}
                      messageHeader={
                        <MessageHeader
                          message={x}
                          source={props.context?.conversation.source}
                        />
                      }
                    />
                  )}
                </div>
              ))}
              <div
                className={`${classes.container} ${conversationMessagesStyles.boundaryBottom}`}
                ref={bottomBoundaryRef}
              ></div>
            </>
          )}
        </>
      )}
    </SimpleBar>
  );
};

export default ConversationMessagesCore;
