import { RootState } from "../../state/reducers/RootReducer";
import { createSelector } from "reselect";

export const GetReportInternalRefByPageRoute = createSelector(
  (state: RootState) => state.UserProfileReducer,
  (_: RootState, page: string) => page,
  (state, page): string => {
    const name = state.userProfile?.navigationOptions.find((x) =>
      x.path.startsWith(`/reports/${page}`)
    )?.name;

    return name ? name : "";
  }
);

export const GetReportIconByPageRoute = createSelector(
  (state: RootState) => state.UserProfileReducer,
  (_: RootState, page: string) => page,
  (state, page): string => {
    let icon: string | undefined = "";
    const navItem = state.userProfile?.navigationOptions.find((x) =>
      x.path.startsWith(`/reports/${page}`)
    );

    if (navItem && navItem?.icon) {
      icon = navItem.icon;
    } else if (navItem && navItem?.parent) {
      const parent = state.userProfile?.navigationOptions.find(
        (x) => x.name === navItem.parent
      );
      icon = parent ? parent.icon : "";
    }

    return icon ? icon : "";
  }
);

export const GetReportIconParentClassByPageRoute = createSelector(
  (state: RootState) => state.UserProfileReducer,
  (_: RootState, page: string) => page,
  (state, page): string => {
    const parent = state.userProfile?.navigationOptions.find((x) =>
      x.path.startsWith(`/reports/${page}`)
    )?.parent;

    return parent ? parent : "Default";
  }
);