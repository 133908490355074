import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { IReviewResponseTemplate } from "../../../state/types/VisitReport";
import { ReviewTemplateDialogStyles } from "../styles/reviewTemplateDialogStyles";
import i18n from "../../../localizations/i18n";
import {
  ApplyTextSubstitutions,
  textSubstitution,
} from "../../../utils/textManipulations";

interface IProps {
  showSelections: boolean;
  templates: IReviewResponseTemplate[];
  substitutions: textSubstitution[];
  onSelect: (template: IReviewResponseTemplate) => void;
  onClose: () => void;
}

const ReviewTemplateDialog = (props: IProps) => {
  const classes = ReviewTemplateDialogStyles();
  const [selectedTemplate, setSelectedTemplate] =
    useState<IReviewResponseTemplate>();

  const applySelection = () => {
    if (selectedTemplate) {
      props.onSelect(selectedTemplate);
    }
  };

  const closeSelection = () => {
    props.onClose();
  };

  const selectTemplate = (template: IReviewResponseTemplate) => {
    setSelectedTemplate(template);
  };

  useEffect(() => {
    setSelectedTemplate(undefined);
  }, [props.templates]);

  return (
    <Dialog open={props.showSelections} onClose={closeSelection}>
      <DialogTitle>
        {i18n.translate("VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Instructions")}
      </DialogTitle>
      <DialogContent>
        <>
          {props.templates.map(
            (template: IReviewResponseTemplate, index: number) => {
              const isSelected = selectedTemplate?.name === template.name;
              const tokanisedText = ApplyTextSubstitutions(
                template.template,
                props.substitutions
              );

              return (
                <Button
                  key={index}
                  color={isSelected ? "primary" : "default"}
                  onClick={() => selectTemplate(template)}
                  className={`${classes.templateButton} ${
                    isSelected ? classes.templateButtonSelected : ""
                  }`}
                >
                  <>
                    {template.name}
                    <span className={classes.templateText}>
                      {tokanisedText}
                    </span>
                  </>
                </Button>
              );
            }
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"primary"}
          onClick={applySelection}
          disabled={selectedTemplate === undefined}
        >
          {i18n.translate("VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Select")}
        </Button>
        <Button variant="contained" onClick={closeSelection}>
          {i18n.translate("VISIT_DETAILS_REVIEW_TEMPLATE_DIALOG_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewTemplateDialog;
