import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import { i18n } from "../../../localizations";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GuestContactFieldStyles } from "../styles/guestContactFieldStyles";
import { GuestContactStyles } from "../styles/guestContactStyles";

type props = {
  removeFile: (fileName: string) => void;
  uploadFiles: File[];
};

const PendingAttachmentsList = (props: props) => {
  const classes = GuestContactFieldStyles();
  const guestContactStyles = GuestContactStyles();

  const { removeFile, uploadFiles } = props;
  return (
    <Accordion className={guestContactStyles.accordionRow}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {i18n.translate("ATTACHMENTS_PENDING")}
      </AccordionSummary>
      <AccordionDetails>
        <>
          {uploadFiles.map((file, index) => {
            return (
              <span className={classes.flexRow} style={{ width: "100%" }}>
                {i18n.translate("ATTACHMENT")} {index + 1}: {file.name}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => removeFile(file.name)}
                >
                  {i18n.translate("ATTACHMENT_DELETE_ATTACHMENT")}
                </Button>
              </span>
            );
          })}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default PendingAttachmentsList;
