import { hgemColours } from "../../../../themes/defaultTheme";
import { useTheme, Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  shrink: {
    flexGrow: 0,
  },
  grow: {
    flexGrow: 1,
  },
  formFieldContainer: {
    padding: "5px 0",
  },
  icon: {
    color: theme.palette.grey[400],
    marginBottom: "5px",

    "& span": {
      fontSize: "1em",
    },
  },
  indicator: {
    width: "40px",
  },
  iconStatus: {
    color: theme.palette.grey[400],
  },
  iconStatusError: {
    color: hgemColours.Red,
  },
  iconStatusOk: {
    color: hgemColours.LimeGreen,
  },
}));

export const FormFieldStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
