import {
  ICaseDetails,
  WorkflowStatus,
} from "../../../state/types/TaskCentreCases";
import { AddCommentCommand } from "../../../state/types/TaskCentreCommands";
import {
  createNewTaskCentreCase,
  executeCaseCommand,
} from "../../../state/actions/TaskCentre-CaseList-Actions";
import { useDispatch } from "react-redux";
import { IAppNotification } from "../../../state/types/AppNotification";
import CommonCommentField from "../../_common/comments/components/commonCommentField";

interface IProps {
  caseDetails: ICaseDetails;
  fieldNotification?: IAppNotification | undefined;
  isNewCase: boolean;
  hideAttachments?: boolean;
}

const CommentField = (props: IProps) => {
  const dispatch = useDispatch();

  const submitComment = (commentText: string, _: boolean, files: File[]) => {
    const commentCommand = new AddCommentCommand(
      props.caseDetails.id,
      commentText,
      files
    );

    if (props.isNewCase) {
      dispatch(
        createNewTaskCentreCase(
          props.caseDetails.vId,
          "",
          WorkflowStatus.Discussion,
          [commentCommand]
        )
      );
    } else {
      dispatch(executeCaseCommand(props.caseDetails.id, [commentCommand]));
    }
  };

  return (
    <CommonCommentField
      fieldNotification={props.fieldNotification}
      onSubmit={submitComment}
      submitFiles={true}
      hideAttachmentsInput={props.hideAttachments}
      currentCase={props.caseDetails}
    />
  );
};

export default CommentField;
