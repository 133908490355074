import {
  ApplicationActions,
  RESET_PERSISTED_STATE_PENDING_FLAG,
} from "../actions/Application-Actions";
import {
  FILTEROPTIONS_PENDING,
  FILTEROPTIONS_SUCCESS,
  FILTEROPTIONS_ERROR,
  FilterOptionActions,
  RESET_FILTER_OPTIONS,
} from "../actions/FilterOptions-Actions";
import {
  IFilterOptions,
  IVisitDateFilterOptions,
} from "../types/FilterOptions";

export interface IFilterOptionsState {
  filterOptions: IFilterOptions;
  lastRefreshed: number | undefined;
  pending: boolean;
  error: string;
}

const INITIAL_STATE: IFilterOptionsState = {
  filterOptions: {
    locationOptions: [],
    questionnaireOptions: [],
    visitDateOptions: { clientPeriods: [] } as IVisitDateFilterOptions,
    visitSourceOptions: [],
    periodHierarchyOptions: [],
    lastUpdated: undefined,
    dishFilters: [],
  } as IFilterOptions,
  lastRefreshed: undefined,
  pending: false,
  error: "",
};

export default function FilterOptionsReducer(
  state: IFilterOptionsState = INITIAL_STATE,
  action: FilterOptionActions | ApplicationActions
): IFilterOptionsState {
  switch (action.type) {
    case FILTEROPTIONS_PENDING:
      return {
        ...state,
        pending: true,
        error: "",
      };
    case FILTEROPTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: "",
        filterOptions: action.filterOptions,
      };
    case FILTEROPTIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case RESET_FILTER_OPTIONS:
      return INITIAL_STATE;
    case RESET_PERSISTED_STATE_PENDING_FLAG:
      return {
        ...state,
        pending: false,
        error: "",
      };
    default:
      return state;
  }
}
