import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaskCentreManagers } from "../../../state/actions/TaskCentre-CaseList-Actions";
import { ManagerCount } from "../state/selectors";

const TaskManagersLoader = () => {
  const dispatch = useDispatch();

  const managerCount = useSelector(ManagerCount);

  useEffect(() => {
    if (managerCount === 0) {
      dispatch(fetchTaskCentreManagers());
    }
  }, [dispatch, managerCount]);

  return <></>;
};

export default TaskManagersLoader;
