import React from "react";
import Routing from "./navigation/routing";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ErrorBoundary } from "./components/_common/errorBoundary/errorBoundary";
import { store, persistor } from "./state/redux-store";
import { PersistGate } from "redux-persist/integration/react";
import {
  registerAxiosInterceptors,
  registerAxiosRetry,
} from "./services/AxiosMIddleware";
import DayJsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AppThemeWrapper from "./AppThemeWrapper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import MaintenanceBoundary from "./components/Maintenance/MaintenanceBoundary";

registerAxiosInterceptors();
registerAxiosRetry();

dayjs.extend(utc);

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <PersistGate loading={null} persistor={persistor}>
            <AppThemeWrapper>
              <MaintenanceBoundary>
                <BrowserRouter>
                  <Routing />
                </BrowserRouter>
              </MaintenanceBoundary>
            </AppThemeWrapper>
          </PersistGate>
        </MuiPickersUtilsProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
