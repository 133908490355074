import React from "react";
import { Badge } from "@material-ui/core";

interface IProps {
  labelText: string;
  showBadge?: boolean;
}

const TabLabel = (props: IProps) => {
  if (props.showBadge) {
    return (
      <Badge variant="dot" color="primary">
        {props.labelText}
      </Badge>
    );
  }

  return <>{props.labelText}</>;
};

export default TabLabel;
