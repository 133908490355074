import { Dialog, Icon, IconButton, LinearProgress } from "@material-ui/core";
import { i18n } from "../../../../localizations";
import { VisitPhotoModalStyles } from "../../../inboxMedia/styles/visitPhotoModalStyles";
import { MediaLinks } from "./types/mediaLinks";

interface IProps {
  mediaLinks: MediaLinks | undefined;
  contentType: string;
  onClose: () => void;
  onDownload: () => void;
}

const MediaMessageVideoModal = (props: IProps) => {
  const classes = VisitPhotoModalStyles();

  return (
    <Dialog className={classes.dialog} open={true} onClose={props.onClose}>
      {props.mediaLinks && (
        <>
          <div className={classes.imageOptions}>
            <IconButton
              size="small"
              onClick={props.onClose}
              title={i18n.translate("MEDIA_MESSAGE_VIDEO_MODAL_Close")}
            >
              <Icon className="far fa-window-close" />
            </IconButton>
            <IconButton
              size="small"
              onClick={props.onDownload}
              title={i18n.translate("MEDIA_MESSAGE_VIDEO_MODAL_Download")}
            >
              <Icon className="fas fa-download" />
            </IconButton>
          </div>
          <video controls controlsList="nodownload" role="application">
            <source
              src={props.mediaLinks.localUrl}
              type={props.contentType}
            ></source>
            {i18n.translate("MEDIA_MESSAGE_VIDEO_No_Video_Support")}
          </video>
        </>
      )}
      {!props.mediaLinks && (
        <div className={classes.pendingContainer}>
          <LinearProgress color="primary" />
          <Icon className={`fas fa-camera ${classes.imagePending}`} />
        </div>
      )}
    </Dialog>
  );
};

export default MediaMessageVideoModal;
