import { useEffect, useState } from "react";
import { AuthServiceInstance } from "../../../authentication/authService";

export const useUserName = () => {
  const [userName, setUserName] = useState<string | undefined>();

  useEffect(() => {
    if (!userName) {
      AuthServiceInstance.getUserName().then((userId) => {
        setUserName(userId);
      });
    }
  }, [userName, setUserName]);

  return userName;
};
