import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import i18n from "../../../localizations/i18n";
import { GuestContactConfirmDialogStyles } from "../styles/guestContactConfirmDialogStyles";

interface IProps {
  isOpen: boolean;
  onClosing: () => void;
  onConfirmation: (comment: string) => void;
}

const GuestContactConfirmDialog = (props: IProps) => {
  const classes = GuestContactConfirmDialogStyles();

  const [contactNotes, setContactNotes] = useState("");

  const onNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactNotes(event.target.value);
  };

  const onClose = () => {
    setContactNotes("");
    props.onClosing();
  };

  const onConfirm = () => {
    const notes = contactNotes;
    props.onConfirmation(notes);
    setContactNotes("");
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={onClose}
      aria-labelledby="guest-contact-confirm-title"
      aria-describedby="guest-contact-confirm-description"
    >
      <DialogTitle id="guest-contact-confirm-title">
        {i18n.translate("TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="guest-contact-confirm-description"
          className={classes.dialogText}
        >
          {i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Description"
          )}
        </DialogContentText>
        <TextField
          multiline
          className={classes.notesInput}
          label={i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Notes"
          )}
          value={contactNotes}
          onChange={onNotesChange}
          rows={2}
          variant="outlined"
          fullWidth
          error={contactNotes.length > 1000}
          helperText={i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_Notes_Error"
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_CancelTask"
          )}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          autoFocus
          variant="contained"
          disabled={contactNotes.length > 1000}
        >
          {i18n.translate(
            "TASK_CENTRE_GUEST_DETAILS_Contact_Confirmation_ConfirmTask"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuestContactConfirmDialog;
