import { ConversationMessage } from "../../../../state/types/Conversations";
import { CommentStyles } from "../../../taskCentreEvents/styles/commentStyles";
import { CommonStyleClasses } from "../../../../assets";

export type MessageProps = {
  conversationId: number;
  message: ConversationMessage;
  messageHeader: React.ReactElement;
};

type MessageCoreProps = MessageProps & {
  content: React.ReactElement;
  sideContent?: React.ReactElement | undefined;
};

const MessageCore = (props: MessageCoreProps) => {
  const classes = CommentStyles();
  const commonClasses = CommonStyleClasses();
  const commentSourceClass = props.message.isInbound
    ? "other-comment"
    : "my-comment";

  return (
    <div
      className={`${classes.container} ${commentSourceClass} ${commonClasses.darkGreyText}`}
    >
      {props.messageHeader}
      <div className={classes.conversationsFlexRow}>
        <span className={`${classes.commentText} conversations comment`}>
          {props.content}
        </span>
        {!props.message.isInbound && props.sideContent && (
          <span className={classes.outBound}>{props.sideContent}</span>
        )}
        {props.message.isInbound && props.sideContent && (
          <span className={classes.inBound}>{props.sideContent}</span>
        )}
      </div>
    </div>
  );
};

export default MessageCore;
