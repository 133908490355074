import {
  ApplicationActions,
  RESET_PERSISTED_STATE_PENDING_FLAG,
} from "../actions/Application-Actions";
import {
  REPORTCONFIGURATION_SUCCESS,
  REPORTCONFIGURATION_ERROR,
  REPORTCONFIGURATION_RESET,
  ReportConfigurationActions,
} from "../actions/ReportConfiguration-Actions";
import { IReportConfiguration } from "../types/ReportConfiguration";

export interface IReportConfigurationState {
  reportConfiguraton: IReportConfiguration;
  error: string;
}

const INITIAL_STATE: IReportConfigurationState = {
  reportConfiguraton: {
    reportDetails: [],
    accessTokens: [],
    lastUpdated: undefined,
    tokensExpireAt: undefined,
  } as IReportConfiguration,
  error: "",
};

export default function ReportConfigurationReducer(
  state: IReportConfigurationState = INITIAL_STATE,
  action: ReportConfigurationActions | ApplicationActions
): IReportConfigurationState {
  switch (action.type) {
    case REPORTCONFIGURATION_SUCCESS:
      return {
        ...state,
        error: "",
        reportConfiguraton: action.reportConfiguration,
      };
    case REPORTCONFIGURATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case REPORTCONFIGURATION_RESET:
      return INITIAL_STATE;
    case RESET_PERSISTED_STATE_PENDING_FLAG:
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
}
