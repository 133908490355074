import { IAppealsAcess, IAppealsFeatureActive } from "../types/Appeals";

export const APPEALS_FEATURE_ACTIVE_PENDING = "APPEALS_FEATURE_ACTIVE_PENDING";
export const APPEALS_FEATURE_ACTIVE_SUCCESS = "APPEALS_FEATURE_ACTIVE_SUCCESS";
export const APPEALS_FEATURE_ACTIVE_ERROR = "APPEALS_FEATURE_ACTIVE_ERROR ";
export const FETCH_APPEALS_FEATURE_ACTIVE = "FETCH_APPEALS_FEATURE_ACTIVE";
export const APPEALS_CAN_ACCESS_PENDING = "APPEALS_CAN_ACCESS_PENDING";
export const APPEALS_CAN_ACCESS_SUCCESS = "APPEALS_CAN_ACCESS_SUCCESS";
export const APPEALS_CAN_ACCESS_ERROR = "APPEALS_CAN_ACCESS_ERROR ";
export const FETCH_APPEALS_CAN_ACCESS = "FETCH_APPEALS_CAN_ACCESS";

export interface IAppealsFeatureActiveSuccess {
  type: typeof APPEALS_FEATURE_ACTIVE_SUCCESS;
  appealsFeatureActive: IAppealsFeatureActive;
}

export function appealsFeatureActiveSuccess(
  appealsFeatureActive: IAppealsFeatureActive
): IAppealsFeatureActiveSuccess {
  return {
    type: APPEALS_FEATURE_ACTIVE_SUCCESS,
    appealsFeatureActive: appealsFeatureActive,
  };
}

export interface IAppealsFeatureActivePending {
  type: typeof APPEALS_FEATURE_ACTIVE_PENDING;
}

export function appealsFeatureActivePending(): IAppealsFeatureActivePending {
  return {
    type: APPEALS_FEATURE_ACTIVE_PENDING,
  };
}

export interface IAppealsFeatureActiveError {
  type: typeof APPEALS_FEATURE_ACTIVE_ERROR;
  error: string;
}

export function appealsFeatureActiveError(
  error: string
): IAppealsFeatureActiveError {
  return {
    type: APPEALS_FEATURE_ACTIVE_ERROR,
    error: error,
  };
}

export interface IFetchAppealsFeatureActive {
  type: typeof FETCH_APPEALS_FEATURE_ACTIVE;
}

export function fetchAppealsFeatureActive(): IFetchAppealsFeatureActive {
  return {
    type: FETCH_APPEALS_FEATURE_ACTIVE,
  };
}

export interface IAppealsCanAccessSuccess {
  type: typeof APPEALS_CAN_ACCESS_SUCCESS;
  appealsCanAccess: IAppealsAcess;
}

export function appealsCanAccessSuccess(
  appealsCanAccess: IAppealsAcess
): IAppealsCanAccessSuccess {
  return {
    type: APPEALS_CAN_ACCESS_SUCCESS,
    appealsCanAccess: appealsCanAccess,
  };
}

export interface IAppealsCanAccessPending {
  type: typeof APPEALS_CAN_ACCESS_PENDING;
}

export function appealsCanAccessPending(): IAppealsCanAccessPending {
  return {
    type: APPEALS_CAN_ACCESS_PENDING,
  };
}

export interface IAppealsCanAccessError {
  type: typeof APPEALS_CAN_ACCESS_ERROR;
  error: string;
}

export function appealsCanAccessError(error: Error): IAppealsCanAccessError {
  return {
    type: APPEALS_CAN_ACCESS_ERROR,
    error: error.message,
  };
}

export interface IFetchAppealsCanAccess {
  type: typeof FETCH_APPEALS_CAN_ACCESS;
}

export function fetchAppealsCanAccess(): IFetchAppealsCanAccess {
  return {
    type: FETCH_APPEALS_CAN_ACCESS,
  };
}

export type AppealsActions =
  | IAppealsFeatureActivePending
  | IAppealsFeatureActiveSuccess
  | IAppealsFeatureActiveError
  | IFetchAppealsFeatureActive
  | IAppealsCanAccessPending
  | IAppealsCanAccessSuccess
  | IAppealsCanAccessError
  | IFetchAppealsCanAccess;
