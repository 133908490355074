// Stoage checks: developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API

class LocalStorage {
  private localStorage = window["localStorage"];
  private sessionStorage = window["sessionStorage"];

  private localStorageAvailable(): boolean {
    try {
      const x = "__storage_test__";
      this.localStorage.setItem(x, x);
      this.localStorage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        (e.code === 22 ||
          e.code === 1014 ||
          e.name === "QuotaExceededError" ||
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        this.localStorage &&
        this.localStorage.length !== 0
      );
    }
  }

  private getValueFromLocalStorage(key: string): string | null {
    return this.localStorage.getItem(key);
  }

  private getValueFromSessionCookie(key: string): string | undefined {
    const escapedCookieValue = `; ${document.cookie}`;
    const matchedCookieParts = escapedCookieValue.split(`; ${key}=`);

    return matchedCookieParts.length === 2
      ? matchedCookieParts.pop()?.split(";").shift()
      : "";
  }

  private setValueInLocalStorage(key: string, value: string): void {
    this.localStorage.setItem(key, value);
  }

  private setValueInSessionCookie(key: string, value: string): void {
    document.cookie = `${key}=${value};Secure;samesite=Strict;path=/non-transfered;max-age=31536000;`;
  }

  private clearLocalStorage(): void {
      const storageKeys = Object.keys(this.localStorage).map(x => x);
      storageKeys.forEach(x => {
         if (!x.startsWith("hgem_")){
            this.localStorage.removeItem(x);
         } 
      });
  }

  private clearAllSessionStorage(): void {
    try {
      this.sessionStorage.clear();
    } catch (e) {
      console.error(e);
    }
  }

  private clearAllCookieStorage(): void {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i],
        entry = cookie.split("="),
        name = entry[0];
      document.cookie =
        name +
        "=;Secure;samesite=Strict;path=/non-transfered;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  }

  getValue(key: string): string {
    if (this.localStorageAvailable()) {
      return this.getValueFromLocalStorage(key) || "";
    } else {
      return this.getValueFromSessionCookie(key) || "";
    }
  }

  getValueOrDefault(key: string, defaultValue: string): string {
    let value = this.getValue(key);

    if (!value || value === undefined || value.length === 0) {
      this.setValue(key, defaultValue);
      value = defaultValue;
    }

    return value;
  }

  setValue(key: string, value: string): void {
    if (this.localStorageAvailable()) {
      this.setValueInLocalStorage(key, value);
    } else {
      this.setValueInSessionCookie(key, value);
    }
  }

  clearAllData(): void {
    if (this.localStorageAvailable()) {
      this.clearLocalStorage();
      this.clearAllSessionStorage();
    } else {
      this.clearAllCookieStorage();
    }
  }
}

export const StorageInstance = new LocalStorage();
