import { Theme, makeStyles } from "@material-ui/core";
import SignalRNotificationsHubConnection from "../../signalr/SignalRNotificationsHubConnection";
import SessionManager from "../sessionManager/sessionManager";
import { hgemColours } from "../../themes/defaultTheme";

const Maintenance = () => {

    const style = makeStyles<Theme>(() => ({
        maintenanceContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            margin: 0
        },
        maintenanceText: {
            color: hgemColours.DarkGrey
        },
        maintenanceImage: {
            marginTop: 20,
            maxWidth: "80%"
        }
    }))();

    return (
        <SignalRNotificationsHubConnection>
            <>
                <div className={style.maintenanceContainer}>
                    <h1>We'll be back as soon as we can!</h1>
                    <p className={style.maintenanceText}>The Hub is temporarily unavailable. We will get things back to normal as quickly as possible.</p>
                    <img src="hgem_hub_logo.png" alt="HGEM" className={style.maintenanceImage}></img>
                </div>
                <SessionManager />
            </>
        </SignalRNotificationsHubConnection>
    )

};

export default Maintenance;