import { Typography } from "@material-ui/core";
import { i18n } from "../../../../localizations";
import { AppealsStyles } from "../../../InboxAppeals/styles";

type Props = {
    showTitle?: boolean | undefined
    children: React.ReactElement;
}

const AttachmentsContainer = (props: Props) => {
    const appealsClasses = AppealsStyles();

    return <div className={`${appealsClasses.attachmentsContainer}`}>
        {(props.showTitle === undefined || props.showTitle) && <Typography>
            <strong>{i18n.translate("ATTACHMENTS")}</strong>
        </Typography>}
        {props.children}
    </div>
};

export default AttachmentsContainer;