import React from "react";
import { Route } from "react-router-dom";
import { routes } from "./routes";

interface routeProps {
  location: Location;
  notFoundComponent: React.FunctionComponent;
  siteRootComponent: React.FunctionComponent;
}

const UnknownRoute = (props: routeProps) => {
  if (props.location.pathname !== routes._empty) {
    return <Route component={props.notFoundComponent} />;
  } else {
    return <Route component={props.siteRootComponent} />;
  }
};

export default UnknownRoute;
