import { RootState } from "../../../state/reducers/RootReducer";
import { createSelector } from "reselect";
import { IVisitDetailsState } from "../../../state/reducers/VisitDetails-Reducer";
import { ITaskCentreCaseListState } from "../../../state/reducers/TaskCentre-CaseList-Reducer";
import { CaseEventType } from "../../../state/types/TaskCentreCases";
import { IAppealsState } from "../../../state/reducers/Appeals-Reducer";
import { IConversationsState } from "../../../state/reducers/Conversations-Reducer";

export const SelectedVisitId = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    return state.visitDetails.selectedVisitId;
  }
);

export const SelectedVisitBrandId = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    let brandId;
    const id = state.visitDetails.selectedVisitId;

    if (id) {
      const matchedVisit = state.visitDetails.visits.find((x) => x.id === id);
      brandId = matchedVisit ? matchedVisit.brandId : undefined;
    }

    return brandId;
  }
);

export const SelectedVisitType = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    let type = "";
    const id = state.visitDetails.selectedVisitId;

    if (id) {
      const matchedVisit = state.visitDetails.visits.find((x) => x.id === id);
      type = matchedVisit ? matchedVisit.type : "";
    }

    return type;
  }
);

export const SelectedVisitCanUseGuestContact = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    const id = state.visitDetails.selectedVisitId;
    const matchedVisit = state.visitDetails.visits.find((x) => x.id === id);

    return matchedVisit ? matchedVisit.contactAvailable : false;
  }
);

export const SelectedVisitTaskCount = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    const selectedVisitId = state.visitDetails.selectedVisitId;
    if (selectedVisitId) {
      const matchedTasksCount = state.visitTaskCounts.find(
        (x) => x.visitId === selectedVisitId
      );

      return matchedTasksCount && matchedTasksCount.count
        ? matchedTasksCount.count
        : 0;
    }

    return 0;
  }
);

export const SelectedVisitHasMediaOrReceipts = createSelector(
  (state: RootState) => state.VisitDetailsReducer,
  (state: IVisitDetailsState) => {
    const selectedVisitId = state.visitDetails.selectedVisitId;
    if (selectedVisitId) {
      const selectedVisit = state.visitDetails.visits.find(
        (x) => x.id === selectedVisitId
      );

      return selectedVisit
        ? selectedVisit.hasMedia || selectedVisit.hasReceipt
        : false;
    }

    return false;
  }
);

export const SelectedCaseCommentCount = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    const selectedCaseId = state.taskCentreCases.selectedCaseId;
    if (selectedCaseId) {
      const selectedCase = state.taskCentreCases.cases.find(
        (x) => x.id === selectedCaseId
      );

      return selectedCase
        ? selectedCase.events.filter(
            (x) => x.type === CaseEventType.CommentAddedEvent
          ).length
        : 0;
    }

    return 0;
  }
);

export const SelectedCaseMessageCount = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    const selectedCaseId = state.taskCentreCases.selectedCaseId;
    if (selectedCaseId) {
      const selectedCase = state.taskCentreCases.cases.find(
        (x) => x.id === selectedCaseId
      );

      return selectedCase
        ? selectedCase.events.filter(
            (x) =>
              x.type === CaseEventType.MessageSentEvent ||
              x.type === CaseEventType.MessageReceivedEvent ||
              x.type === CaseEventType.GuestContactConfirmedEvent
          ).length
        : 0;
    }

    return 0;
  }
);

export const SelectedCase = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    const caseId = state.taskCentreCases.selectedCaseId;
    const cases = state.taskCentreCases.cases;

    return caseId ? cases.find((x) => x.id === caseId) : undefined;
  }
);

export const SelectedCaseCanUseGuestContact = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    let canContact = false;
    const caseId = state.taskCentreCases.selectedCaseId;

    if (caseId) {
      const selectedCase = state.taskCentreCases.cases.find(
        (x) => x.id === caseId
      );
      canContact = selectedCase
        ? selectedCase.vType?.toLowerCase() === "of" ||
          selectedCase.vType?.toLowerCase() === "ef"
        : false;
    }

    return canContact;
  }
);

export const ManagerCount = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.managers.length;
  }
);

export const CategoryCount = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.categories.length;
  }
);

export const AppealsFeatureActive = createSelector(
  (state: RootState) => state.AppealsReducer,
  (state: IAppealsState) => {
    return state.appealsFeatureActive.isActive;
  }
);

export const ConversationsFeatureActive = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.featureActive;
  }
);

export const ConversationLocations = createSelector(
  (state: RootState) => state.FilterOptionsReducer,
  (state) => {
    return state.filterOptions.locationOptions;
  }
);
