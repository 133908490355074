import {
  IReviewContent,
  IReviewResponseTemplate,
  VisitReportContentType,
} from "../types/VisitReport";

export const VISIT_REPORT_SUCCESS = "VISIT_REPORT_SUCCESS";
export const VISIT_REPORT_ERROR = "VISIT_REPORT_ERROR";
export const VISIT_REPORT_PENDING = "VISIT_RVISIT_REPORT_PENDINGEPORT_BUSY";
export const VISIT_REPORT_DOWNLOAD_PDF = "VISIT_REPORT_DOWNLOAD_PDF";
export const VISIT_REPORT_OPEN_VISIT_DETAIL = "VISIT_REPORT_OPEN_VISIT_DETAIL";
export const VISIT_REPORT_FETCH_VISIT_HTML = "VISIT_REPORT_FETCH_VISIT_HTML";
export const VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT =
  "VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT";
export const VISIT_REPORT_VISIT_CONTENT_SUCCESS =
  "VISIT_REPORT_VISIT_CONTENT_SUCCESS";
export const VISIT_REPORT_CLEAR_ERROR = "VISIT_REPORT_CLEAR_ERROR";
export const VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES =
  "VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES";
export const VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES_SUCCESS =
  "VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES_SUCCESS";

export interface IVisitReportSuccess {
  type: typeof VISIT_REPORT_SUCCESS;
}

export function visitReportSuccess(): IVisitReportSuccess {
  return {
    type: VISIT_REPORT_SUCCESS,
  };
}

export interface IVisitReportPending {
  type: typeof VISIT_REPORT_PENDING;
}

export function visitReportPending(): IVisitReportPending {
  return {
    type: VISIT_REPORT_PENDING,
  };
}

export interface IVisitReportError {
  type: typeof VISIT_REPORT_ERROR;
  error: string;
}

export function visitReportError(error: string): IVisitReportError {
  return {
    type: VISIT_REPORT_ERROR,
    error: error,
  };
}

export interface IDownloadVisitReportPdf {
  type: typeof VISIT_REPORT_DOWNLOAD_PDF;
  visitId: number;
}

export function downloadVisitReportPdf(
  visitId: number
): IDownloadVisitReportPdf {
  return {
    type: VISIT_REPORT_DOWNLOAD_PDF,
    visitId,
  };
}

export interface IOpenVisitReportInInbox {
  type: typeof VISIT_REPORT_OPEN_VISIT_DETAIL;
  visitId: number;
  visitType: string | undefined;
}

export function openVisitReportInInbox(
  visitId: number,
  visitType: string | undefined
): IOpenVisitReportInInbox {
  return {
    type: VISIT_REPORT_OPEN_VISIT_DETAIL,
    visitId,
    visitType,
  };
}

export interface IFetchVisitReportHtml {
  type: typeof VISIT_REPORT_FETCH_VISIT_HTML;
  visitId: number;
}

export function fetchVisitReportHtml(visitId: number): IFetchVisitReportHtml {
  return {
    type: VISIT_REPORT_FETCH_VISIT_HTML,
    visitId,
  };
}

export interface IFetchVisitReportReviewContent {
  type: typeof VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT;
  visitId: number;
}

export function fetchVisitReportReviewContent(
  visitId: number
): IFetchVisitReportReviewContent {
  return {
    type: VISIT_REPORT_FETCH_VISIT_REVIEW_CONTENT,
    visitId,
  };
}

export interface IVisitReportContentSuccess {
  type: typeof VISIT_REPORT_VISIT_CONTENT_SUCCESS;
  visitId: number;
  html: string | undefined;
  reviewContent: IReviewContent | undefined;
  contentType: VisitReportContentType;
}

export function visitReportContentSuccess(
  visitId: number,
  html: string | undefined,
  reviewContent: IReviewContent | undefined,
  contentType: VisitReportContentType
): IVisitReportContentSuccess {
  return {
    type: VISIT_REPORT_VISIT_CONTENT_SUCCESS,
    visitId,
    html,
    reviewContent,
    contentType,
  };
}

export function clearVisitReportError(): IClearVisitReportError {
  return {
    type: VISIT_REPORT_CLEAR_ERROR,
  };
}

export interface IClearVisitReportError {
  type: typeof VISIT_REPORT_CLEAR_ERROR;
}

export interface IFetchReviewResponseTemplates {
  brandId: number | undefined | null;
  type: typeof VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES;
}

export function fetchReviewResponseTemplates(
  brandId: number | undefined | null
): IFetchReviewResponseTemplates {
  return {
    brandId: brandId,
    type: VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES,
  };
}

export interface IFetchReviewResponseTemplatesSuccess {
  type: typeof VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES_SUCCESS;
  templates: IReviewResponseTemplate[];
}

export function fetchReviewResponseTemplatesSuccess(
  templates: IReviewResponseTemplate[]
): IFetchReviewResponseTemplatesSuccess {
  return {
    type: VISIT_REPORT_FETCH_REVIEW_RESPONSE_TEMPLATES_SUCCESS,
    templates,
  };
}

export type VisitReportActions =
  | IDownloadVisitReportPdf
  | IOpenVisitReportInInbox
  | IVisitReportSuccess
  | IVisitReportError
  | IVisitReportPending
  | IFetchVisitReportHtml
  | IFetchVisitReportReviewContent
  | IVisitReportContentSuccess
  | IClearVisitReportError
  | IFetchReviewResponseTemplates
  | IFetchReviewResponseTemplatesSuccess;
