import React from "react";
import ELearningView from "../components/eLearning/eLearningView";

const ELearning = () => {
  return (
    <>
      <ELearningView />
    </>
  );
};

export default ELearning;
