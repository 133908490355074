import axios, { AxiosResponse } from "axios";
import { IGuestDetails } from "../types/GuestDetails";

export const getGuestDetailsApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<IGuestDetails>> => {
  return axios.get<IGuestDetails>(
    `${process.env.REACT_APP_GUEST_DETAILS_API_BASEURL}client/${clientId}/visit/${visitId}/Guest/ContactDetails`
  );
};
