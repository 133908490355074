import { colors, makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  container: {
    overflow: "hidden",
  },

  sectionContainer: {
    display: "inline-block",
    width: "100%",
    marginBottom: "25px",

    "&& p": {
      fontSize: "1em",
      fontWeight: "700",
      color: colors.grey[500],
    },

    "&& p > span": {
      display: "inline",
      marginRight: "10px",
    },
  },
}));

export const InboxMediaStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
