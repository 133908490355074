import { useDispatch, useSelector } from "react-redux";
import { IStateObserver, IStateObserverProps } from "./IStateObserver";
import {
  UserProfileErrors,
  UserProfileLastUpdated,
  UserProfileLoaded,
} from "../state/selectors";
import { fetchUserProfileSuccess } from "../../../state/actions/UserProfile-Actions";
import { getUserProfileAsync } from "../../../state/functions/userProfileFunctions";

const useUserProfileObserver = () => {
  const dispatch = useDispatch();
  const userProfileLoaded = useSelector(UserProfileLoaded);
  const userProfileErrors = useSelector(UserProfileErrors);
  const userProfileLastUpdated = useSelector(UserProfileLastUpdated);

  const stateRequiresRefresh = async (): Promise<boolean> => {
    return (
      !userProfileLoaded ||
      !userProfileLastUpdated ||
      userProfileErrors.length > 0
    );
  };

  const setUserProfileAsync = async (props: IStateObserverProps) => {
    const { clientId, userId, userName } = props;

    const { userProfile, appVersion } = await getUserProfileAsync(
      clientId,
      userId,
      userName
    );

    dispatch(fetchUserProfileSuccess(userProfile, appVersion));
  };

  const observer: IStateObserver = {
    name: "useUserProfileObserver",
    isInitialised: userProfileLoaded ? userProfileLoaded : false,
    hasErrors: userProfileErrors.length > 0,
    lastRefresh: userProfileLastUpdated,
    initialiseState: setUserProfileAsync,
    stateRequiresRefresh,
    refreshState: setUserProfileAsync,
  };

  return observer;
};

export default useUserProfileObserver;
