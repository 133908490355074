import { Theme } from "@material-ui/core/styles";
import { hgemColours } from "../../../themes/defaultTheme";

export const ReportRenderStyles = (theme: Theme) => ({
  container: {
    backgroundColor: hgemColours.GreyReportBackground,
    minHeight: "400px",
    margin: "10px 20px 10px 10px",

    "& iframe": {
      border: "none",
      backgroundColor: theme.palette.background.paper,
    },
  },
  scrollContainer: {
    overflow: "auto",
    height: "100vh",
    width: "99%",
  },
  outer: {
    overflow: "auto",
  },
});
