import { SagaMiddleware } from "redux-saga";
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { AuthServiceInstance } from "../../authentication/authService";
import i18n from "../../localizations/i18n";
import { Logging, SeverityLevel } from "../../utils/logging";
import { sendInternalAppNotification } from "../actions/AppNotification-Actions";
import {
  SignalRNotificationAction,
  SIGNALR_NOTIFICATION,
} from "../actions/SignalRNotifications-Actions";
import {
  fetchVisitTasksCounts,
  FETCH_VISITDETAILS_LIST,
  FETCH_VISIT_TASKS_COUNT,
  FETCH_VISIT_MEDIA,
  FETCH_VISIT_RECEIPTS,
  IFetchVisitTasksCounts,
  IFetchVisitDetailsList,
  IFetchVisitMedia,
  IPostReviewResponse,
  mergeVisitTasksCounts,
  mergeVisitMediaItems,
  mergeVisitReceiptItems,
  POST_REVIEW_RESPONSE,
  setVisitListItemContactStatus,
  updateVisitDetailsList,
  visitDetailsListError,
  visitDetailsListPending,
  visitSavedUpdateVisitDetailsList,
  setVisitDetailsVisitCount,
} from "../actions/VisitDetails-Actions";
import { fetchVisitReportReviewContent } from "../actions/VisitReport-Actions";
import {
  getTaskCountsApi,
  getVisitMediaApi,
  getVisitReceiptsApi,
  getVisitsApi,
  IVisitTasksCountResponse,
  IVisitDetailsResponse,
  postReviewResponseApi,
} from "../api/VisitDetails-API";
import { VisitDetailsQuery } from "../selectors/VisitDetails-Selectors";
import {
  IAppNotification,
  NotificationSource,
  NotificationType,
} from "../types/AppNotification";
import { SignalRNotificationTypes } from "../types/SignalRNotifications";
import { IVisitTaskCount, IVisitMedia } from "../types/VisitDetails";
import { IGetVisitDetailsQuery } from "../types/VisitDetailsQuery";

export function registerVisitDetailsSagas(sagaMiddleware: SagaMiddleware) {
  sagaMiddleware.run(function* () {
    yield takeLatest(FETCH_VISITDETAILS_LIST, fetchVisitDetails);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_VISIT_TASKS_COUNT, fetchTasksCount);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_VISIT_MEDIA, fetchVisitMediaForVisit);
  });

  sagaMiddleware.run(function* () {
    yield takeLeading(FETCH_VISIT_RECEIPTS, fetchReceiptsForVisit);
  });

  sagaMiddleware.run(function* () {
    yield takeEvery(POST_REVIEW_RESPONSE, postReviewResponse);
  });

  sagaMiddleware.run(function* () {
    yield takeEvery(SIGNALR_NOTIFICATION, signalRNotification);
  });
}

function* fetchVisitDetails(action: IFetchVisitDetailsList): unknown {
  yield put(visitDetailsListPending(action.query));

  try {
    const clientId = yield call(AuthServiceInstance.getClientId);
    const userId = yield call(AuthServiceInstance.getUserId);
    const isGeneralSearch = action.query.visitId === undefined;

    action.query.userId = userId;

    const visitResponse: IVisitDetailsResponse = (yield call(
      getVisitsApi,
      clientId,
      action.query
    )).data;

    yield put(setVisitDetailsVisitCount(visitResponse.totalCount));

    yield put(updateVisitDetailsList(visitResponse.visits, isGeneralSearch));

    const visitIds = visitResponse.visits?.map((x) => x.id);

    if (visitIds && visitIds.length > 0) {
      yield put(fetchVisitTasksCounts(visitIds));
    }
  } catch (e) {
    Logging.captureError("Saga:fetchVisitDetails", e, SeverityLevel.Error);
    yield put(visitDetailsListError("Failed to fetch visit details"));
  }
}

function* fetchTasksCount(action: IFetchVisitTasksCounts): unknown {
  try {
    const actionCounts: IVisitTasksCountResponse[] = (yield call(
      getTaskCountsApi,
      action.visitIds
    )).data;

    yield put(
      mergeVisitTasksCounts(
        actionCounts.map((x) => {
          return { visitId: x.R, count: x.C } as IVisitTaskCount;
        })
      )
    );
  } catch (e) {
    Logging.captureError("Saga:fetchVisitDetails", e, SeverityLevel.Error);
  }
}

function* fetchVisitMediaForVisit(action: IFetchVisitMedia): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const fileNames: string[] = (yield call(
      getVisitMediaApi,
      clientId,
      action.visitId
    )).data;

    const visitMediaEntry: IVisitMedia = {
      visitId: action.visitId,
      fileNames: fileNames,
    };

    yield put(mergeVisitMediaItems(visitMediaEntry));
  } catch (e) {
    Logging.captureError(
      "Saga:fetchVisitMediaForVisit",
      e,
      SeverityLevel.Error
    );
  }
}

function* fetchReceiptsForVisit(action: IFetchVisitMedia): unknown {
  try {
    const clientId = yield call(AuthServiceInstance.getClientId);

    const fileNames: string[] = (yield call(
      getVisitReceiptsApi,
      clientId,
      action.visitId
    )).data;

    const visitReceiptEntry: IVisitMedia = {
      visitId: action.visitId,
      fileNames: fileNames,
    };

    yield put(mergeVisitReceiptItems(visitReceiptEntry));
  } catch (e) {
    Logging.captureError("Saga:fetchReceiptsForVisit", e, SeverityLevel.Error);
  }
}

function* postReviewResponse(action: IPostReviewResponse): unknown {
  try {
    const busyNotification: IAppNotification = {
      Identifier: `${action.visitId}|posted-review-response`,
      Source: NotificationSource.VisitDetails_PostReviewResponse,
      Type: NotificationType.Busy,
      ShowInNotificationCentre: false,
      NotificationTimeout: 60 * 1000,
    };

    yield put(sendInternalAppNotification(busyNotification, true));

    const clientId: number = yield call(AuthServiceInstance.getClientId);
    const reviewResponseId = yield call(
      postReviewResponseApi,
      clientId,
      action.visitId,
      action.reviewId,
      action.responseText
    );

    if (reviewResponseId) {
      const completeNotification: IAppNotification = {
        Identifier: `${action.visitId}|posted-review-response`,
        Source: NotificationSource.VisitDetails_PostReviewResponse,
        Type: NotificationType.Success,
        Message: i18n.translate(
          "VISIT_DETAILS_REVIEW_RESPONSE_POST_RESPONSE_SUCCESS"
        ),
        ShowInNotificationCentre: false,
        NotificationTimeout: 5000,
      };

      yield put(sendInternalAppNotification(completeNotification, true));
      yield put(fetchVisitReportReviewContent(action.visitId));
      yield put(setVisitListItemContactStatus(action.visitId, true));
    }
  } catch (e) {
    Logging.captureError("Saga:postReviewResponse", e, SeverityLevel.Error);

    const errorNotification: IAppNotification = {
      Identifier: `${action.visitId}|posted-review-response`,
      Source: NotificationSource.VisitDetails_PostReviewResponse,
      Type: NotificationType.Error,
      Message: i18n.translate(
        "VISIT_DETAILS_REVIEW_RESPONSE_POST_RESPONSE_ERROR"
      ),
      ShowInNotificationCentre: false,
      NotificationTimeout: 0,
    };

    yield put(sendInternalAppNotification(errorNotification, true));
  }
}

function* signalRNotification(action: SignalRNotificationAction): unknown {
  const notification = action.notification;

  if (notification.notificationType !== SignalRNotificationTypes.VisitSaved) {
    return;
  }

  const previousQuery: IGetVisitDetailsQuery | undefined = yield select(
    VisitDetailsQuery
  );

  if (
    !previousQuery ||
    (previousQuery.visitId && previousQuery.visitId !== notification.visitId)
  ) {
    return;
  }

  const clientId = yield call(AuthServiceInstance.getClientId);
  const userId = yield call(AuthServiceInstance.getUserId);

  const newQuery: IGetVisitDetailsQuery = {
    ...previousQuery,
    pagination: { itemsPerPage: 1, pageIndex: 0 },
    visitId: notification.visitId,
    excludeFiltersWithVisitId: false,
    userId: userId,
  };

  try {
    const visitResponse: IVisitDetailsResponse = (yield call(
      getVisitsApi,
      clientId,
      newQuery
    )).data;

    const visit = visitResponse.visits[0];

    if (visit) {
      yield put(visitSavedUpdateVisitDetailsList(visit));

      yield put(fetchVisitTasksCounts([visit.id]));
    }
  } catch (e) {
    console.error(e);
    Logging.captureError("Saga:fetchVisitDetails", e, SeverityLevel.Error);
    yield put(visitDetailsListError("Failed to fetch visit details"));
  }
}
