import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { HubThemeSelector } from "./themes/state/selectors";

interface IProps {
    children: React.ReactElement;
}

const AppThemeWrapper = (props: IProps) => {
  const theme = useSelector(HubThemeSelector);

  return (
    <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
  );
};

export default AppThemeWrapper;
