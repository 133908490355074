import { createSelector } from "reselect";
import { ITaskCentreCaseListState } from "../reducers/TaskCentre-CaseList-Reducer";
import { IConversationsState } from "../reducers/Conversations-Reducer";
import { RootState } from "../reducers/RootReducer";

export const ConversationSourceConfigs = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.sourceConfigs;
  }
);

export const ConversationStates = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.conversationStates;
  }
);

export const ConversationVisitStates = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversationVisits;
  }
);

export const SelectedConversationId = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.selectedConversationId;
  }
);

export const TotalConversationsCount = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.conversationStates.length;
  }
);

export const InitialConversationsListLoaded = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.initialListLoaded;
  }
);

export const ConversationsListPending = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.isLoading;
  }
);

export const MoreConversationsAvailable = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.filters.conversationId
      ? false
      : state.conversations.hasMorePages;
  }
);

export const ConversationsNextPageToken = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversations.nextPageToken;
  }
);

export const ConversationManagers = createSelector(
  (state: RootState) => state.TaskCentreCaseListReducer,
  (state: ITaskCentreCaseListState) => {
    return state.managers;
  }
);

export const ConversationVisits = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.conversationVisits;
  }
);

export const UnreadConversationsCount = createSelector(
  (state: RootState) => state.ConversationsReducer,
  (state: IConversationsState) => {
    return state.unreadConversations.unreadCount;
  }
);
