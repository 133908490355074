import { I18n } from "i18n-js";

import en from "../localizations/strings/en";
import fr from "../localizations/strings/fr";

const i18n = new I18n(
  {
    en: en,
    "en-GB": en,
    "fr-FR": fr,
  },
  {
    locale: navigator.language,
    enableFallback: true,
    missingBehavior: "guess",
  }
);

export default i18n;
