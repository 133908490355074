import { useEffect } from "react";
import { useHistory } from "react-router";
import { routes } from "../../navigation/routes";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Grid, LinearProgress } from "@material-ui/core";

const SiteRoot = () => {
  const history = useHistory();
  const { inProgress } = useMsal();

  useEffect(() => {
    let checkCount = 0;
    const checkAuth = async (): Promise<void> => {
      checkCount++;
      if (inProgress === InteractionStatus.None || checkCount > 5) {
        history.push(routes.inbox);
      }
    };

    const authCheckInterval = setInterval(async () => checkAuth(), 1000);
    return () => clearInterval(authCheckInterval);
  }, [history, inProgress]);

  return (
    <>
      <span className="no-display">
        Processing Authentication: {inProgress}
      </span>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <LinearProgress />
        </Grid>
      </Grid>
    </>
  );
};

export default SiteRoot;
