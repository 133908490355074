import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "../../localizations/i18n";
import { routes } from "../../navigation/routes";
import { getUrlQueryValueNumber } from "../../utils/urlParser";
import {
  visitReportErrorSelector,
  visitReportPendingSelector,
} from "./state/selectors";
import { downloadVisitReportPdf } from "../../state/actions/VisitReport-Actions";
import { VisitReportAutoDownloaderStyles } from "./styles/visitReportAutoDownloaderStyles";

const VisitReportAutoDownloader = () => {
  const classes = VisitReportAutoDownloaderStyles();

  const queryVisitId = getUrlQueryValueNumber("visitid");
  const [visitId] = useState(queryVisitId);

  const [parseError, setParseError] = useState("");

  const dispatch = useDispatch();
  const reportPending = useSelector(visitReportPendingSelector);
  const reportError = useSelector(visitReportErrorSelector);

  const history = useHistory();

  const refreshPage = () => {
    window.location.reload();
  };

  const goToVisitDetails = () => {
    history.push(routes.visitDetails);
  };

  const refreshPageMessage = () => {
    return (
      <p>
        {i18n.translate("VISIT_REPORT_DOWNLOAD_Download_Try_Again_Message")}
        <Button variant="contained" onClick={refreshPage}>
          {i18n.translate("VISIT_REPORT_DOWNLOAD_Download_Try_Again")}
        </Button>
      </p>
    );
  };

  const goToVisitDetailsMessage = () => {
    return (
      <p>
        {i18n.translate("VISIT_REPORT_DOWNLOAD_View_More_Reports")}
        <Button variant="contained" color="primary" onClick={goToVisitDetails}>
          {i18n.translate("VISIT_REPORT_DOWNLOAD_Visit_Details")}
        </Button>
      </p>
    );
  };

  useEffect(() => {
    if (visitId && !isNaN(visitId)) {
      dispatch(downloadVisitReportPdf(visitId));
    } else {
      setParseError("Invalid Visit Id");
    }
  }, [dispatch, visitId]);

  return (
    <div className={classes.container}>
      {reportPending && reportError === "" && parseError === "" && (
        <>
          <p className={classes.header}>
            {i18n.translate("VISIT_REPORT_DOWNLOAD_Download_Running")}
          </p>
          {refreshPageMessage()}
        </>
      )}
      {!reportPending && reportError && (
        <>
          <p className={classes.header}>
            {i18n.translate("VISIT_REPORT_DOWNLOAD_Download_Error")}
          </p>
          {refreshPageMessage()}
        </>
      )}

      {!reportPending && reportError === "" && parseError === "" && (
        <>
          <p className={classes.header}>
            {i18n.translate("VISIT_REPORT_DOWNLOAD_Download_Complete")}
          </p>
          {goToVisitDetailsMessage()}
        </>
      )}

      {!reportPending && parseError !== "" && (
        <>
          <p className={classes.header}>
            {i18n.translate("VISIT_REPORT_DOWNLOAD_Parse_URL_Error")}
          </p>
          {goToVisitDetailsMessage()}
        </>
      )}
    </div>
  );
};

export default VisitReportAutoDownloader;
