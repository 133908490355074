import React, { useState, useEffect } from "react";
import FilterWrapper from "./filterWrapper";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import { IManager } from "../../../state/types/TaskCentreCases";
import SearchTextInput from "../searchTextInput/searchTextInput";
import { i18n } from "../../../localizations";

interface IProps {
  availableAssignees: IManager[];
  selectedAssignees: number[];
  applySelectionCallback: (selections: IManager[]) => void;
}

const TaskAssigneePicker = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState<string[]>([]);
  const [assigneeSearchValue, setAssigneeSearchValue] = useState("");

  const handleToggleOpen = () => {
    setAssigneeSearchValue("");
    setIsOpen(!isOpen);
  };

  const getAppliedSelectionText = (): string => {
    let selectionText = "";
    if (
      selectedAssignees.length === 0 ||
      selectedAssignees.length === props.availableAssignees.length
    ) {
      selectionText = i18n.translate("TASK_ASSIGNEE_PICKER_All_Selected");
    } else if (selectedAssignees.length === 1) {
      return selectedAssignees[0];
    } else {
      selectionText = `${selectedAssignees.length} ${i18n.translate(
        "TASK_ASSIGNEE_PICKER_Selected"
      )}`;
    }

    return selectionText;
  };

  const buildTooltipContent = (): string => {
    if (selectedAssignees) {
      let assignees = selectedAssignees.join(", ");
      if (assignees.length > 150) {
        assignees = assignees.substring(0, 150) + "...";
      }
      return assignees;
    } else {
      return i18n.translate("TASK_ASSIGNEE_PICKER_All_Selected");
    }
  };

  const applySelection = (selectedOptions: string[]) => {
    const selectedManagers = props.availableAssignees.filter(
      (x) => selectedOptions.indexOf(x.name) > -1
    );

    props.applySelectionCallback(selectedManagers);
    setAssigneeSearchValue("");
    setIsOpen(false);
  };

  const cancelSelection = () => {
    setAssigneeSearchValue("");
    setIsOpen(false);
  };

  const clearSearch = () => {
    setAssigneeSearchValue("");
  };

  const onSearch = (searchValue: string) => {
    setAssigneeSearchValue(searchValue);
  };

  useEffect(() => {
    const selectedAssignees = props.availableAssignees
      .filter((x) => props.selectedAssignees.indexOf(x.id) > -1)
      .map((x) => x.name);

    setSelectedAssignees(selectedAssignees ? selectedAssignees : []);
  }, [props.availableAssignees, props.selectedAssignees]);

  return (
    <FilterWrapper
      label={i18n.translate("TASK_ASSIGNEE_PICKER_Assignee")}
      selectionHint={getAppliedSelectionText()}
      isOpen={isOpen}
      toggleOpenState={handleToggleOpen}
      tooltip={buildTooltipContent()}
      highlight={false}
    >
      <SearchTextInput
        label={i18n.translate("TASK_ASSIGNEE_PICKER_Search")}
        onClearSearch={clearSearch}
        onSearch={onSearch}
        searchPlaceholder={i18n.translate("TASK_ASSIGNEE_PICKER_SearchByName")}
      />

      <GenericMultiSelect
        useSelectAllOption={false}
        selectAllOptionText={i18n.translate(
          "TASK_ASSIGNEE_PICKER_Select_All_Option"
        )}
        multiSelect={true}
        options={props.availableAssignees
          .filter(
            (x) =>
              x.name.toLowerCase().indexOf(assigneeSearchValue.toLowerCase()) >
              -1
          )
          .map((x) => x.name)}
        selectedOptions={selectedAssignees}
        applySelectionCallback={applySelection}
        cancelSelectionCallback={cancelSelection}
        applySort={true}
      />
    </FilterWrapper>
  );
};

export default TaskAssigneePicker;
