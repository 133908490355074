import { useEffect, useState } from "react";
import { voucherIsActive } from "../../api";
import { VoucherResponse } from "../../types";

type Props = {
  clientId: number | undefined;
};

export const voucherDetails = (props: Props) => {
  const { clientId } = props;
  const [voucherDetails, setVoucherDetails] = useState<VoucherResponse[]>();
  useEffect(() => {
    if (clientId) {
      voucherIsActive(clientId).then((response) => {
        setVoucherDetails(response.data);
      });
    }
  }, [setVoucherDetails, clientId]);

  return voucherDetails;
};
