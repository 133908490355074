import { Fade, FormControlLabel, Hidden, Switch } from "@material-ui/core";
import {
  AppealStatus,
  AppealQuestionPicker,
  ApproverSelect,
  AppealsButton,
} from ".";
import { AppealsStyles } from "../styles";
import {
  ICaseDetails,
  IManager,
  WorkflowStatus,
} from "../../../state/types/TaskCentreCases";
import { i18n } from "../../../localizations";
import { useEffect, useState } from "react";
import { useAppealReferenceQuestions } from "../../../state/hooks/appeals/useReferenceQuestions";
import { useClientId } from "../../../state/hooks/clients/useClientId";
import { useApprovalManagers } from "../../../state/hooks/appeals/useApprovalManagers";
import { BrandSelectionFormStyles } from "../../userToolbar/styles/brandSelectionFormStyles";
import {
  getAppealApproverEvent,
  getAppealBonusAppealOnly,
  getAppealQuestions,
} from "../utils";
import { TasksStyles } from "../../taskCentreEditor/styles/tasksStyles";

type Props = {
  currentAppeal: ICaseDetails;
  isApprovalManager: boolean;
  isPendingAction: boolean;
  setIsPendingAction: React.Dispatch<React.SetStateAction<boolean>>;
  cancel: () => void;
  save: (params: {
    bonusAppealOnly: boolean | undefined;
    reviewerId: number | undefined;
    deleteQuestionIds: number[] | undefined;
    addQuestionIds: number[] | undefined;
  }) => Promise<void>;
};
const EditableAppealInformation = (props: Props) => {
  const {
    currentAppeal,
    isApprovalManager,
    isPendingAction,
    setIsPendingAction,
    cancel,
    save,
  } = props;
  const appealsClasses = AppealsStyles();
  const commonClasses = BrandSelectionFormStyles();
  const taskStyles = TasksStyles();

  const clientId = useClientId();

  const appealReferenceQuestions = useAppealReferenceQuestions({
    clientId: clientId,
    visitId: currentAppeal.vId,
  });

  const approvalManagers = useApprovalManagers({
    clientId: clientId,
    visitId: currentAppeal.vId,
  });

  const [wholeReport, setWholeReport] = useState<boolean>(false);
  const [bonusAppealOnly, setBonusAppealOnly] = useState<boolean>(false);

  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const [selectedQuestionsIsInvalid, setSelectedQuestionsIsInvalid] =
    useState<boolean>(false);

  const [selectedManager, setSelectedManager] = useState<
    IManager | undefined
  >();
  const [approverIsInvalid, setApproverIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (currentAppeal) {
      const approverEvent = getAppealApproverEvent(currentAppeal);

      const bonusAppealOnly = getAppealBonusAppealOnly(currentAppeal);

      const questions = getAppealQuestions(currentAppeal);

      const questionSubjects = questions.map((x) => x.questionSubject);

      const questionIds = questions.map((x) => x.id);

      setSelectedQuestions(questionSubjects);
      setSelectedQuestionIds(questionIds);

      if (bonusAppealOnly) {
        setBonusAppealOnly(true);
        setWholeReport(false);
      } else if (questions.length === 0) {
        setBonusAppealOnly(false);
        setWholeReport(true);
      }

      if (approverEvent?.assigneeId) {
        const approvalManager = approvalManagers.find(
          (x) => x.id === approverEvent.assigneeId
        );

        setSelectedManager(approvalManager);
      }
    }
  }, [currentAppeal, approvalManagers]);

  const selectedQuestionsIsValid = (): void => {
    if (selectedQuestionIds.length === 0 && !wholeReport) {
      setSelectedQuestionsIsInvalid(true);
    }
    setSelectedQuestionsIsInvalid(false);
  };

  const selectedManagerIsValid = (): void => {
    if (!isApprovalManager && !selectedManager) {
      setApproverIsInvalid(true);
    }
    setApproverIsInvalid(false);
  };

  const validate = () => {
    return new Promise<void>((resolve, reject) => {
      if (
        currentAppeal.wfStatus === WorkflowStatus.AppealRaised &&
        !selectedManager &&
        selectedQuestionIds.length === 0 &&
        !wholeReport &&
        !bonusAppealOnly
      ) {
        setSelectedQuestionsIsInvalid(true);
        setApproverIsInvalid(true);

        reject();
        return;
      }
      if (
        !selectedManager &&
        currentAppeal.wfStatus === WorkflowStatus.AppealRaised
      ) {
        setApproverIsInvalid(true);

        reject();
        return;
      }
      if (
        selectedQuestionIds.length === 0 &&
        !wholeReport &&
        !bonusAppealOnly
      ) {
        setSelectedQuestionsIsInvalid(true);

        reject();
        return;
      }

      resolve();
    });
  };

  const submit = async () => {
    await validate();

    const originalQuestionIds = getAppealQuestions(currentAppeal).map(
      (x) => x.id
    );

    const questionIds =
      wholeReport || bonusAppealOnly ? [] : selectedQuestionIds;

    const questionIdsToDelete = originalQuestionIds.filter(
      (x) => !questionIds.some((y) => y === x)
    );

    const questionIdsToAdd = questionIds.filter(
      (x) => !originalQuestionIds.some((y) => y === x)
    );

    const shouldUpdateQuestionIdsToDelete = questionIdsToDelete.length > 0;

    const shouldUpdateQuestionIdsToAdd = questionIdsToAdd.length > 0;

    const shouldUpdateBonusAppealOnly =
      getAppealBonusAppealOnly(currentAppeal) !== bonusAppealOnly;

    const shouldUpdateAssignee =
      getAppealApproverEvent(currentAppeal)?.assigneeId !== selectedManager?.id;

    if (
      !shouldUpdateBonusAppealOnly &&
      !shouldUpdateAssignee &&
      !shouldUpdateQuestionIdsToDelete &&
      !shouldUpdateQuestionIdsToAdd
    ) {
      cancel();
    } else {
      await save({
        bonusAppealOnly: shouldUpdateBonusAppealOnly
          ? bonusAppealOnly
          : undefined,
        reviewerId: shouldUpdateAssignee ? selectedManager?.id : undefined,
        deleteQuestionIds: shouldUpdateQuestionIdsToDelete
          ? questionIdsToDelete
          : undefined,
        addQuestionIds: shouldUpdateQuestionIdsToAdd
          ? questionIdsToAdd
          : undefined,
      });
    }
  };

  return (
    <>
      <div className={`${appealsClasses.flexRow} ${appealsClasses.topRow}`}>
        <span className={`${appealsClasses.appealStatus}`}>
          <AppealStatus status={currentAppeal.wfStatus} />
        </span>
        {currentAppeal.wfStatus === WorkflowStatus.AppealRaised && (
          <>
            <span className={`${appealsClasses.flexItem}`}>
              <ApproverSelect
                managers={approvalManagers}
                selectedManager={selectedManager}
                selectChange={setSelectedManager}
                isInvalid={approverIsInvalid}
                validate={selectedManagerIsValid}
              />
            </span>
          </>
        )}
        <Hidden implementation="js" mdDown>
          <span className={appealsClasses.newAppealButtonsBar}>
            <AppealsButton
              name={i18n.translate("APPEALS_Edit_SAVE_BUTTON_TEXT")}
              setIsPending={setIsPendingAction}
              isPending={isPendingAction}
              promise={() => submit()}
              className={taskStyles.tasksButton}
            />
            <AppealsButton
              className={taskStyles.tasksButton}
              name={i18n.translate("APPEALS_Edit_CANCEL_BUTTON_TEXT")}
              variant={"outlined"}
              setIsPending={setIsPendingAction}
              isPending={isPendingAction}
              promise={() => {
                cancel();
                return Promise.resolve();
              }}
            />
          </span>
        </Hidden>
      </div>
      <div className={`${appealsClasses.flexRow}`}>
        <span className={`${appealsClasses.wholeReportSwitch}`}>
          <FormControlLabel
            label={i18n.translate("APPEALS_WHOLE_REPORT_LABEL")}
            control={
              <Switch
                size="medium"
                className={commonClasses.checkbox}
                checked={wholeReport}
                onChange={(event) => {
                  setWholeReport(event.target.checked);

                  if (event.target.checked) {
                    setBonusAppealOnly(false);
                  }
                }}
              />
            }
          />
        </span>
        <span>
          <FormControlLabel
            label={i18n.translate("APPEALS_QUESTIONS_BONUS_APPEAL_ONLY")}
            control={
              <Switch
                size="medium"
                className={commonClasses.checkbox}
                checked={bonusAppealOnly}
                onChange={(event) => {
                  setBonusAppealOnly(event.target.checked);

                  if (event.target.checked) {
                    setWholeReport(false);
                  }
                }}
              />
            }
          />
        </span>
      </div>
      {!wholeReport && !bonusAppealOnly && (
        <Fade in={!wholeReport && !bonusAppealOnly}>
          <div>
            <AppealQuestionPicker
              appealReferenceQuestions={appealReferenceQuestions}
              selectedQuestions={selectedQuestions}
              setSelectedQuestionIds={setSelectedQuestionIds}
              setSelectedQuestions={setSelectedQuestions}
              isInvalid={selectedQuestionsIsInvalid}
              validate={selectedQuestionsIsValid}
            />
          </div>
        </Fade>
      )}
      <span
        className={`${appealsClasses.flexRow} ${appealsClasses.floatRight} ${appealsClasses.flexRowWithIcon}`}
      >
        <Hidden implementation="css" mdUp>
          <>
            <AppealsButton
              name={i18n.translate("APPEALS_Edit_SAVE_BUTTON_TEXT")}
              setIsPending={setIsPendingAction}
              isPending={isPendingAction}
              promise={() => submit()}
              className={taskStyles.tasksButton}
            />
            <AppealsButton
              className={taskStyles.tasksButton}
              name={i18n.translate("APPEALS_Edit_CANCEL_BUTTON_TEXT")}
              variant={"outlined"}
              setIsPending={setIsPendingAction}
              isPending={isPendingAction}
              promise={() => {
                cancel();
                return Promise.resolve();
              }}
            />
          </>
        </Hidden>
      </span>
    </>
  );
};

export default EditableAppealInformation;
