import { CardDetailsStyles } from "../styles/cardDetailsStyles";

interface IProps {
  label: string;
  labelText: string;
}

const CardDetailsConfirmation = (props: IProps) => {
  const classes = CardDetailsStyles();
  return (
    <>
      <dt className={classes.definitionListItem}>{props.label}</dt>
      <dd className={classes.definitionListDefinition}>{props.labelText}</dd>
    </>
  );
};
export default CardDetailsConfirmation;
