import Skeleton from "@material-ui/lab/Skeleton";
import { ConversationListSkeletonStyles } from "../styles";

const ConversationListSkeleton = () => {
  const classes = ConversationListSkeletonStyles();

  return (
    <div>
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
      <Skeleton
        className={classes.loader}
        variant="rect"
        height={100}
        animation="wave"
      />
    </div>
  );
};

export default ConversationListSkeleton;
