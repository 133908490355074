import { createSelector } from "reselect";
import { RootState } from "../../../../state/reducers/RootReducer";

export const GetActiveNotificationById = createSelector(
  (state: RootState) => state.AppNotificationReducer,
  (_: RootState, id: string | undefined) => id,
  (state, id) => {
    return id
      ? state.notifications.find((x) => x.Identifier === id)
      : undefined;
  }
);
