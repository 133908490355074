import { ClientBorderThreshold } from "../../../state/api";

export const textColourFinder = (score: number | undefined, thresholds: ClientBorderThreshold): string => {
  let textColour = "default";
  const {lowThreshold, highThreshold} = thresholds;
  
  if (score || score === 0) {
    const roundScore = Math.round(score)

    if (roundScore <= lowThreshold) {
      textColour = "red";
    }
    if (roundScore > lowThreshold && roundScore < highThreshold) {
      textColour = "amber";
    }
    if (roundScore >= highThreshold) {
      textColour = "green";
    }
  }

  return textColour;
};
